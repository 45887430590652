export default {
  setQuickEditActive(state, isActive) {
    state.quickEditActive = isActive;
  },
  SET_IMPORTED_VENDORS(state, vendors) {
    console.log('updating imported data', vendors);
    state.importedVendors = vendors;
    console.log('success update imported data');
  },
  UPDATE_VENDOR_BRANDING_FILES(state, { vendorId, brandFiles }) {
    console.log('UPDATE_VENDOR_BRANDING_FILES', vendorId, brandFiles);

    const vendorToUpdate = state.importedVendors.find(vendor => vendor._id === vendorId);
    if (vendorToUpdate) {
      // Assuming `newBrandingFiles` is a field inside each vendor object
      vendorToUpdate.newBrandingFiles = brandFiles;
    }
  },
  

  
};
