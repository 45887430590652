<template>
  <form
    @submit.prevent="submitForm"
    class="js-validate needs-validation"
    novalidate
  >
    <div class="my-5">
      <h1 class="display-5">Forgot password?</h1>
      <p>
        Enter the email address you used when you joined and we'll send you
        instructions to reset your password.
      </p>
    </div>

    <div class="row">
      <div class="col mb-3 mb-lg-0 text-start">
        <div class="mb-4">
          <label class="form-label" for="resetPasswordSrEmail" tabindex="0"
            >Your email</label
          >

          <input
            type="email"
            class="form-control form-control-lg"
            name="email"
            id="resetPasswordSrEmail"
            v-model="email"
            tabindex="1"
            placeholder="Enter your email address"
            aria-label="Enter your email address"
            required
          />
          <span class="invalid-feedback"
            >Please enter a valid email address.</span
          >
        </div>
      </div>
    </div>

    <div v-if="passReset">
      <div v-if="passReset.success">
        <p class="text-info text-start fw-bold mb-0">
          Password reset initiated!
        </p>
        <p class="text-start">
          Check your email (including Junk/Spam folders) to finish resetting
          your password.
        </p>
      </div>
      <div v-if="!passReset.success">
        <p class="text-info text-start fw-bold mb-0">Oops!</p>
        <p class="text-start">
          Something went wrong while attempting to initiate your password reset.
          Please try again. If the issue continues please reach out to our team
          at <span class="fw-bold">hello@boldxchange.com</span>
        </p>
      </div>
    </div>

    <!-- submit -->
    <div class="d-flex flex-column mt-2">
      <button class="btn btn-primary" type="button" disabled v-if="isLoading">
        <span
          class="spinner-grow spinner-grow-sm me-1"
          aria-hidden="true"
        ></span>
        <span role="status">Confirming email...</span>
      </button>
      <button
        v-if="!isLoading"
        @click="resetPassword"
        type="submit"
        class="btn btn-primary btn-lg clickable"
      >
        Submit email
      </button>
      <div class="text-center">
        <button
          class="btn btn-link clickable"
          @click.prevent="$emit('showResetPassword')"
        >
          <b-icon-chevron-left></b-icon-chevron-left> Back to Sign in
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import SuccessfulActionModal from "../../components/ui/modals/success/SuccessfulActionModal.vue";

export default {
  components: {
    SuccessfulActionModal,
  },
  name: "ResetPassword",
  props: ["userEmail"],

  methods: {
    async resetPassword() {
      this.passReset = false;
      this.isLoading = true;
      const { email } = this;

      const success = await this.$store.dispatch("auth/resetPass", { email });
      // console.log("RESET PASSWORD: ", success);
      if (success) {
        this.isLoading = false;
        this.passReset = { success };
        setTimeout(() => {
          this.passReset = false;
        }, 10000);
      } else {
        this.isLoading = false;
        this.passReset = { success };
        setTimeout(() => {
          this.passReset = false;
        }, 10000);
      }
      // this.passReset = { success };
      //show popup to check email

      // if (this.isBoldXchangeAdmin) {
      //   this.$router.push({ path: "/admin" });
      // } else {
      //   //if (this.$store.state.auth.user.role === "user") {
      //   this.$router.push({ path: "/" });
      // }
      // this.$root.$emit("login", this.$store.state.auth.token);
    },
  },
  data() {
    return {
      isLoading: false,
      email: "",
      passReset: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
