export default {
  getCartSkus(state) {
    return state.skus;
  },
  getCartData(state) {
    return state.cartData;
  },
  getFinalRetailPrice(state) {
    return state.finalRetailPrice;
  },
  getPercentMargin(state) {
    return state.percentMargin;
  },
  getCartCount(state){
    return state.cartCount;

  }
};

