<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <div class="page-header">
        <div class="d-flex justify-content-between">
          <h1 class="page-header-title text-start">Clients</h1>
        </div>
      </div>
      <div class="mb-1">
        <div class="input-group input-group-merge">
          <!-- <div class="input-group-prepend input-group-text">
              <b-icon-search></b-icon-search>
            </div> -->
          <input
            @keyup.enter="getClientsBySearchQuery()"
            type="text"
            id="datatableSearch"
            class="form-control"
            placeholder="Search client by name or domain"
            aria-label="Search client by name or domain"
            v-model="searchQuery"
          />
          <button
            type="button"
            class="btn btn-secondary"
            @click="getClientsBySearchQuery()"
          >
            Search<b-icon-search class="ms-2"></b-icon-search>
          </button>
        </div>
        <div v-if="clientsAreLoading" class="text-start mt-1">
          <span
            class="spinner-border spinner-border-sm me-1"
            aria-hidden="true"
          ></span>
          <span role="status">Loading...</span>
        </div>
        <p class="mt-1 text-start" v-else>
          <span class="badge bg-warning">{{ getQueryCount }}</span>
          Results
        </p>
        <div class="text-danger text-start mt-1" v-if="!doesClientExist">
          There are no clients in our database that match your search.
        </div>
      </div>
      <!-- End Search -->
      <div class="options mt-4">
        <ul class="list-group list-group-flush list-group-numbered text-start">
          <li
            @click="selectClient(client)"
            v-for="client in clients"
            :key="client._id"
            class="list-group-item list-group-item-action"
          >
            {{ client.name || "N/A" }} | 
            {{ client.domains[0] || "N/A" }}
          </li>
        </ul>
      </div>
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "ClientListModal",
  components: {
    FixedModalSmall,
  },
  data() {
    return {
      searchQuery: "",
      searchValueExists: false,
      doesClientExist: true,
      count: 0,
      clients: [],
      clientsAreLoading: false,
    };
  },
  created() {
    this.getAllClients();
  },
  watch: {
    searchQuery() {
      if (this.searchQuery === "") {
        this.searchCount = 0;
        this.getAllClients();
      }
      this.searchValueExists = false;
    },
  },
  computed: {
    getQueryCount() {
      return this.count;
    },
    getSearchQuery() {
      return this.searchQuery;
    },
  },
  methods: {

    async getAllClients() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.clientsAreLoading = true;
      this.doesClientExist = true;

      const pageSize = 300;
      const pageNo = 1;

      api
        .listClients({
          limit: pageSize,
          skip: pageSize * (pageNo - 1),
          sort: "name",
        })
        .then((response) => {
          this.clientsAreLoading = false;
          // console.log("client search response", response.data.result);
          if (response.data.result.records.length === 0) {
            this.count = 0;
            this.doesClientExist = false;
          } else {
            this.count = response.data.result.count;
            this.clients = response.data.result.records;
            this.doesClientExist = true;
            this.searchValueExists = true;
          }
        })
        .catch((error) => {
          console.log("client search query failed", error);
        });
    },
    async getClientsBySearchQuery() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.doesClientExist = true;
      this.clientsAreLoading = true;
      if (this.getSearchQuery === "") {
        this.getAllClients();
      } else {
        const pageSize = 300;
        api
          .listClients(
            {
              limit: pageSize,
              sort: "name",
              // expand: "users",
            },
            {
              filter: {
                $or: [
                  { name: { $regex: this.getSearchQuery } },
                  { domains: { $regex: this.getSearchQuery } },
                ],
              },
            }
          )
          .then((response) => {
            this.clientsAreLoading = false;
            // console.log("client search response", response.data.result);
            if (response.data.result.records.length === 0) {
              this.count = 0;
              this.doesClientExist = false;
            } else {
              this.count = response.data.result.count;
              this.clients = response.data.result.records;
              this.doesClientExist = true;
              this.searchValueExists = true;
            }
          })
          .catch((error) => {
            console.log("client search query failed", error);
          });
      }
    },
    selectClient(client) {
      this.$emit("setNewClient", client);
    },
  },
};
</script>

<style scoped>
#upload-region {
  width: 100%;
  background-color: #f8fafd;
  border: 0.125rem dashed rgba(231, 234, 243, 0.7);
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

#upload-region.dragover {
  background-color: #f0f0f0;
}

ul {
  /* add the following two lines */
  max-height: 20rem;
  overflow-y: auto;
  cursor: pointer;
}

li {
  /* margin-bottom: 10px; */
}
</style>
