<template>
  <FixedModalSmall>
    <!-- Page Header -->
    <template v-if="showImage">
      <div
        class="card-body d-flex flex-column align-items-center justify-content-center text-center"
      >
        <div class="mx-auto text-center">
          <h1 class="display-1">&#127881;</h1>
        </div>
        <div class="mx-auto text-center mb-3">
          <h2 class="mb-1">
            The Bold Xchange team will complete a final review and be in touch
            when your order goes into production.
          </h2>
        </div>
        <slot name="button-1"
          ><button
            class="btn btn-secondary"
            @click.stop="$emit('showDetailsCompleteModal')"
          >
            Close
          </button></slot
        >
        <slot name="button-2"></slot>
      </div>
    </template>
    <template v-else-if="orderWasPlaced">
      <div
        class="card-body d-flex flex-column align-items-center justify-content-center text-center"
      >
        <div class="mx-auto text-center">
          <h1 class="display-1">&#127881;</h1>
        </div>
        <div class="mx-auto text-center mb-3">
          <h1 class="display-3 mb-1">Woot woot!</h1>
          <p class="fs-1">Order was placed</p>
        </div>
        <slot name="button-1"
          ><button
            class="btn btn-secondary"
            @click.stop="$emit('showOrderWasPlacedMessage')"
          >
            Close
          </button></slot
        >
        <slot name="button-2"></slot>
      </div>
    </template>
    <template v-else-if="bulkImport || vendorAdded">
      <div
        class="card-body d-flex flex-column align-items-center justify-content-center text-center"
      >
        <div class="mx-auto text-center">
          <h1 class="display-1">&#127881;</h1>
        </div>
        <div class="mx-auto text-center mb-3">
          <h1 class="display-3 mb-1">Success!</h1>
          <template v-if="bulkImport">
            <p class="fs-1">Your new vendors are in the database.</p>
          </template>
          <template v-if="vendorAdded">
            <p class="fs-1">Your new vendor is in the database.</p>
          </template>
        </div>
        <div class="d-flex gap-2">
          <slot name="button-1">
            <button class="btn btn-secondary" @click.stop="$emit('close')">
              Close
            </button></slot
          >
          <slot name="button-2"></slot>
        </div>
      </div>
    </template>
    <template v-else-if="vendorEdited">
      <div
        class="card-body d-flex flex-column align-items-center justify-content-center text-center"
      >
        <div class="mx-auto text-center">
          <h1 class="display-1">&#127881;</h1>
        </div>
        <div class="mx-auto text-center mb-3">
          <h1 class="display-3 mb-1">Success!</h1>
          <template v-if="bulkImport">
            <p class="fs-1">Your new vendors are in the database.</p>
          </template>
          <template v-if="vendorAdded">
            <p class="fs-1">Your new vendor is in the database.</p>
          </template>
        </div>
        <div class="d-flex gap-2">
          <slot name="button-1">
            <button class="btn btn-secondary" @click.stop="$emit('close')">
              Close
            </button></slot
          >
          <slot name="button-2"></slot>
        </div>
      </div>
    </template>
    <template v-else-if="memberAdded || memberEdited">
      <div
        class="card-body d-flex flex-column align-items-center justify-content-center text-center"
      >
        <div class="mx-auto text-center">
          <h1 class="display-1">&#127881;</h1>
        </div>
        <div class="mx-auto text-center mb-3">
          <h1 class="display-3 mb-1">Success!</h1>
          <template v-if="memberAdded">
            <p class="fs-1">Your new member is in the database.</p>
          </template>
          <template v-if="memberEdited">
            <p class="fs-1">Your member edits are saved.</p>
          </template>
        </div>
        <div class="d-flex gap-2">
          <slot name="button-1"> </slot>
          <slot name="button-2"></slot>
        </div>
      </div>
    </template>
    <template v-else-if="campaignCreated || recipientsImported || !campaignSent">
            <div class="card-body d-flex flex-column justify-content-center text-start">
        <slot name="heading"> </slot>

          <slot name="content-slot-1"> </slot>
          <slot name="content-slot-2"> </slot>
        </div>
    </template>
    <template v-else>
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col">
            <div class="card-header d-flex justify-content-center mb-7">
              <img
                class="mb-5"
                style="max-width: 10rem"
                src="../../../../assets/svg/logos/Bold_Xchange_PrimaryLogo_Black.svg"
                alt="Image Description"
              />
            </div>
            <h3 class="page-header-title mb-4 text-info">
              <slot name="message">Success!</slot>
            </h3>
            <slot name="button-1"
              ><button
                class="btn btn-secondary"
                @click.stop="$emit('showDetailsCompleteModal')"
              >
                Close
              </button></slot
            >
            <slot name="button-2"></slot>
          </div>
        </div></div
    ></template>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "SuccessfulActionModal",
  components: {
    FixedModalSmall,
  },
  props: {
    showImage: {
      type: Boolean,
      required: false,
    },
    orderWasPlaced: {
      type: Boolean,
      required: false,
    },
    bulkImport: {
      type: Boolean,
      required: false,
    },
    memberAdded: {
      type: Boolean,
      required: false,
    },
    vendorAdded: {
      type: Boolean,
      required: false,
    },
    vendorEdited: {
      type: Boolean,
      required: false,
    },
    memberEdited: {
      type: Boolean,
      required: false,
    },
    campaignCreated: {
      type: Boolean,
      required: false,
    },
    campaignSent: {
      type: Boolean,
      required: false,
    },
    recipientsImported: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped></style>
