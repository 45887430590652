<template>
  <template v-if="showLandingPagePreview">
    <Teleport to="#modal">
      <TheModal
        @closeModal="toggleLandingPagePreview"
        :open="showLandingPagePreview"
        :closeOnClickOutside="true"
      >
        <LandingPageContent
          :campaignView="true"
          :getCampaignLandingPageData="getCampaignLandingPageData"
          :getCampaignLandingPageTitle="getCampaignLandingPageTitle"
          :getCampaignLandingPageMessage="getCampaignLandingPageMessage"
          @closeModal="toggleLandingPagePreview"
        ></LandingPageContent>
      </TheModal>
    </Teleport>
  </template>
  <TheCard>
    <TheBox :withPadding="true">
      <BlockStack :gap="500">
        <BlockStack :gap="300">
          <label class="tw-text-lg tw-leading-6 tw-text-gray-900"
            >Do you want to include a gift note with this campaign?</label
          >
          <div
            class="tw-flex tw-items-center tw-gap-3 tw-flex-grow tw-cursor-pointer"
            @click="enableGiftNote()"
          >
            <ToggleButton
              :toggleOn="getCampaignLandingPageStatus"
            ></ToggleButton>
            <template v-if="!getCampaignLandingPageStatus">
              <div class="tw-flex tw-flex-col tw-max-w-sm">
                <p class="tw-text-lg tw-gray-blue-700 tw-p-0 tw-m-0">
                  No, do not include gift note (default)
                </p>
              </div>
            </template>
            <template v-if="getCampaignLandingPageStatus">
              <div class="tw-flex tw-flex-col -tw-mt-1 tw-ml-1 tw-max-w-sm">
                <p class="tw-text-lg tw-gray-blue-700 tw-p-0 tw-m-0">
                  Yes, include gift note
                </p>
              </div>
            </template>
          </div>
        </BlockStack>
        <template v-if="getCampaignLandingPageStatus">
          <template
            v-if="
              !getCampaignLandingPageTitle || !getCampaignLandingPageMessage
            "
          >
            <p class="tw-text-lg tw-text-gray-700 tw-m-0 tw-bg-red-100">
              Please complete all required fields
            </p>
          </template>
          <TheBox
            class="tw-relative hover:tw-opacity-90 tw-cursor-pointer tw-gap-2 tw-p-2"
            :class="{
              'tw-border-solid tw-border-2 tw-border-green-600 tw-rounded tw-flex tw-flex-col':
                getCampaignLandingPageTitle && getCampaignLandingPageMessage,
              'tw-border-solid tw-border-2 tw-border-red-600 tw-rounded tw-flex tw-flex-col':
                !getCampaignLandingPageTitle || !getCampaignLandingPageMessage,
            }"
          >
            <div>
              <template
                v-if="
                  getCampaignLandingPageTitle && getCampaignLandingPageMessage
                "
              >
                <b-icon-check-circle-fill
                  class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-green-600"
                ></b-icon-check-circle-fill>
              </template>
              <template
                v-if="
                  !getCampaignLandingPageTitle || !getCampaignLandingPageMessage
                "
              >
                <b-icon-exclamation-circle-fill
                  class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-red-600"
                ></b-icon-exclamation-circle-fill>
              </template>
            </div>
            <BlockStack :gap="300" class="tw-grow">
              <BlockStack :gap="100" class="tw-grow">
                <label
                  for="giftNoteTitle"
                  class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                  >Title<span
                    v-if="!getCampaignLandingPageTitle"
                    class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                    >required field</span
                  ></label
                >

                <input
                  v-model="getCampaignLandingPageTitle"
                  @input="handleUpdatingGiftNoteTitle($event)"
                  @click.stop
                  type="text"
                  name="giftNoteTitle"
                  id="giftNoteTitle"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6"
                  placeholder="Add gift note title"
                />
              </BlockStack>
              <BlockStack :gap="100" class="tw-grow">
                <label
                  for="giftNoteMessage"
                  class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                  >Message<span
                    v-if="!getCampaignLandingPageMessage"
                    class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                    >required field</span
                  ></label
                >

                <BlockStack :gap="100" class="tw-w-full">
                  <RichTextEditor
                    :isEditing="true"
                    v-model="getCampaignLandingPageMessage"
                    @update:modelValue="handleUpdatingGiftNoteMessage"
                  ></RichTextEditor>
                </BlockStack>
              </BlockStack>
              <template
                v-if="
                  getCampaignLandingPageTitle && getCampaignLandingPageMessage
                "
              >
                <div>
                  <TheButton
                    variant="secondary"
                    size="regular"
                    @click="toggleLandingPagePreview()"
                  >
                    <template #text> Preview gift note </template>
                  </TheButton>
                </div>
              </template>
            </BlockStack>
          </TheBox>
        </template>
      </BlockStack>
    </TheBox>
  </TheCard>
</template>

<script>
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";
import ToggleButton from "../../../components-v2/ui/actions/ToggleButton";
import TheLink from "../../../components-v2/ui/actions/TheLink";
import TheModal from "../../../components-v2/ui/modals/TheModal.vue";
import RichTextEditor from "../../../components-v2/ui/forms/RichTextEditor.vue";
import LandingPageContent from "../../../components-v2/ui/modals/LandingPageContent.vue";
import userMixin from "@/mixins/userMixin";

export default {
  name: "TheGiftNote",
  emits: ["loadSteps"],
  mixins: [userMixin],
  components: {
    LandingPageContent,
    RichTextEditor,
    TheModal,
    TheLink,
    ToggleButton,
    TheButton,
    TheCard,
    TheBox,
    BlockStack,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
      isToggled: false,
    },
  },
  async created() {
    await this.refreshClient();
  },
  data() {
    return {
      showLandingPagePreview: false,
    };
  },
  computed: {
    Avatar() {
      const url = this?.User?.photo;
      if (url) {
        if (url.indexOf("/") === 0) {
          return process.env.VUE_APP_API_BASE_URL + url;
        } else {
          return url;
        }
      } else {
        return `https://api.dicebear.com/7.x/initials/svg?seed=${this?.User?.firstName}%20${this?.User?.lastName}&backgroundColor=3949ab`;
      }
    },
    getCampaignGiftingLeadName() {
      return this.$store.getters["campaigns/getCampaignGiftingLeadName"];
    },
    getCampaignLandingPageData() {
      return this.$store.getters["campaigns/getCampaignLandingPageData"];
    },
    getCampaignLandingPageStatus() {
      return this.$store.getters["campaigns/getCampaignLandingPageStatus"];
    },
    getCampaignLandingPageTitle() {
      return this.$store.getters["campaigns/getCampaignLandingPageTitle"];
    },
    getCampaignLandingPageMessage() {
      return this.$store.getters["campaigns/getCampaignLandingPageMessage"];
    },
  },
  methods: {
    handleUpdatingGiftNoteTitle(event) {
      let giftNoteTitle = event.target.value.trim();
      console.log("giftNoteTitle: ", giftNoteTitle);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_GIFT_NOTE_TITLE",
        giftNoteTitle
      );
      this.$emit("loadSteps");
    },
    handleUpdatingGiftNoteMessage(newMessage) {
      // Ensure newMessage is not null or undefined
      if (newMessage !== undefined && newMessage !== null) {
        let giftNoteMessage = newMessage.trim();
        console.log("Updated giftNoteMessage: ", giftNoteMessage);
        this.$store.commit(
          "campaigns/SET_CAMPAIGN_BUILDER_GIFT_NOTE_MESSAGE",
          giftNoteMessage
        );
        this.$emit("loadSteps");
      }
    },
    enableGiftNote() {
      const isGiftNoteEnabled = this.getCampaignLandingPageStatus;

      console.log("isGiftNoteEnabled: ", isGiftNoteEnabled);

      // this.$store.commit(
      //   "campaigns/SET_CAMPAIGN_BUILDER_GIFT_NOTE_ENABLED",
      //   !isGiftNoteEnabled
      // );

      const landingPageSettings = {
        required: false,
        enabled: !isGiftNoteEnabled,
        giftNote: {
          title: "",
          message: "",
        },
        signature: this?.userMixinData?.metadata?.landingPageSettings?.signature,
        organizationLogo:
          this?.userMixinData?.metadata?.landingPageSettings?.organizationLogo,
      };

      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_GIFT_NOTE_SETTINGS",
        landingPageSettings
      );

      this.$emit("loadSteps");
    },
    toggleLandingPagePreview() {
      this.showLandingPagePreview = !this.showLandingPagePreview;
      console.log("show gift note preview");
    },
  },
};
</script>

<style scoped></style>
