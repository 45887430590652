<template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <ThePage :fullWidth="true">
    <template v-if="checkingIfUserHasBudgets">
      <CampaignDetailsPageSkeleton></CampaignDetailsPageSkeleton>
    </template>
    <template v-if="!checkingIfUserHasBudgets">
      <BlockStack :gap="800" class="tw-w-full tw-max-w-7xl">
        <PageSection title="Overview">
          <BudgetStats
            :budget="budget"
            @checkForBudgets="checkForBudgets"
          ></BudgetStats>
        </PageSection>
        <PageSection title="Campaigns for this budget">
          <!-- <template v-if="showCampaigns && !showRecipients"> -->
          <CampaignsByBudgetTable
            :budget="budget"
          ></CampaignsByBudgetTable>
        </PageSection>
      </BlockStack>
    </template>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import CampaignDetailsPageSkeleton from "../../components-v2/ui/skeletons/page-skeletons/CampaignDetailsPageSkeleton.vue";
import CampaignsByBudgetTable from "../../components-v2/budgets/CampaignsByBudgetTable";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import moment from "moment";
import BudgetStats from "../../components-v2/budgets/BudgetStats.vue";

export default {
  name: "BudgetDetails",
  components: {
    NotificationMessage,
    CampaignsByBudgetTable,
    ThePage,
    PageSection,
    CampaignDetailsPageSkeleton,
    BlockStack,
    BudgetStats,
  },
  data() {
    return {
      budget: {},
      recipients: [],
      recipientCount: 0,
      checkingIfUserHasBudgets: true,
      showImportRecipientsModal: false,
      isLoading: true,
      showCampaigns: false,
      showRecipients: false,
    };
  },
  created() {
    this.checkForBudgets();
  },
  computed: {
    budgetId() {
      return this.$route.params.budgetId;
    },
    User() {
      return this.$store.state.auth.user;
    },
    memberId() {
      return this.$route.params.memberId;
    },
    memberFirstName() {
      return this?.member?.firstName;
    },
    memberLastName() {
      return this?.member?.lastName;
    },
    memberEmail() {
      return this?.member?.email;
    },
    memberClientName() {
      return this?.member?.client?.name;
    },
    memberClientDomain() {
      return this?.member?.client?.domains[0];
    },
    formattedDate() {
      const date = moment(this?.member?.createdAt).format("MM/DD/YYYY");
      return date;
    },
  },
  methods: {
    /** loading budget */
    async checkForBudgets() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      try {
        let requestBody = {};
        let filter = {
          _id: this.budgetId,
        };
        const queryParameters = {
          limit: 1,
          expand: "user metadata",
        };

        requestBody = {
          filter: filter,
        };
        const listBudgetsRes = await api.listBudgets(
          queryParameters,
          requestBody
        );
        console.log("listBudgetsRes: ", listBudgetsRes);
        this.budget = listBudgetsRes.data.result.records[0];
        this.count = listBudgetsRes.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.error("Failed to load budgets:", error);
        // Handle the error appropriately
      } finally {
        this.checkingIfUserHasBudgets = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
