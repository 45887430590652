<template>
  <FixedModalSmall>
    <div class="card-header py-5 text-center border-bottom">
      <img
        style="max-width: 10rem"
        src="../../../assets/svg/logos/Bold_Xchange_PrimaryLogo_Black.svg"
        alt="Image Description"
      />
    </div>
    <template v-if="dataInfoArray">
      <div class="row py-5">
        <!-- Delete sections -->
        <template v-for="(data, index) in dataInfoArray" :key="index">
          <template v-if="data.type !== 'media'">
            <div class="col-12 col-sm-5">
              <h3 class="page-header-title mb-1 text-info">
                Delete selected {{ data.type }}?
              </h3>
              <p>
                This action is permanent. You won't be able to recover this data
                once deleted.
              </p>
              <div class="d-flex justify-content-center gap-2">
                <button
                  @click.stop="confirm()"
                  type="button"
                  class="btn btn-danger text-white"
                >
                  Yes
                </button>
                <button
                  @click.stop="cancel()"
                  type="button"
                  class="btn btn-soft-primary"
                >
                  No
                </button>
              </div>
            </div>
            <div class="col-12 col-sm-7">
              <div class="card">
                <div class="card-header bg-soft-dark">
                  <h5 class="page-header-title">
                    <span class="text-capitalize">{{ data.type }}</span>
                    being deleted
                  </h5>
                </div>
                <div class="card-body bg-soft-light">
                  <ul class="list-checked list-checked-success text-muted">
                    <li
                      v-for="item in data.data"
                      :key="item.id"
                      class="list-checked-item text-start"
                    >
                      <template v-if="data.type === 'campaigns'">
                        <p class="mb-0 text-decoration-underline">Name</p>
                        <p class="mb-1 fw-bold text-capitalize">
                          {{ item.metadata.campaign_name }}
                        </p>
                        <p class="mb-0 text-decoration-underline">Status</p>
                        <p class="mb-1 fw-bold text-capitalize">
                          {{ item.metadata.campaign_status }}
                        </p>
                        <p class="mb-0 text-decoration-underline">Type</p>
                        <p class="mb-0 fw-bold text-capitalize">
                          {{ item.metadata.campaign_type }}
                        </p>
                      </template>
                      <template v-if="data.type === 'vendors'">
                        {{ item.name }}
                      </template>
                      <template v-if="data.type === 'recipients'">
                        <div class="d-flex flex-column">
                          <span class="fw-bold"
                            >{{ item.firstName + " " + item.lastName }}
                          </span>
                        </div>
                      </template>
                      <template v-if="data.type === 'members'">
                        <div class="d-flex flex-column">
                          <span class="fw-bold"
                            >{{ item.firstName + " " + item.lastName }}
                          </span>
                          <span>{{ item.email }} </span>
                        </div>
                      </template>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-12">
              <h3 class="page-header-title mb-1 text-info">
                Delete selected {{ data.type }}?
              </h3>
              <p>
                This action is permanent. You won't be able to recover this data
                once deleted.
              </p>
              <div class="d-flex justify-content-center gap-2">
                <button
                  @click.stop="confirm()"
                  type="button"
                  class="btn btn-danger text-white"
                >
                  Yes
                </button>
                <button
                  @click.stop="cancel()"
                  type="button"
                  class="btn btn-soft-primary"
                >
                  No
                </button>
              </div>
            </div>
          </template>
        </template>
      </div>
    </template>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../ui/modals/templates/FixedModalSmall.vue";

export default {
  name: "ConfirmDeleteModal",
  components: {
    FixedModalSmall,
  },
  props: {
    orders: {
      type: Array,
      required: false,
    },
    products: {
      type: Array,
      required: false,
    },
    kits: {
      type: Array,
      required: false,
    },
    proposals: {
      type: Array,
      required: false,
    },
    vendors: {
      type: Array,
      required: false,
    },
    shippingMethods: {
      type: Array,
      required: false,
    },
    packagingOptions: {
      type: Array,
      required: false,
    },
    members: {
      type: Array,
      required: false,
    },
    campaigns: {
      type: Array,
      required: false,
    },
    recipients: {
      type: Array,
      required: false,
    },
    media: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataTypes: {
        campaigns: this.campaigns,
        vendors: this.vendors,
        members: this.members,
        recipients: this.members,
        media: this.media,
      },
    };
  },
  computed: {
    dataInfoArray() {
      const dataTypes = [
        { type: "campaigns", data: this.campaigns },
        { type: "vendors", data: this.vendors },
        { type: "members", data: this.members },
        { type: "recipients", data: this.recipients },
        { type: "media", data: this.media },

        // Include other data types with their respective props
      ];

      return dataTypes.filter((dataInfo) => dataInfo.data);
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
