<template>
  <transition
    enter-active-class="tw-transition tw-ease-out tw-duration-200 tw-transform"
    enter-from-class="tw-opacity-0 tw-translate-x-[-100%]"
    enter-to-class="tw-opacity-100 tw-translate-x-0"
    leave-active-class="tw-transition tw-ease-in tw-duration-200 tw-transform"
    leave-from-class="tw-opacity-100 tw-translate-x-0"
    leave-to-class="tw-opacity-0 tw-translate-x-[-100%]"
  >
    <div class="tw-relative lg:tw-hidden" v-show="open">
      <div
        class="tw-fixed tw-z-50 tw-inset-0 tw-flex tw-bg-black tw-bg-opacity-5"
        @click.self="close"
      >
        <Transition
          enter-active-class="tw-transition tw-ease-out tw-duration-300 tw-transform "
          enter-from-class="tw-opacity-0 tw-translate-x-[-100%] tw-scale-95"
          enter-to-class="tw-opacity-100 tw-translate-x-0 tw-scale-100"
          leave-active-class="tw-ease-in tw-duration-200"
          leave-from-class="tw-opacity-100 tw-translate-x-0 tw-scale-100"
          leave-to-class="tw-opacity-0 tw-translate-x-[-100%] tw-scale-95"
        >
          <div
            class="tw-bg-blue-900 tw-text-left tw-overflow-hidden tw-shadow-xl tw-p-4 tw-max-w-48"
            role="dialog"
            ref="modal"
            aria-modal="true"
            v-show="open"
            aria-labelledby="modal-headline"
          >
            <slot></slot>
          </div>
        </Transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SlideOutRight",
  emits: ["closeModal"],
  props: {
    title: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "",
    },
    role: {
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.open) {
      this.toggleBodyClass(true);
    }
  },
  beforeUnmount() {
    this.toggleBodyClass(false);
  },
  watch: {
    open(newValue) {
      this.toggleBodyClass(newValue);
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    handleOutsideClick() {
      if (this.closeOnClickOutside) {
        this.close();
      }
    },
    toggleBodyClass(open) {
      const method = open ? "add" : "remove";
      document.body.classList[method]("tw-overflow-hidden");
    },
  },
};
</script>
<style scoped></style>
