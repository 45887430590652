<template>
  <StripePaymentForm></StripePaymentForm>
</template>

<script>
import StripePaymentForm from "./StripePaymentForm.vue";
export default {
  name: "CustomerRegistrationFailure",
  components: { StripePaymentForm },
  data() {
    return {};
  },
  async mounted() {
    // this.stripeRes = await this.signUpForGcMarketplace();
  },
  methods: {
    async signUpForGcMarketplace() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      const signupRes = await api.signUpForGcMarketplace(
        // {
        //   useLink: false,
        // },
        {
          successUrl:
            process.env.API_BASE_URL +
            "/gc-marketplace-setup/success/{CHECKOUT_SESSION_ID}",
          cancelUrl:
            process.env.API_BASE_URL +
            "/api/v2/subscriptions/gc-marketplace/setup-failure",
        }
      );
      return signupRes;
    },
  },
};
</script>

<style scoped></style>
