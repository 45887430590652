import { getBoldClient } from "@/api/getBoldClient.js";
import store from "../store/index";

// Handles getting the default payment method for a user
const getDefaultPaymentInformation = async (userId) => {
  const token = store.state.auth.token; // Access token from Vuex store
  const api = await getBoldClient(token); // Get the API client using the token

  const defaultPaymentRes = await api.defaultPaymentInfo(null, {
    filter: {
      _id: userId,
    },
  });
  return defaultPaymentRes.data; // Directly return the data
};

// Handles setting up a new payment method
const setupPaymentMethod = async () => {
  const token = store.state.auth.token; // Access token from Vuex store
  const api = await getBoldClient(token); // Get the API client using the token

  const successUrl =
    window.location.origin + "/payment-method/save/{CHECKOUT_SESSION_ID}";
  const cancelUrl = window.location.origin + "/account";

  const paymentMethodRes = await api.setupPaymentMethod({
    successUrl: successUrl,
    cancelUrl: cancelUrl,
  });
  return paymentMethodRes.data; // Directly return the data
};

export { getDefaultPaymentInformation, setupPaymentMethod };
