<template>
  <BulkImportModal
    v-if="showBulkImportModal"
    @cancel="toggleBulkImportModal"
  ></BulkImportModal>

  <div class="container content-space-3 content-space-md-5 d-flex align-items-center justify-content-center">
    <div class="row justify-content-center gy-3">
      <div class="text-center mb-5">
        <h1 class="display-3">Select option</h1>
      </div>
      <div class="col-md-4">
        <router-link
          class="card card-lg card-transition text-center h-100"
          to="/add/vendor"
        >
          <div class="card-body">
            <h1 class="display-1 text-bold-xchange-blue">
              <b-icon-building-add></b-icon-building-add>
            </h1>

            <h2 class="card-title">Add single vendor</h2>
          </div>
        </router-link>
      </div>
      <div class="col-md-4">
        <a
          class="card card-lg card-transition text-center h-100"
          @click="toggleBulkImportModal()"
        >
          <div class="card-body">
            <h1 class="display-1 text-bold-xchange-blue">
              <b-icon-filetype-csv></b-icon-filetype-csv>
            </h1>

            <h2 class="card-title">Bulk upload multiple vendors</h2>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import BulkImportModal from "../../components/ui/modals/BulkImportModal.vue";

export default {
  name: "VendorsOptions",
  components: { BulkImportModal },
  data() {
    return {
      showBulkImportModal: false,
    };
  },
  created() {},
  computed: {},
  methods: {
    toggleBulkImportModal() {
      this.showBulkImportModal = !this.showBulkImportModal;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  cursor: pointer;
}
</style>
