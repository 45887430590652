<template>
  <template v-if="importMessage.successful.show">
    <Teleport to="#modal">
      <TheModal :open="importMessage.successful.show">
        <ModalBody>
          <SuccessContent :recipientsImported="importMessage.successful.show">
            <template #heading>
              <BlockStack :gap="800" class="tw-items-center tw-text-center">
                <div>
                  <h2 class="tw-text-6xl tw-font-bold tw-text-gray-900">
                    &#127881;
                  </h2>
                  <h2 class="tw-text-3xl tw-font-bold tw-text-blue-800 tw-m-0">
                    Your import was successful
                  </h2>

                  <template v-if="group?._id">
                    <ol class="tw-list-disc tw-m-0 tw-text-left">
                      <li class="text-gray-700 tw-text-sm">
                        {{ importCount }} new contacts were added to your
                        account.
                      </li>
                      <li class="text-gray-700 tw-text-sm">
                        Your recipients group titled
                        <span class="tw-font-bold tw-capitalize">{{
                          group?.metadata?.name
                        }}</span>
                        was successfully updated.
                      </li>
                    </ol>
                  </template>
                  <template v-else>
                    <p class="tw-text-lg tw-m-0 tw-max-w-96">
                      We've added {{ importCount }} new contacts to your
                      member's account.
                    </p>
                  </template>
                </div>
                <div>
                  <TheButton
                    variant="tertiary"
                    size="large"
                    @click="reloadPage()"
                  >
                    <template #text>Close</template>
                  </TheButton>
                </div>
              </BlockStack>
            </template>
          </SuccessContent>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="isImportingContacts">
    <Teleport to="#modal">
      <TheModal :open="isImportingContacts">
        <ModalBody>
          <RecipientsImportStatus :recipientsImportStatus="importProgress">
          </RecipientsImportStatus>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="updateMessage.failed.show">
    <div
      class="tw-border-red-400 tw-bg-red-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-flex-col tw-gap-4 tw-w-full tw-mx-auto tw-mb-4"
    >
      <div class="tw-flex tw-gap-2 tw-items-center">
        <b-icon-exclamation-circle-fill
          class="tw-text-red-500"
        ></b-icon-exclamation-circle-fill>
        <p class="tw-m-0 tw-text-lg">Upload failed</p>
      </div>
      <ol class="tw-list-disc tw-m-0 tw-text-left">
        <template v-if="csvColumnErrors.isActive">
          <li class="text-gray-700 tw-text-sm">
            Your CSV file column headers do not match the column headers of our
            <span
              ><a href="/files/contacts-csv-upload-template.csv" download
                >contact list CSV file template</a
              >.</span
            >
          </li>
        </template>
        <template v-if="csvRowErrors.isActive">
          <li class="text-gray-700 tw-text-sm">
            One or more rows are missing information.
          </li>
        </template>
      </ol>
    </div>
  </template>
  <div
    class="tw-rounded tw-flex tw-flex-col tw-items-center tw-justify-center"
    :class="{ 'tw-bg-gray-200': !updateMessage.successful.show }"
  >
    <template v-if="!updateMessage.successful.show">
      <div class="tw-h-28 tw-w-auto tw-flex-shrink-0 tw-rounded">
        <img
          src="../../../assets/svg/illustrations-test/47_import.png"
          alt="office365 logo"
          class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
        />
      </div>
      <!-- button -->
      <div
        class="tw-flex tw-flex-col tw-items-center tw-gap-3 tw-justify-center"
      >
        <label
          class="tw-border-none tw-w-44 tw-justify-center tw-cursor-pointer tw-text-sm tw-py-2.5 tw-px-3 tw-rounded tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-blue-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed"
        >
          Upload CSV File

          <input
            class="form-control"
            type="file"
            accept=".csv"
            hidden
            @change="handleFileUpload($event, hideTooltip)"
          />
        </label>
        <p class="tw-text-lg tw-leading-8 tw-text-gray-700">
          Use our
          <a href="/files/contacts-csv-upload-template.csv" download
            >contact list CSV file template</a
          >
          to streamline your upload process.
        </p>
        <div
          class="tw-border-orange-400 tw-bg-orange-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-flex-col tw-gap-2 tw-w-full tw-mx-auto"
        >
          <div class="tw-flex tw-gap-2 tw-items-center">
            <b-icon-exclamation-circle-fill
              class="tw-text-orange-500 tw-text-sm"
            ></b-icon-exclamation-circle-fill>
            <p class="tw-m-0 tw-text-sm">
              <span class="fw-bold">First Name</span>,
              <span class="fw-bold">Last Name</span>, and
              <span class="fw-bold">Email Address</span> fields are required for
              all contacts.
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-if="updateMessage.successful.show">
      <div class="tw-flex tw-flex-col tw-gap-3">
        <div
          class="tw-border-green-400 tw-bg-green-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-flex-col tw-gap-4 tw-w-full tw-mx-auto"
        >
          <div class="tw-flex tw-gap-2 tw-items-center">
            <b-icon-check-circle-fill
              class="tw-text-green-500"
            ></b-icon-check-circle-fill>
            <template v-if="csvCount">
              <p class="tw-m-0 tw-text-lg">
                <span class="tw-bg-yellow-300"
                  >{{ this.content.data.length }} contacts</span
                >
                were uploaded from CSV file
              </p>
            </template>
          </div>
        </div>
        <div
          class="tw-border-orange-400 tw-bg-orange-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-flex-col tw-gap-2 tw-w-full tw-mx-auto"
        >
          <div class="tw-flex tw-gap-2 tw-items-center">
            <b-icon-exclamation-circle-fill
              class="tw-text-orange-500"
            ></b-icon-exclamation-circle-fill>
            <p class="tw-m-0 tw-text-lg">Please note...</p>
          </div>
          <ol class="tw-list-disc tw-m-0 tw-text-left">
            <li class="text-gray-700 tw-text-sm">
              Uploaded contacts that are missing first name, last name, or email
              will be ignored.
            </li>
            <li class="text-gray-700 tw-text-sm">
              Only selected contacts will be imported.
            </li>
            <li class="text-gray-700 tw-text-sm">
              If an imported contact's email address matches an email address
              already in the member's existing contacts list, the existing
              contact’s information will be updated with the data from the
              import file.
            </li>
          </ol>
        </div>

        <ImportRecipientsTableV1
          :contacts="content"
          @cancelImport="cancelImport"
          @createNewRecipients="createNewRecipients"
          :csvUpload="csvCount"
        ></ImportRecipientsTableV1>
      </div>
    </template>
  </div>
</template>

<script>
import ThePage from "../../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../../components-v2/ui/layout/PageHeader.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton";
import ImportRecipientsTableV1 from "../../../components-v2/recipients/ImportRecipientsTableV1.vue";
import TheModal from "../../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../../components-v2/ui/layout/ModalBody.vue";
import SuccessContent from "../../../components-v2/ui/modals/SuccessContent.vue";
import RecipientsImportStatus from "../../../components-v2/ui/modals/RecipientsImportStatus.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import Papa from "papaparse";

export default {
  name: "ImportData",
  components: {
    BlockStack,
    RecipientsImportStatus,
    ImportRecipientsTableV1,
    ThePage,
    PageHeader,
    TheButton,
    TheModal,
    ModalBody,
    SuccessContent,
    TheBox,
    TheCard,
  },
  emits: ["reloadData"],
  props: {
    memberId: {
      type: String,
      default: null,
    },
    userId: {
      type: String,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
    existingGroupMemberIds: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      updateMessage: {
        successful: {
          text: "Import successful.",
          class: "alert-soft-success alert-dismissible fade show",
          show: false,
        },
        failed: {
          text: "Import failed. Please try again.",
          class: "alert-soft-danger alert-dismissible fade show",
          show: false,
        },
      },
      importMessage: {
        successful: {
          //   text: "Import successful.",
          //   class: "alert-soft-success alert-dismissible fade show",
          show: false,
        },
        failed: {
          text: "Import failed. Please try again.",
          class: "alert-soft-danger alert-dismissible fade show",
          show: false,
        },
      },
      orderErrorObject: {},
      csvColumnErrors: {
        isActive: false,
        data: null,
      },
      csvRowErrors: {
        isActive: false,
        data: null,
      },
      editedRow: {},
      content: [],
      file: [],
      selectedRows: [],
      failedRecipients: null,
      successfulRecipients: [],
      selectedLifecycleStage: "",
      isBulkEditing: false,
      selectAll: false,
      isEditing: false,
      importInstructionsAreVisible: false,
      parsed: false,
      isImportingContacts: false,
      campaignId: null,
      importProgress: 0,
      importCount: 0,
      csvCount: 0,
      after: 0,
    };
  },
  computed: {
    routeToCampaignCreation() {
      return this.$router.options.history.state.back.includes(
        "/campaign/edit/"
      );
    },
    routeToDashboard() {
      return this.$router.options.history.state.back.includes("/dashboard");
    },
    routeToRecipients() {
      return this.$router.options.history.state.back.includes("/recipients");
    },
    userClient() {
      return this?.$store?.state?.auth?.user?.client;
    },
    combinedUniqueIds() {
      const uniqueIds = [
        ...new Set([
          ...this.existingGroupMemberIds,
          ...this.successfulRecipients,
        ]),
      ];
      return uniqueIds.map((id) => ({
        doc: id,
        metadata: {},
      }));
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** create recipients */
    async createNewRecipients(selectedRecipients) {
      // Import the Client type for better IntelliSense and type-checking
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      this.isImportingContacts = true;
      // const giftingLeads = [this.userId];
      const giftingLeads = [this.memberId ?? this.userId];
      const totalRecipients = selectedRecipients.length;
      this.importCount = totalRecipients;
      let processedCount = 0;

      // Array to hold recipients that failed to be imported
      const failedRecipients = [];
      // const successfulRecipients = [];

      // Loop through each recipient in selectedRecipients
      for (const recipientData of selectedRecipients) {
        const recipient = {
          firstName: recipientData["First Name"],
          lastName: recipientData["Last Name"],
          "metadata.giftingLeads": giftingLeads,
          "contact.email": recipientData["Email Address"],
          // "contact.metadata.lifecycleStage": recipientData["Lifecycle Stage"],
          "contact.company": recipientData["Company"] || "",
        };

        // Call the API for each recipient inside the loop
        try {
          const recipientRes = await api.upsertRecipient(null, {
            filter: {
              "contact.email": { $eq: recipientData["Email Address"] },
              ...(!this.isBoldXchangeAdmin && {
                "metadata.giftingLeads": { $eq: giftingLeads },
              }),
            },
            patch: recipient,
          });
          console.log(
            `Successfully created recipient: ${recipientData["Email Address"]}`
          );
          console.log(`recipientRes: ${recipientRes.data.result._id}`);
          processedCount++;
          this.updateProgress((processedCount / totalRecipients) * 100);
          this.successfulRecipients.push(recipientRes.data.result._id);
        } catch (error) {
          // Handle errors for individual recipient creations and add to the failedRecipients array
          // this.handleError(error);
          console.log(error);
          failedRecipients.push(recipient);
          processedCount++;
          this.updateProgress((processedCount / totalRecipients) * 100);
        }
      }

      // Check for failed recipients to retry or handle appropriately
      if (failedRecipients.length > 0) {
        console.log(
          `Some recipients could not be created: ${failedRecipients}`
        );
        // Optionally, store failedRecipients in a state or context for retrying later
        this.failedRecipients = failedRecipients;
        // Implement or call a function to retry or handle failed recipients
        // this.handleRetry(failedRecipients);
      } else {
        // If all went well
        // this.successfulRecipients = successfulRecipients;
        if (this?.group?._id) {
          // alert();
          await this.upsertGroup();
        }
        this.handleSuccess();
      }

      // No longer fetching contacts
      this.isImportingContacts = false;
    },
    async handleCreatingNewRecipients() {
      try {
        await Promise.all([this.createNewRecipients(), this.upsertGroup()]);
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your groups and segments data. Please reload the page and try again."
        );
      } finally {
        this.loadingAllRecipientsGroupsAndSegments = false;
      }
    },
    async upsertGroup() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      const recipientsIds = this.combinedUniqueIds;
      const groupPatch = {
        members: recipientsIds,
      };
      const groupId = this.group._id;

      try {
        const groupsRes = await api.upsertGroup(null, {
          filter: { _id: groupId },
          patch: groupPatch,
        });

        console.log("groupsRes: ", groupsRes);
      } catch (error) {
        this.handleError(error);
        console.log("group was NOT upserted", error);
      }
    },

    selectedRecipientsIds(selectedRecipients) {
      return selectedRecipients.map((recipient) => recipient._id) || [];
    },

    /** file upload */
    handleFileUpload(event) {
      this.importInstructionsAreVisible = false;
      this.resetUploadState();
      this.file = event.target.files[0];
      this.csvCount = 1;
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const expectedColumns = [
            "First Name",
            "Last Name",
            "Email Address",
            "Company",
          ];
          const actualColumns = Object.keys(results.data[0]);

          const { missingColumns, extraColumns } = this.validateColumns(
            expectedColumns,
            actualColumns
          );

          const columnErrors = this.buildColumnErrors(
            missingColumns,
            extraColumns
          );
          const rowErrors = this.validateRows(results.data);

          this.handleValidationResults(columnErrors, rowErrors, results);
        },
      });
    },
    validateColumns(expectedColumns, actualColumns) {
      const missingColumns = expectedColumns.filter(
        (column) => !actualColumns.includes(column)
      );
      const extraColumns = actualColumns.filter(
        (column) => !expectedColumns.includes(column)
      );

      return { missingColumns, extraColumns };
    },
    validateRows(data) {
      const rowErrors = [];
      data.forEach((row, i) => {
        const emptyFields = [];

        if (!row["First Name"] || row["First Name"].trim() === "") {
          emptyFields.push("First Name");
        }
        if (!row["Last Name"] || row["Last Name"].trim() === "") {
          emptyFields.push("Last Name");
        }
        if (!row["Email Address"] || row["Email Address"].trim() === "") {
          emptyFields.push("Email Address");
        }
        if (emptyFields.length > 0) {
          rowErrors.push(
            `Row ${i + 1} has missing data in: ${emptyFields.join(", ")}`
          );
        }
      });

      return rowErrors;
    },
    buildColumnErrors(missingColumns, extraColumns) {
      const columnErrors = [];

      if (extraColumns.length > 0 || missingColumns.length > 0) {
        columnErrors.push(`Error: Column name validation failed`);

        if (missingColumns.length > 0) {
          columnErrors.push(`Missing columns: ${missingColumns.join(", ")}`);
        }
        if (extraColumns.length > 0) {
          columnErrors.push(`Extra columns: ${extraColumns.join(", ")}`);
        }
      }

      return columnErrors;
    },
    handleValidationResults(columnErrors, rowErrors, results) {
      if (columnErrors.length > 0 || rowErrors.length > 0) {
        this.handleValidationErrors(columnErrors, rowErrors);
      } else {
        this.handleSuccessfulUpload(results);
      }
    },
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    },
    resetUploadState() {
      this.updateMessage.failed.show = false;
      this.updateMessage.successful.show = false;
      this.csvColumnErrors.isActive = false;
      this.csvRowErrors.isActive = false;
      this.content = [];
    },
    handleValidationErrors(columnErrors, rowErrors) {
      this.updateMessage.failed.show = true;
      this.csvColumnErrors.isActive = columnErrors.length > 0;
      this.csvColumnErrors.data = columnErrors;

      this.csvRowErrors.isActive = rowErrors.length > 0;
      this.csvRowErrors.data = rowErrors;
      // console.log("column errors: ", columnErrors);
      // console.log("row errors: ", rowErrors);
    },
    handleSuccessfulUpload(results) {
      this.updateMessage.successful.show = true;
      // Add the new key-value pair to each row
      results.data.forEach((row) => {
        row.id = this.generateUniqueId(); // Assign a unique ID
        row["Lifecycle Stage"] = "Unlisted Lead";
      });
      results.data.sort((a, b) =>
        a["First Name"].localeCompare(b["First Name"])
      );
      this.content = results;
      this.parsed = true;
    },
    updateProgress(percent) {
      this.importProgress = percent;
    },
    handleRetry(failedRecipients) {
      console.log("Retry logic to be implemented.", failedRecipients);
    },
    cancelImport() {
      this.updateMessage.successful.show = false;
      this.content = [];
      this.isBulkEditing = false;
      this.selectedRows = [];
      this.csvCount = 0;
      this.resetUploadState();
    },
    validateAndUploadContacts(contacts) {
      Papa.parse(Papa.unparse(contacts), {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const expectedColumns = ["First Name", "Last Name", "Email Address"];
          const actualColumns = Object.keys(results.data[0]);

          const { missingColumns, extraColumns } = this.validateColumns(
            expectedColumns,
            actualColumns
          );

          const columnErrors = this.buildColumnErrors(
            missingColumns,
            extraColumns
          );
          const rowErrors = this.validateRows(results.data);

          this.handleValidationResults(columnErrors, rowErrors, results);
        },
      });
    },

    /** handle success and errors */
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
    handleSuccess() {
      // console.log("recipients imported successfully! ", records);
      this.importMessage.successful.show = true;
    },
    handleError(error) {
      console.error("Error adding new recipient: ", error);
      this.updateMessage.failed.show = true;
      setTimeout(() => {
        this.updateMessage.failed.show = false;
      }, 5000);
      setTimeout(() => {
        this.updateFailed = false;
      }, 5000);
    },
    reloadPage() {
      this.importMessage.successful.show = false;
      this.$emit("reloadData");
    },
  },
};
</script>

<style scoped></style>
