<template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <!-- <PageHeader :showActions="true" title="Calendar Sync"> </PageHeader> -->
  <template v-if="!isFinishingLinking">

  <ThePage customWidthClass="tw-max-w-3xl tw-mx-auto">
    <PageSection class="tw-w-full tw-mt-20 tw-mx-auto">
      <div class="tw-mx-auto tw-lg:tw-max-w-7xl tw-px-6 tw-lg:px-8">
        <div
          class="tw-mx-auto tw-max-w-4xl tw-flex tw-justify-center tw-flex-col tw-text-center"
        >
          <img
            class="tw-h-12 tw-w-12 tw-flex-shrink-0 tw-mx-auto"
            src="../../assets/svg/brands/office365.png"
            alt=""
          />
          <h2
            class="tw-text-base tw-mt-2 tw-font-semibold tw-leading-7 tw-text-indigo-400 tw-uppercase"
          >
            calendar sync
          </h2>
          <p
            class="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-5xl"
          >
            Office365 integration
          </p>
          <p class="tw-text-xl tw-leading-8 tw-text-gray-700">
            Bold Xchange allows you to require a recipient to take book a
            meeting with your in order to accept their gift. Connect your
            Office365 account to have meetings booked on your calendar in
            real-time any time a lead accepts your e-gift terms.
          </p>
          <div class="tw-mx-auto">
            <template v-if="hasOffice365Tokens">
              <div
                class="tw-flex tw-gap-2 tw-items-center tw-text-lg tw-bg-white tw-rounded tw-p-4"
              >
                <b-icon-check-circle-fill
                  class="tw-text-green-700"
                ></b-icon-check-circle-fill>
                <p class="tw-m-0 tw-font-semibold tw-text-green-700">
                  Your account is connected
                </p>
              </div>
            </template>
            <template v-else>
              <div>
                <TheButton
                  :hasLeadingIcon="true"
                  variant="tertiary"
                  @click="startLinkingOffice365()"
                  size="large"
                  :disabled="connectingCalendar"
                >
                  <template #leading-icon>
                    <img
                      class="tw-h-6 tw-w-auto tw-flex-shrink-0"
                      src="../../assets/svg/brands/office365.png"
                      alt=""
                    />
                  </template>

                  <template #text>
                    <p class="tw-ml-4 tw-m-0">Connect to Office365</p>
                  </template>
                </TheButton>
              </div>
            </template>
          </div>
        </div>
      </div>
    </PageSection>
  </ThePage>
  </template>
</template>

<script>
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
export default {
  name: "Office365Integration",
  components: {
    TheButton,
    ThePage,
    PageSection,
    NotificationMessage,
  },
  async mounted() {
    const code = this.$route.query.code;
    console.log("code", code);
    if (code) {
      await this.finishLinkingOffice365(code);
    }
    await this.refreshClient();
  },
  data() {
    return {
      isFinishingLinking: false,
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    hasOffice365Tokens() {
      return this?.$store?.state?.auth?.user?.metadata?.office365?.tokens;
    },
  },
  methods: {
    async finishLinkingOffice365(code) {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */

        const api = await this.getBoldClient();
        this.isFinishingLinking = true;

        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/office365`,
          code: code,
        };

        const response = await api.finishLinkingOffice365(redirectUri);
        console.log("office365Response", response);
        // window.location.reload();
      } catch (error) {
        console.error("Failed to finish link office365", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isFinishingLinking = false;
        this.$router.push('/integrations')
      }
    },
    async startLinkingOffice365() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */

        const api = await this.getBoldClient();

        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/office365`,
        };

        const response = await api.startLinkingOffice365(redirectUri);
        console.log("office365Response", response);
        const office365UrlRedirect = response?.data?.url;
        window.location.href = office365UrlRedirect;
      } catch (error) {
        console.error("Failed to link office365", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isFinishingLinking = false;
      }
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
  },
};
</script>
<style scoped></style>
