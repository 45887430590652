<template>
  <template v-if="showNewRecipientModal">
    <Teleport to="#modal">
      <TheModal
        title="Creating new recipient"
        @closeModal="closeModal"
        :open="showNewRecipientModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <NewRecipientForm
          @closeModal="closeModal"
          @handleNewRecipient="handleNewContact"
        ></NewRecipientForm>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showRecipientDetailsModal">
    <Teleport to="#modal">
      <WideModal
        title="Recipient details"
        @closeModal="closeModal"
        :open="showRecipientDetailsModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <EditRecipientForm
          :campaignView="campaignView"
          :dashboardView="dashboardView"
          @closeModal="closeModal"
          @handleSuccess="handleSuccess()"
          :recipient="selectedRecipient"
        ></EditRecipientForm>
      </WideModal>
    </Teleport>
  </template>
  <template v-if="showDeleteModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        title="Delete selected recipients?"
        @closeModal="closeModal"
        :open="showDeleteModal"
      >
        <ModalBody>
          <DeleteData
            :recipients="idsToDelete"
            @closeModal="closeModal"
            @confirmDelete="confirmDelete()"
          ></DeleteData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showBulkEditModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        title="Bulk editing lifecycle stage"
        @closeModal="closeModal"
        :open="showBulkEditModal"
      >
        <ModalBody>
          <BulkEditData
            :recipients="recipientIdsToEdit"
            @closeModal="closeModal"
            @confirmBulkEdit="confirmBulkEdit"
          ></BulkEditData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="checkingIfUserHasRecipients">
    <div
      class="tw-flex tw-justify-center tw-items-center tw-w-full tw-max-w-5xl tw-mx-auto"
    >
      <PageSection :fullWidth="true">
        <PulseAnimation>
          <DataTableSkeleton></DataTableSkeleton>
        </PulseAnimation>
      </PageSection>
    </div>
  </template>
  <template v-else>
    <TheCard class="tw-w-full">
      <template v-if="userHasRecipients">
        <CardHeader
          :title="cardHeaderTitle"
          :showTitle="true"
          :showActions="true"
          :useSearchBar="!dashboardView && userHasRecipients ? true : false"
        >
          <template #card-search-bar>
            <div class="tw-max-w-xl tw-w-full">
              <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-full">
                <div
                  class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                >
                  <b-icon-search
                    class="tw-h-5 tw-w-5 tw-text-gray-400"
                    aria-hidden="true"
                  ></b-icon-search>
                </div>
                <input
                  @keyup.enter="handleSearchQuery()"
                  v-model="searchQuery.value"
                  type="text"
                  name="recipientSearch"
                  id="recipientSearch"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                  placeholder="Search recipient by name or email"
                />
                <template v-if="searchQuery.active">
                  <div
                    @click="resetFilters()"
                    class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-cursor-pointer"
                  >
                    <span>reset</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template #card-header-actions>
            <template v-if="dashboardView">
              <TheLink to="/recipients" :isRouterLink="true">
                <template #text>View all recipients</template>
              </TheLink>
            </template>
            <template
              v-else-if="
                campaignView &&
                userHasRecipients &&
                selectedRecipients.length === 0
              "
            >
              <div class="tw-flex tw-gap-2 tw-items-center">
                <DropdownMenu
                  :items="addOrImportDropdownItems()"
                  :hasLeadingIcon="true"
                  :buttonDropdown="true"
                  :buttonDropdownList="true"
                  buttonText="Add"
                  size="small"
                  variant="primary"
                >
                </DropdownMenu>

                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  variant="tertiary"
                  size="small"
                  @click="toggleListView()"
                >
                  <template #text>
                    <template v-if="viewLifecycleDistribution">
                      All recipients</template
                    >
                    <template
                      v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
                      >Lifecycle lists</template
                    >
                  </template>
                  <template #leading-icon>
                    <template v-if="viewLifecycleDistribution">
                      <b-icon-list></b-icon-list
                    ></template>
                    <template
                      v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
                      ><b-icon-grid></b-icon-grid
                    ></template>
                  </template>
                </TheButton>
              </div>
            </template>
            <template v-else>
              <template v-if="isBoldXchangeAdmin">
                <div class="tw-flex tw-bg-gray-100 tw-p-0.5 tw-rounded-md">
                  <TheButton
                    type="button"
                    :variant="
                      viewMyRecordsOnly === false ? 'secondary' : 'ghost'
                    "
                    size="regular"
                    @click="setRecordsUserRecordsToView(false)"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon>
                      <b-icon-people></b-icon-people>
                    </template>
                    <template #text>Customer recipients</template>
                  </TheButton>
                  <TheButton
                    type="button"
                    :variant="
                      viewMyRecordsOnly === true ? 'secondary' : 'ghost'
                    "
                    size="regular"
                    @click="setRecordsUserRecordsToView(true)"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon>
                      <b-icon-person></b-icon-person>
                    </template>
                    <template #text>My recipients</template>
                  </TheButton>
                </div>
              </template>
              <TheButton
                type="button"
                :hasLeadingIcon="true"
                variant="tertiary"
                size="regular"
                @click="toggleListView()"
              >
                <template #text>
                  <template v-if="viewLifecycleDistribution">
                    List all</template
                  >
                  <template
                    v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
                    >View Lists</template
                  >
                </template>
                <template #leading-icon>
                  <template v-if="viewLifecycleDistribution">
                    <b-icon-list></b-icon-list
                  ></template>
                  <template
                    v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
                    ><b-icon-grid></b-icon-grid
                  ></template>
                </template>
              </TheButton>
            </template>
          </template>
        </CardHeader>
      </template>
      <CardBody>
        <template v-if="recipientsAreLoading">
          <PulseAnimation>
            <DataTableSkeleton></DataTableSkeleton>
          </PulseAnimation>
        </template>
        <template v-else>
          <!-- if userHasRecipients show recipient list -->
          <template v-if="userHasRecipients && count">
            <template v-if="viewLifecycleDistribution">
              <div class="tw-overflow-y-auto tw-w-full">
                <table
                  class="tw-min-w-full tw-divide-stone-300 tw-sticky tw-top tw-z-1"
                >
                  <thead
                    class="tw-sticky tw-top-0 tw-z-10 tw-border-b tw-border-stone-300"
                  >
                    <!-- life -->
                    <tr>
                      <th
                        scope="col"
                        class="tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Lifecycle Stage
                      </th>
                      <th
                        scope="col"
                        class="tw-text-right tw-px-3 tw-py-3.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Recipients
                      </th>
                    </tr>
                  </thead>
                  <tbody class="tw-divide-y tw-divide-gray-200 tw-bg-white">
                    <tr
                      v-for="stage in lifecycleStages"
                      :key="stage.lifecycleStage"
                    >
                      <td class="tw-py-4 tw-px-3">
                        <TheLink
                          variant="tertiary"
                          @click="handleQueryByLifecycle(stage.lifecycleStage)"
                        >
                          <template #text>
                            {{ `${stage.lifecycleStage}s` }}
                          </template>
                          <template #leading-icon
                            ><b-icon-plus-lg></b-icon-plus-lg>
                          </template>
                        </TheLink>
                      </td>
                      <td
                        class="tw-text-right tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-flex tw-items-center tw-justify-end tw-gap-2"
                      >
                        <p class="tw-m-0 tw-text-sm tw-font-bold">
                          {{ stage.count }}
                        </p>
                        <b-icon-people></b-icon-people>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template
              v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
            >
              <div
                class="tw-overflow-y-auto tw-min-h-[500px] tw-max-h-[500px] tw-w-full"
              >
                <template v-if="!dashboardView">
                  <div
                    class="tw-flex tw-justify-between gap-4 tw-h-12 tw-px-3 tw-bg-stone-100 tw-sticky tw-top-0 tw-z-40 tw-items-center tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b tw-border-stone-300"
                  >
                    <div>
                      <template v-if="isBoldXchangeAdmin && !viewMyRecordsOnly">
                        <p
                          class="tw-m-0 tw-text-sm tw-font-bold tw-text-red-600"
                        >
                          You are currently viewing customer recipients
                        </p>
                      </template>
                      <template v-if="viewMyRecordsOnly">
                        <input
                          id="selectAllRecipients"
                          name="selectAllRecipients"
                          type="checkbox"
                          class="tw-left-4 tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-me-2"
                          :checked="
                            selectedRecipients.length === recipients.length
                          "
                          @change="selectAllRecipients($event.target.checked)"
                        /><label
                          class="tw-text-gray-900"
                          for="selectAllRecipients"
                          >Select all visible</label
                        >
                      </template>
                    </div>
                    <template
                      v-if="
                        selectedRecipients.length > 0 &&
                        !campaignView &&
                        viewMyRecordsOnly
                      "
                    >
                      <div class="tw-flex tw-gap-4 tw-items-center">
                        <p
                          class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600"
                        >
                          {{ selectedRecipients.length }} selected
                        </p>
                        <DropdownMenu
                          :disabled="selectedRecipients.length === 0"
                          :items="bulkDropdownItems()"
                          :buttonDropdown="true"
                          :buttonDropdownList="true"
                          buttonText="Bulk actions"
                        >
                        </DropdownMenu>
                      </div>
                    </template>
                    <template
                      v-if="selectedRecipients.length > 0 && campaignView"
                    >
                      <div class="tw-flex tw-gap-4 tw-items-center">
                        <template v-if="campaignRecipientsSelectionUpdated">
                          <p
                            class="tw-m-0 tw-text-sm tw-font-bold tw-text-green-600"
                          >
                            <b-icon-check-lg></b-icon-check-lg> Recipient list
                            updated
                          </p>
                        </template>
                        <template v-else>
                          <p
                            class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600"
                          >
                            {{ selectedRecipients.length }} selected
                          </p>
                        </template>
                        <TheButton
                          :disabled="this.selectedRecipients.length === 0"
                          variant="success"
                          @click="setCampaignRecipients()"
                          size="small"
                          :hasLeadingIcon="true"
                        >
                          <template #leading-icon
                            ><b-icon-check-lg></b-icon-check-lg
                          ></template>
                          <template #text>Add selected recipients</template>
                        </TheButton>
                      </div>
                    </template>
                  </div>
                </template>
                <table class="tw-min-w-full tw-divide-stone-300">
                  <thead
                    class="tw-sticky tw-z-10 tw-border-b tw-border-stone-300"
                    :class="{ 'tw-top-12': !dashboardView }"
                  >
                    <tr>
                      <th
                        scope="col"
                        class="tw-min-w-20 tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Email
                      </th>
                      <template v-if="!dashboardView">
                        <th
                          scope="col"
                          class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                        >
                          Phone number
                        </th>
                        <th
                          scope="col"
                          class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                        >
                          Company
                        </th>
                      </template>

                      <th
                        scope="col"
                        class="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Lifecycle Stage
                      </th>
                      <template v-if="!dashboardView">
                        <th
                          scope="col"
                          class="tw-px-3 tw-py-3.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                        >
                          <span class="tw-sr-only">Edit</span>
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody
                    class="tw-divide-y tw-divide-gray-200 tw-bg-white tw-min-h-[500px]"
                  >
                    <tr
                      v-for="recipient in recipientData"
                      :key="recipient.id"
                      :class="[
                        selectedRecipients.includes(recipient.id) &&
                          'bg-gray-50',
                      ]"
                    >
                      <template v-if="isBoldXchangeAdmin && !viewMyRecordsOnly">
                        <td class="tw-px-3 tw-py-4">
                          <div class="tw-flex tw-items-center gap-2">
                            <TheLink
                              variant="tertiary"
                              @click="toggleRecipientDetailsModal(recipient)"
                            >
                              <template #text>
                                <template
                                  v-if="
                                    recipient?.firstName && recipient?.lastName
                                  "
                                >
                                  {{
                                    recipient?.firstName +
                                    " " +
                                    recipient?.lastName
                                  }}
                                </template>
                                <template v-else>N/A</template>
                              </template>
                              <template #leading-icon
                                ><b-icon-plus-lg></b-icon-plus-lg>
                              </template>
                            </TheLink>
                            <div class="sm:tw-hidden tw-flex tw-flex-col">
                              <p class="tw-p-0 tw-m-0 tw-text-sm">
                                {{ recipient?.contact?.email || "---" }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </template>
                      <template v-if="viewMyRecordsOnly">
                        <td class="tw-relative tw-px-3 tw-py-4">
                          <div
                            v-if="isRecipientSelected(recipient.id)"
                            class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-blue-600"
                          ></div>

                          <div class="tw-flex tw-items-center gap-2">
                            <template v-if="!dashboardView">
                              <input
                                :disabled="!viewMyRecordsOnly"
                                type="checkbox"
                                class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-cursor-pointer"
                                :checked="isRecipientSelected(recipient.id)"
                                @change="toggleRecipientSelection(recipient)"
                              />
                            </template>
                            <TheLink
                              variant="tertiary"
                              @click="toggleRecipientDetailsModal(recipient)"
                            >
                              <template #text>
                                <template
                                  v-if="
                                    recipient?.firstName && recipient?.lastName
                                  "
                                >
                                  {{
                                    recipient?.firstName +
                                    " " +
                                    recipient?.lastName
                                  }}
                                </template>
                                <template v-else>N/A</template>
                              </template>
                              <template #leading-icon
                                ><b-icon-plus-lg></b-icon-plus-lg>
                              </template>
                            </TheLink>
                            <div class="sm:tw-hidden tw-flex tw-flex-col">
                              <p class="tw-p-0 tw-m-0 tw-text-sm">
                                {{ recipient?.contact?.email || "---" }}
                              </p>
                            </div>
                          </div>
                        </td>
                      </template>
                      <td
                        class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                      >
                        {{ recipient?.contact?.email || "---" }}
                      </td>

                      <template v-if="!dashboardView">
                        <td
                          class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                        >
                          {{ recipient?.contact?.phone || "---" }}
                        </td>
                        <td
                          class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                        >
                          {{ recipient?.contact?.company || "---" }}
                        </td>
                      </template>

                      <td
                        class="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                      >
                        {{
                          recipient?.contact?.metadata?.lifecycleStage || "---"
                        }}
                      </td>

                      <template v-if="!dashboardView">
                        <td
                          class="tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-3"
                        >
                          <div class="tw-flex tw-justify-end">
                            <template v-if="!campaignView">
                              <template v-if="viewMyRecordsOnly">
                                <div class="tw-flex tw-justify-end">
                                  <IconDropdown
                                    :id="recipient._id"
                                    :items="getDropdownItems(recipient)"
                                    :disabled="false"
                                    variant="tertiary"
                                  >
                                    <template #icon>
                                      <b-icon-three-dots-vertical />
                                    </template>
                                  </IconDropdown>
                                </div>
                              </template>
                              <!-- <template v-else>FALSE</template> -->
                            </template>
                          </div>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </template>
          <!-- if userHasRecipients AND query count is 0 show recipient message -->
          <template v-if="userHasRecipients && !count">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
            >
              <template v-if="searchQuery.active">
                <img
                  style="max-height: 150px; width: auto"
                  src="../../assets/svg/illustrations-test/14_no_query_results.png"
                  alt="Image Description"
                />
              </template>
              <template v-else>
                <img
                  style="max-height: 150px; width: auto"
                  src="../../assets/svg/illustrations-test/46_blank_page.png"
                  alt="Image Description"
                />
              </template>

              <template v-if="activeStage && searchQuery.active">
                <h1 class="tw-text-2xl tw-m-0">
                  No exact matches found in
                  <span class="tw-text-indigo-600">{{ activeStage }}s</span>
                </h1>
                <p class="tw-m-0 tw-text-lg tw-max-w-xl">
                  Adjust your search, or manage your list by either adding new
                  recipients or moving existing ones into this one.
                </p>
              </template>
              <template v-else-if="activeStage && !searchQuery.active">
                <h1 class="tw-text-2xl tw-m-0">
                  Your
                  <span class="tw-text-indigo-600">{{
                    `${activeStage}s`
                  }}</span>
                  list is empty
                </h1>
                <p class="tw-m-0 tw-text-lg">
                  Add new recipients or move existing ones into this one.
                </p>
              </template>
              <template v-else>
                <h1 class="tw-text-2xl tw-m-0">No exact matches found</h1>
                <p class="tw-m-0 tw-text-lg tw-max-w-xl">
                  Adjust your search, or manage your list by either adding new
                  recipients or moving existing ones into this one.
                </p>
              </template>
            </div>
          </template>
          <!-- if !userHasRecipients show message -->
          <template v-if="!userHasRecipients">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px]"
            >
              <EmptyState emptyState="recipients"></EmptyState>
              <div class="tw-flex tw-flex-col tw-gap-4 tw-items-center">
                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  variant="primary"
                  size="large"
                  @click="toggleNewRecipientModal"
                >
                  <template #text>Add recipient</template>
                  <template #leading-icon
                    ><b-icon-plus-lg></b-icon-plus-lg
                  ></template>
                </TheButton>
                <TheLink
                  type="button"
                  variant="tertiary"
                  size="large"
                  :isRouterLink="true"
                  to="/recipients/import"
                >
                  <template #text>Import contacts</template>
                </TheLink>
              </div>
            </div>
          </template>
        </template>
      </CardBody>
      <template
        v-if="userHasRecipients && !dashboardView && !viewLifecycleDistribution"
      >
        <CardFooter>
          <!-- Pagination -->
          <nav
            class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200"
            aria-label="Pagination"
          >
            <div class="tw-hidden sm:tw-block">
              <p class="tw-text-sm tw-text-gray-700 tw-m-0">
                Showing
                <span class="tw-font-bold"
                  >{{ this.recipientData.length }} of {{ count }}</span
                >
                records
              </p>
            </div>
            <div
              class="tw-flex tw-flex-1 tw-justify-between tw-gap-x-3 sm:tw-justify-end"
            >
              <TheButton
                class="disabled:tw-bg-slate-200 disabled:tw-cursor-auto"
                :disabled="
                  this.currentPage === 1 || this.selectedRecipients.length > 0
                "
                @click="previousPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-left></b-icon-chevron-left
                ></template>
              </TheButton>
              <TheButton
                class="disabled:tw-bg-slate-200"
                :disabled="
                  this.isLastPage || this.selectedRecipients.length > 0
                "
                @click="nextPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-right></b-icon-chevron-right
                ></template>
              </TheButton>
            </div>
          </nav>
        </CardFooter>
      </template>
    </TheCard>
  </template>
</template>

<script>
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader.vue";
import CardBody from "../../components-v2/ui/layout/CardBody.vue";
import CardFooter from "../../components-v2/ui/layout/CardFooter.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import WideModal from "../../components-v2/ui/modals/WideModal.vue";
import NewRecipientForm from "../../components-v2/ui/modals/NewRecipientForm.vue";
import EditRecipientForm from "../../components-v2/ui/modals/EditRecipientForm.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import DropdownMenu from "../../components-v2/ui/forms/DropdownMenu.vue";
import DeleteData from "../ui/modals/DeleteData.vue";
import BulkEditData from "../ui/modals/BulkEditData.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import DataTableSkeleton from "../../components-v2/ui/skeletons/DataTableSkeleton.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import IconDropdown from "../../components-v2/ui/elements/dropdown/IconDropdown.vue";

import {
  // listRecipients,
  deleteRecipients,
  // bulkUpdateRecipients,
} from "@/services/recipientService";

export default {
  name: "RecipientsIndexTableV2",
  components: {
    IconDropdown,
    BulkEditData,
    DeleteData,
    NewRecipientForm,
    EditRecipientForm,
    TheModal,
    DropdownMenu,
    TheLink,
    EmptyState,
    CardBody,
    TheCard,
    CardHeader,
    TheButton,
    CardFooter,
    PageSection,
    DataTableSkeleton,
    PulseAnimation,
    ModalBody,
    WideModal,
  },
  emits: [
    "setCampaignRecipients",
    "update:isModalVisible",
  ],
  props: {
    campaignView: {
      type: Boolean,
      default: false,
    },
    campaignId: {
      type: String,
    },
    dashboardView: {
      type: Boolean,
      default: false,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    sharedCampaignData: {
      type: Array,
      default: () => [],
    },
    totalRecipientsCount: {
      type: Number,
      default: 1,
      required: false,
    },
    campaignRecipients: {
      type: Array,
    },
    viewCampaignRecipientsSelection: {
      type: Boolean,
    },
  },
  watch: {
    isModalVisible(newVal) {
      if (newVal) {
        this.toggleNewRecipientModal();
      }
    },
  },
  created() {
    /** load data */
    if (!this.dashboardView) {
      this.checkForRecipientData();
    } else {
      this.recipientsAreLoading = false;
      this.checkingIfUserHasRecipients = false;
      if (this.totalRecipientsCount) {
        this.userHasRecipients = true;
      }
      this.viewLifecycleDistribution = false;
      this.viewAllRecipients = true;
      this.currentListView = "All recipients";
      this.count = this.totalRecipientsCount;
    }
  },
  data() {
    return {
      newRecipient: {},
      selectedRecipient: {},
      lifecycleStages: [
        { lifecycleStage: "Center of Influence", count: 0 },
        { lifecycleStage: "Customer", count: 0 },
        { lifecycleStage: "Warm Lead", count: 0 },
        { lifecycleStage: "Cold Lead", count: 0 },
        { lifecycleStage: "Unlisted Lead", count: 0 },
      ],
      recipients: [],
      selectedRecipients: [],
      viewMyRecordsOnly: true,
      showNewRecipientModal: false,
      showRecipientDetailsModal: false,
      recipientsAreLoading: false,
      isAddingRecipient: false,
      userHasRecipients: false,
      checkingIfUserHasRecipients: false,
      recipientIdsToDelete: null,
      recipientIdsToEdit: null,
      recipientIsReadyToSave: false,
      showDeleteModal: false,
      showBulkEditModal: false,
      viewLifecycleDistribution: true,
      viewAllRecipients: false,
      viewRecipientsByLifecycleStage: false,
      campaignRecipientsSelectionUpdated: false,
      count: 0,
      pageSize: 25,
      currentPage: 1,
      activeStage: "",
      currentListView: "Lifecycle lists",
      // searchQuery: "",
      searchQuery: {
        active: false,
        value: "",
      },
      sortedValue: "firstName",
    };
  },
  computed: {
    cardHeaderTitle() {
      if (this.dashboardView) {
        return "Recipients";
      } else if (this.activeStage === "") {
        return this.currentListView;
      } else {
        return `${this.activeStage}s`;
      }
    },
    recipientData() {
      return this.dashboardView ? this.sharedCampaignData : this.recipients;
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.recipients.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    userClient() {
      return this?.$store?.state?.auth?.user?.client;
    },
    recipientGiftingLead() {
      return (recipient) => {
        if (
          recipient?.metadata?.giftingLeads &&
          recipient?.metadata?.giftingLeads[0] ===
            this?.$store?.state?.auth?.user?._id
        ) {
          return true;
        }
      };
    },
    idsToEdit() {
      return this.recipientIdsToEdit.map((recipient) => recipient._id);
    },
    idsToDelete() {
      return this.recipientIdsToDelete.map((recipient) => recipient._id);
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** handle deleting selected recipients */
    async confirmDelete() {
      try {
        const response = await deleteRecipients(this.idsToDelete);
        this.handleSuccess(response, "deleteRecipients");
      } catch (error) {
        console.log("recipient was NOT deleted", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your delete request. Please try again."
        );
      } finally {
        this.showDeleteModal = false;
      }
    },
    toggleDeleteModal(recipient) {
      this.selectedRecipients.push(recipient);
      this.recipientIdsToDelete = this.selectedRecipients;
      this.showDeleteModal = !this.showDeleteModal;
    },
    handleBulkDelete(recipients) {
      this.recipientIdsToDelete = recipients;
      this.showDeleteModal = !this.showDeleteModal;
    },

    /** handle viewing and editing recipient details */
    toggleRecipientDetailsModal(recipient) {
      this.selectedRecipient = recipient;
      this.showRecipientDetailsModal = !this.showRecipientDetailsModal;
    },

    /** handle bulk editing recipients */
    async confirmBulkEdit(lifecycleStageSelection) {
      // Import the Client type for better IntelliSense and type-checking
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      try {
        const patchObject = {
          "contact.metadata.lifecycleStage": lifecycleStageSelection,
        };
        const response = await api.bulkUpdateRecipients(null, {
          filter: { _id: { $in: this.idsToEdit } },
          patch: patchObject,
        });
        this.handleSuccess(response, "bulkUpdateRecipients");
      } catch (error) {
        console.log("recipient was NOT edited", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your edit request. Please try again."
        );
      } finally {
        this.showBulkEditModal = false;
      }
    },
    handleBulkEdit(recipients) {
      this.recipientIdsToEdit = recipients;
      this.showBulkEditModal = !this.showBulkEditModal;
    },
    closeModal() {
      this.showDeleteModal = false;
      this.showNewRecipientModal = false;
      this.showRecipientDetailsModal = false;
      (this.showBulkEditModal = false),
        this.$emit("update:isModalVisible", false);
    },

    /** handle recipient selection */
    selectAllRecipients(isChecked) {
      this.selectedRecipients = isChecked ? this.recipients.slice() : [];
    },
    isRecipientSelected(recipientId) {
      return this.selectedRecipients.some((r) => r.id === recipientId);
    },
    toggleRecipientSelection(recipient) {
      const index = this.selectedRecipients.findIndex(
        (r) => r.id === recipient.id
      );
      if (index > -1) {
        this.selectedRecipients.splice(index, 1);
      } else {
        this.selectedRecipients.push(recipient);
      }
    },

    /** load data */
    async checkForRecipientData(page) {
      this.checkingIfUserHasRecipients = true;
      try {
        await this.checkIfUserHasRecipients(page);
        if (this.count) {
          await Promise.all([
            this.loadWarmLeads(),
            this.loadColdLeads(),
            this.loadCenterOfInfluenceLeads(),
            this.loadCustomerLeads(),
            this.loadUnlistedLeads(),
          ]);
        }
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please reload the page and try again."
        );
      } finally {
        this.checkingIfUserHasRecipients = false;
      }
    },
    async checkIfUserHasRecipients(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.currentPage = page === undefined ? this.currentPage : page;
      this.checkingIfUserHasRecipients = true;

      let requestBody = {};
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.viewMyRecordsOnly && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(!this.viewMyRecordsOnly && {
          "metadata.giftingLeads": { $ne: [this.userId] },
        }),
      };

      if (this.searchQuery.value !== "") {
        filter.$or = [
          { firstName: { $regex: this.searchQuery.value } },
          { lastName: { $regex: this.searchQuery.value } },
          { "contact.email": { $regex: this.searchQuery.value } },
          { "contact.phone": { $regex: this.searchQuery.value } },
          { "contact.company": { $regex: this.searchQuery.value } },
          { "contact.title": { $regex: this.searchQuery.value } },
          {
            "contact.metadata.lifecycleStage": {
              $regex: this.searchQuery.value,
            },
          },
        ];
      }
      let selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);

        const count = response.data.result.count;
        this.count = response.data.result.count;
        this.userHasRecipients = count;
        this.recipients = response.data.result.records;
        this.checkingIfUserHasRecipients = false;
      } catch (error) {
        this.checkingIfUserHasRecipients = false;
        console.log("recipients did not load", error);
      }
    },
    /** handle loading recipient count by lifecycle stage */
    async loadWarmLeads() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      const lifecycleStage = "Warm Lead";
      let requestBody = {};
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.viewMyRecordsOnly && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(!this.viewMyRecordsOnly && {
          "metadata.giftingLeads": { $ne: [this.userId] },
        }),
        "contact.metadata.lifecycleStage": { $regex: lifecycleStage },
      };
      let selection = "firstName lastName contact metadata";

      const queryParameters = {
        limit: 1,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      const response = await api.listRecipients(queryParameters, requestBody);
      const count = response.data.result.count;
      this.warmLeadsCount = count;
      this.updateLifecycleCount(this.lifecycleStages, lifecycleStage, count);
    },
    async loadColdLeads() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      const lifecycleStage = "Cold Lead";
      let requestBody = {};
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        "contact.metadata.lifecycleStage": { $regex: lifecycleStage },
      };

      let selection = "firstName lastName contact metadata";

      const queryParameters = {
        limit: 1,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      const response = await api.listRecipients(queryParameters, requestBody);
      const count = response.data.result.count;
      this.coldLeadsCount = count;
      this.updateLifecycleCount(this.lifecycleStages, lifecycleStage, count);
    },
    async loadCenterOfInfluenceLeads() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      const lifecycleStage = "Center of Influence";
      let requestBody = {};
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        "contact.metadata.lifecycleStage": { $regex: lifecycleStage },
      };
      let selection = "firstName lastName contact metadata";

      const queryParameters = {
        limit: 1,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      const response = await api.listRecipients(queryParameters, requestBody);
      const count = response.data.result.count;
      this.centerOfInfluenceLeadsCount = count;
      this.updateLifecycleCount(this.lifecycleStages, lifecycleStage, count);
    },
    async loadCustomerLeads() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      const lifecycleStage = "Customer";
      let requestBody = {};
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        "contact.metadata.lifecycleStage": { $regex: lifecycleStage },
      };
      let selection = "firstName lastName contact metadata";

      const queryParameters = {
        limit: 1,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      const response = await api.listRecipients(queryParameters, requestBody);
      const count = response.data.result.count;
      this.customerLeadsCount = count;
      this.updateLifecycleCount(this.lifecycleStages, lifecycleStage, count);
    },
    async loadUnlistedLeads() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      const lifecycleStage = "Unlisted Lead";
      let requestBody = {};
      let filter = {};
      filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
        $or: [
          {
            "contact.metadata.lifecycleStage": { $regex: lifecycleStage },
          },
          { "contact.metadata.lifecycleStage": { $exists: false } },
        ],
      };

      const selection = "firstName lastName contact metadata";

      const queryParameters = {
        limit: 1,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      const response = await api.listRecipients(queryParameters, requestBody);
      const count = response.data.result.count;
      this.unlistedLeadsCount = count;
      this.updateLifecycleCount(this.lifecycleStages, lifecycleStage, count);
    },

    /** handle updating lifecycle stages counts */
    updateLifecycleCount(lifecycleStages, stageName, count) {
      const stepIndex = lifecycleStages.findIndex(
        (stage) => stage.lifecycleStage === stageName
      );
      if (stepIndex !== -1) {
        lifecycleStages[stepIndex].count = count;
      }
    },

    /** paginate list of recipients */
    async nextPage() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.recipientsAreLoading = true;
      this.currentPage++;

      let requestBody = {};
      let filter = {};
      filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
      };

      const lifecycleStageFilter = this.activeStage
        ? this.activeStage === "Unlisted Lead"
          ? {
              $or: [
                {
                  "contact.metadata.lifecycleStage": {
                    $regex: this.activeStage,
                  },
                },
                { "contact.metadata.lifecycleStage": { $exists: false } },
              ],
            }
          : { "contact.metadata.lifecycleStage": { $regex: this.activeStage } }
        : {};

      const searchQueryFilter = this.searchQuery.value
        ? {
            $or: [
              { firstName: { $regex: this.searchQuery.value } },
              { lastName: { $regex: this.searchQuery.value } },
              { "contact.email": { $regex: this.searchQuery.value } },
            ],
          }
        : {};

      filter = {
        ...filter,
        ...lifecycleStageFilter,
        ...searchQueryFilter,
      };
      const selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);

        this.recipients = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please try again."
        );
      } finally {
        this.recipientsAreLoading = false;
      }
    },
    async previousPage() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.recipientsAreLoading = true;
      this.currentPage--;

      let requestBody = {};
      let filter = {};
      filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
      };

      const lifecycleStageFilter = this.activeStage
        ? this.activeStage === "Unlisted Lead"
          ? {
              $or: [
                {
                  "contact.metadata.lifecycleStage": {
                    $regex: this.activeStage,
                  },
                },
                { "contact.metadata.lifecycleStage": { $exists: false } },
              ],
            }
          : { "contact.metadata.lifecycleStage": { $regex: this.activeStage } }
        : {};

      const searchQueryFilter = this.searchQuery.value
        ? {
            $or: [
              { firstName: { $regex: this.searchQuery.value } },
              { lastName: { $regex: this.searchQuery.value } },
              { "contact.email": { $regex: this.searchQuery.value } },
            ],
          }
        : {};

      filter = {
        ...filter,
        ...lifecycleStageFilter,
        ...searchQueryFilter,
      };
      const selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);

        this.recipients = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please try again."
        );
      } finally {
        this.recipientsAreLoading = false;
      }
    },

    /** handle listing recipients by lifecycle stage */
    handleQueryByLifecycle(lifecycleStage) {
      this.viewLifecycleDistribution = false;
      this.viewRecipientsByLifecycleStage = true;
      this.activeStage = lifecycleStage;
      this.listRecipientsByLifecycleStage(lifecycleStage);
    },
    async listRecipientsByLifecycleStage(lifecycleStage) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.recipientsAreLoading = true;
      let requestBody = {};
      let filter = {};
      filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
      };

      const lifecycleStageFilter = this.activeStage
        ? this.activeStage === "Unlisted Lead"
          ? {
              $or: [
                {
                  "contact.metadata.lifecycleStage": {
                    $regex: this.activeStage,
                  },
                },
                { "contact.metadata.lifecycleStage": { $exists: false } },
              ],
            }
          : { "contact.metadata.lifecycleStage": { $regex: this.activeStage } }
        : {};

      filter = { ...filter, ...lifecycleStageFilter };
      const selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);
        this.recipients = response.data.result.records;
        const count = response.data.result.count;
        this.count = count;
        this.updateLifecycleCount(this.lifecycleStages, lifecycleStage, count);
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please reload the page and try again."
        );
      } finally {
        this.recipientsAreLoading = false;
      }
    },
    /** handle resetting recipient list */
    resetFilters() {
      this.searchQuery.value = "";
      this.searchQuery.active = false;
      this.currentPage = 1;
      this.loadRecipients();
    },
    async loadRecipients(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.recipientsAreLoading = true;

      this.currentPage = page === undefined ? this.currentPage : page;

      let requestBody = {};
      let filter = {};
      filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
      };

      let lifecycleStageFilter = {};
      if (this.activeStage && this.activeStage === "Unlisted Lead") {
        lifecycleStageFilter = {
          $or: [
            {
              "contact.metadata.lifecycleStage": {
                $regex: this.activeStage,
              },
            },
            { "contact.metadata.lifecycleStage": { $exists: false } },
          ],
        };
      }
      if (this.activeStage && this.activeStage !== "Unlisted Lead") {
        lifecycleStageFilter = {
          "contact.metadata.lifecycleStage": { $regex: this.activeStage },
        };
      }

      const searchQueryFilter = this.searchQuery.value
        ? {
            $or: [
              { firstName: { $regex: this.searchQuery.value } },
              { lastName: { $regex: this.searchQuery.value } },
              { "contact.email": { $regex: this.searchQuery.value } },
            ],
          }
        : {};

      filter = {
        ...filter,
        ...lifecycleStageFilter,
        ...searchQueryFilter,
      };
      const selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);

        this.recipientsAreLoading = false;
        this.recipients = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        this.recipientsAreLoading = false;
        console.log("recipients did not load", error);
      }
    },

    /**handle listing recipients by search query*/
    handleSearchQuery() {
      this.searchQuery.active = true;
      if (this.viewLifecycleDistribution) {
        this.getRecipientsBySearchQuery();
        this.viewLifecycleDistribution = false;
        this.viewAllRecipients = true;
      } else if (this.viewRecipientsByLifecycleStage && this.activeStage) {
        this.getRecipientsBySearchQuery();
      } else {
        this.getRecipientsBySearchQuery();
      }
    },
    createSearchQuery() {
      if (this.searchQuery.value === "" && this.activeStage === "") return {};

      const searchValues = this.searchQuery.value.split(" ");
      const userFields = ["firstName", "lastName", "contact.email"];

      const searchConditions = searchValues.map((value) => ({
        $or: userFields.map((field) => ({
          [field]: { $regex: value, $options: "i" },
        })),
      }));

      return {
        ...searchConditions[0],
        ...(this.activeStage && {
          "contact.metadata.lifecycleStage": { $regex: this.activeStage },
        }),
        // ...(this.isBoldXchangeAdmin &&
        //   this.viewMyRecordsOnly && {
        //     "metadata.giftingLeads": { $in: [this.userId] },
        //   }),
      };
    },
    async getRecipientsBySearchQuery(page) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.recipientsAreLoading = true;
      this.currentPage = page === undefined ? 1 : page;

      let requestBody = {};
      let filter = {};
      filter = {
        ...(!this.isBoldXchangeAdmin && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        ...(this.isBoldXchangeAdmin &&
          !this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $ne: [this.userId] },
          }),
        ...(this.isBoldXchangeAdmin &&
          this.viewMyRecordsOnly && {
            "metadata.giftingLeads": { $in: [this.userId] },
          }),
      };
      let query = this.createSearchQuery();

      filter = { ...filter, ...query };

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      let selection = "firstName lastName contact metadata";

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);
        this.recipients = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please try again."
        );
      } finally {
        this.recipientsAreLoading = false;
      }
    },

    /** handle adding recipient to campaign */
    handleSelectContact(recipient) {
      this.isAddingRecipient = false;
    },
    setCampaignRecipients() {
      this.$emit("setCampaignRecipients", this.selectedRecipients);
      this.selectedRecipients = [];
      this.campaignRecipientsSelectionUpdated = true;
      setTimeout(() => {
        this.campaignRecipientsSelectionUpdated = false;
      }, 4000);
    },

    /** dropdown menu items and actions */
    getDropdownItems(recipient) {
      this.selectedRecipient = recipient;
      return [
        {
          label: "Edit",
          isDeleteAction: false,
          action: () => this.toggleRecipientDetailsModal(recipient),
        },
        {
          label: "Delete",
          isDeleteAction: true,
          action: () => this.toggleDeleteModal(recipient),
        },
      ];
    },
    bulkDropdownItems() {
      return [
        {
          label: "Update lifecycle stage",
          isDeleteAction: false,
          action: () => this.handleBulkEdit(this.selectedRecipients),
        },
        {
          label: "Delete",
          isDeleteAction: true, // This item is a delete action
          action: () => this.handleBulkDelete(this.selectedRecipients),
        },
      ];
    },
    addOrImportDropdownItems() {
      return [
        {
          label: "Add one recipient",
          isDeleteAction: false,
          action: () => this.toggleNewRecipientModal(),
        },
        {
          label: "Import recipients",
          isRouterLink: true,
          to: `/recipients/import/${this.campaignId}`,
        },
      ];
    },
    setRecordsUserRecordsToView(value) {
      this.selectedRecipients = [];
      this.viewMyRecordsOnly = value;
      this.searchQuery.value = "";
      this.searchQuery.active = false;
      this.checkForRecipientData();
    },

    reloadList() {
      const page = this.currentPage;
      if (this.searchQuery.value) {
        this.handleSearchQuery();
      } else if (this.activeStage) {
        this.listRecipientsByLifecycleStage(this.activeStage);
      } else {
        this.checkForRecipientData(page);
      }
    },

    /** handle different table views */
    toggleListView() {
      if (this.viewLifecycleDistribution) {
        this.viewLifecycleDistribution = false;
        this.viewAllRecipients = true;
        this.currentListView = "All recipients";
        this.resetFilters();
      } else if (this.viewAllRecipients) {
        this.currentListView = "Lifecycle lists";
        this.viewLifecycleDistribution = true;
        this.viewAllRecipients = false;
        this.checkForRecipientData();
      } else {
        this.viewRecipientsByLifecycleStage = false;
        this.viewLifecycleDistribution = true;
        this.checkForRecipientData();
        // this.resetFilters();
      }
      // this.resetFilters();
      this.selectedRecipients = [];
      this.activeStage = "";
      this.searchQuery.value = "";
      this.searchQuery.active = false;
    },

    /** quick edits */
    activateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to true
      this.$store.dispatch("orders/activateQuickEdit");
    },
    deactivateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to false
      this.$store.dispatch("orders/deactivateQuickEdit");
    },

    /** modals */
    toggleNewRecipientModal() {
      this.showNewRecipientModal = !this.showNewRecipientModal;
    },
    handleNewContact(recipient) {
      this.showNewRecipientModal = false;
      this.handleSuccess(recipient, "handleNewRecipient");
    },

    /** handle success and errors */
    handleSuccess(response, api) {
      if (this.campaignView) {
        this.$emit("setCampaignRecipients", [response]);
        this.reloadList();
        return;
      }

      if (api === "handleNewRecipient") {
        let newRecipientEmail = response.contact.email;
        this.searchQuery.value = newRecipientEmail;
        this.searchQuery.active = true;
        this.viewLifecycleDistribution = false;
        this.viewAllRecipients = true;
        this.userHasRecipients = true;
        this.getRecipientsBySearchQuery();
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Recipient successfully created.",
          duration: 4000,
          id: Date.now(),
        });
      } else if (api === "deleteRecipients") {
        let res = response;
        this.selectedRecipients = [];
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Selected recipients were successfully deleted.",
          duration: 4000,
          id: Date.now(),
        });
        this.loadRecipients();
      } else if (api === "bulkUpdateRecipients") {
        let res = response;
        this.selectedRecipients = [];
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Selected recipients were successfully edited.",
          duration: 4000,
          id: Date.now(),
        });
        this.loadRecipients();
      } else {
        this.showNewRecipientModal = false;
        this.loadRecipients();
      }
    },

    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error?.response) {
        // Process API error message if customMessage is not provided
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      // this.reloadList();
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>
