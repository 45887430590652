// store/modules/notifications/index.js
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  notification: {
    visible: false,
    type: null, // 'success' or 'error'
    message: '',
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
