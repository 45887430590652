<template>
  <!-- //TheBox -->
  <div :class="componentClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TheBox",
  components: {},
  props: {
    withPadding: {
      type: Boolean,
      default: false,
    },
    noVerticalPadding: {
      type: Boolean,
      default: false,
    },
    noHorizontalPadding: {
      type: Boolean,
      default: false,
    },
    noTopPadding: {
      type: Boolean,
      default: false,
    },
    noBottomPadding: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
  },
  computed: {
    componentClass() {
      return {
        "tw-p-4 sm:tw-p-6": this.withPadding,
        "tw-px-4 sm:tw-px-6 tw-py-0": this.noVerticalPadding,
        "tw-py-4 sm:tw-py-6 tw-px-0": this.noHorizontalPadding,
        "tw-px-4 tw-pb-4 sm:tw-px-6 sm:tw-pb-6 tw-pt-0": this.noTopPadding,
        "tw-px-4 tw-pt-4 sm:tw-px-6 sm:tw-pt-6 tw-pb-0": this.noBottomPadding,

        // Add customWidth class if customWidth is provided
        [this.customClass]: !!this.customClass,
      };
    },
  },
};
</script>
