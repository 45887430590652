<template>
  <div class="content container-fluid">
    <ImportedVendorList></ImportedVendorList>
  </div>
</template>

<script>
import ImportedVendorList from "../../components-v2/vendors/ImportedVendorList.vue";

export default {
  name: "ImportVendors",
  components: {
    ImportedVendorList,
  },
};
</script>

<style scoped></style>
