// src/mixins/userMixin.js

export default {
  data() {
    return {
      userMixinData: {
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        jobTitle: "",
        metadata: {
          profileSettings: {
            displayName: "",
            aboutDescription: "",
            websiteUrl: "",
          },
          campaignSettings: {
            giftCardAmount: {
              value: 0,
              applyToNewCampaigns: false,
            },
            homeRegions: [],
          },
          landingPageSettings: {
            giftNote: {
              title: "",
              message: "",
              applyToNewCampaigns: false,
            },
            signature: {
              displayName: {
                value: "",
                show: false,
              },
              jobTitle: {
                value: "",
                show: false,
              },
              organizationName: {
                value: "",
                show: false,
              },
              accountEmail: {
                value: "",
                show: false,
              },
              websiteUrl: {
                value: "",
                show: false,
              },
              profilePhoto: {
                value: "",
                show: false,
              },
              aboutDescription: {
                value: "",
                show: false,
              },
            },
            organizationLogo: {
              value: "",
              show: false,
            },
          },
        },
      },
      newProfilePhotoMediaFiles: [],
      newOrganizationLogoMediaFiles: [],
      existingProfilePhotoMediaFiles: [],
      existingOrganizationLogoMediaFiles: [],
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
  },
  methods: {
    async refreshUser() {
      await this.$store.dispatch("auth/refreshUser");
      // await this.loadUserProfileMedia();
      this.initializeForm(this.User);
    },

    initializeForm(user) {
      this.userMixinData.firstName = user?.firstName || "";
      this.userMixinData.lastName = user?.lastName || "";
      this.userMixinData.email = user?.email || "";
      this.userMixinData.company = user?.company || "";
      this.userMixinData.jobTitle = user?.jobTitle || "";

      /** PROFILE settings */
      this.userMixinData.metadata.profileSettings.displayName =
        user?.metadata?.profileSettings?.displayName ||
        `${user?.firstName} ${user?.lastName}`;
      this.userMixinData.metadata.profileSettings.aboutDescription =
        user?.metadata?.profileSettings?.aboutDescription || "";
      this.userMixinData.metadata.profileSettings.websiteUrl =
        user?.metadata?.profileSettings?.websiteUrl || "";

      /** CAMPAIGN settings */
      this.userMixinData.metadata.campaignSettings.giftCardAmount.value =
        user?.metadata?.campaignSettings?.giftCardAmount?.value || 0;
      this.userMixinData.metadata.campaignSettings.giftCardAmount.applyToNewCampaigns =
        user?.metadata?.campaignSettings?.giftCardAmount?.applyToNewCampaigns ||
        false;
      this.userMixinData.metadata.campaignSettings.homeRegions =
        user?.metadata?.campaignSettings?.homeRegions ||
        user?.metadata?.homeRegions ||
        [];

      /** landing page LOGO settings */
      this.userMixinData.metadata.landingPageSettings.organizationLogo.value =
        user?.metadata?.landingPageSettings?.organizationLogo?.value || "";
      this.userMixinData.metadata.landingPageSettings.organizationLogo.show =
        user?.metadata?.landingPageSettings?.organizationLogo?.show || false;

      /** landing page SIGNATURE settings */
      this.userMixinData.metadata.landingPageSettings.signature = {
        displayName: {
          value:
            user?.metadata?.landingPageSettings?.signature?.displayName
              ?.value || `${user?.firstName} ${user?.lastName}`,
          show:
            user?.metadata?.landingPageSettings?.signature?.displayName?.show ||
            false,
        },
        jobTitle: {
          value:
            user?.metadata?.landingPageSettings?.signature?.jobTitle?.value ||
            "",
          show:
            user?.metadata?.landingPageSettings?.signature?.jobTitle?.show ||
            false,
        },
        organizationName: {
          value:
            user?.metadata?.landingPageSettings?.signature?.organizationName
              ?.value || "",
          show:
            user?.metadata?.landingPageSettings?.signature?.organizationName
              ?.show || false,
        },
        accountEmail: {
          value:
            user?.metadata?.landingPageSettings?.signature?.accountEmail
              ?.value || user?.email,
          show:
            user?.metadata?.landingPageSettings?.signature?.accountEmail
              ?.show || false,
        },
        websiteUrl: {
          value:
            user?.metadata?.landingPageSettings?.signature?.websiteUrl?.value ||
            "",
          show:
            user?.metadata?.landingPageSettings?.signature?.websiteUrl?.show ||
            false,
        },
        profilePhoto: {
          value:
            user?.metadata?.landingPageSettings?.signature?.profilePhoto
              ?.value || "",
          show:
            user?.metadata?.landingPageSettings?.signature?.profilePhoto
              ?.show || false,
        },
        aboutDescription: {
          value:
            user?.metadata?.landingPageSettings?.signature?.aboutDescription
              ?.value || "",
          show:
            user?.metadata?.landingPageSettings?.signature?.aboutDescription
              ?.show || false,
        },
      };
    },
  },
  async created() {
    await this.refreshUser();
  },
};
