<template>
  <template v-if="showNewRecipientModal">
    <Teleport to="#modal">
      <TheModal
        title="Creating new recipient"
        @closeModal="closeModal"
        :open="showNewRecipientModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <NewRecipientForm
          :group="group"
          @closeModal="closeModal"
          @handleNewRecipient="handleNewRecipient"
        ></NewRecipientForm>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showImportRecipientsModal">
    <Teleport to="#modal">
      <WideModal
        title="Importing recipients"
        @closeModal="toggleRecipientsImportModal()"
        :open="showImportRecipientsModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <ImportData
          :userId="userId"
          :group="group"
          :existingGroupMemberIds="existingGroupMemberIds"
          @reloadData="handleReloadingData"
        ></ImportData>
      </WideModal>
    </Teleport>
  </template>
  <template v-if="showDeleteModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        :title="
          currentTab === 1
            ? 'Delete selected groups?'
            : 'Delete selected segments'
        "
        @closeModal="closeModal"
        :open="showDeleteModal"
      >
        <ModalBody>
          <template v-if="currentTab === 1">
            <DeleteData
              :groups="[groupId]"
              @closeModal="closeModal"
              @confirmDelete="confirmDelete()"
            ></DeleteData>
          </template>
          <template v-if="currentTab === 2">
            <DeleteData
              :segments="[groupId]"
              @closeModal="closeModal"
              @confirmDelete="confirmDelete()"
            ></DeleteData>
          </template>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <PageHeader
    headingBadge="group"
    :showStrapline="true"
    :showActions="true"
    :showSubheading="true"
    :title="groupName"
  >
    <template #strapline-content>
      <TheLink variant="primary" size="small" :isRouterLink="true" to="/groups">
        <template #text><b-icon-arrow-left />All Groups</template>
      </TheLink>
    </template>
    <template #page-header-actions>
      <ButtonDropdown
        :id="'button-dropdown-recipients'"
        :items="dropdownActionItems()"
        buttonText="Manage group"
        :disabled="false"
        :active="false"
        :hasLeadingIcon="false"
        :hasTrailingIcon="true"
        size="large"
        variant="primary"
      />
    </template>
    <template #subheading-content>
      <div>
        <div class="sm:tw-hidden">
          <label for="tabs" class="tw-sr-only">Select a tab</label>
          <select
            v-model="currentTab"
            id="tabs"
            name="tabs"
            class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
          >
            <option
              v-for="tab in tabs"
              :key="tab.id"
              :selected="tab.id"
              :value="tab.id"
            >
              {{ tab.name }}
            </option>
          </select>
        </div>
        <div class="tw-hidden sm:tw-block">
          <div
            class="tw-border-b-2 tw-border-gray-200 tw-border-solid tw-border-t-0 tw-border-x-0"
          >
            <nav class="tw-flex tw-items-center tw-space-x-8" aria-label="Tabs">
              <a
                v-for="tab in tabs"
                :key="tab.name"
                @click.prevent="handleTabChange(tab.id)"
                :class="[
                  currentTab === tab.id
                    ? 'tw-border-blue-700 tw-text-blue-800 tw-border-solid tw-border-2 tw-border-t-0 tw-border-x-0 tw-cursor-pointer'
                    : ' tw-text-gray-800 hover:tw-border-blue-700 hover:tw-border-solid hover:tw-border-x-0 hover:tw-border-t-0 hover:tw-text-blue-800 tw-cursor-pointer',
                  'tw-whitespace-nowrap tw-border-2  tw-pt-1 tw-px-1 tw-text-lg tw-font-medium tw-cursor-pointer',
                ]"
              >
                <template v-if="tab.name === 'Recipients'">
                  {{ tab.name }} ({{
                    isLoadingGroup ? "..." : groupRecipientsCount
                  }})</template
                >
                <template v-else> {{ tab.name }}</template>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </template>
  </PageHeader>

  <ThePage customWidthClass="tw-w-full tw-max-w-8xl">
    <!-- <BlockStack :gap="500" class="tw-max-w-8xl tw-w-full"> -->
    <template v-if="currentTab === 1">
      <template v-if="!groupRecipientsCount">
        <PageSection class="tw-w-full tw-max-w-3xl tw-mx-auto">
          <TheCard class="tw-w-full">
            <TheBox :withPadding="true">
              <EmptyState emptyState="groupRecipients"></EmptyState>
              <BlockStack :gap="400" class="tw-mx-auto tw-max-w-xs">
                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  :hasTrailingIcon="true"
                  variant="tertiary"
                  size="large"
                  @click="toggleRecipientsImportModal()"
                >
                  <template #text>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-grow"
                    >
                      <span>Import CSV</span>
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </div>
                  </template>
                  <template #leading-icon
                    ><b-icon-upload></b-icon-upload
                  ></template>
                </TheButton>
                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  :hasTrailingIcon="true"
                  variant="tertiary"
                  size="large"
                  @click="toggleNewRecipientModal"
                >
                  <template #text>
                    <div
                      class="tw-flex tw-items-center tw-justify-between tw-grow"
                    >
                      <span>Create new recipient</span>
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </div>
                  </template>
                  <template #leading-icon
                    ><b-icon-plus-lg></b-icon-plus-lg
                  ></template>
                </TheButton>
              </BlockStack>
            </TheBox>
          </TheCard>
        </PageSection>
      </template>
      <template v-else>
        <RecipientProfilesTable
          :existingGroupMemberIds="existingGroupMemberIds"
          :recipientsGroupDetailsView="true"
          :isModalVisible="isModalVisible"
          :groupId="groupId"
          @update:isModalVisible="closeModal"
          @reloadData="handleReloadingData()"
          @updateGroupRecipientsCount="updateGroupRecipientsCount"
        ></RecipientProfilesTable>
      </template>
      <!-- group: {{ group }} -->
    </template>
    <template v-if="currentTab === 2">
      <RecipientsGroupSettings
        :group="group"
        @reloadData="checkIfUserHasGroups()"
      ></RecipientsGroupSettings>
    </template>
    <!-- </BlockStack> -->
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import RecipientProfilesTable from "../../components-v2/recipients/RecipientProfilesTable";
import ButtonDropdown from "../../components-v2/ui/elements/dropdown/ButtonDropdown.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import WideModal from "../../components-v2/ui/modals/WideModal.vue";
import ImportData from "../../components-v2/ui/modals/ImportData.vue";
import RecipientsGroupSettings from "../../components-v2/groups/RecipientsGroupSettings.vue";
import NewRecipientForm from "../../components-v2/ui/modals/NewRecipientForm.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import DeleteData from "../../components-v2/ui/modals/DeleteData.vue";

export default {
  name: "RecipientGroupDetails",
  emits: [""],
  components: {
    DeleteData,
    ModalBody,
    TheModal,
    NewRecipientForm,
    RecipientsGroupSettings,
    TheLink,
    TheBox,
    TheCard,
    PageSection,
    EmptyState,
    TheButton,
    BlockStack,
    ButtonDropdown,
    ThePage,
    PageHeader,
    RecipientProfilesTable,
    ImportData,
    WideModal,
  },
  created() {
    this.checkIfUserHasGroups();
  },
  data() {
    return {
      group: {},
      tabs: [
        { id: 1, name: "Recipients" },
        { id: 2, name: "Settings" },
        // { id: 3, name: "Settings" },
      ],
      currentTab: 1,
      isModalVisible: false,
      showImportRecipientsModal: false,
      showNewRecipientModal: false,
      showDeleteModal: false,
      isLoadingGroup: true,
      groupRecipientsCount: 1,
    };
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    groupId() {
      return this.$route.params.groupId;
    },
    existingGroupMemberIds() {
      return this?.group?.members?.map((recipient) => recipient.doc);
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    groupName() {
      return this?.group?.metadata?.name || "---";
    },
  },
  methods: {
    async checkIfUserHasGroups() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      this.isLoadingGroup = true;
      const api = await this.getBoldClient();
      let requestBody = {};
      let filter = {
        model: "Recipient",
        _id: this.groupId,
        "metadata.admins": { $eq: this.userId },
      };

      // let selection = "metadata";

      const queryParameters = {
        // skip: (this.currentPage - 1) * this.pageSize,
        limit: 1,
        // sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);

        // const count = response.data.result.count;
        // this.count = response.data.result.count;
        // this.userHasRecipients = count;
        this.group = response.data.result.records[0];
        // this.checkingIfUserHasRecipients = false;
      } catch (error) {
        this.checkingIfUserHasRecipients = false;
        console.log("recipients did not load", error);
      } finally {
        this.isLoadingGroup = false;
      }
    },
    updateGroupRecipientsCount(count) {
      this.groupRecipientsCount = count;
    },
    async upsertGroup(recipient) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      const newGroupMemberIds = [recipient._id];
      const recipientsIds = this.combineIds(
        this.existingGroupMemberIds,
        newGroupMemberIds
      );
      const groupPatch = {
        members: recipientsIds,
      };

      try {
        const response = await api.upsertGroup(null, {
          filter: { _id: this.groupId },
          patch: groupPatch,
        });
        this.handleSuccess(response, "upsertGroup");
      } catch (error) {
        this.handleError(error);
        console.log("group was NOT upserted", error);
      }
    },
    async confirmDelete() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      try {
        const deleteMethod =
          this.currentTab === 1 ? "deleteGroups" : "deleteSegments";
        const response = await api[deleteMethod](null, {
          filter: { _id: this.groupId },
        });

        console.log("delete method: ", deleteMethod);
        console.log("this.idsToDelete: ", this.idsToDelete);

        this.handleSuccess(response, deleteMethod);
      } catch (error) {
        console.log("data was NOT deleted", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your delete request. Please try again."
        );
      } finally {
        this.showDeleteModal = false;
      }
    },
    combineIds(existingGroupMemberIds, newGroupMemberIds) {
      // Combine both arrays and create a Set to keep only unique values
      const combinedUniqueIds = [
        ...new Set([...existingGroupMemberIds, ...newGroupMemberIds]),
      ];

      // Map the unique IDs to the specified structure
      return combinedUniqueIds.map((id) => ({
        doc: id,
        metadata: {},
      }));
    },
    handleReloadingData() {
      this.showImportRecipientsModal = false;
      this.updateGroupRecipientsCount(1);
      this.checkIfUserHasGroups();
    },
    /** other */
    toggleRecipientsImportModal() {
      this.showImportRecipientsModal = !this.showImportRecipientsModal;
    },
    dropdownActionItems() {
      return [
        {
          label: "Import CSV",
          sublabel: "Import new contacts directly into this group",
          isDeleteAction: false,
          action: () => this.toggleRecipientsImportModal(),
        },
        {
          label: "Create new recipient",
          sublabel: "Add a new contact directly into this group",
          action: () => this.toggleNewRecipientModal(),
        },
        // {
        //   label: "Add from recipient profiles list",
        //   // sublabel: "Dynamic group based on defined properties",
        //   action: () => this.toggleNewGroupModal(),
        // },
        {
          label: "Delete",
          isDeleteAction: true,

          action: () => this.toggleDeleteModal(),
        },
      ];
    },

    toggleNewRecipientModal() {
      this.showNewRecipientModal = !this.showNewRecipientModal;
    },
    toggleNewGroupModal() {
      // alert();
      this.isModalVisible = !this.isModalVisible;
    },
    toggleDeleteModal(dataType, dataId) {
      if (dataType === "group") {
        this.groupIdsToDelete = [dataId];
      }
      if (dataType === "segment") {
        this.segmentIdsToDelete = [dataId];
      }
      this.showDeleteModal = !this.showDeleteModal;
    },
    closeModal() {
      this.isModalVisible = false;
      this.showNewRecipientModal = false;
      this.showDeleteModal = false;
    },
    async handleNewRecipient(recipient) {
      await this.upsertGroup(recipient);
      this.closeModal();
    },

    handleTabChange(tabId) {
      this.currentTab = tabId;
    },

    handleSuccess(response, api) {
      if (api === "upsertGroup") {
        let res = response;
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Group was successfully edited.",
          duration: 4000,
          id: Date.now(),
        });
        this.handleReloadingData();
      } else {
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Group was successfully deleted.",
          duration: 4000,
          id: Date.now(),
        });
        this.$router.push("/groups");
      }
      // upsertGroup
    },
  },
};
</script>

<style scoped></style>
