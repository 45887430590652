<template>
  <template v-if="!subscriptionsAreLoading && payments.length > 0">
    <TheCard>
      <CardHeader
        :showTitle="true"
        title="Billing history"
        :showSubheading="true"
      >
        <template #subheading-content
          >Billing is processed and handled by Stripe, Inc.
        </template>
      </CardHeader>
      <TheBox :noTopPadding="true">
        <div
          class="tw-mt-0 tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-gray-100 tw-border-t tw-border-gray-200 tw-text-sm tw-leading-6"
        >
          <SubscriptionsHistoryListItem
            v-for="payment in payments"
            :key="payment.index"
            :payment="payment"
          >
          </SubscriptionsHistoryListItem>
        </div>
      </TheBox>
    </TheCard>
  </template>
</template>

<script>
import TheCard from "../../components-v2/ui/layout/TheCard";
import CardHeader from "../../components-v2/ui/layout/CardHeader";
import TheBox from "../../components-v2/ui/layout/TheBox";

import SubscriptionsHistoryListItem from "./SubscriptionsHistoryListItem.vue";

export default {
  name: "SubscriptionsHistoryList",
  components: {
    SubscriptionsHistoryListItem,
    TheCard,
    CardHeader,
    TheBox,
  },
  data() {
    return {
      payments: [],
      subscriptions: [],
      count: 0,
      subscriptionsAreLoading: false,
    };
  },
  async created() {
    this.getSubscriptionInfo();
    // this.loadCampaigns();
  },
  computed: {
    User() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async getSubscriptionInfo() {
      this.subscriptionsAreLoading = true;
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      try {
        const subscriptionRes = await api.getSubscriptionInfo();
        this.subscriptions = subscriptionRes.data.subscriptions;
        this.payments = subscriptionRes?.data?.subscriptions[0]?.payments || [];
        this.subscriptionsAreLoading = false;

        // return paymentInformation;
      } catch (error) {
        console.log("getSubscriptionInfo error: ", error);
        console.error("Stack trace for getSubscriptionInfo");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
