// recipientService.js
import { getBoldClient } from "@/api/getBoldClient.js";
import store from "../store/index";

const listRecipients = async (queryParameters, requestBody) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);

  let apiRequestBody = {
    filter: requestBody.filter,
  };

  // Conditionally add selection and/or group if they are provided
  if (requestBody.selection) {
    apiRequestBody.selection = requestBody.selection;
  }
  if (requestBody.group) {
    apiRequestBody.group = requestBody.group;
  }
  return api.listRecipients(queryParameters, requestBody);
};

const listSingleRecipientById = async (recipientId) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);

  const queryParameters = {
    limit: 1,
    expand: "contact",
  };

  const requestBody = {
    filter: { _id: recipientId },
  };


  return api.listRecipients(queryParameters, requestBody);
};

const upsertRecipient = async (recipientId, patchObject) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);
  return api.upsertRecipient(null, {
    filter: { _id: recipientId },
    patch: patchObject,
  });
};

const deleteRecipients = async (recipientIds) => {
  const token = store.state.auth.token;

  const api = await getBoldClient(token);
  return api.deleteRecipients(null, {
    filter: { _id: { $in: recipientIds } },
  });
};

const bulkUpdateRecipients = async (recipientIds, patchObject) => {
  const token = store.state.auth.token;

  const api = await getBoldClient(token);
  return api.bulkUpdateRecipients(null, {
    filter: { _id: { $in: recipientIds } },
    patch: patchObject,
  });
};

export {
  listSingleRecipientById,
  listRecipients,
  upsertRecipient,
  deleteRecipients,
  bulkUpdateRecipients,
};
