<template>
  <BlockStack :gap="800" :fullWidth="true">
    <PageSection title="Overview">
      <DashboardStatsSkeleton :numberOfCards="3"></DashboardStatsSkeleton>
    </PageSection>
    <PageSection class="tw-w-full">
      <div class="tw-flex-1 tw-bg-white tw-rounded tw-p-4">
        <DataTableSkeleton :numberOfRows="5"></DataTableSkeleton>
      </div>
    </PageSection>
    <PageSection title="Campaign Details" class="tw-w-full">
      <div class="tw-flex-1 tw-bg-white tw-rounded tw-p-4">
        <DataTableSkeleton :numberOfRows="5"></DataTableSkeleton>
      </div>
    </PageSection>
  </BlockStack>
</template>

<script>
import PageSection from "../../../../components-v2/ui/layout/PageSection.vue";
import BlockStack from "../../../../components-v2/ui/layout/BlockStack.vue";
import DashboardStatsSkeleton from "../../../../components-v2/ui/skeletons/DashboardStatsSkeleton.vue";
import DataTableSkeleton from "../../../../components-v2/ui/skeletons/DataTableSkeleton.vue";
export default {
  name: "CampaignDetailsPageSkeleton",
  components: {
    PageSection,
    DashboardStatsSkeleton,
    DataTableSkeleton,
    BlockStack,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
