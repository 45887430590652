<template>
    <div class="tw-p-4  tw-w-full tw-bg-gray-50">
        <slot></slot>
    </div>
</template>
  
<script>


export default {
    name: 'CardFooter',
    components: {  },

    props: {
        textAlign: {
            type: String,
            default: 'start'
        },
        height: {
            type: [Number, Object],
            default: null
        },
        id: {
            type: String,
            default: ''
        },
        role: {
            default: null
        },
        padding: {
            type: [Number, Object],
            default: 200
        },
        backgroundColor: {
            type: String,
            default: 'none'
        },
        width: {
            type: [Number, Object],
            default: 100
        }
    },
    computed: {
        componentStyles() {
            return {
                width: typeof this.width === 'number' ? `${this.width}%` : 'auto',
            };
        }
    }
};
</script>
  