<template>
  <!-- container -->
  <ContentBox class="tw-p-2 sm:tw-p-4">
    <!-- inline stack -->
    <InlineStack>
      <!-- page title + subcontent -->
      <BlockStack :gap="300">
        <!-- Profile -->
        <BlockStack :gap="100" class="">
          <!-- strapline -->
          <template v-if="showStrapline">
            <slot name="strapline-content"> </slot>
          </template>
          <!-- heading -->
          <div class="tw-flex tw-items-center tw-gap-2">
            <h1
              class="tw-m-0 tw-text-xl tw-font-bold sm:tw-truncate sm:tw-text-2xl sm:tw-tracking-tight"
            >
              {{ title }}
            </h1>
            <template v-if="headingBadge">
              <div>
                <span
                  class="tw-rounded tw-bg-gray-100 tw-px-1 tw-py-1 tw-text-sm tw-text-gray-800 tw-capitalize"
                >
                  {{ headingBadge }}</span
                >
              </div></template
            >
          </div>
          <!-- subheading -->
          <template v-if="showSubheading">
            <div>
              <slot name="subheading-content"> </slot>
            </div>
          </template>
        </BlockStack>
      </BlockStack>
      <template v-if="showActions">
        <div class="tw-flex tw-flex-wrap tw-gap-2">
          <slot name="page-header-actions"></slot>
        </div>
      </template>
    </InlineStack>
  </ContentBox>
</template>

<script>
import InlineStack from "./InlineStack.vue";
import BlockStack from "./BlockStack.vue";
import ContentBox from "./ContentBox.vue";

export default {
  name: "PageHeader",
  components: { BlockStack, InlineStack, ContentBox },
  props: {
    id: {
      type: String,
      default: "",
    },
    role: {
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    /**
     * False by default
     * Makes actions slot accessible when true
     */
    showActions: {
      type: Boolean,
      default: false,
    },
    /**
     * False by default
     * Makes subheading content including text or badge
     */
    showSubheading: {
      type: Boolean,
      default: false,
    },
    showStrapline: {
      type: Boolean,
      default: false,
    },
    headingBadge: {
      type: String,
      default: null,
    },
  },
  computed: {},
};
</script>
