<template>
  <div v-if="notificationData" :class="notificationClass">
    <component
      :is="iconComponent"
      class="tw-text-lg"
      :class="iconColor"
    ></component>
    <p class="tw-text-sm tw-m-0 tw-text-start" :class="textColor">
      {{ notificationData?.message }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    scope: {
      type: String,
      required: true,
    },
  },
  computed: {
    scopedNotifications() {
      // Use the component's name as the scope to fetch relevant notifications
      return this.$store.getters["alerts/scopedAlerts"](this.scope) || [];
    },
    notificationData() {
      // If multiple notifications could be shown, you might need to decide how to handle them.
      // For simplicity, this example takes the first notification. Adjust based on your UI logic.
      return this?.scopedNotifications?.length > 0
        ? this.scopedNotifications[0]
        : null;
    },

    notificationClass() {
      return [
        "tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-items-center tw-gap-4 tw-w-full tw-mx-auto",
        {
          "tw-border-green-400 tw-bg-green-50":
            this.notificationData.type ===
            this.$store.state.alerts.alertTypes.SUCCESS,
          "tw-border-red-400 tw-bg-red-50":
            this.notificationData.type ===
            this.$store.state.alerts.alertTypes.ERROR,
        },
      ];
    },
    iconComponent() {
      return this.notificationData.type ===
        this.$store.state.alerts.alertTypes.SUCCESS
        ? "b-icon-check-circle"
        : "b-icon-exclamation-circle";
    },
    iconColor() {
      return {
        "tw-text-green-700":
          this.notificationData.type ===
          this.$store.state.alerts.alertTypes.SUCCESS,
        "tw-text-red-700":
          this.notificationData.type ===
          this.$store.state.alerts.alertTypes.ERROR,
      };
    },
    textColor() {
      return {
        "tw-text-green-700":
          this.notificationData.type ===
          this.$store.state.alerts.alertTypes.SUCCESS,
        "tw-text-red-700":
          this.notificationData.type ===
          this.$store.state.alerts.alertTypes.ERROR,
      };
    },
  },
};
</script>
