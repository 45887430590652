<template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <PageHeader :showActions="true" title="Campaign templates">
    <template #page-header-actions>
      <TheButton
        :hasLeadingIcon="true"
        variant="primary"
        @click="createCampaignTemplateDraft()"
        size="large"
        :disabled="creatingCampaignTemplateDraft"
      >
        <template #text>
          <template v-if="creatingCampaignTemplateDraft"
            >Creating campaign</template
          >
          <template v-else>Build template</template>
        </template>
        <template #leading-icon>
          <template v-if="creatingCampaignTemplateDraft">
            <div role="status">
              <svg
                aria-hidden="true"
                class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="tw-sr-only">Loading...</span>
            </div>
          </template>
          <template v-else><b-icon-plus-lg></b-icon-plus-lg></template>
        </template>
      </TheButton>
    </template>
  </PageHeader>
  <ThePage customWidthClass="tw-w-full tw-mx-auto tw-gap-5">
    <BlockStack :gap="500" class="tw-max-w-8xl tw-w-full">
      <div>
        <div class="sm:tw-hidden">
          <label for="tabs" class="tw-sr-only">Select a tab</label>
          <select
            v-model="currentTab"
            id="tabs"
            name="tabs"
            class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
          >
            <option
              v-for="tab in tabs"
              :key="tab.id"
              :selected="tab.id"
              :value="tab.id"
            >
              {{ tab.name }}
            </option>
          </select>
        </div>
        <div class="tw-hidden sm:tw-block">
          <div
            class="tw-border-b-3 tw-border-gray-200 tw-border-solid tw-border-t-0 tw-border-x-0"
          >
            <nav class="tw-flex tw-items-center tw-space-x-8" aria-label="Tabs">
              <a
                v-for="tab in tabs"
                :key="tab.name"
                @click.prevent="handleTabChange(tab.id)"
                :class="[
                  currentTab === tab.id
                    ? 'tw-border-blue-700 tw-text-blue-800 tw-border-solid tw-border-2 tw-border-t-0 tw-border-x-0 tw-cursor-pointer'
                    : ' tw-text-gray-500 hover:tw-border-gray-300 hover:tw-border-solid hover:tw-border-x-0 hover:tw-border-t-0 hover:tw-text-gray-700 tw-cursor-pointer',
                  'tw-whitespace-nowrap tw-border-2 tw-border-b-5 tw-py-4 tw-px-1 tw-text-xl tw-font-medium tw-cursor-pointer',
                ]"
                >{{ tab.name }}</a
              >
            </nav>
          </div>
        </div>
      </div>

      <template v-if="currentTab === 1">
        <BlockStack :gap="1000" class="tw-w-full">
          <CampaignTemplatesList :boldTemplates="true"></CampaignTemplatesList>
        </BlockStack>
      </template>
      <template v-if="currentTab === 2">
        <PageSection class="tw-w-full">
          <CampaignTemplatesList :savedTemplates="true"></CampaignTemplatesList>
        </PageSection>
      </template>
      <template v-if="currentTab === 3">
        <PageSection class="tw-w-full">
          <CampaignTemplatesList :draftTemplates="true"></CampaignTemplatesList>
        </PageSection>
      </template>
    </BlockStack>
  </ThePage>
</template>

<script>
import CampaignTemplatesList from "../../components-v2/templates/CampaignTemplatesList";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";

export default {
  name: "ViewTemplates",
  components: {
    CampaignTemplatesList,
    BlockStack,
    TheButton,
    ThePage,
    PageHeader,
    PageSection,
    NotificationMessage,
  },
  data() {
    return {
      tabs: [
        { id: 1, name: "Bold Xchange Templates" },
        { id: 2, name: "Saved Templates" },
        { id: 3, name: "Drafts" },
      ],
      giftCardAmounts: [
        { type: "giftCardAmount", value: 5 },
        { type: "giftCardAmount", value: 10 },
        { type: "giftCardAmount", value: 25 },
        { type: "giftCardAmount", value: 50 },
        { type: "giftCardAmount", value: 75 },
        { type: "giftCardAmount", value: 100 },
        { type: "giftCardAmount", value: 150 },
      ],
      categories: [
        { type: "category", name: "Wine & Spirits", value: "Wine & Spirits" },
        { type: "category", name: "Fine Dining", value: "Fine Dining" },
        { type: "category", name: "Casual Dining", value: "Casual Dining" },
        {
          type: "category",
          name: "Coffee & Cafes",
          value: ["Coffee Shop", "Coffee", "Cafe"],
        },
        {
          type: "category",
          name: "Ice Cream & Desserts",
          value: ["Ice Cream", "Desserts", "Bakery"],
        },
      ],
      selectedCategoryValue: [],
      selectedNeighborhoods: [],
      neighborhoods: [],
      currentTab: 1,
      savedTemplates: 0,
      draftTemplates: 0,
      campaignId: null,
      templateSelection: null,
      hoverGiftCardAmount: null,
      hoverCategory: null,
      hoverNeighborhood: null,
      showFilterModal: false,
      loadingCategories: false,
      hover: false,
      creatingCampaignTemplateDraft: false,
    };
  },
  async created() {
    await this.$store.dispatch("auth/refreshUser");
    this.performTabSpecificLogic();
    // this.getAllVendorCategories();
    // this.getAllVendorNeighborhoods();
  },
  computed: {
    user() {
      return this?.$store?.state?.auth?.user;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** handle retrieving categories and neighborhoods */
    async getAllVendorCategories() {
      try {
        this.loadingCategories = true;
        const api = await this.getBoldClient();
        const categoriesRes = await api.listVendors({
          distinct: "categories",
          expand: "metadata",
        });
        const categories = categoriesRes.data.result.records;
        this.categories = categories;
      } catch (error) {
        this.handleErrorFromAPI(error);
      } finally {
        this.loadingCategories = false;
      }
    },
    async getAllVendorNeighborhoods() {
      try {
        this.loadingCategories = true;
        const api = await this.getBoldClient();
        const categoriesRes = await api.listVendors({
          distinct: "metadata.neighborhoods",
          expand: "metadata",
        });
        const neighborhoods = categoriesRes.data.result.records;
        const newNeighs = [];
        for (const neighborhood of neighborhoods) {
          newNeighs.push({ type: "neighborhood", value: neighborhood });
        }
        this.neighborhoods = newNeighs;
      } catch (error) {
        this.handleErrorFromAPI(error);
      } finally {
        this.loadingCategories = false;
      }
    },
    async createCampaignTemplateDraft() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      const api = await this.getBoldClient();
      this.creatingCampaignTemplateDraft = true;

      try {
        const user = this?.user;

        const fromEmail = user?.email;
        const fromName = `${user?.firstName} ${user?.lastName}`;
        const requestBody = {
          tabName: "Full collection",
          filter: {
            $or: [
              {
                categories: {
                  $in: [
                    "Wine & Spirits",
                    "Fine Dining",
                    "Casual Dining",
                    "Coffee Shop",
                    "Coffee",
                    "Cafe",
                    "Ice Cream",
                    "Desserts",
                    "Bakery",
                  ],
                },
              },
              {
                "metadata.tags": {
                  $in: [
                    "Wine & Spirits",
                    "Fine Dining",
                    "Casual Dining",
                    "Coffee Shop",
                    "Coffee",
                    "Cafe",
                    "Ice Cream",
                    "Desserts",
                    "Bakery",
                  ],
                },
              },
            ],
            $and: [
              {
                $or: [
                  { "metadata.isOnline": { $exists: false } },
                  { "metadata.isOnline": true },
                ],
              },
            ],
          },
        };

        const campaignData = {
          from: { email: fromEmail, name: fromName },
          subject: "",
          text: "",
          html: "",
          recipients: [],
          metadata: {
            campaign_template: {
              isPrivate: true, // default
              name: "Untitled Template", // default
              status: "draft", // default
            },
            campaign_name: "Untitled Campaign",
            campaign_status: "draft",
            campaign_type: "",
            gift_card_amount: 0,
            collection_query: { custom_selection_ids: 0, requestBody: requestBody },
          },
          user: user?.id,
        };

        const campaignResponse = await api.createMailCampaigns(null, [
          campaignData,
        ]);
        this.handleSuccess(campaignResponse);
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your new campaign template. Please reload the page and try again."
        );
      } finally {
        this.creatingCampaignTemplateDraft = false;
      }
    },

    /** handle template apply */
    handleTemplateSelection(selection) {
      //   if (selection.type === "category") {
      //     console.log("this is a category");
      //   }
      this.templateSelection = selection;
      this.showFilterModal = !this.showFilterModal;
    },
    closeModal() {
      this.showFilterModal = false;
    },

    /** handle tab change */
    handleTabChange(tabId) {
      this.currentTab = tabId;
      this.performTabSpecificLogic();
    },
    async performTabSpecificLogic() {
      if (this.currentTab === 1) {
        // Perform logic specific to tab 1
        console.log("tab 1 is active");

        // await this.getAllVendorCategories();
        // await this.getAllVendorNeighborhoods();
      } else if (this.currentTab === 2) {
        // Perform logic specific to tab 2
        console.log("tab 2 is active");
      }
      // Add additional conditions here for other tabs if necessary
    },

    /** handle success and errors */
    handleSuccess(response) {
      this.campaignId = response.data.result.records[0]._id;
      this.$router.push(`/template/edit/${this.campaignId}`);
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      this.checkingIfUserHasInvoices = false;
      this.isLinking = false;
      this.campaignIsSaving = false;
      console.error(errorMessage);
    },
  },
};
</script>
<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
