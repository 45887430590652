<template>
  <PageHeader title="Invoices"> </PageHeader>
  <ThePage customWidthClass="tw-w-full tw-max-w-8xl">
    <InvoicesTable></InvoicesTable>
  </ThePage>
</template>

<script>
import InvoicesTable from "../../components-v2/invoices/InvoicesTable";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";

export default {
  name: "ViewInvoices",
  components: {
    ThePage,
    InvoicesTable,
    PageHeader,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
