<template>
  <transition
    enter-active-class="tw-transition tw-ease-out tw-duration-200 tw-transform"
    enter-from-class="tw-opacity-0"
    enter-to-class="tw-opacity-100"
    leave-active-class="tw-transition tw-ease-in tw-duration-200 tw-transform"
    leave-from-class="tw-opacity-100"
    leave-to-class="tw-opacity-0"
  >
    <div
      class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto tw-bg-black tw-bg-opacity-50"
      v-show="open"
    >
      <div
        class="tw-flex lg:tw-items-center lg:tw-pt-0 tw-items-start tw-pt-20 tw-justify-center tw-min-h-screen tw-text-center tw-px-4"
        @click.self="close"
      >
        <Transition
          enter-active-class="tw-transition tw-ease-out tw-duration-300 tw-transform "
          enter-from-class="tw-opacity-0 tw-translate-y-10 tw-scale-95"
          enter-to-class="tw-opacity-100 tw-translate-y-0 tw-scale-100"
          leave-active-class="tw-ease-in tw-duration-200"
          leave-from-class="tw-opacity-100 tw-translate-y-0 tw-scale-100"
          leave-to-class="tw-opacity-0 tw-translate-y-10 tw-translate-y-0 tw-scale-95"
        >
          <div
            class="tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl"
            :class="contentClass"
            role="dialog"
            ref="modal"
            aria-modal="true"
            v-show="open"
            aria-labelledby="modal-headline"
          >
            <template v-if="bookMeetingView">
              <div class="tw-flex tw-flex-col gap-2">
                <div class="tw-self-end tw-pt-2 tw-pr-2 tw-z-50">
                  <CircleButton
                    variant="tertiary"
                    @click="close()"
                    class=""
                  >
                    <template #icon
                      ><b-icon-x-lg class="tw-text-black"></b-icon-x-lg
                    ></template>
                  </CircleButton>
                </div>

                <slot></slot>
              </div>
            </template>
            <template v-else><slot></slot></template>
          </div>
        </Transition>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalHeader from "../layout/ModalHeader.vue";
import CircleButton from "../actions/CircleButton.vue";

export default {
  name: "FullPageModal",
  emits: ["closeModal"],
  components: { ModalHeader, CircleButton },

  props: {
    bookMeetingView: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "",
    },
    role: {
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    customWidthClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.open) {
      this.toggleBodyClass(true);
    }
  },
  beforeUnmount() {
    this.toggleBodyClass(false);
  },
  watch: {
    open(newValue) {
      this.toggleBodyClass(newValue);
    },
  },
  computed: {
    contentClass() {
      return {
        "tw-w-full": this.fullWidth,
        "tw-w-auto ": this.autoWidth,
        // Add customWidth class if customWidth is provided
        [this.customWidthClass]: !!this.customWidthClass,
      };
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    handleOutsideClick() {
      if (this.closeOnClickOutside) {
        this.close();
      }
    },
    toggleBodyClass(open) {
      const method = open ? "add" : "remove";
      document.body.classList[method]("tw-overflow-hidden");
    },
  },
};
</script>
<style scoped></style>
