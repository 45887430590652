<template>
  <BlockStack :gap="400" :fullWidth="true">
    <template v-if="!bookMeetingView">
    <h1
      class="tw-m-0 tw-font-bold text-gray-900 tw-text-center tw-text-wrap"
      :class="{
        'tw-text-2xl sm:tw-text-4xl': !bookMeetingView,
        'tw-text-lg sm:tw-text-2xl tw-max-h-[250px] tw-overflow-y-auto tw-overflow-x-hidden':
          bookMeetingView,
      }"
    >
      {{ vendorName }}
    </h1>
  </template>
    <div class="tw-min-w-96 tw-w-full">
      <template v-if="vendorMedia.length >= 1">
        <TheCarousel :list="vendorMedia"> </TheCarousel>
      </template>
      <template v-if="vendorMedia.length === 0">
        <div
          class="tw-aspect-h-7 tw-aspect-w-10 tw-w-full tw-overflow-hidden tw-rounded-lg tw-bg-gray-100"
        >
          <img
            src="../../../assets/img/1920x400/Bold_Xchange_Gift_Card_Placeholder_WhiteOnBlue_1200x630.jpg"
            alt=""
            class="tw-h-full tw-w-full tw-pointer-events-none tw-object-cover"
          />
        </div>
      </template>
    </div>
    <div class="tw-max-h-[250px] tw-overflow-y-auto tw-overflow-x-hidden">
      <BlockStack :gap="300" :fullWidth="true">
        <template v-if="!bookMeetingView">
          <template v-if="scrollPositionIsAtTop">
            <div class="show-more m-0 py-2 text-primary fw-bold">
              <b-icon-arrow-down-circle
                class="text-primary fw-bold me-1"
              ></b-icon-arrow-down-circle
              >see more
            </div>
          </template>
        </template>
        <!-- bio -->
        <div>
          <h2 class="mb-0 fw-bold">Bio</h2>
          <p class="fs-3 text-body mb-0">
            {{ vendorDescription ? vendorDescription : noDataPlaceholderText }}
          </p>
        </div>
        <!-- address -->
        <div>
          <h2 class="mb-0 fw-bold">Location</h2>
          <p class="fs-3 text-body mb-0">
            <template
              v-if="vendorStreet && vendorCity && vendorState && vendorPostal"
            >
              {{
                vendorStreet +
                ", " +
                vendorCity +
                ", " +
                vendorState +
                " " +
                vendorPostal
              }}
            </template>
            <template v-else>USA</template>
          </p>
        </div>
        <template v-if="!bookMeetingView">
          <!-- neighborhood -->
          <div>
            <h2 class="mb-2 fw-bold">Neighborhood</h2>
            <div class="d-flex flex-row flex-wrap justify-content-left mt-1">
              <template
                v-if="vendorNeighborhoods && vendorNeighborhoods.length > 0"
              >
                <div class="d-flex flex-wrap gap-1">
                  <span
                    class="fs-3 badge bg-soft-secondary"
                    v-for="(neighborhood, key) in vendorNeighborhoods"
                    :key="'item-' + key"
                  >
                    <span class="fw-normal text-dark">{{ neighborhood }}</span>
                  </span>
                </div>
              </template>
              <template v-else>
                <p class="text-danger">{{ noDataPlaceholderText }}</p>
              </template>
            </div>
          </div>
          <!-- categories -->
          <div>
            <h2 class="mb-2 fw-bold">Specialty</h2>
            <template v-if="vendorCategories && vendorCategories.length > 0">
              <div class="d-flex flex-wrap gap-1">
                <span
                  class="fs-3 badge bg-soft-secondary"
                  v-for="(category, key) in vendorCategories"
                  :key="'item-' + key"
                >
                  <span class="fw-normal text-dark">{{ category }}</span>
                </span>
              </div>
            </template>
            <template v-else>
              <p class="text-danger">{{ noDataPlaceholderText }}</p>
            </template>
          </div>
        </template>
      </BlockStack>
    </div>
  </BlockStack>
</template>

<script>
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheCarousel from "../../../components-v2/ui/carousel/TheCarousel";

export default {
  name: "GiftCardModal",
  components: {
    TheCarousel,
    BlockStack,
  },
  props: {
    selectedVendor: {
      type: Object,
      required: true,
    },
    isGiftCardPopUp: {
      type: Boolean,
      required: false,
    },
    vendorMedia: {
      type: Array,
      required: false,
    },
    bookMeetingView: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      noDataPlaceholderText: "No information",
      options_1: {
        responsive: [{ size: 1 }],
      },
      options_2: {
        responsive: [{ size: 2 }],
      },
      scrollPositionIsAtTop: true,
    };
  },
  computed: {
    vendorId() {
      return this.selectedVendor._id;
    },
    vendorName() {
      return this.selectedVendor.name;
    },
    vendorDescription() {
      return this?.selectedVendor?.description;
    },
    vendorCategories() {
      const categories = this?.selectedVendor?.categories;
      if (categories) {
        return categories.filter(
          (category) =>
            category.toLowerCase() !== "gift card" &&
            category.toLowerCase() !== "st. louis, mo"
        );
      }
      return categories;
    },
    vendorNeighborhoods() {
      const categories = this?.selectedVendor?.metadata?.neighborhoods;
      if (categories) {
        return categories.filter(
          (category) =>
            category.toLowerCase() !== "gift card" &&
            category.toLowerCase() !== "st. louis, mo"
        );
      }
      return categories;
    },
    vendorStreet() {
      return this?.selectedVendor?.address?.lines[0];
    },
    vendorCity() {
      return this?.selectedVendor?.address?.city;
    },
    vendorState() {
      return this?.selectedVendor?.address?.state;
    },
    vendorCountry() {
      return this?.selectedVendor?.address?.country;
    },
    vendorPostal() {
      return this?.selectedVendor?.address?.postalCode;
    },
    vendorContactName() {
      return this?.selectedVendor?.contact?.name;
    },
    vendorContactTitle() {
      return this?.selectedVendor?.contact?.title;
    },
    vendorContactEmail() {
      return this?.selectedVendor?.contact?.email;
    },
    vendorContactPhone() {
      return this?.selectedVendor?.contact?.phone;
    },
    vendorWebsite() {
      return this?.selectedVendor?.metadata?.website_url;
    },
    vendorTwitter() {
      return this?.selectedVendor?.metadata?.twitter_url;
    },
    vendorLinkedIn() {
      return this?.selectedVendor?.metadata?.linkedin_url;
    },
    vendorTikTok() {
      return this?.selectedVendor?.metadata?.tiktok_url;
    },
    vendorInstagram() {
      return this?.selectedVendor?.metadata?.instagram_url;
    },
    vendorTypeformId() {
      const match =
        this?.selectedVendor?.metadata?.typeform_url.match(/\/to\/([^/]+)$/);
      if (match && match[1]) {
        return match[1];
      } else {
        return null;
      }
    },
    hasSocialLinks() {
      return (
        this.vendorWebsite ||
        this.vendorLinkedIn ||
        this.vendorTwitter ||
        this.vendorTikTok ||
        this.vendorInstagram
      );
    },
  },
  methods: {
    /** scroll behavior */
    // handleScroll() {
    //   this.scrollPositionIsAtTop =
    //     document.querySelector("#giftCardContent").scrollTop === 0;
    // },
  },
};
</script>

<style scoped></style>
