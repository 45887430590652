<template>
  <LoadingGiftIcon v-if="isLoading"></LoadingGiftIcon>

  <template v-else>
    <CreateClientModal
      v-if="isNewClientModalVisible"
      @cancel="closeCreateClientModal"
      @setNewClient="setNewClient"
    ></CreateClientModal>
    <ClientListModal
      v-if="isClientListModalVisible"
      @cancel="closeClientListModal"
      @setNewClient="setNewClient"
    ></ClientListModal>

    <SuccessfulActionModal
      v-if="updateMessage.successful.show"
      :memberEdited="updateMessage.successful.show"
    >
      <template #button-1>
        <button class="btn btn-primary" @click.stop="viewMembers()">
          Go to members list
        </button>
      </template>
      <template #button-2>
        <button class="btn btn-secondary" @click.stop="closeSuccessModal()">
          Close
        </button>
      </template>
    </SuccessfulActionModal>
    <div class="container-fluid">
      <div class="row">
        <div class="col-8 mx-auto">
          <!-- update messages -->
          <div
            class="mt-5"
            :class="updateMessage.failed.class"
            v-if="updateMessage.failed.show"
          >
            <p class="mb-0 fw-bold fs-4 text-white">
              {{ updateMessage.failed.text }}
            </p>
          </div>
          <div
            class="d-flex align-items-end justify-content-between text-start my-3 my-lg-5"
          >
            <h1 class="mb-0">Edit Member</h1>
          </div>
          <form @submit.prevent="editMember()">
            <!-- organization details -->
            <div class="card mb-3 mb-lg-5 text-start">
              <div class="card-header bg-dark">
                <h4 class="card-header-title text-white">
                  Organization information
                </h4>
              </div>
              <div class="card-body">
                <!-- client -->
                <div class="col-lg-6 text-start">
                  <div class="mb-4">
                    <div class="input-group">
                      <div
                        class="form-select"
                        @click.prevent="showClientListModal()"
                      >
                        <span>Select organization</span>
                      </div>
                      <button
                        type="button"
                        class="btn btn-warning fw-bold"
                        @click.prevent="showCreateClientModal()"
                      >
                        create
                      </button>
                    </div>
                  </div>
                </div>
                <template v-if="selectedClient">
                  <div class="row">
                    <div class="col-6 text-start">
                      <label for="companyName" class="form-label fw-bold"
                        >Organization
                      </label>
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        name="companyName"
                        id="companyName"
                        v-model="selectedClient.name"
                      />
                    </div>
                    <!-- Company Domain -->
                    <div class="col-6 text-start">
                      <label class="form-label fw-bold" for="companyDomain"
                        >Domain</label
                      >
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-lg"
                        name="companyDomain"
                        id="companyDomain"
                        v-model="selectedClient.domains[0]"
                      />
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- Step 2: Complete new member profile -->
            <div class="card mb-3 mb-lg-5 text-start">
              <div class="card-header bg-dark">
                <h4 class="card-header-title text-white">Member information</h4>
              </div>
              <div class="card-body">
                <div class="row gy-5">
                  <!-- first name -->
                  <div class="col-6">
                    <label for="firstName" class="form-label fw-bold"
                      >First Name</label
                    >
                    <span
                      v-if="!firstNameIsValid"
                      class="badge bg-soft-info ms-2"
                      ><span class="text-danger">required</span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      :class="firstNameClass"
                      name="firstName"
                      id="firstName"
                      placeholder="First name of new member"
                      aria-label="First name of new member"
                      v-model="member.firstName"
                      required
                    />
                  </div>
                  <!-- last name -->
                  <div class="col-6">
                    <label for="lastName" class="form-label fw-bold"
                      >Last Name</label
                    >
                    <span
                      v-if="!lastNameIsValid"
                      class="badge bg-soft-info ms-2"
                      ><span class="text-danger">required</span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      :class="lastNameClass"
                      name="lastName"
                      id="lastName"
                      placeholder="Last name of new member"
                      aria-label="Last name of new member"
                      v-model="member.lastName"
                      required
                    />
                  </div>
                  <!-- Email -->
                  <div class="col-6">
                    <label class="form-label fw-bold" for="giftingLeadEmail"
                      >Email</label
                    >
                    <span v-if="!emailIsValid" class="badge bg-soft-info ms-2"
                      ><span class="text-danger">required</span>
                    </span>
                    <span v-if="!emailIsValid" class="badge bg-light ms-1"
                      >Please follow this example:
                      <span class="text-primary">hello@boldxchange.com</span>
                    </span>
                    <div
                      v-if="doesGiftingLeadEmailExist"
                      class="badge bg-soft-danger d-flex align-items-center mb-1"
                    >
                      <span class="text-dark text-wrap text-start"
                        >The email you selected already exists in the
                        organization selected. Please use a different
                        email.</span
                      >
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      :class="emailClass"
                      name="giftingLeadEmail"
                      id="giftingLeadEmail"
                      placeholder="Primary email of new member"
                      aria-label="Primary email of new member"
                      v-model="member.email"
                      @input="debounceCheckIfClientLeadEmailExists()"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Step 3: Save new member -->
            <div class="d-flex justify-content-end">
              <template v-if="!memberIsSaving">
                <button
                  :class="{ disabled: !allItemsComplete }"
                  class="btn btn-success"
                >
                  Save changes
                </button>
              </template>
              <template v-if="memberIsSaving">
                <button class="btn btn btn-primary" type="button" disabled>
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving changes...
                </button></template
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import LoadingGiftIcon from "../../components/ui/LoadingGiftIcon.vue";
import CreateClientModal from "../../components/ui/modals/create/CreateClientModal.vue";
import SuccessfulActionModal from "../../components/ui/modals/success/SuccessfulActionModal.vue";
import ClientListModal from "../../components/ui/modals/lists/ClientListModal.vue";

export default {
  name: "EditMember",
  components: {
    LoadingGiftIcon,
    CreateClientModal,
    SuccessfulActionModal,
    ClientListModal,
  },
  data() {
    return {
      updateMessage: {
        successful: {
          // text: "Proposal(s) successfully updated!",
          class: "card-header bg-success",
          show: false,
        },
        failed: {
          text: "Something went wrong while attempting to edit your member. Please try again.",
          class: "bg-danger text-white p-4",
          show: false,
        },
      },
      member: {},
      selectedClient: {},
      doesGiftingLeadEmailExist: false,
      isNewClientModalVisible: false,
      isClientListModalVisible: false,
      memberIsSaving: false,
      isLoading: true,
      debounceTimer: null,
      originalEmail: null,
    };
  },
  computed: {
    allItemsComplete() {
      return (
        this.firstNameIsValid && this.lastNameIsValid && this.emailIsValid
        // && this.selectedClient
      );
    },
    firstNameIsValid() {
      return this.member.firstName.trim().length > 0;
    },
    lastNameIsValid() {
      return this.member.lastName.trim().length > 0;
    },
    emailIsValid() {
      // You might want to include a more robust email validation logic here
      const email = this.member.email.trim();
      return email.length > 0 && /\S+@\S+\.\S+/.test(email);
    },
    firstNameClass() {
      return {
        "is-invalid": !this.member.firstName,
        "is-valid": this.member.firstName,
      };
    },
    lastNameClass() {
      return {
        "is-invalid": !this.member.lastName,
        "is-valid": this.member.lastName,
      };
    },
    emailClass() {
      return {
        "is-invalid": !this.emailIsValid || this.doesGiftingLeadEmailExist,
        "is-valid": this.emailIsValid && !this.doesGiftingLeadEmailExist,
      };
    },
  },
  created() {
    const memberId = this.$route.params.memberId;
    this.loadMemberInformation(memberId);
  },
  methods: {
    async loadMemberInformation(memberId) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.isLoading = true;

      let requestBody = {
        _id: memberId,
      };

      let selection = "client firstName lastName email createdAt";

      const queryParameters = {
        limit: 1,
        sort: "firstName",
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.isLoading = false;
        this.member = response.data.result.records[0];
        this.selectedClient = response.data.result.records[0].client;
        this.originalEmail = response.data.result.records[0].email;
      } catch (error) {
        console.log("member did not load", error);
        this.isLoading = false;
      }
    },
    async checkIfClientLeadEmailExists() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      // console.log("gifting lead email", this.member.email);
      this.doesGiftingLeadEmailExist = false;

      let requestBody = {
        $and: [
          { email: { $ne: this.originalEmail } },
          { email: { $eq: this.member.email } },
        ],
      };
      let selection = "email";
      const queryParameters = {
        limit: 1,
        sort: "firstName",
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        const count = response.data.result.count;
        if (!count) {
          // console.log("gifting lead email query response", count);
          this.doesGiftingLeadEmailExist = false;
        } else {
          // console.log("gifting lead email query count", count);
          this.doesGiftingLeadEmailExist = true;
          this.member.email = "";
        }
      } catch (error) {
        console.log("users did not load", error);
      }
    },
    async editMember() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      const memberToUpdate = {
        firstName: this.member.firstName,
        lastName: this.member.lastName,
        email: this.member.email,
        role: this.member.role,
      };

      if (this?.selectedClient?._id) {
        memberToUpdate.client = this.selectedClient._id;
      }

      // console.log("userToUpdate", memberToUpdate);

      try {
        await api.upsertUser(null, {
          filter: { _id: this.member._id },
          patch: memberToUpdate,
        });
        this.handleSuccess(); //
      } catch (error) {
        console.log("user was NOT created", error);
      }
    },
    debounceCheckIfClientLeadEmailExists() {
      // Clear any previously scheduled timeouts
      clearTimeout(this.debounceTimer);

      // Get the input value
      // this.validateEmail();
      // console.log("is email valid?: ", this.emailIsValid);

      // Check if the input value is not empty
      if (this.emailIsValid) {
        // Schedule a new timeout to call checkIfClientExists after 500ms
        this.debounceTimer = setTimeout(() => {
          this.checkIfClientLeadEmailExists(); // Pass the trimmed input value to the method
        }, 300);
      }
    },

    /** organizations */
    setNewClient(newClient) {
      this.selectedClient = newClient;
      this.isNewClientModalVisible = false;
      this.isClientListModalVisible = false;
      if (this.emailIsValid) {
        this.checkIfClientLeadEmailExists();
      }
    },
    showClientListModal() {
      this.isClientListModalVisible = true;
    },
    closeClientListModal() {
      this.isClientListModalVisible = false;
    },
    showCreateClientModal() {
      this.isNewClientLeadModalVisible = false;
      this.isNewClientModalVisible = true;
    },
    closeCreateClientModal() {
      this.isNewClientModalVisible = false;
    },

    /** navigation */
    returnToAddOrderPage() {
      this.$router.push({ path: "/add-order" });
      window.location.reload();
    },
    cancelSelect() {
      this.isClientLeadListVisible = false;
    },
    viewMembers() {
      this.updateMessage.successful.show = false;
      this.$router.push(`/members/`);
    },
    closeSuccessModal() {
      this.updateMessage.successful.show = false;
      window.location.reload();
    },

    /** error handling */
    handleSuccess() {
      // console.log("new member added! ", data);
      this.updateMessage.successful.show = true;
    },
    handleError(error) {
      console.error("Error adding new member: ", error);
      this.updateMessage.failed.show = true;
      setTimeout(() => {
        this.updateMessage.failed.show = false;
      }, 5000);
      setTimeout(() => {
        this.updateFailed = false;
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
