// src/mixins/userMixin.js

export default {
  data() {
    return {
      newProfilePhotoMediaFiles: [],
      newOrganizationLogoMediaFiles: [],
      existingProfilePhotoMediaFiles: [],
      existingOrganizationLogoMediaFiles: [],
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    getProfilePhotoUrl() {
      return this?.existingProfilePhotoMediaFiles[0]?.url;
    },
    getOrganizationLogoUrl() {
      return this?.existingOrganizationLogoMediaFiles[0]?.url;
    },
  },
  methods: {
    async loadUserProfileMedia() {
      // console.log("loadUserProfileMedia");

      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      this.isLoading = true;

      let userId = this?.User?._id;
      if (this.isGiftCardRecipient) {
        // console.log("campaign user: ", this?.campaign?.user);
        userId = this?.campaign?.user;
        // return;
      }

      let filter = {
        $and: [
          {
            $or: [
              { "metadata.tags": { $eq: "profile photo" } },
              { "metadata.tags": { $eq: "organization logo" } },
            ],
          },
          { "metadata.user": { $toObjectId: userId } },
        ],
      };

      let selection = "media";
      const requestBody = {
        filter: filter,
      };

      const queryParameters = {
        limit: 2,
        expand: "metadata",
      };

      try {
        const response = await api.listMedia(queryParameters, requestBody);
        const mediaFiles = response.data.result.records;
        this.handleCreatingMediaUrls(mediaFiles, api);
        this.isLoading = false;
      } catch (error) {
        console.log("member did not load", error);
        this.isLoading = false;
      }
    },
    async upsertProfilePhoto() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      this.isSaving = true;
      const api = await this.getBoldClient();

      try {
        // Check if the user has an existing profile photo
        const existingProfilePhotoId = this.existingProfilePhotoMediaFiles
          .length
          ? this.existingProfilePhotoMediaFiles[0]._id
          : null;

        if (existingProfilePhotoId) {
          // Delete the existing profile photo
          await api.deleteMedia(null, {
            filter: { _id: existingProfilePhotoId },
          });
        }

        // Upload the new profile photo
        await this.handleMediaSubmission();

        await this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },
    async upsertOrganizationLogo() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      this.isSaving = true;
      const api = await this.getBoldClient();

      try {
        const existingOrganizationLogoId = this
          .existingOrganizationLogoMediaFiles.length
          ? this.existingOrganizationLogoMediaFiles[0]._id
          : null;

        if (existingOrganizationLogoId) {
          // Delete the existing profile photo
          await api.deleteMedia(null, {
            filter: { _id: existingOrganizationLogoId },
          });
        }

        // Upload the new profile photo
        await this.handleMediaSubmission();

        this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },
    async deleteProfilePhoto() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      this.isSaving = true;
      const api = await this.getBoldClient();

      try {
        // Check if the user has an existing profile photo
        const existingProfilePhotoId = this.existingProfilePhotoMediaFiles
          .length
          ? this.existingProfilePhotoMediaFiles[0]._id
          : null;

        await api.deleteMedia(null, {
          filter: { _id: existingProfilePhotoId },
        });

        this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },
    async deleteOrganizationLogo() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      this.isSaving = true;
      const api = await this.getBoldClient();

      try {
        // Check if the user has an existing profile photo
        const existingOrganizationLogoId = this
          .existingOrganizationLogoMediaFiles.length
          ? this.existingOrganizationLogoMediaFiles[0]._id
          : null;

        await api.deleteMedia(null, {
          filter: { _id: existingOrganizationLogoId },
        });

        this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },

    handleCreatingMediaUrls(mediaFiles, api) {
      const mediaArr = mediaFiles || [];
      const profilePhoto = this.filterMediaByTag(mediaArr, "profile photo");
      const organizationLogo = this.filterMediaByTag(
        mediaArr,
        "organization logo"
      );

      this.createMediaUrls(
        profilePhoto,
        this.existingProfilePhotoMediaFiles,
        api
      );
      this.createMediaUrls(
        organizationLogo,
        this.existingOrganizationLogoMediaFiles,
        api
      );

      this.isLoading = false;
    },

    createMediaUrls(mediaArr, mediaUrlArr, api) {
      mediaUrlArr.length = 0;
      for (const media of mediaArr) {
        const mediaId = media._id;
        const mediaUrl = api.utils.getMediaUrl(mediaId);
        mediaUrlArr.push({ _id: mediaId, url: mediaUrl });
      }
      // console.log("createMediaUrls");
    },

    filterMediaByTag(mediaArr, tag) {
      return mediaArr.filter((media) => {
        return media.metadata.tags.includes(tag);
      });
    },
    handleProfilePhotoMediaUpload(event) {
      // console.log("handleLogoMediaUploads");

      let uploadedFiles = event.target.files;

      for (var i = 0; i < uploadedFiles.length; i++) {
        const uniqueId = Math.random().toString(36).substr(2, 9); // Generate a unique ID
        const fileWithId = {
          id: uniqueId,
          file: uploadedFiles[i],
        };
        this.newProfilePhotoMediaFiles = [fileWithId];
      }
      this.getProfilePhotoPreview();
    },
    handleOrganizationLogoMediaUpload(event) {
      // console.log("handleLogoMediaUploads");

      let uploadedFiles = event.target.files;

      for (var i = 0; i < uploadedFiles.length; i++) {
        const uniqueId = Math.random().toString(36).substr(2, 9); // Generate a unique ID
        const fileWithId = {
          id: uniqueId,
          file: uploadedFiles[i],
        };
        this.newOrganizationLogoMediaFiles = [fileWithId];
      }
      this.getOrganizationLogoPreview();
    },
    getProfilePhotoPreview() {
      this.newProfilePhotoMediaFiles.forEach((file) => {
        if (/\.(jpe?g|png|gif)$/i.test(file.file.name)) {
          let reader = new FileReader();

          reader.onload = () => {
            let imgElement = document.getElementById("image-" + file.id);
            if (imgElement) {
              imgElement.src = reader.result;
            }
          };

          reader.readAsDataURL(file.file);
        }
      });
    },
    getOrganizationLogoPreview() {
      this.newOrganizationLogoMediaFiles.forEach((file) => {
        if (/\.(jpe?g|png|gif)$/i.test(file.file.name)) {
          let reader = new FileReader();

          reader.onload = () => {
            let imgElement = document.getElementById("image-" + file.id);
            if (imgElement) {
              imgElement.src = reader.result;
            }
          };

          reader.readAsDataURL(file.file);
        }
      });
    },
    /** media */
    async handleMediaSubmission() {
      // Define an array of media submission tasks
      try {
        await this.submitProfilePhotoMedia();
        await this.submitOrganizationLogoMedia();

        this.newProfilePhotoMediaFiles = [];
        this.newOrganizationLogoMediaFiles = [];
        return true;
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },
    async submitProfilePhotoMedia() {
      await this.uploadMedia("profile photo", this.newProfilePhotoMediaFiles);
    },
    async submitOrganizationLogoMedia() {
      await this.uploadMedia(
        "organization logo",
        this.newOrganizationLogoMediaFiles
      );
    },
    async uploadMedia(tag, files) {
      try {
        const api = await this.getBoldClient();
        // this.mediaIsSaving = true;

        const axiosOpts = {
          headers: { "Content-Type": "multipart/form-data" },
        };

        const uploadPromises = files.map(async (file) => {
          let formData = new FormData();
          formData.append("tags", tag);
          formData.append("user", this?.User?._id);
          formData.append("media", file.file);
          return await api.uploadMedia(null, formData, axiosOpts);
        });

        await Promise.all(uploadPromises);

        // this.handleSuccess("mediaUpdate");
      } catch (error) {
        this.handleError(error);
      }
    },

    async handleLoadingUserMedia() {
      await this.$store.dispatch("auth/refreshUser");
      await this.loadUserProfileMedia();
    },
  },
  async created() {
    await this.handleLoadingUserMedia();
  },
};
