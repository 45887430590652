<template>
  <PageHeader title="Invoice details"> </PageHeader>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <ThePage customWidthClass="tw-max-w-3xl tw-mx-auto tw-justify-center">
    <template v-if="invoicesAreLoading">
      <InvoiceDetailsPageSkeleton></InvoiceDetailsPageSkeleton>
    </template>
    <template v-else-if="hasData">
      <BlockStack :gap="800" customWidthClass="tw-w-full tw-mx-auto">
        <PageSection>
          <TheCard>
            <CardHeader
              :showTitle="true"
              title="Gift card details"
              :showActions="true"
            >
              <template #card-header-actions>
                <template v-if="!hasInvoiceBeenFulfilled">
                  <BaseButton
                    :disabled="invoiceIsSaving"
                    variant="success"
                    size="small"
                    :hasLeadingIcon="true"
                    @click="markInvoiceAsFulfilled()"
                  >
                    <template #leading-icon>
                      <template v-if="invoiceIsSaving">
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span class="tw-sr-only">Loading...</span>
                        </div>
                      </template>
                      <template v-else>
                        <b-icon-check-circle-fill></b-icon-check-circle-fill>
                      </template>
                    </template>

                    <template #text> Mark fulfilled </template>
                  </BaseButton>
                </template>
              </template>
            </CardHeader>

            <TheBox :noTopPadding="true">
              <div class="tw-w-auto">
                <dl
                  class="tw-mt-0 tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-gray-100 tw-border-t tw-border-gray-200 tw-text-sm tw-leading-6"
                >
                  <div class="sm:tw-flex">
                    <dt
                      class="tw-font-bold tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                    >
                      Status
                    </dt>
                    <dd
                      class="tw-flex tw-flex-col tw-gap-2 tw-justify-between tw-gap-x-6 tw-font-normal tw-p-4"
                      :class="{
                        'tw-bg-red-50': !hasInvoiceBeenFulfilled,
                        'tw-bg-green-50': !!hasInvoiceBeenFulfilled,
                      }"
                    >
                      <template v-if="!hasInvoiceBeenFulfilled">
                        <p class="tw-text-red-500 tw-font-bold tw-m-0">
                          Action required
                        </p>
                        <ol class="tw-list-decimal tw-m-0 tw-text-left">
                          <li
                            v-for="(item, index) in stepsToFulfillGift"
                            :key="index"
                            class="text-gray-700"
                          >
                            {{ item }}
                          </li>
                        </ol>
                        <TheLink
                          :external="true"
                          variant="primary"
                          :url="vendorGiftCardPage"
                        >
                          <template #text> Go to gift card page</template>
                        </TheLink>
                      </template>
                      <template v-else>
                        <p class="tw-text-green-700 tw-font-bold tw-m-0">
                          Action completed
                        </p>
                        <p class="tw-text-gray-900 tw-m-0">
                          Card was fulfilled on
                          {{ campaignFulfillmentDate }}
                        </p>
                      </template>
                    </dd>
                  </div>

                  <div class="tw-pt-4 sm:tw-flex">
                    <dt
                      class="tw-font-bold tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                    >
                      Gifting Lead
                    </dt>
                    <dd
                      class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto tw-font-normal"
                    >
                      <div>
                        <template v-if="campaignFromName">
                          <p class="tw-text-gray-900 tw-capitalize tw-m-0">
                            {{ campaignFromName }}
                          </p>
                        </template>
                        <template v-if="campaignFromEmail">
                          <p class="tw-text-gray-900 tw-m-0">
                            {{ campaignFromEmail }}
                          </p>
                        </template>
                      </div>
                    </dd>
                  </div>
                  <div class="tw-pt-4 sm:tw-flex">
                    <dt
                      class="tw-font-bold tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                    >
                      Recipient's Information
                    </dt>
                    <dd
                      class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto tw-font-normal"
                    >
                      <div>
                        <template v-if="recipientName">
                          <p class="tw-p-0 tw-m-0 tw-text-sm tw-capitalize">
                            {{ recipientName }}
                          </p>
                        </template>
                        <template v-if="recipientEmail">
                          <p class="tw-p-0 tw-m-0 tw-text-sm">
                            {{ recipientEmail }}
                          </p>
                        </template>
                      </div>
                    </dd>
                  </div>
                  <div class="tw-pt-6 sm:tw-flex">
                    <dt
                      class="tw-font-bold tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                    >
                      Gift Card Amount
                    </dt>
                    <dd
                      class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto tw-font-normal"
                    >
                      <template v-if="campaign?.metadata">
                        <p class="tw-text-gray-900 tw-capitalize tw-m-0">
                          {{ giftCardAmount }}
                        </p>
                      </template>
                    </dd>
                  </div>
                  <div class="tw-pt-6 sm:tw-flex">
                    <dt
                      class="tw-font-bold tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                    >
                      Vendor
                    </dt>
                    <dd class="tw-flex tw-flex-col tw-m-0 tw-font-normal">
                      <p class="tw-text-gray-900 tw-capitalize tw-m-0">
                        {{ vendorName }}
                      </p>
                      <template
                        v-if="
                          vendorStreet &&
                          vendorCity &&
                          vendorState &&
                          vendorPostal
                        "
                      >
                        {{
                          vendorStreet +
                          ", " +
                          vendorCity +
                          ", " +
                          vendorState +
                          " " +
                          vendorPostal
                        }}
                      </template>
                    </dd>
                  </div>
                </dl>
              </div>
            </TheBox>
          </TheCard>
        </PageSection>
      </BlockStack>
    </template>
    <template v-else>
      <PageSection class="tw-w-full">
        <PageDataFailedToLoad
          emptyState="invoiceDetails"
        ></PageDataFailedToLoad>
      </PageSection>
    </template>
  </ThePage>
</template>

<script>
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import InvoiceDetailsPageSkeleton from "../../components-v2/ui/skeletons/page-skeletons/InvoiceDetailsPageSkeleton.vue";
import BaseButton from "../../components-v2/ui/actions/BaseButton";
import TheLink from "../../components-v2/ui/actions/TheLink";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import TheBox from "../../components-v2/ui/layout/TheBox";
import TheCard from "../../components-v2/ui/layout/TheCard";
import CardHeader from "../../components-v2/ui/layout/CardHeader";
import PageDataFailedToLoad from "../../components-v2/ui/feedback/PageDataFailedToLoad.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";

import {
  listInvoices,
  upsertInvoice,
  findRedeemerMatch,
} from "@/services/invoiceService";

import { listSingleRecipientById } from "@/services/recipientService";

export default {
  name: "InvoiceDetails",
  components: {
    PageSection,
    NotificationMessage,
    PageDataFailedToLoad,
    InvoiceDetailsPageSkeleton,
    TheLink,
    ThePage,
    PageHeader,
    BaseButton,
    BlockStack,
    PageSection,
    TheBox,
    TheCard,
    CardHeader,
  },
  data() {
    return {
      api: {
        error: 0,
        success: 0,
        message: "",
      },
      stepsToFulfillGift: [
        "Follow the link below.",
        "Purchase the gift card using the Recipient's Information.",
        "Return to this page and mark this gift fulfilled.",
      ],
      invoice: null,
      campaign: null,
      vendor: null,
      recipient: null,
      linkCopied: false,
      invoicesAreLoading: true,
      invoiceIsSaving: false,
      totalSalesCount: 0,
      totalGiftsDelivered: 0,
      totalVendorsSupported: 0,
      noDataPlaceholderText: "--",
    };
  },
  async created() {
    await this.initializeComponentData();
  },
  computed: {
    /** campaign */
    hasData() {
      return (
        !this.invoicesAreLoading &&
        this.invoice !== null &&
        this.campaign !== null 
        // && this.vendor !== null
        // &&
        // this.recipient !== null
      );
    },
    campaignFromName() {
      return this?.campaign?.from?.name;
    },
    campaignFromEmail() {
      return this?.campaign?.from?.email;
    },
    giftCardAmount() {
      return `$${this?.campaign?.metadata?.gift_card_amount}`;
    },
    campaignStatus() {
      return this?.campaign?.metadata?.campaign_status;
    },

    hasInvoiceBeenFulfilled() {
      return this?.invoice?.metadata?.date_fulfilled;
    },
    campaignFulfillmentDate() {
      // First, check if dateString is not null or undefined
      if (!this?.invoice?.metadata?.date_fulfilled) return "";

      // Create a Date object from the dateString
      const date = new Date(this?.invoice?.metadata?.date_fulfilled);

      // Extract month, day, and year, and ensure they are in the correct format
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adds leading 0 if needed
      const day = date.getDate().toString().padStart(2, "0"); // Adds leading 0 if needed
      const year = date.getFullYear();

      // Return the formatted date string
      return `${month}/${day}/${year}`;
    },

    /** recipient */
    recipientName() {
      if (
        this?.recipient?.redeemer?.firstName &&
        this?.recipient?.redeemer?.lastName
      ) {
        return `${this?.recipient?.redeemer?.firstName} ${this?.invoice?.redeemer?.lastName}`;
      } else if (this?.recipient?.firstName && this?.invoice?.lastName) {
        return `${this?.recipient?.firstName} ${this?.invoice?.lastName}`;
      } else {
        return "N/A";
      }
    },
    recipientEmail() {
      if (this?.recipient?.redeemer?.email) {
        return this?.recipient?.redeemer?.email;
      } else if (this?.recipient?.contact?.email) {
        return this?.recipient?.contact?.email;
      } else {
        return "N/A";
      }
    },
    formattedDate() {
      return this.getStamp();
    },

    /** vendor */
    vendorName() {
      return this?.vendor?.name;
    },
    vendorGiftCardPage() {
      return this?.vendor?.metadata?.gift_card_page;
    },
    vendorWebsite() {
      return this?.vendor?.metadata?.website_url;
    },
    vendorStreet() {
      return this?.vendor?.address?.lines[0];
    },
    vendorCity() {
      return this?.vendor?.address?.city;
    },
    vendorState() {
      return this?.vendor?.address?.state;
    },
    vendorCountry() {
      return this?.vendor?.address?.country;
    },
    vendorPostal() {
      return this?.vendor?.address?.postalCode;
    },

    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** handle initializing component data */
    async initializeComponentData() {
      try {
        // Attempt to fetch invoice
        this.checkingIfUserHasInvoices = true;
        this.invoicesAreLoading = true;
        let vendorId;
        const invoice = await this.checkForInvoices();

        if (typeof invoice?.campaign?.seen === "boolean") {
          const recipientId = invoice.campaign[0].recipients[0];
          const recipient = await this.listSingleRecipientById(recipientId);
          this.recipient = recipient;
          vendorId = invoice.campaign[0].metadata.recipient_selection;
          console.log("recipient", recipient);
        } else {
          // Attempt to find redeemer match in invoice
          const redeemerMatch = findRedeemerMatch(invoice);
          // Set recipient based on redeemer match
          this.recipient = redeemerMatch;
          vendorId = redeemerMatch?.recipient_selection;
          console.log("redeemerMatch", redeemerMatch);
        }

        // Get vendor ID from redeemer match and fetch vendor data
        await this.getSingleVendorByID(vendorId);
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "Something went wrong while loading the invoice details. Please try again."
        );
        console.error("Failed to initialize:", error);
      } finally {
        this.checkingIfUserHasInvoices = false;
        this.invoicesAreLoading = false;
      }
    },
    /** invoice */
    async checkForInvoices() {
      const queryParameters = {
        limit: 1,
        expand: "user metadata campaign.recipients",
      };

      const id = this.$route.params.invoiceId;
      let requestBody = {
        _id: id,
      };

      const response = await listInvoices(queryParameters, requestBody);
      const count = response.data.result.count;
      this.count = count;
      this.userHasInvoices = count;
      const invoice = response.data.result.records[0];
      this.invoice = invoice;
      this.campaign = invoice.campaign[0];
      console.log("listInvoices response", response);

      return invoice;
    },
    /** vendor */
    async getSingleVendorByID(vendorId) {
      const api = await this.getBoldClient();
      const {
        data: {
          result: { records: singleVendorById },
        },
      } = await api.listVendors(
        {
          limit: 1,
          expand: "media contact address",
        },
        { filter: { _id: vendorId } }
      );
      this.vendor = singleVendorById[0];
    },
    /** vendor */
    async listSingleRecipientById(recipientId) {
      try {
        const response = await listSingleRecipientById(recipientId);
        console.log("recipients response", response);
        return response.data.result.records[0];
      } catch (error) {
        this.checkingIfUserHasRecipients = false;
        console.log("recipients did not load", error);
      }
    },
    /** update campaign status */
    async markInvoiceAsFulfilled() {
      this.invoiceIsSaving = true;

      const invoiceId = this.invoice.id;
      const currentDate = this.getCurrentDateISO();
      const patchObject = {
        "metadata.date_fulfilled": currentDate,
      };

      try {
        await upsertInvoice(invoiceId, patchObject);
        this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
      } finally {
        this.invoiceIsSaving = false;
      }
    },

    /** current date */
    getCurrentDateISO() {
      const currentDate = new Date();
      return currentDate.toISOString();
    },

    /** handle success and errors */
    handleSuccess() {
      let successMessage = "Update successful!";

      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: successMessage,
        duration: 4000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.initializeComponentData();
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage =
          "An error occurred setting up the request. Please try again.";
      }

      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
