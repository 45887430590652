<template>
  <!-- <ThePage class="tw-w-full"> -->
  <BlockStack :gap="1000" class="tw-w-full">
    <PageSection class="tw-max-w-2xl">
      <TheCard class="tw-w-full tw-overflow-visible">
        <CardHeader :showTitle="true" :showActions="!isEditingDetails" title="Group settings">
          <template #card-header-actions>
            <TheButton
              variant="primary"
              size="small"
              :hasLeadingIcon="true"
              @click="editGroupDetails()"
            >
            <template #leading-icon><b-icon-pencil /></template>
              <template #text>
                Edit
              </template>
            </TheButton>
          </template>
        </CardHeader>

        <TheBox :noTopPadding="true" class="tw-w-full">
          <EditGroupForm
            :group="group"
            :isEditingDetails="isEditingDetails"
            @reloadData="reloadData"
            @editGroupDetails="editGroupDetails"
          ></EditGroupForm>
        </TheBox>
      </TheCard>
    </PageSection>
  </BlockStack>
  <!-- </ThePage> -->
</template>

<script>
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import EditGroupForm from "../../components-v2/ui/modals/edit/EditGroupForm.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";

export default {
  name: "RecipientsGroupSettings",
  emits: ["reloadData",],
  components: {
    TheButton,
    CardHeader,
    TheBox,
    TheCard,
    EditGroupForm,
    BlockStack,
    PageSection,
  },
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  async created() {},
  data() {
    return {
      isEditingDetails: false
    };
  },
  computed: {},
  methods: {
    /** from TheBudget.vue */
    editGroupDetails() {
      this.isEditingDetails = !this.isEditingDetails;
      this.$emit("reloadData");
    },
    reloadData() {
      this.$emit("reloadData");
    },
  },
};
</script>

<style scoped></style>
