<template>
  <BlockStack :gap="400" class="lg:tw-p-10 tw-p-4 ">
    <BlockStack :gap="200" class="tw-max-w-xl">
      <h2 class="tw-text-3xl tw-m-0">Please enter your details</h2>
      <p class="tw-m-0 tw-text-lg">
        We collect this information to help combat fraud, and to keep your
        payment secure.
      </p>
    </BlockStack>
    <BlockStack :gap="400" class="tw-max-w-xl">
      <BlockStack :gap="200" class="tw-max-w-xl">
        <input
          v-model="email"
          type="text"
          name="newUserEmail"
          id="newUserEmail"
          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
          placeholder="bebold@example.com"
        />
        <p v-if="userEmailExists" class="text-danger">
          It looks like you already have an account.
        </p>
      </BlockStack>
      <div class="tw-flex tw-gap-4">
        <TheButton
          variant="primary"
          size="large"
          @click="loadStripeEmbeddedForm()"
          type="button"
          :disabled="!email"
          :hasTrailingIcon="true"
        >
          <template #text>Continue to payment</template>
          <template #trailing-icon>
            <svg
              class="rtl:tw-rotate-180 tw-w-3.5 tw-h-3.5 tw-ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </template>
        </TheButton>
        <template v-if="userEmailExists">
          <TheLink
            :isRouterLink="true"
            to="/login"
            variant="primary"
            size="large"
          >
            <template #text>Login instead</template>
          </TheLink>
        </template>
      </div>
    </BlockStack>
  </BlockStack>
</template>

<script>
import TheButton from "./../../../components-v2/ui/actions/TheButton.vue";
import TheLink from "./../../../components-v2/ui/actions/TheLink.vue";
import BlockStack from "./../../../components-v2/ui/layout/BlockStack.vue";

export default {
  name: "CustomerRegistrationCollectEmail",
  components: { TheButton, BlockStack, TheLink },
  emits: ["loadStripeEmbeddedForm"],
  props: {
    userEmailExists: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      email: null,
    };
  },
  methods: {
    handleEmailInput(value) {
      this.email = value.trim();
    },
    loadStripeEmbeddedForm() {
      this.$emit("loadStripeEmbeddedForm", this.email.trim()); // Emit the input value regardless of validation result
    },
  },
};
</script>

<style scoped></style>
