<template>
  <tr>
    <ConfirmDeleteModal
      :members="[this.member]"
      @cancel="toggleDeleteModal()"
      @confirm="deleteThisMember()"
      v-if="isConfirmDeleteModalVisible"
    >
    </ConfirmDeleteModal>
    <!-- <template v-if="!isBulkEditing">
      <td class="col">
        <div class="d-flex align-items-center gap-2 text-center text-start">
          <td>
            <input
              :disabled="quickEditActive"
              type="checkbox"
              @change="selectRow"
              :checked="isSelected(this.member)"
            />
          </td>
        </div>
      </td>
    </template> -->

    <td class="text-start">
      <router-link
        class="link-primary text-dark fw-bold"
        :to="{
          name: 'MemberDetails',
          params: { memberId: member._id },
        }"
      >
        {{ member.firstName + " " + member.lastName }}
      </router-link>
    </td>
    <td class="text-start">
      <template v-if="member.client">{{ member.email }}</template>
      <template v-else>
        {{ noDataPlaceholderText }}
      </template>
    </td>
    <td class="text-start col-2">
      <template v-if="member.client">{{ member.client.name }}</template>
      <template v-else>
        {{ noDataPlaceholderText }}
      </template>
    </td>
    <td class="text-start">
      <template v-if="member.client">{{ formattedDate }}</template>
      <template v-else>
        {{ noDataPlaceholderText }}
      </template>
    </td>
    <td class="col-auto text-center">
      <div v-if="areQuickEditsActive">
        <p class="text-danger mb-1" v-if="!checkIfQuickEditsAreComplete">
          Form is incomplete.
        </p>
        <button
          v-if="!checkIfQuickEditsAreComplete"
          :disabled="!checkIfQuickEditsAreComplete"
          type="button"
          class="btn btn-sm"
        >
          <b-icon-check class="me-2"></b-icon-check>
          Save changes
        </button>
        <button
          v-if="checkIfQuickEditsAreComplete"
          @click.stop="updateThisOrderGiftingLeads()"
          type="button"
          class="btn btn-outline-success btn-sm"
        >
          <b-icon-check class="me-2"></b-icon-check>
          Save changes
        </button>
      </div>
      <!-- edit details  -->
      <div class="btn-group" role="group">
        <!-- edit details  -->
        <button
          @click.stop="editMember()"
          type="button"
          class="btn btn-white btn-sm"
        >
          <b-icon-pencil class="text-primary me-2"></b-icon-pencil>
          Edit
        </button>
        <button
          @click.stop="toggleDeleteModal()"
          type="button"
          class="btn btn-white btn-sm"
        >
          <b-icon-trash class="text-danger"></b-icon-trash>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import ConfirmDeleteModal from "../../components/ui/modals/ConfirmDeleteModal.vue";
import moment from "moment";

export default {
  name: "MembersListItem",
  components: { ConfirmDeleteModal },
  props: [
    "member",
    "memberId",
    "selectedRows",
    "selectAll",
    "quickEditActive",
    "isBulkEditing",
  ],
  data() {
    return {
      noDataPlaceholderText: "--",
      isConfirmDeleteModalVisible: false,
    };
  },
  created() {},
  computed: {
    formattedDate() {
      const date = moment(this.member.createdAt).format("MM/DD/YYYY");
      return date;
    },
  },
  methods: {
    async deleteThisMember() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      try {
        const api = await this.getBoldClient();
        const memberId = this.member._id;

        await Promise.all([this.deleteSingleMember(api, memberId)]);
        console.log("member deleted successfully");
        this.$emit("showSuccessMessage");
      } catch (error) {
        console.log("error updating proposal", error);
        this.$emit("showErrorMessage");
      }
    },
    async deleteSingleMember(api, memberId) {
      await api.deleteUsers(null, {
        filter: {
          _id: memberId,
        },
      });
    },
    editMember() {
      this.$router.push(`/edit-member/${this.member._id}`);
    },
    toggleDeleteModal() {
      this.isConfirmDeleteModalVisible = !this.isConfirmDeleteModalVisible;
    },

    /** bulk edit */
    isSelected(member) {
      return (
        this.selectAll ||
        this.selectedRows.some((selected) => selected._id === member._id)
      );
    },
    selectRow() {
      this.$emit("row-selected", this.member);
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
