<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <!-- Page Header -->
      <div class="modal-header border-bottom px-0 pt-0 pb-2">
        <h1 class="modal-title">Create new company</h1>
      </div>

      <div class="modal-body p-0 mt-5">
        <div class="mt-3">
          <div class="row">
            <div class="col mb-3 mb-lg-0 text-start">
              <!-- Name -->

              <div class="mb-4">
                <label for="companyName" class="form-label"
                  >Company name
                  <span
                    v-if="!getClientName"
                    class="badge bg-soft-info rounded-pill ms-2"
                    ><span class="text-danger">required</span>
                  </span></label
                >

                <div
                  v-if="doesClientExist"
                  class="badge bg-warning d-flex align-items-center mb-1"
                >
                  <b-icon-exclamation-circle
                    class="text-dark me-1"
                  ></b-icon-exclamation-circle>
                  <span class="text-dark"
                    >A company with a similar name already exists in our
                    database.</span
                  >
                </div>
                <input
                  type="text"
                  class="form-control"
                  :class="clientNameClass"
                  name="companyName"
                  id="companyName"
                  placeholder="Enter company name"
                  aria-label="Enter company name"
                  v-model="client.name"
                  required
                  @input="debounceCheckIfClientExists()"
                />
              </div>

              <!-- Company Domain -->
              <div class="mb-4">
                <label
                  class="form-label justify-label-start"
                  for="companyDomain"
                  >Company domain<span class="badge bg-light ms-1"
                    >Please follow this example:
                    <span class="text-primary">boldxchange.com</span>
                  </span></label
                >
                <div
                  v-if="doesDomainExist"
                  class="badge bg-warning d-flex align-items-center mb-1"
                >
                  <b-icon-exclamation-circle
                    class="text-dark me-1"
                  ></b-icon-exclamation-circle>
                  <span class="text-dark"
                    >A similar domain already exists in our database.</span
                  >
                </div>
                <input
                  type="url"
                  class="form-control form-control-lg"
                  name="companyDomain"
                  id="companyDomain"
                  placeholder="Enter company domain"
                  aria-label="Enter company domain"
                  v-model="domain"
                  @input="debounceCheckIfDomainExists()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-start px-0">
        <!-- submit button -->
        <div class="d-flex flex-column align-items-start w-100 my-2">
          <p class="text-danger" v-if="!checkIfFormIsComplete">
            Form is incomplete. Please fix invalid or missing information.
          </p>
          <div class="d-flex gap-2" v-if="!clientIsSaving && !isSuccessful">
            <button
              :disabled="!checkIfFormIsComplete"
              type="button"
              class="btn btn-primary"
              @click.prevent="createSingleClient()"
            >
              Save company
            </button>
            <button
              type="button"
              class="btn btn-soft-secondary"
              @click.prevent="$emit('cancel')"
            >
              Cancel
            </button>
          </div>
          <button
            class="btn btn-primary"
            type="button"
            disabled
            v-if="clientIsSaving"
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Saving company...
          </button>
          <button
            class="btn btn-primary"
            type="button"
            disabled
            v-if="isSuccessful"
          >
            <span class="text-success"
              ><b-icon-check-circle-fill></b-icon-check-circle-fill>
            </span>
            Success!
          </button>
        </div>
      </div>
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "CreateClientModal",
  components: {
    FixedModalSmall,
  },
  data() {
    return {
      isUrlValid: false,
      clientIsSaving: false,
      isSuccessful: false,
      doesClientExist: false,
      doesDomainExist: false,
      clientName: "",
      client: {
        name: "",
        domains: [],
      },
      domain: "",
      debounceTimer: null,
    };
  },
  computed: {
    checkIfFormIsComplete() {
      return this.getClientName 
    },
    getClientName() {
      return this.client.name === "" ? false : this.client.name;
    },
    getClientDomain() {
      return this.domain === "" ? false : this.domain;
    },
    getDomainsArray() {
      return this.createDomainsArray();
    },
    clientNameClass() {
      return {
        "is-invalid": !this.getClientName,
        "is-valid": this.getClientName,
      };
    },
    domainClass() {
      return {
        "is-invalid": !this.getClientDomain || !this.isUrlValid,
        "is-valid": this.getClientDomain && this.isUrlValid,
      };
    },
  },
  watch: {
    "client.name"() {
      if (this.client.name === "") {
        this.doesClientExist = false;
      }
      this.doesClientExist = false;
    },
  },
  methods: {
    debounceCheckIfClientExists() {
      // Clear any previously scheduled timeouts
      clearTimeout(this.debounceTimer);

      // Get the input value
      const inputValue = this.client.name.trim(); // Trim to remove leading/trailing spaces

      // Check if the input value is not empty
      if (inputValue !== "") {
        // Schedule a new timeout to call checkIfClientExists after 500ms
        this.debounceTimer = setTimeout(() => {
          this.checkIfClientExists(); // Pass the trimmed input value to the method
        }, 800);
      }
    },
    async checkIfClientExists() {
      /**@type {import('@boldexchange/sdk/defs').Client} */
      const api = await this.getBoldClient();
      const clientName = this.client.name;

      api
        .listClients(
          {
            sort: "name",
          },
          {
            filter: {
              $or: [{ name: { $regex: clientName } }],
            },
          }
        )
        .then((response) => {
          const clientList = response.data.result.records;
          // console.log("client name search", response.data.result.records);
          if (clientList.length === 0) {
            this.doesClientExist = false;
          } else {
            this.count = response.data.result.count;
            this.doesClientExist = true;
          }
        })
        .catch((error) => {
          console.log("client search query failed", error);
        });
    },
    validateURL() {
      const url = this.getClientDomain;
      const urlPattern = /^(?!www\.).*\.com$/;

      this.isUrlValid = urlPattern.test(url);
    },
    debounceCheckIfDomainExists() {
      // Clear any previously scheduled timeouts
      clearTimeout(this.debounceTimer);

      this.validateURL();
      // console.log("is URL valid?: ", this.isUrlValid);
      // Check if the input value is valid
      if (this.isUrlValid) {
        // Schedule a new timeout to call checkIfClientExists after 500ms
        this.debounceTimer = setTimeout(() => {
          this.checkIfDomainExists(); // Pass the trimmed input value to the method
        }, 800);
      } else {
        return false;
      }
    },
    async checkIfDomainExists() {
      /**@type {import('@boldexchange/sdk/defs').Client} */
      const api = await this.getBoldClient();
      const domainsArr = [];
      domainsArr.push(this.domain);

      this.doesDomainExist = false;

      api
        .listClients(
          {
            sort: "name",
          },
          {
            filter: { domains: { $eq: this.domain } },
          }
        )
        .then((response) => {
          const clientList = response.data.result.records;
          if (clientList.length === 0) {
            // console.log("client domain search", clientList);
            // console.log("domain count", response.data.result.count);
          } else {
            // console.log("client domain search", clientList);
            this.doesDomainExist = true;
          }
        })
        .catch((error) => {
          console.log("client search query failed", error);
        });
    },
    createDomainsArray() {
      const newDomain = this.domain;
      this.client.domains.push(newDomain);
      const domainArr = this.client.domains;
      return domainArr;
    },
    async createSingleClient() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.clientIsSaving = true;
      const domainsArr = [];
      domainsArr.push(this.domain);
      const clientToCreate = {
        name: this.client.name,
        domains: domainsArr,
      };
      // const singleClient = this.client;
      const clientArr = [];
      clientArr.push(clientToCreate);
      // console.log("client to create", clientArr);

      api
        .createClients(null, clientArr)
        .then((response) => {
          this.isSuccessful = true;
          // console.log("new client response", response);
          const newClient = response.data.result.records[0];
          this.$emit("setNewClient", newClient);
        })
        .catch((error) => {
          console.log("Client was NOT created", error);
        });
    },
  },
};
</script>

<style scoped>
#upload-region {
  width: 100%;
  background-color: #f8fafd;
  border: 0.125rem dashed rgba(231, 234, 243, 0.7);
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

#upload-region.dragover {
  background-color: #f0f0f0;
}
</style>
