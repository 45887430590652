<template>
  <div class="tw-flex tw-flex-col tw-gap-2">
    <template v-if="!bookingIsSelected">
      <h2 class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-overflow-hidden">
        Select a Date & Time
      </h2>
      <div class="tw-flex tw-gap-4 tw-flex-wrap tw-justify-center tw-w-full">
        <!-- <div class=""> -->
        <v-date-picker
          transparent
          borderless
          expanded
          ref="picker"
          v-model="date"
          mode="is24hr"
        />
        <!-- </div> -->
        <div class="tw-max-w-lg tw-w-full">
          <p class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-text-center">
            {{ formattedSelectedDate }}
          </p>
          <div
            class="tw-flex tw-flex-wrap tw-justify-center tw-gap-2 tw-w-full"
          >
            <TheButton
              v-for="meeting in formattedAvailableTimes"
              :key="meeting.index"
              class="tw-w-40 tw-flex tw-justify-center"
              variant="primary"
              size="regular"
              :hasLeadingIcon="true"
              @click="selectBooking(meeting)"
            >
              <template #text>{{ meeting.formattedStart }}</template>
            </TheButton>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TheButton from "../components-v2/ui/actions/TheButton.vue";
import "v-calendar/style.css";
import { DatePicker } from "v-calendar";
import moment from "moment-timezone";

export default {
  components: {
    TheButton,
    VDatePicker: DatePicker,
  },
  props: {
    bookedTimes: Array,
    modelValue: Date,
    testMode: Boolean,
    meetingMinutes: {
      type: Number,
      default: () => 30,
    },
  },
  data() {
    return {
      date: moment().toDate(),
      availableTimes: [],
      selectedBooking: null,
      selectedDate: null,
      dateDisplayed: null,
      userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  },
  mounted() {
    this.selectedDate = moment(this.date).format("YYYY-MM-DD");
    this.calculateAvailableSlots();
  },
  watch: {
    date: {
      handler: "handleDateSelection",
      deep: true,
    },
  },
  computed: {
    formattedAvailableTimes() {
      return this.availableTimes.map((slot) => ({
        start: slot.start,
        end: slot.end,
        formattedStart: moment
          .tz(slot.start, this.userTimezone)
          .format("h:mmA"),
        formattedEnd: moment.tz(slot.end, this.userTimezone).format("h:mmA"),
        date: moment(this.date).format("MMMM Do YYYY"),
      }));
    },
    formattedBookedTimes() {
      return this.bookedTimes.map((slot) => ({
        start: moment.tz(slot.start, this.userTimezone).format("h:mmA"),
        end: moment.tz(slot.end, this.userTimezone).format("h:mmA"),
      }));
    },
    formattedSelectedDate() {
      return moment(this.date).format("MMMM Do YYYY");
    },
    bookingIsSelected() {
      return this.$store.getters["campaigns/getSelectedBooking"];
    },
  },
  methods: {
    handleDateSelection() {
      this.$emit("update:modelValue", this.date);
      this.selectedDate = moment(this.date).format("YYYY-MM-DD");
      console.log("Selected date", this.selectedDate);
      this.calculateAvailableSlots();
    },
    selectBooking(booking) {
      this.selectedBooking = booking;
      console.log("Selected booking", booking);
      this.$store.commit("campaigns/SET_BOOKING", booking);
      console.log(
        "Selected booking",
        this.$store.getters["campaigns/getSelectedBooking"]
      );
    },
    calculateAvailableSlots() {
      let slots = [];
      const now = moment.tz(this.userTimezone).startOf("minute"); // Get the current time rounded to the nearest minute for precision
      const localDayStart = moment.tz(
        `${this.selectedDate}T08:00:00`,
        this.userTimezone
      );
      const localDayEnd = moment.tz(
        `${this.selectedDate}T17:00:00`,
        this.userTimezone
      );

      // Adjust 'now' to the start of the next half-hour if we're already part-way through a current slot
      if (now.minutes() % 30 !== 0) {
        now
          .add(30 - (now.minutes() % 30), "minutes")
          .seconds(0)
          .milliseconds(0);
      } else {
        // If exactly on a half-hour, add 30 minutes to move to the start of the next slot
        now.add(30, "minutes");
      }

      let currentTime = moment.max(localDayStart, now); // Start from the later of the day start or adjusted now

      while (currentTime < localDayEnd) {
        let endTime = currentTime.clone().add(30, "minutes");

        const isOverlapping = this.bookedTimes.some((time) => {
          let start = moment.tz(time.start, "UTC").tz(this.userTimezone);
          let end = moment.tz(time.end, "UTC").tz(this.userTimezone);
          return endTime > start && currentTime < end;
        });

        if (!isOverlapping) {
          slots.push({
            start: currentTime.toISOString(),
            end: endTime.toISOString(),
          });
        }
        currentTime.add(30, "minutes");
      }

      this.availableTimes = slots;
    },
  },
};
</script>

<style></style>
