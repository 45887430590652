<template>
  <div class="tw-min-h-full">
    <main class="tw-flex-1 tw-pb-8 tw-h-full">
      <div class="tw-h-auto tw-bg-stone-100 tw-min-h-[100vh]">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "AnonymousNavigation",
  components: {},
};
</script>

<style scoped></style>
