<template>
  <Teleport to="#modal">
    <TheModal
      title="Creating new recipient"
      @closeModal="closeModal"
      :open="showNewRecipientModal"
      :showModalHeader="true"
      :showModalBody="true"
      :showModalFooter="true"
      :closeOnClickOutside="false"
    >
      <template #modal-body>
        <NewRecipientForm
          @closeModal="closeModal"
          @handleNewRecipient="handleSuccess"
        ></NewRecipientForm>
      </template>
    </TheModal>
  </Teleport>
  <template v-if="!isChecklistComplete">
    <TheCard>
      <ul
        role="list"
        class="tw-list-none tw-divide-solid tw-divide-y tw-divide-x-0 tw-divide-gray-200 tw-p-0 tw-m-0"
      >
        <li
          v-for="item in checkListContent"
          :key="item.number"
          class="tw-list-none tw-cursor-pointer tw-relative tw-flex tw-flex-col tw-gap-x-6 tw-px-4 tw-py-5 sm:tw-px-6"
          @click="toggleListItem(item)"
        >
          <div class="tw-flex tw-min-w-0 tw-gap-x-4 tw-items-top">
            <!-- account -->
            <template v-if="item.type === 'account' && !paymentInformation">
              <b-icon-check-circle-fill
                class="tw-h-5 tw-w-5 tw-flex-none tw-text-gray-400"
              ></b-icon-check-circle-fill>
            </template>
            <template v-if="item.type === 'account' && paymentInformation">
              <b-icon-check-circle-fill
                class="tw-h-5 tw-w-5 tw-flex-none tw-text-green-500"
              ></b-icon-check-circle-fill>
            </template>
            <!-- recipients -->
            <template
              v-if="item.type === 'recipients' && !totalRecipientsCount"
            >
              <b-icon-check-circle-fill
                class="tw-h-5 tw-w-5 tw-flex-none tw-text-gray-400"
              ></b-icon-check-circle-fill>
            </template>
            <template v-if="item.type === 'recipients' && totalRecipientsCount">
              <b-icon-check-circle-fill
                class="tw-h-5 tw-w-5 tw-flex-none tw-text-green-500"
              ></b-icon-check-circle-fill>
            </template>
            <template
              v-if="item.type === 'campaigns' && !totalCampaignsSentCount"
            >
              <b-icon-check-circle-fill
                class="tw-h-5 tw-w-5 tw-flex-none tw-text-gray-400"
              ></b-icon-check-circle-fill>
            </template>
            <template
              v-if="item.type === 'campaigns' && totalCampaignsSentCount"
            >
              <b-icon-check-circle-fill
                class="tw-h-5 tw-w-5 tw-flex-none tw-text-green-500"
              ></b-icon-check-circle-fill>
            </template>
            <div>
              <p
                class="tw-text-xl tw-font-bold tw-leading-6 tw-text-gray-900 tw-m-0 tw-p-0"
              >
                <template v-if="item.type === 'account'">
                  <template v-if="paymentInformation">
                    {{ item.title }}
                  </template>
                  <template v-else>
                    Add a credit card to your profile
                  </template>
                </template>
                <template v-else>
                  {{ item.title }}
                </template>
              </p>
              <p class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0 tw-p-0">
                <template v-if="item.type === 'account'">
                  <template v-if="paymentInformation">
                    {{ item.subtitle }}
                  </template>
                  <template v-else>
                    Let's make sure your recipients can redeem their e-gift
                    cards.
                  </template>
                </template>
                <template v-else>
                  {{ item.subtitle }}
                </template>
              </p>
            </div>
          </div>
          <ul
            v-if="item.showContent"
            class="tw-list-none tw-cursor-auto tw-mt-2 tw-bg-white -tw-mx-6 -tw-mb-5 tw-p-0 tw-m-0 tw-py-5"
            @click.stop
          >
            <div class="tw-px-16 tw-py-5">
              <template v-if="item.type === 'account'">
                <template v-if="!paymentInformation">
                  <div class="tw-flex tw-gap-2 tw-items-center tw-h-60">
                    <img
                      style="max-height: 200px; width: auto"
                      class=""
                      src="../../assets/svg/illustrations-test/97_caution_alert.png"
                      alt="Image Description"
                    />
                    <div class="tw-flex tw-flex-col tw-gap-4">
                      <div>
                        <h2
                          class="tw-text-2xl tw-font-bold tw-leading-6 tw-text-gray-900"
                        >
                          Add credit card to profile
                        </h2>
                        <p
                          class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0 tw-p-0"
                        >
                          Please add a credit card to your profile so that your
                          recipients can redeem the e-gift cards you send them.
                        </p>
                      </div>

                      <div class="tw-flex tw-gap-4">
                        <TheButton
                          :isRouterLink="true"
                          to="/account"
                          variant="critical"
                          size="large"
                        >
                          <template #text>Add credit card</template>
                        </TheButton>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="tw-flex tw-gap-2 tw-items-center tw-h-60">
                    <img
                      style="max-height: 200px; width: auto"
                      class=""
                      src="../../assets/svg/illustrations-test/138_handshake.png"
                      alt="Image Description"
                    />
                    <div class="tw-flex tw-flex-col tw-gap-4">
                      <div>
                        <h2
                          class="tw-text-3xl tw-font-bold tw-leading-6 tw-text-gray-900"
                        >
                          You're rocking and rolling!
                        </h2>
                        <p
                          class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0"
                        >
                          View and edit account details by following the link
                          below.
                        </p>
                      </div>
                      <TheLink
                        variant="primary"
                        url="/account"
                        :isRouterLink="true"
                        size="large"
                      >
                        <template #text> Account settings</template>
                        <template #leading-icon
                          ><b-icon-plus-lg></b-icon-plus-lg>
                        </template>
                      </TheLink>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="item.type === 'recipients'">
                <template v-if="!totalRecipientsCount">
                  <div class="tw-flex tw-gap-2 tw-items-center tw-h-60">
                    <img
                      style="max-height: 200px; width: auto"
                      class=""
                      src="../../assets/svg/illustrations-test/46_blank_page.png"
                      alt="Image Description"
                    />
                    <div class="tw-flex tw-flex-col tw-gap-4">
                      <div>
                        <h2
                          class="tw-text-2xl tw-font-bold tw-leading-6 tw-text-gray-900"
                        >
                          Expand your reach
                        </h2>
                        <p
                          class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0 tw-p-0"
                        >
                          Upload a spreadsheet of recipients or create a single
                          recipient.
                        </p>
                      </div>

                      <div class="tw-flex tw-gap-4">
                        <TheButton
                          :hasLeadingIcon="true"
                          variant="primary"
                          @click="toggleNewRecipientModal"
                          size="large"
                        >
                          <template #text>Create recipient</template>
                          <template #leading-icon>
                            <b-icon-plus-lg></b-icon-plus-lg>
                          </template>
                        </TheButton>
                        <TheLink
                          variant="primary"
                          url="/recipients/import"
                          :isRouterLink="true"
                        >
                          <template #text> Import recipients</template>
                          <template #leading-icon
                            ><b-icon-plus-lg></b-icon-plus-lg>
                          </template>
                        </TheLink>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="totalRecipientsCount">
                  <div class="tw-flex tw-gap-2 tw-items-center tw-h-60">
                    <img
                      style="max-height: 200px; width: auto"
                      class=""
                      src="../../assets/svg/illustrations-test/7_medal_award.png"
                      alt="Image Description"
                    />
                    <div class="tw-flex tw-flex-col tw-gap-4">
                      <div>
                        <h2
                          class="tw-text-3xl tw-font-bold tw-leading-6 tw-text-gray-900"
                        >
                          Great job on completing a major milestone!
                        </h2>
                        <p
                          class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0 tw-p-0"
                        >
                          Next up, create a campaign and impress your new
                          recipient with a thoughtful gift.
                        </p>
                      </div>
                      <div>
                        <TheButton
                          :hasLeadingIcon="true"
                          variant="primary"
                          @click="createCampaign()"
                          size="large"
                        >
                          <template #text>Create campaign</template>
                          <template #leading-icon>
                            <b-icon-plus-lg></b-icon-plus-lg>
                          </template>
                        </TheButton>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
              <template v-if="item.type === 'campaigns'">
                <div class="tw-flex tw-gap-2 tw-items-center tw-h-60">
                  <img
                    style="max-height: 200px; width: auto"
                    class=""
                    src="../../assets/svg/illustrations-test/2_no_campaigns_exist.png"
                    alt="Image Description"
                  />
                  <div class="tw-flex tw-flex-col tw-gap-2">
                    <h2
                      class="tw-text-2xl tw-font-bold tw-leading-6 tw-text-gray-900 tw-m-0"
                    >
                      Start strong and stand out
                    </h2>
                    <p
                      class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0 tw-p-0"
                    >
                      Click here to kick off your first campaign.
                    </p>
                    <div>
                      <TheButton
                        :hasLeadingIcon="true"
                        variant="primary"
                        @click="createCampaign()"
                        size="large"
                      >
                        <template #text>Create campaign</template>
                        <template #leading-icon>
                          <b-icon-plus-lg></b-icon-plus-lg>
                        </template>
                      </TheButton>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </ul>
        </li>
      </ul>
    </TheCard>
  </template>
</template>

<script>
import { getDefaultPaymentInformation } from "@/services/stripeService";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import NewRecipientForm from "../../components-v2/ui/modals/NewRecipientForm.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";

export default {
  name: "NewUserChecklist",
  emits: ["createCampaign", "handleSuccess", "handleError"],
  components: { TheCard, TheButton, TheLink, NewRecipientForm, TheModal },
  props: {
    accountSetupChoices: {
      type: Object,
      required: false,
    },
    totalCampaignsSentCount: {
      type: Number,
      default: 0,
    },
    totalRecipientsCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      landMeetingsContent: [
        {
          type: "account",
          title: "Set up your account",
          subtitle: "It's official, your account is ready to rock and roll!",
          showContent: false,
        },
        {
          type: "recipients",
          title: "Grow your prospect list",
          subtitle:
            "Your future clients are just a click away. Start by adding them to your circle.",
          showContent: false,
        },
        {
          type: "campaigns",
          title: "Send your first gift card campaign",
          subtitle:
            "Turn prospects into meetings by sending a gift that stands out.",
          showContent: false,
        },
      ],
      sendThankYousContent: [
        {
          type: "account",
          title: "Set up your account",
          subtitle: "It's official, your account is ready to rock and roll!",
          showContent: false,
        },
        {
          type: "recipients",
          title: "Populate your network list",
          subtitle:
            "List the business partners and influencers who've made a difference in your journey.",
          showContent: false,
        },
        {
          type: "campaigns",
          title: "Craft a personalized thank you gift campaign",
          subtitle:
            "Express your gratitude and nurture your network with a thoughtful gift.",
          showContent: false,
        },
      ],
      showAppreciationContent: [
        {
          type: "account",
          title: "Set up your account",
          subtitle: "It's official, your account is ready to rock and roll!",
          showContent: false,
        },
        {
          type: "recipients",
          title: "Add your cherished clients",
          subtitle: "Add the clients and customers who've supported your work.",
          showContent: false,
        },
        {
          type: "campaigns",
          title: "Craft a tailored appreciation campaign",
          subtitle:
            "Send a heartfelt thank you and fortify your bonds of loyalty today.",
          showContent: false,
        },
      ],
      defaultListContent: [
        {
          type: "account",
          title: "Set up your account",
          subtitle: "It's official, your account is ready to rock and roll!",
          showContent: false,
        },
        {
          type: "recipients",
          title: "Add your first recipients",
          subtitle: "Lets add some contacts and start making an impact.",
          showContent: false,
        },
        {
          type: "campaigns",
          title: "Send your first campaign",
          subtitle: "Choose and send a gift card your recipient will love! ",
          showContent: false,
        },
      ],
      newRecipient: {},
      defaultPaymentInformation: {},
      hasDefaultPaymentMethod: false,
      dataIsLoading: false,
      showNewRecipientModal: false,
      recipientIsReadyToSave: false,
    };
  },
  async created() {
    await this.getDefaultPaymentInformation();
  },
  computed: {
    User() {
      return this.$store?.state?.auth?.user;
    },
    userId() {
      return this.$store?.state?.auth?.user?._id;
    },
    isChecklistComplete() {
      return (
        this.totalCampaignsSentCount &&
        this.totalRecipientsCount &&
        this.paymentInformation
      );
    },
    checkListContent() {
      if (
        this?.User?.metadata?.accountSetup?.question_one_selection.includes(
          "Land more meetings"
        )
      ) {
        return this.landMeetingsContent;
      } else if (
        this?.User?.metadata?.accountSetup?.question_one_selection.includes(
          "Send thank you gifts"
        )
      )
        return this.sendThankYousContent;
      else if (
        this?.User?.metadata?.accountSetup?.question_one_selection.includes(
          "Show appreciation"
        )
      ) {
        return this.showAppreciationContent;
      } else {
        return this.defaultListContent;
      }
    },
    /** payment information */
    paymentInformation() {
      return !!this?.defaultPaymentInformation?.card;
    },
  },
  methods: {
    /** emits */
    createCampaign() {
      this.toggleListItem("campaigns");
      this.$emit("createCampaign");
    },
    /** toggle list */
    toggleListItem(selectedItem) {
      this.checkListContent.forEach((item) => {
        if (item === selectedItem) {
          item.showContent = !item.showContent;
        } else {
          item.showContent = false;
        }
        if (item.children) {
          item.children.forEach((child) => {
            child.showContent = false;
          });
        }
      });
    },

    /** handle retrieving default payment information */
    async getDefaultPaymentInformation() {
      this.dataIsLoading = true;

      try {
        const defaultPaymentData = await getDefaultPaymentInformation(
          this.userId
        );

        const paymentInformation = defaultPaymentData;
        this.defaultPaymentInformation = paymentInformation;
      } catch (error) {
        if (
          error?.response?.data?.error?.code === 404 &&
          error?.response?.data?.error?.status === "no_payment_method_found"
        ) {
          console.error(error);
        } else {
          this.handleErrorFromAPI(
            error,
            "Something went wrong while attempting to retrieve your payment information from Stripe. Please reload the page and try again."
          );
          console.log("defaultPaymentRes error: ", error);
        }
      } finally {
        this.dataIsLoading = false;
      }
    },
    /** new recipient */
    setNewRecipient(recipient) {
      this.recipientIsReadyToSave = recipient.status;
      this.newRecipient = recipient;
    },
    async saveNewRecipient() {
      const api = await this.getBoldClient();
      const giftingLeads = [this.userId];

      // Function to recursively filter out non-truthy properties
      function filterTruthyProperties(obj) {
        return Object.keys(obj).reduce((acc, key) => {
          if (obj[key] && typeof obj[key] === "object") {
            const nested = filterTruthyProperties(obj[key]);
            if (Object.keys(nested).length > 0) {
              acc[key] = nested;
            }
          } else if (obj[key]) {
            acc[key] = obj[key];
          }
          return acc;
        }, {});
      }

      let filteredContact = filterTruthyProperties(this.newRecipient.contact);

      // Construct the recipient object, conditionally adding the client field
      const recipient = {
        ...(this.userClient && { client: this.userClient }), // Conditionally add client
        firstName: this.newRecipient.recipient.firstName,
        lastName: this.newRecipient.recipient.lastName,
        metadata: {
          giftingLeads,
        },
        contact: filteredContact,
      };

      let recipientsArr = [recipient];


      // Uncomment for actual API interaction
      try {
        const recipientResponse = await api.createRecipients(
          null,
          recipientsArr
        );
        this.handleSuccess(recipientResponse);
      } catch (error) {
        this.handleError(error);
        console.log("recipient was NOT created", error);
      }
    },
    toggleNewRecipientModal() {
      this.showNewRecipientModal = !this.showNewRecipientModal;
    },
    handleNewContact() {
      this.showNewRecipientModal = false;
      this.handleSuccess();
      this.$emit("handleSuccess");
    },
    handleSuccess(recipientResponse) {
      this.$emit("handleSuccess", {
        recipientResponse: recipientResponse,
        action: "saveNewRecipient",
      });
      this.closeModal();
    },
    closeModal() {
      this.showNewRecipientModal = false;
    },
  },
};
</script>

<style scoped></style>
