<template>
  <template v-if="dataInfoArray">
    <div class="tw-flex tw-flex-col tw-gap-2">
      <div>
        <h2 class="tw-text-2xl tw-font-bold tw-text-blue-800 tw-m-0">
          Almost there!
        </h2>
        <p class="tw-text-lg tw-font-bold tw-m-0">
          We’re getting your contacts set up for you...
        </p>
      </div>
      <ProgressTracker :progress="recipientsImportStatus"></ProgressTracker>
    </div>
  </template>
</template>

<script>
import ProgressTracker from "../feedback/ProgressTracker.vue";
export default {
  name: "RecipientsImportStatus",
  components: { ProgressTracker },
  props: {
    recipientsImportStatus: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      dataTypes: {
        recipients: this.recipientsImportStatus,
      },
    };
  },
  computed: {
    dataInfoArray() {
      const dataTypes = [
        { type: "recipients", data: this.recipientsImportStatus },
      ];

      return dataTypes.filter((dataInfo) => dataInfo.data);
    },
  },
  methods: {
    confirm() {
      this.$emit("confirmDelete");
    },
    cancel() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped></style>
