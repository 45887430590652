<template>
    <div class="tw-flex tw-flex-col xl:tw-flex-row tw-gap-2 tw-p-4 sm:tw-p-8" :class="componentClass">
        <slot></slot>
    </div>
</template>
  
<script>

export default {
    name: 'ThePage',
    props: {
        id: {
            type: String,
            default: ''
        },
        role: {
            default: null
        },
        /** 
         * Set to full width by default
         * Use a narrow width layout if the page supports a single unified task. 
         * This layout helps focus attention in a single path from top to bottom in order to complete a specific task.
         */

        fullWidth: {
            type: Boolean,
            default: false
        },
        autoWidth: {
            type: Boolean,
            default: false
        },
        justifyCenter: {
            type: Boolean,
            default: false
        },
        customWidthClass: {
            type: String,
            default: null
        }

    },
    computed: {
        componentClass() {
            return {
                'tw-w-full tw-mx-auto': this.fullWidth,
                'tw-w-auto tw-mx-auto': this.autoWidth,
                'tw-items-center tw-justify-center': this.justifyCenter,
                // Add customWidth class if customWidth is provided
                [this.customWidthClass]: !!this.customWidthClass
            };
        }
    }
};
</script>
  