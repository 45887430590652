<template>
  <ThePage>
    <PageSection
      :title="recipientPageSectionTitle"
      customClass="tw-w-full tw-max-w-5xl"
      :showTooltip="getCampaignRecipients?.length > 1"
      :tooltipContent="recipientsSectionTooltipContent"
      :showActions="getCampaignRecipients?.length > 0"
    >
      <template #page-header-actions v-if="getCampaignRecipients?.length > 0">
        <div class="tw-flex tw-items-center tw-gap-2">
          <p class="tw-bg-green-50 tw-text-green-700 tw-px-2 tw-py-1 tw-m-0">
            {{ getCampaignRecipients?.length }} recipients selected
          </p>
          <TheLink variant="primary" @click="toggleCampaignRecipients">
            <template #text>
              {{
                !getCampaignRecipientsVisibleValue
                  ? "View selection"
                  : "Add more"
              }}
            </template>
          </TheLink>
        </div>
      </template>
      <template
        v-if="
          getCampaignRecipientsIds.length === 0 ||
          !getCampaignRecipientsVisibleValue
        "
      >
        <!-- <RecipientsIndexTableV2
          :campaignId="this.campaign._id"
          :campaignView="true"
          @setCampaignRecipients="setCampaignRecipients"
        >
        </RecipientsIndexTableV2> -->
        <RecipientProfilesTable
          :campaignView="true"
          :campaignId="this.campaign._id"
          @setCampaignRecipients="setCampaignRecipients"

        ></RecipientProfilesTable>
      </template>
      <template
        v-if="
          getCampaignRecipientsIds.length > 0 &&
          getCampaignRecipientsVisibleValue
        "
      >
        <div class="tw-flex tw-flex-col tw-w-full tw-gap-4">
          <CampaignRecipientsSelection
            :campaignRecipients="getCampaignRecipients"
            @removeSelectedCampaignRecipients="removeSelectedCampaignRecipients"
            @toggleCampaignRecipients="toggleCampaignRecipients"
          >
          </CampaignRecipientsSelection>
        </div>
      </template>
    </PageSection>
  </ThePage>
</template>

<script>
import CampaignRecipientsSelection from "../../../components-v2/campaigns/CampaignRecipientsSelection";
import ThePage from "../../../components-v2/ui/layout/ThePage.vue";
import TheLink from "../../../components-v2/ui/actions/TheLink.vue";
import RecipientsIndexTableV2 from "../../../components-v2/recipients/RecipientsIndexTableV2";
import PageSection from "../../../components-v2/ui/layout/PageSection.vue";
import RecipientProfilesTable from "../../../components-v2/recipients/RecipientProfilesTable";

export default {
  name: "TheRecipients",
  emits: ["loadSteps"],
  components: {
    RecipientProfilesTable,
    CampaignRecipientsSelection,
    ThePage,
    TheLink,
    RecipientsIndexTableV2,
    PageSection,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  async created() {},
  data() {
    return {
      // areCampaignRecipientsVisible: false,
    };
  },
  computed: {
    campaignRecipients() {
      return (
        this?.campaign?.recipients?.map((recipient) => recipient._id) || []
      );
    },
    getCampaignRecipients() {
      return this.$store.getters["campaigns/getCampaignRecipients"];
    },
    getCampaignRecipientsVisibleValue() {
      return this.$store.getters["campaigns/getCampaignRecipientsVisibleValue"];
    },
    getCampaignRecipientsIds() {
      return this.$store.getters["campaigns/getCampaignRecipientsIds"];
    },

    recipientsSectionTooltipContent() {
      return "Currently, multi-recipient campaigns can only be sent using a shareable link.";
    },
    recipientPageSectionTitle() {
      return this?.getCampaignRecipients?.length === 0
        ? "Pick recipients for this campaign"
        : "Great, you've picked recipients for your campaign!";
    },
  },
  methods: {
    /** handle adding/removing recipients */
    setCampaignRecipients(newRecipients) {
      let existingRecipients = this.getCampaignRecipients;
      const existingIds = new Set(this.getCampaignRecipients.map((r) => r.id));
      const recipientsToAdd = newRecipients.filter(
        (recipient) => !existingIds.has(recipient.id)
      );

      existingRecipients = [...existingRecipients, ...recipientsToAdd];

      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_RECIPIENTS",
        existingRecipients
      );
      this.$store.commit("campaigns/SHOW_CAMPAIGN_BUILDER_RECIPIENTS_VISIBLE");
      // this.$emit("showCampaignRecipients");
      this.$emit("loadSteps");
    },
    removeSelectedCampaignRecipients(recipientsToRemove) {
      const idsToRemove = new Set(recipientsToRemove.map((r) => r.id));
      const newRecipients = this.getCampaignRecipients.filter(
        (recipient) => !idsToRemove.has(recipient.id)
      );
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_RECIPIENTS",
        newRecipients
      );
      this.$emit("loadSteps");
    },

    /** utility handlers */
    toggleCampaignRecipients() {
      // this.areCampaignRecipientsVisible = !this.areCampaignRecipientsVisible;
      this.$store.commit(
        "campaigns/TOGGLE_CAMPAIGN_BUILDER_RECIPIENTS_VISIBLE"
      );
    },
    showCampaignRecipients() {
      // this.areCampaignRecipientsVisible = true;
      this.$store.commit("campaigns/SHOW_CAMPAIGN_BUILDER_RECIPIENTS_VISIBLE");
    },
  },
};
</script>

<style scoped></style>
