<template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <!-- <PageHeader :showActions="true" title="Calendar Sync"> </PageHeader> -->
  <template v-if="!isFinishingLinking">
    <ThePage customWidthClass="tw-max-w-3xl tw-mx-auto">
      <PageSection class="tw-w-full tw-mt-20 tw-mx-auto">
        <div class="tw-mx-auto tw-lg:tw-max-w-7xl tw-px-6 tw-lg:px-8">
          <div
            class="tw-mx-auto tw-max-w-3xl tw-flex tw-justify-center tw-flex-col tw-text-center"
          >
            <img
              class="tw-h-12 tw-w-auto tw-flex-shrink-0 tw-mx-auto"
              src="../../assets/svg/brands/salesforce.png"
              alt=""
            />
            <h2
              class="tw-text-base tw-mt-2 tw-font-semibold tw-leading-7 tw-text-indigo-400 tw-uppercase"
            >
              contacts sync
            </h2>
            <p
              class="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-5xl"
            >
              Salesforce integration
            </p>
            <p class="tw-text-xl tw-leading-8 tw-text-gray-700">
              Bold Xchange allows you to easily bring Salesforce contacts into
              your Bold Xchange account. Streamline your workflow by connecting
              your Salesforce account to Bold Xchange.
            </p>
            <div class="tw-mx-auto">
              <template v-if="hasSalesforceTokens">
                <div
                  class="tw-flex tw-gap-2 tw-items-center tw-text-lg tw-bg-white tw-rounded tw-p-4"
                >
                  <b-icon-check-circle-fill
                    class="tw-text-green-700"
                  ></b-icon-check-circle-fill>
                  <p class="tw-m-0 tw-font-semibold tw-text-green-700">
                    Your account is connected
                  </p>
                </div>
              </template>
              <template v-else>
                <div>
                  <TheButton
                    :hasLeadingIcon="true"
                    variant="tertiary"
                    @click="startLinkingSalesforce()"
                    size="large"
                    :disabled="connectingCalendar"
                  >
                    <template #leading-icon>
                      <img
                        class="tw-h-6 tw-w-auto tw-flex-shrink-0"
                        src="../../assets/svg/brands/salesforce.png"
                        alt=""
                      />
                    </template>

                    <template #text>
                      <p class="tw-ml-4 tw-m-0">Connect to Salesforce</p>
                    </template>
                  </TheButton>
                </div>
              </template>
            </div>
          </div>
        </div>
      </PageSection>
    </ThePage>
  </template>
</template>

<script>
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";

export default {
  name: "SalesforceIntegration",
  components: {
    TheButton,
    ThePage,
    PageSection,
    NotificationMessage,
  },
  async mounted() {
    const code = this.$route.query.code;
    console.log("code", code);
    if (code) {
      await this.finishLinkingSalesforce(code);
    }
    await this.refreshClient();
  },
  data() {
    return {
      isFinishingLinking: false,
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    hasSalesforceTokens() {
      return this?.$store?.state?.auth?.user?.metadata?.salesforce?.tokens;
    },
  },
  methods: {
    async finishLinkingSalesforce(code) {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */
        const api = await this.getBoldClient();
        this.isFinishingLinking = true;

        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/salesforce`,
          code: code,
        };

        const response = await api.finishLinkingSalesforce(redirectUri);
        console.log("salesforceResponse", response);
      } catch (error) {
        console.error("Failed to finish linking salesforce", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isFinishingLinking = false;
        this.$router.push("/integrations");
      }
    },
    async startLinkingSalesforce() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */
        const api = await this.getBoldClient();
        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/salesforce`,
        };

        const response = await api.startLinkingSalesforce(redirectUri);
        console.log("salesforceResponse", response);
        const salesforceUrlRedirect = response?.data?.url;
        window.location.href = salesforceUrlRedirect;
      } catch (error) {
        console.error("Failed to link salesforce", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isFinishingLinking = false;
      }
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
  },
};
</script>
<style scoped></style>
