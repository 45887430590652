<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <div class="page-header">
        <div class="d-flex justify-content-between">
          <h1 class="page-header-title text-start">Neighborhoods</h1>
        </div>
      </div>
      <div class="mb-1">
        <div class="input-group input-group-merge">
          <!-- <div class="input-group-prepend input-group-text">
                  <b-icon-search></b-icon-search>
                </div> -->
          <input
            @keyup.enter="getNeighborhoodsBySearchQuery()"
            type="text"
            id="datatableSearch"
            class="form-control"
            placeholder="Search by name"
            aria-label="Search name"
            v-model="searchQuery"
          />
          <button
            type="button"
            class="btn btn-secondary"
            @click="getNeighborhoodsBySearchQuery()"
          >
            Search<b-icon-search class="ms-2"></b-icon-search>
          </button>
        </div>
        <div v-if="neighborhoodsAreLoading" class="text-start mt-1">
          <span
            class="spinner-border spinner-border-sm me-1"
            aria-hidden="true"
          ></span>
          <span role="status">Loading...</span>
        </div>
        <p class="mt-1 text-start" v-else>
          <span class="badge bg-warning">{{ getQueryCount }}</span>
          Results
        </p>
        <div class="text-danger text-start mt-1" v-if="!doesNeighborhoodsExist">
          There are no neighborhoods in our database that match your search.
        </div>
      </div>
      <!-- End Search -->
      <div class="mt-3">
        <div class="text-start">
          <template v-if="neighborhoods.length === 0 && !noNeighborhoodsExist">
            <h4>Selected neighborhoods</h4>
            <p class="text-info fs-4">Please select neighborhoods</p>
          </template>
          <template v-if="noNeighborhoodsExist">
            <p class="text-danger fs-4">
              No neighborhoods have been created. Please close this modal and
              create one.
            </p>
          </template>
        </div>
        <template v-if="count"
          ><div class="d-flex flex-row flex-wrap justify-content-left mt-1">
            <ul
              v-for="neighborhood in selectedNeighborhoods"
              :key="neighborhood.index"
              class="list-unstyled"
            >
              <li
                class="bg-soft-secondary rounded me-1 px-2 py-1"
                @click="toggleNeighborhoodSelection(neighborhood)"
              >
                <b-icon-x-circle class="text-danger"></b-icon-x-circle>
                <span class="text-dark ms-1">{{ neighborhood }}</span>
              </li>
            </ul>
          </div></template
        >
      </div>
      <template v-if="count">
        <div class="card mt-3">
          <ul
            class="list-group list-group-flush list-group-numbered text-start"
          >
            <li
              @click="toggleNeighborhoodSelection(neighborhood)"
              :class="{ selected: isNeighborhoodSelected(neighborhood) }"
              v-for="neighborhood in neighborhoods"
              :key="neighborhood.index"
              class="list-group-item list-group-item-action"
            >
              {{ neighborhood }}
            </li>
          </ul>
        </div>
      </template>
      <template v-if="!noNeighborhoodsExist"
        ><div class="d-flex justify-content-end mt-3">
          <button
            type="button"
            class="btn btn-primary"
            @click="confirmSelection()"
          >
            Confirm Selection
          </button>
        </div></template
      >
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "NeighborhoodsListModal",
  components: {
    FixedModalSmall,
  },
  created() {
    this.getAllNeighborhoods();
  },
  data() {
    return {
      searchQuery: "",
      searchValueExists: false,
      doesNeighborhoodsExist: true,
      count: 0,
      neighborhoods: [],
      neighborhoodsAreLoading: false,
      selectedNeighborhoods: [], // New array to store selected categories
      noNeighborhoodsExist: false,
    };
  },

  watch: {
    searchQuery() {
      if (this.searchQuery === "") {
        this.searchCount = 0;
        this.getAllNeighborhoods();
      }
      this.searchValueExists = false;
    },
  },
  computed: {
    getQueryCount() {
      return this.count;
    },
    getSearchQuery() {
      return this.searchQuery;
    },
  },
  methods: {
    async getAllNeighborhoods() {
      try {
        // Get the API client
        const api = await this.getBoldClient();

        this.doesNeighborhoodsExist = true;
        this.neighborhoodsAreLoading = true;

        const pageSize = 300;

        const neighborhoodsResponse = await api.listVendors({
          limit: pageSize,
          sort: "-name",
          distinct: "metadata.neighborhoods",
          expand: "metadata",
        });

        // Extract neighborhoods data
        const neighborhoods =
          neighborhoodsResponse?.data?.result?.records || [];

        // Update the neighborhoods and counts
        this.neighborhoods = neighborhoods;
        const count = neighborhoodsResponse.data.result.records.length;
        this.noNeighborhoodsExist = count === 0;
        this.count = count;

        // Reset loading flags
        this.neighborhoodsAreLoading = false;

        // Log neighborhoods if needed
        // console.log(neighborhoods);
      } catch (error) {
        console.error("Error fetching neighborhoods:", error);
        this.neighborhoodsAreLoading = false;
      }
    },
    async getNeighborhoodsBySearchQuery() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      try {
        const api = await this.getBoldClient();
        this.doesNeighborhoodsExist = true;
        this.neighborhoodsAreLoading = true;

        const pageSize = 300;
        const neighborhoodsResponse = await api.listVendors(
          {
            limit: pageSize,
            sort: "-name",
            distinct: "metadata.neighborhoods",
            expand: "metadata",
          },
          {
            filter: {
              "metadata.neighborhoods": { $in: [this.getSearchQuery] },
            },
          }
        );

        const neighborhoods =
          neighborhoodsResponse?.data?.result?.records || [];

        // Update the neighborhoods and counts
        this.neighborhoods = neighborhoods;
        const count = neighborhoodsResponse.data.result.records.length;
        this.count = count;

        // Reset loading flags
        this.neighborhoodsAreLoading = false;

        // Log neighborhoods if needed
        // console.log(neighborhoods);
      } catch (error) {
        console.error("Error fetching neighborhoods:", error);
        this.count = 0;
        this.neighborhoodsAreLoading = false;
      }
    },
    toggleNeighborhoodSelection(neighborhood) {
      const index = this.selectedNeighborhoods.indexOf(neighborhood);
      if (index === -1) {
        this.selectedNeighborhoods.push(neighborhood);
      } else {
        this.selectedNeighborhoods.splice(index, 1);
      }
    },
    isNeighborhoodSelected(neighborhood) {
      // Check if a neighborhoods is selected
      return this.neighborhoods.includes(neighborhood);
    },
    confirmSelection() {
      // Emit the selected neighborhoods to the parent component
      // console.log("emit confirm selected");
      this.$emit("setNewNeighborhood", this.selectedNeighborhoods);
    },
  },
};
</script>

<style scoped>
ul {
  /* add the following two lines */
  max-height: 20rem;
  overflow-y: auto;
  cursor: pointer;
}

li {
  cursor: pointer;
}
</style>
