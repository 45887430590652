<template>
  <BlockStack :gap="200" :fullWidth="true">
    <div class="tw-w-full tw-flex-1 tw-bg-white tw-rounded tw-p-4">
      <ListSkeleton :numberOfRows="1"></ListSkeleton>
    </div>
  </BlockStack>
</template>

<script>
import BlockStack from "../../../../components-v2/ui/layout/BlockStack.vue";
import ListSkeleton from "../../../../components-v2/ui/skeletons/ListSkeleton.vue";
export default {
  name: "ViewRedemptionsPageSkeleton",
  components: {
    ListSkeleton,
    BlockStack,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
