<template>
  <div class="tw-w-full">
    <h4 class="tw-sr-only">Status</h4>
    <div>
      <div class="tw-overflow-hidden tw-rounded-full tw-bg-gray-200">
        <div
          class="tw-h-2 tw-rounded-full tw-bg-green-600"
          :style="progressBarStyle"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressTracker",
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progressBarStyle() {
      return {
        width: `${this.progress}%`,
      };
    },
  },
};
</script>

<style scoped></style>
