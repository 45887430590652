// store/modules/alerts/index.js
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { ALERT_TYPES } from "../../../constants/alertTypes";

const state = {
  alertTypes: ALERT_TYPES,
  alerts: [],
  alertTimeouts: {}, // New object to track alert timeouts by their ID
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
