<template>
  <div class="tw-relative tw-z-50">
    <!-- Content -->
    <div
      v-for="(slide, index) in list"
      :key="index"
      class="tw-h-full tw-w-full"
    >
      <div
        v-show="currentSlide === index + 1"
        class="tw-aspect-h-7 tw-aspect-w-10 tw-w-full tw-overflow-hidden tw-rounded-lg tw-bg-gray-100"
      >
        <img
          :src="slide.url"
          alt=""
          class="tw-h-full tw-w-full tw-pointer-events-none tw-object-cover"
        />
      </div>
    </div>

    <!-- Navigation -->
    <div
      class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-w-full tw-px-1"
    >
      <div
        class="tw-flex"
        :class="{
          'tw-justify-between': currentSlide !== 1,
          'tw-justify-end': currentSlide === 1,
        }"
      >
        <!-- Show left chevron only when currentSlide is not the first slide -->
        <b-icon-chevron-left
          v-if="currentSlide !== 1"
          @click.stop="prevSlide()"
          class="tw-rounded-full tw-h-8 tw-w-8 tw-bg-white tw-text-lg tw-p-1 hover:tw-bg-slate-200"
        ></b-icon-chevron-left>

        <!-- Show right chevron only when currentSlide is not the last slide -->
        <b-icon-chevron-right
          v-if="currentSlide !== slideCount"
          @click.stop="nextSlide()"
          class="tw-rounded-full tw-h-8 tw-w-8 tw-bg-white tw-text-lg tw-p-1 hover:tw-bg-slate-200"
        ></b-icon-chevron-right>
      </div>
      <!-- <div v-if="pagination" class="pagination tw-absolute tw-w-full tw-top-20 tw-z-10">
        <span
          @click="goToSlide(index)"
          v-for="(slide, index) in slideCount"
          :key="index"
          class="tw-cursor-pointer tw-w-5 tw-h-5 tw-rounded-full tw-mb-0"
          :class="{ 'tw-bg-white/70': index + 1 !== currentSlide, 'tw-bg-blue-200': index + 1 === currentSlide }"
        >
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    startAutoPlay: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 5000,
    },
    list: {
      type: Array,
      default: null,
    },
    navigation: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentSlide: 1,
    };
  },
  computed: {
    slideCount() {
      return this.list.length;
    },
  },
  methods: {
    nextSlide() {
      if (this.currentSlide === this.slideCount) {
        this.currentSlide = 1;
        return;
      }
      this.currentSlide += 1;
    },
    prevSlide() {
      if (this.currentSlide === 1) {
        this.currentSlide = this.slideCount;
        return;
      }
      this.currentSlide -= 1;
    },
    goToSlide(index) {
      this.currentSlide = index + 1;
    },
    autoPlay() {
      setInterval(() => {
        this.nextSlide();
      }, this.timeout);
    },
  },
  mounted() {
    if (this.startAutoPlay) {
      this.autoPlay();
    }
  },
};
</script>
<style scoped>
/* span {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.active {
  background-color: #6347c7;
} */
</style>
