
export default {
  SET_USER(state, user) {
    state.user = user;
    state.userId = user && user._id;

  },

  SET_TOKEN(state, token) {
    state.token = token;
    window.localStorage.setItem("jwt", token);
  },
  LOGOUT(state) {
    state.token = undefined;
    state.user = undefined;
    window.localStorage.clear();
    if (this?._vm?.$router) {

      this._vm.$router.push?.({ path: "/login" });
    } else {
      window.location = '/';
    }
    // location.reload();
  },
  SET_TARGET_ROUTE(state, route) {
    state.targetRoute = route;
  },
};
