import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      quickEditActive: false,
      googleUrlRedirect: null, 
      office365UrlRedirect: null,
      salesforceUrlRedirect: null,
    };
  },
  mutations,
  getters,
  actions,
};
