// store/modules/alerts/actions.js
export default {
  showAlert({ commit }, alert) {
    commit("ADD_ALERT", alert);
    // Set and track the timeout
    const timeoutId = setTimeout(() => {
      commit("REMOVE_ALERT", { id: alert.id });
      // Also, remove the timeout ID from the tracking object
      commit("REMOVE_TIMEOUT", alert.id);
    }, alert.duration);

    // Store the timeout ID in the state
    commit("ADD_TIMEOUT", { id: alert.id, timeoutId });
  },
  removeAlert({ commit }, { id, scope }) {
    commit("REMOVE_ALERT", { id, scope });
  },
  
};
