<template>
  <template v-if="showNewRecipientModal">
    <Teleport to="#modal">
      <WideModal
        title="Recipient details"
        @closeModal="closeModal"
        :open="showRecipientDetailsModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <!-- <template #modal-body> -->
        <EditRecipientForm
          @closeModal="closeModal"
          @handleSuccess="handleSuccess"
          :recipient="recipient"
        ></EditRecipientForm>
        <!-- </template> -->
      </WideModal>
    </Teleport>
  </template>
  <PageHeader title="Recipient details"> </PageHeader>
  <ThePage customWidthClass="tw-w-full tw-mx-auto tw-justify-center">
    <template v-if="recipientIsLoading">
      <div class="tw-max-w-3xl tw-w-full tw-mx-auto">
        <PulseAnimation>
          <ListSkeleton :numberOfRows="7"></ListSkeleton>
        </PulseAnimation>
      </div>
    </template>
    <template v-else>
      <BlockStack :gap="800" :fullWidth="true">
        <template v-if="campaignsAreLoading">
          <LoadingGiftIcon></LoadingGiftIcon>
        </template>

        <template v-else>
          <PageSection :fullWith="true">
            <template v-if="api.success">
              <div
                class="tw-border-l-4 tw-border-green-400 tw-bg-green-50 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-items-center tw-gap-4 tw-max-w-3xl tw-w-full tw-mx-auto"
              >
                <b-icon-check-circle
                  class="tw-text-green-700 tw-text-lg"
                ></b-icon-check-circle>
                <p class="tw-text-sm tw-m-0 tw-text-start tw-text-green-700">
                  {{ api.message }}
                </p>
              </div>
            </template>
            <TheCard customWidthClass="tw-max-w-3xl tw-w-full tw-mx-auto">
              <CardHeader :showTitle="true" title="Details" :showActions="true">
                <template #card-header-actions>
                  <BaseButton
                    variant="primary"
                    size="small"
                    :hasLeadingIcon="true"
                    @click="toggleEditRecipient()"
                  >
                    <template #leading-icon>
                      <b-icon-pencil></b-icon-pencil>
                    </template>

                    <template #text> Edit</template>
                  </BaseButton>
                </template>
              </CardHeader>

              <TheBox :noTopPadding="true">
                <div class="tw-w-auto">
                  <dl
                    class="tw-mt-0 tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-gray-100 tw-border-t tw-border-gray-200 tw-text-sm tw-leading-6"
                  >
                    <div class="sm:tw-flex">
                      <dt
                        class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                      >
                        First name
                      </dt>
                      <dd
                        class="tw-flex tw-flex-col tw-justify-between tw-gap-x-6"
                      >
                        <template v-if="firstName">
                          <p class="tw-text-gray-900 tw-m-0">
                            {{ firstName }}
                          </p>
                        </template>
                      </dd>
                    </div>
                    <div class="tw-pt-4 sm:tw-flex">
                      <dt
                        class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                      >
                        Last name
                      </dt>
                      <dd
                        class="tw-flex tw-flex-col tw-justify-between tw-gap-x-6"
                      >
                        <template v-if="lastName">
                          <p class="tw-text-gray-900 tw-m-0">
                            {{ lastName }}
                          </p>
                        </template>
                      </dd>
                    </div>
                    <div class="tw-pt-4 sm:tw-flex">
                      <dt
                        class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                      >
                        Email
                      </dt>
                      <dd
                        class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto"
                      >
                        <template v-if="email">
                          <p class="tw-text-gray-900 tw-m-0">
                            {{ email }}
                          </p>
                        </template>
                      </dd>
                    </div>
                    <div class="tw-pt-6 sm:tw-flex">
                      <dt
                        class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                      >
                        Phone
                      </dt>
                      <dd
                        class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto tw-font-normal"
                      >
                        <template v-if="phone">
                          <p class="tw-text-gray-900 tw-capitalize tw-m-0">
                            {{ phone }}
                          </p>
                        </template>
                      </dd>
                    </div>
                    <div class="tw-pt-6 sm:tw-flex">
                      <dt
                        class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                      >
                        Company
                      </dt>
                      <dd class="tw-flex tw-flex-col tw-m-0 tw-font-normal">
                        <template v-if="company">
                          <p class="tw-text-gray-900 tw-capitalize tw-m-0">
                            {{ company }}
                          </p>
                        </template>
                      </dd>
                    </div>
                    <div class="tw-pt-6 sm:tw-flex">
                      <dt
                        class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                      >
                        Title
                      </dt>
                      <dd
                        class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto tw-font-normal"
                      >
                        <template v-if="title">
                          <p class="tw-text-gray-900 tw-capitalize tw-m-0">
                            {{ title }}
                          </p>
                        </template>
                      </dd>
                    </div>
                    <div class="tw-pt-6 sm:tw-flex">
                      <dt
                        class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                      >
                        Lifecycle Stage
                      </dt>
                      <dd
                        class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto tw-font-normal"
                      >
                        <template v-if="lifecycleStage">
                          <p class="tw-text-gray-900 tw-m-0">
                            {{ lifecycleStage }}
                          </p>
                        </template>
                      </dd>
                    </div>
                  </dl>
                </div>
              </TheBox>
            </TheCard>
          </PageSection>
        </template>
      </BlockStack>
    </template>
  </ThePage>
</template>

<script>
import LoadingGiftIcon from "../../components/ui/LoadingGiftIcon.vue";
import EditRecipientForm from "../../components-v2/ui/modals/EditRecipientForm.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader";
import ThePage from "../../components-v2/ui/layout/ThePage";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import PageSection from "../../components-v2/ui/layout/PageSection";
import TheBox from "../../components-v2/ui/layout/TheBox";
import TheCard from "../../components-v2/ui/layout/TheCard";
import CardHeader from "../../components-v2/ui/layout/CardHeader";
import BaseButton from "../../components-v2/ui/actions/BaseButton";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import WideModal from "../../components-v2/ui/modals/WideModal.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import ListSkeleton from "../../components-v2/ui/skeletons/ListSkeleton.vue";

import moment from "moment";
export default {
  name: "RecipientDetails",
  components: {
    WideModal,
    LoadingGiftIcon,
    EditRecipientForm,
    PageHeader,
    ThePage,
    BlockStack,
    PageSection,
    TheBox,
    TheCard,
    CardHeader,
    BaseButton,
    TheModal,
    ListSkeleton,
    PulseAnimation,
  },
  data() {
    return {
      api: {
        error: 0,
        success: 0,
        message: "",
      },
      recipient: {},
      recipientIsLoading: true,
      showNewRecipientModal: false,
      isEditingRecipient: false,
    };
  },
  created() {
    /** load data */
    this.loadRecipientInformation();
  },
  computed: {
    Avatar() {
      if (this.firstName && this.lastName) {
        return `https://api.dicebear.com/7.x/initials/svg?seed=${this.firstName}%20${this.lastName}&backgroundColor=3949ab`;
      }
      return false;
    },
    firstName() {
      return this?.recipient?.firstName;
    },
    lastName() {
      return this?.recipient?.lastName;
    },
    email() {
      return this?.recipient?.contact?.email;
    },
    phone() {
      return this?.recipient?.contact?.phone;
    },
    company() {
      return this?.recipient?.contact?.company;
    },
    title() {
      return this?.recipient?.contact?.title;
    },
    lifecycleStage() {
      return this?.recipient?.contact?.metadata?.lifecycleStage;
    },
    formattedDate() {
      const date = moment(this.member.createdAt).format("MM/DD/YYYY");
      return date;
    },
  },
  methods: {
    async loadRecipientInformation() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      const recipientId = this.$route.params.recipientId;
      this.recipientIsLoading = true;

      let requestBody = {
        _id: recipientId,
      };

      let selection = "firstName lastName contact";

      const queryParameters = {
        limit: 1,
        expand: "contact",
      };

      try {
        const response = await api.listRecipients(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.recipientIsLoading = false;
        this.recipient = response.data.result.records[0];
      } catch (error) {
        console.log("recipients did not load", error);
        this.recipientIsLoading = false;
      }
    },

    /** modals */
    toggleEditRecipient() {
      this.showNewRecipientModal = !this.showNewRecipientModal;
    },
    closeModal() {
      this.showNewRecipientModal = false;
    },

    /** handle success and errors */
    handleSuccess() {
      this.loadRecipientInformation();
      this.showNewRecipientModal = false;

      // Set the API error state and message
      this.api.success = 1;
      this.api.message = "Recipient was successfully edited.";

      // Reset the error state after a delay
      setTimeout(() => {
        this.api.success = 0;
        this.api.message = "";
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
