<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <div class="page-header">
        <div class="d-flex justify-content-between">
          <h1 class="page-header-title text-start">Addresses</h1>
        </div>
      </div>
      <div class="mb-1">
        <div class="input-group input-group-merge">
          <input
            @keyup.enter="getAddressesBySearchQuery()"
            type="text"
            id="datatableSearch"
            class="form-control"
            placeholder="Search addresses"
            aria-label="Search addresses"
            v-model="searchQuery"
          />
          <button
            type="button"
            class="btn btn-secondary"
            @click="getAddressesBySearchQuery()"
          >
            Search<b-icon-search class="ms-2"></b-icon-search>
          </button>
        </div>
        <div v-if="addressesAreLoading" class="text-start mt-1">
          <span
            class="spinner-border spinner-border-sm me-1"
            aria-hidden="true"
          ></span>
          <span role="status">Loading...</span>
        </div>
        <p class="mt-1 text-start" v-else>
          <span class="badge bg-warning">{{ count }}</span>
          Results
        </p>
        <div class="text-danger text-start mt-1" v-if="!count">
          There are no addresses in our database that match your search.
        </div>
      </div>
      <!-- End Search -->
      <div class="options mt-4">
        <ul class="list-group list-group-flush list-group-numbered text-start">
          <li
            @click="selectAddress(address)"
            v-for="address in addresses"
            :key="address.id"
            class="list-group-item-numbered list-group-item-action"
          >
            <div>
              <p v-if="address?.lines" class="mb-0">
                Address Line 1:
                {{ address.lines[0] ? address.lines[0] : "No information" }}
              </p>
              <p v-if="address?.lines" class="mb-0">
                Address Line 2:
                {{ address.lines[1] ? address.lines[1] : "No information" }}
              </p>
              <p v-if="address?.city" class="mb-0">
                City: {{ address.city ? address.city : "No information" }}
              </p>
              <p v-if="address?.state" class="mb-0">
                State: {{ address.state ? address.state : "No information" }}
              </p>
              <p v-if="address?.postalCode" class="mb-0">
                Postal Code:
                {{ address.postalCode ? address.postalCode : "No information" }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "ClientListModal",
  components: {
    FixedModalSmall,
  },
  data() {
    return {
      searchQuery: "",
      searchValueExists: false,
      doesAddressExist: true,
      count: 0,
      addresses: [],
      addressesAreLoading: false,
    };
  },
  watch: {
    searchQuery() {
      if (this.searchQuery === "") {
        this.searchCount = 0;
        this.getAllAddresses();
      }
      this.searchValueExists = false;
    },
  },
  created() {
    this.getAllAddresses();
  },
  computed: {
    getQueryCount() {
      return this.count;
    },
    getSearchQuery() {
      return this.searchQuery;
    },
  },
  methods: {
    async getAllAddresses() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.addressesAreLoading = true;
      this.doesAddressExist = true;

      const pageSize = 300;

      let requestBody = {};
      let selection = "lines city state postalCode country";
      const queryParameters = {
        limit: pageSize,
        sort: "-state",
      };

      try {
        const response = await api.listAddresses(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        const count = response.data.result.count;
        this.count = count;

        this.addresses = response.data.result.records;
        if (count) {
          this.doesAddressExist = true;
          this.searchValueExists = true;
        } else {
          this.doesAddressExist = true;
          this.searchValueExists = true;
        }
        this.addressesAreLoading = false;
      } catch (error) {
        this.addressesAreLoading = false;
        console.log("addresses did not load", error);
      }
    },
    async getAddressesBySearchQuery() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.addressesAreLoading = true;
      this.doesAddressExist = true;

      const pageSize = 300;

      let requestBody = {
        $or: [
          { city: { $regex: this.getSearchQuery } },
          { state: { $regex: this.getSearchQuery } },
          { postalCode: { $regex: this.getSearchQuery } },
          { lines: { $regex: this.getSearchQuery } },
        ],
      };
      let selection = "lines city state postalCode country";
      const queryParameters = {
        limit: pageSize,
        sort: "-state",
      };

      try {
        const response = await api.listAddresses(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        const count = response.data.result.count;
        this.count = count;
        this.addresses = response.data.result.records;
        if (count) {
          this.doesAddressExist = true;
          this.searchValueExists = true;
        } else {
          this.doesAddressExist = true;
          this.searchValueExists = true;
        }
        this.addressesAreLoading = false;
      } catch (error) {
        this.addressesAreLoading = false;
        console.log("addresses did not load", error);
      }
    },
    selectAddress(address) {
      this.$emit("setNewAddress", address);
    },
  },
};
</script>

<style scoped>
#upload-region {
  width: 100%;
  background-color: #f8fafd;
  border: 0.125rem dashed rgba(231, 234, 243, 0.7);
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

#upload-region.dragover {
  background-color: #f0f0f0;
}

ul {
  /* add the following two lines */
  max-height: 20rem;
  overflow-y: auto;
  cursor: pointer;
}

li {
  /* margin-bottom: 10px; */
}
</style>
