<template>
  <TheCard class="tw-overflow-visible tw-w-full">
    <CardHeader
      :showTitle="true"
      :showSubheading="this.isBoldXchangeAdmin || this.isGiftingLead"
      :showActions="this.isBoldXchangeAdmin || this.isGiftingLead"
      :showBorder="true"
      title="Your Information"
    >
      <template #subheading-content>
        <p class="tw-m-0 tw-text-sm tw-text-gray-800 tw-w-sm">
          This information will be used for campaign communications.
        </p>
      </template>
      <template #card-header-actions>
        <template v-if="!isEditingDetails">
          <TheButton
            variant="primary"
            size="small"
            @click="editMemberDetails()"
            :hasLeadingIcon="true"
          >
            <template #leading-icon><b-icon-pencil /></template>
            <template #text> Edit </template>
          </TheButton>
        </template>
        <template v-if="isEditingDetails">
          <div class="tw-flex tw-gap-2">
            <TheButton
              :disabled="!formComplete || isSaving"
              variant="success"
              size="small"
              @click="upsertUser()"
            >
              <template #text> Save </template>
            </TheButton>
            <TheButton
              variant="tertiary"
              size="small"
              @click="cancelEdit()"
              :hasLeadingIcon="true"
            >
              <template #text> Cancel </template>
            </TheButton>
          </div>
        </template>
      </template>
    </CardHeader>
    <TheBox :withPadding="true">
      <BlockStack :gap="400">
        <!-- User Information Form -->
        <!-- Account Email -->
        <div
          class="sm:tw-flex tw-items-center tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
        >
          <p
            class="tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
          >
            Account Email
          </p>
          <div class="tw-flex tw-items-top tw-gap-2">
            <p
              class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
            >
              {{ memberEmail }}
            </p>
          </div>
        </div>
        <template v-if="this.isBoldXchangeAdmin || this.isGiftingLead">
          <!-- Display Name -->
          <div
            class="sm:tw-flex tw-items-center tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
          >
            <div
              class="tw-flex tw-items-center tw-gap-2 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
            >
              <p class="tw-text-gray-900 tw-m-0">Display Name</p>
              <TheTooltip
                :toolTip="true"
                :tooltipContent="displayNameTooltipContent"
              >
                <template #icon>
                  <b-icon-question-circle
                    class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                  ></b-icon-question-circle>
                </template>
              </TheTooltip>
            </div>
            <input
              v-model="userMixinData.metadata.profileSettings.displayName"
              type="text"
              name="displayName"
              id="displayName"
              :class="{
                'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                  !isEditingDetails,
                'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                  isEditingDetails,
              }"
              class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
              placeholder="Display name"
              :readonly="!isEditingDetails"
            />
          </div>
          <!-- First Name -->
          <div
            class="sm:tw-flex tw-items-center tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
          >
            <p
              class="tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
            >
              First Name<span
                v-if="!memberFirstName && isEditingDetails"
                class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                >required field</span
              >
            </p>
            <input
              v-model="userMixinData.firstName"
              type="text"
              name="firstName"
              id="firstName"
              :class="{
                'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                  !isEditingDetails,
                'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                  isEditingDetails,
              }"
              class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
              placeholder="First name"
              :readonly="!isEditingDetails"
            />
          </div>
          <!-- Last Name -->
          <div
            class="sm:tw-flex tw-items-center tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
          >
            <p
              class="tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
            >
              Last Name<span
                v-if="!memberLastName && isEditingDetails"
                class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                >required field</span
              >
            </p>
            <input
              v-model="userMixinData.lastName"
              type="text"
              name="lastName"
              id="lastName"
              :class="{
                'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                  !isEditingDetails,
                'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                  isEditingDetails,
              }"
              class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
              placeholder="Last name"
              :readonly="!isEditingDetails"
            />
          </div>
          <!-- Organization Name -->
          <div
            class="sm:tw-flex tw-items-center tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
          >
            <p
              class="tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
            >
              Organization Name
            </p>
            <input
              v-model="userMixinData.company"
              type="text"
              name="companyName"
              id="companyName"
              :class="{
                'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                  !isEditingDetails,
                'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                  isEditingDetails,
              }"
              class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-500 tw-text-sm tw-leading-6"
              placeholder="e.g. Best Company"
              :readonly="!isEditingDetails"
            />
          </div>
          <!-- Job Title -->
          <div
            class="sm:tw-flex tw-items-center tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
          >
            <p
              class="tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
            >
              Job Title
            </p>
            <input
              v-model="userMixinData.jobTitle"
              type="text"
              name="jobTitle"
              id="jobTitle"
              :class="{
                'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                  !isEditingDetails,
                'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                  isEditingDetails,
              }"
              class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-500 tw-text-sm tw-leading-6"
              placeholder="e.g. VP of Sales"
              :readonly="!isEditingDetails"
            />
          </div>
          <!-- Bio -->
          <div
            class="sm:tw-flex tw-items-start tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
          >
            <p
              class="tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
            >
              About
            </p>
            <BlockStack :gap="100" class="tw-w-full">
              <textarea
                rows="4"
                v-model="
                  userMixinData.metadata.profileSettings.aboutDescription
                "
                @input="limitDescription"
                name="aboutDescription"
                id="aboutDescription"
                placeholder="Write a few sentences about yourself and/or organization."
                :class="{
                  'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                    !isEditingDetails,
                  'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                    isEditingDetails,
                }"
                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-500 tw-text-sm tw-leading-6"
                :readonly="!isEditingDetails"
              ></textarea>
              <p class="tw-text-gray-500 tw-text-sm tw-m-0 tw-text-right">
                {{ charCount }} / 250
              </p>
            </BlockStack>
          </div>
          <!-- Website URL -->
          <div
            class="sm:tw-flex tw-items-center tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
          >
            <p
              class="tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
            >
              Website URL
            </p>
            <input
              v-model="userMixinData.metadata.profileSettings.websiteUrl"
              type="text"
              name="jobTitle"
              id="jobTitle"
              :class="{
                'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                  !isEditingDetails,
                'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                  isEditingDetails,
              }"
              class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-500 tw-text-sm tw-leading-6"
              placeholder="e.g. www.example.com"
              :readonly="!isEditingDetails"
            />
          </div>
        </template>
      </BlockStack>
    </TheBox>
  </TheCard>
</template>

<script>
import TheBox from "../../components-v2/ui/layout/TheBox";
import TheCard from "../../components-v2/ui/layout/TheCard";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import CardHeader from "../../components-v2/ui/layout/CardHeader";
import TheButton from "../ui/actions/TheButton.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import userMixin from "@/mixins/userMixin";

export default {
  name: "UserContactInformation",
  components: {
    BlockStack,
    TheButton,
    TheBox,
    TheCard,
    CardHeader,
    TheTooltip,
  },
  mixins: [userMixin],
  data() {
    return {
      isEditingDetails: false,
      isSaving: false,
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    formComplete() {
      return this.userMixinData.firstName && this.userMixinData.lastName;
    },
    displayNameTooltipContent() {
      return "This is how your name will be displayed in your signature for your custom landing page.";
    },
    memberFirstName() {
      return this?.userMixinData?.firstName;
    },
    memberLastName() {
      return this?.userMixinData?.lastName;
    },
    memberEmail() {
      return this?.userMixinData?.email;
    },
    charCount() {
      return this.userMixinData.metadata.profileSettings.aboutDescription
        .length;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async upsertUser() {
      this.isSaving = true;
      const api = await this.getBoldClient();

      const memberToUpdate = {
        firstName: this.userMixinData.firstName,

        lastName: this.userMixinData.lastName,

        "metadata.profileSettings.displayName":
          this.userMixinData.metadata.profileSettings.displayName,

        "metadata.landingPageSettings.signature.displayName.value":
          this.userMixinData.metadata.profileSettings.displayName,

        ...(this.userMixinData.metadata.profileSettings.displayName && {
          "metadata.profileSettings.displayName":
            this.userMixinData.metadata.profileSettings.displayName,

          "metadata.landingPageSettings.signature.displayName.value":
            this.userMixinData.metadata.profileSettings.displayName,
        }),

        // ...(this.userMixinData.company && {
        company: this.userMixinData.company,
        "metadata.landingPageSettings.signature.organizationName.value":
          this.userMixinData.company,
        // }),

        // ...(this.userMixinData.jobTitle && {
        jobTitle: this.userMixinData.jobTitle,
        "metadata.landingPageSettings.signature.jobTitle.value":
          this.userMixinData.jobTitle,
        // }),

        // ...(this.userMixinData.metadata.profileSettings.websiteUrl && {
        "metadata.profileSettings.websiteUrl":
          this.userMixinData.metadata.profileSettings.websiteUrl,

        "metadata.landingPageSettings.signature.websiteUrl.value":
          this.userMixinData.metadata.profileSettings.websiteUrl,
        // }),

        // ...(this.userMixinData.metadata.profileSettings.aboutDescription && {
        "metadata.profileSettings.aboutDescription":
          this.userMixinData.metadata.profileSettings.aboutDescription,

        "metadata.landingPageSettings.signature.aboutDescription.value":
          this.userMixinData.metadata.profileSettings.aboutDescription,
        // }),
      };

      try {
        await api.upsertUser(null, {
          filter: { _id: this.User?._id },
          patch: memberToUpdate,
        });
        this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },
    editMemberDetails() {
      this.isEditingDetails = !this.isEditingDetails;
    },
    limitDescription(event) {
      if (event.target.value.length > 250) {
        this.userMixinData.metadata.profileSettings.aboutDescription =
          event.target.value.slice(0, 250);
      }
    },
    cancelEdit() {
      this.initializeForm(this.User);
      this.isEditingDetails = false;
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
    handleSuccess() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Profile successfully updated.",
        duration: 6000,
        id: Date.now(),
      });
      this.isEditingDetails = false;
      this.refreshUser();
    },
  },
};
</script>

<style scoped></style>
