<template>
  <template v-if="showApplyModal">
    <Teleport to="#modal">
      <TheModal
        title="Create campaign"
        @closeModal="closeModal"
        :open="showFilterModal"
        :showModalHeader="true"
        :showModalBody="true"
        :showModalFooter="true"
        :closeOnClickOutside="false"
      >
        <template #modal-body>
          <TheBox :withoutPadding="true">
            <p class="tw-m-1 tw-text-lg">
              Do you want to create a new campaign using the following
              selection?
            </p>
            <template v-if="templateSelection?.type">
              <template v-if="templateSelection.type === 'giftCardAmount'">
                <p class="tw-m-0 tw-text-lg">
                  <span class="tw-font-bold">Gift Card Amount: </span>${{
                    templateSelection.value
                  }}
                </p>
              </template>
              <template v-if="templateSelection.type === 'category'"></template>
              <template
                v-if="templateSelection.type === 'neighborhood'"
              ></template>
            </template>
          </TheBox>
        </template>
        <template #modal-footer>
          <div>
            <TheButton size="large" variant="success" @click="resetFilters()">
              <template #text>Yes, create campaign</template>
            </TheButton>
          </div>
        </template>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showDeleteModal">
    <Teleport to="#modal">
      <TheModal
        @closeModal="closeModal"
        :open="showDeleteModal"
        :showModalHeader="true"
        :showModalBody="true"
        :showModalFooter="false"
        :closeOnClickOutside="false"
        title="Delete this template?"
      >
        <ModalBody>
          <DeleteData
            :campaignTemplates="[campaign]"
            :isPrivate="isPrivate"
            @closeModal="closeModal"
            @confirmDelete="confirmDelete()"
          ></DeleteData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <li class="tw-relative hover:tw-opacity-90 tw-z-0">
    <TheCard class="tw-w-full tw-p-2 tw-overflow-visible">
      <BlockStack :gap="300">
        <div class="tw-relative">
          <div class="tw-absolute tw-top-2 tw-left-2 tw-z-10">
            <div
              class="tw-flex gap-1"
              :class="{ 'tw-bg-gray-50 tw-p-1 tw-rounded-md': templateStatus }"
            >
              <template v-if="!isPrivate">
                <b-icon-star-fill
                  class="tw-h-5 tw-w-5 tw-bg-yellow-400 tw-rounded-full tw-p-1"
                >
                </b-icon-star-fill> </template
              ><template v-if="templateStatus"
                ><p class="tw-m-0">Draft</p></template
              >
            </div>
          </div>
          <div class="tw-absolute tw-top-2 tw-right-2 tw-z-10">
            <IconDropdown
              :items="campaignListItemDropdownItems(campaign)"
              :id="campaign._id"
            >
              <template #icon>
                <b-icon-three-dots-vertical />
              </template>
            </IconDropdown>
          </div>

          <div class="tw-aspect-h-10 tw-aspect-w-10">
            <div
              class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-font-bold tw-text-lg tw-rounded tw-border-solid tw-border tw-border-gray-200 tw-p-4"
              :class="iconBackgroundColorClass"
            >
              <p
                class="tw-pointer-events-none tw-block tw-m-0 tw-font-bold tw-text-gray-900"
              >
                {{ getTemplateName || "Untitled" }}
              </p>
            </div>
          </div>
        </div>
        <BlockStack :gap="200">
          <p class="tw-pointer-events-none tw-text-lg tw-m-0 tw-text-gray-900">
            <b-icon-credit-card class="tw-mr-2"></b-icon-credit-card>${{
              campaign.metadata.gift_card_amount
            }}
            gift card
          </p>
          <div class="tw-flex tw-gap-2 tw-text-lg tw-items-start">
            <b-icon-bookmark-heart
              class="tw-text-lg tw-mt-1"
            ></b-icon-bookmark-heart>
            <div class="">
              <p
                class="tw-pointer-events-none tw-m-0 tw-text-lg tw-text-gray-900"
              >
                {{
                  this.isCustomGiftCardSelection
                    ? "Custom selection"
                    : "Recipients choice"
                }}
              </p>
              <template v-if="getTemplateCategory">
                <p class="tw-pointer-events-none tw-m-0 tw-text-gray-900">
                  {{
                    `from ${getTemplateCategory}`
                  }}
                </p>
              </template>
            </div>
          </div>

          <p
            class="tw-pointer-events-none tw-block tw-truncate tw-text-lg tw-m-0 tw-text-gray-900"
          >
            <b-icon-geo-alt class="tw-mr-2"></b-icon-geo-alt>
            <!-- <template v-if="neighborhoodList">{{ neighborhoodList }}</template> -->
            <template v-if="appliedCollectionRegion">{{
              appliedCollectionRegion
            }}</template>
          </p>
        </BlockStack>
      </BlockStack>
    </TheCard>
  </li>
</template>

<script>
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
// import ModalFooter from "../../components-v2/ui/layout/ModalFooter.vue";
// import GiftCardContent from "../../components-v2/ui/modals/GiftCardContent.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
// import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import DropdownMenu from "../../components-v2/ui/forms/DropdownMenu.vue";
import IconDropdown from "../../components-v2/ui/elements/dropdown/IconDropdown.vue";

import DeleteData from "../ui/modals/DeleteData.vue";
import TheBox from "../../components-v2/ui/layout/TheBox";

export default {
  name: "CampaignTemplatesListItem",
  components: {
    IconDropdown,
    TheBox,
    DeleteData,
    DropdownMenu,
    TheCard,
    BlockStack,
    TheModal,
    ModalBody,
    // ModalFooter,
    // GiftCardContent,
    TheButton,
    // NotificationMessage,
  },
  emits: ["checkForCampaigns"],
  props: {
    // isToggled: {
    //   type: Boolean,
    //   default: false,
    // },
    // selectedGiftCards: {
    //   type: Array,
    //   require: true,
    // },
    campaign: { type: Object, required: false },
  },
  data() {
    return {
      api: {
        error: 0,
        success: 0,
        message: "",
      },
      vendorMedia: [],
      giftCardModalIsOpen: false,
      giftCardWasRedeemed: false,
      finalizingGiftCardSelection: false,
      showMeetingPicker: false,
      showDeleteModal: false,
      showApplyModal: false,
      // campaignIdToDelete: null,
    };
  },
  created() {
    // this.handleVendorMedia();
  },
  computed: {
    user() {
      return this?.$store?.state?.auth?.user;
    },
    isGiftingLeadAndRecipient() {
      return (
        this?.campaign?.recipients[0]?.contact?.email === this?.user?.email ||
        this.isGiftingLead
      );
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    neighborhoodList() {
      // Check if campaign object has collection_query property
      if (
        this?.campaign?.metadata?.collection_query?.requestBody?.filter?.$and
      ) {
        // Get all 'and' conditions
        const andConditions =
          this?.campaign?.metadata?.collection_query?.requestBody?.filter?.$and;

        // Check if 'and' conditions exist
        if (andConditions && Array.isArray(andConditions)) {
          // Loop through each condition in '$and'
          for (let condition of andConditions) {
            // Check if the condition includes 'metadata.neighborhoods'
            if (condition["metadata.neighborhoods"]) {
              // Return the value of 'metadata.neighborhoods'
              return condition["metadata.neighborhoods"].$in.join(", ");
            }
          }
        }
      }
      // Return 'Not specified' if 'metadata.neighborhoods' is not found
      return false;
    },
    appliedCollectionRegion() {
      const filters =
        this?.campaign?.metadata?.collection_query?.requestBody?.filter?.$and;

      for (let filter of filters) {
        if (filter["metadata.region"]) {
          return filter["metadata.region"].$in[0];
        }
      }

      return "St. Louis";
    },
    isCustomGiftCardSelection() {
      return this?.campaign?.metadata?.collection_query?.custom_selection_ids &&
        this?.campaign?.metadata?.collection_query?.custom_selection_ids
          .length > 0
        ? true
        : false;
    },
    getTemplateCategory() {
      return this?.campaign?.metadata?.collection_query?.requestBody?.tabName;
    },
    getTemplateName(){
      return this?.campaign?.metadata?.campaign_name
    },
    iconBackgroundColorClass() {
      return {
        "tw-bg-blue-100 tw-bg-red-200":
          this?.campaign?.metadata?.collection_query?.requestBody?.tabName ===
          "Full collection",
        "tw-bg-blue-100 tw-bg-blue-200":
          this?.campaign?.metadata?.collection_query?.requestBody?.tabName ===
          "Casual Dining",
        "tw-bg-blue-100 tw-bg-yellow-200":
          this?.campaign?.metadata?.collection_query?.requestBody?.tabName ===
          "Coffee & Cafes",
        "tw-bg-blue-100 tw-bg-indigo-200":
          this?.campaign?.metadata?.collection_query?.requestBody?.tabName ===
          "Fine Dining",
        "tw-bg-blue-100 tw-bg-sky-200":
          this?.campaign?.metadata?.collection_query?.requestBody?.tabName ===
          "Ice Cream & Desserts",
        "tw-bg-blue-100 tw-bg-slate-200":
          this?.campaign?.metadata?.collection_query?.requestBody?.tabName ===
          "Wine & Spirits",
      };
    },
    isPrivate() {
      return this?.campaign?.metadata?.campaign_template?.isPrivate;
    },
    templateStatus() {
      return this?.campaign?.metadata?.campaign_template?.status === "draft";
    },
  },
  methods: {
    /** handle gift card redemption */
    async redeemThisCard() {
      this.finalizingGiftCardSelection = true;
      const api = await this.getBoldClient();
      const campaignId = this.$route.params.campaignId;
      const vendorId = this.vendor.id;

      let apiParams = {
        recipient_selection: vendorId,
      };

      if (this.requiresBooking) {
        apiParams.booking = {
          end: this.bookingIsSelected.end,
          start: this.bookingIsSelected.start,
        };
      }

      try {
        await api.finalizeGiftCardSelection({ campaignId }, apiParams);

        this.handleSuccess();
      } catch (error) {
        console.log("finalizeGiftCardSelection error", error);
        this.handleErrorFromAPI(
          error,
          "Something went wrong finalizing your request. Please try again. If the issue continues, please contact our team at hello@boldxchange.com"
        );
      } finally {
        this.finalizingGiftCardSelection = false;
      }
    },

    /** dropdown */
    campaignListItemDropdownItems(campaign) {
      // campaign can only be edited by it's user
      if (campaign?.metadata?.campaign_template?.status === "draft") {
        return [
          {
            label: "Edit details",
            isRouterLink: true,
            to: `/template/edit/${campaign.id}`,
          },
          {
            label: "Delete",
            isDeleteAction: true,
            action: () => this.toggleDeleteModal(),
          },
        ];
      } else if (
        campaign?.metadata?.campaign_template?.status === "saved" &&
        this.isBoldXchangeAdmin &&
        (!campaign?.metadata?.campaign_template?.isPrivate ||
          campaign?.metadata?.campaign_template?.isPrivate)
      ) {
        return [
          {
            label: "Edit details",
            isRouterLink: true,
            to: `/template/edit/${campaign.id}`,
          },
          {
            label: "Duplicate",
            isDeleteAction: false,
            action: () => this.duplicateCampaignTemplate(campaign),
          },
          {
            label: "Apply to new campaign",
            isDeleteAction: false,
            action: () => this.createCampaignFromTemplate(campaign),
          },
          {
            label: "Delete",
            isDeleteAction: true,
            action: () => this.toggleDeleteModal(),
          },
        ];
      } else if (
        campaign?.metadata?.campaign_template?.status === "saved" &&
        !campaign?.metadata?.campaign_template?.isPrivate &&
        !this.isBoldXchangeAdmin
      ) {
        return [
          {
            label: "Duplicate",
            isDeleteAction: false,
            action: () => this.duplicateCampaignTemplate(campaign),
          },
          {
            label: "Apply to new campaign",
            isDeleteAction: false,
            action: () => this.createCampaignFromTemplate(campaign),
          },
        ];
      } else if (
        campaign?.metadata?.campaign_template?.status === "saved" &&
        campaign?.metadata?.campaign_template?.isPrivate &&
        !this.isBoldXchangeAdmin
      ) {
        return [
          {
            label: "Edit details",
            isRouterLink: true,
            to: `/template/edit/${campaign.id}`,
          },
          {
            label: "Duplicate",
            isDeleteAction: false,
            action: () => this.duplicateCampaignTemplate(campaign),
          },
          {
            label: "Apply to new campaign",
            isDeleteAction: false,
            action: () => this.createCampaignFromTemplate(campaign),
          },
          {
            label: "Delete",
            isDeleteAction: true,
            action: () => this.toggleDeleteModal(),
          },
        ];
      } else {
        return [];
      }
    },

    /** create new campaign from template */
    async createCampaignFromTemplate(campaign) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      const api = await this.getBoldClient();
      this.creatingCampaignDraft = true;
      // const fromEmail = this?.user?.email;
      // const fromName = `${this?.user?.firstName} ${this?.user?.lastName}`;

      try {
        const metadata = { ...campaign?.metadata };

        // Remove budget if it is null
        if (metadata.budget === null) {
          delete metadata.budget;
        }

        // Remove campaign_template
        if (metadata.campaign_template) {
          delete metadata.campaign_template;
        }

        const campaignData = {
          from: campaign?.from,
          subject: campaign?.subject,
          text: campaign?.text,
          html: campaign?.html,
          recipients: campaign?.recipients,
          metadata: metadata,
          user: this?.user?.id,
        };

        const campaignResponse = await api.createMailCampaigns(null, [
          campaignData,
        ]);
        this.handleSuccess(campaignResponse, "creatingCampaignFromTemplate");
      } catch (error) {
        console.log(error);
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your new campaign. Please reload the page and try again."
        );
      } finally {
        this.creatingCampaignDraft = false;
      }
    },
    async duplicateCampaignTemplate(campaign) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      const api = await this.getBoldClient();
      this.creatingCampaignDraft = true;

      try {
        // Prepare metadata
        const metadata = {
          ...campaign?.metadata,
          campaign_template: {
            isPrivate: true, // default
            name: `${campaign.metadata.campaign_name} COPY`, // default
            status: "draft", // default
          },
        };

        // Remove budget if it is null
        if (metadata.budget === null) {
          delete metadata.budget;
        }

        const campaignData = {
          from: campaign?.from,
          subject: campaign?.subject,
          text: campaign?.text,
          html: campaign?.html,
          recipients: campaign?.recipients,
          metadata: metadata,
          user: this?.user?.id,
        };

        const campaignResponse = await api.createMailCampaigns(null, [
          campaignData,
        ]);
        this.handleSuccess(campaignResponse, "duplicateCampaignTemplate");
      } catch (error) {
        console.log(error);
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your new campaign. Please reload the page and try again."
        );
      } finally {
        this.creatingCampaignDraft = false;
      }
    },
    /** handle selection */
    toggleGiftCardSelection() {
      this.$emit("toggleGiftCardSelection", this.vendor);
    },
    isSelected() {
      return this.selectedGiftCards.some(
        (selected) => selected.id === this.vendor.id
      );
    },
    /** handle deleting template */
    async confirmDelete() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      const campaignIdToDelete = this?.campaign?._id;

      try {
        await api.deleteMailCampaigns(null, {
          filter: { _id: campaignIdToDelete },
        });
        this.handleSuccess();
      } catch (error) {
        console.error("Campaign was NOT deleted", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignIsSaving = false;
      }
    },
    /** handle modals */
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    toggleApplyModal() {
      this.showApplyModal = !this.showApplyModal;
    },
    closeModal() {
      this.showDeleteModal = false;
      this.showApplyModal = false;
    },
    /** handle success and errors */
    // handleSuccess() {},
    handleSuccess(response, api) {
      if ((response, api === "creatingCampaignFromTemplate")) {
        const campaignId = response.data.result.records[0]._id;
        this.$router.push(`/campaign/edit/${campaignId}`);
      } else if ((response, api === "duplicateCampaignTemplate")) {
        // let successMessage = "Template was successfully duplicated.";
        const campaignId = response.data.result.records[0]._id;
        this.$router.push(`/template/edit/${campaignId}`);
        // this.$emit("checkForCampaigns");
        // this.$store.dispatch("alerts/showAlert", {
        //   type: this.notificationTypes.SUCCESS,
        //   message: successMessage,
        //   duration: 4000,
        //   id: Date.now(),
        // });
      } else {
        this.showDeleteModal = false;
        let successMessage = "Template was successfully deleted.";
        this.$emit("checkForCampaigns");
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: successMessage,
          duration: 4000,
          id: Date.now(),
        });
      }
    },
    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error.response) {
        // Process API error message if customMessage is not provided
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.source.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 8000,
        id: Date.now(),
      });
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>

<style scoped></style>
