// store/modules/alerts/mutations.js
export default {
  ADD_ALERT(state, alert) {
    state.alerts.push({
      ...alert,
      // id: Date.now(), // Ensures each notification is unique
    });
    console.warn(`alert type: ${alert.type}`);
  },
  REMOVE_ALERT(state, { id, scope }) {
    state.alerts = state.alerts.filter((a) => a.id !== id || a.scope !== scope);
  },

  ADD_TIMEOUT(state, { id, timeoutId }) {
    state.alertTimeouts[id] = timeoutId;
  },

  REMOVE_TIMEOUT(state, id) {
    if (state.alertTimeouts[id]) {
      clearTimeout(state.alertTimeouts[id]);
      delete state.alertTimeouts[id];
    }
  },
};
