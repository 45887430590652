<template>
  <!-- password -->
  <div class="mb-4">
    <label class="form-label w-100" for="signupSrPassword" tabindex="0">
      <span class="d-flex justify-content-between align-items-center">
        <span>Password</span>
        <button
          class="btn btn-link mb-0 clickable"
          @click.prevent="$emit('showResetPassword')"
        >
          Forgot Password?
        </button>
      </span>
    </label>

    <div
      class="input-group input-group-merge"
      data-hs-validation-validate-class
    >
      <input
        v-model="password"
        type="password"
        class="js-toggle-password form-control form-control-lg"
        name="password"
        id="signupSrPassword"
        placeholder="8+ characters required"
        aria-label="8+ characters required"
        required
        minlength="8"
        data-hs-toggle-password-options='{
                               "target": [".js-toggle-password-target-1", ".js-toggle-password-target-2"],
                               "defaultClass": "bi-eye-slash",
                               "showClass": "bi-eye",
                               "classChangeTarget": ".js-toggle-password-show-icon-1"
                             }'
      />
      <a
        class="js-toggle-password-target-1 input-group-append input-group-text"
        href="javascript:;"
      >
        <i class="js-toggle-password-show-icon-1 bi-eye"></i>
      </a>
    </div>
  </div>
  <div v-if="!formIsValid">
    <p class="text-start">
      <span class="text-danger text-start fw-bold"
        >Incorrect email or password.</span
      >
      Try entering your information again, or
      <a
        class="link link-primary mb-0"
        @click.prevent="$emit('showResetPassword')"
        >resetting your password</a
      >.
    </p>
  </div>
  <!-- login button -->
  <div class="d-grid gap-2 text-center">
    <button
      @click.prevent="login()"
      type="submit"
      class="btn btn-primary btn-lg clickable"
    >
      Sign in
    </button>
    <!-- <div class="fw-bold">Or</div>
    <button
      class="btn btn-outline-warning btn-lg text-black"
      @click.prevent="showOnetimePasscode()"
    >
      Login with One-Time-Passcode
    </button> -->
  </div>

  <!-- End Content -->
</template>

<script>
export default {
  name: "UserLogin",
  components: {},
  props: ["userEmail"],
  methods: {
    showOnetimePasscode() {
      this.$emit("useOnetimePasscode");
    },
    async login() {
      this.formIsValid = true;
      try {
        await this.$store.dispatch("auth/login", {
          email: this.userEmail,
          password: this.password,
        });

        // Your existing code
        this.refreshClient;
        if (this.isBoldXchangeAdmin) {
          // location.reload();
          this.$router.push({ path: "/dashboard" });
        }
        // else {
        //   const redirectToProposal = this.$route.query.redirectToProposal;
        //   if (redirectToProposal) {
        //     this.$router.push({
        //       name: "ProposalDetails",
        //       params: { proposalId: redirectToProposal },
        //     });
        //   }
        else {
          // Redirect to a default page (e.g., home) if redirectToProposal is not present
          this.$router.push({ path: "/dashboard" });
        }
        // }

        await this.refreshClient();
        this.$root.$emit("login", this.$store.state.auth.token);
      } catch (error) {
        // Handle error here
        this.formIsValid = false; // Update the formIsValid variable based on the error condition
        console.error("Login error:", error);
      }
    },
  },
  data() {
    return {
      isPasswordless: true,
      email: "",
      password: "",
      formIsValid: true,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  cursor: pointer;
}
</style>
