<template>
  <div class="tw-pb-4 sm:tw-pb-6 tw-w-full">
    <!-- inline stack -->
    <div class="tw-flex gap-2 tw-w-full tw-flex-wrap tw-align-middle">
      <!-- page title + subcontent -->
      <BlockStack :gap="300" class="tw-flex-grow">
        <!-- search bar -->
        <template v-if="useSearchBar">
          <slot name="card-search-bar"></slot>
        </template>
        <!-- title -->
        <BlockStack :gap="100">
          <!-- heading -->
          <h1
            class="tw-m-0 tw-text-lg tw-font-bold sm:tw-truncate sm:tw-text-xl sm:tw-tracking-tight"
          >
            {{ title }}
          </h1>
          <template v-if="subheading">
            <slot name="subheading-content">
              <p class="tw-m-0">{{ subheading }}</p>
            </slot>
          </template>
          <template v-if="showSubheading">
            <div>
              <slot name="subheading-content">
                <p class="tw-m-0">Add a subheading here or</p>
                <span
                  class="tw-items-center tw-rounded-md tw-bg-yellow-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-600 tw-ring-1 tw-ring-inset tw-ring-gray-500/10"
                >
                  Badge</span
                >
              </slot>
            </div>
          </template>
        </BlockStack>
      </BlockStack>
      <template v-if="showActions">
        <div class="tw-flex tw-flex-wrap tw-gap-2">
          <slot name="card-header-actions"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BlockStack from "./BlockStack.vue";
import InlineStack from "./InlineStack.vue";

export default {
  /**
   * The header represents the card.
   * It must have card title clearly conveys the purpose of the card.
   * It can have a header action to modify the card contents or navigate to where they can view more details
   */

  name: "ModalHeader",
  components: { BlockStack, InlineStack },
  props: {
    title: {
      type: String,
      default: null,
    },
    textAlign: {
      type: String,
      default: "start",
    },
    /**
     * Makes search bar slot accessible when set true
     */
    useSearchBar: {
      type: Boolean,
      default: false,
    },
    /**
     * False by default
     * Makes actions slot accessible when true
     */
    showActions: {
      type: Boolean,
      default: false,
    },
    showSubheading: {
      type: Boolean,
      default: false,
    },
    subheading: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "",
    },
    role: {
      default: null,
    },
    padding: {
      type: [Number, Object],
      default: 200,
    },
    backgroundColor: {
      type: String,
      default: "none",
    },
    width: {
      type: [Number, Object],
      default: 100,
    },
    /**
     * Shown by default
     * Routes to previous page if history exists
     */
    showBreadcrumb: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    componentClass() {
      return typeof this.backgroundColor === "string"
        ? this.backgroundColor
        : "";
    },
  },
};
</script>
