import axios from "axios";

export default {
  async checkPasswordless({ email, api }) {
    const result = await api.checkPasswordless(null, { email });
    console.log("checkPasswordless result", result);
  },

  async login({ commit }, { email, password }) {
    const route = process.env.VUE_APP_API_BASE_URL + "/api/v1/users/login";

    // const result = await axios.post(route, { email, password });
    const result = await axios.post(route, { email, password });

    // valid response
    const { token, user } = result.data;
    window.localStorage.setItem("jwt", token);
    commit("SET_USER", user);
    commit("SET_TOKEN", token);
    // console.log(result);
  },
  /**
   *
   * @param {*} param0
   * @param {{
   *  client: import('@boldxchange/sdk/defs').Client
   * }} param1
   */
  async loginOTP({ commit }, { email, otp, client }) {
    const result = await client.loginOTP(null, { email, otp });

    // const cookies = result.headers['set-cookie'].map(cookie => cookie.split('=')); // ['jwt', 'token']
    // const jwtCookie = cookies.find(([key, value]) => key === 'jwt');

    // if response is not 200
    // if (!result.ok){
    //     console.log(result);
    //     const error = new Error("Failed to authenticate.");
    //     throw error;
    // }

    // valid response
    const { token, user } = result.data;
    window.localStorage.setItem("jwt", token);
    commit("SET_USER", user);
    commit("SET_TOKEN", token);
    // console.log(result);
  },
  async resetPass({ commit }, { email }) {
    const route =
      process.env.VUE_APP_API_BASE_URL + "/api/v1/users/forgotPassword";

    const res = await axios.post(
      route,
      { email },
      {
        validateStatus: () => true,
      }
    );
    if (res.status >= 200 && res.status < 300) {
      return true;
    } else {
      return false;
    }
  },
  async finishResetPass(
    { commit },
    { newPass: password, passConfirm: passwordConfirm, token }
  ) {
    const route =
      process.env.VUE_APP_API_BASE_URL + "/api/v1/users/resetPassword/" + token;

    const res = await axios.post(
      route,
      { password, passwordConfirm },
      {
        validateStatus: () => true,
      }
    );

    if (res.status >= 200 && res.status < 300) {
      return {
        finish: () => {
          const { token, user } = res.data;
          window.localStorage.setItem("jwt", token);
          commit("SET_USER", user);
          commit("SET_TOKEN", token);
        },
      };
    } else {
      return false;
    }
  },
  async registerUser({ commit }, data) {
    const userData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
    };
    const route = process.env.VUE_APP_API_BASE_URL + "/api/v1/users/signup";
    // console.log({ route });
    const result = await axios.post(route, userData);
    const { token, user } = result.data;
    commit("SET_USER", user);
    commit("SET_TOKEN", token);

    // context.commit("registerUser", userData);
  },
  async refreshUser({ state, commit }) {
    const route = "/api/v1/users/me";
    if (!state.token || state.token === "null") return;
    const res = await axios.get(process.env.VUE_APP_API_BASE_URL + route, {
      headers: {
        Authorization: "Bearer " + state.token,
      },
      validateStatus: () => true,
    });
    if (res.status >= 200 && res.status < 300) {
      const user = res.data.doc;
      // console.log("Setting user", { user });
      commit("SET_USER", user);
    } else {
      if (
        ["jwt_expired", "bad_token"].includes(
          (typeof res.data.status === "string"
            ? res.data.status
            : res.data.code
          ).toLowerCase()
        )
      ) {
        commit("LOGOUT");
      }
    }
  },
  setUserAndToken({ commit }, { token, user }) {
    window.localStorage.setItem("jwt", token); // Store the token in localStorage
    commit("SET_USER", user); // Commit the user information to the Vuex store
    commit("SET_TOKEN", token); // Commit the token to the Vuex store
    // console.log("token");
  },

  logout({ commit }) {
    // Perform logout-related tasks (clear token, user data, etc.)
    // ...

    // After performing logout tasks, commit a mutation
    commit("LOGOUT");
  },
};
