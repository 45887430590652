<template>
    <div :class="componentClass"
        class="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-shadow tw-overflow-hidden">
        <slot></slot>
    </div>
    <!-- <div :id="id" :role="role" :style="componentStyles">
        <slot></slot>
    </div> -->
</template>
  
<script>
export default {
    name: 'TheCard',
    props: {
        id: {
            type: String,
            default: ''
        },
        role: {
            default: null
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        autoWidth: {
            type: Boolean,
            default: false
        },
        customWidthClass: {
            type: String,
            default: null
        },

    },
    computed: {
        componentClass() {
            return {
                'tw-w-full': this.fullWidth,
                'tw-w-auto ': this.autoWidth,
                // Add customWidth class if customWidth is provided
                [this.customWidthClass]: !!this.customWidthClass
            };
        }
    }
};
</script>
  