<template>
  <footer class="bg-soft-secondary">
    <div class="row justify-content-center align-items-center">
      <div class="col">
        <span class="fs-6 mb-0  fw-bold">
          &copy; Bold Xchange, Inc.
          <span class="">2024.</span>
        </span>
      </div>
    </div>
    <!-- End Row -->
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
};
</script>

<style scoped>
</style>
