<template>
  <div class="tw-w-full tw-h-full tw-overflow-hidden">
    <div class="tw-flex tw-flex-col">
      <div class="tw-animate-pulse">
        <div class="tw-bg-gray-300 tw-h-8 tw-rounded-md tw-mb-4"></div>
        <!-- Table Header Placeholder -->
        <div class="tw-space-y-2">
          <div
            v-for="row in numberOfRows"
            :key="row"
            class="tw-grid tw-grid-cols-6 tw-gap-4"
          >
            <!-- Column placeholder -->
            <div class="tw-col-span-1 tw-h-4 tw-bg-gray-200 tw-rounded"></div>
            <div class="tw-col-span-2 tw-h-4 tw-bg-gray-200 tw-rounded"></div>
            <div class="tw-col-span-1 tw-h-4 tw-bg-gray-200 tw-rounded"></div>
            <div class="tw-col-span-1 tw-h-4 tw-bg-gray-200 tw-rounded"></div>
            <div class="tw-col-span-1 tw-h-4 tw-bg-gray-200 tw-rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataTableSkeleton",
  props: {
    // number of row to display
    numberOfRows: {
      type: Number,
      default: 25,
    },
  },
};
</script>

<style scoped></style>
