<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <div class="page-header">
        <div class="d-flex justify-content-between">
          <h1 class="page-header-title text-start">Categories</h1>
        </div>
      </div>
      <div class="mb-1">
        <div class="input-group input-group-merge">
          <!-- <div class="input-group-prepend input-group-text">
                  <b-icon-search></b-icon-search>
                </div> -->
          <input
            @keyup.enter="getCategoriesBySearchQuery()"
            type="text"
            id="datatableSearch"
            class="form-control"
            placeholder="Search category by name"
            aria-label="Search category by name"
            v-model="searchQuery"
          />
          <button
            type="button"
            class="btn btn-secondary"
            @click="getCategoriesBySearchQuery()"
          >
            Search<b-icon-search class="ms-2"></b-icon-search>
          </button>
        </div>
        <div v-if="categoriesAreLoading" class="text-start mt-1">
          <span
            class="spinner-border spinner-border-sm me-1"
            aria-hidden="true"
          ></span>
          <span role="status">Loading...</span>
        </div>
        <p class="mt-1 text-start" v-else>
          <span class="badge bg-warning">{{ getQueryCount }}</span>
          Results
        </p>
        <div class="text-danger text-start mt-1" v-if="!doesCategoryExist">
          There are no categories in our database that match your search.
        </div>
      </div>
      <!-- End Search -->
      <div class="mt-3">
        <div class="text-start">
          <h4>Selected categories</h4>
          <p v-if="selectedCategories.length === 0" class="text-info fs-4">
            Please select categories.
          </p>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-left mt-1">
          <ul
            v-for="category in selectedCategories"
            :key="category.index"
            class="list-unstyled"
          >
            <li
              class="bg-soft-secondary rounded me-1 px-2 py-1"
              @click="toggleCategorySelection(category)"
            >
              <b-icon-x-circle class="text-danger"></b-icon-x-circle>
              <span class="text-dark ms-1">{{ category }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="card mt-3">
        <ul class="list-group list-group-flush list-group-numbered text-start">
          <li
            @click="toggleCategorySelection(category)"
            :class="{ selected: isCategorySelected(category) }"
            v-for="category in categories"
            :key="category._id"
            class="list-group-item list-group-item-action"
          >
            {{ category }}
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <button
          type="button"
          class="btn btn-primary"
          @click="confirmSelection()"
        >
          Confirm Selection
        </button>
      </div>
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "CategoriesListModal",
  components: {
    FixedModalSmall,
  },
  data() {
    return {
      searchQuery: "",
      searchValueExists: false,
      doesCategoryExist: true,
      count: 0,
      categories: [],
      categoriesAreLoading: false,
      selectedCategories: [], // New array to store selected categories
    };
  },
  created() {
    this.getAllCategories();
  },
  watch: {
    searchQuery() {
      if (this.searchQuery === "") {
        this.searchCount = 0;
        this.getAllCategories();
      }
      this.searchValueExists = false;
    },
  },
  computed: {
    getQueryCount() {
      return this.count;
    },
    getSearchQuery() {
      return this.searchQuery;
    },
  },
  methods: {
    async getAllCategories() {
      // Get the API client
      const api = await this.getBoldClient();

      // Set loading and existence flags
      this.categoriesAreLoading = true;
      this.doesCategoryExist = true;
      this.vendorsAreLoading = true; // Added for vendor loading state

      const pageSize = 300;

      // Use Promise.all to wait for both API requests to complete
      const [productResponse, vendorResponse] = await Promise.all([
        api.listProducts({
          limit: pageSize,
          sort: "-name",
          distinct: "categories",
        }),
        api.listVendors({
          limit: pageSize,
          sort: "-name",
          distinct: "categories",
        }),
      ]);

      // Extract product and vendor category data
      const productCategories = productResponse.data.result.records;
      const vendorCategories = vendorResponse.data.result.records;

      // Calculate category counts
      const productCategoriesCount = productCategories.length;
      const vendorCategoriesCount = vendorCategories.length;

      // Combine both product and vendor categories into allCategories
      const allCategories = [...productCategories, ...vendorCategories];

      // Calculate total category count
      const totalCategoriesCount =
        productCategoriesCount + vendorCategoriesCount;

      // Update the categories and counts
      this.categories = allCategories;
      this.count = totalCategoriesCount;

      // Reset loading flags
      this.categoriesAreLoading = false;
      this.vendorsAreLoading = false;

      // console.log(allCategories); 
    },
    async getCategoriesBySearchQuery() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      // Set loading and existence flags
      this.categoriesAreLoading = true;
      this.doesCategoryExist = true;
      this.vendorsAreLoading = true; // Added for vendor loading state

      if (this.getSearchQuery === "") {
        this.getAllCategories();
      } else {
        const pageSize = 300;

        // Use Promise.all to wait for both API requests to complete
        const [productResponse, vendorResponse] = await Promise.all([
          api.listProducts(
            {
              limit: pageSize,
              sort: "-name",
              distinct: "categories",
            },
            {
              filter: {
                $or: [{ categories: { $regex: this.getSearchQuery } }],
              },
            }
          ),
          api.listVendors(
            {
              limit: pageSize,
              sort: "-name",
              distinct: "categories",
            },
            {
              filter: {
                $or: [{ categories: { $regex: this.getSearchQuery } }],
              },
            }
          ),
        ]);

        // Extract product and vendor category data
        const productCategories = productResponse.data.result.records;
        const vendorCategories = vendorResponse.data.result.records;

        // Calculate category counts
        const productCategoriesCount = productCategories.length;
        const vendorCategoriesCount = vendorCategories.length;

        // Combine both product and vendor categories into allCategories
        const allCategories = [...productCategories, ...vendorCategories];

        // Calculate total category count
        const totalCategoriesCount =
          productCategoriesCount + vendorCategoriesCount;

        if (allCategories.length === 0) {
          this.count = 0;
          this.doesCategoryExist = false;
          this.categoriesAreLoading = false;
          this.vendorsAreLoading = false;
        } else {
          this.count = totalCategoriesCount;
          this.categories = allCategories;
          this.doesCategoryExist = true;
          this.categoriesAreLoading = false;
          this.vendorsAreLoading = false;
        }
      }
    },
    selectCategory(category) {
      this.$emit("setNewCategory", category);
    },
    toggleCategorySelection(category) {
      // Toggle the selected state of a category
      const index = this.selectedCategories.indexOf(category);
      if (index === -1) {
        this.selectedCategories.push(category);
      } else {
        this.selectedCategories.splice(index, 1);
      }
    },

    isCategorySelected(category) {
      // Check if a category is selected
      return this.selectedCategories.includes(category);
    },

    confirmSelection() {
      // Emit the selected categories to the parent component
      this.$emit("setNewCategories", this.selectedCategories);
    },
  },
};
</script>

<style scoped>
#upload-region {
  width: 100%;
  background-color: #f8fafd;
  border: 0.125rem dashed rgba(231, 234, 243, 0.7);
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

#upload-region.dragover {
  background-color: #f0f0f0;
}

ul {
  /* add the following two lines */
  max-height: 20rem;
  overflow-y: auto;
  cursor: pointer;
}

li {
  cursor: pointer;
}
</style>
