// import axios from "axios";

export default {
  activateQuickEdit({ commit }) {
    commit("setQuickEditActive", true);
  },
  deactivateQuickEdit({ commit }) {
    commit("setQuickEditActive", false);
  },
};
