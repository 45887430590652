<template>
    <div :id="id" :style="componentStyles">
        <slot></slot>
    </div>
</template>
  
<script>
export default {
    name: 'ThePlaceholder',
    props: {
        height: {
            type: [Number, Object],
            default: 500
        },
        id: {
            type: String,
            default: ''
        },
        role: {
            default: null
        },
        padding: {
            type: [Number, Object],
            default: 200
        },
        backgroundColor: {
            type: Boolean,
            default: true
        },
        width: {
            type: [Number, Object],
            default: 100
        }
    },
    computed: {
        componentStyles() {
            return {
                height: typeof this.height === 'number' ? `${(this.height / 100) * 4}px` : null,
                padding: typeof this.padding === 'number' ? `${(this.padding / 100) * 4}px` : null,
                width: typeof this.width === 'number' ? `${this.width}%` : 'auto',
                backgroundColor: this.backgroundColor ? '#ffcc48' : null,
            };
        }
    }
};
</script>
  