<template>
  <!-- <ThePage class="tw-w-full"> -->
    <BlockStack :gap="1000" class="tw-w-full">
      <PageSection>
        <div
          class="tw-grid tw-max-w-4xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              1. Segment details
            </h2>
          </div>
          <div class="md:tw-col-span-2">
            <TheCard class="tw-max-w-3xl tw-w-full tw-overflow-visible">
              <TheBox :withPadding="true" class="tw-w-full">
                <EditSegmentForm
                  :segment="segment"
                  @reloadData="reloadData"
                ></EditSegmentForm>
              </TheBox>
            </TheCard>
          </div>
        </div>
      </PageSection>
      <PageSection>
        <div
          class="tw-grid tw-max-w-4xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              2. Segment parameters
            </h2>
          </div>
          <div class="md:tw-col-span-2">
            <TheCard class="tw-max-w-3xl tw-w-full tw-overflow-visible">
              <TheBox :withPadding="true" class="tw-w-full">
                <EditSegmentForm
                :showCriteriaOnly="true"
                  :segment="segment"
                  @reloadData="reloadData"
                ></EditSegmentForm>
              </TheBox>
            </TheCard>
          </div>
        </div>
      </PageSection>
    </BlockStack>
  <!-- </ThePage> -->
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import EditSegmentForm from "../../components-v2/ui/modals/edit/EditSegmentForm.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";

export default {
  name: "RecipientsGroupSettings",
  emits: ["resetSessionId", "loadSteps", "handlePaymentMethodSetup", "reloadData"],
  components: {
    TheBox,
    TheCard,
    EditSegmentForm,
    ThePage,
    BlockStack,
    PageSection,
  },
  props: {
    segment: {
      type: Object,
      default: () => ({}),
    },
  },
  async created() {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    /** from TheBudget.vue */
    resetSessionId() {
      this.$emit("resetSessionId");
    },
    loadSteps() {
      this.$emit("loadSteps");
    },
    handlePaymentMethodSetup() {
      this.$emit("handlePaymentMethodSetup");
    },
    reloadData(){
      this.$emit('reloadData');
    }
  },
};
</script>

<style scoped></style>
