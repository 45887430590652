<template>
  <div class="tw-m-auto tw-grid tw-grid-cols-2 md:tw-grid-cols-12">
    <div class="tw-bg-stone-50 p-4 tw-col-span-6">
      <div
        class="tw-flex tw-flex-col tw-justify-center md:tw-mt-56 md:tw-px-20 tw-gap-5"
      >
        <img
          style="max-width: 25rem"
          src="../../../assets/svg/logos/Bold_Xchange_PrimaryLogo_Black.svg"
          alt="Image Description"
        />
        <h1 class="tw-text-xl tw-text-left">
          A one-month subscription to the Bold Xchange marketplace, streamlining
          client and prospect gifting.
        </h1>
      </div>
    </div>
    <div class="p-4 tw-col-span-6 md:tw-col-span-6">
      <!-- <div id="embedded-stripe-checkout"> -->
        <!-- Checkout will insert the payment form here -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    async signUpForGcMarketplace() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      const signupRes = await api.signUpForGcMarketplace(null, {
        successUrl:
          process.env.VUE_APP_API_BASE_URL + "/blah/{CHECKOUT_SESSION_ID}",
        returnUrl:
          process.env.VUE_APP_API_BASE_URL + "/blah/{CHECKOUT_SESSION_ID}",
        cancelUrl:
          process.env.VUE_APP_API_BASE_URL +
          "/api/v2/subscriptions/gc-marketplace/setup-failure",
      });

      return signupRes;
    },
  },
};
</script>
