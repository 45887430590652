<template>
  <template v-if="showBulkEditModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        title="Bulk editing lifecycle stage"
        @closeModal="closeModal"
        :open="showBulkEditModal"
      >
        <ModalBody>
          <BulkEditData
            :recipients="recipientIdsToEdit"
            @closeModal="closeModal"
            @confirmBulkEdit="confirmBulkEdit"
          ></BulkEditData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <TheCard class="tw-w-full">
    <template v-if="contacts">
      <CardHeader
        :title="tableTitle"
        :showTitle="true"
        :showActions="true"
        :useSearchBar="true"
      >
        <template #card-search-bar>
          <div class="tw-max-w-xl tw-w-full">
            <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-full">
              <div
                class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
              >
                <b-icon-search
                  class="tw-h-5 tw-w-5 tw-text-gray-400"
                  aria-hidden="true"
                ></b-icon-search>
              </div>
              <input
                @keyup.enter="handleSearchQuery()"
                v-model="searchQuery.value"
                type="text"
                name="recipientSearch"
                id="recipientSearch"
                class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                placeholder="Search recipient by first name or email"
              />
              <template v-if="searchQuery.active">
                <div
                  @click="resetFilters()"
                  class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-cursor-pointer"
                >
                  <span>reset</span>
                </div>
              </template>
            </div>
          </div>
        </template>
        <template #card-header-actions>
          <template v-if="hubSpotUpload">
            <TheButton
              :disabled="selectedRecipients.length > 0"
              variant="ghost"
              @click="loadMoreHubSpotContacts()"
            >
              <template #text
                ><span class="tw-text-blue-500"
                  >Load more contacts</span
                ></template
              >
            </TheButton>
          </template>

          <TheButton variant="tertiary" @click="cancelImport()">
            <template #text>Cancel import</template>
          </TheButton>
          <TheButton
            variant="success"
            @click="createNewRecipients()"
            :disabled="selectedRecipients.length === 0"
          >
            <template #text>Import selected</template>
          </TheButton>
        </template>
      </CardHeader>
    </template>
    <CardBody>
      <template v-if="filteredContacts.length > 0">
        <div
          class="tw-overflow-y-auto tw-min-h-[350px] tw-max-h-[350px] tw-w-full"
        >
          <div
            class="tw-flex tw-justify-between gap-4 tw-h-12 tw-px-3 tw-bg-stone-100 tw-sticky tw-top-0 tw-z-20 tw-items-center tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b tw-border-stone-300"
          >
            <div>
              <input
                id="importSelectAllRecipients"
                name="importSelectAllRecipients"
                type="checkbox"
                class="tw-left-4 tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-me-2"
                :checked="selectedRecipients.length === contacts.data.length"
                @change="selectAllRecipients($event.target.checked)"
              /><label class="tw-text-gray-900" for="importSelectAllRecipients"
                >Select all</label
              >
            </div>
            <template v-if="selectedRecipients.length > 0 && !campaignView">
              <p class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600">
                {{ selectedRecipients.length }} selected
              </p>
            </template>
            <template v-if="selectedRecipients.length > 0 && campaignView">
              <div class="tw-flex tw-gap-4 tw-items-center">
                <template v-if="campaignRecipientsSelectionUpdated">
                  <p class="tw-m-0 tw-text-sm tw-font-bold tw-text-green-600">
                    <b-icon-check-lg></b-icon-check-lg> Recipient list updated
                  </p>
                </template>
                <template v-else>
                  <p class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600">
                    {{ selectedRecipients.length }} selected
                  </p>
                </template>
                <TheButton
                  :disabled="this.selectedRecipients.length === 0"
                  variant="success"
                  @click="setCampaignRecipients()"
                  size="small"
                  :hasLeadingIcon="true"
                >
                  <template #leading-icon
                    ><b-icon-check-lg></b-icon-check-lg
                  ></template>
                  <template #text>Add selected recipients</template>
                </TheButton>
              </div>
            </template>
          </div>
          <table class="tw-min-w-full tw-divide-stone-300">
            <thead
              class="tw-sticky tw-top-12 tw-z-10 tw-border-b tw-border-stone-300"
            >
              <tr>
                <th
                  class="tw-min-w-20 tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                  scope="col"
                >
                  First Name
                </th>
                <th
                  class="tw-min-w-20 tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                  scope="col"
                >
                  Last Name
                </th>
                <th
                  class="tw-min-w-20 tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                  scope="col"
                >
                  Email
                </th>
                <th
                  class="tw-min-w-20 tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                  scope="col"
                >
                  Company
                </th>
                <!-- <th
                  class="tw-min-w-20 tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                  scope="col"
                >
                  Tags
                </th> -->
              </tr>
            </thead>
            <tbody
              class="tw-divide-y tw-divide-gray-200 tw-bg-white tw-min-h-[500px]"
            >
              <tr
                v-for="recipient in filteredContacts"
                :key="recipient.id"
                :class="[
                  selectedRecipients.includes(recipient.id) && 'bg-gray-50',
                ]"
              >
                <td class="tw-relative tw-px-3 tw-py-4">
                  <div
                    v-if="isRecipientSelected(recipient.id)"
                    class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-blue-600"
                  ></div>

                  <div class="tw-flex tw-items-center gap-2">
                    <input
                      type="checkbox"
                      class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-cursor-pointer"
                      :checked="isRecipientSelected(recipient.id)"
                      @change="toggleRecipientSelection(recipient)"
                    />
                    {{ recipient["First Name"] }}
                  </div>
                </td>
                <td
                  class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                >
                  {{ recipient["Last Name"] }}
                </td>
                <td
                  class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                >
                  {{ recipient["Email Address"] }}
                </td>
                <td
                  class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                >
                  {{ recipient["Company"] || "---" }}
                </td>

                <!-- <td
                  class="tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                >
                  {{ recipient["Lifecycle Stage"] }}
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <!-- </template> -->
      <!-- if userHasRecipients AND query count is 0 show recipient message -->
      <template v-if="filteredContacts.length === 0">
        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
        >
          <template v-if="searchQuery.active">
            <img
              style="max-height: 150px; width: auto"
              src="../../assets/svg/illustrations-test/14_no_query_results.png"
              alt="Image Description"
            />
            <h1 class="tw-text-2xl tw-m-0">No exact matches found</h1>
            <p class="tw-m-0 tw-text-lg tw-max-w-xl">
              Try updating your search
            </p>
          </template>
          <template v-else>
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px]"
            >
              <EmptyState emptyState="recipients"></EmptyState>
              <div class="tw-flex tw-flex-col tw-gap-4 tw-items-center">
                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  variant="primary"
                  size="large"
                  @click="toggleNewRecipientModal"
                >
                  <template #text>Add recipient</template>
                  <template #leading-icon
                    ><b-icon-plus-lg></b-icon-plus-lg
                  ></template>
                </TheButton>
                <TheLink
                  type="button"
                  variant="tertiary"
                  size="large"
                  :isRouterLink="true"
                  to="/recipients/import"
                >
                  <template #text>Import CSV</template>
                </TheLink>
              </div>
            </div>
          </template>
        </div>
      </template>
      <!-- </template> -->
    </CardBody>
  </TheCard>
</template>

<script>
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader.vue";
import CardBody from "../../components-v2/ui/layout/CardBody.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import BulkEditData from "../ui/modals/BulkEditData.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";

export default {
  name: "ImportRecipientsTableV1",
  components: {
    BulkEditData,
    TheModal,
    TheLink,
    EmptyState,
    CardBody,
    TheCard,
    CardHeader,
    TheButton,
    ModalBody,
  },
  emits: ["cancelImport", "createNewRecipients", "loadMoreHubSpotContacts"],
  props: {
    contacts: {
      type: Object,
      default: () => {},
    },
    csvUpload: {
      type: Number,
      default: null,
    },
    salesforceUpload: {
      type: Number,
      default: null,
    },
    hubSpotUpload: {
      type: Number,
      default: null,
    },
    selectedSobject: {
      type: String,
      default: null,
    },
  },
  watch: {
    isModalVisible(newVal) {
      if (newVal) {
        this.toggleNewRecipientModal();
      }
    },
  },
  data() {
    return {
      selectedRecipients: [],
      showBulkEditModal: false,
      searchQuery: {
        active: false,
        value: "",
      },
    };
  },
  computed: {
    filteredContacts() {
      if (!this.searchQuery.active) {
        return this.contacts.data;
      }
      return this.contacts.data.filter((recipient) => {
        return (
          recipient["First Name"]
            .toLowerCase()
            .includes(this.searchQuery.value.toLowerCase()) ||
          recipient["Last Name"]
            .toLowerCase()
            .includes(this.searchQuery.value.toLowerCase()) ||
          recipient["Email Address"]
            .toLowerCase()
            .includes(this.searchQuery.value.toLowerCase())
        );
      });
    },
    tableTitle() {
      if (this.csvUpload) {
        return "CSV contacts";
      } else if (this.salesforceUpload) {
        return this.selectedSobject === "Contact"
          ? "Salesforce Contacts"
          : "Salesforce Leads";
      } else if (this.hubSpotUpload) {
        return "HubSpot contacts";
      } else {
        return "Contacts";
      }
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** emits */
    cancelImport() {
      this.$emit("cancelImport");
    },
    createNewRecipients() {
      this.$emit("createNewRecipients", this.selectedRecipients);
    },
    loadMoreHubSpotContacts() {
      this.selectedRecipients = [];
      this.$emit("loadMoreHubSpotContacts");
    },

    /** handle bulk editing recipients */
    handleBulkEdit(recipients) {
      console.log("recipients", recipients);
      this.recipientIdsToEdit = recipients;
      this.showBulkEditModal = !this.showBulkEditModal;
    },
    closeModal() {
      this.showDeleteModal = false;
      this.showNewRecipientModal = false;
      this.showRecipientDetailsModal = false;
      (this.showBulkEditModal = false),
        this.$emit("update:isModalVisible", false);
    },

    /** handle recipient selection */
    selectAllRecipients(isChecked) {
      this.selectedRecipients = isChecked ? this.contacts.data.slice() : [];
    },
    isRecipientSelected(recipientId) {
      return this.selectedRecipients.some((r) => r.id === recipientId);
    },
    toggleRecipientSelection(recipient) {
      const index = this.selectedRecipients.findIndex(
        (r) => r.id === recipient.id
      );
      if (index > -1) {
        this.selectedRecipients.splice(index, 1);
      } else {
        this.selectedRecipients.push(recipient);
      }
    },

    /** handle resetting recipient list */
    resetFilters() {
      this.searchQuery.value = "";
      this.searchQuery.active = false;
    },
    /**handle listing recipients by search query*/
    handleSearchQuery() {
      this.searchQuery.active = true;
      return this.filteredContacts;
    },

    bulkDropdownItems() {
      return [
        {
          label: "Update lifecycle stage",
          isDeleteAction: false,
          action: () => this.handleBulkEdit(this.selectedRecipients),
        },
        {
          label: "Delete",
          isDeleteAction: true, // This item is a delete action
          action: () => this.handleBulkDelete(this.selectedRecipients),
        },
      ];
    },
  },
};
</script>
