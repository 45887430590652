<template>
  <template v-if="showRecipientDetailsModal">
    <Teleport to="#modal">
      <WideModal
        title="Recipient details"
        @closeModal="closeModal"
        :open="showRecipientDetailsModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <EditRecipientForm
          :editMyRecordsOnly="isMyRecord"
          :campaignView="campaignView"
          @closeModal="closeModal"
          @handleSuccess="handleSuccess()"
          :recipient="selectedRecipient"
        ></EditRecipientForm>
      </WideModal>
    </Teleport>
  </template>
  <TheCard
    class="tw-w-full"
    :class="{ 'tw-border-solid tw-border-green-600': !isReviewingDetails }"
  >
    <CardBody>
      <!-- selected recipients -->
      <template v-if="campaignRecipients?.length">
        <div
          class="tw-overflow-y-auto tw-w-full"
          :class="{
            'tw-min-h-[100px] tw-max-h-[300px]': readOnlyView,
            'tw-min-h-[150px] tw-max-h-[500px]': !readOnlyView,
          }"
        >
          <template v-if="!readOnlyView">
            <div
              class="tw-flex tw-justify-between gap-4 tw-h-12 tw-px-3 tw-bg-white tw-sticky tw-top-0 tw-z-20 tw-items-center tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b tw-border-stone-300"
            >
              <div>
                <input
                  id="recipients"
                  name="recipients"
                  type="checkbox"
                  class="tw-left-4 tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-red-600 focus:tw-ring-red-600 tw-me-2"
                  :checked="
                    selectedRecipients.length === campaignRecipients.length
                  "
                  @change="selectAllRecipients($event.target.checked)"
                /><label class="tw-text-gray-900" for="recipients"
                  >Select all visible</label
                >
              </div>
              <div class="tw-flex tw-gap-4 tw-items-center">
                <template v-if="campaignRecipientsSelectionUpdated">
                  <p class="tw-m-0 tw-text-sm tw-font-bold tw-text-green-600">
                    <b-icon-check-lg></b-icon-check-lg> Recipient list updated
                  </p>
                </template>
                <template v-else>
                  <p class="tw-m-0 tw-text-sm tw-font-bold tw-text-red-600">
                    {{ selectedRecipients.length }} selected
                  </p>
                </template>

                <TheButton
                  :disabled="selectedRecipients.length === 0"
                  variant="critical"
                  @click="removeSelectedCampaignRecipients()"
                  size="small"
                  :hasLeadingIcon="true"
                >
                  <template #leading-icon><b-icon-x-lg></b-icon-x-lg></template>
                  <template #text>Remove selected recipients</template>
                </TheButton>
              </div>
            </div>
          </template>
          <table class="tw-min-w-full tw-divide-stone-300">
            <thead
              class="tw-sticky tw-z-10 tw-border-b tw-border-stone-300"
              :class="{ 'tw-top-12': !readOnlyView }"
            >
              <tr>
                <th
                  scope="col"
                  class="tw-min-w-20 tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                >
                  Email
                </th>
                <template v-if="!campaignDetailsView">
                  <th
                    scope="col"
                    class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                  >
                    Phone number
                  </th>
                  <th
                    scope="col"
                    class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                  >
                    Company
                  </th>
                </template>
                <th
                  scope="col"
                  class="tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                >
                  Tags
                </th>
                <template v-if="campaignDetailsView">
                  <th
                    scope="col"
                    class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                  >
                    Engagement
                  </th>
                </template>
              </tr>
            </thead>
            <tbody
              class="tw-divide-y tw-divide-gray-200 tw-bg-white tw-min-h-[150px]"
            >
              <tr
                v-for="recipient in getCampaignRecipients"
                :key="recipient.id"
                :class="[
                  selectedRecipients.includes(recipient.id) && 'bg-gray-50',
                ]"
              >
                <td class="tw-relative tw-px-3 tw-py-4">
                  <div
                    v-if="isRecipientSelected(recipient.id)"
                    class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-red-600"
                  ></div>

                  <div class="tw-flex tw-items-center gap-2">
                    <template v-if="!readOnlyView">
                      <input
                        type="checkbox"
                        class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-red-600 focus:tw-ring-red-600"
                        :checked="isRecipientSelected(recipient.id)"
                        @change="toggleRecipientSelection(recipient)"
                      />
                    </template>
                    <TheLink
                      variant="tertiary"
                      @click="toggleRecipientDetailsModal(recipient)"
                    >
                      <template #text>
                        <template
                          v-if="recipient?.firstName && recipient?.lastName"
                        >
                          {{ recipient?.firstName + " " + recipient?.lastName }}
                        </template>
                        <template v-else>N/A</template>
                      </template>
                      <template #leading-icon
                        ><b-icon-plus-lg></b-icon-plus-lg>
                      </template>
                    </TheLink>
                    <div class="sm:tw-hidden tw-flex tw-flex-col">
                      <p class="tw-p-0 tw-m-0 tw-text-sm">
                        {{ recipient?.contact?.email || "---" }}
                      </p>
                    </div>
                  </div>
                </td>
                <td
                  class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                >
                  {{ recipient?.contact?.email || "---" }}
                </td>

                <template v-if="!campaignDetailsView">
                  <td
                    class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                  >
                    {{ recipient?.contact?.phone || "---" }}
                  </td>
                  <td
                    class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                  >
                    {{ recipient?.contact?.company || "---" }}
                  </td>
                </template>
                <td
                  class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-4 tw-capitalize tw-text-sm tw-text-gray-500"
                >
                  <template
                    v-if="
                      typeof recipient?.contact?.metadata?.lifecycleStage ===
                      'string'
                    "
                  >
                    <template
                      v-if="recipient?.contact?.metadata?.lifecycleStage"
                    >
                      {{ recipient?.contact?.metadata?.lifecycleStage }}
                    </template>
                    <template v-else> --- </template>
                  </template>

                  <template
                    v-else-if="
                      Array.isArray(
                        recipient?.contact?.metadata?.lifecycleStage
                      )
                    "
                  >
                    <template
                      v-if="
                        recipient?.contact?.metadata?.lifecycleStage.length ===
                        1
                      "
                    >
                      {{ recipient?.contact?.metadata?.lifecycleStage[0] }}
                    </template>
                    <template
                      v-else-if="
                        recipient?.contact?.metadata?.lifecycleStage.length > 1
                      "
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <span
                          class="tw-rounded-md tw-bg-gray-100 tw-px-1.5 tw-py-1 tw-cursor-pointer"
                        >
                          <TheTooltip
                            :toolTip="true"
                            :tooltipContent="tags(recipient)"
                          >
                            <template #icon>
                              <div
                                class="tw-flex tw-items-center tw-gap-2 tw-text-gray-800"
                              >
                                <span>Multiple </span>
                                <b-icon-eye class="tw-z-0"></b-icon-eye>
                              </div>
                            </template>
                          </TheTooltip>
                        </span>
                      </div>
                    </template>
                    <template v-else> --- </template>
                  </template>

                  <template v-else> --- </template>
                </td>
                <template v-if="campaignDetailsView">
                  <td
                    class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                  >
                    <span
                      :class="{
                        'tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-blue-800':
                          recipient?.campaign_status === 'sent',
                        'tw-inline-flex tw-items-center tw-rounded-md tw-bg-orange-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-orange-800':
                          recipient?.campaign_status === 'clicked',
                        'tw-inline-flex tw-items-center tw-rounded-md tw-bg-green-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-green-800':
                          recipient?.campaign_status === 'redeemed',
                      }"
                    >
                      {{ recipient?.campaign_status }}
                    </span>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </CardBody>
    <CardFooter>
      <!-- Pagination -->
      <nav
        class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200"
        aria-label="Pagination"
      >
        <div class="tw-hidden sm:tw-block">
          <p class="tw-text-sm tw-text-orange-700 tw-m-0">
            <span class="tw-font-bold">{{ campaignRecipients.length }}</span>
            Recipients are in this campaign
          </p>
        </div>
      </nav>
    </CardFooter>
  </TheCard>
</template>

<script>
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardBody from "../../components-v2/ui/layout/CardBody.vue";
import CardFooter from "../../components-v2/ui/layout/CardFooter.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import WideModal from "../../components-v2/ui/modals/WideModal.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import EditRecipientForm from "../../components-v2/ui/modals/EditRecipientForm.vue";

export default {
  name: "CampaignRecipientsSelection",
  components: {
    TheTooltip,
    WideModal,
    TheModal,
    EditRecipientForm,
    TheLink,
    CardBody,
    TheCard,
    TheButton,
    CardFooter,
  },
  emits: ["removeSelectedCampaignRecipients", "toggleCampaignRecipients"],
  props: {
    campaignRecipients: {
      type: Array,
    },
    readOnlyView: {
      type: Boolean,
      default: false,
    },
    campaignDetailsView: {
      type: Boolean,
      default: false,
    },
    campaignView: {
      type: Boolean,
      default: true,
    },
    isMyRecord: {
      type: Boolean,
    },
    isReviewingDetails: {
      type: Boolean,
    },
  },
  watch: {},
  created() {},
  data() {
    return {
      selectedRecipients: [],
      count: 0,
      pageSize: 25,
      currentPage: 1,
      sortedValue: "firstName",
      showRecipientDetailsModal: false,
      campaignRecipientsSelectionUpdated: false,
    };
  },
  computed: {
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.getCampaignRecipients.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    getCampaignRecipients() {
      return this.campaignRecipients;
    },
    tags() {
      return (recipient) => {
        if (
          Array.isArray(recipient?.contact?.metadata?.lifecycleStage) &&
          recipient?.contact?.metadata?.lifecycleStage.length === 1
        ) {
          return recipient?.contact?.metadata?.lifecycleStage[0];
        }
        return recipient?.contact?.metadata?.lifecycleStage?.join(", ") || "";
      };
    },
  },
  methods: {
    /** handle recipient selection */
    selectAllRecipients(isChecked) {
      this.selectedRecipients = isChecked
        ? this.campaignRecipients.slice()
        : [];
    },
    isRecipientSelected(recipientId) {
      return this.selectedRecipients.some((r) => r.id === recipientId);
    },
    toggleRecipientSelection(recipient) {
      const index = this.selectedRecipients.findIndex(
        (r) => r.id === recipient.id
      );
      if (index > -1) {
        this.selectedRecipients.splice(index, 1);
      } else {
        this.selectedRecipients.push(recipient);
      }
    },

    removeSelectedCampaignRecipients() {
      // alert();
      this.$emit("removeSelectedCampaignRecipients", this.selectedRecipients);
      this.selectedRecipients = [];
      this.campaignRecipientsSelectionUpdated = true;
      setTimeout(() => {
        this.campaignRecipientsSelectionUpdated = false;
      }, 4000);
    },

    /** handle viewing and editing recipient details */
    toggleRecipientDetailsModal(recipient) {
      this.selectedRecipient = recipient;
      this.showRecipientDetailsModal = !this.showRecipientDetailsModal;
    },
    closeModal() {
      this.showRecipientDetailsModal = false;
    },
    handleSuccess() {
      setTimeout(() => {
        this.showRecipientDetailsModal = false;
      }, 3000);
    },

    /** handle different table views */
    toggleCampaignRecipients() {
      this.$emit("toggleCampaignRecipients");
    },
  },
};
</script>
