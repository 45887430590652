<template>
  <CardHeader
    :showTitle="true"
    :showActions="true"
    title="Choose send mode"
    :showStrapline="true"
  >
    <template #strapline-content>

      <p
              class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-500 sm:tw-truncate sm:tw-text-lg sm:tw-tracking-tight"
            >
            Step 6 of 7
            </p>
    </template>
    <template #card-header-actions>
      <TheLink variant="critical" @click="exitProductTour()">
        <template #text>Exit tour</template>
      </TheLink>
    </template>
  </CardHeader>
  <div class="tw-p-5 tw-pt-0">
    <BlockStack :gap="400" class="">
      <BlockStack :gap="100">
        <p class="tw-text-lg tw-m-0">
          Whether it’s creating a shareable link or sending an email directly
          from our app, choose the sending method that works best with your
          flow.
        </p>
      </BlockStack>
      <div
        class="tw-overflow-hidden tw-rounded-lg tw-shadow-md tw-shadow-gray-300"
      >
        <img
          class="tw-w-full tw-h-auto tw-object-cover"
          src="../../../assets/svg/product-tour/product_tour_6.png"
          alt=""
        />
      </div>
      <div class="tw-flex gap-2">
        <TheButton
          variant="tertiary"
          size="large"
          @click="goToPreviousStep()"
          :hasLeadingIcon="true"
        >
          <template #text> Back</template>
          <template #leading-icon>
            <b-icon-arrow-left></b-icon-arrow-left>
          </template>
        </TheButton>
        <TheButton
          variant="secondary"
          size="large"
          @click="goToNextStep()"
          :hasTrailingIcon="true"
        >
          <template #text> Next step</template>
          <template #trailing-icon>
            <b-icon-arrow-right></b-icon-arrow-right>
          </template>
        </TheButton>
      </div>
    </BlockStack>
  </div>
</template>

<script>
import CardHeader from "../../../components-v2/ui/layout/CardHeader";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../../components-v2/ui/actions/TheLink.vue";

export default {
  name: "ProductTourStep_6_SelectCampaignType",
  emits: ["goToNextStep", "goToPreviousStep", "exitProductTour"],

  components: { TheButton, BlockStack, CardHeader, TheLink },
  methods: {
    exitProductTour() {
      this.$emit("exitProductTour");
    },
    goToNextStep() {
      this.$emit("goToNextStep");
    },
    goToPreviousStep() {
      this.$emit("goToPreviousStep");
    },
  },
};
</script>

<style scoped></style>
