<template>
    <div
        class="tw-w-full md:tw-flex md:tw-items-center md:tw-justify-between lg:tw-border-t lg:tw-border-gray-200">

        <slot></slot>
    </div>
</template>
  
<script>
export default {
    name: 'InlineStack',
    props: {
        /**
         * Horizontal alignment of children.
         * 'start' | 'center' | 'end' | 'space-around' | 'space-between' | 'space-evenly'
         */

        justifyContent: {
            type: String,
            default: 'start'
        },

        /**
        * Vertical alignment of children.
        * 'start' | 'center' | 'end' | 'baseline' | 'stretch'
        */

        alignItems: {
            type: String,
            default: 'center'
        },

        flexDirection: {
            type: String,
            default: 'row'
        },
        flexWrap: {
            type: String,
            default: 'unset'
        },
        gap: {
            type: [Number, Object],
            default: 0
        },
        id: {
            type: String,
            default: ''
        },

        role: {
            default: null
        },
        backgroundColor: {
            type: String,
            default: 'none'
        },
        width: {
            type: [Number, Object],
            default: 100
        },
    },
    computed: {
        componentClass() {
            return typeof this.backgroundColor === 'string' ? this.backgroundColor : '';
        }
    }
};
</script>
  