<template>
  <ClientListModal
    v-if="isClientListModalVisible"
    @cancel="closeClientListModal"
    @setNewClient="setNewClient"
  ></ClientListModal>

  <div class="card mb-3 mb-lg-5">
    <div
      :class="updateMessage.successful.class"
      v-if="updateMessage.successful.show"
    >
      <p class="mb-0 fw-bold fs-4">{{ updateMessage.successful.text }}</p>
    </div>
    <div :class="updateMessage.failed.class" v-if="updateMessage.failed.show">
      <p class="mb-0 fw-bold fs-4 text-white">
        {{ updateMessage.failed.text }}
      </p>
    </div>
    <!-- Header -->
    <div class="card-header d-flex align-items-center justify-content-between">
      <div class="d-flex flex-column text-start">
        <div class="d-flex align-items-center gap-2">
          <h2 class="mb-0"><h2 class="mb-0">Members</h2></h2>
          <span class="mb-0 text-dark fw-normal"
            ><span class="badge bg-soft-info"
              ><span class="fw-bold"
                ><template v-if="membersAreLoading">loading...</template
                ><template v-else>{{ this.count }} records</template></span
              ></span
            >
          </span>
        </div>
      </div>
      <div class="d-flex gap-2">
        <template v-if="isBulkEditing && isBoldXchangeAdmin">
          <template v-if="selectedRows.length === 0">
            <h2 class="mb-0">Select campaigns...</h2>
          </template>
          <template v-if="selectedRows.length > 0">
            <h2 class="mb-0 d-flex align-items-center gap-2">
              <span class="badge bg-bold-xchange-yellow fw-bold">{{
                selectedRows.length
              }}</span>
              Campaigns selected
            </h2>
          </template>
          <!-- <template v-if="isBulkEditing">
            <div class="d-flex align-items-end gap-2">
              <template v-if="selectedRows.length > 0">
                <div class="col-auto text-start">
                  <label
                    for="bulkUpdateCampaignStatus"
                    class="text-start fw-bold"
                    >Update campaign status</label
                  >
                  <div class="input-group">
                    <select
                      v-model="bulkEditCampaignStatus"
                      id="bulkUpdateCampaignStatus"
                      class="form-select"
                    >
                      <option value="" disabled>Select campaign status</option>
                      <option value="draft">Draft</option>
                      <option value="sent">Sent</option>
                      <option value="redeemed">Redeemed</option>
                    </select>
                    <button
                      :disabled="!bulkEditCampaignStatus"
                      class="btn btn-primary"
                      @click="bulkdUpdateCampaigns()"
                    >
                      Apply changes
                    </button>
                  </div>
                </div>
              </template>
              <button
                @click="toggleBulkEdit()"
                class="btn btn-outline-info d-flex align-items-center gap-2"
              >
                <span>Cancel</span>
              </button>
            </div>
          </template> -->
        </template>
        <template v-if="!isBulkEditing">
          <!-- filter button -->
          <div class="d-flex gap-2 align-items-center">
            <div class="d-flex gap-2">
              <span
                v-if="clientValue"
                @click.stop="handleFilterChange((clientValue = 0))"
                class="badge bg-soft-primary text-muted small"
              >
                Client:
                <span class="ms-2 text-dark text-capitalize">{{
                  clientValue
                }}</span>
                <b-icon-x-lg class="ms-2 text-danger"></b-icon-x-lg>
              </span>
            </div>
            <div class="col-auto dropdown">
              <button
                class="btn btn-outline-dark d-flex align-items-center fw-bold"
                :class="{
                  'text-success': isFilterActive,
                }"
                @click.prevent="showClientListModal()"
              >
                <b-icon-sliders class="me-2"></b-icon-sliders> Filter by
                organization
              </button>
            </div>
          </div>

          <!-- search input -->
          <div>
            <div class="input-group input-group-merge">
              <input
                @keyup.enter="handleSearchQuery()"
                type="text"
                class="form-control border border-dark"
                placeholder="Search members"
                aria-label="Search members"
                v-model="searchQuery"
              />
              <button
                type="button"
                class="btn btn-outline-dark d-flex align-items-center"
                @click="handleSearchQuery()"
              >
                <b-icon-search class="fw-bold"></b-icon-search>
              </button>
            </div>
          </div>
          <!-- <button
            :disabled="getQuickEditStatus"
            @click="toggleBulkEdit()"
            class="btn d-flex align-items-center gap-2 btn-outline-danger"
          >
            <b-icon-check2-square></b-icon-check2-square>
            <span>Bulk delete</span>
          </button> -->
        </template>
      </div>
    </div>
    <!-- End Header -->

    <!-- loading icon -->
    <template v-if="membersAreLoading">
      <div
        class="card-body d-flex justify-content-center"
        style="max-height: 500px; min-height: 500px"
      >
        <LoadingGiftIconSmall></LoadingGiftIconSmall>
      </div>
    </template>

    <!-- results exist -->
    <template v-if="!membersAreLoading">
      <template v-if="!count">
        <div
          class="card-body d-flex align-items-center justify-content-center bg-light"
          style="
            overflow: auto;
            overflow-x: hidden;
            max-height: 500px;
            min-height: 500px;
          "
        >
          <div class="text-start">
            <h1 class="display-4">No exact matches</h1>
            <p class="fs-3 mb-0">
              Try updating your search/filters or
              <a class="link link-primary" @click="resetFilters()"
                >click here</a
              >
              to reset.
            </p>
          </div>
        </div>
      </template>
    </template>

    <template
      v-if="this.members.length !== 0 && !membersAreLoading && this.count !== 0"
    >
      <div class="table-responsive">
        <table
          class="table table-thead-bordered table-align-middle table-hover"
        >
          <thead class="thead-light">
            <tr>
              <!-- <template v-if="!isBulkEditing">
                <th>
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @change="selectAllRows"
                  />
                </th>
              </template> -->
              <th class="text-start sortable" @click="handleSort('firstName')">
                <div class="d-flex align-items-center gap-2">
                  Name
                  <div class="d-flex flex-column gap-0 small">
                    <template v-if="sortedValue === 'firstName'">
                      <b-icon-caret-up-fill></b-icon-caret-up-fill>
                      <b-icon-caret-down></b-icon-caret-down>
                    </template>
                    <template v-else-if="sortedValue === '-firstName'">
                      <b-icon-caret-up></b-icon-caret-up>
                      <b-icon-caret-down-fill></b-icon-caret-down-fill>
                    </template>
                    <template v-else>
                      <b-icon-caret-up></b-icon-caret-up>
                      <b-icon-caret-down></b-icon-caret-down>
                    </template>
                  </div>
                </div>
              </th>
              <th
                scope="col"
                class="text-start sortable"
                @click="handleSort('email')"
              >
                <div class="d-flex align-items-center gap-2">
                  Email
                  <div class="d-flex flex-column gap-0 small">
                    <!-- if `sortedValue` === 'firstName' -->
                    <template v-if="sortedValue === 'email'">
                      <b-icon-caret-up-fill></b-icon-caret-up-fill>
                      <b-icon-caret-down></b-icon-caret-down>
                    </template>
                    <template v-else-if="sortedValue === '-email'">
                      <b-icon-caret-up></b-icon-caret-up>
                      <b-icon-caret-down-fill></b-icon-caret-down-fill>
                    </template>
                    <template v-else>
                      <b-icon-caret-up></b-icon-caret-up>
                      <b-icon-caret-down></b-icon-caret-down>
                    </template>
                  </div>
                </div>
              </th>

              <th scope="col" class="text-start">Organization</th>
              <th scope="col" class="text-start">Created on</th>
              <th scope="col" class="text-center">Actions</th>
            </tr>
          </thead>

          <tbody>
            <MembersListItem
              v-for="member in members"
              :key="member._id"
              :memberId="member._id"
              :member="member"
              :select-all="selectAll"
              :selected-rows="selectedRows"
              @row-selected="handleRowSelection"
              @reloadList="reloadList"
              :quickEditActive="getQuickEditStatus"
              @activateQuickEdit="activateQuickEdit"
              @deactivateQuickEdit="deactivateQuickEdit"
              @showSuccessMessage="showSuccessMessage"
            ></MembersListItem>
          </tbody>
        </table>
      </div>
    </template>
    <div class="card-footer">
      <div
        class="row justify-content-center justify-content-sm-between align-items-sm-center"
      >
        <!-- Pagination -->
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a
                class="page-link"
                :class="{ disabled: this.currentPage === 1 }"
                @click="previousPage()"
                aria-label="Previous"
                >Prev</a
              >
            </li>
            <li class="page-item active">
              <a class="page-link" href="#">{{ currentPage }}</a>
            </li>
            <li class="page-item">
              <a
                class="page-link"
                @click="nextPage()"
                aria-label="Next"
                :class="{ disabled: this.isLastPage }"
                >Next</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import ClientListModal from "../../components/ui/modals/lists/ClientListModal.vue";
import MembersListItem from "./MembersListItem.vue";
import LoadingGiftIconSmall from "../../components/ui/LoadingGiftIconSmall.vue";
import Tooltip from "../../components/ui/Tooltip.vue";

export default {
  name: "MembersList",
  components: {
    MembersListItem,
    ClientListModal,
    LoadingGiftIconSmall,
    Tooltip,
  },
  created() {
    // this.deactivateQuickEdit();
    const { query } = this.$route;
    this.clientValue = query.client || 0;
    // this.handleFilterChange();
  },
  data() {
    return {
      updateMessage: {
        successful: {
          text: "Member successfully deleted!",
          class: "bg-success p-3",
          show: false,
        },
        failed: {
          text: "Something went wrong while attempting to delete your member. Please try again.",
          class: "bg-danger  p-3",
          show: false,
        },
      },
      filter: {
        active: false,
        matchExists: false,
        count: 0,
      },
      isConfirmDeleteModalVisible: false,
      selectedRows: [],
      selectAll: false,
      searchCount: 0,
      count: 0,
      pageSize: 10,
      currentPage: 0,
      userHasOrders: true,
      shipHeroShipmentResults: [],
      searchQuery: "",
      sortedValue: "",
      doesOrderExist: true,
      membersAreLoading: true,
      clientValue: 0,
      isClientListModalVisible: false,
      members: [],
      clients: [],
      giftingLeads: [],
    };
  },
  computed: {
    showNoOrderMessage() {
      if (
        (!this.membersAreLoading && this.members.length === 0) ||
        (!this.membersAreLoading && this.members.length !== 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getQuickEditStatus() {
      const status = this.$store.getters["orders/getQuickEditStatus"];
      return status;
    },
    getSortValue() {
      return this.sortValue;
    },
    getSearchQuery() {
      return this.searchQuery;
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.members.length < this.pageSize ||
        this.currentPage === Math.ceil(this.searchCount)
      );
    },
    isFilterActive() {
      return this.clientValue ? true : false;
    },
  },
  methods: {
    async loadMembers() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.membersAreLoading = true;

      this.currentPage = 1;
      // console.log("currentPage", this.currentPage);

      // filters
      let requestBody = {};
      let selection = "client firstName lastName createdAt email";

      // if client filter is !== ''
      if (this.clientValue !== 0) {
        requestBody["client.name"] = {
          $eq: this.clientValue,
        };
      }

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.membersAreLoading = false;
        this.members = response.data.result.records;
        this.count = response.data.result.count;
        this.searchCount = response.data.result.count;
      } catch (error) {
        this.membersAreLoading = false;
        console.log("members did not load", error);
      }
    },
    async nextPage(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.membersAreLoading = true;

      if (page === undefined) {
        this.currentPage++;
      } else {
        this.currentPage = page;
      }
      // console.log("currentPage", this.currentPage);

      // filters
      let requestBody = {};
      let selection = "client firstName lastName createdAt email";

      // if client filter is !== ''
      if (this.clientValue !== 0) {
        requestBody["client.name"] = {
          $eq: this.clientValue,
        };
      }

      // if status filter is !== ''
      if (this.searchQuery !== "") {
        requestBody = {
          $or: [
            { firstName: { $regex: this.searchQuery } },
            { lastName: { $regex: this.searchQuery } },
            { email: { $regex: this.searchQuery } },
            { "client.name": { $regex: this.searchQuery } },
            { "client.domains": { $regex: this.searchQuery } },
          ],
        };
      }

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.membersAreLoading = false;
        this.members = response.data.result.records;
        this.count = response.data.result.count;
        this.searchCount = response.data.result.count;
      } catch (error) {
        this.membersAreLoading = false;
        console.log("members did not load", error);
      }
    },
    async previousPage(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.membersAreLoading = true;

      if (page === undefined) {
        this.currentPage--;
      } else {
        this.currentPage = page;
      }
      // console.log("currentPage", this.currentPage);

      // filters
      let requestBody = {};
      let selection = "client firstName lastName createdAt email";

      // if client filter is !== ''
      if (this.clientValue !== 0) {
        requestBody["client.name"] = {
          $eq: this.clientValue,
        };
      }

      // if status filter is !== ''
      if (this.searchQuery !== "") {
        requestBody = {
          $or: [
            { firstName: { $regex: this.searchQuery } },
            { lastName: { $regex: this.searchQuery } },
            { email: { $regex: this.searchQuery } },
            { "client.name": { $regex: this.searchQuery } },
            { "client.domains": { $regex: this.searchQuery } },
          ],
        };
      }

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.membersAreLoading = false;
        this.members = response.data.result.records;
        this.count = response.data.result.count;
        this.searchCount = response.data.result.count;
      } catch (error) {
        this.membersAreLoading = false;
        console.log("members did not load", error);
      }
    },
    async getMembersBySearchQuery(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.membersAreLoading = true;
      if (page === undefined) {
        this.currentPage = 1;
      } else {
        this.currentPage = page;
      }

      // filters
      let requestBody = {
        $or: [
          { firstName: { $regex: this.searchQuery } },
          { lastName: { $regex: this.searchQuery } },
          { email: { $regex: this.searchQuery } },
          { "client.name": { $regex: this.searchQuery } },
          { "client.domains": { $regex: this.searchQuery } },
        ],
      };
      let selection = "client firstName lastName createdAt email";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: "firstName",
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.membersAreLoading = false;
        this.members = response.data.result.records;
        this.count = response.data.result.count;
        this.searchCount = response.data.result.count;
      } catch (error) {
        this.membersAreLoading = false;
        console.log("members did not load", error);
      }
    },

    handleSort(column) {
      this.currentPage = 1;
      if (this.sortedValue === column) {
        this.sortedValue = `-${column}`;
        return this.loadMembers();
      } else {
        this.sortedValue = column;
        return this.loadMembers();
      }
    },
    handleSearchQuery() {
      this.clearFilters();
      this.clearFilterQuery();
      this.getMembersBySearchQuery();
    },
    clearFilterQuery() {
      const query = {};

      // client
      if (this.clientValue !== 0) query.client = this.clientValue;

      const nonEmptyFilters = Object.fromEntries(
        Object.entries(query).filter(
          ([_, value]) => value !== 0 && value !== ""
        )
      );

      const updatedQuery =
        Object.keys(nonEmptyFilters).length > 0 ? nonEmptyFilters : undefined;

      this.$router.push({ path: "/members", query: updatedQuery });
    },
    updateFilters() {
      this.searchQuery = "";
      const query = {};

      if (this.clientValue !== 0) query.client = this.clientValue;

      const nonEmptyFilters = Object.fromEntries(
        Object.entries(query).filter(
          ([_, value]) => value !== 0 && value !== ""
        )
      );

      const updatedQuery =
        Object.keys(nonEmptyFilters).length > 0 ? nonEmptyFilters : undefined;

      this.$router.push({ path: "/members", query: updatedQuery });
    },
    handleFilterChange() {
      this.updateFilters();
      this.loadMembers();
    },
    clearFilters() {
      this.clientValue = "";
    },
    resetFilters() {
      this.clientValue = 0;
      this.searchQuery = "";
      this.currentPage = 0;
      this.filter.count = 0;
      this.clearFilterQuery();
      this.loadMembers();
    },
    showClientListModal() {
      this.isClientListModalVisible = true;
    },
    closeClientListModal() {
      this.isClientListModalVisible = false;
    },
    setNewClient(client) {
      this.clientValue = client.name;
      this.isClientListModalVisible = false;
      this.handleFilterChange();
    },
    reloadList() {
      const page = this.currentPage;
      if (this.searchQuery !== "") {
        this.getMembersBySearchQuery(page);
      } else {
        this.nextPage(page);
      }
    },

    /** handle bulk and quick edits */
    async bulkdUpdateMembers() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      const memberIds = this.selectedRows.map((member) => member._id);

      const patchObject = {
        "metadata.campaign_status": this.bulkEditCampaignStatus,
      };

      // console.log("bulk update patchObject", patchObject);
      // console.log("bulk update campaignIds", campaignIds);

      try {
        const campaignResponse = await api.deleteUsers(null, {
          filter: {
            _id: { $in: memberIds },
          },
          patch: patchObject,
        });
        this.handleSuccess(campaignResponse); //
      } catch (error) {
        this.handleError(error); //
      }
    },
    toggleBulkEdit() {
      this.isBulkEditing = !this.isBulkEditing;
      this.content = [];
      this.selectedRows = [];
      this.selectAll = false;
    },
    clearBulkEditSelections() {
      this.isBulkEditing = false;
      this.content = [];
      this.selectedRows = [];
      this.selectAll = false;
      this.searchQuery = "";
      this.searchIsActive = false;
    },
    selectAllRows() {
      // Select or deselect all rows based on the "Select All" checkbox
      if (this.selectAll) {
        this.selectedRows = this.campaigns.map((campaign) => campaign);
      } else {
        this.selectedRows = [];
      }
    },
    handleRowSelection(campaign) {
      const existingCampaignIndex = this.selectedRows.findIndex(
        (v) => v._id === campaign._id
      );

      if (existingCampaignIndex !== -1) {
        // If the row is already selected, deselect it
        this.selectedRows.splice(existingCampaignIndex, 1);
      } else {
        // If the row is not selected, select it
        this.selectedRows.push(campaign);
      }
    },
    activateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to true
      this.$store.dispatch("campaigns/activateQuickEdit");
    },
    deactivateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to false
      this.$store.dispatch("campaigns/deactivateQuickEdit");
    },

    /** error handling */
    showSuccessMessage() {
      this.updateMessage.successful.show = true;
      setTimeout(() => {
        this.updateMessage.successful.show = false;
      }, 3000);
      this.reloadList();
    },
    showErrorMessage() {
      this.updateMessage.failed.show = true;
      setTimeout(() => {
        this.updateMessage.failed.show = false;
      }, 5000);
      this.reloadList();
    },

    /** navigation route */
    addNewMember() {
      this.$router.push({ path: "/add/member" });
    },
  },
};
</script>

<style scoped>
.table-responsive {
  max-height: 500px;
  min-height: 500px;
}

thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.table .sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 2px solid black;
}

.sortable {
  cursor: pointer;
}
</style>
