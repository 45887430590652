export default {
  getQuickEditStatus(state) {
    return state.quickEditActive;
  },
  getSelectedBooking(state) {
    return state.selectedBooking;
  },
  getCampaignSelectionRequestBody(state) {
    return state?.campaignBuilderData?.metadata?.collection_query?.requestBody || {};
  },

  /** campaign builder getters */
  getCampaignBuilderData(state) {
    return state.campaignBuilderData;
  },
  getCampaignGiftCardSelection(state) {
    return state.campaignBuilderData.metadata.collection_query
      .custom_selection_ids === 0 ||
      state.campaignBuilderData.metadata.collection_query.custom_selection_ids
        .length === 0
      ? 0
      : state.campaignBuilderData.metadata.collection_query
          .custom_selection_ids;
  },
  getCampaignName(state) {
    let campaignName = "";
    if (state?.campaignBuilderData?.metadata?.campaign_name) {
      campaignName = state.campaignBuilderData.metadata.campaign_name;
    }
    return campaignName;
  },
  getCampaignGiftingLeadName(state) {
    return state.campaignBuilderData.from.name;
  },
  getCampaignGiftingLeadEmail(state) {
    return state.campaignBuilderData.from.email;
  },
  getCampaignRecipients(state) {
    return state?.campaignBuilderData?.recipients || [];
  },
  getCampaignRecipientsIds(state) {
    return (
      state?.campaignBuilderData?.recipients?.map(
        (recipient) => recipient._id
      ) || []
    );
  },
  getCampaignRecipientsVisibleValue(state) {
    return state.areCampaignBuilderRecipientsVisible;
  },
  getCampaignEmailSubject(state) {
    let campaignEmailSubject = "";
    if (state?.campaignBuilderData?.subject) {
      campaignEmailSubject = state.campaignBuilderData.subject;
    }
    return campaignEmailSubject;
  },
  getCampaignEmailText(state) {
    let campaignEmailText = "";
    if (state?.campaignBuilderData?.text) {
      campaignEmailText = state.campaignBuilderData.text;
    }
    return campaignEmailText;
  },
  getCampaignEmailHtmlDisplay(state) {
    let generatedEmailHtml = "";
    // Split the input message into lines
    const lines = state.campaignBuilderData.text.split("\n");

    let consecutiveEmptyLines = 0;

    for (let index = 0; index < lines.length; index++) {
      const line = lines[index].trim();

      if (line !== "") {
        if (consecutiveEmptyLines > 0) {
          // Add <br/> for consecutive empty lines
          for (let i = 0; i < consecutiveEmptyLines; i++) {
            generatedEmailHtml += "<br/>";
          }
          consecutiveEmptyLines = 0; // Reset consecutive empty lines count
        }

        generatedEmailHtml += `<p style="margin: 0;color: rgb(0, 0, 0);">${line}</p>`;
      } else {
        consecutiveEmptyLines++;
      }
    }

    return `${generatedEmailHtml}`;
  },
  getCampaignEmailHtmlData(state, getters) {
    let generatedEmailHtml = getters["getCampaignEmailHtmlDisplay"];
    let emailHtmlData = "";
    const campaignGiftUrl = `${window.location.origin}/gift/${state?.campaignBuilderData?._id}`;
    if (generatedEmailHtml) {
      emailHtmlData = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Email Template</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        color: #241c15;
      }

      table {
        border-collapse: collapse;
      }
    </style>
  </head>
  <body style="font-family: Arial, sans-serif; color: rgb(0, 0, 0)">
    <table
      id="main_table"
      style="
        border-collapse: collapse;
        text-align: center;
        background-color: #fff;
        max-width: 660px;
        width: 100%;
        border: 1px solid #efeeea;
      "
    >
      <tr>
        <td
          id="email_from_container"
          style="
            padding: 24px;
            padding-bottom: 0px;
            text-align: left;
            background-color: #fff;
          "
        >
          <p style="margin: 0; color: rgb(0, 0, 0)">
            ${state.campaignBuilderData.from.name} sent you an
            <span style="font-weight: bold">STL eGift card</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          id="email_text_container"
          style="
            padding: 24px;
            text-align: left;
            background-color: #fff;
            height: 200px;
            vertical-align: top;
          "
        >
          ${generatedEmailHtml}
        </td>
      </tr>
      <tr>
        <td
          id="email_accept_container"
          style="padding: 24px; background-color: #f6f6f4"
        >
          <table
            id="nested_table_1"
            style="width: 100%; margin: 0 auto; border-collapse: collapse"
          >
            <tr>
              <td
                id="nested_table_1_td_1"
                style="
                  text-align: left;
                  padding-right: 5px;
                  vertical-align: middle;
                  height: 100%;
                  max-width: 70%;
                "
              >
                <p style="margin: 0; color: rgb(0, 0, 0); font-size: 16px">
                  Powered by
                  <span style="font-weight: bold">Bold Xchange</span>
                </p>
                <p style="margin: 5px 0 0; color: rgb(0, 0, 0)">
                  Enjoy an eGift card marketplace featuring many of St. Louis’
                  best small businesses.
                </p>
              </td>
              <td
                id="nested_table_1_td_2"
                style="text-align: center; padding: 0; height: 100%; width: 30%"
              >
                <a
                  href="${campaignGiftUrl}"
                  target="_blank"
                  style="
                    text-align: center;
                    background-color: #004aad;
                    border-radius: 6px;
                    color: #ffcc48;
                    font-weight: bold;
                    font-style: normal;
                    padding: 16px 24px;
                    font-size: 16px;
                    text-decoration: none;
                    display: inline-block;
                    width: auto;
                  "
                  >Accept</a
                >
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td
          id="email_contact_container"
          style="padding: 24px; background-color: #fff"
        >
          <table
            id="nested_table_2"
            style="width: 100%; margin: 0 auto; border-collapse: collapse"
          >
            <tr>
              <td>
                <a
                  href="https://boldxchange.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: rgb(0, 0, 0)"
                >
                  <img
                    alt="Gifting Powered by Bold Xchange"
                    title="Bold Xchange"
                    src="https://storage.googleapis.com/boldxchange-public/Bold_Xchange_PrimaryLogo_Black_Email.png"
                    style="width: 175px; height: auto"
                  />
                </a>
                <p style="margin: 10px 0 0; color: rgb(0, 0, 0)">
                  Bold Xchange P.O Box 771576 St. Louis, MO 63177
                </p>
                <p style="margin: 0; margin-bottom: 0; color: rgb(0, 0, 0)">
                  <a
                    style="text-decoration: underline; color: rgb(7, 7, 7)"
                    href="mailto:gifts@boldxchange.com"
                    >gifts@boldxchange.com</a
                  >
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td
          id="email_copyright_container"
          style="padding: 24px; text-align: center; background-color: #fff"
        >
          <p
            style="
              text-align: center;
              background-color: #fff;
              margin: 0;
              color: rgb(7, 7, 7);
            "
          >
            <em> Copyright (C) 2024 Bold Xchange, Inc.</em>
          </p>
        </td>
      </tr>
    </table>
  </body>
</html>
`;
    }
    return emailHtmlData;
  },
  getCampaignType(state) {
    let campaignType = "";
    if (state?.campaignBuilderData?.metadata?.campaign_type) {
      campaignType = state.campaignBuilderData.metadata.campaign_type;
    }
    return campaignType;
  },
  getCampaignGiftCardAmount(state) {
    let giftCardAmount = 0;
    if (state?.campaignBuilderData?.metadata?.gift_card_amount) {
      giftCardAmount = parseInt(
        state.campaignBuilderData.metadata.gift_card_amount
      );
    }
    return giftCardAmount;
  },
  getCampaignSelectedBudget(state) {
    let budget = null;
    if (state?.selectedBudgetData) {
      budget = state.selectedBudgetData;
    }
    return budget;
  },
  getCampaignSelectedBudgetLimitIsMet(state) {
    return state.isBudgetLimitMet;
  },
  getCampaignBudgetTracker(state) {
    return state.campaignBudgetTracker;
  },
  getCampaignTotalCost(state) {
    // Ensure campaignBuilderData and necessary properties exist
    const recipients = state.campaignBuilderData.recipients || [];
    const giftCardAmount = state.campaignBuilderData.metadata
      ? state.campaignBuilderData.metadata.gift_card_amount
      : 0;

    // Calculate total cost
    const totalCost = recipients.length * giftCardAmount;
    return totalCost;
  },
  getCampaignBudgetId(state) {
    let budgetId = "";
    if (state?.campaignBuilderData?.metadata?.budget) {
      budgetId = state.campaignBuilderData.metadata.budget;
    }
    return budgetId;
  },
  getCampaignBookingData(state) {
    let campaignBooking = {};
    if (state?.campaignBuilderData?.booking) {
      campaignBooking = state.campaignBuilderData.booking;
    }
    return campaignBooking;
  },
  getCampaignBookingProvider(state) {
    return state?.campaignBuilderData?.booking?.provider || "";
  },
  getCampaignBookingSummary(state) {
    return state?.campaignBuilderData?.booking?.summary || "";
  },
  getCampaignBookingDescription(state) {
    return state?.campaignBuilderData?.booking?.description || "";
  },
  getCampaignBookingRequirement(state) {
    return state?.campaignBuilderData?.booking?.required || false;
  },
  getCampaignBookingEnabled(state) {
    return state?.campaignBuilderData?.booking?.enabled || false;
  },
  getCampaignLandingPageData(state) {
    let campaignGiftNote = null;
    if (state?.campaignBuilderData?.metadata?.landingPageSettings) {
      campaignGiftNote = state.campaignBuilderData.metadata.landingPageSettings;
    }
    return campaignGiftNote;
  },
  getCampaignLandingPageStatus(state) {
    return (
      state?.campaignBuilderData?.metadata?.landingPageSettings?.enabled ||
      false
    );
  },
  getCampaignLandingPageEnabledStatus(state) {
    return (
      state?.campaignBuilderData?.metadata?.landingPageSettings?.enabled ||
      false
    );
  },
  getCampaignLandingPageRequiredStatus(state) {
    return (
      state?.campaignBuilderData?.metadata?.landingPageSettings?.required ||
      false
    );
  },
  getCampaignLandingPageGiftNote(state) {
    return state?.campaignBuilderData?.metadata?.landingPageSettings?.giftNote;
  },
  getCampaignLandingPageTitle(state) {
    return (
      state?.campaignBuilderData?.metadata?.landingPageSettings?.giftNote
        ?.title || ""
    );
  },
  getCampaignLandingPageMessage(state) {
    return (
      state?.campaignBuilderData?.metadata?.landingPageSettings?.giftNote
        ?.message || ""
    );
  },

  getCampaignTemplateStatus(state) {
    return (
      state?.campaignBuilderData?.metadata?.campaign_template?.isPrivate || false
    );
  },
};
