<template>
  <div class="tw-w-full tw-h-full tw-overflow-hidden">
    <!-- Skeleton header -->
    <div class="tw-animate-pulse tw-w-full">
      <!-- Stats placeholders -->
      <div
        class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-4"
      >
        <div
          v-for="n in numberOfCards"
          :key="`stat-${n}`"
          class="tw-col-span-1 tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-shadow tw-h-36 tw-p-4"
        >
          <div class="tw-flex tw-flex-col tw-gap-2 tw-flex-1">
            <div class="tw-h-4 tw-w-full tw-bg-gray-200 tw-rounded"></div>
            <div class="tw-flex-1 tw-bg-gray-200 tw-rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardStatsSkeleton",
  props: {
    // number of row to display
    numberOfCards: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style scoped></style>
