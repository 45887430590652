import { createStore } from "vuex";

import rootMutations from "./mutations";
// import rootGetters from "./getters";
import rootActions from "./actions";

import invoicesModule from "./modules/invoices/index";
import usersModule from "./modules/users/index";
import authModule from "./modules/auth/index";
import giftsModule from "./modules/gifts/index";
import brandsModule from "./modules/brands/index";
import campaignsModule from "./modules/campaigns/index";
import notificationsModule from "./modules/notifications/index";
import integrationsModule from "./modules/integrations/index";
import alertsModule from "./modules/alerts/index";
import elementsModule from "./modules/elements/index";

const store = createStore({
  modules: {
    users: usersModule,
    elements: elementsModule,
    invoices: invoicesModule,
    auth: authModule,
    gifts: giftsModule,
    brands: brandsModule,
    campaigns: campaignsModule,
    notifications: notificationsModule,
    alerts: alertsModule,
    integrations: integrationsModule,
  },
  state() {
    return {
      isLoggedIn: false,
      user_Id: " ",
      firstName: " ",
      lastName: " ",
      email: " ",
      password: " ",
      passwordConfirm: " ",
    };
  },
  actions: rootActions,
  mutations: rootMutations,
  getters: {
    userId(state) {
      return state.userId;
    },
  },
});

export default store;
