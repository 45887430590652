<template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <template v-if="checkingIfUserHasCampaigns">
    <ViewRedemptionsPageSkeleton></ViewRedemptionsPageSkeleton>
  </template>
  <template v-else>
    <template v-if="totalSentCount">
      <TheCard class="tw-w-full">
        <TheBox :withPadding="true">
          <div class="tw-flex tw-items-center tw-justify-between">
            <h1 class="tw-m-0 tw-text-xl tw-font-bold tw-tracking-tight">
              Want to create another memorable touchpoint?
            </h1>
            <TheButton
              :disabled="!editMyRecordsOnly"
              :hasLeadingIcon="true"
              variant="primary"
              @click="createCampaignDraft()"
              size="regular"
            >
              <template #text>
                <template v-if="creatingCampaignDraft"
                  >Creating campaign</template
                >
                <template v-else>Send another gift</template>
              </template>
              <template #leading-icon>
                <template v-if="creatingCampaignDraft">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="tw-sr-only">Loading...</span>
                  </div>
                </template>
              </template>
            </TheButton>
          </div>
        </TheBox>
      </TheCard>
      <PageSection>
        <RecipientCampaignStats
          :totalCampaignsSentCount="totalSentCount"
          :totalCampaignsClickedCount="totalClicksCount"
          :totalCampaignsRedeemedCount="totalRedeemedCount"
          :totalRecipientsCount="totalRecipientsCount"
          :totalSpendCount="totalSpendCount"
        ></RecipientCampaignStats>
        <RedemptionHistoryList
          :recipientDetailsView="true"
          :campaignData="campaignData"
          :recipient="recipient"
        ></RedemptionHistoryList>
      </PageSection>
    </template>
    <template v-else>
      <PageSection>
        <TheCard class="tw-w-full">
          <EmptyState emptyState="recipientCampaigns"></EmptyState>
          <div class="tw-pb-6 tw-flex tw-justify-center">
            <TheButton
              :disabled="!editMyRecordsOnly"
              :hasLeadingIcon="true"
              variant="primary"
              @click="createCampaignDraft()"
              size="large"
            >
              <template #text>
                <template v-if="creatingCampaignDraft"
                  >Creating campaign</template
                >
                <template v-else>Send recipient a gift</template>
              </template>
              <template #leading-icon>
                <template v-if="creatingCampaignDraft">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="tw-sr-only">Loading...</span>
                  </div>
                </template>
              </template>
            </TheButton>
          </div>
        </TheCard>
      </PageSection>
    </template>
  </template>
</template>

<script>
import RecipientCampaignStats from "../recipients/RecipientCampaignStats.vue";
import RedemptionHistoryList from "../../components-v2/redemptions/RedemptionHistoryList";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import ViewRedemptionsPageSkeleton from "../../components-v2/ui/skeletons/page-skeletons/ViewRedemptionsPageSkeleton.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import TheCard from "../../components-v2/ui/layout/TheCard";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";

export default {
  name: "RecipientRedemptionHistory",
  components: {
    TheBox,
    TheButton,
    TheCard,
    EmptyState,
    RecipientCampaignStats,
    PageSection,
    ViewRedemptionsPageSkeleton,
    NotificationMessage,
    RedemptionHistoryList,
  },
  props: {
    recipient: {
      type: Object,
      required: false,
    },
    editMyRecordsOnly: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.checkForUserData();
    // this.checkForCampaigns();
    // this.calculateTotalGiftCardAmount();
  },
  data() {
    return {
      campaignData: { campaigns: null, count: 0 },
      checkingIfUserHasCampaigns: true,
      userHasCampaigns: false,
      loadingDashboardData: true,
      creatingCampaignDraft: false,
      campaignId: null,
      totalRecipientsCount: 0,
      totalCampaignsSentCount: 0,
      totalCampaignsSentWithSeenBoolean: 0,
      redeemedCampaignsWithoutFinalizations: 0,
      totalSpendByCampaignsWithoutFinalizations: 0,
      totalSpendByCampaignsWithoutFinalizationsAggregated: 0,
      clicksByCampaignsCountWithSeenBoolean: 0,
      clicksByCampaignsCountWithSeenArray: 0,
      redeemedCampaignsWithFinalizations: 0,
      totalSpendByCampaignsWithFinalizations: 0,
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },

    /** stats */
    totalSpendCount() {
      return (
        this.totalSpendByCampaignsWithoutFinalizationsAggregated +
        this.totalSpendByCampaignsWithFinalizations
      );
    },
    totalSentCount() {
      return this.campaignData.count;
    },
    totalRedeemedCount() {
      return (
        this.redeemedCampaignsWithoutFinalizations +
        this.redeemedCampaignsWithFinalizations
      );
    },
    totalClicksCount() {
      return (
        this.clicksByCampaignsCountWithSeenArray +
        this.clicksByCampaignsCountWithSeenBoolean
      );
    },
  },
  methods: {
    /** check for recipient campaigns */
    async checkForUserData() {
      this.loadingDashboardData = true;
      try {
        await this.checkForCampaigns();
        // If user has campaigns, dashboard stats data
        if (this.userHasCampaigns) {
          await Promise.all([
            this.loadRedeemedCampaignsWithoutFinalizations(),
            this.loadTotalSpendByRedeemedCampaignsWithoutFinalizations(),
            this.loadRedeemedCampaignsWithFinalizations(),
            this.loadCampaignsWhereSeenIsArray(),
            this.loadCampaignsWhereSeenIsBoolean(),
          ]);
        }
        this.dataLoadedSuccessfully = true;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your dashboard data. Please reload the page and try again."
        );
      } finally {
        this.loadingDashboardData = false;
      }
    },

    async checkForCampaigns() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let requestBody = {
        $and: [
          {
            "recipients.contact.email": {
              $in: [this.recipient.contact.email],
            },
          },
          {
            $or: [
              { "metadata.campaign_status": { $eq: "redeemed" } },
              { "metadata.campaign_status": { $eq: "sent" } },
              { "metadata.isRedeemed": true },
              { "metadata.finalizations": { $exists: true } },
            ],
          },
        ],
      };

      const queryParameters = {
        limit: 1000,
        sort: "-_id",
        expand: "recipients",
      };

      try {
        const response = await api.listMailCampaigns(queryParameters, {
          filter: requestBody,
        });
        this.campaignData.campaigns = response.data.result.records;
        this.campaignData.count = response.data.result.count;
        this.userHasCampaigns = response.data.result.count;
      } catch (error) {
        console.log("campaigns did not load", error);
      } finally {
        this.checkingIfUserHasCampaigns = false;
      }
    },

    /** handle loading stat data */
    async loadRedeemedCampaignsWithoutFinalizations() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let requestBody = {
        $and: [
          {
            "recipients.contact.email": {
              $in: [this.recipient.contact.email],
            },
          },
          {
            $or: [
              {
                "metadata.campaign_status": {
                  $eq: "redeemed",
                },
              },
              {
                "metadata.isRedeemed": true,
              },
              { "metadata.recipient_selection": { $exists: true } },
            ],
          },
        ],
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients",
      };

      const response = await api.listMailCampaigns(queryParameters, {
        filter: requestBody,
      });
      const redeemedCount = response.data.result.count;
      this.redeemedCampaignsWithoutFinalizations = redeemedCount;
    },
    async loadTotalSpendByRedeemedCampaignsWithoutFinalizations() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let filter = {
        $and: [
          {
            "recipients.contact.email": {
              $in: [this.recipient.contact.email],
            },
          },
          {
            $or: [
              {
                "metadata.campaign_status": {
                  $eq: "redeemed",
                },
              },
              {
                "metadata.isRedeemed": true,
              },
              { "metadata.recipient_selection": { $exists: true } },
            ],
          },
        ],
      };

      let group = {
        _id: null,
        selection: {
          "metadata.gift_card_amount": "$sum",
        },
      };

      const requestBody = {
        filter: filter,
        group: group,
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients",
      };
      const response = await api.listMailCampaigns(
        queryParameters,
        requestBody
      );
      this.totalSpendByCampaignsWithoutFinalizationsAggregated =
        response?.data?.result?.records[0]?.metadata_gift_card_amount__sum || 0;
    },
    async loadRedeemedCampaignsWithFinalizations() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let requestBody = {
        "recipients.contact.email": {
          $in: [this.recipient.contact.email],
        },
        "metadata.finalizations": { $exists: true },
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients",
      };

      const response = await api.listMailCampaigns(queryParameters, {
        filter: requestBody,
      });
      const campaigns = response.data.result.records;
      let finalizedCounts =
        this.calculateRedeemedCampaignsWithFinalizationsByRecipient(
          campaigns,
          this.recipient.contact.email
        );
      this.redeemedCampaignsWithFinalizations =
        finalizedCounts.totalFinalizations;
      this.totalSpendByCampaignsWithFinalizations = finalizedCounts.totalSpend;
    },

    async loadCampaignsWhereSeenIsBoolean() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let requestBody = {
        $and: [
          {
            "recipients.contact.email": {
              $in: [this.recipient.contact.email],
            },
          },
          {
            $or: [
              {
                "metadata.campaign_status": {
                  $eq: "sent",
                },
              },
              {
                "metadata.campaign_status": {
                  $eq: "redeemed",
                },
              },
            ],
          },
          {
            seen: {
              $eq: true,
            },
          },
          {
            $or: [
              { "metadata.isRedeemed": { $exists: false } },
              { "metadata.isRedeemed": true },
            ],
          },
        ],
      };

      const queryParameters = {
        limit: 0,
        expand: "recipients",
      };

      const response = await api.listMailCampaigns(queryParameters, {
        filter: requestBody,
      });
      const count = response.data.result.count;
      this.totalCampaignsSentWithSeenBoolean = count;
      this.clicksByCampaignsCountWithSeenBoolean = count;
    },
    async loadCampaignsWhereSeenIsArray() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let requestBody = {
        $and: [
          {
            "recipients.contact.email": {
              $in: [this.recipient.contact.email],
            },
          },
          { seen: { $exists: true } },
          { seen: { $type: "array" } },
          { "metadata.campaign_status": { $ne: "draft" } },
        ],
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients",
      };
      const response = await api.listMailCampaigns(queryParameters, {
        filter: requestBody,
      });
      const campaignsWithSeenArray = response.data.result.records;
      this.clicksByCampaignsCountWithSeenArray = this.calculateTotalClicks(
        campaignsWithSeenArray,
        this.recipient.contact.email
      ).totalClicks;
    },

    /** handle calculations */
    calculateRedeemedCampaignsWithFinalizationsByRecipient(
      campaignsWithFinalizations,
      recipientEmail
    ) {
      let totalFinalizations = 0;
      let totalSpend = 0;

      // Iterate over each campaign
      campaignsWithFinalizations.forEach((campaign) => {
        const finalizations = campaign.metadata.finalizations;

        // Use a temporary counter for matching finalizations per campaign
        let matchingFinalizations = 0;

        // Check each finalization entry
        for (const key in finalizations) {
          if (finalizations[key].redeemer.email === recipientEmail) {
            matchingFinalizations++;
          }
        }

        // Calculate total spend for the matching finalizations in this campaign
        const campaignTotalSpend =
          matchingFinalizations * campaign.metadata.gift_card_amount;

        // Update totals
        totalFinalizations += matchingFinalizations;
        totalSpend += campaignTotalSpend;
      });

      return { totalFinalizations, totalSpend };
    },
    calculateTotalClicks(campaignsWithSeenArray, recipientEmail) {
      let totalClicks = 0;

      campaignsWithSeenArray.forEach((record) => {
        const recipientEmails = record.recipients.map(
          (recipient) => recipient.contact.email
        );

        // Check if recipientEmail is both in the seen array and among the recipients' emails
        if (
          record.seen.includes(recipientEmail) &&
          recipientEmails.includes(recipientEmail)
        ) {
          totalClicks += 1; // Increment total clicks only once per record if conditions are met
        }
      });

      return { totalClicks };
    },

    /** create new campaign by recipient id */
    async createCampaignDraft() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      const api = await this.getBoldClient();
      this.creatingCampaignDraft = true;

      try {
        const user = this?.User;

        const fromEmail = user?.email;
        const fromName = `${user?.firstName} ${user?.lastName}`;
        const recipientIdsArr = [this.recipient._id];

        const campaignData = {
          from: { email: fromEmail, name: fromName },
          subject: "",
          text: "",
          html: "",
          recipients: recipientIdsArr,
          metadata: {
            campaign_name: "Untitled Campaign",
            campaign_status: "draft",
            campaign_type: "",
            gift_card_amount: 0,
            collection_query: { custom_selection_ids: 0 },
          },
          user: user?.id,
        };

        const campaignResponse = await api.createMailCampaigns(null, [
          campaignData,
        ]);
        this.handleSuccess(campaignResponse, "createCampaignDraft");
      } catch (error) {
        console.log(error);
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your new campaign. Please reload the page and try again."
        );
      } finally {
        this.creatingCampaignDraft = false;
      }
    },
    handleSuccess(response) {
      this.creatingCampaignDraft = false;
      this.campaignId = response.data.result.records[0]._id;
      this.$router.push(`/campaign/edit/${this.campaignId}`);
    },
    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error.response) {
        // Process API error message if customMessage is not provided
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.source.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      this.creatingCampaignDraft = false;
      this.checkForUserData();
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>

<style scoped></style>
