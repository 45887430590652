<template>
  <TheCard>
    <TheBox :withPadding="true" class="tw-relative">
      <BlockStack :gap="100">
        <label
          for="bookingSummary"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >Campaign name<span
            v-if="!getCampaignName"
            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
            >required field</span
          ></label
        >
        <input
          v-model="getCampaignName"
          @input="handleUpdatingCampaignName($event)"
          type="text"
          name="campaignSubject"
          id="campaignSubject"
          :class="{
            'tw-ring-red-300': !getCampaignName,
            'tw-ring-green-500': getCampaignName,
          }"
          class="tw-block tw-w-96 tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
          placeholder=""
        />
      </BlockStack>
      <!-- check circle -->
      <div>
        <template v-if="getCampaignName">
          <b-icon-check-circle-fill
            class="tw-absolute tw-top-4 tw-right-4 tw-h-5 tw-w-5 tw-text-green-600"
          ></b-icon-check-circle-fill>
        </template>
        <template v-else>
          <b-icon-check-circle-fill
            class="tw-absolute tw-top-4 tw-right-4 tw-h-5 tw-w-5 tw-text-gray-400"
          ></b-icon-check-circle-fill>
        </template>
      </div>
    </TheBox>
  </TheCard>
</template>

<script>
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";

export default {
  name: "TheCampaignName",
  emits: ["loadSteps"],
  components: {
    TheCard,
    TheBox,
    BlockStack,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getCampaignName() {
      return this.$store.getters["campaigns/getCampaignName"];
    },
  },
  methods: {
    handleUpdatingCampaignName(event) {
      let campaignName = event.target.value;
      console.log("campaignName: ", campaignName);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_CAMPAIGN_NAME",
        campaignName
      );
      this.$emit("loadSteps");
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
