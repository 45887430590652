// campaigns module - actions.js
// import { getBoldClient } from "@/api/getBoldClient.js";
import singleton from "@/singleton";

export default {
  activateQuickEdit({ commit }) {
    commit("setQuickEditActive", true);
  },
  deactivateQuickEdit({ commit }) {
    commit("setQuickEditActive", false);
  },
  async loadCampaignDetails({ rootState }, campaignId) {
    /**@type {import('@boldxchange/sdk/defs').Client}*/
    // const token = rootState.auth.token; // Assuming your auth token is stored in the rootState.auth.token
    // const api = await getBoldClient(token);
    const api = await singleton.boldClient();


    // filters
    let requestBody = {
      _id: campaignId,
    };
    const queryParameters = {
      limit: 1,
      expand: "recipients invoice",
    };
    try {
      const response = await api.listMailCampaigns(queryParameters, {
        filter: requestBody,
      });
      const campaign = response.data.result.records[0];
      console.log("campaign record: ", campaign);
    } catch (error) {
      this.campaignsAreLoading = false;
      console.error("campaign did not load", error);
    }
  },
};
