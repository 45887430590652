<template>
  <BlockStack
    :gap="400"
    :customClass="customClass"
    :fullWidth="fullWidth"
    :autoWidth="autoWidth"
  >
    <!-- section title -->
    <template v-if="title">
      <InlineStack>
        <BlockStack :gap="100" class="">
          <template v-if="showStrapline">
            <slot name="strapline-content"> </slot>
          </template>
          <template v-if="title">
            <div class="tw-flex tw-items-center tw-gap-2">
              <h2
                class="tw-text-xl sm:tw-text-2xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-m-0 sm:tw-tracking-tight"
              >
                {{ title || "Add a section title" }}
              </h2>
              <template v-if="showTooltip">
                <TheTooltip
                  :toolTip="showTooltip"
                  :tooltipContent="tooltipContent"
                >
                  <template #icon>
                    <div
                      class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-orange-700 tw-flex tw-items-center tw-justify-center tw-bg-orange-100 tw-me-2 tw-cursor-pointer"
                    >
                      <b-icon-exclamation
                        class="tw-text-orange-700 tw-z-0 tw-text-xl"
                      ></b-icon-exclamation>
                    </div>
                  </template>
                </TheTooltip>
              </template>
            </div>
            <template v-if="showSubheading">
              <div>
                <slot name="subheading-content">
                  <p class="tw-m-0">Add a subheading here or</p>
                  <span
                    class="tw-items-center tw-rounded-md tw-bg-yellow-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-600 tw-ring-1 tw-ring-inset tw-ring-gray-500/10"
                  >
                    Badge</span
                  >
                </slot>
              </div>
            </template>
          </template>
        </BlockStack>
        <template v-if="showActions">
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <slot name="page-header-actions"></slot>
          </div>
        </template>
      </InlineStack>
    </template>
    <slot></slot>
  </BlockStack>
</template>

<script>
import InlineStack from "./InlineStack.vue";
import BlockStack from "./BlockStack.vue";
import TheTooltip from "../forms/TheTooltip.vue";

export default {
  name: "PageSection",
  components: { BlockStack, InlineStack, TheTooltip },
  props: {
    id: {
      type: String,
      default: "",
    },
    role: {
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showSubheading: {
      type: Boolean,
      default: false,
    },
    showStrapline: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
    showTooltip: {
      type: Boolean,
    },
    tooltipContent: {
      type: String,
      default: "tooltip content",
    },
  },
  computed: {
    componentClass() {
      return {
        "tw-w-full": this.fullWidth,
        "tw-w-auto ": this.autoWidth,
        // Add customWidth class if customWidth is provided
        [this.customClass]: !!this.customClass,
      };
    },
  },
};
</script>
