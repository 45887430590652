<template>
  <!-- //BlockStack -->
  <div
    class="tw-flex tw-flex-col"
    :style="componentStyles"
    :class="componentClass"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BlockStack",
  props: {
    id: {
      type: String,
      default: "",
    },
    role: {
      default: null,
    },
    gap: {
      type: [Number, Object],
      default: 0,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    autoWidth: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
    spacing: {
      type: String,
      default: null,
    },
  },
  computed: {
    componentStyles() {
      return {
        gap: typeof this.gap === "number" ? `${(this.gap / 100) * 4}px` : null,
      };
    },
    componentClass() {
      return {
        "tw-w-full": this.fullWidth,
        "tw-w-auto ": this.autoWidth,
        [this.customClass]: !!this.customClass,
      };
    },
    paddingClass() {
      return {
        [this.spacing]: !!this.spacing,
      };
    },
  },
};
</script>
