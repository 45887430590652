<template>
    <div class="tooltip-container" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
      <slot :hideTooltip="hideTooltip"></slot>
      <div v-if="showTooltip && !hideOnButtonClick" class="custom-tooltip">
        <slot name="tooltipContent">Default Tooltip Content</slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showTooltip: false,
        hideOnButtonClick: false,
      };
    },
    mounted() {
      document.addEventListener('click', this.checkClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.checkClickOutside);
    },
    methods: {
      hideTooltip() {
        if (!this.hideOnButtonClick) {
          this.showTooltip = false;
        }
      },
      checkClickOutside(event) {
        const tooltipContainer = this.$el;
        if (!tooltipContainer.contains(event.target)) {
          this.hideTooltip();
        }
      },
    },
  };
  </script>
<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  position: absolute;
  top: -40px; /* Adjust the positioning as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 9999;
  display: inline-block;
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;
}

.custom-tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}
</style>
