<template>
  <div class="container-fluid px-lg-5">
    <nav class="navbar">
      <div class="container-fluid container justify-content-center align-items-center gap-2">
        <p class="mb-0 fw-bold">Powered by</p>
        <div class="navbar-brand" aria-label="logo">
          <img
            class="navbar-brand-logo"
            src="../../../../assets/svg/logos/Bold_Xchange_PrimaryLogo_Black.svg"
            alt="Logo"
          />
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "AnonymousNavbar",
  methods: {
    logout() {
      this.$store.commit("auth/LOGOUT");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dropdown-toggle::after {
  border-top: none;
}

.shape {
  background-image: url(../../../../assets/svg/components/card-2.svg);
}
</style>
