<template>
  <button
    class="tw-border-none tw-flex tw-items-center tw-px-1"
    :disabled="disabled"
    :class="[buttonClasses]"
    @click="handleClick"
  >
    <span class="tw-sr-only">Use setting</span>
    <span :class="[sliderClasses]" aria-hidden="true">
      <span :class="[sliderIconClasses]" aria-hidden="true">
        <template v-if="!toggleOn">
          <b-icon-x-lg class=" tw-text-red-400" />
        </template>
        <template v-else>
          <b-icon-check-lg class=" tw-text-blue-600" />
        </template>
      </span>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    toggleOn: {
      type: Boolean,
      default: false,
    },
    enabledColor: {
      type: String,
      default: "blue-600",
    },
    disabledColor: {
      type: String,
      default: "gray-200",
    },
    onClick: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // computedClasses() {
    //   return {
    //     // style classes
    //     ...this.styleClass,
    //     // size classes
    //     ...this.sizeClass,
    //     ...this.buttonClasses
    //   };
    // },
    sizeClass() {
      return {
        "tw-text-xs tw-py-2 tw-px-2 tw-text-center": this.size === "small",
        "tw-text-sm tw-py-2 tw-px-3.5": this.size === "regular",
        "tw-text-lg tw-py-3 tw-px-4": this.size === "large",
      };
    },
    buttonClasses() {
      return [
        "tw-border-none tw-relative tw-inline-flex tw-h-10 tw-w-16 tw-flex-shrink-0 tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors tw-duration-200 tw-ease-in-out focus:tw-outline-none disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed",
        this.toggleOn
          ? `tw-bg-${this.enabledColor} tw-ring-2 tw-ring-blue-600 tw-ring-offset-2`
          : `tw-bg-${this.disabledColor} tw-ring-2 tw-ring-gray-600`,
      ];
    },
    sliderClasses() {
      return [
        "disabled:tw-cursor-not-allowed tw-pointer-events-none tw-relative tw-inline-block tw-h-8 tw-w-8 tw-transform tw-rounded-full tw-bg-white tw-shadow tw-ring-0 tw-transition tw-duration-200 tw-ease-in-out",
        this.toggleOn ? "tw-translate-x-6" : "tw-translate-x-0",
      ];
    },
    sliderIconClasses() {
      return [
        "tw-absolute tw-inset-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-transition-opacity",
        this.toggleOn
          ? "tw-opacity-100 tw-duration-200 ease-in"
          : "tw-duration-100 tw-ease-out",
      ];
    },
  },
  methods: {
    handleClick(event) {
      if (this.onClick) {
        this.onClick(event);
      }
    },
  },
};
</script>

<style scoped>
/* Add any scoped styles if needed */
</style>
