<template>
  <div class="sm:tw-flex tw-py-3">
    <div class="sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6">
      <p class="tw-text-gray-900 tw-capitalize tw-m-0">
        Start:{{ payment?.period?.start || "N/A" }}
      </p>
      <p class="tw-text-gray-900 tw-capitalize tw-m-0">
        end:{{ payment?.period?.end || "N/A" }}
      </p>
    </div>
    <div class="tw-flex tw-justify-between tw-gap-x-6 sm:tw-flex-auto tw-m-0">
      <p class="tw-text-gray-900 tw-capitalize tw-m-0">
        {{ payment?.amount || "N/A" }}
      </p>

      <TheLink :external="true" variant="primary" :url="payment.receipt.url">
        <template #text>Receipt</template>
      </TheLink>
    </div>
  </div>
</template>

<script>
import TheLink from "../../components-v2/ui/actions/TheLink";

export default {
  name: "SubscriptionsHistoryListItem",
  components: { TheLink },
  props: {
    payment: {
      type: Object,
    },
  },
  async created() {},
  data() {
    return {
      vendor: {},
    };
  },
  computed: {
    wasRedeemed() {
      return this?.campaign?.invoice;
    },
    giftCardAmount() {
      return `$${this?.campaign?.metadata?.gift_card_amount}`;
    },
    /** vendor */
    vendorName() {
      return this?.vendor?.name;
    },
    vendorStreet() {
      return this?.vendor?.address?.lines[0];
    },
    vendorCity() {
      return this?.vendor?.address?.city;
    },
    vendorState() {
      return this?.vendor?.address?.state;
    },
    vendorCountry() {
      return this?.vendor?.address?.country;
    },
    vendorPostal() {
      return this?.vendor?.address?.postalCode;
    },
  },
  methods: {
    /** campaign */
    async loadCampaignDetails() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.campaignsAreLoading = true;
      const id = this.$route.params.campaignId;

      let requestBody = {
        _id: id,
      };
      let selection = "from recipients contact invoice metadata";

      const queryParameters = {
        limit: 1,
        expand: "recipients contact invoice",
      };

      try {
        const response = await api.listMailCampaigns(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.campaignsAreLoading = false;
        const vendorId =
          response.data.result.records[0].metadata.recipient_selection;
        return vendorId;
      } catch (error) {
        this.campaignsAreLoading = false;
        console.log("campaigns did not load", error);
      }
    },
    /** vendor */
    async getSingleVendorByID(vendorId) {
      const api = await this.getBoldClient();
      this.vendorDataIsLoading = true;
      try {
        const {
          data: {
            result: { records: singleVendorById },
          },
        } = await api.listVendors(
          {
            limit: 1,
            expand: "media contact address",
          },
          { filter: { _id: vendorId } }
        );

        this.vendor = singleVendorById[0];
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
