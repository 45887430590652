// campaignService.js
import { getBoldClient } from "@/api/getBoldClient.js";
import store from "../store/index";

const upsertUserDetails = async (userId, userDetailsPatch) => {
  const token = store.state.auth.token; // Ensure token retrieval logic is correct
  const api = await getBoldClient(token);

  return api.upsertUser(null, {
    filter: { _id: userId },
    patch: userDetailsPatch,
  });
};

// Export the functions for use in your components or Vuex actions
export { upsertUserDetails };
