<template>
  <TheCard>
    <TheBox :withPadding="true" class="tw-relative">
      <div class="tw-flex tw-flex-col tw-gap-10">
        <div class="tw-flex tw-flex-col tw-gap-5">
          <h2
            class="tw-text-3xl tw-font-bold tw-leading-6 tw-m-0"
            :class="{
              'tw-text-red-500': this.getCampaignGiftCardAmount == 0,
              'tw-text-green-600': this.getCampaignGiftCardAmount != 0,
            }"
          >
            ${{ getCampaignGiftCardAmount }}
          </h2>
          <BlockStack :gap="100">
            <span class="tw-isolate tw-inline-flex tw-rounded-md">
              <button
                type="button"
                @click="setGiftCardAmount(5)"
                class="tw-border-none disabled:tw-bg-slate-300 tw-w-16 tw-relative tw-inline-flex tw-items-center tw-justify-center tw-rounded-l-md tw-bg-white tw-px-3 tw-py-2 tw-text-lg tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
              >
                $5
              </button>
              <button
                type="button"
                @click="setGiftCardAmount(10)"
                class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-w-16 tw--ml-px tw-inline-flex tw-items-center tw-justify-center tw-bg-white tw-px-3 tw-py-2 tw-text-lg tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
              >
                $10
              </button>
              <button
                type="button"
                @click="setGiftCardAmount(25)"
                class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-w-16 tw--ml-px tw-inline-flex tw-items-center tw-justify-center tw-bg-white tw-px-3 tw-py-2 tw-text-lg tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
              >
                $25
              </button>
              <button
                type="button"
                @click="setGiftCardAmount(50)"
                class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-w-16 tw--ml-px tw-inline-flex tw-items-center tw-justify-center tw-bg-white tw-px-3 tw-py-2 tw-text-lg tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
              >
                $50
              </button>
              <button
                type="button"
                @click="setGiftCardAmount(75)"
                class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-w-16 tw--ml-px tw-inline-flex tw-items-center tw-justify-center tw-bg-white tw-px-3 tw-py-2 tw-text-lg tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
              >
                $75
              </button>
              <button
                type="button"
                @click="setGiftCardAmount(100)"
                class="tw-border-none tw-relative tw--ml-px tw-inline-flex tw-w-16 tw-items-center tw-rounded-r-md tw-bg-white tw-px-3 tw-py-2 tw-text-lg tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
              >
                $100
              </button>
            </span>
          </BlockStack>
        </div>
        <template v-if="campaign?.metadata">
          <div>
            <div class="tw-text-start">
              <p
                class="tw-text-lg tw-font-bold tw-leading-6 tw-text-gray-900 tw-m-0"
              >
                Or add a custom gift card amount
              </p>
              <p
                class="tw-text-sm tw-font-bold tw-leading-6 tw-text-gray-500 tw-m-0"
              >
                Amount must be a whole number of $5 or more
              </p>
            </div>

            <div class="tw-relative">
              <div
                class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
              >
                <b-icon-currency-dollar
                  class="tw-h-5 tw-w-5 tw-text-gray-400"
                  aria-hidden="true"
                ></b-icon-currency-dollar>
              </div>
              <input
                v-model="getCampaignGiftCardAmount"
                @input="handleCustomAmountInput"
                type="text"
                name="giftCardAmount"
                id="giftCardAmount"
                class="tw-block tw-max-w-36 tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                placeholder="amount"
              />
            </div>
          </div>
        </template>
      </div>
      <div>
        <template v-if="getCampaignGiftCardAmount">
          <b-icon-check-circle-fill
            class="tw-absolute tw-top-4 tw-right-4 tw-h-5 tw-w-5 tw-text-green-600"
          ></b-icon-check-circle-fill>
        </template>
        <template v-else>
          <b-icon-check-circle-fill
            class="tw-absolute tw-top-4 tw-right-4 tw-h-5 tw-w-5 tw-text-gray-400"
          ></b-icon-check-circle-fill>
        </template>
      </div>
    </TheBox>
  </TheCard>
</template>

<script>
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";
import userMixin from "../../../mixins/userMixin";

export default {
  name: "TheGiftCardAmount",
  emits: ["loadSteps"],
  mixins: [userMixin],
  components: {
    TheCard,
    TheBox,
    BlockStack,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getDefaultGiftCardApplyToNewCampaigns(newVal, oldVal) {
      this.checkGiftCardConditions();
    },
    getDefaultGiftCardAmount(newVal, oldVal) {
      this.checkGiftCardConditions();
    },
  },
  computed: {
    getCampaignGiftCardAmount() {
      return this.$store.getters["campaigns/getCampaignGiftCardAmount"];
    },
    getDefaultGiftCardApplyToNewCampaigns() {
      return this.userMixinData.metadata.campaignSettings.giftCardAmount
        .applyToNewCampaigns;
    },
    getDefaultGiftCardAmount() {
      return this.userMixinData.metadata.campaignSettings.giftCardAmount.value;
    },
  },
  methods: {
    checkGiftCardConditions() {
      if (
        !this.getCampaignGiftCardAmount &&
        this.getDefaultGiftCardApplyToNewCampaigns &&
        this.getDefaultGiftCardAmount
      ) {
        this.setGiftCardAmount(this.getDefaultGiftCardAmount);
      }
    },
    handleCustomAmountInput(event) {
      let value = event.target.value;
      const trimmedValue = value.trim();
      value = parseFloat(trimmedValue);

      // Check if the trimmed value is a valid number and meets the criteria
      if (!Number.isNaN(value) && value >= 5 && Number.isSafeInteger(value)) {
        let giftCardAmount = Math.round(value).toString();
        value = giftCardAmount;
        this.$store.commit(
          "campaigns/SET_CAMPAIGN_BUILDER_GIFT_CARD_AMOUNT",
          value
        );
        this.$emit("loadSteps");
        this.clearTimeoutIfNeeded();
      } else {
        this.clearTimeoutIfNeeded();

        // Reset to "0" string only if the input is not a valid number
        this.timeoutId = setTimeout(() => {
          value = "0"; // Reset to "0" string
          this.$store.commit(
            "campaigns/SET_CAMPAIGN_BUILDER_GIFT_CARD_AMOUNT",
            value
          );
          this.$emit("loadSteps");
        }, 350);
      }
    },
    clearTimeoutIfNeeded() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
    setGiftCardAmount(giftCardAmount) {
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_GIFT_CARD_AMOUNT",
        giftCardAmount
      );
      this.$emit("loadSteps");
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
