<template>
  <TheBox :withPadding="true">
    <BlockStack
      :gap="300"
      class="tw-w-full tw-flex tw-items-center tw-justify-center tw-grow"
    >
      <!-- 2_no_campaigns_exist -->
      <template
        v-if="
          emptyState === 'campaigns' ||
          emptyState === 'invoices' ||
          emptyState === 'memberCampaigns' ||
          emptyState === 'campaignsForBudgets'
        "
      >
        <img
          style="max-height: 150px; width: auto"
          src="../../../assets/svg/illustrations-test/2_no_campaigns_exist.png"
          alt="Empty State Image"
        />
      </template>
      <!-- 46_blank_page -->
      <template
        v-else-if="
          emptyState === 'recipients' ||
          emptyState === 'memberRecipients' ||
          emptyState === 'groupRecipients' ||
          emptyState === 'segmentRecipients'
        "
      >
        <img
          style="max-height: 150px; width: auto"
          src="../../../assets/svg/illustrations-test/46_blank_page.png"
          alt="Empty State Image"
        />
      </template>
      <!-- 108_hands_plus -->
      <template
        v-else-if="
          emptyState === 'gifts' ||
          emptyState === 'recipientCampaigns' ||
          emptyState === 'customCardsSelection' ||
          emptyState === 'savedTemplates' ||
          emptyState === 'draftTemplates' ||
          emptyState === 'budgets'
        "
      >
        <img
          style="max-height: 150px; width: auto"
          src="../../../assets/svg/illustrations-test/108_hands_plus.png"
          alt="Empty State Image"
        />
      </template>
      <!-- 78_origami -->
      <template v-else-if="emptyState === 'boldTemplates'">
        <img
          style="max-height: 150px; width: auto"
          src="../../../assets/svg/illustrations-test/78_origami.png"
          alt="Empty State Image"
        />
      </template>
      <!-- 97_caution_alert -->
      <template v-else-if="emptyState === 'caution'">
        <img
          style="max-height: 150px; width: auto"
          src="../../../assets/svg/illustrations-test/97_caution_alert.png"
          alt="Empty State Image"
        />
      </template>
      <!-- 108_hands_plus -->
      <template v-else>
        <img
          style="max-height: 150px; width: auto"
          src="../../../assets/svg/illustrations-test/108_hands_plus.png"
          alt="Empty State Image"
        />
      </template>
      <BlockStack
        :gap="100"
        class="tw-flex tw-text-center tw-items-center tw-justify-center tw-max-w-2xl"
      >
        <h1
          class="tw-m-0 tw-text-2xl tw-font-bold sm:tw-truncate sm:tw-text-3xl sm:tw-tracking-tight"
        >
          {{ emptyStateTitle }}
        </h1>
        <p class="tw-m-0 tw-text-lg">{{ emptyStateSubtext }}</p>
      </BlockStack>
    </BlockStack>
  </TheBox>
</template>

<script>
import BlockStack from "./../layout/BlockStack.vue";
import TheBox from "./../layout/TheBox.vue";

export default {
  name: "EmptyState",
  components: { BlockStack, TheBox },
  props: {
    role: {
      type: String,
      default: "empty state",
    },
    id: {
      type: String,
      default: "",
    },
    columnReverse: {
      type: Boolean,
      default: false,
    },
    emptyState: {
      type: String,
      default: "generic",
    },
    /**
     * False by default
     * Makes actions slot accessible when true
     */
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyStateData: {
        generic: {
          title: "No data available",
          subtext: "Please take action.",
        },
        campaigns: {
          title: "Create a memorable touchpoint",
          subtext: "Once you create a campaign, it will show up here.",
        },
        budgets: {
          title: "Build your first budget",
          subtext:
            "Create a budget to monitor and limit campaign spending over time.",
        },
        memberCampaigns: {
          title: "This member has not created a campaign yet",
          subtext: "Once they create a campaign, it will show up here.",
        },
        campaignsForBudgets: {
          title: "No campaigns exist for this budget yet",
          subtext:
            "Once a campaign is paired with this budget and sent, it will show up here.",
        },
        recipients: {
          title: "Manage your recipients",
          subtext:
            "Upload a spreadsheet of recipients or create a single recipient.",
        },
        memberRecipients: {
          title: "This member has not created any recipients yet",
          subtext: "Once they create a recipient, it will show up here.",
        },
        groupRecipients: {
          title: "This group doesn't have recipients yet",
          subtext:
            "Upload a CSV file or add a single recipient.",
        },
        segmentRecipients: {
          title: "This segment doesn't have recipients yet",
          subtext:
            "As we receive your data, we'll automatically look for people who meet this segment's criteria.",
        },
        gifts: {
          title: "Your gifts",
          subtext: "eGifts sent to you will show up here.",
        },
        savedTemplates: {
          title: "Manage your saved templates",
          subtext:
            "Looks like you haven't saved any campaign templates. Streamline your workflow with custom campaign templates for recurring occasions and touchpoints.",
        },
        draftTemplates: {
          title: "You don't have draft templates yet",
          subtext: "Your draft campaign templates will appear here.",
        },
        invoices: {
          title: "You don't have invoices yet",
          subtext: "Your invoices will appear here.",
        },
        groups: {
          title: "You don't have groups yet",
          subtext: "Create groups to organize your contacts and optimize your workflow.",
        },
        boldTemplates: {
          title: "Coming soon",
          subtext:
            "Campaign templates to streamline your touchpoint management workflow.",
        },
        recipientCampaigns: {
          title: "Lets create a memorable touchpoint",
          subtext: "Impress your new recipient with a thoughtful gift.",
        },
        caution: {
          title: "Action required",
          subtext:
            "Please update your payment method so your recipients can redeem their gift cards.",
        },
        customCardsSelection: {
          title: "Your e-gift cards selection will show up here",
          subtext: "Return to the catalog to make your selection.",
        },
        data: {
          title: "No exact matches",
          subtext:
            "Upload a spreadsheet of recipients or create a single recipient.",
        },
      },
    };
  },
  computed: {
    emptyStateTitle() {
      return this.emptyStateData[this.emptyState].title;
    },
    emptyStateSubtext() {
      return this.emptyStateData[this.emptyState].subtext;
    },
  },
};
</script>
