<template>
  <CampaignBuilderV2 :isTemplateBuilder="true"></CampaignBuilderV2>
</template>

<script>
import CampaignBuilderV2 from "../../pages-v2/campaigns/CampaignBuilderV2";
export default {
  name: "CampaignTemplateBuilderV2",
  components: {
    CampaignBuilderV2,
  },
};
</script>

<style scoped></style>
