<template>
    <div class="tw-w-full tw-pt-4 sm:tw-pt-6">
        <slot></slot>
    </div>
</template>
  
<script>
import ThePlaceholder from "../placeholders/ThePlaceholder.vue";


export default {
    name: 'CardFooter',
    components: { ThePlaceholder },

    props: {
        textAlign: {
            type: String,
            default: 'start'
        },
        height: {
            type: [Number, Object],
            default: null
        },
        id: {
            type: String,
            default: ''
        },
        role: {
            default: null
        },
        padding: {
            type: [Number, Object],
            default: 200
        },
        backgroundColor: {
            type: String,
            default: 'none'
        },
        width: {
            type: [Number, Object],
            default: 100
        }
    },
    computed: {
        componentStyles() {
            return {
                width: typeof this.width === 'number' ? `${this.width}%` : 'auto',
            };
        }
    }
};
</script>
  