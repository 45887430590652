<template>
    <div class="tw-flex-1 tw-w-full">
        <slot></slot>

    </div>
</template>
  
<script>

export default {
    name: 'CardBody',
    components: {},
    props: {
        id: {
            type: String,
            default: ''
        },
        role: {
            default: null
        },
        backgroundColor: {
            type: Boolean,
            default: true
        },
        width: {
            type: [Number, Object],
            default: 100
        }
    },
    computed: {
        componentStyles() {
            return {
                height: typeof this.height === 'number' ? `${(this.height / 100) * 4}px` : null,
                padding: typeof this.padding === 'number' ? `${(this.padding / 100) * 4}px` : null,
                width: typeof this.width === 'number' ? `${this.width}%` : 'auto',
                backgroundColor: this.backgroundColor ? '#ffcc48' : null,
            };
        }
    }
};
</script>
  