<template>
  <TheBox class="tw-pr-3 tw-max-h-[750px] tw-overflow-y-auto">
    <BlockStack :gap="500" class="tw-max-w-4xl tw-w-full">
      <div>
        <div class="sm:tw-hidden">
          <label for="tabs" class="tw-sr-only">Select a tab</label>
          <select
            v-model="currentTab"
            id="tabs"
            name="tabs"
            class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
          >
            <option
              v-for="tab in tabs"
              :key="tab.id"
              :selected="tab.id"
              :value="tab.id"
            >
              {{ tab.name }}
            </option>
          </select>
        </div>
        <div class="tw-hidden sm:tw-block">
          <div
            class="tw-border-b-3 tw-border-gray-200 tw-border-solid tw-border-t-0 tw-border-x-0"
          >
            <nav class="tw-flex tw-items-center tw-space-x-8" aria-label="Tabs">
              <a
                v-for="tab in tabs"
                :key="tab.name"
                @click.prevent="handleTabChange(tab.id)"
                :class="[
                  currentTab === tab.id
                    ? 'tw-border-blue-700 tw-text-blue-800 tw-border-solid tw-border-2 tw-border-t-0 tw-border-x-0 tw-cursor-pointer'
                    : ' tw-text-gray-500 hover:tw-border-gray-300 hover:tw-border-solid hover:tw-border-x-0 hover:tw-border-t-0 hover:tw-text-gray-700 tw-cursor-pointer',
                  'tw-whitespace-nowrap tw-border-2 tw-border-b-5 tw-py-4 tw-px-1 tw-text-xl tw-font-medium tw-cursor-pointer',
                ]"
                >{{ tab.name }}</a
              >
            </nav>
          </div>
        </div>
      </div>
      <template v-if="currentTab === 1">
        <PageSection class="tw-w-full">
          <TheCard :fullWidth="true">
            <CardHeader
              :showActions="true"
              :useSearchBar="false"
              :showTitle="true"
            >
              <template #card-header-actions>
                <template v-if="!isEditing && !campaignView && !dashboardView">
                  <div>
                    <TheButton
                      :disabled="!recipientGiftingLead"
                      type="button"
                      variant="primary"
                      size="small"
                      :hasLeadingIcon="true"
                      @click="editRecipient()"
                    >
                      <template #leading-icon
                        ><b-icon-pencil></b-icon-pencil
                      ></template>
                      <template #text>Edit</template>
                    </TheButton>
                  </div>
                </template>
                <template v-if="isEditing">
                  <div class="tw-flex tw-gap-4 tw-items-center">
                    <TheButton
                      type="button"
                      variant="success"
                      size="small"
                      :disabled="!this.allItemsComplete"
                      @click="saveRecipient()"
                    >
                      <template #text>Save changes</template>
                    </TheButton>
                    <TheButton
                      type="button"
                      variant="tertiary"
                      size="small"
                      @click="cancelEdit()"
                    >
                      <template #text>Cancel</template>
                    </TheButton>
                  </div>
                </template>
              </template>
            </CardHeader>
            <CardBody class="tw-w-full tw-max-h-[500px] tw-overflow-y-auto">
              <TheBox :noTopPadding="true">
                <BlockStack :gap="600">
                  <NotificationMessage
                    :scope="$options.name"
                  ></NotificationMessage>

                  <!-- caution -->
                  <template v-if="recipientEmailExists">
                    <div
                      class="tw-border-l-4 tw-border-orange-400 tw-bg-orange-50 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-items-center tw-gap-4"
                    >
                      <b-icon-exclamation-circle
                        class="tw-text-orange-700 tw-text-lg"
                      ></b-icon-exclamation-circle>
                      <p
                        class="tw-text-sm tw-m-0 tw-text-start tw-text-orange-700"
                      >
                        This email already exists in your recipient list. Check
                        to avoid duplication.
                      </p>
                    </div>
                  </template>
                  <!-- name -->
                  <div class="tw-flex gap-2 tw-flex-wrap tw-w-full">
                    <!-- first name -->
                    <BlockStack :gap="200" class="tw-grow">
                      <label
                        for="recipientFirstName"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >First name<span
                          v-if="!firstNameIsValid"
                          class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                          >required field</span
                        ></label
                      >
                      <input
                        v-model="form.firstName"
                        type="text"
                        name="recipientFirstName"
                        id="recipientFirstName"
                        :class="[
                          inputClass,
                          { 'tw-ring-red-300': !firstNameIsValid },
                        ]"
                        class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900"
                        placeholder="Joe"
                        :readonly="!isEditing"
                      />
                    </BlockStack>
                    <!-- last name -->
                    <BlockStack :gap="200" class="tw-grow">
                      <label
                        for="recipientLastName"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >Last name<span
                          v-if="!lastNameIsValid"
                          class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                          >required field</span
                        ></label
                      >
                      <input
                        v-model="form.lastName"
                        type="text"
                        name="recipientLastName"
                        id="recipientLastName"
                        :class="[
                          inputClass,
                          { 'tw-ring-red-300': !lastNameIsValid },
                        ]"
                        class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900"
                        placeholder="Kay"
                        :readonly="!isEditing"
                      />
                    </BlockStack>
                  </div>
                  <!-- <div class="tw-flex gap-2 tw-flex-wrap tw-w-full"> -->
                  <!-- email -->
                  <BlockStack :gap="200" class="tw-grow">
                    <label
                      for="recipientEmail"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Email address<span
                        v-if="!emailIsValid"
                        class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required field</span
                      ></label
                    >
                    <input
                      v-model="form.contact.email"
                      type="text"
                      name="recipientEmail"
                      id="recipientEmail"
                      :class="[
                        inputClass,
                        { 'tw-ring-red-300': !emailIsValid },
                      ]"
                      class="tw-block tw-w-1/2 tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900"
                      placeholder="joekay@example.com"
                      :readonly="!isEditing"
                    />
                  </BlockStack>
                  <!-- lifecycle stage -->
                  <BlockStack :gap="200" class="tw-grow">
                    <label
                      for="recipientTags"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Tags</label
                    >

                    <template v-if="!isEditing">
                      <!-- <input
                          v-model="form.contact.metadata.lifecycleStage"
                          type="text"
                          id="recipientLifecycleStage"
                          name="recipientLifecycleStage"
                          :class="[
                            inputClass,
                            { 'tw-ring-red-300': !emailIsValid },
                          ]"
                          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-truncate tw-text-ellipsis"
                          :readonly="!isEditing"
                        /> -->
                      <MultiSelectAccordion
                        :disabled="!isEditing"
                        buttonText="Select tags"
                        v-model="form.contact.metadata.lifecycleStage"
                        :options="availableTags"
                        :placeholder="'Select tags'"
                        size="large"
                        :id="'recipient-tags-read-only'"
                      />
                    </template>
                    <template v-else>
                      <MultiSelectAccordion
                        :disabled="!isEditing"
                        buttonText="Select tags"
                        v-model="form.contact.metadata.lifecycleStage"
                        :options="availableTags"
                        :placeholder="'Select tags'"
                        size="large"
                        :id="'edit-recipient-tags-multiselect-dropdown'"
                      />
                      <!-- <select
                          v-model="form.contact.metadata.lifecycleStage"
                          id="recipientLifecycleStage"
                          name="recipientLifecycleStage"
                          class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                          :readonly="!isEditing"
                        >
                          <option value="" class="" disabled>
                            Select an option
                          </option>
                          <option
                            v-for="option in tagOptions"
                            :value="option.value"
                            :key="option.index"
                            class=""
                          >
                            {{ option.text }}
                          </option>
                        </select> -->
                    </template>
                  </BlockStack>
                  <!-- </div> -->

                  <!-- birthday -->
                  <BlockStack :gap="200">
                    <label
                      for="recipientLifecycleStage"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Birthday</label
                    >
                    <div class="tw-flex tw-gap-2">
                      <template v-if="!isEditing">
                        <input
                          id="birthdayMonth"
                          name="birthdayMonth"
                          v-model="form.contact.metadata.birthday.month"
                          class="tw-block tw-w-1/3 tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900"
                          :class="{
                            'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6':
                              !isEditing,
                          }"
                          placeholder="Month"
                          :readonly="!isEditing"
                        />
                        <input
                          id="birthdayDay"
                          name="birthdayDay"
                          v-model="form.contact.metadata.birthday.day"
                          class="tw-block tw-w-1/3 tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900"
                          :class="{
                            'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6':
                              !isEditing,
                          }"
                          placeholder="Day"
                          :readonly="!isEditing"
                        />
                      </template>
                      <template v-else>
                        <select
                          id="birthdayMonth"
                          name="birthdayMonth"
                          v-model="form.contact.metadata.birthday.month"
                          class="tw-block tw-bg-white tw-w-1/3 tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                        >
                          <option value="" class="" disabled>Month</option>
                          <option
                            v-for="option in monthOptions"
                            :value="option.value"
                            :key="option.index"
                          >
                            {{ option.text }}
                          </option>
                        </select>
                        <select
                          id="birthdayDay"
                          name="birthdayDay"
                          v-model="form.contact.metadata.birthday.day"
                          class="tw-block tw-bg-white tw-w-1/3 tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                        >
                          <option value="" class="" disabled>Day</option>
                          <option
                            v-for="option in dayOptions"
                            :value="option.value"
                            :key="option.index"
                          >
                            {{ option.text }}
                          </option>
                        </select>
                      </template>
                    </div>
                  </BlockStack>
                  <div class="tw-flex gap-2 tw-flex-wrap tw-w-full">
                    <!-- phone -->
                    <BlockStack :gap="200" class="tw-grow">
                      <label
                        for="recipientPhone"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >Phone number<span
                          v-if="!isPhoneNumberValid"
                          class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                          >Please enter a valid phone number (6-12 digits, no
                          special characters).</span
                        ></label
                      >
                      <input
                        v-model="form.contact.phone"
                        type="text"
                        name="recipientPhone"
                        id="recipientPhone"
                        :class="[
                          inputClass,
                          { 'tw-ring-red-300': !isPhoneNumberValid },
                        ]"
                        class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900"
                        placeholder="1111111111"
                        :readonly="!isEditing"
                      />
                    </BlockStack>
                    <!-- company -->
                    <BlockStack :gap="200" class="tw-grow">
                      <label
                        for="recipientCompany"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >Company</label
                      >
                      <input
                        v-model="form.contact.company"
                        type="text"
                        name="recipientCompany"
                        id="recipientCompany"
                        :class="inputClass"
                        class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900"
                        placeholder="Joe Kay Sales"
                        :readonly="!isEditing"
                      />
                    </BlockStack>
                    <!-- title -->
                    <BlockStack :gap="200" class="tw-grow">
                      <label
                        for="recipientTitle"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                        >Job Title</label
                      >
                      <input
                        v-model="form.contact.title"
                        type="text"
                        name="recipientTitle"
                        id="recipientTitle"
                        :class="inputClass"
                        class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900"
                        placeholder="Sales executive"
                        :readonly="!isEditing"
                      />
                    </BlockStack>
                  </div>
                </BlockStack>
              </TheBox>
            </CardBody>
          </TheCard>
        </PageSection>
      </template>
      <template v-if="currentTab === 2">
        <PageSection class="tw-w-full">
          <TheCard :fullWidth="true">
            <CardHeader
              :showActions="true"
              :useSearchBar="false"
              :showTitle="true"
            >
              <template #card-header-actions>
                <template v-if="!isEditing && !campaignView && !dashboardView">
                  <div>
                    <TheButton
                      :disabled="!recipientGiftingLead"
                      type="button"
                      variant="primary"
                      size="small"
                      :hasLeadingIcon="true"
                      @click="editRecipient()"
                    >
                      <template #leading-icon
                        ><b-icon-pencil></b-icon-pencil
                      ></template>
                      <template #text>Edit</template>
                    </TheButton>
                  </div>
                </template>
                <template v-if="isEditing">
                  <div class="tw-flex tw-gap-4 tw-items-center">
                    <TheButton
                      type="button"
                      variant="success"
                      size="small"
                      :disabled="!this.allItemsComplete"
                      @click="saveRecipient()"
                    >
                      <template #text>Save changes</template>
                    </TheButton>
                    <TheButton
                      type="button"
                      variant="tertiary"
                      size="small"
                      @click="cancelEdit()"
                    >
                      <template #text>Cancel</template>
                    </TheButton>
                  </div>
                </template>
              </template>
            </CardHeader>
            <CardBody class="tw-w-full">
              <TheBox :noTopPadding="true">
                <BlockStack :gap="400">
                  <NotificationMessage
                    :scope="$options.name"
                  ></NotificationMessage>
                  <!-- notes -->
                  <BlockStack :gap="200">
                    <RichTextEditor
                      :isEditing="isEditing"
                      v-model="form.contact.metadata.notes"
                    ></RichTextEditor>
                  </BlockStack>
                </BlockStack>
              </TheBox>
            </CardBody>
          </TheCard>
        </PageSection>
      </template>
      <template v-if="currentTab === 3">
        <PageSection class="tw-w-full">
          <RecipientRedemptionHistory
            :editMyRecordsOnly="recipientGiftingLead"
            :recipient="recipient"
          ></RecipientRedemptionHistory>
        </PageSection>
      </template>
    </BlockStack>
  </TheBox>
</template>

<script>
import BlockStack from "../layout/BlockStack.vue";
import TheBox from "../layout/TheBox.vue";
import TheButton from "../actions/TheButton.vue";
import NotificationMessage from "../feedback/NotificationMessage.vue";
import RichTextEditor from "../forms/RichTextEditor.vue";
import PageSection from "../layout/PageSection.vue";
import TheCard from "../layout/TheCard.vue";
import CardBody from "../layout/CardBody.vue";
import CardHeader from "../layout/CardHeader.vue";
import RecipientRedemptionHistory from "../../recipients/RecipientRedemptionHistory.vue";
import MultiSelectAccordion from "../elements/accordion/MultiSelectAccordion.vue";

export default {
  name: "EditRecipientForm",
  emits: ["closeModal", "handleSuccess"],

  components: {
    MultiSelectAccordion,
    TheBox,
    RecipientRedemptionHistory,
    RichTextEditor,
    BlockStack,
    CardHeader,
    TheButton,
    NotificationMessage,
    PageSection,
    CardBody,
    TheCard,
  },
  props: {
    recipient: {
      type: Object,
      default: () => ({}),
    },
    campaignView: {
      type: Boolean,
      default: false,
    },
    dashboardView: {
      type: Boolean,
      default: false,
    },
    editMyRecordsOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        contact: {
          company: "",
          email: "",
          title: "",
          phone: "",
          metadata: {
            lifecycleStage: "",
            birthday: {
              month: "",
              day: "",
            },
            notes: null,
          },
        },
      },
      tabs: [
        { id: 1, name: "Contact Information" },
        { id: 2, name: "Notes" },
        { id: 3, name: "Gifting History" },
      ],
      existingRecipientsTags: [],
      availableTags: [
        "a-list",
        "b-list",
        "c-list",
        "center of influence",
        "cold lead",
        "customer",
        "warm lead",
      ],
      months: [],
      daysInMonth: [],
      currentTab: 1,
      isMyRecord: true,
      isEditing: false,
      recipientEmailExists: false,
      debounceTimer: null,
    };
  },
  created() {
    this.initializeForm(this.recipient);
    // this.isMyRecord = this.recipient?.giftingLeads === this?.$store?.state?.auth?.user?.id;

    this.daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);
    this.months = Array.from({ length: 12 }, (_, i) => {
      return new Date(0, i).toLocaleDateString("default", { month: "long" });
    });
    this.getRecipientsTags();
  },
  watch: {
    "contact.email": {
      handler: "handleNameChange",
      deep: true,
    },
  },
  computed: {
    allItemsComplete() {
      return (
        this.firstNameIsValid &&
        this.lastNameIsValid &&
        this.emailIsValid &&
        this.isPhoneNumberValid
      );
    },
    firstNameIsValid() {
      return this.form.firstName.trim().length > 0;
    },
    lastNameIsValid() {
      return this.form.lastName.trim().length > 0;
    },
    emailIsValid() {
      // Trim the email to remove leading and trailing white spaces
      const email = this.form.contact.email.trim();

      // Check if the email is empty and return false immediately if it is
      if (email === "") {
        return false;
      }

      // Use a regular expression to test if the email is in a valid format
      // This regex checks for the presence of characters before and after an "@"
      // and at least one character after a dot, which is a basic validation for email formats
      return /\S+@\S+\.\S+/.test(email);
    },
    isPhoneNumberValid() {
      const phoneNumber = this.form.contact.phone;

      if (phoneNumber.trim() !== "") {
        // Validate: should contain only numbers and be at most 12 characters long
        const numberRegex = /^\d+$/; // Regex to check if string contains only numbers

        return (
          phoneNumber.length >= 6 &&
          phoneNumber.length <= 12 &&
          numberRegex.test(phoneNumber)
        );
      }
      return true; // If phone number is empty, consider it as valid
    },
    lifecycleStageIsValid() {
      return this.form.contact.metadata.lifecycleStage.trim().length > 0;
    },
    tagOptions() {
      const options = this.tags.map((tag) => ({
        text: tag,
        value: tag,
      }));
      return options;
    },
    monthOptions() {
      const options = this.months.map((month) => ({
        text: month,
        value: month,
      }));
      return options;
    },
    dayOptions() {
      const options = this.daysInMonth.map((day) => ({
        text: day,
        value: day,
      }));
      return options;
    },
    userClient() {
      return this?.$store?.state?.auth?.user?.client;
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    /** classes */
    inputClass() {
      return {
        "tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6":
          this.isEditing,
        "tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6":
          !this.isEditing,
      };
    },
    recipientGiftingLead() {
      // return (recipient) => {
      return (
        this?.recipient?.metadata?.giftingLeads &&
        this?.recipient?.metadata?.giftingLeads[0] ===
          this?.$store?.state?.auth?.user?._id
      );
      // };
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** initialize form data */
    initializeForm(recipientData) {
      // Assuming `recipientData` has a similar structure to your `form` data
      this.form.firstName = recipientData.firstName || "";
      this.form.lastName = recipientData.lastName || "";
      if (recipientData?.contact) {
        this.form.contact.email = recipientData?.contact?.email || "";
        this.form.contact.phone = recipientData?.contact?.phone || "";
        this.form.contact.company = recipientData?.contact?.company || "";
        this.form.contact.title = recipientData?.contact?.title || "";
        const lifecycleStage = recipientData?.contact?.metadata?.lifecycleStage;
        if (typeof lifecycleStage === "string") {
          this.form.contact.metadata.lifecycleStage = [lifecycleStage];
        } else if (Array.isArray(lifecycleStage)) {
          this.form.contact.metadata.lifecycleStage = lifecycleStage;
        } else {
          this.form.contact.metadata.lifecycleStage = [];
        }
        this.form.contact.metadata.birthday = recipientData?.contact?.metadata
          ?.birthday || {
          month: "",
          day: "",
        };
        this.form.contact.metadata.notes =
          recipientData?.contact?.metadata?.notes || null;
      }
    },
    async getRecipientsTags() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      try {
        const api = await this.getBoldClient();

        let filter = {};
        const queryParameters = {
          limit: 500,
          // sort: "-name",
          distinct: "contact.metadata.lifecycleStage",
          expand: "contact",
        };

        // const requestBody = { filter };

        const recipientsTagsRes = await api.listRecipients(
          queryParameters
          // requestBody
        );

        const tags = recipientsTagsRes?.data?.result?.records || [];

        this.existingRecipientsTags = tags;
        this.setAvailableTags();
      } catch (error) {
        console.error("Error fetching neighborhoods:", error);
      }
    },
    setAvailableTags() {
      // Define availableTags
      const availableTags = this.availableTags;

      // Combine lifecycleStage with availableTags, ensuring unique values
      const lifecycleStage = this.form.contact.metadata.lifecycleStage;
      const existingTags = this.existingRecipientsTags;

      // Normalize both arrays to lowercase for comparison
      const normalizedAvailableTags = availableTags.map((tag) =>
        tag.toLowerCase()
      );
      const normalizedLifecycleStage = lifecycleStage.map((tag) =>
        tag.toLowerCase()
      );
      const normalizedExistingTags = existingTags.map((tag) =>
        tag.toLowerCase()
      );

      // Combine and ensure uniqueness
      const combinedNormalizedTags = Array.from(
        new Set([
          ...normalizedLifecycleStage,
          ...normalizedAvailableTags,
          ...normalizedExistingTags,
        ])
      );

      // Sort the combined unique tags in alphabetical order
      const sortedCombinedTags = combinedNormalizedTags.sort();

      this.availableTags = sortedCombinedTags;
    },

    /** handle tab change */
    handleTabChange(tabId) {
      this.currentTab = tabId;
    },

    /** handle name change */
    handleNameChange() {
      // Clear any previously set timer to avoid unnecessary API calls
      clearTimeout(this.debounceTimer);

      // Set a new timer
      this.debounceTimer = setTimeout(() => {
        // Ensure both email is truthy before calling the API
        if (this.emailIsValid) {
          this.checkIfRecipientEmailExists();
        }
      }, 1000); // Adjust debounce time as needed
    },
    async checkIfRecipientEmailExists() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      const email = this.form.contact.email.trim();
      let filterConditions = [
        {
          "contact.email": {
            $eq: email,
          },
        },
      ];

      // Only if the user is not an admin, add the giftingLeads condition
      if (!this.isBoldXchangeAdmin) {
        filterConditions.push({
          "metadata.giftingLeads": { $in: [this.userId] },
        });
      }

      // If there are multiple conditions (meaning the user is not an admin), use $and
      let requestBody = {};
      if (filterConditions.length > 1) {
        requestBody.$and = filterConditions;
      } else {
        // If only the email condition is present (admin user), don't use $and
        requestBody = filterConditions[0];
      }

      let selection = "firstName lastName contact";

      const queryParameters = {
        limit: 300,
        sort: "firstName",
        expand: "contact",
      };

      try {
        const response = await api.listRecipients(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        const count = response.data.result.count;
        this.recipientEmailExists = count > 0; // true if count is greater than 0, otherwise false
      } catch (error) {
        console.log("recipients did not load", error);
      }
    },

    /** handle create */
    async saveRecipient() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      const clientId = this.userClient;
      const giftingLeads = [this.userId];

      let filteredContact = this.filterTruthyProperties(this.form.contact);

      const patchObject = {
        ...(clientId && { client: clientId }),
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        "contact.company": filteredContact.company,
        "contact.email": filteredContact.email,
        "contact.title": filteredContact.title,
        "contact.phone": filteredContact.phone,
        "contact.metadata.lifecycleStage":
          filteredContact.metadata.lifecycleStage,
        "contact.metadata.birthday": filteredContact.metadata.birthday,
        "contact.metadata.notes": filteredContact.metadata.notes,
      };

      try {
        await api.upsertRecipient(null, {
          filter: {
            _id: this.recipient._id,
            ...(this.isBoldXchangeAdmin && {
              "metadata.giftingLeads": { $eq: giftingLeads },
            }),
          },
          patch: patchObject,
        });

        this.handleSuccess(); // Ensure handleSuccess is properly adjusted to handle update response.
      } catch (error) {
        this.handleErrorFromAPI(error); // Assuming this method handles error displaying/logging.
        console.log("recipient update failed", error);
      }
    },

    filterTruthyProperties(obj) {
      if (Array.isArray(obj)) {
        return obj.filter((item) => item); // Filter out falsy values in the array
      }
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] && typeof obj[key] === "object") {
          const nested = this.filterTruthyProperties(obj[key]);
          if (Object.keys(nested).length > 0 || Array.isArray(obj[key])) {
            acc[key] = nested;
          }
        } else if (obj[key]) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    },

    /** handle editing recipient */
    editRecipient() {
      this.isEditing = !this.isEditing;
    },

    /** emits */
    closeModal() {
      this.$emit("closeModal");
    },
    cancelEdit() {
      this.isEditing = !this.isEditing;
      this.initializeForm(this.recipient);
    },

    /** handle success and errors */
    handleSuccess() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Recipient was successfully edited.",
        duration: 4000,
        id: Date.now(),
      });
      this.isEditing = false;
      this.$emit("handleSuccess");
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage =
          "An error occurred setting up the request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      console.error(errorMessage);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
