<template>
  <!-- menu button -->
  <div class="tw-flex tw-items-center">
    <div class="tw-relative" ref="dropdown">
      <template v-if="toolTip">
        <a
          class="tw-flex tw-items-center tw-text-white tw-text-decoration-none"
          @mouseover="openDropdown()"
          @mouseleave="closeDropdown()"
        >
          <slot name="icon"></slot>
        </a>
      </template>
      <div
        class="tw-z-50 tw-absolute lg:tw-bottom-7 lg:tw-right-0 tw-mt-2 tw-w-48 tw-bg-black/90 tw-text-white tw-p-2 tw-rounded-md tw-shadow-lg"
        :class="{ 'tw-hidden': !isOpen }"
      >
        <template v-if="toolTip">
          <div>
            {{ tooltipContent }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  components: {},
  props: {
    toolTip: {
      type: Boolean,
      default: false,
    },
    tooltipContent: {
      type: String,
      default: "Add tooltip content",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    openDropdown() {
      this.isOpen = true;
    },
    closeDropdown() {
      this.isOpen = false;
    },
  },
};
</script>
