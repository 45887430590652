<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <div class="page-header">
        <div class="d-flex justify-content-between">
          <h1 class="page-header-title text-start">Tags</h1>
        </div>
      </div>
      <div class="mb-1">
        <div class="input-group input-group-merge">
          <!-- <div class="input-group-prepend input-group-text">
                  <b-icon-search></b-icon-search>
                </div> -->
          <input
            @keyup.enter="getTagsBySearchQuery()"
            type="text"
            id="datatableSearch"
            class="form-control"
            placeholder="Search by name"
            aria-label="Search name"
            v-model="searchQuery"
          />
          <button
            type="button"
            class="btn btn-secondary"
            @click="getTagsBySearchQuery()"
          >
            Search<b-icon-search class="ms-2"></b-icon-search>
          </button>
        </div>
        <div v-if="tagsAreLoading" class="text-start mt-1">
          <span
            class="spinner-border spinner-border-sm me-1"
            aria-hidden="true"
          ></span>
          <span role="status">Loading...</span>
        </div>
        <p class="mt-1 text-start" v-else>
          <span class="badge bg-warning">{{ getQueryCount }}</span>
          Results
        </p>
        <div class="text-danger text-start mt-1" v-if="!doesTagExist">
          There are no tags in our database that match your search.
        </div>
      </div>
      <!-- End Search -->
      <div class="mt-3">
        <div class="text-start">
          <template v-if="tags.length === 0 && !noTagsExist">
            <h4>Selected tags</h4>
            <p class="text-info fs-4">Please select tags.</p>
          </template>
          <template v-if="noTagsExist">
            <p class="text-danger fs-4">
              No tags have been created. Please close this modal and create one.
            </p>
          </template>
        </div>
        <template v-if="count"
          ><div class="d-flex flex-row flex-wrap justify-content-left mt-1">
            <ul
              v-for="tag in selectedTags"
              :key="tag.index"
              class="list-unstyled"
            >
              <li
                class="bg-soft-secondary rounded me-1 px-2 py-1"
                @click="toggleTagsSelection(tag)"
              >
                <b-icon-x-circle class="text-danger"></b-icon-x-circle>
                <span class="text-dark ms-1">{{ tag }}</span>
              </li>
            </ul>
          </div></template
        >
      </div>
      <template v-if="count">
        <div class="card mt-3">
          <ul
            class="list-group list-group-flush list-group-numbered text-start"
          >
            <li
              @click="toggleTagsSelection(tag)"
              :class="{ selected: isTagSelected(tag) }"
              v-for="tag in tags"
              :key="tag.index"
              class="list-group-item list-group-item-action"
            >
              {{ tag }}
            </li>
          </ul>
        </div>
      </template>
      <template v-if="!noTagsExist"
        ><div class="d-flex justify-content-end mt-3">
          <button
            type="button"
            class="btn btn-primary"
            @click="confirmSelection()"
          >
            Confirm Selection
          </button>
        </div></template
      >
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "TagListModal",
  components: {
    FixedModalSmall,
  },
  created() {
    this.getAllTags();
  },
  data() {
    return {
      searchQuery: "",
      searchValueExists: false,
      doesTagExist: true,
      count: 0,
      tags: [],
      tagsAreLoading: false,
      selectedTags: [], // New array to store selected categories
      noTagsExist: false,
    };
  },

  watch: {
    searchQuery() {
      if (this.searchQuery === "") {
        this.searchCount = 0;
        this.getAllTags();
      }
      this.searchValueExists = false;
    },
  },
  computed: {
    getQueryCount() {
      return this.count;
    },
    getSearchQuery() {
      return this.searchQuery;
    },
  },
  methods: {
    async getAllTags() {
      try {
        // Get the API client
        const api = await this.getBoldClient();

        this.doesTagExist = true;
        this.tagsAreLoading = true;

        const pageSize = 500;

        const tagsResponse = await api.listVendors({
          limit: pageSize,
          sort: "-name",
          distinct: "metadata.tags",
          expand: "metadata",
        });

        // Extract product and vendor category data
        const tags = tagsResponse?.data?.result?.records || [];

        // Update the categories and counts
        this.tags = tags;
        const count = tagsResponse.data.result.records.length;
        this.noTagsExist = count === 0;
        this.count = count;

        // Reset loading flags
        this.tagsAreLoading = false;

        // Log tags if needed
        // console.log(tags);
      } catch (error) {
        console.error("Error fetching tags:", error);
        this.tagsAreLoading = false;
      }
    },
    async getTagsBySearchQuery() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      try {
        const api = await this.getBoldClient();
        this.doesTagExist = true;
        this.tagsAreLoading = true;

        const pageSize = 500;
        const tagsResponse = await api.listVendors(
          {
            limit: pageSize,
            sort: "-name",
            distinct: "metadata.tags",
            expand: "metadata",
          },
          {
            filter: {
              "metadata.tags": { $in: [this.getSearchQuery] },
            },
          }
        );

        const tags = tagsResponse?.data?.result?.records || [];

        // Update the categories and counts
        this.tags = tags;
        const count = tagsResponse.data.result.records.length;
        this.count = count;

        // Reset loading flags
        this.tagsAreLoading = false;

        // Log tags if needed
        // console.log(tags);
      } catch (error) {
        console.error("Error fetching tags:", error);
        this.count = 0;
        this.tagsAreLoading = false;
      }
    },
    toggleTagsSelection(tag) {
      const index = this.selectedTags.indexOf(tag);
      if (index === -1) {
        this.selectedTags.push(tag);
      } else {
        this.selectedTags.splice(index, 1);
      }
    },

    isTagSelected(tag) {
      // Check if a category is selected
      return this.tags.includes(tag);
    },

    confirmSelection() {
      // Emit the selected categories to the parent component
      // console.log("emit confirm selected");
      this.$emit("setNewTag", this.selectedTags);
    },
  },
};
</script>

<style scoped>
ul {
  /* add the following two lines */
  max-height: 20rem;
  overflow-y: auto;
  cursor: pointer;
}

li {
  cursor: pointer;
}
</style>
