<template>
  <PageHeader :showActions="true" title="Groups">
    <template #page-header-actions>
      <template>
        <ButtonDropdown
          :id="'button-dropdown-recipients'"
          :items="dropdownActionItems()"
          buttonText="Create new"
          :disabled="false"
          :active="false"
          :hasLeadingIcon="false"
          :hasTrailingIcon="true"
          size="large"
          variant="primary"
        />
      </template>

      <TheButton
        type="button"
        :hasLeadingIcon="true"
        :hasTrailingIcon="true"
        variant="primary"
        size="large"
        @click="toggleNewGroupModal()"
      >
        <template #text>Create new group </template>
        <template #leading-icon><b-icon-plus-lg></b-icon-plus-lg></template>
      </TheButton>
    </template>
  </PageHeader>
  <NotificationMessage :scope="$options.name"></NotificationMessage>

  <ThePage customWidthClass="tw-min-w-[600px] tw-max-w-7xl">
    <!-- <RecipientsGroupsTable
      :isNewGroupModalVisible="isNewGroupModalVisible"
      :isNewSegmentModalVisible="isNewSegmentModalVisible"
      @update:isNewGroupModalVisible="closeModal"
      @update:isNewSegmentModalVisible="closeModal"
    ></RecipientsGroupsTable> -->
    <RecipientsGroupsAndSegmentsList
      :isNewGroupModalVisible="isNewGroupModalVisible"
      :isNewSegmentModalVisible="isNewSegmentModalVisible"
      @update:isNewGroupModalVisible="closeModal"
      @update:isNewSegmentModalVisible="closeModal"
    ></RecipientsGroupsAndSegmentsList>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
// import RecipientsGroupsTable from "../../components-v2/groups/recipients/RecipientsGroupsTable";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import ButtonDropdown from "../../components-v2/ui/elements/dropdown/ButtonDropdown.vue";
import RecipientsGroupsAndSegmentsList from "../../components-v2/groups/recipients/RecipientsGroupsAndSegmentsList";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";

export default {
  name: "ViewRecipientsGroups",
  emits: [""],
  components: {
    TheButton,
    RecipientsGroupsAndSegmentsList,
    ButtonDropdown,
    ThePage,
    PageHeader,
    // RecipientsGroupsTable,
    NotificationMessage,
  },
  data() {
    return {
      isNewGroupModalVisible: false,
      isNewSegmentModalVisible: false,
    };
  },
  methods: {
    /** other */
    async checkIfUserHasGroups(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.currentPage = page === undefined ? this.currentPage : page;
      this.checkingIfUserHasGroups = true;
      let requestBody = {};
      let filter = {
        model: "Recipient",
        "metadata.admins": { $eq: this.userId },
      };

      // let selection = "metadata";

      const queryParameters = {
        limit: 1,
      };

      requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);

        const count = response.data.result.count;
        this.count = response.data.result.count;
        // this.userHasGroups = count;
        // this.userHasGroups
        this.groups = response.data.result.records;
        return count ? true : false;
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.checkingIfUserHasGroups = false;
      }
    },
    dropdownActionItems() {
      return [
        {
          label: "Create group",
          sublabel: "Static group of recipients",
          isDeleteAction: false,
          action: () => this.toggleNewGroupModal(),
        },
        {
          label: "Create segment",
          sublabel: "Dynamic group based on defined properties",
          action: () => this.toggleNewSegmentModal(),
        },
      ];
    },

    toggleNewGroupModal() {
      // alert();
      this.isNewGroupModalVisible = !this.isNewGroupModalVisible;
      // this.isNewSegmentModalVisible = !this.isNewSegmentModalVisible;
    },
    toggleNewSegmentModal() {
      // alert();
      // this.isNewGroupModalVisible = !this.isNewGroupModalVisible;
      this.isNewSegmentModalVisible = !this.isNewSegmentModalVisible;
    },
    closeModal() {
      this.isNewGroupModalVisible = false;
      this.isNewSegmentModalVisible = false;
    },
  },
};
</script>

<style scoped></style>
