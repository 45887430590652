export default {
  getUser(state){
    return state.user;
  },
  userId(state) {
    return state.userId;
  },
  getToken(state){
    return state.token;
  }, 

  // user account details
  

};
