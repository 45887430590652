<template>
  <FixedModalSmall>
    <!-- <template> -->
    <div class="row">
      <div class="d-flex justify-content-end">
        <div class="d-flex gap-2">
          <button
            type="button"
            class="btn btn-xs btn-soft-secondary"
            @click="cancel()"
          >
            <b-icon-x-lg class=""></b-icon-x-lg>
          </button>
        </div>
      </div>
    </div>
    <!-- Body -->

    <div class="row">
      <!-- Delete sections -->
      <div class="col-12 text-start">
        <h3 class="page-header-title mb-1">Upload CSV to Vendors</h3>
        <p>
          <a
            class="fw-bold"
            href="/files/vendors-csv-upload-template.csv"
            download
            >Download a sample Vendor CSV template</a
          >
          when uploading your vendors list.
        </p>
      </div>
      <div
        class="card p-10 border bg-soft-secondary d-flex align-items-center justify-content-center mb-4"
      >
        <template v-if="dataTypes.vendors.length === 0">
          <img
            class="avatar avatar-xl avatar-4x3 mb-3"
            src="../../../assets/svg/illustrations/oc-browse.svg"
            alt="Image Description"
          />
          <label class="btn btn-soft-primary fs-3" for="vendorCSVFile"
            >Browse files
            <input
              class="form-control"
              id="vendorCSVFile"
              ref="vendorCSVFile"
              type="file"
              accept=".csv"
              hidden
              @change="handleFileUpload($event)"
            />
          </label>
        </template>
        <template v-else>
          <img
            class="avatar avatar-xl avatar-4x3 mb-3"
            src="../../../assets/svg/illustrations/oc-hi-five.svg"
            alt="Image Description"
          />
          <p>
            Uploading
            <span class="fw-bold">{{ dataTypes.vendors.length }}</span> new
            vendors
          </p>
          <div class="d-flex gap-2 justify-content-end">
            <button type="button" class="btn btn-white" @click="cancel()">
              Cancel
            </button>
            <button
              :disabled="dataTypes.vendors.length === 0"
              @click="continueBulkImportProcess()"
              type="button"
              class="btn btn-primary"
            >
              Continue<b-icon-chevron-right class="ms-2"></b-icon-chevron-right>
            </button>
          </div>
        </template>
      </div>

      <template v-if="!csvUploadWasSuccessful">
        <div class="card border-danger text-start">
          <div class="card-header">
            <div class="d-flex flex-column justify-content-start">
              <h3 class="mb-0">
                Woops...something went wrong with your file upload
              </h3>
              <p class="mb-0">
                Please review the errors below and try uploading the file again.
              </p>
              <p class="mb-0">
                Make sure you're using our
                <span
                  ><a
                    class="fw-bold"
                    href="/files/vendors-csv-upload-template.csv"
                    download
                    >Vendor CSV template</a
                  >
                  when uploading your address list.</span
                >
              </p>
            </div>
          </div>
          <div
            class="card-body d-flex align-items-top text-start gap-2"
            v-if="csvColumnErrors.isActive"
          >
            <b-icon-exclamation-circle
              class="text-danger"
            ></b-icon-exclamation-circle>
            <div>
              <p class="mb-0">
                Your CSV file column headers do not match the column headers of
                our
                <span
                  ><a href="/files/vendors-csv-upload-template.csv" download
                    >Vendor CSV template</a
                  >.</span
                >
              </p>
            </div>
          </div>
          <div
            class="card-body d-flex align-items-top text-start gap-2"
            v-if="csvRowErrors.isActive"
          >
            <b-icon-exclamation-circle
              class="text-danger"
            ></b-icon-exclamation-circle>
            <div>
              <p class="mb-0">
                The following rows in your CSV file have missing data. Please
                fix the issue and try again.
              </p>
              <ul
                class="list-group w-100"
                style="max-height: 300px; overflow-y: auto"
              >
                <li
                  class="list-group-item"
                  v-for="item in csvRowErrors.data"
                  :key="item.index"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- </template> -->
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../ui/modals/templates/FixedModalSmall.vue";
import Papa from "papaparse";

export default {
  name: "BulkImportModal",
  components: {
    FixedModalSmall,
  },
  data() {
    return {
      dataTypes: {
        orders: [],
        products: [],
        kits: [],
        proposals: [],
        vendors: [],
        shippingMethods: [],
        packagingOptions: [],
        media: [],
      },
      content: 0,
      parsed: false,
      file: [],
      progress: 0,
      isSaving: false,
      uploadFailed: false,
      uploadSuccess: false,
      CSVdownloaded: false,
      csvColumnErrors: {
        isActive: false,
        data: null,
      },
      csvRowErrors: {
        isActive: false,
        data: null,
      },
      csvUploadWasSuccessful: true,
      fileIsBeingDeleted: false,
      itemsPerPage: 1,
      currentPage: 0,
    };
  },
  computed: {
    dataInfoArray() {
      const dataTypes = [
        { type: "orders", data: this.dataTypes.orders },
        { type: "products", data: this.dataTypes.products },
        { type: "kits", data: this.dataTypes.kits },
        { type: "proposals", data: this.dataTypes.proposals },
        { type: "vendors", data: this.dataTypes.vendors },
        { type: "shippingMethods", data: this.dataTypes.shippingMethods },
        { type: "packagingOptions", data: this.dataTypes.packagingOptions },
        { type: "media", data: this.dataTypes.media },

        // Include other data types with their respective props
      ];

      return dataTypes.filter((dataInfo) => dataInfo.data);
    },
    totalPages() {
      return Math.ceil(this.dataTypes.vendors.length / this.itemsPerPage);
    },
    paginatedVendors() {
      const startIndex = this.currentPage * this.itemsPerPage;
      return this.dataTypes.vendors.slice(
        startIndex,
        startIndex + this.itemsPerPage
      );
    },
  },
  methods: {
    continueBulkImportProcess() {
      const vendors = this.dataTypes.vendors;
      this.$store.commit("brands/SET_IMPORTED_VENDORS", vendors);
      this.$router.push({ path: "/import/vendors" });
    },
    cancel() {
      this.$emit("cancel");
    },
    handleFileUpload(event) {
      this.csvUploadWasSuccessful = true;
      this.dataTypes.vendors = [];
      this.file = event.target.files[0];
      // console.log(this.file);
      this.parseFile();
    },
    parseFile() {
      // console.log("papa.parse");
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const expectedColumns = [
            "name",
            "description",
            "shortDescription",
            "website_url",
            "twitter_url",
            "linkedin_url",
            "tiktok_url",
            "instagram_url",
            "categories",
            "tags",
            "onlineStorePlatforms",
            "tags",
            "gift_card_page",
            "gift_card_minimum",
            "neighborhoods",
            "region",
            // "typeform_url",
            "address_1",
            "address_2",
            "city",
            "state",
            "postalCode",
            // "country",
          ];
          // console.log("papa.parse");

          const actualColumns = Object.keys(results.data[0]);

          const missingColumns = expectedColumns.filter(
            (column) => !actualColumns.includes(column)
          );
          const extraColumns = actualColumns.filter(
            (column) => !expectedColumns.includes(column)
          );

          const columnErrors = this.validateColumns(
            missingColumns,
            actualColumns,
            extraColumns
          );

          const rowErrors = this.validateRows(results.data);
          // console.log("rowErrors", rowErrors);

          if (columnErrors.length > 0 || rowErrors.length > 0) {
            // console.log("papa.parse failed");
            this.progress = 0;
            this.uploadFailed = true;
            this.handleValidationErrors(columnErrors, rowErrors);
          } else {
            // console.log("papa.parse");
            this.handleSuccessfulUpload(results);
          }
        },
      });
    },
    validateColumns(missingColumns, actualColumns, extraColumns) {
      const columnErrors = [];
      if (extraColumns.length > 0 || missingColumns.length > 0) {
        columnErrors.push(`Error: Column name validation failed`);
        if (missingColumns.length > 0) {
          columnErrors.push(`Missing columns: ${missingColumns.join(", ")}`);
        }
        if (extraColumns.length > 0) {
          columnErrors.push(`Extra columns: ${extraColumns.join(", ")}`);
        }
      }
      return columnErrors;
    },
    validateRows(data) {
      const rowErrors = [];
      const requiredFields = [
        "name",
        "description",
        "shortDescription",
        "website_url",
        "twitter_url",
        "linkedin_url",
        "tiktok_url",
        "instagram_url",
        "categories",
        "tags",
        "onlineStorePlatforms",
        "tags",
        "gift_card_page",
        "gift_card_minimum",
        "neighborhoods",
        "region",
        // "typeform_url",
        "address_1",
        "address_2",
        "city",
        "state",
        "postalCode",
        // "country",
      ];

      data.forEach((row, i) => {
        const emptyFields = [];

        requiredFields.forEach((field) => {
          if (!row[field] && row[field] === "address_2") {
            emptyFields.push(field.charAt(0).toUpperCase() + field.slice(1));
          }
        });

        if (emptyFields.length > 0) {
          rowErrors.push(
            `Row ${i + 1} has missing data in: ${emptyFields.join(", ")}`
          );
        }
      });

      return rowErrors;
    },
    handleValidationErrors(columnErrors, rowErrors) {
      this.csvUploadWasSuccessful = true;
      this.csvColumnErrors.isActive = false;
      this.csvRowErrors.isActive = false;

      if (columnErrors.length > 0) {
        // console.log(columnErrors);
        this.csvColumnErrors.isActive = true;
        this.csvColumnErrors.data = columnErrors;
        this.csvUploadWasSuccessful = false;
      }
      if (rowErrors.length > 0) {
        this.csvRowErrors.isActive = true;
        this.csvRowErrors.data = rowErrors;
        this.csvUploadWasSuccessful = false;
      }
      if (rowErrors.length === 0 && columnErrors.length === 0) {
        this.csvUploadWasSuccessful = true;
      }
    },
    handleSuccessfulUpload(results) {
      // console.log("results: ", results);
      this.csvUploadWasSuccessful = true;
      this.uploadSuccess = true;
      this.dataTypes.vendors = this.mapColumnsToVendor(results.data);
      this.parsed = true;
      this.progress = 1;
    },
    generateRandomId() {
      // Customize this function to generate a random ID as per your requirements
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const idLength = 10;
      let randomId = "";
      for (let i = 0; i < idLength; i++) {
        randomId += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return randomId;
    },
    // Function to map CSV columns to vendor object properties
    mapColumnsToVendor(csvData) {
      return csvData.map((row) => {
        const giftCardMinimum = row["gift_card_minimum"];
        const parsedGiftCardMinimum = +giftCardMinimum || 0;

        return {
          _id: this.generateRandomId(),
          name: row["name"] || "",
          description: row["description"] || "",
          categories: row["categories"]
            ? row["categories"].split(",").map((item) => item.trim())
            : [],
          address: {
            lines: [row["address_1"] || "", row["address_2"] || ""],
            city: row["city"] || "",
            state: row["state"] || "",
            postalCode: row["postalCode"] || "",
            country: "US",
          },
          metadata: {
            shortDescription: row["shortDescription"] || "",
            website_url: row["website_url"] || "",
            twitter_url: row["twitter_url"] || "",
            linkedin_url: row["linkedin_url"] || "",
            tiktok_url: row["tiktok_url"] || "",
            instagram_url: row["instagram_url"] || "",
            gift_card_page: row["gift_card_page"] || "",
            gift_card_minimum: parsedGiftCardMinimum,
            region: [row["region"]] || [],
            // typeform_url: row["typeform_url"] || "",
            onlineStorePlatforms: row["onlineStorePlatforms"]
              ? row["onlineStorePlatforms"]
                  .split(",")
                  .map((item) => item.trim())
              : [],
            tags: row["tags"]
              ? row["tags"].split(",").map((item) => item.trim())
              : [],
            neighborhoods: row["neighborhoods"]
              ? row["neighborhoods"].split(",").map((item) => item.trim())
              : [],
          },
        };
      });
    },
  },
};
</script>

<style scoped></style>
