<template>
  <TheCard>
    <TheBox :withPadding="true">
      <div class="tw-flex tw-items-start tw-h-full">
        <!-- <div class="tw-hidden sm:tw-block">
          <img
            src="../../assets/svg/illustrations-test/13_gift_box.png"
            alt="product.imageAlt"
            class="tw-h-auto tw-w-16 tw-rounded tw-object-cover tw-object-center tw-bg-gray-200"
          />
        </div> -->
        <div class="tw-flex-1 tw-self-start">
          <div
            class="tw-flex tw-items-start tw-gap-x-2 tw-gap-y-2 tw-flex-wrap"
          >
            <template v-if="vendor?.name">
              <div class="tw-flex-1 tw-min-w-48 tw-w-full">
                <BlockStack :gap="100">
                  <p class="tw-m-0 tw-text-lg">
                    <span class="tw-font-bold">Gift card amount</span>:
                    {{ giftCardAmount }}
                  </p>

                  <p class="tw-m-0 tw-text-lg">
                    <span class="tw-font-bold">Sent</span>:
                    {{ getTimeStamp(campaign.id) }}
                  </p>

                  <template v-if="myGiftsView">
                    <div class="tw-flex tw-flex-col">
                      <p class="tw-m-0 tw-text-lg tw-font-bold">From:</p>
                      <div class="tw-flex tw-flex-col">
                        <p class="tw-m-0 tw-text-lg">
                          {{ giftingLeadName }}
                        </p>
                        <p class="tw-m-0 tw-text-lg">
                          {{ giftingLeadEmail }}
                        </p>
                      </div>
                    </div>
                  </template>

                  <p class="tw-m-0 tw-text-lg">
                    <span class="tw-font-bold">Your selection</span>:
                  </p>
                  <div class="tw-p-2 tw-bg-stone-100 tw-rounded tw-w-full">
                    <p class="tw-m-0 tw-text-lg">{{ vendorName }}</p>
                    <p class="tw-m-0 tw-text-lg">
                      <template
                        v-if="
                          vendorStreet &&
                          vendorCity &&
                          vendorState &&
                          vendorPostal
                        "
                      >
                        {{
                          vendorStreet +
                          ", " +
                          vendorCity +
                          ", " +
                          vendorState +
                          " " +
                          vendorPostal
                        }}
                      </template>
                    </p>
                  </div>
                </BlockStack>
              </div>
            </template>
            <template v-else>
              <div class="tw-flex-1 tw-min-w-48">
                <BlockStack :gap="100">
                  <p class="tw-m-0 tw-text-lg">
                    <span class="tw-font-bold">Gift card amount</span>:
                    {{ giftCardAmount }}
                  </p>

                  <p class="tw-m-0 tw-text-lg">
                    <span class="tw-font-bold">Sent</span>:
                    {{ getTimeStamp(campaign.id) }}
                  </p>

                  <template v-if="myGiftsView">
                    <div class="tw-flex tw-flex-col">
                      <p class="tw-m-0 tw-text-lg tw-font-bold">From:</p>
                      <div class="tw-flex tw-flex-col">
                        <p class="tw-m-0 tw-text-lg">
                          {{ giftingLeadName }}
                        </p>
                        <p class="tw-m-0 tw-text-lg">
                          {{ giftingLeadEmail }}
                        </p>
                      </div>
                    </div>
                  </template>
                </BlockStack>
              </div>
            </template>

            <div class="tw-flex tw-justify-end">
              <template v-if="Array.isArray(this?.campaign?.seen)">
                <template v-if="!!hasGiftBeenRedeemed">
                  <TheButton
                    disabled
                    :hasLeadingIcon="true"
                    variant="success"
                    size="regular"
                  >
                    <template #text
                      ><p class="tw-m-0 tw-font-bold tw-text-green-600">
                        <b-icon-check-circle-fill
                          class="tw-text-green-600"
                        ></b-icon-check-circle-fill>
                        Redeemed
                      </p></template
                    >
                  </TheButton>
                </template>
                <template v-if="!hasGiftBeenRedeemed && !recipientDetailsView">
                  <TheButton
                    :isRouterLink="true"
                    :to="{
                      name: 'CampaignGift',
                      params: { campaignId: this.campaign._id },
                    }"
                    variant="secondary"
                    size="regular"
                  >
                    <template #text>Redeem</template>
                  </TheButton>
                </template>
                <template v-if="!hasGiftBeenRedeemed && recipientDetailsView">
                  <p class="tw-m-0 tw-text-lg tw-font-bold tw-text-red-600">
                    <b-icon-x-circle-fill
                      class="tw-me-1"
                    ></b-icon-x-circle-fill>
                    Not Redeemed
                  </p>
                </template>
              </template>
              <template v-else>
                <template v-if="!!hasGiftBeenRedeemed">
                  <p class="tw-m-0 tw-font-bold tw-text-green-600">
                    <b-icon-check-circle-fill
                      class="tw-me-1"
                    ></b-icon-check-circle-fill>
                    Redeemed
                  </p>
                </template>
                <template v-if="!hasGiftBeenRedeemed && !recipientDetailsView">
                  <TheButton
                    :isRouterLink="true"
                    :to="{
                      name: 'CampaignGift',
                      params: { campaignId: this.campaign._id },
                    }"
                    variant="secondary"
                    size="regular"
                  >
                    <template #text>Redeem</template>
                  </TheButton>
                </template>
                <template v-if="!hasGiftBeenRedeemed && recipientDetailsView">
                  <p class="tw-m-0 tw-text-lg tw-font-bold tw-text-red-600">
                    <b-icon-x-circle-fill
                      class="tw-me-1"
                    ></b-icon-x-circle-fill>
                    Not Redeemed
                  </p>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </TheBox>
  </TheCard>
</template>

<script>
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import TheCard from "../../components-v2/ui/layout/TheCard";
import TheBox from "../../components-v2/ui/layout/TheBox";
import { findRedeemerMatch } from "@/services/campaignService";

export default {
  name: "RedemptionHistoryListItem",
  components: { TheButton, TheCard, TheBox, BlockStack },
  props: {
    campaign: {
      type: Object,
    },
    recipient: {
      type: Object,
    },
    recipientDetailsView: {
      type: Boolean,
    },
    myGiftsView: {
      type: Boolean,
    },
  },
  async created() {
    // redeemed with seen array
    if (Array.isArray(this?.campaign?.seen) && this.redeemerMatch) {
      const vendorId = findRedeemerMatch(
        this?.recipient?.contact?.email,
        this.userId,
        this.campaign
      ).recipient_selection;

      await this.getSingleVendorByID(vendorId);
    }
    // redeemed without seen array
    if (
      (this?.campaign?.metadata?.recipient_selection ||
        this?.campaign?.metadata?.campaign_status === "redeemed") &&
      !this.redeemerMatch
    ) {
      await this.getSingleVendorByID(
        this?.campaign?.metadata?.recipient_selection
      );
    }

    // redeemed without recipient_selection
    if (
      (!this?.campaign?.metadata?.recipient_selection ||
        this?.campaign?.metadata?.campaign_status === "redeemed") &&
      !this.redeemerMatch
    ) {
      this.vendor = null;
    }
  },
  data() {
    return {
      vendor: {},
    };
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?.id;
    },
    hasGiftBeenRedeemed() {
      return (
        (Array.isArray(this?.campaign?.seen) && this.redeemerMatch) ||
        ((this?.campaign?.metadata?.recipient_selection ||
          this?.campaign?.metadata?.campaign_status === "redeemed") &&
          !this.redeemerMatch)
      );
    },
    redeemerMatch() {
      return findRedeemerMatch(
        this?.recipient?.contact?.email,
        this.userId,
        this.campaign
      );
    },
    giftCardAmount() {
      return `$${this?.campaign?.metadata?.gift_card_amount}`;
    },
    giftingLeadName() {
      return this?.campaign?.from?.name;
    },
    giftingLeadEmail() {
      return this?.campaign?.from?.email;
    },
    giftCardSelection() {
      return this?.campaign?.metadata?.recipient_selection;
    },
    getTimeStamp() {
      return (id) => {
        if (id) {
          const objectIdToTimestamp = require("objectid-to-timestamp");
          const originalDate = new Date(objectIdToTimestamp(id));

          const year = originalDate.getFullYear();
          const month = (originalDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          const day = originalDate.getDate().toString().padStart(2, "0");

          return `${month}/${day}/${year}`;
        }
      };
    },

    /** vendor */
    vendorName() {
      return this?.vendor?.name;
    },
    vendorStreet() {
      return this?.vendor?.address?.lines[0];
    },
    vendorCity() {
      return this?.vendor?.address?.city;
    },
    vendorState() {
      return this?.vendor?.address?.state;
    },
    vendorCountry() {
      return this?.vendor?.address?.country;
    },
    vendorPostal() {
      return this?.vendor?.address?.postalCode;
    },
  },
  methods: {
    /** vendor */
    async getSingleVendorByID(vendorId) {
      const api = await this.getBoldClient();
      this.vendorDataIsLoading = true;
      try {
        const {
          data: {
            result: { records: singleVendorById },
          },
        } = await api.listVendors(
          {
            limit: 1,
            expand: "media contact address",
          },
          { filter: { _id: vendorId } }
        );

        this.vendor = singleVendorById[0];
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
