<template>
  <div class="tw-max-h-auto tw-overflow-y-auto">
    <BlockStack :gap="400">
      <NotificationMessage :scope="$options.name"></NotificationMessage>
      <template v-if="sessionId">
        <!-- name -->
        <BlockStack :gap="100">
          <label
            for="budgetName"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >
            Name<span
              v-if="!budgetNameValid"
              class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
              >required field</span
            >
          </label>
          <!-- <template v-if="!budget.name"> -->
          <div class="tw-flex tw-gap-2 tw-items-center">
            <!-- <EmojiPicker @showSelectedEmoji="showSelectedEmoji" /> -->
            <input
              v-model="budget.name"
              type="text"
              id="budgetName"
              placeholder="e.g. Customers"
              :class="{
                'tw-ring-red-300': !budgetName,
                'tw-ring-green-500': budgetName,
              }"
              class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
            />
          </div>
          <!-- </template> -->
        </BlockStack>
        <!-- description -->
        <BlockStack :gap="100">
          <label
            for="budgetDescription"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >
            Purpose (optional)
          </label>
          <textarea
            rows="3"
            v-model="budget.description"
            name="budgetDescription"
            id="budgetDescription"
            placeholder="Details about how and when to use this budget."
            class="tw-block tw-px-2.5 tw-py-2 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
          ></textarea>
          <!-- </template> -->
        </BlockStack>
        <!-- limit -->
        <BlockStack :gap="100">
          <label
            for="budgetLimit"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >
            How much do you want to budget?<span
              v-if="!budgetLimit"
              class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
              >required field</span
            >
          </label>
          Amount must be a whole number of $50 or more
          <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-auto">
            <div
              class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
            >
              <b-icon-currency-dollar
                class="tw-h-5 tw-w-5 tw-text-gray-400"
                aria-hidden="true"
              ></b-icon-currency-dollar>
            </div>
            <input
              v-model="budget.limit"
              @input="validateBudgetLimitValue"
              type="text"
              name="budgetLimit"
              id="budgetLimit"
              :class="{
                'tw-ring-red-300': !budgetLimit,
                'tw-ring-green-500': budgetLimit,
              }"
              class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
              placeholder="Set budget limit"
            />
          </div>
          <!-- </template> -->
        </BlockStack>
        <!-- period -->
        <BlockStack :gap="300">
          <BlockStack :gap="100">
            <label
              for="budgetPeriod"
              class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
              >Budget type<span
                v-if="!budget.period"
                class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                >required field</span
              ></label
            >
            Choose between a monthly, quarterly, or yearly recurring budget
            <select
              v-model="budget.period"
              id="budgetPeriod"
              name="budgetPeriod"
              class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
              :class="{
                'tw-ring-red-300': !budget.period,
                'tw-ring-green-500': budget.period,
              }"
            >
              <option value="" disabled>Select schedule</option>
              <option
                v-for="option in periods"
                :value="option.value"
                :key="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </BlockStack>
        </BlockStack>
      </template>
      <template v-else>
        <BlockStack :gap="500">
          <p class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0">
            To effectively monitor and limit tracking, each budget must be
            associated with a credit card.
            <a href="https://stripe.com/" target="_blank">Stripe, Inc.</a>
            securely manages all transactions.
          </p>
          <p class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0">
            By tapping “Continue” you will be redirected to Stripe’s secure
            payment portal to enter your credit card information.
          </p>
          <p class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-mb-0">
            Do you wish to continue?
          </p>
        </BlockStack>
      </template>
      <template v-if="sessionId">
        <div class="tw-flex tw-gap-2 tw-mb-1">
          <TheButton
            :disabled="!allItemsComplete || creatingBudget"
            @click="createBudgetFromPaymentMethod()"
            size="large"
            :hasLeadingIcon="true"
            variant="success"
          >
            <template #text>
              <template v-if="creatingBudget">Saving...</template>
              <template v-else>Save budget</template>
            </template>
            <template #leading-icon>
              <template v-if="creatingBudget">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="tw-sr-only">Loading...</span>
                </div>
              </template>
            </template>
          </TheButton>
          <TheButton
            @click="cancelBudget()"
            size="large"
            :hasLeadingIcon="true"
            variant="tertiary"
          >
            <template #text> Cancel </template>
            <template #leading-icon> </template>
          </TheButton>
        </div>
      </template>
      <template v-else>
        <div class="tw-flex tw-gap-2 tw-mb-1">
          <TheButton
            type="button"
            variant="primary"
            size="large"
            @click="setupPaymentMethod()"
          >
            <template #text>Continue</template>
          </TheButton>
          <TheButton
            type="button"
            variant="tertiary"
            size="large"
            @click="closeModal()"
          >
            <template #text>Cancel</template>
          </TheButton>
        </div>
      </template>
    </BlockStack>
  </div>
</template>

<script>
import BlockStack from "../layout/BlockStack.vue";
import TheButton from "../actions/TheButton.vue";
import NotificationMessage from "../feedback/NotificationMessage.vue";
export default {
  name: "NewBudgetForm",
  emits: [
    "createBudgetFromPaymentMethod",
    "closeModal",
    "cancelBudget",
    "setupPaymentMethod",
    "createBudgetFromPaymentMethod",
  ],
  props: {
    sessionId: {
      type: String,
    },
  },
  components: {
    BlockStack,
    TheButton,
    NotificationMessage,
  },
  data() {
    return {
      periods: [
        {
          text: "Monthly - resets the 1st of every month at 12 a.m.",
          value: "0 0 1 * *",
        },
        {
          text: "Quarterly - resets the 1st of every quarter at 12 a.m.",
          value: "0 0 1 */3 *",
        },
        {
          text: "Yearly - resets the 1st of January of the following year at 12 a.m.",
          value: "0 0 1 1 *",
        },
      ],
      budgetTypes: [
        {
          id: 1,
          name: "One-time",
          description: "Budget limit does not automatically reset",
        },
        {
          id: 2,
          name: "Recurring",
          description: "Budget limit resets automatically based on a schedule",
        },
      ],
      selectedBudgetType: null,
      budget: {
        name: "",
        limit: 0,
        period: "",
        description: "",
      },
      creatingBudget: false,
    };
  },
  created() {},
  computed: {
    allItemsComplete() {
      return this.budgetNameValid && this.budgetLimit && this.budgetPeriod;
    },
    user() {
      return this?.$store?.state?.auth?.user;
    },
    budgetNameValid() {
      return this?.budget?.name?.trim().length > 0 ? this?.budget?.name : false;
    },
    budgetName() {
      return this?.budget?.name.trim();
    },
    budgetDescription() {
      return this?.budget?.description.trim();
    },
    budgetLimit() {
      return parseInt(this?.budget?.limit) || 0;
    },
    budgetPeriod() {
      return this?.budget?.period !== "" ? this.budget.period : null;
    },
    budgetLimitInPennies() {
      const limit = parseInt(this?.budget?.limit, 10);
      return isNaN(limit) ? 0 : limit * 100;
    },
    getSessionId() {
      return this.sessionId;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** handle creating budget */
    async createBudgetFromPaymentMethod() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      this.creatingBudget = true;

      const sessionId = this.getSessionId;
      const limit = this.budgetLimitInPennies;
      const budget = {
        sessionId,
        limit: limit,
        name: this.budgetName,
      };
      console.log("budget: ", budget);

      const api = await this.getBoldClient();
      try {
        const budgetCreationRes = await api.createBudgetFromPaymentMethod({
          sessionId,
          name: this.budgetName,
          limit: limit,
        });
        console.log("budgetCreationRes.data.budget: ", budgetCreationRes);
        const budgetId = budgetCreationRes.data.budget;
        await this.updateBudgetWithPeriod(budgetId);
        this.handleSuccess(budgetId);
      } catch (error) {
        console.log("budgetCreationRes error: ", error);
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your budget. Please try again."
        );
        this.creatingBudget = false;
      }
    },
    async updateBudgetWithPeriod(budgetId) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      const api = await this.getBoldClient();
      try {
        const upsertBudgetRes = await api.upsertBudget(null, {
          filter: { _id: budgetId },
          patch: {
            "users.0.period": this.budgetPeriod,
            "metadata.description": this.budgetDescription || "",
          },
        });
        console.log("upsertBudgetRes: ", upsertBudgetRes);
      } catch (error) {
        console.log("budgetCreationRes error: ", error);
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your campaign."
        );
      }
    },

    /** input handlers */
    validateBudgetLimitValue() {
      const trimmedValue = this?.budget?.limit?.trim();
      const value = parseFloat(trimmedValue);

      // Clear any existing timeout to avoid multiple resets
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }

      // Check if the trimmed value is a valid number and meets the criteria
      if (!Number.isNaN(value) && value >= 50 && Number.isSafeInteger(value)) {
        this.budget.limit = Math.round(value).toString(); // Convert back to string
      } else {
        // Set a timeout to reset the value to "0" after 350 ms
        this.timeoutId = setTimeout(() => {
          this.budget.limit = "0"; // Reset to "0" string
        }, 350);
      }
    },

    /** emits */
    closeModal() {
      this.$emit("closeModal");
    },
    cancelBudget() {
      this.$emit("cancelBudget");
    },

    /** emits */
    setupPaymentMethod() {
      this.$emit("setupPaymentMethod");
    },
    /** handle success and errors */
    handleSuccess(budgetId) {
      this.$emit("handleSuccess", budgetId);
    },
    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error?.response) {
        // Process API error message if customMessage is not provided
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
