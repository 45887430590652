<template>
  <TheCard :fullWidth="true">
    <div class="tw-p-4 ">
      <div class="tw-animate-pulse tw-flex tw-space-x-4">
        <slot></slot>
      </div>
    </div>
  </TheCard>
</template>

<script>
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";

export default {
  name: "PulseAnimation",
  components: { TheCard },
};
</script>

<style scoped></style>
