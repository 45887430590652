<template>
  <template v-if="showImportRecipientsModal">
    <Teleport to="#modal">
      <WideModal
        title="Importing recipients"
        @closeModal="toggleRecipientsImportModal()"
        :open="showImportRecipientsModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <ImportData
          :userId="userId"
          :group="group"
          :existingGroupMemberIds="existingGroupMemberIds"
          @reloadData="handleReloadingData"
        ></ImportData>
      </WideModal>
    </Teleport>
  </template>
  <PageHeader
    headingBadge="segment"
    :showStrapline="true"
    :showActions="true"
    :showSubheading="true"
    :title="this?.segment?.metadata?.name"
  >
    <template #strapline-content>
      <TheLink variant="primary" size="small" :isRouterLink="true" to="/groups">
        <template #text><b-icon-arrow-left />Groups & Segments</template>
      </TheLink>
    </template>
    <template #page-header-actions>
      <ButtonDropdown
        :id="'button-dropdown-recipients'"
        :items="dropdownActionItems()"
        buttonText="Manage group"
        :disabled="false"
        :active="false"
        :hasLeadingIcon="false"
        :hasTrailingIcon="true"
        size="large"
        variant="primary"
      />
    </template>
    <template #subheading-content>
      <div>
        <div class="sm:tw-hidden">
          <label for="tabs" class="tw-sr-only">Select a tab</label>
          <select
            v-model="currentTab"
            id="tabs"
            name="tabs"
            class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
          >
            <option
              v-for="tab in tabs"
              :key="tab.id"
              :selected="tab.id"
              :value="tab.id"
            >
              {{ tab.name }}
            </option>
          </select>
        </div>
        <div class="tw-hidden sm:tw-block">
          <div
            class="tw-border-b-2 tw-border-gray-200 tw-border-solid tw-border-t-0 tw-border-x-0"
          >
            <nav class="tw-flex tw-items-center tw-space-x-8" aria-label="Tabs">
              <a
                v-for="tab in tabs"
                :key="tab.name"
                @click.prevent="handleTabChange(tab.id)"
                :class="[
                  currentTab === tab.id
                    ? 'tw-border-blue-700 tw-text-blue-800 tw-border-solid tw-border-2 tw-border-t-0 tw-border-x-0 tw-cursor-pointer'
                    : ' tw-text-gray-800 hover:tw-border-blue-700 hover:tw-border-solid hover:tw-border-x-0 hover:tw-border-t-0 hover:tw-text-blue-800 tw-cursor-pointer',
                  'tw-whitespace-nowrap tw-border-2  tw-pt-1 tw-px-1 tw-text-lg tw-font-medium tw-cursor-pointer',
                ]"
              >
                <template v-if="tab.name === 'Recipients'">
                  {{ tab.name }} ({{ segmentRecipientsCount }})</template
                >
                <template v-else> {{ tab.name }}</template>
              </a>
            </nav>
          </div>
        </div>
      </div>
    </template>
  </PageHeader>
  <NotificationMessage :scope="$options.name"></NotificationMessage>

  <ThePage customWidthClass="tw-w-full tw-max-w-8xl">
    <!-- <BlockStack :gap="500" class="tw-max-w-8xl tw-w-full"> -->
    <template v-if="loadingSegment">
      <PulseAnimation>
        <DataTableSkeleton></DataTableSkeleton>
      </PulseAnimation>
    </template>
    <template v-else>
      <template v-if="currentTab === 1">
        <template v-if="!segmentRecipientsCount">
          <PageSection class="tw-w-full tw-max-w-3xl tw-mx-auto">
            <TheCard class="tw-w-full">
              <TheBox :withPadding="true">
                <EmptyState emptyState="segmentRecipients"></EmptyState>
              </TheBox>
            </TheCard>
          </PageSection>
        </template>
        <template v-else>
          <RecipientProfilesTable
            :existingGroupMemberIds="existingGroupMemberIds"
            :recipientsGroupDetailsView="true"
            :isModalVisible="isModalVisible"
            :segmentId="segmentId"
            :segmentFilter="segment"
            @update:isModalVisible="closeModal"
            @updateSegmentRecipientCount="updateSegmentRecipientCount"
          ></RecipientProfilesTable>
        </template>
      </template>
      <template v-if="currentTab === 2">
        <RecipientsSegmentSettings
          :segment="segment"
          @reloadData="checkIfUserHasSegments()"
        ></RecipientsSegmentSettings>
      </template>
    </template>
    <!-- </BlockStack> -->
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import RecipientProfilesTable from "../../components-v2/recipients/RecipientProfilesTable";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import ButtonDropdown from "../../components-v2/ui/elements/dropdown/ButtonDropdown.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import DataTableSkeleton from "../../components-v2/ui/skeletons/DataTableSkeleton.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import WideModal from "../../components-v2/ui/modals/WideModal.vue";
import ImportData from "../../components-v2/ui/modals/ImportData.vue";
import RecipientsSegmentSettings from "../../components-v2/groups/RecipientsSegmentSettings.vue";

export default {
  name: "RecipientSegmentDetails",
  emits: [""],
  components: {
    RecipientsSegmentSettings,
    TheLink,
    TheBox,
    TheCard,
    PageSection,
    EmptyState,
    ButtonDropdown,
    ThePage,
    PageHeader,
    RecipientProfilesTable,
    NotificationMessage,
    ImportData,
    WideModal,
    DataTableSkeleton,
    PulseAnimation,
  },
  created() {
    this.checkIfUserHasSegments();
  },
  data() {
    return {
      segment: {},
      tabs: [
        { id: 1, name: "Recipients" },
        { id: 2, name: "Settings" },
        // { id: 3, name: "Settings" },
      ],
      currentTab: 1,
      segmentRecipientsCount: 1,
      isModalVisible: false,
      showImportRecipientsModal: false,
      loadingSegment: true,
      groupName: "Customers",
    };
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    segmentId() {
      return this.$route.params.segmentId;
    },
    existingGroupMemberIds() {
      return this?.group?.members?.map((member) => member.doc);
    },
  },
  methods: {
    async checkIfUserHasSegments() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      // this.currentPage = page === undefined ? this.currentPage : page;

      let requestBody = {};
      let filter = {
        model: "Recipient",
        _id: this.segmentId,
      };

      // let selection = "metadata";

      const queryParameters = {
        // skip: (this.currentPage - 1) * this.pageSize,
        limit: 1,
        // sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listSegments(queryParameters, requestBody);
        this.segment = response.data.result.records[0];
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.loadingSegment = false;
      }
    },
    async checkIfUserHasRecipients(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.currentPage = page === undefined ? this.currentPage : page;
      this.checkingIfUserHasRecipients = true;

      let requestBody = {};
      let filter = {
        // ...(!this.isBoldXchangeAdmin && {
        //   "metadata.giftingLeads": { $in: [this.userId] },
        // }),
        ...(this.viewMyRecordsOnly && {
          "metadata.giftingLeads": { $in: [this.userId] },
        }),
        // ...(!this.viewMyRecordsOnly && {
        //   "metadata.giftingLeads": { $ne: [this.userId] },
        // }),
        ...(this.groupId && {
          "groups.0._id": { $eq: this.groupId },
        }),
      };

      if (this.searchQuery.value !== "") {
        filter.$or = [
          { firstName: { $regex: this.searchQuery.value } },
          { lastName: { $regex: this.searchQuery.value } },
          { "contact.email": { $regex: this.searchQuery.value } },
          { "contact.phone": { $regex: this.searchQuery.value } },
          { "contact.company": { $regex: this.searchQuery.value } },
          { "contact.title": { $regex: this.searchQuery.value } },
          {
            "contact.metadata.lifecycleStage": {
              $regex: this.searchQuery.value,
            },
          },
        ];
      }
      // let selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "groups",
      };

      requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);

        const count = response.data.result.count;
        this.count = response.data.result.count;
        this.userHasRecipients = count;
        this.recipients = response.data.result.records;
        this.checkingIfUserHasRecipients = false;
      } catch (error) {
        this.checkingIfUserHasRecipients = false;
        console.log("recipients did not load", error);
      }
    },
    updateSegmentRecipientCount(count) {
      this.segmentRecipientsCount = count;
    },
    handleReloadingData() {
      this.showImportRecipientsModal = false;
      this.checkIfUserHasSegments();
    },
    /** other */
    toggleRecipientsImportModal() {
      this.showImportRecipientsModal = !this.showImportRecipientsModal;
    },
    dropdownActionItems() {
      return [
        {
          label: "Import CSV",
          // sublabel: "Static group of recipients",
          isDeleteAction: false,
          action: () => this.toggleRecipientsImportModal(),
        },
        {
          label: "Create new recipient",
          // sublabel: "Dynamic group based on defined properties",
          action: () => this.toggleNewGroupModal(),
        },
        {
          label: "Add from recipient profiles list",
          // sublabel: "Dynamic group based on defined properties",
          action: () => this.toggleNewGroupModal(),
        },
        {
          label: "Delete",
          // sublabel: "Group analytics will be permanently deleted",
          isDeleteAction: true,

          action: () => this.toggleNewGroupModal(),
        },
      ];
    },

    toggleNewGroupModal() {
      // alert();
      this.isModalVisible = !this.isModalVisible;
    },
    closeModal() {
      this.isModalVisible = false;
    },

    handleTabChange(tabId) {
      this.currentTab = tabId;
      // this.performTabSpecificLogic();
    },
  },
};
</script>

<style scoped></style>
