<template>
  <ThePage :fullWith="true" class="tw-bg-stone-100 tw-h-screen">
    <BlockStack
      :gap="800"
      class="tw-flex tw-pt-20 tw-text-center tw-items-center tw-justify-start tw-mx-auto"
    >
      <TheCard class="tw-min-w-96 tw-max-w-96">
        <div class="card-body pt-0">
          <!-- Form -->
          <form
            @submit.prevent="submitForm"
            class="js-validate needs-validation"
            novalidate
          >
            <div class="card-header d-flex justify-content-center mb-5">
              <img
                class="mb-5"
                style="max-width: 10rem"
                src="../../assets/svg/logos/Bold_Xchange_PrimaryLogo_Black.svg"
                alt="Image Description"
              />
            </div>
            <!-- heading -->
            <div class="mb-5">
              <h1 class="display-5">Change password</h1>
              <p>Please enter and confirm your new password.</p>
            </div>

            <div class="row">
              <div class="col mb-3 mb-lg-0 text-start">
                <!-- email -->
                <div class="mb-4">
                  <label class="form-label" for="newPass" tabindex="0"
                    >New Password</label
                  >

                  <input
                    type="password"
                    class="form-control form-control-lg"
                    name="newPass"
                    id="newPass"
                    v-model="newPass"
                    tabindex="1"
                    placeholder="Enter your new password"
                    aria-label="Enter your new password"
                    required
                  />
                </div>
                <div class="mb-4">
                  <label class="form-label" for="passConfirm" tabindex="2"
                    >Confirm New Password</label
                  >

                  <input
                    type="password"
                    class="form-control form-control-lg"
                    name="passConfirm"
                    id="passConfirm"
                    v-model="passConfirm"
                    tabindex="3"
                    placeholder="Confirm your new password"
                    aria-label="Confirm your new password"
                    required
                  />
                </div>
              </div>
            </div>

            <div v-if="passReset">
              <div v-if="passReset.success">
                <p class="text-info text-start fw-bold mb-0">
                  Password reset successful!
                </p>
                <!-- <p class="text-start">
                    Check your email (including Junk/Spam folders) to finish
                    resetting your password.
                  </p> -->
              </div>
              <div v-if="!passReset.success">
                <p class="text-info text-start fw-bold mb-0">Oops!</p>
                <p class="text-start">
                  Looks like your passwords do not match. Double check they
                  match and try again.
                </p>
              </div>
            </div>

            <!-- submit -->
            <div class="d-grid gap-2">
              <button
                @click="resetPassword"
                type="submit"
                class="btn btn-primary btn-lg"
              >
                Save new password
              </button>
              <div class="text-center">
                <router-link
                  to="/login"
                  class="btn btn-link"
                  href="./authentication-login-basic.html"
                >
                  <b-icon-chevron-left></b-icon-chevron-left> Back to Sign in
                </router-link>
              </div>
            </div>
          </form>
          <!-- End Form -->
        </div>
      </TheCard>
    </BlockStack>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
export default {
  components: {
    ThePage,
    BlockStack,
    TheCard,
  },
  name: "FinishResetPassword",

  methods: {
    async resetPassword() {
      const { newPass, passConfirm } = this;
      const { token } = this.$route.params;
      const success = await this.$store.dispatch("auth/finishResetPass", {
        newPass,
        passConfirm,
        token,
      });
      this.passReset = { success };
      success.finish && success.finish();
      if (success) {
        this.$router.push("/");
      }
      //show popup to check email

      // if (this.isBoldXchangeAdmin) {
      //   this.$router.push({ path: "/admin" });
      // } else {
      //   //if (this.$store.state.auth.user.role === "user") {
      //   this.$router.push({ path: "/" });
      // }
      // this.$root.$emit("login", this.$store.state.auth.token);
    },
  },
  data() {
    return {
      newPass: "",
      passConfirm: "",
      passReset: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
