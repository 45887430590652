<template>
  <Teleport to="#modal">
    <FullPageModal :open="isTakingTour" customWidthClass="tw-max-w-5xl ">
      <template v-if="currentStep === 1">
        <ProductTourStep_1_Dashboard
          @goToNextStep="goToNextStep"
          @exitProductTour="exitProductTour"
        ></ProductTourStep_1_Dashboard>
      </template>
      <template v-if="currentStep === 2">
        <ProductTourStep_2_Campaign
          @goToNextStep="goToNextStep"
          @goToPreviousStep="goToPreviousStep"
          @exitProductTour="exitProductTour"
        ></ProductTourStep_2_Campaign>
      </template>
      <template v-if="currentStep === 3">
        <ProductTourStep_3_AddRecipient
          @goToNextStep="goToNextStep"
          @goToPreviousStep="goToPreviousStep"
          @exitProductTour="exitProductTour"
        ></ProductTourStep_3_AddRecipient>
      </template>
      <template v-if="currentStep === 4">
        <ProductTourStep_4_SetGiftCardAmount
          @goToNextStep="goToNextStep"
          @goToPreviousStep="goToPreviousStep"
          @exitProductTour="exitProductTour"
        ></ProductTourStep_4_SetGiftCardAmount>
      </template>
      <template v-if="currentStep === 5">
        <ProductTourStep_5_SelectGiftCards
          @goToNextStep="goToNextStep"
          @goToPreviousStep="goToPreviousStep"
          @exitProductTour="exitProductTour"
        ></ProductTourStep_5_SelectGiftCards>
      </template>
      <template v-if="currentStep === 6">
        <ProductTourStep_6_SelectCampaignType
          @goToNextStep="goToNextStep"
          @goToPreviousStep="goToPreviousStep"
          @exitProductTour="exitProductTour"
        ></ProductTourStep_6_SelectCampaignType>
      </template>
      <template v-if="currentStep === 7">
        <ProductTourStep_7_SendCampaign
          @goToNextStep="goToNextStep"
          @goToPreviousStep="goToPreviousStep"
          @exitProductTour="exitProductTour"
        ></ProductTourStep_7_SendCampaign>
      </template>
    </FullPageModal>
  </Teleport>
</template>

<script>
import FullPageModal from "../../../components-v2/ui/modals/FullPageModal.vue";
import ProductTourStep_1_Dashboard from "./ProductTourStep_1_Dashboard.vue";
import ProductTourStep_2_Campaign from "./ProductTourStep_2_Campaign.vue";
import ProductTourStep_3_AddRecipient from "./ProductTourStep_3_AddRecipient.vue";
import ProductTourStep_4_SetGiftCardAmount from "./ProductTourStep_4_SetGiftCardAmount";
import ProductTourStep_5_SelectGiftCards from "./ProductTourStep_5_SelectGiftCards";
import ProductTourStep_6_SelectCampaignType from "./ProductTourStep_6_SelectCampaignType";
import ProductTourStep_7_SendCampaign from "./ProductTourStep_7_SendCampaign";
// import AccountSetupStepTwo from "./AccountSetupStepTwo.vue";

export default {
  name: "ProductTour",
  emits: ["exitProductTour"],
  components: {
    // AccountSetupStepTwo,
    ProductTourStep_1_Dashboard,
    ProductTourStep_2_Campaign,
    ProductTourStep_3_AddRecipient,
    ProductTourStep_4_SetGiftCardAmount,
    ProductTourStep_5_SelectGiftCards,
    ProductTourStep_6_SelectCampaignType,
    ProductTourStep_7_SendCampaign,
    FullPageModal,
  },
  props: {
    isTakingTour: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  computed: {},
  methods: {
    /** handle steps */
    handleStepChange(step) {
      this.currentStep = step;
    },
    goToNextStep() {
      this.currentStep++;
    },
    goToPreviousStep() {
      this.currentStep--;
    },
    exitProductTour() {
      this.$emit("exitProductTour");
    },
  },
};
</script>

<style scoped></style>
