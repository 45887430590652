<template>
  <!-- showNewGroupModal -->

  <template v-if="showNewGroupModal">
    <Teleport to="#modal">
      <TheModal
        title="Creating group"
        subheading="Give your group a name and then we'll add recipients to it."
        @closeModal="closeModal"
        :open="showNewGroupModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <GroupForm @closeModal="closeModal"></GroupForm>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showNewSegmentModal">
    <Teleport to="#modal">
      <WideModal
        title="Creating segment"
        subheading="Segments allow you to track and analyze recipients who meet certain conditions."
        @closeModal="closeModal"
        :open="showNewSegmentModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <SegmentForm @closeModal="closeModal"></SegmentForm>
      </WideModal>
    </Teleport>
  </template>
  <template v-if="showDeleteModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        :title="
          currentTab === 1
            ? 'Delete selected groups?'
            : 'Delete selected segments'
        "
        @closeModal="closeModal"
        :open="showDeleteModal"
      >
        <ModalBody>
          <template v-if="currentTab === 1">
            <DeleteData
              :groups="idsToDelete"
              @closeModal="closeModal"
              @confirmDelete="confirmDelete()"
            ></DeleteData>
          </template>
          <template v-if="currentTab === 2">
            <DeleteData
              :segments="idsToDelete"
              @closeModal="closeModal"
              @confirmDelete="confirmDelete()"
            ></DeleteData>
          </template>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="checkingIfUserHasGroups">
    <!-- <div
      class="tw-flex tw-justify-center tw-items-center tw-w-full tw-max-w-5xl tw-mx-auto"
    > -->
    <PageSection :fullWidth="true">
      <PulseAnimation>
        <DataTableSkeleton></DataTableSkeleton>
      </PulseAnimation>
    </PageSection>
    <!-- </div> -->
  </template>
  <template v-else>
    <!-- <div class="tw-overflow-visible"> -->
    <template>
      <PageSection :fullWidth="true">
        <PulseAnimation>
          <DataTableSkeleton></DataTableSkeleton>
        </PulseAnimation>
      </PageSection>
    </template>
    <!-- <template v-else> -->
    <template v-if="userHasGroups || userHasSegments">
      <TheCard customWidthClass="tw-w-full">
        <template v-if="userHasGroups || userHasSegments">
          <CardHeader
            :showTitle="true"
            :showActions="true"
            :useSearchBar="true"
          >
            <template #card-search-bar>
              <div class="tw-max-w-xl tw-w-full">
                <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-full">
                  <div
                    class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                  >
                    <b-icon-search
                      class="tw-h-5 tw-w-5 tw-text-gray-400"
                      aria-hidden="true"
                    ></b-icon-search>
                  </div>
                  <input
                    @keyup.enter="handleSearchQuery()"
                    v-model="searchQuery.value"
                    type="text"
                    name="recipientSearch"
                    id="recipientSearch"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                    :placeholder="
                      currentTab === 1
                        ? 'Search group by name or tags'
                        : 'Search segments'
                    "
                  />
                  <template v-if="searchQuery.active">
                    <div
                      @click="resetFilters()"
                      class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-cursor-pointer"
                    >
                      <span>reset</span>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <template #card-header-actions>
              <template v-if="dashboardView">
                <TheLink to="/recipients" :isRouterLink="true">
                  <template #text>View all recipients</template>
                </TheLink>
              </template>
              <template>
                <div class="tw-flex tw-gap-4 tw-items-end">
                  <div>
                    <div class="sm:tw-hidden">
                      <label for="tabs" class="tw-sr-only">Select a tab</label>
                      <select
                        v-model="currentTab"
                        id="tabs"
                        name="tabs"
                        class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                      >
                        <option
                          v-for="tab in tabs"
                          :key="tab.id"
                          :selected="tab.id"
                          :value="tab.id"
                        >
                          {{ tab.name }}
                        </option>
                      </select>
                    </div>
                    <div class="tw-hidden sm:tw-block">
                      <div
                        class="tw-border-b-2 tw-border-gray-200 tw-border-solid tw-border-t-0 tw-border-x-0"
                      >
                        <nav
                          class="tw-flex tw-items-center tw-space-x-2"
                          aria-label="Tabs"
                        >
                          <a
                            v-for="tab in tabs"
                            :key="tab.name"
                            @click.prevent="handleTabChange(tab.id)"
                            :class="[
                              currentTab === tab.id
                                ? 'tw-border-blue-700 tw-text-blue-800 tw-border-solid tw-border-2 tw-border-t-0 tw-border-x-0 tw-cursor-pointer'
                                : ' tw-text-gray-800 hover:tw-border-blue-700 hover:tw-border-solid hover:tw-border-x-0 hover:tw-border-t-0 hover:tw-text-blue-800 tw-cursor-pointer',
                              'tw-whitespace-nowrap tw-border-2  tw-pt-1 tw-px-1 tw-text-sm tw-font-medium tw-cursor-pointer',
                            ]"
                            >{{ tab.name }}</a
                          >
                        </nav>
                      </div>
                    </div>
                  </div>
                  <!-- <ButtonDropdown
                :id="'button-dropdown-group-type'"
                :items="typeDropdownItems()"
                buttonText="Show: Groups"
                :disabled="false"
                :active="false"
                :hasLeadingIcon="false"
                :hasTrailingIcon="true"
                size="small"
                variant="tertiary"
              /> -->
                  <!-- <ButtonDropdown
                :id="'button-dropdown-sort'"
                :items="sortByDropdownItems()"
                buttonText="Sort by: Newest"
                :disabled="false"
                :active="false"
                :hasLeadingIcon="false"
                :hasTrailingIcon="true"
                size="small"
                variant="tertiary"
              /> -->
                </div>
              </template>
            </template>
          </CardHeader>
        </template>

        <template v-if="loadingAllRecipientsGroupsAndSegments">
          <PulseAnimation>
            <DataTableSkeleton></DataTableSkeleton>
          </PulseAnimation>
        </template>
        <template v-else>
          <!-- if userHasGroups show recipient list -->
          <template v-if="userHasGroups && count">
            <template v-if="currentTab === 1">
              <div
                class="tw-overflow-y-auto tw-min-h-[500px] tw-max-h-[500px] tw-w-full"
              >
                <table
                  class="tw-min-w-full tw-divide-stone-300 tw-sticky tw-top tw-z-0"
                >
                  <thead
                    class="tw-sticky tw-top-0 tw-z-10 tw-border-b tw-border-stone-300"
                  >
                    <!-- life -->
                    <tr>
                      <th
                        scope="col"
                        class="tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Type
                      </th>
                      <!-- <template v-if="currentTab === 1"> -->
                      <th
                        scope="col"
                        class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Recipients
                      </th>
                      <!-- </template> -->
                      <th
                        scope="col"
                        class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Created
                      </th>
                      <th
                        scope="col"
                        class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Tags
                      </th>
                      <th
                        scope="col"
                        class="tw-px-3 tw-py-3.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        <span class="tw-sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="tw-divide-y tw-divide-solid tw-divide-gray-200 tw-bg-white"
                  >
                    <template v-if="currentTab === 1">
                      <RecipientsGroupsAndSegmentsListItem
                        v-for="group in groups"
                        @toggleDeleteModal="toggleDeleteModal"
                        :key="group._id"
                        :group="group"
                        :currentTab="currentTab"
                      >
                      </RecipientsGroupsAndSegmentsListItem>
                    </template>
                    <template v-if="currentTab === 2">
                      <RecipientsGroupsAndSegmentsListItem
                        @toggleDeleteModal="toggleDeleteModal"
                        v-for="segment in segments"
                        :key="segment._id"
                        :segment="segment"
                        :currentTab="currentTab"
                      >
                      </RecipientsGroupsAndSegmentsListItem>
                    </template>
                  </tbody>
                </table>
              </div>
            </template>
          </template>
          <!-- if userHasRecipients AND query count is 0 show recipient message -->
          <template v-if="userHasGroups && !count">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
            >
              <template v-if="searchQuery.active">
                <img
                  style="max-height: 150px; width: auto"
                  src="../../../assets/svg/illustrations-test/14_no_query_results.png"
                  alt="Image Description"
                />
              </template>
              <template v-else>
                <img
                  style="max-height: 150px; width: auto"
                  src="../../../assets/svg/illustrations-test/46_blank_page.png"
                  alt="Image Description"
                />
              </template>

              <template v-if="searchQuery.active">
                <h1 class="tw-text-2xl tw-m-0">
                  No exact matches found in your
                  <span class="tw-text-indigo-600">{{
                    currentTab === 1 ? "Groups" : "Segments"
                  }}</span>
                </h1>
                <p class="tw-m-0 tw-text-lg tw-max-w-xl">
                  Adjust your search or manage your
                  {{ currentTab === 1 ? "Groups" : "Segments" }} by creating a
                  new one.
                </p>
              </template>
            </div>
          </template>
          <!-- if !userHasRecipients show message -->
        </template>

        <template v-if="userHasGroups">
          <CardFooter>
            <!-- Pagination -->
            <nav
              class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200"
              aria-label="Pagination"
            >
              <div class="tw-hidden sm:tw-block">
                <p class="tw-text-sm tw-text-gray-700 tw-m-0">
                  Showing
                  <span class="tw-font-bold"
                    >{{
                      this.currentTab === 1
                        ? this?.groups?.length
                        : this?.segments?.length
                    }}
                    of {{ count }}</span
                  >
                  records
                </p>
              </div>
              <div
                class="tw-flex tw-flex-1 tw-justify-between tw-gap-x-3 sm:tw-justify-end"
              >
                <TheButton
                  class="disabled:tw-bg-slate-200 disabled:tw-cursor-auto"
                  :disabled="
                    this.currentPage === 1 || this.selectedGroups.length > 0
                  "
                  @click="previousPage()"
                  variant="tertiary"
                  size="small"
                >
                  <template #text
                    ><b-icon-chevron-left></b-icon-chevron-left
                  ></template>
                </TheButton>
                <TheButton
                  class="disabled:tw-bg-slate-200"
                  :disabled="this.isLastPage || this.selectedGroups.length > 0"
                  @click="nextPage()"
                  variant="tertiary"
                  size="small"
                >
                  <template #text
                    ><b-icon-chevron-right></b-icon-chevron-right
                  ></template>
                </TheButton>
              </div>
            </nav>
          </CardFooter>
        </template>
        <!-- </template> -->
      </TheCard>
    </template>
    <template v-else>
      <div class="tw-flex tw-justify-center tw-mx-auto">
        <TheCard class="tw-w-full">
          <TheBox :withPadding="true">
            <EmptyState emptyState="groups"></EmptyState>
            <div class="tw-flex tw-justify-center">
              <TheButton
                type="button"
                :hasLeadingIcon="true"
                :hasTrailingIcon="true"
                variant="primary"
                size="large"
                @click="toggleNewGroupModal()"
              >
                <template #text>Create new group </template>
                <template #leading-icon
                  ><b-icon-plus-lg></b-icon-plus-lg
                ></template>
              </TheButton>
            </div>
          </TheBox>
        </TheCard>
      </div>
    </template>
    <!-- </template> -->
    <!-- </div> -->
  </template>
</template>

<script>
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";
import CardHeader from "../../../components-v2/ui/layout/CardHeader.vue";
import CardFooter from "../../../components-v2/ui/layout/CardFooter.vue";
import TheModal from "../../../components-v2/ui/modals/TheModal.vue";
import WideModal from "../../../components-v2/ui/modals/WideModal.vue";
import GroupForm from "../../../components-v2/ui/modals/create/GroupForm.vue";
import SegmentForm from "../../../components-v2/ui/modals/create/SegmentForm.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../../components-v2/ui/actions/TheLink.vue";
import EmptyState from "../../../components-v2/ui/feedback/EmptyState.vue";
import DeleteData from "../../ui/modals/DeleteData.vue";
import PageSection from "../../../components-v2/ui/layout/PageSection.vue";
import DataTableSkeleton from "../../../components-v2/ui/skeletons/DataTableSkeleton.vue";
import PulseAnimation from "../../../components-v2/ui/feedback/PulseAnimation.vue";
import ModalBody from "../../../components-v2/ui/layout/ModalBody.vue";
import RecipientsGroupsAndSegmentsListItem from "./RecipientsGroupsAndSegmentsListItem";
import NotificationMessage from "../../../components-v2/ui/feedback/NotificationMessage.vue";
import moment from "moment";

export default {
  name: "RecipientsGroupsAndSegmentsList",
  components: {
    NotificationMessage,
    TheBox,
    RecipientsGroupsAndSegmentsListItem,
    EmptyState,
    GroupForm,
    SegmentForm,
    DeleteData,
    TheModal,
    TheLink,
    TheCard,
    CardHeader,
    TheButton,
    CardFooter,
    PageSection,
    DataTableSkeleton,
    PulseAnimation,
    ModalBody,
    WideModal,
  },
  emits: [
    "setCampaignRecipients",
    "update:isNewGroupModalVisible",
    "update:isNewSegmentModalVisible",
  ],
  props: {
    campaignView: {
      type: Boolean,
      default: false,
    },
    campaignId: {
      type: String,
    },
    dashboardView: {
      type: Boolean,
      default: false,
    },
    isNewGroupModalVisible: {
      type: Boolean,
      default: false,
    },
    isNewSegmentModalVisible: {
      type: Boolean,
      default: false,
    },
    sharedCampaignData: {
      type: Array,
      default: () => [],
    },
    totalRecipientsCount: {
      type: Number,
      default: 1,
      required: false,
    },
    campaignRecipients: {
      type: Array,
    },
    viewCampaignRecipientsSelection: {
      type: Boolean,
    },
  },
  watch: {
    isNewGroupModalVisible(newVal) {
      if (newVal) {
        this.toggleNewGroupModal();
      }
    },
    isNewSegmentModalVisible(newVal) {
      if (newVal) {
        this.toggleNewSegmentModal();
      }
    },
  },
  created() {
    // this.checkIfUserHasGroups();
    this.loadRecipientGroupsAndSegments();
  },
  data() {
    return {
      newRecipient: {},
      searchQuery: {
        active: false,
        value: "",
      },
      selectedRecipient: {},
      recipients: [],
      groups: [],
      segments: [],
      selectedGroups: [],
      selectedSegments: [],
      showNewGroupModal: false,
      showNewSegmentModal: false,
      isAddingGroupOrSegment: false,
      userHasGroups: false,
      userHasSegments: false,
      checkingIfUserHasGroups: false,
      checkingIfUserHasSegments: false,
      loadingAllRecipientsGroupsAndSegments: false,
      groupIdsToDelete: null,
      segmentIdsToDelete: null,
      showDeleteModal: false,
      campaignRecipientsSelectionUpdated: false,
      isViewingGroups: true,
      isViewingSegments: false,
      count: 0,
      pageSize: 100,
      currentPage: 1,
      recipient: "",
      groupId: "test",
      sortedValue: "-_id",
      tabs: [
        // { id: 1, name: "All" },
        { id: 1, name: "Groups" },
        { id: 2, name: "Segments" },
      ],
      currentTab: 1,
    };
  },
  computed: {
    recipientData() {
      return this.dashboardView ? this.sharedCampaignData : this.recipients;
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.groups.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    userClient() {
      return this?.$store?.state?.auth?.user?.client;
    },
    recipientGiftingLead() {
      return (recipient) => {
        if (
          recipient?.metadata?.giftingLeads &&
          recipient?.metadata?.giftingLeads[0] ===
            this?.$store?.state?.auth?.user?._id
        ) {
          return true;
        }
      };
    },
    idsToEdit() {
      return this.recipientIdsToEdit.map((recipient) => recipient._id);
    },
    idsToDelete() {
      return this.currentTab === 1
        ? this.groupIdsToDelete
        : this.segmentIdsToDelete;
    },
    getTimeStamp() {
      return (id) => {
        const objectIdToTimestamp = require("objectid-to-timestamp");
        const originalDate = new Date(objectIdToTimestamp(id));
        return moment(originalDate).format("MMM D, YYYY, h:mm A");
      };
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** handle deleting selected recipients */
    async confirmDelete() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      try {
        const deleteMethod =
          this.currentTab === 1 ? "deleteGroups" : "deleteSegments";
        const response = await api[deleteMethod](null, {
          filter: { _id: this.idsToDelete[0] },
        });

        console.log("delete method: ", deleteMethod);
        console.log("this.idsToDelete: ", this.idsToDelete);

        this.handleSuccess(response, deleteMethod);
      } catch (error) {
        console.log("data was NOT deleted", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your delete request. Please try again."
        );
      } finally {
        this.showDeleteModal = false;
      }
    },
    handleBulkDelete(recipients) {
      this.recipientIdsToDelete = recipients;
      this.showDeleteModal = !this.showDeleteModal;
    },

    closeModal() {
      this.showDeleteModal = false;
      this.showNewGroupModal = false;
      this.showNewSegmentModal = false;
      this.$emit("update:isNewGroupModalVisible", false);
      this.$emit("update:isNewSegmentModalVisible", false);
    },

    /** handle recipient selection */
    selectAllRecipients(isChecked) {
      this.selectedGroups = isChecked ? this.recipients.slice() : [];
    },
    isRecipientSelected(recipientId) {
      return this.selectedGroups.some((r) => r.id === recipientId);
    },
    toggleRecipientSelection(recipient) {
      const index = this.selectedGroups.findIndex((r) => r.id === recipient.id);
      if (index > -1) {
        this.selectedGroups.splice(index, 1);
      } else {
        this.selectedGroups.push(recipient);
      }
    },

    /** load data */
    async loadRecipientGroupsAndSegments() {
      this.loadingAllRecipientsGroupsAndSegments = true;
      try {
        await Promise.all([
          (this.userHasGroups = await this.checkIfUserHasGroups()),
          // (this.userHasSegments = this.checkIfUserHasSegments()),
        ]);
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your groups and segments data. Please reload the page and try again."
        );
      } finally {
        this.loadingAllRecipientsGroupsAndSegments = false;
      }
    },
    async checkIfUserHasGroups(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.currentPage = page === undefined ? this.currentPage : page;
      this.checkingIfUserHasGroups = true;
      let requestBody = {};
      let filter = {
        model: "Recipient",
        "metadata.admins": { $eq: this.userId },
      };

      if (this.searchQuery.value !== "") {
        filter.$or = [
          { "metadata.name": { $regex: this.searchQuery.value } },
          { "metadata.tags": { $regex: this.searchQuery.value } },
        ];
      }
      // let selection = "metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);

        const count = response.data.result.count;
        this.count = response.data.result.count;
        // this.userHasGroups = count;
        // this.userHasGroups
        this.groups = response.data.result.records;
        return count ? true : false;
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.checkingIfUserHasGroups = false;
      }
    },
    async checkIfUserHasSegments(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.currentPage = page === undefined ? this.currentPage : page;
      this.checkingIfUserHasSegments = true;

      let requestBody = {};
      let filter = {
        model: "Recipient",
      };

      if (this.searchQuery.value !== "") {
        filter.$or = [
          { "metadata.name": { $regex: this.searchQuery.value } },
          { "metadata.tags": { $in: [this.searchQuery.value] } },
        ];
      }
      // let selection = "metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listSegments(queryParameters, requestBody);
        const count = response.data.result.count;
        this.count = response.data.result.count;
        // this.userHasSegments = count;
        this.segments = response.data.result.records;
        return count;
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.checkingIfUserHasSegments = false;
      }
    },

    /** paginate list of recipients */
    async nextPage() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.loadingAllRecipientsGroupsAndSegments = true;
      this.currentPage++;

      let requestBody = {};
      let filter = {
        model: "Recipient",
        "metadata.admins": { $eq: this.userId },
      };

      const searchQueryFilter = this.searchQuery.value
        ? {
            $or: [
              { "metadata.name": { $regex: this.searchQuery.value } },
              { "metadata.tags": { $regex: this.searchQuery.value } },
            ],
          }
        : {};

      filter = {
        ...filter,
        ...searchQueryFilter,
      };
      const selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);
        this.groups = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please try again."
        );
      } finally {
        this.loadingAllRecipientsGroupsAndSegments = false;
      }
    },
    async previousPage() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.loadingAllRecipientsGroupsAndSegments = true;
      this.currentPage--;

      let requestBody = {};
      let filter = {
        model: "Recipient",
        "metadata.admins": { $eq: this.userId },
      };

      const searchQueryFilter = this.searchQuery.value
        ? {
            $or: [
              { "metadata.name": { $regex: this.searchQuery.value } },
              { "metadata.tags": { $regex: this.searchQuery.value } },
            ],
          }
        : {};

      filter = {
        ...filter,
        ...searchQueryFilter,
      };
      const selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);
        this.groups = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please try again."
        );
      } finally {
        this.loadingAllRecipientsGroupsAndSegments = false;
      }
    },

    /** handle resetting recipient list */
    resetFilters() {
      this.searchQuery.value = "";
      this.searchQuery.active = false;
      this.currentPage = 1;
      this.loadRecipientGroupsAndSegments();
    },

    handleTabChange(tabId) {
      this.currentTab = tabId;
      this.performTabSpecificLogic();
    },
    performTabSpecificLogic() {
      if (this.currentTab === 1) {
        this.checkIfUserHasGroups();
      } else {
        this.checkIfUserHasSegments();
      }
    },

    /**handle listing recipients by search query*/
    handleSearchQuery() {
      this.searchQuery.active = true;
      this.performTabSpecificLogic();
    },

    /** handle adding recipient to campaign */
    setCampaignRecipients() {
      this.$emit("setCampaignRecipients", this.selectedGroups);
      this.selectedGroups = [];
      this.campaignRecipientsSelectionUpdated = true;
      setTimeout(() => {
        this.campaignRecipientsSelectionUpdated = false;
      }, 4000);
    },

    /** dropdown menu items and actions */
    getDropdownItems(data) {
      // this.selectedRecipient = type;
      let dataType;
      if (data?.members) {
        dataType = "group";
      } else {
        dataType = "segment";
      }
      return [
        {
          label: "Edit",
          isDeleteAction: false,
          action: () => this.handleRoutingToDetailsPage(dataType, data._id),
        },
        // {
        //   label: "View campaigns",
        //   isDeleteAction: false,
        //   action: () => this.toggleRecipientDetailsModal(type),
        // },
        // {
        //   label: "Duplicate",
        //   isDeleteAction: false,
        //   action: () => this.toggleRecipientDetailsModal(type),
        // },
        {
          label: "Delete",
          isDeleteAction: true,
          action: () => this.toggleDeleteModal(dataType, data._id),
        },
      ];
    },
    /** routing */
    handleRoutingToDetailsPage(dataType, dataId) {
      if (dataType === "group") {
        this.$router.push({
          name: "RecipientGroupDetails",
          params: { dataId },
        });
      }
      if (dataType === "segment") {
        this.$router.push({
          name: "RecipientSegmentDetails",
          params: { dataId },
        });
      }
    },
    toggleDeleteModal(dataType, dataId) {
      if (dataType === "group") {
        this.groupIdsToDelete = [dataId];
      }
      if (dataType === "segment") {
        this.segmentIdsToDelete = [dataId];
      }
      this.showDeleteModal = !this.showDeleteModal;
    },
    sortByDropdownItems() {
      return [
        {
          label: "Newest",
          // action: () => this.toggleNewGroupModal(),
        },
        {
          label: "Oldest",
          // action: () => this.listGroup(),
        },
        {
          label: "A-Z",
          // action: () => this.toggleNewGroupModal(),
        },
        {
          label: "Z-A",
          // action: () => this.listGroup(),
        },
      ];
    },
    typeDropdownItems() {
      return [
        {
          label: "Groups",
          // action: () => this.handleListingGroupsAndSegments('groups'),
        },
        {
          label: "Segments",
          // action: () => this.handleListingGroupsAndSegments('segments'),
        },
      ];
    },

    reloadList() {
      const page = this.currentPage;
      if (this.searchQuery.value) {
        this.handleSearchQuery();
      } else if (this.activeStage) {
        this.listRecipientsByLifecycleStage(this.activeStage);
      } else {
        this.checkForRecipientData(page);
      }
    },

    /** quick edits */
    activateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to true
      this.$store.dispatch("orders/activateQuickEdit");
    },
    deactivateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to false
      this.$store.dispatch("orders/deactivateQuickEdit");
    },

    /** modals */
    // toggleNewGroupModal
    toggleNewGroupModal() {
      this.showNewGroupModal = !this.showNewGroupModal;
    },
    toggleNewSegmentModal() {
      this.showNewSegmentModal = !this.showNewSegmentModal;
    },

    /** handle success and errors */
    handleSuccess(response, api) {
      if (api === "deleteGroups") {
        let res = response;
        this.groupIdsToDelete = [];
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Group was successfully deleted.",
          duration: 4000,
          id: Date.now(),
        });
        this.loadRecipientGroupsAndSegments();
      } else if (api === "deleteSegments") {
        let res = response;
        this.segmentIdsToDelete = [];
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Segment was successfully edited.",
          duration: 4000,
          id: Date.now(),
        });
        this.loadRecipientGroupsAndSegments();
      } else {
        this.showNewGroupModal = false;
        this.loadRecipientGroupsAndSegments();
      }
      this.showDeleteModal = false;
    },

    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error?.response) {
        // Process API error message if customMessage is not provided
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      // this.reloadList();
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>
