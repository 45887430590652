<template>
  <!-- Content -->
  <div class="content container-fluid">
    <div class="row justify-content-center align-items-sm-center py-sm-10">
      <div class="col-9 col-sm-6 col-lg-4">
        <div class="text-center text-sm-end me-sm-4 mb-5 mb-sm-0">
          <img
            class="img-fluid"
            src="../../assets/svg/illustrations/oc-thinking.svg"
            alt="Image Description"
          />
        </div>
      </div>
      <!-- End Col -->

      <div class="col-sm-6 col-lg-4 text-center text-sm-start">
        <h1 class="display-1 mb-0">Oops!</h1>
        <p class="lead">
          We can't seem to find the page you're looking for. Please check your
          URL or
          <router-link class="link primary-link" to="/">return home</router-link>.
        </p>
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Content -->
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
