<template>
  <PageHeader title="Account" :showSubheading="true">
    <template #subheading-content>
      <div>
        <div class="sm:tw-hidden">
          <label for="tabs" class="tw-sr-only">Select a tab</label>
          <select
            v-model="currentTab"
            id="tabs"
            name="tabs"
            class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
          >
            <template v-if="this.isBoldXchangeAdmin || this.isGiftingLead">
              <option
                v-for="tab in adminAndGiftingLeadTabs"
                :key="tab.id"
                :selected="tab.id"
                :value="tab.id"
              >
                {{ tab.name }}
              </option>
            </template>
            <template v-else>
              <option
                v-for="tab in giftRecipientTabs"
                :key="tab.id"
                :selected="tab.id"
                :value="tab.id"
              >
                {{ tab.name }}
              </option>
            </template>
          </select>
        </div>
        <div class="tw-hidden sm:tw-block">
          <div
            class="tw-border-b-2 tw-border-gray-200 tw-border-solid tw-border-t-0 tw-border-x-0"
          >
            <nav class="tw-flex tw-items-center tw-space-x-4" aria-label="Tabs">
              <template v-if="this.isBoldXchangeAdmin || this.isGiftingLead">
                <a
                  v-for="tab in adminAndGiftingLeadTabs"
                  :key="tab.name"
                  @click.prevent="handleTabChange(tab.id)"
                  :class="[
                    currentTab === tab.id
                      ? 'tw-border-blue-700 tw-text-blue-800 tw-border-solid tw-border-2 tw-border-t-0 tw-border-x-0 tw-cursor-pointer'
                      : ' tw-text-gray-800 hover:tw-border-blue-700 hover:tw-border-solid hover:tw-border-x-0 hover:tw-border-t-0 hover:tw-text-blue-800 tw-cursor-pointer',
                    'tw-whitespace-nowrap tw-border-2  tw-pt-1 tw-px-1 tw-text-lg tw-font-medium tw-cursor-pointer',
                  ]"
                >
                  {{ tab.name }}
                </a>
              </template>
              <template v-else>
                <a
                  v-for="tab in giftRecipientTabs"
                  :key="tab.name"
                  @click.prevent="handleTabChange(tab.id)"
                  :class="[
                    currentTab === tab.id
                      ? 'tw-border-blue-700 tw-text-blue-800 tw-border-solid tw-border-2 tw-border-t-0 tw-border-x-0 tw-cursor-pointer'
                      : ' tw-text-gray-800 hover:tw-border-blue-700 hover:tw-border-solid hover:tw-border-x-0 hover:tw-border-t-0 hover:tw-text-blue-800 tw-cursor-pointer',
                    'tw-whitespace-nowrap tw-border-2  tw-pt-1 tw-px-1 tw-text-lg tw-font-medium tw-cursor-pointer',
                  ]"
                >
                  {{ tab.name }}
                </a>
              </template>
            </nav>
          </div>
        </div>
      </div>
    </template>
  </PageHeader>
  <NotificationMessage :scope="$options.name"></NotificationMessage>

  <ThePage class="tw-w-full tw-max-w-4xl">
    <template v-if="currentTab === 1">
      <BlockStack :gap="800" class="tw-w-full">
        <PageSection class="tw-w-full">
          <UserContactInformation> </UserContactInformation
        ></PageSection>
        <template v-if="this.isBoldXchangeAdmin || this.isGiftingLead">
          <PageSection class="tw-w-full">
            <UserProfileMedia> </UserProfileMedia
          ></PageSection>
        </template>
      </BlockStack>
    </template>
    <template
      v-if="currentTab === 2 && (this.isBoldXchangeAdmin || this.isGiftingLead)"
    >
      <BlockStack :gap="800" class="tw-w-full">
        <PageSection class="tw-w-full">
          <UserCampaignSettings> </UserCampaignSettings
        ></PageSection>
        <PageSection class="tw-w-full">
          <UserLandingPageSettings> </UserLandingPageSettings
        ></PageSection>
      </BlockStack>
    </template>
    <template
      v-if="currentTab === 3 && (this.isBoldXchangeAdmin || this.isGiftingLead)"
    >
      <PageSection class="tw-w-full">
        <StripeDefaultPaymentCard></StripeDefaultPaymentCard>
        <SubscriptionsHistoryList></SubscriptionsHistoryList>
      </PageSection>
    </template>
  </ThePage>
</template>

<script>
import SubscriptionsHistoryList from "../../components-v2/subscriptions/SubscriptionsHistoryList";
import StripeDefaultPaymentCard from "../../components-v2/stripe/payments/StripeDefaultPaymentCard.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader";
import ThePage from "../../components-v2/ui/layout/ThePage";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import PageSection from "../../components-v2/ui/layout/PageSection";
import UserContactInformation from "../../components-v2/account/UserContactInformation";
import UserProfileMedia from "../../components-v2/account/UserProfileMedia";
import UserCampaignSettings from "../../components-v2/account/UserCampaignSettings";
import UserLandingPageSettings from "../../components-v2/account/UserLandingPageSettings";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";

export default {
  name: "AccountSettings",
  components: {
    UserLandingPageSettings,
    NotificationMessage,
    UserContactInformation,
    UserProfileMedia,
    UserCampaignSettings,
    SubscriptionsHistoryList,
    StripeDefaultPaymentCard,
    PageHeader,
    ThePage,
    BlockStack,
    PageSection,
  },
  data() {
    return {
      selectedUser: {},
      paymentInformation: {},
      subscription: {},
      adminAndGiftingLeadTabs: [
        { id: 1, name: "Profile Settings" },
        { id: 2, name: "Campaign Settings" },
        { id: 3, name: "Payments & Billing" },
      ],
      giftRecipientTabs: [{ id: 1, name: "Profile Settings" }],
      currentTab: 1,
      totalSalesCount: 0,
      totalGiftsDelivered: 0,
      totalVendorsSupported: 0,
      clientName: "",
      isLoading: true,
      showModal: false,
      isEditingFullName: false,
      hasDefaultPaymentMethod: false,
    };
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    userFullName() {
      return (
        this?.$store?.state?.auth?.user?.firstName +
        " " +
        this?.$store?.state?.auth?.user?.lastName
      );
    },
    userEmail() {
      return this?.$store?.state?.auth?.user?.email;
    },
    userCompany() {
      return this?.$store?.state?.auth?.user?.client?.name;
    },
    /** subscription info */
    subscriptionName() {
      return this?.subscription?.name;
    },

    /** payment info */
    paymentCardBrand() {
      return this?.paymentInformation?.card?.display_brand;
    },
    paymentCardLastFourDigits() {
      return this?.paymentInformation?.card?.last4;
    },
    paymentCardExpirationDate() {
      return (
        this?.paymentInformation?.card?.exp_month +
        " / " +
        this?.paymentInformation?.card?.exp_year
      );
    },
    paymentMethodButtonClass() {
      return this.hasDefaultPaymentMethod ? "primary" : "critical";
    },
  },
  methods: {
    toggleRedemptionsNotifications() {
      this.receiveRedemptionsNotifications =
        !this.receiveRedemptionsNotifications;
    },
    toggleClicksNotifications() {
      this.receiveClicksNotifications = !this.receiveClicksNotifications;
    },
    handleTabChange(tabId) {
      this.currentTab = tabId;
    },
  },
};
</script>

<style scoped></style>
