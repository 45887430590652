<template>
  <AnonymousNavbar></AnonymousNavbar>
  <main>
    <router-view></router-view>
  </main>
  <TheFooter></TheFooter>
</template>

<script>
import AnonymousNavbar from "./AnonymousNavbar.vue";
import TheFooter from "../../../ui/navigation/TheFooter.vue";

export default {
  name: "AnonymousNavigation",
  components: {
    TheFooter,
    AnonymousNavbar,
  },
};
</script>

<style scoped></style>
