<template>
  <ButtonDropdown
    :disabled="!isEditingDetails"
    :id="'bulk-add-recipients-to-group-dropdown'"
    :items="groupListDropdownItems()"
    :buttonText="
      selectedGroup?.id
        ? `Group: ${selectedGroup.name}`
        : 'Choose from group...'
    "
    :alignLeft="alignLeft"
    :active="false"
    :hasLeadingIcon="false"
    :hasTrailingIcon="true"
    maxHeight="200px"
    size="small"
    :variant="selectedGroup?.id ? 'secondary' : 'tertiary'"
  />
</template>

<script>
import ButtonDropdown from "../../elements/dropdown/ButtonDropdown.vue";

export default {
  name: "RecipientsGroupsButtonDropdown",
  emits: ["handleGroupSelection"],
  components: {
    ButtonDropdown,
  },
  props: {
    segmentFilter: {
      type: Object,
      default: () => ({}),
    },
    group: {
      type: Object,
      default: () => ({}),
    },
    isEditingDetails: {
      type: Boolean,
      default: true,
    },
    alignLeft: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.checkIfUserHasGroups();
    // this.initializeForm(this.segment);
  },
  data() {
    return {
      groups: [],
      selectedGroup: {
        id: null,
        name: null,
        members: [],
        isOrIsNot: true,
      },
      checkingIfUserHasGroups: false, // Add this flag for loading state
    };
  },
  watch: {
    group(newVal) {
      if (!newVal) {
        this.selectedGroup = {
          id: null,
          name: null,
          members: [],
          isOrIsNot: true,
        };
      }
    },
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    isFormComplete() {
      return this.selectedGroup.id;
    },
    segmentNameIsValid() {
      return this.form.metadata.name.trim().length > 0
        ? this.form.metadata.name.trim()
        : false;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    filterIsComplete() {
      return (
        this.segmentFilter.selectedCriteria.value &&
        this.segmentFilter.selectedGroup.id !== null &&
        typeof this.segmentFilter.selectedGroup.isOrIsNot === "boolean"
      );
    },
    selectedGroupId() {
      return this.segmentFilter.selectedGroup.id;
    },
  },
  methods: {
    async checkIfUserHasGroups() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.checkingIfUserHasGroups = true;
      let requestBody = {};
      let filter = {
        model: "Recipient",
        "metadata.admins": { $eq: this.userId },
      };

      const queryParameters = {
        limit: 1000,
        sort: "metadata.name",
      };

      requestBody = {
        filter: filter,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);

        const count = response.data.result.count;
        this.count = response.data.result.count;
        this.userHasGroups = count;
        const groups = response.data.result.records;

        // Fetch recipients for each group
        for (let group of groups) {
          const recipients = await this.listRecipientsByGroupId(group._id);
          group.members = recipients.map((id) => ({
            doc: id,
            metadata: {},
          }));
        }

        this.groups = groups;

        return true;
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.checkingIfUserHasGroups = false;
      }
    },

    async listRecipientsByGroupId(groupId) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let requestBody = {};
      let filter = {
        "metadata.giftingLeads": { $in: [this.userId] },
        "groups._id": { $eq: groupId },
      };

      const queryParameters = {
        limit: 1000,
        expand: "groups campaigns",
      };

      requestBody = {
        filter: filter,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);
        return response.data.result.records.map((record) => record._id);
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please reload the page and try again."
        );
        return []; // Return empty array in case of error
      }
    },

    groupListDropdownItems() {
      if (this.checkingIfUserHasGroups) {
        return [
          {
            label: "Loading...",
            sublabel: "Please wait while we load your groups.",
          },
        ];
      }
      if (this.groups.length === 0) {
        return [
          {
            label: "You don't have any groups, yet.",
            sublabel: "Once you create a group, it'll show up here.",
          },
        ];
      }
      return this.groups.map((group) => ({
        label: group.metadata.name,
        sublabel: `Members: ${group.members.length}`,
        action: () => this.handleGroupSelection(group),
      }));
    },
    handleGroupSelection(group) {
      this.selectedGroup.id = group._id;
      this.selectedGroup.name = group.metadata.name;
      this.selectedGroup.members = group.members;
      this.$emit("handleGroupSelection", group);
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
