<template>
  <div
    class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-4"
  >
    <div
      v-for="stat in stats"
      :key="stat.name"
      class="tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-shadow tw-w-full"
    >
      <!-- header -->
      <div class="tw-p-4 sm:tw-p-6 tw-w-full">
        <div
          class="tw-flex gap-2 tw-w-full tw-flex-wrap tw-items-center tw-relative"
        >
          <h3
            class="tw-m-0 tw-text-lg tw-font-medium sm:tw-text-xl sm:tw-tracking-tight tw-text-wrap"
          >
            {{ stat.name }}
          </h3>

          <!-- </div> -->
        </div>
      </div>
      <!-- body -->
      <div class="tw-flex-1 tw-w-full">
        <!-- box -->
        <div
          class="tw-px-4 sm:tw-px-6 tw-pt-0 tw-pb-4 sm:tw-pb-6 tw-h-full tw-flex tw-items-end"
        >
          <div class="tw-flex tw-items-start">
            <div class="tw-text-5xl tw-font-bold tw-text-gray-900">
              <template v-if="stat.type === 'campaigns'">
                {{ totalCampaignsSentCount }}
              </template>
              <template v-if="stat.type === 'clicked'">
                {{ averageClickRate }}%
              </template>
              <template v-if="stat.type === 'redeemed'">
                {{ averageRedemptionRate }}%
              </template>
              <template v-if="stat.type === 'recipients'">
                ${{ totalSpendCount }}
              </template>
            </div>
            <TheTooltip :toolTip="true" :tooltipContent="stat.description">
              <template #icon>
                <div
                  class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                >
                  <b-icon-exclamation
                    class="tw-text-black tw-z-0"
                  ></b-icon-exclamation>
                </div>
              </template>
            </TheTooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
export default {
  name: "MultiRecipientCampaignEngagementStats",
  components: { TheTooltip },
  props: {
    totalCampaignsSentCount: {
      type: Number,
      default: 0,
    },
    totalSpendCount: {
      type: Number,
      default: 0,
    },
    totalCampaignsClickedCount: {
      type: Number,
      default: 0,
    },
    totalCampaignsRedeemedCount: {
      type: Number,
      default: 0,
    },
    campaignLink: {
      type: String
    }
  },
  data() {
    return {
      stats: [
        {
          type: "campaigns",
          name: "Total recipients",
          description: "The total number of recipients in campaign.",
        },
        {
          type: "clicked",
          name: "Click rate",
          description:
            "The percentage of campaigns clicked.",
        },
        {
          type: "redeemed",
          name: "Redemption rate",
          description: "The percentage of campaigns redeemed.",
        },
        {
          type: "recipients",
          name: "Total spend",
          description: "The total sum of redeemed gift cards.",
        },
      ],
      tooltipText: null,
      activeStat: null,
    };
  },
  watch: {
    userHasCampaigns() {
      if (this.userHasCampaigns) {
        // this.loadData();
      }
    },
  },
  created() {
    /** load data */
    //   this.checkIfUserHasCampaigns();
  },
  computed: {
    averageClickRate() {
      if (this.totalCampaignsSentCount === 0) {
        return 0; // or any other appropriate value to indicate no campaigns sent
      }
      return (
        (this.totalCampaignsClickedCount / this.totalCampaignsSentCount) *
        100
      ).toFixed(1);
    },
    averageRedemptionRate() {
      if (this.totalCampaignsSentCount === 0) {
        return 0; // or any other appropriate value to indicate no campaigns sent
      }
      return (
        (this.totalCampaignsRedeemedCount / this.totalCampaignsSentCount) *
        100
      ).toFixed(1);
    },
    isTooltipVisible() {
      return (stat) => this.activeStat === stat.type;
    },
  },
  methods: {
    /** tooltip */
    toggleTooltip(stat) {
      if (this.activeStat === stat.type) {
        this.activeStat = null;
      } else {
        this.activeStat = stat.type;
        this.tooltipText = stat.description;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-square-container {
  flex: 1 1 auto;
  max-width: 150px;
  max-height: 150px;
  width: min(150px, 150px);
  height: min(150px, 150px);
  padding: 5px;
}

.flex-square-container h1 {
  font-size: 2.5rem;
}
</style>
