<template>
  <template v-if="newBudgetModalIsVisible">
    <Teleport to="#modal">
      <TheModal
        size="small"
        :title="
          !budgetSessionId
            ? '1. Setup budget credit card'
            : '2. Finalize budget details'
        "
        @closeModal="resetSessionId"
        :open="newBudgetModalIsVisible"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <NewBudgetForm
          @closeModal="resetSessionId"
          @cancelBudget="resetSessionId"
          :sessionId="getSessionId"
          @setupPaymentMethod="handlePaymentMethodSetup"
          @handleSuccess="handleSuccess"
        ></NewBudgetForm>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="!reviewCampaignView">
    <TheCard class="tw-overflow-visible">
      <TheBox :withPadding="true">
        <BlockStack :gap="500">
          <template v-if="count">
            <BlockStack :gap="500">
              <BlockStack :gap="100">
                <label
                  for="budgetPeriod"
                  class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                  >Select a budget</label
                >
                <select
                  :value="getCampaignBudgetId"
                  @change="handleBudgetSelection($event)"
                  id="budgetPeriod"
                  name="budgetPeriod"
                  class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                >
                  <option value="">Do not set budget</option>
                  <option
                    v-for="option in budgets"
                    :value="option._id"
                    :key="option._id"
                  >
                    {{
                      `Name: ${option?.name} // Limit: $${budgetLimitInDollars(
                        option?.users[0]?.limit
                      )}`
                    }}
                  </option>
                </select>
              </BlockStack>
              <div class="">
                <TheButton
                  @click="toggleNewBudgetModal()"
                  size="regular"
                  :hasLeadingIcon="true"
                  variant="primary"
                >
                  <template #text>Create budget </template>
                  <template #leading-icon
                    ><b-icon-plus-lg></b-icon-plus-lg>
                  </template>
                </TheButton>
              </div>
              <template v-if="getCampaignBudgetId">
                <template v-if="!getCampaignBudgetTracker?.isBudgetLimitMet">
                  <template
                    v-if="
                      getCampaignBudgetTracker?.existingAllocatedGreaterThanBudgetLimit
                    "
                  >
                    <div
                      class="tw-flex-1 tw-w-full tw-border-solid tw-border-orange-400 tw-border-2 tw-p-4 tw-rounded-md"
                    >
                      <div class="tw-flex tw-items-top tw-gap-2">
                        <b-icon-exclamation-triangle-fill
                          class="tw-text-orange-400 tw-flex-shrink-0 tw-mt-0.5"
                        ></b-icon-exclamation-triangle-fill>
                        <BlockStack :gap="200">
                          <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                            Outstanding gifts for this budget exceed the Budget
                            Limit. Once the budget limit is reached, any
                            outstanding redemption attempts will be declined.
                          </p>
                          <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                            To ensure all recipients will be able to redeem,
                            increase the Budget Limit, reduce the number of
                            recipients, or lower the Gift Card Amount for this
                            campaign.
                          </p>
                        </BlockStack>
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="
                      getCampaignBudgetTracker?.newAllocatedGreaterThanBudgetLimit &&
                      !getCampaignBudgetTracker?.existingAllocatedGreaterThanBudgetLimit
                    "
                  >
                    <div
                      class="tw-flex-1 tw-w-full tw-border-solid tw-border-orange-400 tw-border-2 tw-p-4 tw-rounded-md"
                    >
                      <div class="tw-flex tw-items-top tw-gap-2">
                        <b-icon-exclamation-triangle-fill
                          class="tw-text-orange-400 tw-flex-shrink-0 tw-mt-0.5"
                        ></b-icon-exclamation-triangle-fill>
                        <BlockStack :gap="300">
                          <BlockStack :gap="100">
                            <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                              Projected cost of this campaign exceeds the
                              Available balance. Once the budget limit is
                              reached, any outstanding redemption attempts will
                              be declined.
                            </p>
                            <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                              To ensure all recipients will be able to redeem,
                              increase the Budget Limit, reduce the number of
                              recipients, or lower the Gift Card Amount for this
                              campaign.
                            </p>
                          </BlockStack>
                          <template v-if="!reviewCampaignView">
                            <div
                              class="sm:tw-flex tw-items-top tw-bg-orange-100"
                            >
                              <p
                                class="tw-text-gray-900 sm:tw-w-48 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                              >
                                Projected campaign cost
                              </p>
                              <BlockStack :gap="100" class="tw-grow">
                                <div class="tw-flex tw-items-top tw-gap-2">
                                  <p
                                    class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
                                  >
                                    ${{ this.getCampaignTotalCost }}.00
                                  </p>
                                  <TheTooltip
                                    :toolTip="true"
                                    :tooltipContent="
                                      projectedCampaignCostTooltipContent
                                    "
                                  >
                                    <template #icon>
                                      <div
                                        class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                                      >
                                        <b-icon-exclamation
                                          class="tw-text-black tw-z-0"
                                        ></b-icon-exclamation>
                                      </div>
                                    </template>
                                  </TheTooltip>
                                </div>
                              </BlockStack>
                            </div>
                          </template>
                        </BlockStack>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="getCampaignBudgetTracker?.isBudgetLimitMet">
                  <div
                    class="tw-flex-1 tw-w-full tw-border-solid tw-border-red-600 tw-border-2 tw-p-4 tw-rounded-md"
                  >
                    <div class="tw-flex tw-items-top tw-gap-2">
                      <b-icon-exclamation-triangle-fill
                        class="tw-text-red-600 tw-flex-shrink-0 tw-mt-0.5"
                      ></b-icon-exclamation-triangle-fill>
                      <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                        <span class="tw-font-bold"
                          >Budget Limit has been reached.</span
                        >
                        Please update this budget, select another, or create a
                        new budget.
                      </p>
                    </div>
                  </div>
                </template>
                <template v-if="getCampaignSelectedBudget">
                  <BudgetStats
                    :budget="getCampaignSelectedBudget"
                    :campaignTotalCost="getCampaignTotalCost"
                    :campaignBuilderView="true"
                    @checkForBudgets="refreshBudgetSelection"
                    @updateBudgetLimitTracker="updateBudgetLimitTracker"
                  ></BudgetStats>
                </template>
              </template>
            </BlockStack>
          </template>
          <template v-else>
            <div class="tw-flex tw-gap-2">
              <img
                style="max-height: 100px; width: auto"
                class=""
                src="../../../assets/svg/illustrations-test/32_goal.png"
                alt="Image Description"
              />
              <BlockStack :gap="500">
                <div>
                  <h2
                    class="tw-text-2xl tw-font-bold tw-leading-6 tw-text-gray-900"
                  >
                    {{ "Build your first budget" }}
                  </h2>
                  <p
                    class="tw-text-lg tw-leading-6 tw-text-gray-900 tw-m-0 tw-p-0"
                  >
                    Create a budget to monitor and limit campaign spending over
                    time.
                  </p>
                </div>

                <BlockStack :gap="600">
                  <div>
                    <TheButton
                      @click="toggleNewBudgetModal()"
                      size="small"
                      :hasLeadingIcon="true"
                      variant="primary"
                    >
                      <template #text>Create budget </template>
                      <template #leading-icon
                        ><b-icon-plus-lg></b-icon-plus-lg>
                      </template>
                    </TheButton>
                  </div>
                </BlockStack>
              </BlockStack>
            </div>
          </template>
        </BlockStack>
      </TheBox>
    </TheCard>
  </template>
  <template v-if="reviewCampaignView">
    <template v-if="getCampaignBudgetId">
      <template v-if="!getCampaignBudgetTracker?.isBudgetLimitMet">
        <template
          v-if="
            getCampaignBudgetTracker?.existingAllocatedGreaterThanBudgetLimit
          "
        >
          <div
            class="tw-flex-1 tw-w-full tw-border-solid tw-border-orange-400 tw-border-2 tw-p-4 tw-rounded-md"
          >
            <div class="tw-flex tw-items-top tw-gap-2">
              <b-icon-exclamation-triangle-fill
                class="tw-text-orange-400 tw-flex-shrink-0 tw-mt-0.5"
              ></b-icon-exclamation-triangle-fill>
              <BlockStack :gap="200">
                <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                  Outstanding gifts for this budget exceed the Budget Limit.
                  Once the budget limit is reached, any outstanding redemption
                  attempts will be declined.
                </p>
                <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                  To ensure all recipients will be able to redeem, increase the
                  Budget Limit, reduce the number of recipients, or lower the
                  Gift Card Amount for this campaign.
                </p>
              </BlockStack>
            </div>
          </div>
        </template>
        <template
          v-if="
            getCampaignBudgetTracker?.newAllocatedGreaterThanBudgetLimit &&
            !getCampaignBudgetTracker?.existingAllocatedGreaterThanBudgetLimit
          "
        >
          <div
            class="tw-flex-1 tw-w-full tw-border-solid tw-border-orange-400 tw-border-2 tw-p-4 tw-rounded-md"
          >
            <div class="tw-flex tw-items-top tw-gap-2">
              <b-icon-exclamation-triangle-fill
                class="tw-text-orange-400 tw-flex-shrink-0 tw-mt-0.5"
              ></b-icon-exclamation-triangle-fill>
              <BlockStack :gap="200">
                <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                  Projected cost of this campaign exceeds the Available balance. Once
                  the budget limit is reached, any outstanding redemption
                  attempts will be declined.
                </p>
                <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                  To ensure all recipients will be able to redeem, increase the
                  Budget Limit, reduce the number of recipients, or lower the
                  Gift Card Amount for this campaign.
                </p>
              </BlockStack>
            </div>
          </div>
        </template>
      </template>
      <template v-if="getCampaignBudgetTracker?.isBudgetLimitMet">
        <div
          class="tw-flex-1 tw-w-full tw-border-solid tw-border-red-600 tw-border-2 tw-p-4 tw-rounded-md"
        >
          <div class="tw-flex tw-items-top tw-gap-2">
            <b-icon-exclamation-triangle-fill
              class="tw-text-red-600 tw-flex-shrink-0 tw-mt-0.5"
            ></b-icon-exclamation-triangle-fill>
            <p class="tw-text-sm tw-m-0 tw-text-gray-900">
              <span class="tw-font-bold">Budget Limit has been reached.</span>
              Please update this budget, select another, or create a new budget.
            </p>
          </div>
        </div>
      </template>
      <template v-if="getCampaignSelectedBudget">
        <BudgetStats
          :budget="getCampaignSelectedBudget"
          :campaignTotalCost="getCampaignTotalCost"
          :campaignBuilderView="true"
          @checkForBudgets="refreshBudgetSelection"
          @updateBudgetLimitTracker="updateBudgetLimitTracker"
        ></BudgetStats>
      </template>
    </template>
  </template>
</template>

<script>
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";
import BudgetStats from "../../../components-v2/budgets/BudgetStats.vue";
import TheModal from "../../../components-v2/ui/modals/TheModal.vue";
import NewBudgetForm from "../../../components-v2/ui/modals/NewBudgetForm.vue";
import TheTooltip from "../../../components-v2/ui/forms/TheTooltip.vue";

export default {
  name: "TheBudget",
  emits: ["resetSessionId", "handlePaymentMethodSetup"],
  components: {
    TheTooltip,
    TheModal,
    NewBudgetForm,
    TheButton,
    TheCard,
    TheBox,
    BlockStack,
    BudgetStats,
  },
  props: {
    budgetSessionId: {
      type: String,
    },
    reviewCampaignView: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.handleCreatedLifecycleLogic();
  },
  data() {
    return {
      budgets: [],
      count: 0,
      periods: [
        {
          text: "Monthly - resets the 1st of every month at 12 a.m.",
          value: "0 0 1 * *",
        },
        {
          text: "Quarterly - resets the 1st of every quarter at 12 a.m.",
          value: "0 0 1 */3 *",
        },
        {
          text: "Yearly - resets the 1st of January of the following year at 12 a.m.",
          value: "0 0 1 1 *",
        },
      ],
      budget: {
        name: "",
        limit: 0,
        period: "",
        description: "",
      },
      selectedEmoji: null,
      debounceTimer: null,
      selectedBudgetId: null,
      newBudgetModalIsVisible: false,
    };
  },
  computed: {
    user() {
      return this?.$store?.state?.auth?.user;
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    getCampaignBudgetId() {
      return this.$store.getters["campaigns/getCampaignBudgetId"];
    },
    getCampaignTotalCost() {
      return this.$store.getters["campaigns/getCampaignTotalCost"];
    },
    getCampaignSelectedBudget() {
      let selectedBudgetId = this.getCampaignBudgetId;
      const selectedBudgetFinal = this.findBudgetById(selectedBudgetId);
      return selectedBudgetFinal;
    },
    getCampaignSelectedBudgetLimitIsMet() {
      return this.$store.getters[
        "campaigns/getCampaignSelectedBudgetLimitIsMet"
      ];
    },
    getCampaignBudgetTracker() {
      return this.$store.getters["campaigns/getCampaignBudgetTracker"];
    },
    budgetNameValid() {
      return this.budget.name.trim().length > 0 ? this.budget.name : false;
    },
    budgetName() {
      return this.budget.name.trim();
    },
    budgetDescription() {
      return this?.budget?.description.trim();
    },
    budgetLimit() {
      return parseInt(this?.budget?.limit) || 0;
    },
    budgetPeriod() {
      console.log("this.budget.period", this.budget.period);
      return this.budget.period;
    },
    budgetLimitInPennies() {
      const limit = parseInt(this?.budget?.limit, 10);
      return isNaN(limit) ? 0 : limit * 100;
    },
    budgetLimitInDollars() {
      return (limit) => (limit / 100).toFixed(2);
    },
    getSessionId() {
      return this.budgetSessionId;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },

    /** tooltips */
    projectedCampaignCostTooltipContent() {
      return "The total expense calculated by multiplying the gift card amount by the number of recipients.";
    },
  },
  methods: {
    /** handle listing budgets */
    async checkForBudgets() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      try {
        let requestBody = {};
        let filter = {
          "user._id": { $eq: this.userId },
        };
        const queryParameters = {
          limit: 1000,
          sort: "-_id",
          expand: "user",
        };

        requestBody = {
          filter: filter,
        };
        const listBudgetsRes = await api.listBudgets(
          queryParameters,
          requestBody
        );
        console.log("listBudgetsRes: ", listBudgetsRes);
        this.budgets = listBudgetsRes.data.result.records;
        this.count = listBudgetsRes.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.error("Failed to load budgets:", error);
        // Handle the error appropriately
      } finally {
        this.checkingIfUserHasBudgets = false;
      }
    },

    /** handle payment method setup */
    toggleNewBudgetModal() {
      this.newBudgetModalIsVisible = !this.newBudgetModalIsVisible;
    },
    handlePaymentMethodSetup() {
      this.$emit("handlePaymentMethodSetup");
    },

    /** handle resetting budget session id */
    resetSessionId() {
      this.toggleNewBudgetModal();
      this.$emit("resetSessionId");
    },

    /** handle limit validation */
    validateBudgetLimitValue() {
      const trimmedValue = this.budget.limit.trim();
      const value = parseFloat(trimmedValue);

      // Clear any existing timeout to avoid multiple resets
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }

      // Check if the trimmed value is a valid number and meets the criteria
      if (!Number.isNaN(value) && value >= 5 && Number.isSafeInteger(value)) {
        this.budget.limit = Math.round(value).toString(); // Convert back to string
      } else {
        // Set a timeout to reset the value to "0" after 350 ms
        this.timeoutId = setTimeout(() => {
          this.budget.limit = "0"; // Reset to "0" string
        }, 350);
      }
    },
    handleBudgetSelection(event) {
      const selectedBudgetId = event.target.value;
      const selectedBudget = this.findBudgetById(selectedBudgetId);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BUDGET_ID",
        selectedBudgetId
      );
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BUDGET",
        selectedBudget
      );
      if (!selectedBudgetId) {
        console.log(
          "getCampaignSelectedBudgetLimitIsMet: ",
          this.getCampaignSelectedBudgetLimitIsMet
        );
        this.$store.commit("campaigns/SET_CAMPAIGN_BUILDER_BUDGET", null);
        this.$store.commit("campaigns/SET_CAMPAIGN_BUILDER_BUDGET_ID", null);
      }
      if (this?.getCampaignBudgetTracker?.isBudgetLimitMet) {
        // this.$store.commit(
        //   "campaigns/SET_CAMPAIGN_BUILDER_BUDGET_LIMIT_STATUS",
        //   this.budgetLimitTracker.isBudgetLimitMet
        // );
        this.$store.commit(
          "campaigns/SET_CAMPAIGN_BUILDER_BUDGET_TRACKER",
          null
        );
      }
    },
    async refreshBudgetSelection(budgetId) {
      await this.checkForBudgets();
      console.log("new budget id: ", budgetId);
      const selectedBudgetId = budgetId;
      const selectedBudget = this.findBudgetById(selectedBudgetId);
      console.log("new selected budget: ", selectedBudget);

      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BUDGET_ID",
        selectedBudgetId
      );
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BUDGET",
        selectedBudget
      );
    },
    updateBudgetLimitTracker(budgetLimitTracker) {
      if (budgetLimitTracker) {
        console.log("emitted budgetLimitTracker", budgetLimitTracker);
        // this.$store.commit(
        //   "campaigns/SET_CAMPAIGN_BUILDER_BUDGET_LIMIT_STATUS",
        //   budgetLimitTracker.isBudgetLimitMet
        // );
        this.$store.commit(
          "campaigns/SET_CAMPAIGN_BUILDER_BUDGET_TRACKER",
          budgetLimitTracker
        );
      }
    },
    handleBudgetPeriodSelection(event) {
      let selectedBudgetPeriod = event.target.value;
      console.log("selectedBudgetPeriod", selectedBudgetPeriod);
      console.log("budget.period", this.budget.period);
    },
    findBudgetById(selectedBudgetId) {
      return this.budgets.find((budget) => budget._id === selectedBudgetId);
    },

    /** handle errors */
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      console.error(errorMessage);
    },

    /** handle successful budget creation */
    async handleSuccess(budgetId) {
      await this.checkForBudgets();
      console.log("new budget id: ", budgetId);
      const selectedBudgetId = budgetId;
      const selectedBudget = this.findBudgetById(selectedBudgetId);
      console.log("new selected budget: ", selectedBudget);

      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BUDGET_ID",
        selectedBudgetId
      );
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BUDGET",
        selectedBudget
      );
      this.toggleNewBudgetModal();
      this.$emit("resetSessionId");
    },
    /** other */
    showSelectedEmoji(selectedEmoji) {
      this.selectedEmoji = selectedEmoji;
    },
    handleSessionIdLogic() {
      const sessionId = this.$route.params.CHECKOUT_SESSION_ID;
      console.log("sessionId: ", sessionId);
      console.log("this.budgetSessionId: ", this.budgetSessionId);

      if (sessionId && sessionId !== "cancelled") {
        console.log("sessionId: ", sessionId);
        this.toggleNewBudgetModal();
      }
    },
    handleCreatedLifecycleLogic() {
      this.handleSessionIdLogic();
      this.checkForBudgets();
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
