<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <!-- Page Header -->
      <div class="modal-header border-bottom px-0 pt-0 pb-2">
        <h1 class="modal-title">Create Address</h1>
      </div>

      <div class="modal-body p-0 mt-5">
        <!-- line 1 -->
        <div class="row">
          <div class="col text-start">
            <div class="mb-4">
              <label class="form-label fw-bold" for="addressLine1"
                >Address line 1 (Street address, P.O. box)</label
              >
              <!-- <span
                v-if="!getAddressLine1"
                class="badge bg-soft-info rounded-pill ms-2"
                ><span class="text-danger">required</span>
              </span> -->
              <input
                type="url"
                class="form-control form-control-lg"
                :class="{ 'border-success': getAddressLine1 }"
                name="addressLine1"
                id="addressLine1"
                required
                placeholder="Street address, P.O box"
                aria-label="Street address, P.O box"
                v-model="addressLine1"
              />
            </div>
          </div>
        </div>
        <!-- line 2 -->
        <div class="row">
          <div class="col text-start">
            <div class="mb-4">
              <label class="form-label fw-bold" for="addressLine2"
                >Address line 2 (Apartment, suite, unit, building, floor,
                etc.)</label
              >
              <input
                type="url"
                class="form-control form-control-lg"
                :class="{ 'border-success': getAddressLine2 }"
                name="addressLine2"
                id="addressLine2"
                required
                placeholder="Street address, P.O box"
                aria-label="Street address, P.O box"
                v-model="addressLine2"
              />
            </div>
          </div>
        </div>
        <!-- city/state -->
        <div class="row">
          <div class="col-lg-6 text-start">
            <div class="mb-4">
              <label class="form-label fw-bold" for="city">City</label>
              <span v-if="!getCity" class="badge bg-soft-info rounded-pill ms-2"
                ><span class="text-danger">required</span>
              </span>
              <input
                type="url"
                class="form-control form-control-lg"
                :class="{ 'border-success': getCity }"
                name="city"
                id="city"
                required
                placeholder="St. Louis"
                aria-label="St. Louis"
                v-model="address.city"
              />
            </div>
          </div>
          <div class="col-lg-6 text-start">
            <div class="mb-4">
              <label class="form-label fw-bold" for="stateSelect">State</label>
              <span
                v-if="!getState"
                class="badge bg-soft-info rounded-pill ms-2"
                ><span class="text-danger">required</span>
              </span>
              <select
                class="form-select text-dark"
                :class="{ 'border-success': getState }"
                required
                v-model="address.state"
                name="stateSelect"
                id="stateSelect"
              >
                <option value="" class="" disabled>Select state</option>
                <option
                  v-for="option in stateOptions"
                  :value="option.value"
                  :key="option.index"
                >
                  {{ option.text }} ({{ option.value }})
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- postal code/country -->
        <div class="row">
          <div class="col-lg-6 text-start">
            <div class="mb-4">
              <label class="form-label fw-bold" for="postalCode"
                >Postal Code</label
              >
              <!-- <span
                v-if="!getPostalCode"
                class="badge bg-soft-info rounded-pill ms-2"
                ><span class="text-danger">required</span>
              </span> -->
              <input
                type="url"
                class="form-control form-control-lg"
                :class="{ 'border-success': getPostalCode }"
                name="postalCode"
                id="postalCode"
                required
                placeholder="63139"
                aria-label="63139"
                v-model="address.postalCode"
              />
            </div>
          </div>
          <div class="col-lg-6 text-start">
            <div class="mb-4">
              <div class="tom-select-custom">
                <label class="form-label fw-bold" for="countrySelect"
                  >Country</label
                >
                <!-- <span
                  v-if="!getCountry"
                  class="badge bg-soft-info rounded-pill ms-2"
                  ><span class="text-danger">required</span>
                </span> -->
                <input
                  :readonly="true"
                  type="url"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6"
                  name="country"
                  id="country"
                  required
                  placeholder="country"
                  v-model="address.country"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start px-0">
        <!-- submit button -->
        <div class="d-flex flex-column align-items-start w-100 my-2">
          <p class="text-danger" v-if="!checkIfFormIsComplete">
            Form is incomplete. Please fix invalid or missing information.
          </p>
          <div class="d-flex gap-2" v-if="!addressIsSaving && !isSuccessful">
            <button
              :disabled="!checkIfFormIsComplete"
              type="button"
              class="btn btn-primary"
              @click.prevent="createSingleVendorAddress()"
            >
              Save address
            </button>
            <button
              type="button"
              class="btn btn-soft-secondary"
              @click.prevent="$emit('cancel')"
            >
              Cancel
            </button>
          </div>
          <button
            class="btn btn-primary"
            type="button"
            disabled
            v-if="addressIsSaving"
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Saving address...
          </button>
          <button
            class="btn btn-primary"
            type="button"
            disabled
            v-if="isSuccessful"
          >
            <span class="text-success"
              ><b-icon-check-circle-fill></b-icon-check-circle-fill>
            </span>
            Success!
          </button>
        </div>
      </div>
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "CreateAddressModal",
  components: {
    FixedModalSmall,
  },
  data() {
    return {
      addressList: [],
      addressIsSaving: false,
      isSuccessful: false,
      debounceTimer: null,
      doesVendorExist: false,
      address: {
        city: "",
        state: "",
        postalCode: "",
        country: "US",
      },
      addressLine1: "",
      addressLine2: "",
      stateOptions: [
        { text: "Alabama", value: "AL" },
        { text: "Alaska", value: "AK" },
        { text: "Arizona", value: "AZ" },
        { text: "Arkansas", value: "AR" },
        { text: "California", value: "CA" },
        { text: "Colorado", value: "CO" },
        { text: "Connecticut", value: "CT" },
        { text: "Delaware", value: "DE" },
        { text: "Florida", value: "FL" },
        { text: "Georgia", value: "GA" },
        { text: "Hawaii", value: "HI" },
        { text: "Idaho", value: "ID" },
        { text: "Illinois", value: "IL" },
        { text: "Indiana", value: "IN" },
        { text: "Iowa", value: "IA" },
        { text: "Kansas", value: "KS" },
        { text: "Kentucky", value: "KY" },
        { text: "Louisiana", value: "LA" },
        { text: "Maine", value: "ME" },
        { text: "Maryland", value: "MD" },
        { text: "Massachusetts", value: "MA" },
        { text: "Michigan", value: "MI" },
        { text: "Minnesota", value: "MN" },
        { text: "Mississippi", value: "MS" },
        { text: "Missouri", value: "MO" },
        { text: "Montana", value: "MT" },
        { text: "Nebraska", value: "NE" },
        { text: "Nevada", value: "NV" },
        { text: "New Hampshire", value: "NH" },
        { text: "New Jersey", value: "NJ" },
        { text: "New Mexico", value: "NM" },
        { text: "New York", value: "NY" },
        { text: "North Carolina", value: "NC" },
        { text: "North Dakota", value: "ND" },
        { text: "Ohio", value: "OH" },
        { text: "Oklahoma", value: "OK" },
        { text: "Oregon", value: "OR" },
        { text: "Pennsylvania", value: "PA" },
        { text: "Rhode Island", value: "RI" },
        { text: "South Carolina", value: "SC" },
        { text: "South Dakota", value: "SD" },
        { text: "Tennessee", value: "TN" },
        { text: "Texas", value: "TX" },
        { text: "Utah", value: "UT" },
        { text: "Vermont", value: "VT" },
        { text: "Virginia", value: "VA" },
        { text: "Washington", value: "WA" },
        { text: "West Virginia", value: "WV" },
        { text: "Wisconsin", value: "WI" },
        { text: "Wyoming", value: "WY" },
      ],
    };
  },
  computed: {
    checkIfFormIsComplete() {
      if (
        // !this.getAddressLine1 ||
        !this.getCity ||
        !this.getState
        // ||
        // !this.getPostalCode ||
        // !this.getCountry
      ) {
        return false;
      }
      return true;
    },
    getAddressLine1() {
      return this?.addressLine1;
    },
    getAddressLine2() {
      return this?.addressLine2;
    },
    getCity() {
      return this?.address?.city;
    },
    getState() {
      return this?.address?.state;
    },
    getPostalCode() {
      return this?.address?.postalCode;
    },
    getCountry() {
      return this?.address?.country;
    },
    vendorClass() {
      return {
        "is-invalid": !this.getVendorName,
        "is-valid": this.getVendorName,
      };
    },
  },
  methods: {
    async createSingleVendorAddress() {
      try {
        // Get the Bold client
        const api = await this.getBoldClient();

        // Prepare address lines
        const addressLines = [];
        if (this.addressLine1) {
          addressLines.push(this.addressLine1);
        }
        if (this.addressLine2) {
          addressLines.push(this.addressLine2);
        }

        // Create an address object
        let newAddress = {
          city: this.address.city,
          state: this.address.state,
          postalCode: this.address.postalCode,
          country: this.address.country,
        };

        // Conditionally add the 'lines' property
        if (addressLines.length > 0) {
          newAddress.lines = addressLines;
        }

        // Create the address using the API
        const response = await api.createAddresses(null, [newAddress]);

        // Handle a successful response
        this.isSuccessful = true;
        console.log("address created", response);

        const address = response.data.result.records[0];
        this.$emit("setNewAddress", address);
      } catch (error) {
        console.error("Error creating address", error);

        // Handle the error as needed
        console.log("address was NOT created");
      }
    },
  },
};
</script>

<style scoped>
#upload-region {
  width: 100%;
  background-color: #f8fafd;
  border: 0.125rem dashed rgba(231, 234, 243, 0.7);
  border-radius: 0.5rem;
  padding: 3rem 3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

#upload-region.dragover {
  background-color: #f0f0f0;
}
</style>
