import singleton from "@/singleton";
// import axios from "axios";

export default {
  activateQuickEdit({ commit }) {
    commit("setQuickEditActive", true);
  },
  deactivateQuickEdit({ commit }) {
    commit("setQuickEditActive", false);
  },


  async startLinkingOffice365() {
    const api = await singleton.boldClient;

    const redirectUri = {
      redirectUri: window.location.origin + `/integrations/office365`,
    };

    const response = await api.startLinkingOffice365(redirectUri);
    return response.data;
  },
  async finishLinkingOffice365(code) {
    const api = await singleton.boldClient;

    console.log("serializer = ", api.defaults.paramsSerializer);
    const redirectUri = {
      redirectUri: window.location.origin + `/integrations/office365`,
      code: code,
    };

    const response = await api.finishLinkingOffice365(redirectUri);
    return response.data;
  },
  async startLinkingSalesforce() {
    const api = await singleton.boldClient;

    const redirectUri = {
      redirectUri: window.location.origin + `/integrations/salesforce`,
    };

    const response = await api.startLinkingSalesforce(redirectUri);
    return response.data; // Directly return the data
  },
  async finishLinkingSalesforce(code) {
    const api = await singleton.boldClient;

    const redirectUri = {
      redirectUri: window.location.origin + `/integrations/salesforce`,
      code: code,
    };

    const response = await api.finishLinkingSalesforce(redirectUri);
    return response.data;
  },
  async fetchSalesforceContacts(queryOptions) {
    const api = await singleton.boldClient;

    const response = await api.fetchSalesforceContacts(queryOptions);
    return response;
  },
};
