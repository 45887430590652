<template>
  <ThePage customWidthClass="tw-w-full tw-max-w-5xl tw-mx-auto">
    <template v-if="campaignsAreLoading">
      <CampaignDetailsPageSkeleton></CampaignDetailsPageSkeleton>
    </template>
    <template v-else-if="hasData">
      <BlockStack :gap="800" :fullWidth="true">
        <PageSection title="Overview">
          <MultiRecipientCampaignEngagementStats
            :totalCampaignsSentCount="getTotalCampaignsSentCount"
            :totalCampaignsClickedCount="getTotalCampaignsClickedCount"
            :totalCampaignsRedeemedCount="getTotalCampaignsRedeemedCount"
            :totalRecipientsCount="getTotalRecipientsCount"
            :totalSpendCount="getTotalSpendCount"
            :campaignLink="uniqueCampaignLink"
          ></MultiRecipientCampaignEngagementStats>
        </PageSection>
        <PageSection
          class="tw-w-full"
          :showActions="this?.campaign?.recipients?.length > 0"
        >
          <TheCard :fullWidth="true">
            <CardBody>
              <TheBox :withPadding="true">
                <BlockStack :gap="800">
                  <template v-if="this?.campaign?.recipients">
                    <BlockStack :gap="300">
                      <div
                        class="tw-flex tw-gap-2 tw-flex-wrap tw-justify-between"
                      >
                        <div class="">
                          <h2
                            class="tw-text-lg tw-font-semibold tw-text-gray-900 tw-m-0"
                          >
                            Use this unique gift link—add it to the email you
                            send your prospect or client.
                          </h2>
                          <p
                            class="tw-m-0 tw-text-lg tw-bg-indigo-50 tw-inline-block"
                          >
                            {{ uniqueCampaignLink }}
                          </p>
                        </div>
                        <template v-if="linkCopied">
                          <div class="d-flex align-items-center gap-2">
                            <img
                              style="max-height: 30px; width: auto"
                              src="../../assets/svg/illustrations-test/78_origami.png"
                              alt="Image Description"
                            />
                            <span class="text-bold-xchange-blue fw-bold">
                              Link copied!
                            </span>
                          </div>
                        </template>

                        <template v-else>
                          <TheButton
                            type="button"
                            variant="secondary"
                            size="regular"
                            @click.stop="copyLink()"
                          >
                            <template #text>Copy link</template>
                          </TheButton>
                        </template>
                      </div>
                    </BlockStack>
                  </template>
                </BlockStack>
              </TheBox>
            </CardBody>
          </TheCard>
        </PageSection>
        <PageSection
          title="Campaign Details"
          class="tw-w-full"
          :showActions="this?.campaign?.recipients?.length > 0"
        >
          <div
            class="tw-flex gap-2 tw-w-full"
            :class="{ 'tw-flex-col': campaignType === 'email' }"
          >
            <TheCard :fullWidth="true">
              <CardBody>
                <TheBox :withPadding="true">
                  <BlockStack :gap="800">
                    <template v-if="this?.campaign?.recipients">
                      <BlockStack :gap="300">
                        <h2
                          class="tw-text-lg tw-font-semibold tw-text-gray-900 tw-m-0"
                        >
                          Recipients
                        </h2>
                        <BlockStack :gap="200">
                          <template v-if="this?.campaign?.recipients">
                            <CampaignRecipientsSelection
                              :campaignRecipients="this?.recipients"
                              :readOnlyView="true"
                              :campaignDetailsView="true"
                              :campaignView="false"
                              :isMyRecord="isMyRecord"
                            >
                            </CampaignRecipientsSelection>
                          </template>
                        </BlockStack>
                      </BlockStack>
                    </template>
                    <template v-if="this?.campaign?.metadata">
                      <BlockStack :gap="300">
                        <h2
                          class="tw-text-lg tw-font-semibold tw-text-gray-900 tw-m-0"
                        >
                          Gift card
                        </h2>
                        <BlockStack :gap="200">
                          <template v-if="this?.campaign?.metadata">
                            <div class="sm:tw-flex tw-items-center">
                              <h4
                                class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                              >
                                Card amount
                              </h4>
                              <div class="tw-flex tw-items-center tw-gap-2">
                                <p
                                  class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
                                >
                                  ${{ this.giftCardAmount }}.00
                                </p>
                                <TheTooltip
                                  :toolTip="true"
                                  :tooltipContent="giftCardAmountTooltipContent"
                                >
                                  <template #icon>
                                    <div
                                      class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                                    >
                                      <b-icon-exclamation
                                        class="tw-text-black tw-z-0"
                                      ></b-icon-exclamation>
                                    </div>
                                  </template>
                                </TheTooltip>
                              </div>
                            </div>
                            <div class="sm:tw-flex tw-items-center">
                              <h4
                                class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                              >
                                Gift card selection
                              </h4>
                              <div class="tw-flex tw-items-center tw-gap-2">
                                <p
                                  class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
                                >
                                  {{
                                    this.isCustomGiftCardSelection
                                      ? "Custom selection"
                                      : "Recipients choice"
                                  }}
                                </p>
                                <TheTooltip
                                  :toolTip="true"
                                  :tooltipContent="
                                    giftCardSelectionTooltipContent
                                  "
                                >
                                  <template #icon>
                                    <div
                                      class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                                    >
                                      <b-icon-exclamation
                                        class="tw-text-black tw-z-0"
                                      ></b-icon-exclamation>
                                    </div>
                                  </template>
                                </TheTooltip>
                              </div>
                            </div>
                            <div class="sm:tw-flex tw-items-center">
                              <h4
                                class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                              >
                                Send mode
                              </h4>
                              <div class="tw-flex tw-items-center tw-gap-2">
                                <p
                                  class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0 tw-capitalize"
                                >
                                  {{ this.campaignType }}
                                </p>
                                <TheTooltip
                                  :toolTip="true"
                                  :tooltipContent="sendModeTooltipContent"
                                >
                                  <template #icon>
                                    <div
                                      class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                                    >
                                      <b-icon-exclamation
                                        class="tw-text-black tw-z-0"
                                      ></b-icon-exclamation>
                                    </div>
                                  </template>
                                </TheTooltip>
                              </div>
                            </div>
                            <div class="sm:tw-flex tw-items-start">
                              <h4
                                class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                              >
                                Campaign terms
                              </h4>
                              <template v-if="campaign?.booking?.required">
                                <BlockStack :gap="200">
                                  <p
                                    class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-left tw-m-0"
                                  >
                                    Recipients will be required to accept a
                                    calendar invite in order to redeem their
                                    gift.
                                  </p>
                                  <BlockStack :gap="300" class="tw-grow">
                                    <BlockStack :gap="100" class="tw-grow">
                                      <label
                                        for="bookingSummary"
                                        class="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                                        >Meeting title</label
                                      >
                                      <input
                                        v-model="campaign.booking.summary"
                                        type="text"
                                        name="bookingSummary"
                                        id="bookingSummary"
                                        class="tw-block read-only:tw-bg-gray-100 tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                                        readOnly
                                      />
                                    </BlockStack>
                                    <BlockStack :gap="100" class="tw-grow">
                                      <label
                                        for="bookingDescription"
                                        class="tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                                        >Meeting summary</label
                                      >
                                      <textarea
                                        rows="3"
                                        v-model="campaign.booking.description"
                                        name="bookingDescription"
                                        id="bookingDescription"
                                        class="tw-block read-only:tw-bg-gray-100 tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-sm"
                                        readOnly
                                      ></textarea>
                                    </BlockStack>
                                  </BlockStack>
                                </BlockStack>
                              </template>
                              <template v-else>
                                <p
                                  class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-left tw-m-0"
                                >
                                  Recipients are NOT required to accept a
                                  calendar invite in order to redeem their gift.
                                </p>
                              </template>
                            </div>
                          </template>
                        </BlockStack>
                      </BlockStack>
                    </template>

                    <template v-if="campaignType === 'email'">
                      <BlockStack :gap="300">
                        <h2
                          class="tw-text-lg tw-font-semibold tw-text-gray-900 tw-m-0"
                        >
                          Email
                        </h2>
                        <BlockStack :gap="200">
                          <input
                            v-model="campaign.subject"
                            type="text"
                            name="campaignSubject"
                            id="campaignSubject"
                            class="tw-block read-only:tw-bg-gray-100 tw-w-96 tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                            placeholder=""
                            readonly
                          />
                          <TheCard :fullWidth="true">
                            <CardBody>
                              <TheBox :withPadding="false">
                                <table
                                  id="main_table"
                                  style="
                                    border-collapse: collapse;
                                    text-align: center;
                                    background-color: #fff;
                                    width: 100%;
                                    border: 1px solid #efeeea;
                                  "
                                >
                                  <tr>
                                    <td
                                      id="email_from_container"
                                      style="
                                        padding: 24px;
                                        padding-bottom: 0px;
                                        text-align: left;
                                        background-color: #fff;
                                      "
                                    >
                                      <p style="margin: 0; color: rgb(0, 0, 0)">
                                        {{ campaignFromName }} sent you an
                                        <span style="font-weight: bold"
                                          >STL eGift card</span
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      id="email_text_container"
                                      style="
                                        padding: 24px;
                                        text-align: left;
                                        background-color: #fff;
                                        min-height: 200px;
                                      "
                                    >
                                      <template v-if="campaignText">
                                        <div
                                          style="min-height: 150px"
                                          v-html="generatedHtml"
                                        ></div>
                                      </template>
                                      <template v-else>
                                        <div
                                          class="d-flex align-items-center gap-2"
                                        >
                                          <img
                                            style="
                                              max-height: 100px;
                                              width: auto;
                                            "
                                            src="../../assets/svg/illustrations-test/75_pencil.png"
                                            alt="Image Description"
                                          />

                                          <div class="text-start">
                                            <p class="fs-4 mb-0">
                                              Your message will show up here.
                                            </p>
                                          </div>
                                        </div>
                                      </template>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      id="email_accept_container"
                                      style="
                                        padding: 24px;
                                        background-color: #f6f6f4;
                                      "
                                    >
                                      <table
                                        id="nested_table_1"
                                        style="
                                          width: 100%;
                                          margin: 0 auto;
                                          border-collapse: collapse;
                                        "
                                      >
                                        <tr>
                                          <td
                                            id="nested_table_1_td_1"
                                            style="
                                              text-align: left;
                                              padding-right: 5px;
                                              vertical-align: middle;
                                              height: 100%;
                                              max-width: 70%;
                                            "
                                          >
                                            <p
                                              style="
                                                margin: 0;
                                                color: rgb(0, 0, 0);
                                                font-size: 16px;
                                              "
                                            >
                                              Powered by
                                              <span style="font-weight: bold"
                                                >Bold Xchange</span
                                              >
                                            </p>
                                            <p
                                              style="
                                                margin: 5px 0 0;
                                                color: rgb(0, 0, 0);
                                              "
                                            >
                                              Enjoy an eGift card marketplace
                                              featuring many of St. Louis’ best
                                              small businesses.
                                            </p>
                                          </td>
                                          <td
                                            id="nested_table_1_td_2"
                                            style="
                                              text-align: center;
                                              padding: 0;
                                              height: 100%;
                                              width: 30%;
                                            "
                                          >
                                            <span
                                              style="
                                                text-align: center;
                                                background-color: #004aad;
                                                border-radius: 6px;
                                                color: #ffcc48;
                                                font-weight: bold;
                                                font-style: normal;
                                                padding: 16px 24px;
                                                font-size: 16px;
                                                text-decoration: none;
                                                display: inline-block;
                                                width: auto;
                                              "
                                              >Accept</span
                                            >
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      id="email_contact_container"
                                      style="
                                        padding: 24px;
                                        background-color: #fff;
                                      "
                                    >
                                      <table
                                        id="nested_table_2"
                                        style="
                                          width: 100%;
                                          margin: 0 auto;
                                          border-collapse: collapse;
                                        "
                                      >
                                        <tr>
                                          <td>
                                            <a
                                              href="https://boldxchange.com"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              style="color: rgb(0, 0, 0)"
                                            >
                                              <img
                                                alt="Gifting Powered by Bold Xchange"
                                                title="Bold Xchange"
                                                src="https://storage.googleapis.com/boldxchange-public/Bold_Xchange_PrimaryLogo_Black_Email.png"
                                                style="
                                                  width: 175px;
                                                  height: auto;
                                                "
                                              />
                                            </a>
                                            <p
                                              style="
                                                margin: 10px 0 0;
                                                color: rgb(0, 0, 0);
                                              "
                                            >
                                              Bold Xchange P.O Box 771576 St.
                                              Louis, MO 63177
                                            </p>
                                            <p
                                              style="
                                                margin: 0;
                                                margin-bottom: 0;
                                                color: rgb(0, 0, 0);
                                              "
                                            >
                                              <a
                                                style="
                                                  text-decoration: underline;
                                                  color: rgb(7, 7, 7);
                                                "
                                                href="mailto:gifts@boldxchange.com"
                                                >gifts@boldxchange.com</a
                                              >
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      id="email_copyright_container"
                                      style="
                                        padding: 24px;
                                        text-align: center;
                                        background-color: #fff;
                                      "
                                    >
                                      <p
                                        style="
                                          text-align: center;
                                          background-color: #fff;
                                          margin: 0;
                                          color: rgb(7, 7, 7);
                                        "
                                      >
                                        <em>
                                          Copyright (C) 2024 Bold Xchange,
                                          Inc.</em
                                        >
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </TheBox>
                            </CardBody>
                          </TheCard>
                        </BlockStack>
                      </BlockStack>
                    </template>
                  </BlockStack>
                </TheBox>
              </CardBody>
            </TheCard>
          </div>
        </PageSection>
      </BlockStack>
    </template>
    <template v-else>
      <PageDataFailedToLoad emptyState="campaignDetails"></PageDataFailedToLoad>
    </template>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardBody from "../../components-v2/ui/layout/CardBody.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import CampaignDetailsPageSkeleton from "../../components-v2/ui/skeletons/page-skeletons/CampaignDetailsPageSkeleton.vue";
import PageDataFailedToLoad from "../../components-v2/ui/feedback/PageDataFailedToLoad.vue";
import MultiRecipientCampaignEngagementStats from "../../components-v2/campaigns/MultiRecipientCampaignEngagementStats.vue";
import CampaignRecipientsSelection from "../../components-v2/campaigns/CampaignRecipientsSelection";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";

import {
  campaignRecipientsEngagement,
  campaignEngagementStats,
} from "@/services/campaignService";

export default {
  name: "CampaignDetails",
  components: {
    PageDataFailedToLoad,
    TheButton,
    ThePage,
    PageSection,
    CampaignRecipientsSelection,
    CampaignDetailsPageSkeleton,
    MultiRecipientCampaignEngagementStats,
    BlockStack,
    TheBox,
    CardBody,
    TheCard,
    TheTooltip,
  },
  data() {
    return {
      campaign: null,
      engagementStats: {},
      recipients: [],
      totalSpendCount: "1",
      noDataPlaceholderText: "--",
      linkCopied: false,
      campaignsAreLoading: false,
      isMyRecord: true
    };
  },
  async created() {
    const campaign = await this.loadCampaignDetails();
    this.campaign = campaign;
    this.isMyRecord = campaign?.user === this?.$store?.state?.auth?.user?.id;
    const recipients = campaignRecipientsEngagement(this.campaign);
    this.recipients = recipients;
    this.engagementStats = campaignEngagementStats(recipients);
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    /** campaign */
    hasData() {
      return !this.campaignsAreLoading && this.campaign !== null;
    },
    campaignName() {
      return this?.campaign?.metadata?.campaign_name || this?.campaign?.subject;
    },
    giftCardAmount() {
      return parseInt(this?.campaign?.metadata?.gift_card_amount) || 0;
    },
    doesRedeemedPropertyExist() {
      return this?.campaign?.metadata.isRedeemed !== undefined;
    },
    isRedeemedStatus() {
      return (
        (this?.campaign?.metadata?.isRedeemed !== undefined &&
          this?.campaign?.metadata?.isRedeemed) ||
        this?.campaign?.metadata?.campaign_status === "redeemed"
      );
    },
    wasLinkClicked() {
      return this?.campaign?.seen;
    },
    uniqueCampaignLink() {
      return `${window.location.origin}/gift/${this?.campaign?._id}`;
    },
    campaignType() {
      return this?.campaign?.metadata?.campaign_type;
    },
    formattedEmailContent() {
      return this?.campaign?.text.split("\n");
    },
    campaignSubject() {
      return this?.campaign?.subject;
    },

    /** recipients */
    recipientsCount() {
      return this?.campaign?.recipients?.length || 0;
    },
    formattedDate() {
      return this.getStamp();
    },

    // tooltip content
    recipientsSectionTooltipContent() {
      return "Currently, multi-recipient campaigns can only be sent using a shareable link.";
    },
    giftCardSelectionTooltipContent() {
      return this.isCustomGiftCardSelection
        ? "Recipients first see your curated list of e-gift cards, but they can always explore and choose any card from the Bold collection."
        : "Recipients get to explore and pick any e-gift card from the Bold collection.";
    },
    giftCardAmountTooltipContent() {
      return "The cash value of the e-gift card that recipients can redeem.";
    },
    sendModeTooltipContent() {
      return this.campaignType === "link"
        ? "You shared your campaign with your recipients using a unique link."
        : "You sent your campaign through our email builder, complete with an e-gift card link for easy recipient engagement.";
    },

    // stats
    getTotalRecipientsCount() {
      return this?.campaign?.recipients?.length;
    },
    getTotalCampaignsClickedCount() {
      return this?.engagementStats?.totalCampaignsClickedCount;
    },
    getTotalCampaignsRedeemedCount() {
      return this?.engagementStats?.totalCampaignsRedeemedCount;
    },
    getTotalCampaignsSentCount() {
      return this?.engagementStats?.totalCampaignsSentCount;
    },
    getTotalSpendCount() {
      return (
        this?.engagementStats?.totalCampaignsRedeemedCount *
        this?.campaign?.metadata?.gift_card_amount
      );
    },
    //email content
    campaignFromName() {
      return this?.campaign?.from?.name;
    },
    campaignText() {
      return this?.campaign?.text?.trim()?.length > 0
        ? this?.campaign?.text?.trim()
        : "";
    },
    campaignHtml() {
      return this.getEmailHtml();
    },
    generatedHtml() {
      // Split the input message into lines
      const lines = this.campaign.text.split("\n");

      // Generate HTML code with styling
      let htmlCode = "";
      let consecutiveEmptyLines = 0;

      for (let index = 0; index < lines.length; index++) {
        const line = lines[index].trim();

        if (line !== "") {
          if (consecutiveEmptyLines > 0) {
            // Add <br/> for consecutive empty lines
            for (let i = 0; i < consecutiveEmptyLines; i++) {
              htmlCode += "<br/>";
            }
            consecutiveEmptyLines = 0; // Reset consecutive empty lines count
          }

          htmlCode += `<p style="margin: 0;color: rgb(0, 0, 0);">${line}</p>`;
        } else {
          consecutiveEmptyLines++;
        }
      }

      return `${htmlCode}`;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async loadCampaignDetails() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.campaignsAreLoading = true;
      const id = this.$route.params.campaignId;

      // filters
      let requestBody = {
        _id: id,
      };
      // let selection = "from  subject seen text html metadata";

      const queryParameters = {
        limit: 1,
        expand: "recipients contact invoice",
      };

      try {
        const response = await api.listMailCampaigns(queryParameters, {
          filter: requestBody,
          // selection: selection,
        });

        return response.data.result.records[0];
      } catch (error) {
        console.log("campaigns did not load", error);
      } finally {
        this.campaignsAreLoading = false;
        this.campaign = null;
      }
    },
    async copyLink() {
      const linkToCopy = this.uniqueCampaignLink;
      await navigator.clipboard.writeText(linkToCopy);
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 2000);
    },
    getStamp() {
      // original date
      const objectIdToTimestamp = require("objectid-to-timestamp");
      const creationTimestamp = objectIdToTimestamp(this.campaign.id);
      const originalTimestampSeconds = Math.floor(creationTimestamp / 1000);
      // today
      const dataRightNow = new Date();
      const timeStampRightNow = dataRightNow.getTime();
      const newTimeStampSeconds = Math.floor(timeStampRightNow / 1000);
      // compare
      const difference = newTimeStampSeconds - originalTimestampSeconds;
      let output = ``;
      if (difference < 60) {
        // Less than a minute has passed:
        output = `${difference} seconds ago`;
      } else if (difference < 3600) {
        // Less than an hour has passed:
        output = `${Math.floor(difference / 60)} minutes ago`;
      } else if (difference < 86400) {
        // Less than a day has passed:
        output = `${Math.floor(difference / 3600)} hours ago`;
      } else if (difference < 2620800) {
        // Less than a month has passed:
        output = `${Math.floor(difference / 86400)} days ago`;
      } else if (difference < 31449600) {
        // Less than a year has passed:
        output = `${Math.floor(difference / 2620800)} months ago`;
      } else {
        // More than a year has passed:
        output = `${Math.floor(difference / 31449600)} years ago`;
      }

      return output;
    },

    /** handle generating html */
    getEmailHtml() {
      return this.generatedHtml
        ? `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Email Template</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        color: #241c15;
      }

      table {
        border-collapse: collapse;
      }
    </style>
  </head>
  <body style="font-family: Arial, sans-serif; color: rgb(0, 0, 0)">
    <table
      id="main_table"
      style="
        border-collapse: collapse;
        text-align: center;
        background-color: #fff;
        max-width: 660px;
        width: 100%;
        border: 1px solid #efeeea;
      "
    >
      <tr>
        <td
          id="email_from_container"
          style="
            padding: 24px;
            padding-bottom: 0px;
            text-align: left;
            background-color: #fff;
          "
        >
          <p style="margin: 0; color: rgb(0, 0, 0)">
            ${this.campaignFromName} sent you an
            <span style="font-weight: bold">STL eGift card</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          id="email_text_container"
          style="
            padding: 24px;
            text-align: left;
            background-color: #fff;
            height: 200px;
            vertical-align: top;
          "
        >
          ${this.generatedHtml}
        </td>
      </tr>
      <tr>
        <td
          id="email_accept_container"
          style="padding: 24px; background-color: #f6f6f4"
        >
          <table
            id="nested_table_1"
            style="width: 100%; margin: 0 auto; border-collapse: collapse"
          >
            <tr>
              <td
                id="nested_table_1_td_1"
                style="
                  text-align: left;
                  padding-right: 5px;
                  vertical-align: middle;
                  height: 100%;
                  max-width: 70%;
                "
              >
                <p style="margin: 0; color: rgb(0, 0, 0); font-size: 16px">
                  Powered by
                  <span style="font-weight: bold">Bold Xchange</span>
                </p>
                <p style="margin: 5px 0 0; color: rgb(0, 0, 0)">
                  Enjoy an eGift card marketplace featuring many of St. Louis’
                  best small businesses.
                </p>
              </td>
              <td
                id="nested_table_1_td_2"
                style="text-align: center; padding: 0; height: 100%; width: 30%"
              >
                <a
                  href="${this.campaignGiftUrl}"
                  target="_blank"
                  style="
                    text-align: center;
                    background-color: #004aad;
                    border-radius: 6px;
                    color: #ffcc48;
                    font-weight: bold;
                    font-style: normal;
                    padding: 16px 24px;
                    font-size: 16px;
                    text-decoration: none;
                    display: inline-block;
                    width: auto;
                  "
                  >Accept</a
                >
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td
          id="email_contact_container"
          style="padding: 24px; background-color: #fff"
        >
          <table
            id="nested_table_2"
            style="width: 100%; margin: 0 auto; border-collapse: collapse"
          >
            <tr>
              <td>
                <a
                  href="https://boldxchange.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: rgb(0, 0, 0)"
                >
                  <img
                    alt="Gifting Powered by Bold Xchange"
                    title="Bold Xchange"
                    src="https://storage.googleapis.com/boldxchange-public/Bold_Xchange_PrimaryLogo_Black_Email.png"
                    style="width: 175px; height: auto"
                  />
                </a>
                <p style="margin: 10px 0 0; color: rgb(0, 0, 0)">
                  Bold Xchange P.O Box 771576 St. Louis, MO 63177
                </p>
                <p style="margin: 0; margin-bottom: 0; color: rgb(0, 0, 0)">
                  <a
                    style="text-decoration: underline; color: rgb(7, 7, 7)"
                    href="mailto:gifts@boldxchange.com"
                    >gifts@boldxchange.com</a
                  >
                </p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td
          id="email_copyright_container"
          style="padding: 24px; text-align: center; background-color: #fff"
        >
          <p
            style="
              text-align: center;
              background-color: #fff;
              margin: 0;
              color: rgb(7, 7, 7);
            "
          >
            <em> Copyright (C) 2024 Bold Xchange, Inc.</em>
          </p>
        </td>
      </tr>
    </table>
  </body>
</html>
`
        : "";
    },

    /** handle error */
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.checkingIfUserHasCampaigns = false;
      this.campaignsAreLoading = false;
      this.campaignIsSaving = false;
      this.reloadList();
      console.error(errorMessage);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
