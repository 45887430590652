<template>
  <div class="tw-flex tw-flex-col">
    <div class="tw-bg-blue-800 tw-p-5 tw-py-8">
      <BlockStack :gap="100" class="tw-align-middle tw-justify-center">
        <h1
          class="tw-m-0 tw-text-2xl tw-font-bold tw-text-white sm:tw-text-3xl sm:tw-tracking-tight"
        >
          Choose your journey
        </h1>
      </BlockStack>
    </div>
    <div class="tw-p-5 tw-pt-10">
      <BlockStack :gap="400" class="tw-align-middle tw-justify-center">
        <BlockStack :gap="200" class="tw-align-middle tw-justify-center">
          <p class="tw-m-0 tw-text-lg tw-text-gray-700">Question 1 of 2</p>
          <h2
            class="tw-m-0 tw-text-lg tw-font-bold sm:tw-text-xl sm:tw-tracking-tight"
          >
            How would you like to get started?
          </h2>
        </BlockStack>
        <div
          class="tw-flex tw-flex-col tw-gap-4 tw-justify-start tw-text-start"
        >
          <div class="tw-max-w-6xl">
            <div
              class="tw-flex tw-flex-wrap tw-justify-center tw-gap-3 tw-w-full"
            >
              <label
                class="tw-cursor-pointer tw-w-full"
                for="questionTwoOptionOne"
              >
                <input
                  type="radio"
                  class="tw-peer tw-sr-only"
                  name="accountSetupQuestionTwo"
                  id="questionTwoOptionOne"
                  value="Take a tour"
                  v-model="accountSetupQuestionTwoChoice"
                />
                <div
                  class="tw-rounded-md tw-bg-gray-100 tw-p-5 tw-text-gray-600 tw-ring-2 tw-ring-transparent tw-transition-all tw-shadow tw-shadow-gray-300 hover:tw-shadow-none hover:tw-bg-gray-200 peer-checked:tw-text-blue-600 peer-checked:tw-ring-blue-400 peer-checked:tw-ring-offset-2"
                >
                  <div class="tw-flex tw-gap-2 tw-items-start">
                    <div>
                      <svg width="24" height="24" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
                        />
                      </svg>
                    </div>
                    <div>
                      <p class="tw-m-0 tw-text-lg">
                        Take a tour (<span class="tw-font-bold tw-text-blue-500"
                          >recommended:</span
                        >
                        approx. 2 minutes)
                      </p>
                    </div>
                  </div>
                </div>
              </label>
              <label
                class="tw-cursor-pointer tw-w-full"
                for="questionTwoOptionTwo"
              >
                <input
                  type="radio"
                  class="tw-peer tw-sr-only"
                  name="accountSetupQuestionTwo"
                  id="questionTwoOptionTwo"
                  value="Jump in right in"
                  v-model="accountSetupQuestionTwoChoice"
                />
                <div
                  class="tw-rounded-md tw-bg-gray-100 tw-p-5 tw-text-gray-600 tw-ring-2 tw-ring-transparent tw-transition-all tw-shadow tw-shadow-gray-300 hover:tw-shadow-none hover:tw-bg-gray-200 peer-checked:tw-text-blue-600 peer-checked:tw-ring-blue-400 peer-checked:tw-ring-offset-2"
                >
                  <div class="tw-flex tw-gap-2 tw-items-start">
                    <div>
                      <svg width="24" height="24" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
                        />
                      </svg>
                    </div>
                    <p class="tw-m-0 tw-text-lg">Jump in right in</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div class="tw-self-end">
            <TheButton
              variant="primary"
              size="large"
              @click="saveAccountSetupStepQuestionTwoChoice()"
              :hasTrailingIcon="true"
              :disabled="!accountSetupQuestionTwoChoice"
            >
              <template #text>Let's go</template>
              <template #trailing-icon>
                <svg
                  class="rtl:tw-rotate-180 tw-w-3.5 tw-h-3.5 tw-ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </template>
            </TheButton>
          </div>
        </div>
      </BlockStack>
    </div>
  </div>
</template>

<script>
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";

export default {
  name: "AccountSetupStepQuestionTwo",
  emits: ["saveAccountSetupStepQuestionTwoChoice"],
  components: { BlockStack, TheButton },
  data() {
    return {
      accountSetupQuestionTwoChoice: "",
    };
  },
  methods: {
    saveAccountSetupStepQuestionTwoChoice() {
      this.$emit(
        "saveAccountSetupStepQuestionTwoChoice",
        this.accountSetupQuestionTwoChoice
      );
    },
  },
};
</script>

<style scoped></style>
