<template>
  <PageHeader :showActions="true" title="All profiles">
    <template #page-header-actions>
      <TheButton
        type="button"
        :hasLeadingIcon="true"
        variant="primary"
        size="large"
        @click="toggleNewRecipientModal"
      >
        <template #text>Quick add</template>
        <template #leading-icon><b-icon-plus-lg></b-icon-plus-lg></template>
      </TheButton>
      <TheButton
        type="button"
        :hasLeadingIcon="true"
        variant="tertiary"
        size="large"
        :isRouterLink="true"
        to="/recipients/import"
      >
        <template #text>Import contacts</template>
        <template #leading-icon><b-icon-upload></b-icon-upload></template>
      </TheButton>
    </template>
  </PageHeader>
  <NotificationMessage :scope="$options.name"></NotificationMessage>

  <ThePage customWidthClass="tw-min-w-[600px] tw-max-w-7xl">
    <!-- <RecipientsIndexTableV2
      :isModalVisible="isModalVisible"
      @update:isModalVisible="closeModal"
    ></RecipientsIndexTableV2> -->
    <RecipientProfilesTable
      :isModalVisible="isModalVisible"
      @update:isModalVisible="closeModal"
    ></RecipientProfilesTable>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import TheButton from "../../components-v2/ui/actions/TheButton";
// import RecipientsIndexTableV2 from "../../components-v2/recipients/RecipientsIndexTableV2";
import RecipientProfilesTable from "../../components-v2/recipients/RecipientProfilesTable";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";

export default {
  name: "ViewRecipients",
  emits: [""],
  components: {
    RecipientProfilesTable,
    ThePage,
    PageHeader,
    TheButton,
    // RecipientsIndexTableV2,
    NotificationMessage,
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    dropdownActionItems() {
      return [
        {
          label: "Quick add",
          isDeleteAction: false,
          // action: () => this.toggleNewRecipientModal(),
        },
        {
          label: "Import recipients",
          isDeleteAction: false,
          // action: () => this.toggleNewRecipientModal(),
        },
      ];
    },

    toggleNewRecipientModal() {
      this.isModalVisible = !this.isModalVisible;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped></style>
