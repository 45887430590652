<template>
  <template v-if="showFilterModal">
    <Teleport to="#modal">
      <TheModal @closeModal="toggleFilterModal()" :open="showFilterModal">
        <ModalHeader title="Filters"></ModalHeader>
        <nav
          class="tw-flex tw-flex-1 tw-flex-col tw-divide-y tw-divide-cyan-800 tw-overflow-y-auto tw-border tw-border-black"
          aria-label="Sidebar"
        >
          <ul
            class="tw-flex tw-flex-1 tw-flex-col tw-gap-y-3 tw-list-none tw-ps-0 tw-pe-0 tw-m-0"
          >
            <p class="tw-m-0 tw-font-bold tw-pl-2">View by status</p>
            <ul role="list" class="tw-space-y-1 tw-list-none tw-p-0 tw-m-0">
              <li
                v-for="status in statusOptions"
                :key="status.value"
                @click="handleStatusSelection(status.value)"
                :class="[
                  selectedCampaignStatus === status.value
                    ? 'tw-bg-stone-200'
                    : 'hover:tw-bg-stone-200',
                  'tw-group tw-capitalize tw-flex tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-text-gray-700 tw-cursor-pointer',
                ]"
              >
                <b-icon-list
                  v-if="status.value === 'all'"
                  class="tw-mr-4 tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-700"
                  aria-hidden="true"
                ></b-icon-list>
                <b-icon-check-square
                  v-else-if="status.value === 'sent'"
                  class="tw-mr-4 tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-700"
                  aria-hidden="true"
                ></b-icon-check-square>
                <b-icon-cursor
                  v-else-if="status.value === 'clicked'"
                  class="tw-mr-4 tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-700"
                  aria-hidden="true"
                ></b-icon-cursor>
                <b-icon-bag-heart
                  v-else-if="status.value === 'redeemed'"
                  class="tw-mr-4 tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-700"
                  aria-hidden="true"
                ></b-icon-bag-heart>
                <b-icon-file-earmark-text
                  v-else-if="status.value === 'draft'"
                  class="tw-mr-4 tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-700"
                  aria-hidden="true"
                ></b-icon-file-earmark-text>

                <span class="tw-leading-6"> {{ status.value }}</span>
              </li>
            </ul>
            <p class="tw-m-0 tw-font-bold tw-pl-2">View by type</p>
            <ul role="list" class="tw-space-y-1 tw-list-none tw-p-0 tw-m-0">
              <li
                v-for="type in typeOptions"
                :key="type.value"
                @click="handleTypeSelection(type.value)"
                :class="[
                  selectedCampaignType === type.value
                    ? 'tw-bg-tone-200'
                    : 'hover:tw-bg-stone-200',
                  'tw-group tw-flex tw-rounded-md tw-p-2 tw-text-sm tw-leading-6 tw-text-gray-700 tw-cursor-pointer',
                ]"
              >
                <b-icon-send
                  v-if="type.value === 'email'"
                  class="tw-mr-4 tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-700"
                  aria-hidden="true"
                ></b-icon-send>
                <b-icon-patch-check
                  v-else-if="type.value === 'link'"
                  class="tw-mr-4 tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-700"
                  aria-hidden="true"
                ></b-icon-patch-check>

                <span class="tw-leading-6"> {{ type.value }}</span>
              </li>
            </ul>
            <p class="tw-m-0 tw-font-bold tw-pl-2">View by budget period</p>

            <ul role="list" class="tw-space-y-1 tw-list-none tw-p-0 tw-m-0">
              <li
                v-for="date in dateOptions"
                :key="date.value"
                @click="handleQueryByBudgetPeriod(date.value)"
                :class="[
                  showingCurrentPeriodCampaigns === date.value
                    ? 'tw-bg-tone-200'
                    : 'hover:tw-bg-stone-200',
                  'tw-group tw-flex tw-rounded-md tw-p-2 tw-text-sm tw-leading-6  tw-text-gray-700 tw-cursor-pointer',
                ]"
              >
                <b-icon-calendar3
                  class="tw-mr-4 tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-700"
                  aria-hidden="true"
                ></b-icon-calendar3>
                <span class="tw-leading-6"> {{ date.value }}</span>
              </li>
            </ul>
          </ul>
        </nav>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="checkingIfUserHasCampaigns">
    <div
      class="tw-flex tw-justify-center tw-items-center tw-w-full tw-max-w-5xl tw-mx-auto"
    >
      <PageSection :fullWidth="true">
        <PulseAnimation>
          <DataTableSkeleton></DataTableSkeleton>
        </PulseAnimation>
      </PageSection>
    </div>
  </template>
  <template v-else>
    <TheCard class="tw-w-full">
      <template v-if="userHasCampaigns">
        <CardHeader title="Campaigns" :showActions="true" :useSearchBar="true">
          <template #card-search-bar>
            <div class="tw-max-w-lg tw-w-full tw-flex-grow">
              <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-full">
                <div
                  class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                >
                  <b-icon-search
                    class="tw-h-5 tw-w-5 tw-text-gray-400"
                    aria-hidden="true"
                  ></b-icon-search>
                </div>
                <input
                  @keyup.enter="handleSearchQuery()"
                  v-model="searchQuery"
                  type="text"
                  name="campaignSearch"
                  id="campaignSearch"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                  :placeholder="searchBarPlaceholder"
                />
              </div>
            </div>
          </template>
          <template #card-header-actions>
            <template v-if="budget">
              <div class="tw-flex tw-bg-gray-100 tw-p-0.5 tw-rounded-md">
                <TheButton
                  type="button"
                  :variant="
                    !showingCurrentPeriodCampaigns ? 'secondary' : 'ghost'
                  "
                  size="regular"
                  @click="handleQueryByBudgetPeriod(false)"
                  :hasLeadingIcon="true"
                >
                  <template #leading-icon>
                    <b-icon-list></b-icon-list>
                  </template>
                  <template #text>All time</template>
                </TheButton>
                <TheButton
                  type="button"
                  :variant="
                    showingCurrentPeriodCampaigns ? 'secondary' : 'ghost'
                  "
                  size="regular"
                  @click="handleQueryByBudgetPeriod(true)"
                  :hasLeadingIcon="true"
                >
                  <template #leading-icon>
                    <b-icon-calendar3></b-icon-calendar3>
                  </template>
                  <template #text>From current period</template>
                </TheButton>
              </div>
            </template>
            <!-- sort -->
            <DropdownMenu
              :buttonDropdownList="true"
              :buttonDropdown="true"
              :buttonText="
                sortedValue === '-_id' ? 'Sort by: Newest' : 'Sort by: Oldest'
              "
              :items="sortDropdownItems()"
            >
            </DropdownMenu>
            <template v-if="budget && budget !== undefined">
              <TheButton
                type="button"
                variant="tertiary"
                :disabled="campaignsAreLoading"
                @click="toggleFilterModal"
              >
                <template #text>Filters</template>
              </TheButton>
            </template>
          </template>
        </CardHeader>
      </template>
      <CardBody>
        <template v-if="campaignsAreLoading">
          <PulseAnimation>
            <DataTableSkeleton></DataTableSkeleton>
          </PulseAnimation>
        </template>
        <template v-else>
          <!-- if userHasCampaigns show campaigns list -->
          <template v-if="userHasCampaigns && count">
            <div
              class="tw-overflow-y-auto tw-min-h-[200px] tw-max-h-[500px] tw-w-full"
            >
              <table
                class="tw-min-w-full tw-divide-stone-300 tw-sticky tw-top tw-z-1"
              >
                <thead
                  class="tw-sticky tw-top-0 tw-z-10 tw-border-b tw-border-stone-300"
                >
                  <tr>
                    <th
                      scope="col"
                      class="tw-min-w-[8px] tw-w-8 tw-py-3 tw-px-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="tw-min-w-[8px] tw-w-8 tw-py-3 tw-px-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Recipient
                    </th>
                    <template v-if="this.isBoldXchangeAdmin">
                      <th
                        scope="col"
                        class="tw-hidden sm:tw-table-cell tw-min-w-[8px] tw-w-8 tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                      >
                        Created By
                      </th>
                    </template>
                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-min-w-32 tw-w-32 tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Date Created
                    </th>

                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Type
                    </th>

                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Status
                    </th>

                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3 tw-text-left tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Gift Card Amount
                    </th>

                    <th
                      scope="col"
                      class="tw-px-3 tw-py-3 tw-text-xs tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-z-1 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      <span class="tw-sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="tw-divide-y tw-divide-gray-200 tw-bg-white tw-min-h-[500px]"
                >
                  <tr
                    v-for="campaign in campaignData"
                    :key="campaign.id"
                    :class="[
                      selectedCampaigns.includes(campaign.id) && 'bg-gray-50',
                    ]"
                  >
                    <td class="tw-py-4 tw-px-3 tw-min-w-40">
                      <template
                        v-if="
                          campaign?.metadata?.campaign_status === 'draft' &&
                          this.isBoldXchangeAdmin &&
                          campaign?.user?._id === this.userId
                        "
                      >
                        <TheLink
                          variant="primary"
                          :url="{
                            name: 'CampaignBuilder',
                            params: { campaignId: campaign._id },
                          }"
                          :isRouterLink="true"
                        >
                          <template #text>
                            <div class="tw-flex tw-flex-col">
                              <template v-if="campaignName(campaign)">
                                <p class="tw-p-0 tw-m-0 tw-text-sm">
                                  {{ campaignName(campaign) || "---" }}
                                </p>
                              </template>
                              <template v-else>N/A</template>
                            </div>
                          </template>
                        </TheLink>
                      </template>
                      <template
                        v-if="
                          campaign?.metadata?.campaign_status === 'draft' &&
                          this.isBoldXchangeAdmin &&
                          campaign?.user?._id !== this.userId
                        "
                      >
                        <TheLink
                          variant="primary"
                          :url="{
                            name: 'CampaignDetails',
                            params: { campaignId: campaign._id },
                          }"
                          :isRouterLink="true"
                        >
                          <template #text>
                            <div class="tw-flex tw-flex-col">
                              <template v-if="campaignName(campaign)">
                                <p class="tw-p-0 tw-m-0 tw-text-sm">
                                  {{ campaignName(campaign) || "---" }}
                                </p>
                              </template>
                              <template v-else>N/A</template>
                            </div>
                          </template>
                        </TheLink>
                      </template>
                      <template
                        v-if="
                          campaign?.metadata?.campaign_status === 'draft' &&
                          !this.isBoldXchangeAdmin
                        "
                      >
                        <TheLink
                          variant="primary"
                          :url="{
                            name: 'CampaignBuilder',
                            params: { campaignId: campaign._id },
                          }"
                          :isRouterLink="true"
                        >
                          <template #text>
                            <div class="tw-flex tw-flex-col">
                              <template v-if="campaignName(campaign)">
                                <p class="tw-p-0 tw-m-0 tw-text-sm">
                                  {{ campaignName(campaign) || "---" }}
                                </p>
                              </template>
                              <template v-else>N/A</template>
                            </div>
                          </template>
                        </TheLink>
                      </template>
                      <template
                        v-if="campaign?.metadata?.campaign_status !== 'draft'"
                      >
                        <TheLink
                          variant="primary"
                          :url="{
                            name: 'CampaignDetails',
                            params: { campaignId: campaign._id },
                          }"
                          :isRouterLink="true"
                        >
                          <template #text>
                            <div class="tw-flex tw-flex-col">
                              <template v-if="campaignName(campaign)">
                                <p class="tw-p-0 tw-m-0 tw-text-sm">
                                  {{ campaignName(campaign) || "---" }}
                                </p>
                              </template>
                              <template v-else> N/A </template>
                            </div>
                          </template>
                        </TheLink>
                      </template>
                      <div
                        class="sm:tw-hidden tw-mt-2 tw-flex tw-flex-col tw-gap-2"
                      >
                        <p class="tw-p-0 tw-m-0 tw-text-sm">
                          Date created:
                          <!-- {{ getTimeStamp(campaign.id) }} -->
                        </p>
                        <div class="tw-flex tw-flex-wrap tw-gap-1">
                          <span
                            class=""
                            :class="{
                              'tw-inline-flex tw-items-center tw-rounded-md tw-bg-gray-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-800':
                                campaign?.metadata?.campaign_status === 'draft',
                              'tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-blue-800':
                                campaign?.metadata?.campaign_status ===
                                  'sent' ||
                                (campaign?.metadata?.isRedeemed !== undefined &&
                                  !campaign?.metadata?.isRedeemed),
                              'tw-inline-flex tw-items-center tw-rounded-md tw-bg-green-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-green-800':
                                (campaign?.metadata?.isRedeemed !== undefined &&
                                  campaign?.metadata?.isRedeemed) ||
                                campaign?.metadata?.campaign_status ===
                                  'redeemed',
                            }"
                          >
                            {{ campaign?.metadata?.campaign_status }}
                          </span>
                          <template
                            v-if="
                              campaign?.seen &&
                              campaign?.metadata?.campaign_status !== 'draft'
                            "
                          >
                            <span
                              class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-orange-100 tw-px-1.5 tw-py-1 tw-text-xs tw-font-medium tw-text-orange-800"
                            >
                              clicked
                            </span>
                          </template>
                          <!-- clicked status -->
                        </div>
                      </div>
                    </td>
                    <td class="tw-py-4 tw-px-3">
                      <template v-if="recipientsCount(campaign) === 0">
                        ---
                      </template>
                      <template v-if="recipientsCount(campaign) === 1">
                        <!-- if single or no recipients -->
                        <p class="tw-p-0 tw-m-0 tw-text-sm">
                          {{ recipientName(campaign) }}
                        </p>
                        <p class="tw-p-0 tw-m-0 tw-text-sm tw-truncate">
                          {{ recipientEmail(campaign) || "---" }}
                        </p>
                      </template>
                      <template v-if="recipientsCount(campaign) > 1">
                        <p
                          class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-gray-100 tw-px-2 tw-py-1 tw-text-sm"
                        >
                          <b-icon-people class="tw-me-2"></b-icon-people
                          ><span class="tw-text-gray-600 tw-me-1 tw-font-bold"
                            >{{ recipientsCount(campaign) }}
                          </span>
                          recipients
                        </p>
                      </template>
                    </td>
                    <template v-if="this.isBoldXchangeAdmin">
                      <td
                        class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                      >
                        <div class="tw-flex tw-flex-col">
                          <p class="tw-p-0 tw-m-0 tw-text-sm">
                            {{ userName(campaign) || "N/A" }}
                          </p>
                          <p class="tw-p-0 tw-m-0 tw-text-sm">
                            {{ userEmail(campaign) || "N/A" }}
                          </p>
                        </div>
                      </td>
                    </template>
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <p class="tw-p-0 tw-m-0 tw-text-sm">
                        {{ getTimeStamp(campaign.id) }}
                      </p>
                    </td>

                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <template v-if="campaignType(campaign)">{{
                        campaignType(campaign)
                      }}</template>
                      <template v-else>link</template>
                    </td>

                    <!-- status -->
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <div class="tw-flex tw-flex-wrap tw-gap-1">
                        <!-- show draft -->
                        <template
                          v-if="campaign?.metadata?.campaign_status === 'draft'"
                        >
                          <span
                            class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-gray-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-800"
                          >
                            draft
                          </span>
                        </template>
                        <!-- show sent count -->
                        <template
                          v-if="campaign?.metadata?.campaign_status === 'sent'"
                        >
                          <span
                            class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-blue-800"
                          >
                            sent
                          </span>
                        </template>
                        <!-- show redeemed count -->
                        <template v-if="campaign?.metadata?.finalizations">
                          <span
                            class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-green-100 tw-px-1 tw-py-1 tw-text-xs tw-font-medium tw-text-green-800"
                          >
                            <span
                              class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-white tw-text-xs tw-font-bold tw-text-gray-800 tw-me-2"
                            >
                              {{
                                getCampaignRecipientsEngagement(campaign)
                                  .totalCampaignsRedeemedCount
                              }}
                            </span>
                            redeemed
                          </span>
                        </template>
                        <template
                          v-if="
                            (campaign?.metadata?.isRedeemed !== undefined &&
                              campaign?.metadata?.isRedeemed) ||
                            campaign?.metadata?.campaign_status === 'redeemed'
                          "
                        >
                          <span
                            class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-green-100 tw-px-1 tw-py-1 tw-text-xs tw-font-medium tw-text-green-800"
                          >
                            redeemed
                          </span>
                        </template>
                        <!-- show clicked count -->
                        <template
                          v-if="
                            Array.isArray(campaign?.seen) &&
                            getCampaignRecipientsEngagement(campaign)
                              ?.totalCampaignsClickedCount &&
                            campaign?.metadata?.campaign_status !== 'draft'
                          "
                        >
                          <span
                            class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-orange-100 tw-px-1.5 tw-py-1 tw-text-xs tw-font-medium tw-text-orange-800"
                          >
                            <template
                              v-if="
                                campaign?.metadata?.campaign_status !==
                                  'draft' &&
                                getCampaignRecipientsEngagement(campaign)
                                  ?.totalCampaignsClickedCount
                              "
                            >
                              <span
                                class="tw-inline-flex tw-items-center tw-bg-white tw-text-xs tw-font-bold tw-text-gray-800 tw-me-2"
                              >
                                {{
                                  getCampaignRecipientsEngagement(campaign)
                                    ?.totalCampaignsClickedCount
                                }}
                              </span>
                            </template>
                            clicked
                          </span>
                        </template>
                        <template
                          v-if="
                            typeof campaign?.seen === 'boolean' &&
                            campaign?.seen &&
                            campaign?.metadata?.campaign_status !== 'draft'
                          "
                        >
                          <span
                            class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-orange-100 tw-px-1.5 tw-py-1 tw-text-xs tw-font-medium tw-text-orange-800"
                          >
                            clicked
                          </span>
                        </template>
                      </div>
                    </td>
                    <!-- actions -->

                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      {{ `$${campaign?.metadata?.gift_card_amount} ` || "---" }}
                    </td>
                    <td
                      class="tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-3"
                    >
                      <TheLink
                        variant="primary"
                        :url="{
                          name: 'CampaignDetails',
                          params: { campaignId: campaign?._id },
                        }"
                        :isRouterLink="true"
                      >
                        <template #text> View campaign</template>
                      </TheLink>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <!-- if userHasCampaigns AND query count is 0 show campaigns message -->
          <template v-if="userHasCampaigns && !count">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
            >
              <img
                style="max-height: 150px; width: auto"
                src="../../assets/svg/illustrations-test/14_no_query_results.png"
                alt="Image Description"
              />
              <h1 class="tw-text-2xl tw-m-0">No exact matches</h1>
              <p class="tw-m-0 tw-text-lg">
                Try updating your search/filters or
                <a
                  class="tw-font-bold tw-cursor-pointer"
                  @click="resetFilters()"
                  >click here</a
                >
                to reset.
              </p>
            </div>
          </template>
          <!-- if !userHasCampaigns show message -->
          <template v-if="!userHasCampaigns">
            <div
              class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
            >
              <EmptyState emptyState="campaignsForBudgets"></EmptyState>
            </div>
          </template>
        </template>

        <!-- </div> -->
      </CardBody>
      <template v-if="userHasCampaigns">
        <CardFooter>
          <!-- Pagination -->
          <nav
            class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200 tw-z-0"
            aria-label="Pagination"
          >
            <div class="tw-hidden sm:tw-block">
              <p class="tw-text-sm tw-text-gray-700 tw-m-0">
                <span class="tw-font-bold">{{ count }}</span> records
              </p>
            </div>
            <div
              class="tw-flex tw-flex-1 tw-justify-between tw-gap-x-3 sm:tw-justify-end"
            >
              <TheButton
                class="disabled:tw-bg-slate-200 disabled:tw-cursor-auto"
                :disabled="this.currentPage === 1"
                @click="previousPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-left></b-icon-chevron-left
                ></template>
              </TheButton>
              <TheButton
                class="disabled:tw-bg-slate-200"
                :disabled="this.isLastPage"
                @click="nextPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-right></b-icon-chevron-right
                ></template>
              </TheButton>
            </div>
          </nav>
        </CardFooter>
      </template>
    </TheCard>
  </template>
</template>

<script>
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader.vue";
import CardBody from "../../components-v2/ui/layout/CardBody.vue";
import CardFooter from "../../components-v2/ui/layout/CardFooter.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import DataTableSkeleton from "../../components-v2/ui/skeletons/DataTableSkeleton.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import DropdownMenu from "../../components-v2/ui/forms/DropdownMenu.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalHeader from "../../components-v2/ui/layout/ModalHeader.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import { parseCronExpression } from "cron-schedule";

import {
  listMailCampaigns,
  bulkUpdateMailCampaigns,
  campaignRecipientsEngagement,
  campaignEngagementStats,
} from "@/services/campaignService";

export default {
  name: "CampaignsIndexTable",
  components: {
    DataTableSkeleton,
    PulseAnimation,
    ModalHeader,
    TheModal,
    DropdownMenu,
    TheLink,
    EmptyState,
    CardBody,
    TheCard,
    CardHeader,
    TheButton,
    CardFooter,
    PageSection,
  },
  props: {
    budget: {
      type: Object,
    },
  },
  mounted() {
    /** load data */
    this.checkForCampaigns();
  },
  data() {
    return {
      statusOptions: [
        { value: "all" },
        // { value: "sent" },
        { value: "clicked" },
        { value: "redeemed" },
        // { value: "draft" },
      ],
      typeOptions: [{ value: "email" }, { value: "link" }],
      dateOptions: [
        { value: "All time" },
        { value: "This period" },
        // { value: "Last period" },
        // { value: "Past 30 days" },
        // { value: "Past 90 days" },
      ],
      selectedCampaigns: [],
      selectedRows: [],
      campaigns: [],
      selectedNeighborhoods: [],
      count: 0,
      pageSize: 25,
      currentPage: 1,
      searchQuery: "",
      sortedValue: "-_id",
      showingCurrentPeriodCampaigns: false,
      selectedCampaignType: "",
      selectedCampaignStatus: "all",
      bulkEditCampaignStatus: "",
      noDataPlaceholderText: "---",
      startDate: "",
      endDate: "",
      startDateTimestamp: null,
      endDateTimestamp: null,
      stamp: null,
      customDateRangeApplied: false,
      searchIsActive: false,
      checkingIfUserHasCampaigns: false,
      campaignsAreLoading: false,
      userHasCampaigns: true,
      showFilterModal: false,
      showDeleteModal: false,
    };
  },
  computed: {
    budgetId() {
      return this.$route.params.budgetId;
    },
    campaignData() {
      return this.campaigns;
    },
    currentUserEmail() {
      return this?.$store?.state?.auth?.user?.email;
    },
    User() {
      return this?.$store?.state?.auth?.user;
    },
    getQuickEditStatus() {
      return this.$store.getters["campaigns/getQuickEditStatus"];
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.campaigns.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    userName() {
      return (campaign) => {
        const firstName = campaign?.user?.firstName;
        const lastName = campaign?.user?.lastName;
        if (firstName && lastName) {
          return `${firstName} ${lastName}`;
        } else {
          return false;
        }
      };
    },
    userEmail() {
      return (campaign) => campaign?.user?.email;
    },
    recipientsCount() {
      return (campaign) => {
        return campaign?.recipients?.length || 0;
      };
    },
    getCampaignRecipientsEngagement() {
      return (campaign) => {
        const recipients = campaignRecipientsEngagement(campaign);
        return campaignEngagementStats(recipients);
      };
    },

    recipientName() {
      return (campaign) => {
        const recipientsCount = campaign?.recipients?.length || 0;
        // Handling no recipients
        if (recipientsCount === 0) {
          return "N/A";
        }

        // Handling a single recipient with both first and last names
        if (recipientsCount === 1) {
          const firstName = campaign.recipients[0]?.firstName;
          const lastName = campaign.recipients[0]?.lastName;
          if (firstName && lastName) {
            return `${firstName} ${lastName}`;
          } else {
            return "N/A";
          }
        }
      };
    },
    recipientEmail() {
      return (campaign) => {
        if (!campaign?.recipients[0]?.contact?.email) {
          return "N/A";
        }

        return campaign?.recipients[0]?.contact?.email;
      };
    },
    campaignName() {
      return (campaign) => {
        if (campaign?.metadata?.campaign_name) {
          return campaign?.metadata?.campaign_name;
        } else {
          return "Untitled Campaign";
        }
      };
    },
    campaignType() {
      return (campaign) => campaign?.metadata?.campaign_type;
    },
    campaignInvoice() {
      return (campaign) => {
        if (
          campaign?.invoice &&
          campaign?.metadata?.campaign_status === "sent"
        ) {
          return "Not fulfilled";
        } else if (
          campaign?.invoice &&
          campaign?.metadata?.campaign_status === "redeemed"
        ) {
          return "Order fulfilled ";
        }
        return false;
      };
    },
    getTimeStamp() {
      return (id) => {
        if (id) {
          const objectIdToTimestamp = require("objectid-to-timestamp");
          const originalDate = new Date(objectIdToTimestamp(id));

          const year = originalDate.getFullYear();
          const month = (originalDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          const day = originalDate.getDate().toString().padStart(2, "0");

          return `${month}/${day}/${year}`;
        }
      };
    },
    searchBarPlaceholder() {
      return "Search by recipient name or email";
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
    getBudgetPeriodStartAndEndTimestamps() {
      const cron = this?.budget?.users[0]?.period
        ? parseCronExpression(this?.budget?.users[0]?.period)
        : false;
      let start = cron && cron.getPrevDate().toISOString();
      let end = cron && cron.getNextDate().toISOString();

      return {
        startOfBudgetPeriod: start,
        endOfBudgetPeriod: end,
      };
    },
  },
  methods: {
    /** handle checking if user has campaigns */
    async checkForCampaigns(page) {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.checkingIfUserHasCampaigns = true;
      this.currentPage = page === undefined ? this.currentPage : page;

      try {
        let filter = {};

        filter = {
          "user._id": this?.budget?.user?._id,
          $and: [
            { "metadata.budget": { $exists: true } },
            {
              $or: [
                {
                  "metadata.budget": {
                    $toObjectId: this.$route.params.budgetId,
                  },
                },
                {
                  "metadata.budget": {
                    $toObjectId: this.$route.params.budgetId,
                  },
                },
              ],
            },
            {
              $or: [
                {
                  "metadata.campaign_status": {
                    $eq: "sent",
                  },
                },
                {
                  "metadata.campaign_status": {
                    $eq: "redeemed",
                  },
                },
              ],
            },
          ],
        };

        const queryParameters = {
          skip: (this.currentPage - 1) * this.pageSize,
          limit: this.pageSize,
          sort: this.sortedValue,
          expand: "user recipients",
        };

        const requestBody = {
          filter: filter,
        };

        const response = await api.listMailCampaigns(
          queryParameters,
          requestBody
        );
        const count = response.data.result.count;
        this.count = count;
        this.userHasCampaigns = count;
        this.campaigns = response.data.result.records;
        this.checkingIfUserHasCampaigns = false;
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.error("Failed to load campaigns:", error);
        // Handle the error appropriately
      } finally {
        this.checkingIfUserHasCampaigns = false;
      }
    },

    /** handle pagination of records */
    async nextPage() {
      this.changePage(this.currentPage + 1);
    },

    async previousPage() {
      this.changePage(this.currentPage - 1);
    },

    async changePage(newPage) {
      this.campaignsAreLoading = true;
      this.currentPage = newPage;

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "user recipients",
      };

      let requestBody = this.createRequestBody();

      try {
        const response = await listMailCampaigns(queryParameters, requestBody);
        this.campaigns = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        console.error("Failed to load campaigns:", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignsAreLoading = false;
      }
    },

    /** Query filters */
    createRecipientFilter() {
      let filter = {};
      filter = {
        "user._id": this?.budget?.user?._id,
        $and: [
          { "metadata.budget": { $exists: true } },
          {
            $or: [
              {
                "metadata.budget": {
                  $toObjectId: this.$route.params.budgetId,
                },
              },
              {
                "metadata.budget": {
                  $toObjectId: this.$route.params.budgetId,
                },
              },
            ],
          },
          {
            $or: [
              {
                "metadata.campaign_status": {
                  $eq: "sent",
                },
              },
              {
                "metadata.campaign_status": {
                  $eq: "redeemed",
                },
              },
            ],
          },
        ],
      };

      return filter;
    },
    createDateFilter() {
      let filter = {};
      if (this?.stamp && !this?.stamp?.startOfBudgetPeriod) {
        filter["metadata.date_sent"] = { $gte: { $oidTime: this.stamp } };
      }
      if (
        (this?.stamp && this?.stamp.startOfBudgetPeriod) ||
        this?.stamp?.endOfBudgetPeriod
      ) {
        filter["metadata.date_sent"] = {
          $gte: this.stamp.startOfBudgetPeriod,
          $lte: this.stamp.endOfBudgetPeriod,
        };
      }
      return filter;
    },
    createTypeFilter() {
      if (this.selectedCampaignType) {
        if (this.selectedCampaignType === "link") {
          return {
            $or: [
              { "metadata.campaign_type": { $eq: this.selectedCampaignType } },
              { "metadata.isRedeemed": { $exists: true } },
            ],
          };
        }
        return { "metadata.campaign_type": { $eq: this.selectedCampaignType } };
      }
      return {};
    },
    createStatusFilter() {
      if (
        !this.selectedCampaignStatus ||
        this.selectedCampaignStatus === "all"
      ) {
        return {};
      }

      let filter = {};

      switch (this.selectedCampaignStatus) {
        case "sent":
          filter = {
            $or: [
              { "metadata.campaign_status": { $eq: "sent" } },
              { "metadata.campaign_status": { $eq: "redeemed" } },
              { "metadata.isRedeemed": true },
            ],
          };
          break;
        case "redeemed":
          filter = {
            $or: [
              {
                "metadata.campaign_status": {
                  $eq: "redeemed",
                },
              },
              {
                "metadata.isRedeemed": true,
              },
              { "metadata.finalizations": { $exists: true } },
            ],
          };
          break;
        case "clicked":
          filter = {
            $or: [
              {
                $and: [
                  { "metadata.campaign_status": { $exists: true } },
                  {
                    $or: [
                      {
                        "metadata.campaign_status": {
                          $eq: "sent",
                        },
                      },
                      {
                        "metadata.campaign_status": {
                          $eq: "redeemed",
                        },
                      },
                    ],
                  },
                  {
                    seen: {
                      $eq: true,
                    },
                  },
                  {
                    $or: [
                      { "metadata.isRedeemed": { $exists: false } },
                      { "metadata.isRedeemed": true },
                    ],
                  },
                ],
              },
              {
                $and: [
                  { seen: { $exists: true } },
                  { seen: { $ne: [] } }, // { seen: { $ne: [] } },
                  { "metadata.campaign_status": { $ne: "draft" } },
                  { "metadata.campaign_status": { $exists: true } },
                ],
              },
            ],
          };
          break;
        default:
          filter = {
            "metadata.campaign_status": { $eq: this.selectedCampaignStatus },
          };
      }

      return filter;
    },
    combineFilters(filters) {
      return filters.reduce(
        (combined, filter) => ({ ...combined, ...filter }),
        {}
      );
    },
    createRequestBody() {
      const filters = [
        this.createRecipientFilter(),
        this.createDateFilter(),
        this.createTypeFilter(),
        this.createStatusFilter(),
        this.createSearchQuery(),
      ];

      return this.combineFilters(filters);
    },

    /** handle retrieving records by selected filter */
    async filterCampaigns(page) {
      this.campaignsAreLoading = true;
      this.showFilterModal = false;

      this.currentPage = page === undefined ? this.currentPage : page;

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "user recipients",

      };

      const requestBody = this.createRequestBody();

      try {
        const { data } = await listMailCampaigns(queryParameters, requestBody);
        this.campaigns = data.result.records;
        this.count = data.result.count;
      } catch (error) {
        console.error("Failed to load campaigns:", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignsAreLoading = false;
      }
    },

    /** handle retrieving records by search query */
    createSearchQuery() {
      if (this.searchQuery === "") return {};

      const searchValues = this.searchQuery.split(" ");
      let searchConditions = [];
      searchConditions = searchValues
        .map((value) => [
          { "recipients.firstName": { $regex: value, $options: "i" } },
          { "recipients.lastName": { $regex: value, $options: "i" } },
          { "recipients.contact.email": { $regex: value, $options: "i" } },
        ])
        .flat();
      // }

      return { $or: searchConditions };
    },
    createRequestBodyWithSearchQuery(currentUserEmail) {
      let filters = this.createRecipientFilter(currentUserEmail);

      const searchQueryFilters = this.createSearchQuery();
      if (Object.keys(searchQueryFilters).length > 0) {
        filters = { ...filters, ...searchQueryFilters };
      }

      return filters;
    },
    async getCampaignsBySearchQuery(page) {
      this.campaignsAreLoading = true;
      this.currentPage = page === undefined ? 1 : page;

      let currentUserEmail = this?.User?.email;
      const requestBody =
        this.createRequestBodyWithSearchQuery(currentUserEmail);

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "user recipients",
      };

      try {
        const { data } = await listMailCampaigns(queryParameters, requestBody);
        this.campaigns = data.result.records;
        this.count = data.result.count;
      } catch (error) {
        console.error("Campaigns did not load", error);
        this.handleErrorFromAPI(error); // Ensure you have a method to handle API errors
      } finally {
        this.campaignsAreLoading = false;
      }
    },

    /** handle bulk and quick edits */
    async bulkdUpdateCampaigns() {
      this.campaignIsSaving = true; // Assuming you have a flag to indicate saving state

      const campaignIds = this.selectedRows.map((campaign) => campaign._id);
      const patchObject = {
        "metadata.campaign_status": this.bulkEditCampaignStatus,
      };

      try {
        await bulkUpdateMailCampaigns(campaignIds, patchObject);
        // Assuming `handleSuccess` processes and displays success feedback
        this.handleSuccess("bulkEditCampaignStatus");
      } catch (error) {
        // Assuming `handleErrorFromAPI` processes and displays error feedback
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignIsSaving = false;
      }
    },
    /** campaign list item dropdown items */
    campaignListItemDropdownItems(campaign) {
      // campaign can only be edited by it's user
      if (
        campaign?.metadata?.campaign_status === "draft" &&
        !this.isBoldXchangeAdmin
      ) {
        return [
          {
            label: "Edit",
            isRouterLink: true,
            to: `/campaign/edit/${campaign.id}`,
          },
          {
            label: "Delete",
            isDeleteAction: true, // This item is a delete action
            action: () => this.toggleDeleteModal(campaign),
          },
        ];
      } else if (
        campaign?.metadata?.campaign_status === "draft" &&
        this.isBoldXchangeAdmin &&
        campaign?.user?._id === this.userId
      ) {
        return [
          {
            label: "Edit",
            isRouterLink: true,
            to: `/campaign/edit/${campaign.id}`,
          },
          {
            label: "Delete",
            isDeleteAction: true, // This item is a delete action
            action: () => this.toggleDeleteModal(campaign),
          },
        ];
      } else if (
        campaign?.metadata?.campaign_status === "draft" &&
        this.isBoldXchangeAdmin &&
        campaign?.user?._id !== this.userId
      ) {
        return [
          {
            label: "View details",
            isRouterLink: true,
            to: `/campaign/report/${campaign.id}`,
          },
        ];
      } else if (
        campaign?.metadata?.campaign_status !== "draft" &&
        this.isBoldXchangeAdmin
      ) {
        return [
          {
            label: "View details",
            isRouterLink: true,
            to: `/campaign/report/${campaign.id}`,
          },
        ];
      } else {
        return [
          {
            label: "View details",
            isRouterLink: true,
            to: `/campaign/report/${campaign.id}`,
          },
        ];
      }
    },
    campaignStatusDropdownItems(campaign) {
      if (campaign?.metadata?.campaign_status === "sent") {
        return [
          {
            label: "Mark 'REDEEMED'",
            isCriticalAction: true, // This item is a delete action
            action: () => this.updateCampaignStatus(campaign, "redeemed"),
          },
        ];
      } else {
        return [
          {
            label: "Mark 'SENT'",
            isCriticalAction: true, // This item is a delete action
            action: () => this.updateCampaignStatus(campaign, "sent"),
          },
        ];
      }
    },
    /** campaign list item dropdown items */
    sortDropdownItems() {
      return [
        {
          label: "Sort by: Newest",
          action: () => this.handleSort("-_id"),
        },
        {
          label: "Sort by: Oldest",
          action: () => this.handleSort("_id"),
        },
      ];
    },

    /** modals */
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },

    /** timestamp */
    setStamp() {
      let timestamp = null;

      if (this.showingCurrentPeriodCampaigns) {
        timestamp = this.getBudgetPeriodStartAndEndTimestamps;
      }

      this.stamp = timestamp;
    },

    /** utility handlers */
    handlePrimaryAction() {
      this.$router.push("/campaigns");
    },
    handleQueryByBudgetPeriod(date) {
      this.selectedCampaignType = "";
      this.selectedCampaignStatus = "";
      this.showingCurrentPeriodCampaigns = date;
      this.customDateRangeApplied = false;
      this.clearSearchQuery();
      if (date === "All time") {
        this.stamp = null;
        this.filterCampaigns();
      } else {
        this.setStamp();
        this.filterCampaigns();
      }
    },
    handleTypeSelection(type) {
      this.clearSearchQuery();
      this.stamp = null;
      // this.showingCurrentPeriodCampaigns = true;
      this.selectedCampaignStatus = "";
      this.selectedCampaignType = type;
      this.customDateRangeApplied = false;
      this.filterCampaigns();
    },
    handleStatusSelection(status) {
      this.clearSearchQuery();
      this.stamp = null;
      // this.showingCurrentPeriodCampaigns = true;
      this.selectedCampaignType = "";
      this.selectedCampaignStatus = status;
      this.customDateRangeApplied = false;
      if (status === "all") {
        this.selectedCampaignStatus = "";
        this.filterCampaigns();
      } else {
        this.filterCampaigns();
      }
    },
    handleSort(column) {
      this.checkIfCampaignStatusIsAll();
      this.currentPage = 1;

      this.sortedValue = this.sortedValue === column ? `-${column}` : column;

      if (this.searchQuery) {
        return this.handleSearchQuery();
      } else {
        return this.filterCampaigns();
      }
    },
    handleSearchQuery() {
      this.searchIsActive = true;
      this.selectedCampaignStatus = "";
      this.selectedCampaignType = "";
      // this.showingCurrentPeriodCampaigns = false;
      this.customDateRangeApplied = false;
      this.getCampaignsBySearchQuery();
      // this.trackFilter();
    },
    checkIfCampaignStatusIsAll() {
      if (this.selectedCampaignStatus === "all") {
        this.selectedCampaignStatus = "";
      }
    },
    clearSearchQuery() {
      this.searchIsActive = false;
      this.searchQuery = "";
    },
    clearCustomDates() {
      this.startDate = "";
      this.endDate = "";
      this.customDateRangeApplied = false;
      this.filterCampaigns();
    },
    resetFilters() {
      this.selectedCampaignStatus = "";
      this.selectedCampaignType = "";
      this.showingCurrentPeriodCampaigns = false;
      this.searchIsActive = false;
      this.startDate = "";
      this.endDate = "";
      this.customDateRangeApplied = false;
      this.searchQuery = "";
      this.currentPage = 1;
      this.stamp = null;
      this.filterCampaigns();
    },
    reloadList() {
      const page = this.currentPage;
      if (this.searchQuery !== "") {
        this.getCampaignsBySearchQuery(page);
      } else {
        // this.filterCampaigns(page);
        this.checkForCampaigns(page);
      }
    },

    /** handle success and errors */
    handleSuccess(api) {
      let successMessage = "Update successful!";
      if (api === "upsertMailCampaign") {
        successMessage = "Campaign status was successfully updated.";
      } else if (api === "deleteMailCampaigns") {
        this.showDeleteModal = false;
        successMessage = "Campaign was successfully deleted.";
      }

      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: successMessage,
        duration: 4000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.clearBulkEditSelections();
      this.reloadList();
      this.campaignIsSaving = false;
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.checkingIfUserHasCampaigns = false;
      this.campaignsAreLoading = false;
      this.campaignIsSaving = false;
      this.reloadList();
      console.error(errorMessage);
    },

    /** mixpanel */
    // trackFilter() {
    //   this.mixpanel.track("Campaign List Filters Applied", {
    //     dateRange: this.showingCurrentPeriodCampaigns,
    //     search: this.searchQuery,
    //   });
    // },
  },
};
</script>
