<template>
  <AnonymousNavigation v-if="isUserAnonymous"></AnonymousNavigation>
  <div class="container position-relative zi-n1">
    <figure
      class="position-absolute"
      style="top: -5rem; left: 35rem; width: 20rem; height: 20rem"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260">
        <circle fill="#e7eaf3" opacity="0.3" cx="130" cy="130" r="50"></circle>
      </svg>
    </figure>
    <figure
      class="position-absolute"
      style="top: -35rem; left: 50rem; width: 62rem; height: 62rem"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260">
        <circle fill="#e7eaf3" opacity=".3" cx="130" cy="130" r="130"></circle>
      </svg>
    </figure>

    <figure
      class="position-absolute"
      style="top: 20rem; left: 80rem; width: 30rem; height: 20rem"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260">
        <circle fill="#e7eaf3" opacity="0.4" cx="130" cy="130" r="10"></circle>
      </svg>
    </figure>
  </div>

  <LoadingGiftIcon v-if="isLoading"></LoadingGiftIcon>

  <div v-else>
    <!-- Content -->
    <div class="container-fluid content-space-1 text-start">
      <div class="row gap-2">
        <div
          class="col col-md-3"
          style="max-height: 5rem"
          v-if="getVendorMedia"
        >
          <img class="h-100" :src="media[0].url" alt="Logo" />
        </div>
        <h1 class="display-3">
          <span class="text-highlight-primary">{{ vendorName }}</span>
        </h1>
      </div>

      <!-- info -->
      <div class="row mt-3">
        <div class="col-md-3 order-md-2 mb-3 mb-md-0">
          <div class="ps-md-4">
            <ul class="list-unstyled list-py-2">
              <li>
                <h2>Headquarters</h2>
                <p
                  class="fs-3 mb-0"
                  :class="{
                    'text-muted': !vendorCity,
                    'text-info': vendorCity,
                  }"
                >
                  {{
                    vendorCity && vendorState
                      ? vendorCity + ", " + vendorState
                      : "USA"
                  }}
                </p>
              </li>
              <li>
                <h2>Specialty</h2>
                <div class="d-flex flex-row flex-wrap">
                  <template
                    v-if="vendorCategories && vendorCategories.length > 0"
                  >
                    <div
                      class="py-2 px-1"
                      v-for="(category, key) in vendorCategories"
                      :key="'item-' + key"
                    >
                      <!-- badge -->
                      <span class="bg-soft-info rounded me-1 px-2 py-1">
                        <span class="text-dark ms-1">{{ category }}</span>
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <p class="fs-3 text-danger">{{ noDataPlaceholderText }}</p>
                  </template>
                </div>
              </li>
              <template v-if="hasSocialLinks">
                <li>
                  <h2>Links</h2>
                  <!-- Socials -->
                  <ul class="list-inline">
                    <li class="list-inline-item" v-if="vendorWebsite">
                      <a
                        class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                        title="Website URL"
                        :href="vendorWebsite"
                        target="_blank"
                      >
                        <b-icon-globe></b-icon-globe>
                      </a>
                    </li>
                    <li class="list-inline-item" v-if="vendorLinkedIn">
                      <a
                        class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                        title="LinkedIn URL"
                        :href="vendorLinkedIn"
                        target="_blank"
                      >
                        <b-icon-linkedin></b-icon-linkedin>
                      </a>
                    </li>
                    <li class="list-inline-item" v-if="vendorTwitter">
                      <a
                        class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                        title="Twitter URL"
                        :href="vendorTwitter"
                        target="_blank"
                      >
                        <b-icon-twitter></b-icon-twitter>
                      </a>
                    </li>
                    <li class="list-inline-item" v-if="vendorTikTok">
                      <a
                        class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                        title="TikTok URL"
                        :href="vendorTikTok"
                        target="_blank"
                      >
                        <b-icon-tiktok></b-icon-tiktok>
                      </a>
                    </li>
                    <li class="list-inline-item" v-if="vendorInstagram">
                      <a
                        class="btn btn-soft-secondary btn-xs btn-icon rounded-circle me-1"
                        title="Instagram URL"
                        :href="vendorInstagram"
                        target="_blank"
                      >
                        <b-icon-instagram></b-icon-instagram>
                      </a>
                    </li>
                  </ul>
                  <!-- End Socials -->
                </li>
              </template>
            </ul>
            <!-- End List -->
          </div>
        </div>
        <!-- End Col -->
        <div class="col-md-9">
          <div class="mb-2">
            <p
              class="fs-3"
              :class="{
                'text-danger': !vendorDescription,
              }"
            >
              {{
                vendorDescription ? vendorDescription : noDataPlaceholderText
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <ProductsByVendorList
      :vendorObj="this.selectedVendor"
    ></ProductsByVendorList>
  </div>
</template>

<script>
import AnonymousNavigation from "../../components/ui/navigation/anonymous-users/AnonymousNavigation.vue";
import LoadingGiftIcon from "../../components/ui/LoadingGiftIcon.vue";
import ProductsByVendorList from "../vendors/ProductsByVendorList";

export default {
  name: "VendorDetails",
  components: {
    AnonymousNavigation,
    LoadingGiftIcon,
    ProductsByVendorList,
  },
  data() {
    return {
      media: [],
      isLoading: true,
      noDataPlaceholderText: "No information",
      selectedVendor: {},
      //   vendorId: ""
    };
  },
  computed: {
    getVendorMedia() {
      return this.media.length > 0 ? this.media : false;
    },
    getVendorId() {
      return this.$route.params.vendorId;
    },
    vendorName() {
      return this.selectedVendor.name;
    },
    vendorDescription() {
      return this?.selectedVendor?.description;
    },
    vendorCategories() {
      return this?.selectedVendor?.categories;
    },
    vendorCity() {
      return this?.selectedVendor?.address?.city;
    },
    vendorState() {
      return this?.selectedVendor?.address?.state;
    },
    vendorCountry() {
      return this?.selectedVendor?.address?.country;
    },
    vendorPostal() {
      return this?.selectedVendor?.address?.postalCode;
    },
    vendorContactName() {
      return this?.selectedVendor?.contact?.name;
    },
    vendorContactTitle() {
      return this?.selectedVendor?.contact?.title;
    },
    vendorContactEmail() {
      return this?.selectedVendor?.contact?.email;
    },
    vendorContactPhone() {
      return this?.selectedVendor?.contact?.phone;
    },
    hasSocialLinks() {
      return (
        this.vendorWebsite ||
        this.vendorLinkedIn ||
        this.vendorTwitter ||
        this.vendorTikTok ||
        this.vendorInstagram
      );
    },
    vendorWebsite() {
      return this?.selectedVendor?.metadata?.website_url;
    },
    vendorTwitter() {
      return this?.selectedVendor?.metadata?.twitter_url;
    },
    vendorLinkedIn() {
      return this?.selectedVendor?.metadata?.linkedin_url;
    },
    vendorTikTok() {
      return this?.selectedVendor?.metadata?.tiktok_url;
    },
    vendorInstagram() {
      return this?.selectedVendor?.metadata?.instagram_url;
    },
  },
  created() {
    // const vendorIdParams = this.$route.params.vendorId;
    // this.vendorID = vendorIdParams;
    // console.log("vendor id: ", vendorIdParams);
    this.getSingleVendorByID();
  },
  methods: {
    async getSingleVendorByID() {
      try {
        const apiClient = await this.getBoldClient();
        const response = await apiClient.listVendors(
          {
            limit: 1,
            expand: "media address",
          },
          {
            filter: { _id: this.getVendorId },
          }
        );

        const singleVendor = response.data.result.records[0];

        if (singleVendor) {
          // console.log("Vendor:", singleVendor);

          this.selectedVendor = {
            name: singleVendor.name || "",
            description: singleVendor.description || "",
            categories: singleVendor.categories || [],
            contact: singleVendor.contact || {},
            address: singleVendor.address || {},
            metadata: singleVendor.metadata || {},
          };

          this.selectedVendor.contact = {
            name: this.selectedVendor.contact.name || "",
            title: this.selectedVendor.contact.title || "",
            email: this.selectedVendor.contact.email || "",
            phone: this.selectedVendor.contact.phone || "",
          };

          this.selectedVendor.address = {
            lines: this.selectedVendor.address.lines || [],
            city: this.selectedVendor.address.city || "",
            state: this.selectedVendor.address.state || "",
            postalCode: this.selectedVendor.address.postalCode || "",
            country: this.selectedVendor.address.country || "",
            _id: this.selectedVendor.address._id || "",
          };

          this.selectedVendor.metadata = {
            website_url: this.selectedVendor.metadata.website_url || "",
            twitter_url: this.selectedVendor.metadata.twitter_url || "",
            linkedin_url: this.selectedVendor.metadata.linkedin_url || "",
            tiktok_url: this.selectedVendor.metadata.tiktok_url || "",
            instagram_url: this.selectedVendor.metadata.instagram_url || "",
          };

          // console.log("media", singleVendor.media);
          const mediaArray = singleVendor.media || [];
          this.createUrl(mediaArray, apiClient);
          this.isLoading = false;
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error);
        // Handle the error or display an error message here
      }
    },

    createUrl(mediaArr, api) {
      let mediaUrlArr = [];

      for (let i = 0; i < mediaArr.length; i++) {
        let mediaId = mediaArr[i]._id;
        let mediaUrl = api.utils.getMediaUrl(mediaId);
        // {_id: mediaId, url: mediaUrl}
        mediaUrlArr.push({ _id: mediaId, url: mediaUrl });
      }
      this.media = mediaUrlArr;
    },
  },
};
</script>

<style scoped>
.dropdown-toggle::after {
  border-top: none;
}
</style>
