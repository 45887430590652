<template>
    <div class="modal-mask p-0 p-sm-10 ">
      <div class="card animated fadeInUp">
        <div class="card-body d-flex flex-column container-fluid" >
          <slot></slot>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "FixedModalSmall",
};
</script>

<style scoped>
.modal-mask {
  /* padding: 1rem; */
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, opacity 0.5s ease;
  overflow: auto; /* Allow the modal-mask to be scrollable if needed */
}


.card {
  max-width: 750px;
  margin: auto;
  overflow: auto; /* Allow the modal-container to be scrollable if content overflows */
}
</style>
