<template>
  <div class="container-fluid">
    <div class="d-flex align-items-baseline flex-wrap">
      <h2 class="display-5 text-start mb-0 ">
        <span class="fs-2 me-1">Products by</span><span class="text-highlight-primary">{{ vendorObj.name }}</span>
      </h2>
    </div>
  </div>
  <!-- Content -->
  <div
    class="container-fluid"
    v-if="!productsAreLoading && products.length !== 0"
  >
    <div class="row row-cols-2 row-cols-md-4 row-cols-lg-5 mt-2 gy-4">
      <ProductsByVendorListItem
        v-for="product in products"
        :key="product._id"
        :product="product"
        :media="product.media"
      >
      </ProductsByVendorListItem>
    </div>
  </div>
</template>

<script>
import ProductsByVendorListItem from "./ProductsByVendorListItem.vue";
export default {
  name: "ProductsByVendorList",
  components: { ProductsByVendorListItem },
  props: ["vendorObj"],
  data() {
    return {
      products: [],
      productsAreLoading: true,
    };
  },
  computed: {
    getVendorName() {
      return this.vendorObj.name;
    },
  },
  created() {
    // const vendorId = this.$route.params.vendorId;
    // console.log("vendor id: ", vendorId);

    const vendorName = this.vendorObj.name;

    this.listProductsByVendor(vendorName);
  },
  methods: {
    async listProductsByVendor(vendorName) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      api
        .listProducts(
          {
            limit: 15,
            // sort: "-name",
            expand: "vendor media kit",
          },
          {
            filter: {
              "kit.sku": { $exists: false },
              "vendor.name": vendorName,
            },
          }
        )
        .then((response) => {
          this.productsAreLoading = false;
          this.products = response.data.result.records;
          this.count = response.data.result.count;
          // console.log("products by vendor", response.data.result.records);
        })
        .catch((error) => {
          this.productsAreLoading = false;
          console.log("products did not load", error);
        });
    },
  },
};
</script>

<style scoped></style>
