<template>
  <template v-if="showDeleteModal">
    <Teleport to="#modal">
      <TheModal
        @closeModal="closeModal"
        :open="showDeleteModal"
        :showModalHeader="true"
        :showModalBody="true"
        :showModalFooter="false"
        :closeOnClickOutside="false"
        title="Delete this budget?"
      >
        <ModalBody>
          <DeleteData
            :budgets="[budget]"
            @closeModal="closeModal"
            @confirmDelete="confirmDelete()"
          ></DeleteData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="budget">
    <div class="tw-flex tw-gap-2">
      <template
        v-if="!showBudgetForm && !campaignBuilderView && !isBudgetListItem"
      >
        <TheCard>
          <TheBox :withPadding="true" class="tw-min-w-96">
            <BlockStack :gap="400">
              <BlockStack :gap="100">
                <p
                  class="tw-pointer-events-none tw-text-lg tw-font-bold tw-m-0 tw-text-gray-900"
                >
                  Budget owner
                </p>
                <div>
                  <p
                    class="tw-pointer-events-none tw-text-sm tw-m-0 tw-text-gray-900 tw-capitalize"
                  >
                    {{ budgetUserName }}
                  </p>
                  <p
                    class="tw-pointer-events-none tw-text-sm tw-m-0 tw-text-gray-900"
                  >
                    {{ budgetUserEmail }}
                  </p>
                </div>
              </BlockStack>
              <BlockStack :gap="100">
                <p
                  class="tw-pointer-events-none tw-text-lg tw-font-bold tw-m-0 tw-text-gray-900"
                >
                  Created on
                </p>
                <div>
                  <p
                    class="tw-pointer-events-none tw-text-sm tw-m-0 tw-text-gray-900"
                  >
                    {{ dateCreated }}
                  </p>
                </div>
              </BlockStack>
              <template v-if="budgetDescription">
                <BlockStack :gap="100">
                  <p
                    class="tw-pointer-events-none tw-text-lg tw-font-bold tw-m-0 tw-text-gray-900"
                  >
                    Purpose
                  </p>
                  <div>
                    <p
                      class="tw-pointer-events-none tw-text-sm tw-m-0 tw-text-gray-900"
                    >
                      {{ budgetDescription }}
                    </p>
                  </div>
                </BlockStack>
              </template>
            </BlockStack>
          </TheBox>
        </TheCard>
      </template>
      <TheCard
        class="tw-w-full tw-relative tw-overflow-visible"
        :class="{
          'tw-border-solid tw-border-2 tw-border-gray-300': campaignBuilderView,
        }"
      >
        <BlockStack :gap="200">
          <TheBox :noBottomPadding="true">
            <p
              class="tw-pointer-events-none tw-block tw-truncate tw-text-lg tw-m-0 tw-text-gray-900 tw-capitalize"
            >
              {{ budgetName }}
            </p>
            <p
              class="tw-pointer-events-none tw-inline-block tw-text-xs tw-m-0 tw-text-gray-900 tw-py-0.5 tw-px-2 tw-rounded-md tw-bg-stone-200"
            >
              Resets {{ recurringPeriod }} --- {{ nextRenewalDate }}
            </p>
          </TheBox>
          <div class="tw-flex-1 tw-w-full">
            <TheBox :noTopPadding="true">
              <BlockStack :gap="300" class="tw-w-full">
                <template
                  v-if="
                    !showBudgetForm && !hideSettingsButton && !isBudgetListItem
                  "
                >
                  <div>
                    <TheButton
                      size="small"
                      variant="secondary"
                      :hasLeadingIcon="true"
                      @click="toggleBudgetSettingsAndInitializeBudgetForm()"
                      class="tw-absolute tw-top-2 tw-right-2 tw-z-10"
                    >
                      <template #leading-icon
                        ><b-icon-gear></b-icon-gear
                      ></template>
                      <template #text>Settings</template>
                    </TheButton>
                  </div>
                </template>
                <template v-if="isBudgetListItem">
                  <div>
                    <DropdownMenu
                      class="tw-absolute tw-top-2 tw-right-2 tw-z-10"
                      :iconDropdown="true"
                      :items="budgetListItemDropdownList(budget)"
                    >
                      <!-- budgetListItemDropdownList -->
                      <template #icon>
                        <b-icon-three-dots-vertical
                          class="tw-text-black tw-z-0"
                        ></b-icon-three-dots-vertical>
                      </template>
                    </DropdownMenu>
                  </div>
                </template>
                <BlockStack :gap="100" class="tw-h-full tw-w-full">
                  <div class="tw-flex tw-items-center tw-gap-2">
                    <p
                      class="tw-pointer-events-none tw-inline-block tw-truncate tw-text-lg tw-m-0 tw-text-gray-900 tw-font-bold"
                    >
                      Budget Limit: ${{ budgetLimitInDollars }}
                    </p>
                    <TheTooltip
                      :toolTip="true"
                      :tooltipContent="limitTooltipContent"
                    >
                      <template #icon>
                        <b-icon-question-circle
                          class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                        ></b-icon-question-circle>
                      </template>
                    </TheTooltip>
                  </div>
                  <div>
                    <div
                      class="tw-overflow-hidden tw-rounded-md tw-bg-teal-400"
                    >
                      <div
                        class="tw-h-6 tw-rounded-md tw-bg-teal-800"
                        :style="redeemedProgressBarStyle"
                      ></div>
                    </div>
                  </div>
                  <!-- redeemed -->
                  <div class="tw-flex tw-justify-between">
                    <div class="tw-flex tw-items-center tw-gap-1">
                      <p
                        class="tw-pointer-events-none tw-inline-block tw-truncate tw-text-sm tw-m-0 tw-text-gray-900"
                      >
                        <b-icon-circle-fill
                          class="t tw-text-teal-800 tw-w-2"
                        ></b-icon-circle-fill>
                        Redeemed
                      </p>
                      <TheTooltip
                        :toolTip="true"
                        :tooltipContent="redeemedTooltipContent"
                      >
                        <template #icon>
                          <b-icon-question-circle
                            class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                          ></b-icon-question-circle>
                        </template>
                      </TheTooltip>
                    </div>
                    <p
                      class="tw-pointer-events-none tw-text-lg tw-m-0 tw-text-gray-900 tw-text-left"
                    >
                      ${{ `${getTotalBudgetRedeemed}.00` }}
                    </p>
                  </div>

                  <!-- outstanding -->
                  <div class="tw-flex tw-justify-between">
                    <!-- legend -->
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <p
                        class="tw-pointer-events-none tw-inline-block tw-truncate tw-text-sm tw-m-0 tw-text-gray-900"
                      >
                        <b-icon-circle-fill
                          class="t tw-text-orange-700 tw-w-2"
                        ></b-icon-circle-fill>
                        Outstanding
                      </p>
                      <TheTooltip
                        :toolTip="true"
                        :tooltipContent="outstandingTooltipContent"
                      >
                        <template #icon>
                          <b-icon-question-circle
                            class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                          ></b-icon-question-circle>
                        </template>
                      </TheTooltip>
                    </div>
                    <!-- value -->
                    <p
                      class="tw-pointer-events-none tw-text-lg tw-m-0 tw-text-gray-900 tw-text-left"
                    >
                      <!-- ${{ getTotalBudgetOutstanding }} -->
                      ${{ `${getTotalBudgetOutstanding}.00` }}
                    </p>
                  </div>

                  <!-- available -->
                  <div class="tw-flex tw-justify-between">
                    <!-- legend -->
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <p
                        class="tw-pointer-events-none tw-inline-block tw-truncate tw-text-sm tw-m-0 tw-text-gray-900"
                      >
                        <b-icon-circle-fill
                          class="t tw-text-teal-400 tw-w-2"
                        ></b-icon-circle-fill>
                        Available
                      </p>
                      <TheTooltip
                        :toolTip="true"
                        :tooltipContent="availableTooltipContent"
                      >
                        <template #icon>
                          <b-icon-question-circle
                            class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                          ></b-icon-question-circle>
                        </template>
                      </TheTooltip>
                    </div>
                    <!-- value -->
                    <p
                      class="tw-pointer-events-none tw-text-lg tw-m-0 tw-text-gray-900 tw-text-left"
                    >
                      ${{ `${getTotalBudgetAvailable}.00` }}
                    </p>
                  </div>
                </BlockStack>
                <template v-if="!campaignBuilderView">
                  <template v-if="!isCheckingBalance">
                    <template v-if="isBudgetListItem">
                      <template
                        v-if="
                          isOutstandingGreaterThanAvailable && !isBudgetLimitMet
                        "
                      >
                        <div
                          class="tw-flex tw-items-center tw-gap-1 tw-border-solid tw-border-orange-400 tw-border-2 tw-p-4 tw-rounded-md"
                        >
                          <div
                            class="tw-flex tw-items-top tw-gap-2 tw-font-bold tw-text-sm"
                          >
                            <b-icon-exclamation-triangle-fill
                              class="tw-text-orange-400 tw-mt-0.5"
                            ></b-icon-exclamation-triangle-fill>
                            <p class="tw-m-0 tw-text-gray-900">
                              Outstanding gifts exceed Budget Limit.
                            </p>
                          </div>
                          <TheTooltip
                            :toolTip="true"
                            :tooltipContent="outstandingTooltipContentListItem"
                          >
                            <template #icon>
                              <b-icon-question-circle
                                class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                              ></b-icon-question-circle>
                            </template>
                          </TheTooltip>
                        </div>
                      </template>
                      <template v-if="isBudgetLimitMet">
                        <div
                          class="tw-flex tw-items-center tw-gap-1 tw-border-solid tw-border-red-600 tw-border-2 tw-p-4 tw-rounded-md"
                        >
                          <div
                            class="tw-flex tw-items-top tw-gap-2 tw-font-bold tw-text-sm"
                          >
                            <b-icon-exclamation-triangle-fill
                              class="tw-text-red-600 tw-mt-0.5"
                            ></b-icon-exclamation-triangle-fill>
                            <p class="tw-m-0 tw-text-gray-900">
                              Budget Limit has been reached.
                            </p>
                          </div>
                          <TheTooltip
                            :toolTip="true"
                            :tooltipContent="limitMetTooltipContent"
                          >
                            <template #icon>
                              <b-icon-question-circle
                                class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                              ></b-icon-question-circle>
                            </template>
                          </TheTooltip>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-if="
                          isOutstandingGreaterThanAvailable && !isBudgetLimitMet
                        "
                      >
                        <div
                          class="tw-flex-1 tw-w-full tw-border-solid tw-border-orange-400 tw-border-2 tw-p-4 tw-rounded-md"
                        >
                          <div class="tw-flex tw-items-top tw-gap-2">
                            <b-icon-exclamation-triangle-fill
                              class="tw-text-orange-400 tw-flex-shrink-0 tw-mt-0.5"
                            ></b-icon-exclamation-triangle-fill>
                            <BlockStack :gap="200">
                              <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                                Outstanding gifts for this budget exceed the
                                Budget Limit. Once the budget limit is reached,
                                any outstanding redemption attempts will be
                                declined.
                              </p>
                              <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                                To ensure all recipients will be able to redeem,
                                increase the Budget Limit, reduce the number of
                                recipients, or lower the Gift Card Amount for
                                this campaign.
                              </p>
                            </BlockStack>
                          </div>
                        </div>
                      </template>
                      <template v-if="isBudgetLimitMet">
                        <div
                          class="tw-flex-1 tw-w-full tw-border-solid tw-border-red-600 tw-border-2 tw-p-4 tw-rounded-md"
                        >
                          <div
                            class="tw-flex tw-items-top tw-gap-2 tw-text-sm tw-w-full"
                          >
                            <b-icon-exclamation-triangle-fill
                              class="tw-text-red-600 tw-flex-shrink-0 tw-mt-0.5"
                            ></b-icon-exclamation-triangle-fill>
                            <BlockStack :gap="200">
                              <p class="tw-text-sm tw-m-0 tw-text-gray-900">
                                You have reached the limit for this budget
                                period. Unless the budget limit is updated, any
                                redemption attempts for outstanding gift card
                                offers will be declined until the budget resets
                                {{ nextRenewalDate }}.
                                <span v-if="!isBudgetListItem">
                                  To update your budget, tap Settings above.
                                </span>
                              </p>
                            </BlockStack>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </template>
              </BlockStack>
            </TheBox>
            <template v-if="showBudgetForm">
              <div
                class="tw-flex-1 tw-w-full tw-border-solid tw-border-2 tw-border-x-0 tw-border-b-0 tw-border-gray-100 tw-pb-4 sm:tw-pb-6"
              ></div>
              <div class="tw-flex-1 tw-w-full">
                <TheBox :noTopPadding="true">
                  <BlockStack :gap="400">
                    <NotificationMessage
                      :scope="$options.name"
                    ></NotificationMessage>

                    <!-- name -->
                    <BlockStack :gap="100">
                      <label
                        for="budgetName"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Name
                        <span
                          v-if="!budgetForm.name"
                          class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                          >required field</span
                        >
                      </label>
                      <div class="tw-flex tw-gap-2 tw-items-center">
                        <input
                          v-model="budgetForm.name"
                          type="text"
                          id="budgetName"
                          placeholder="e.g. Customers"
                          :class="{
                            'tw-ring-red-300': !budgetForm.name,
                            'tw-ring-green-500': budgetForm.name,
                          }"
                          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                        />
                      </div>
                    </BlockStack>
                    <!-- description -->
                    <BlockStack :gap="100">
                      <label
                        for="budgetDescription"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Purpose (optional)
                      </label>
                      <textarea
                        rows="3"
                        v-model="budgetForm.metadata.description"
                        name="budgetDescription"
                        id="budgetDescription"
                        placeholder="Details about how and when to use this budget."
                        class="tw-block tw-px-2.5 tw-py-2 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                      ></textarea>
                      <!-- </template> -->
                    </BlockStack>
                    <!-- limit -->
                    <BlockStack :gap="100">
                      <label
                        for="budgetLimit"
                        class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        How much do you want to budget?<span
                          v-if="!budgetForm.limit"
                          class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                          >required field</span
                        >
                      </label>
                      Amount must be a whole number of $50 or more
                      <div
                        class="tw-relative tw-rounded-md tw-shadow-sm tw-w-auto"
                      >
                        <div
                          class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                        >
                          <b-icon-currency-dollar
                            class="tw-h-5 tw-w-5 tw-text-gray-400"
                            aria-hidden="true"
                          ></b-icon-currency-dollar>
                        </div>
                        <input
                          v-model="budgetForm.limit"
                          @input="validateBudgetLimitValue"
                          type="text"
                          name="budgetLimit"
                          id="budgetLimit"
                          :class="{
                            'tw-ring-red-300': !budgetForm.limit,
                            'tw-ring-green-500': budgetForm.limit,
                          }"
                          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                          placeholder="Set budget limit"
                        />
                      </div>
                      <!-- </template> -->
                    </BlockStack>
                    <!-- period -->
                    <BlockStack :gap="300">
                      <BlockStack :gap="100">
                        <label
                          for="budgetPeriod"
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                          >Budget type<span
                            v-if="!budgetForm.period"
                            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                            >required field</span
                          ></label
                        >
                        Choose between a monthly, quarterly, or yearly recurring
                        budget
                        <select
                          v-model="budgetForm.period"
                          id="budgetPeriod"
                          name="budgetPeriod"
                          class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                          :class="{
                            'tw-ring-red-300': !budgetForm.period,
                            'tw-ring-green-500': budgetForm.period,
                          }"
                        >
                          <option value="" disabled>Select schedule</option>
                          <option
                            v-for="option in periods"
                            :value="option.value"
                            :key="option.value"
                          >
                            {{ option.text }}
                          </option>
                        </select>
                      </BlockStack>
                    </BlockStack>

                    <div class="tw-flex tw-gap-2 tw-mb-1">
                      <TheButton
                        :disabled="!allItemsComplete"
                        @click="updateBudget()"
                        size="large"
                        :hasLeadingIcon="true"
                        variant="success"
                      >
                        <template #text> Save changes </template>
                        <template #leading-icon> </template>
                      </TheButton>
                      <TheButton
                        @click="toggleBudgetSettingsAndInitializeBudgetForm()"
                        size="large"
                        :hasLeadingIcon="true"
                        variant="tertiary"
                      >
                        <template #text> Cancel </template>
                        <template #leading-icon> </template>
                      </TheButton>
                    </div>
                  </BlockStack>
                </TheBox>
              </div>
            </template>
          </div>
        </BlockStack>
      </TheCard>
    </div>
  </template>
  <template v-else>
    <TheCard class="tw-w-fit">
      <BlockStack :gap="200">
        <TheBox :withPadding="true">
          <p class="tw-text-lg tw-m-0 tw-text-gray-900">
            <span class="tw-font-bold tw-text-red-600">This budget was deleted.</span> Below are
            a list of campaigns that were associated with this budget.
          </p>
        </TheBox>
      </BlockStack>
    </TheCard>
  </template>
</template>

<script>
import DeleteData from "../ui/modals/DeleteData.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import DropdownMenu from "../../components-v2/ui/forms/DropdownMenu.vue";

import { parseCronExpression } from "cron-schedule";

import moment from "moment";

export default {
  name: "BudgetStats",
  emits: ["checkForBudgets", "updateBudgetLimitTracker"],
  components: {
    DropdownMenu,
    TheTooltip,
    TheBox,
    TheCard,
    BlockStack,
    TheButton,
    NotificationMessage,
    TheModal,
    ModalBody,
    DeleteData,
  },
  props: {
    budget: { type: Object, default: null },
    campaignTotalCost: { type: Number },
    campaignBuilderView: { type: Boolean, default: false },
    hideSettingsButton: { type: Boolean, default: false },
    isBudgetListItem: { type: Boolean, default: false },
  },
  watch: {
    budget: {
      handler: "reloadStats",
      deep: true,
      immediate: true,
    },
    campaignTotalCost: {
      handler: "reloadStats",
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      budgetForm: {
        name: "",
        limit: "",
        period: "",
        metadata: {
          description: "",
        },
      },
      periods: [
        {
          text: "Monthly - resets the 1st of every month at 12 a.m.",
          value: "0 0 1 * *",
        },
        {
          text: "Quarterly - resets the 1st of every quarter at 12 a.m.",
          value: "0 0 1 */3 *",
        },
        {
          text: "Yearly - resets the 1st of January of the following year at 12 a.m.",
          value: "0 0 1 1 *",
        },
      ],
      count: 0,
      budgetBalance: 0,
      totalBudgetRedeemed: 0,
      totalBalanceRemaining: 0,
      totalGiftsAmountAllocated: 0,
      showBudgetForm: false,
      showDeleteModal: false,
      isCheckingBalance: true,
    };
  },
  async created() {},
  computed: {
    allItemsComplete() {
      return (
        this.budgetForm.name && this.budgetForm.limit && this.budgetForm.period
      );
    },
    user() {
      return this?.$store?.state?.auth?.user;
    },
    budgetId() {
      return this?.budget?._id;
    },
    budgetUserName() {
      return `${this?.budget?.user?.firstName} ${this?.budget?.user?.lastName}`;
    },
    budgetUserEmail() {
      return this?.budget?.user?.email;
    },
    budgetName() {
      return this?.budget?.name;
    },
    budgetDescription() {
      return this?.budget?.metadata?.description.trim();
    },
    budgetPeriod() {
      return this?.budget?.users[0]?.period !== ""
        ? this?.budget?.users[0]?.period
        : null;
    },
    budgetLimitInDollars() {
      const limit = this?.budget?.users[0]?.limit || 0;
      return limit / 100;
    },
    budgetBalanceInDollars() {
      const limit = this?.budgetBalance || 0;
      return limit / 100;
    },
    nextRenewalDate() {
      const now = moment();
      let nextDate;
      switch (this?.budget?.users[0]?.period) {
        case "0 0 * * 0":
          nextDate = now.add(1, "week").startOf("week");
          break;
        case "0 0 1 * *":
          nextDate = now.add(1, "month").startOf("month");
          break;
        case "0 0 1 */3 *":
          nextDate = now.add(3, "months").startOf("quarter");
          break;
        case "0 0 1 1 *":
          nextDate = now.add(1, "year").startOf("year");
          break;
        case "* * * * *":
          nextDate = now.add(1, "minute").startOf("minute");
          break;
        default:
          return "Invalid cron expression";
      }
      return `on ${nextDate.format("MMMM Do, YYYY")} at ${nextDate.format(
        "h:mm:ss a"
      )}`;
    },
    recurringPeriod() {
      const periodMap = {
        "0 0 1 * *": "Monthly",
        "0 0 1 */3 *": "Quarterly",
        "0 0 1 1 *": "Yearly",
      };

      const period = this.budget?.users?.[0]?.period;
      return periodMap[period] || "Unknown";
    },

    /** form data */
    budgetFormName() {
      return this?.budgetForm?.name.trim();
    },
    budgetFormPeriod() {
      return this?.budgetForm?.period !== "" ? this?.budgetForm?.period : null;
    },
    budgetFormDescription() {
      return this?.budgetForm?.metadata?.description.trim();
    },
    budgetFormLimitInPennies() {
      const limit = parseInt(this?.budgetForm?.limit, 10);
      return isNaN(limit) ? 0 : limit * 100;
    },

    /** aggregations */
    getTotalBudgetRedeemed() {
      return this.budgetLimitInDollars - this.totalBalanceRemaining;
      // totalBudgetRedeemed
    },
    getTotalBudgetAvailable() {
      return this.budgetLimitInDollars - this.getTotalBudgetRedeemed;
    },
    // getTotalGiftsAmountAllocated
    getTotalBudgetOutstanding() {
      if (!this.totalGiftsAmountAllocated) {
        return 0;
      }
      return this.totalGiftsAmountAllocated - this.getTotalBudgetRedeemed;
    },
    getTotalGiftsAmountAllocated() {
      return `${this.totalGiftsAmountAllocated}.00`;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },

    dateCreated() {
      return this.getTimeStamp();
    },

    /** progress bars */
    redeemedProgressBarStyle() {
      return {
        width: `${
          (this.getTotalBudgetRedeemed / this.budgetLimitInDollars) * 100
        }%`,
      };
    },
    isBudgetLimitMet() {
      return this.totalBalanceRemaining === 0;
    },
    isOutstandingGreaterThanAvailable() {
      return this.getTotalBudgetOutstanding > this.getTotalBudgetAvailable;
    },

    /** tooltips */
    limitTooltipContent() {
      return "Total dollars redeemable from a budget.";
    },
    redeemedTooltipContent() {
      return "Total dollars from cleared transactions.";
    },
    outstandingTooltipContent() {
      return "Total dollars of unredeemed eGift cards.";
    },
    outstandingTooltipContentListItem() {
      return "Once the budget limit is reached, any outstanding redemption attempts will be declined.";
    },
    limitMetTooltipContent() {
      return "Unless the budget limit is updated, any redemption attempts for outstanding gift card offers will be declined until the budget resets.";
    },
    availableTooltipContent() {
      return "Remaining budget funds.";
    },
    getBudgetPeriodStartAndEndTimestamps() {
      const cron = this?.budget?.users[0]?.period
        ? parseCronExpression(this?.budget?.users[0]?.period)
        : false;
      let start = cron && cron.getPrevDate().toISOString();
      let end = cron && cron.getNextDate().toISOString();

      return {
        startOfBudgetPeriod: start,
        endOfBudgetPeriod: end,
      };
    },
  },
  methods: {
    /** aggregation for invoices: amount the user has actually had redeemed against their budget limit */
    async aggregateInvoicesForThisBudget() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      try {
        let requestBody = {};
        let filter = {};

        let group = {
          _id: null,
          selection: {
            "metadata.amount": "$sum",
          },
        };
        filter = {
          "user._id": this?.budget?.user?._id,
          $and: [
            { "metadata.budget": { $exists: true } },
            {
              $or: [
                {
                  "campaign.metadata.budget": { $toObjectId: this.budgetId },
                },
                {
                  "campaign.metadata.budget": {
                    $toObjectId: this.budgetId,
                  },
                },
              ],
            },
            {
              "_.id": {
                $gte: {
                  $oidTime:
                    this.getBudgetPeriodStartAndEndTimestamps
                      .startOfBudgetPeriod,
                },
              },
            },
            {
              "_.id": {
                $lte: {
                  $oidTime:
                    this.getBudgetPeriodStartAndEndTimestamps.endOfBudgetPeriod,
                },
              },
            },
          ],
        };
        requestBody = {
          filter: filter,
          group: group,
        };
        const queryParameters = {
          limit: 10000,
          expand: "user metadata campaign campaign.metadata",
        };
        const aggregateInvoicesRes = await api.listInvoices(
          queryParameters,
          requestBody
        );

        const totalRedeemed =
          aggregateInvoicesRes?.data?.result?.records[0]
            ?.metadata_amount__sum || 0;
        this.totalBudgetRedeemed = totalRedeemed / 100;
      } catch (error) {
        console.log("budgetCreationRes error: ", error);
      }
    },
    async checkBudgetBalance() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.isCheckingBalance = true;

      try {
        const budgetId = this?.budgetId;
        const checkBudgetBalanceRes = await api.checkBudgetBalance(
          // {
          budgetId
          // }
        );
        console.log(
          "checkBudgetBalanceRes.data.balance: ",
          checkBudgetBalanceRes.data.balance
        );
        // this.budgetBalance = checkBudgetBalanceRes.data.balance;
        // this.totalBudgetRedeemed = checkBudgetBalanceRes.data.balance / 100;
        this.totalBalanceRemaining = checkBudgetBalanceRes.data.balance / 100;
      } catch (error) {
        console.log("budgetCreationRes error: ", error);
      } finally {
        this.isCheckingBalance = false;
      }
    },

    /** aggregation for mail campaigns: amount the user has sent out against their budget limit */
    async aggregateCampaignsForThisBudget() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      console.log(
        "getBudgetPeriodStartAndEndTimestamps",
        this.getBudgetPeriodStartAndEndTimestamps
      );
      console.log("aggregateCampaignsForThisBudget");
      try {
        let requestBody = {};
        let filter = {};

        let group = {
          _id: null,
          selection: {
            "metadata.gift_card_amount": "$sum",
          },
        };
        filter = {
          "user._id": this?.budget?.user?._id,
          "metadata.campaign_status": {
            $eq: "sent",
          },
          $and: [
            { "metadata.budget": { $exists: true } },
            {
              $or: [
                {
                  "metadata.budget": { $toObjectId: this.budgetId },
                },
                {
                  "metadata.budget": { $toObjectId: this.budgetId },
                },
              ],
            },
            {
              "metadata.date_sent": {
                $gte: this.getBudgetPeriodStartAndEndTimestamps
                  .startOfBudgetPeriod,
              },
            },
            {
              "metadata.date_sent": {
                $lte: this.getBudgetPeriodStartAndEndTimestamps
                  .endOfBudgetPeriod,
              },
            },
          ],
        };
        requestBody = {
          filter: filter,
          // group: group,
        };
        const queryParameters = {
          limit: 10000,
          expand: "user metadata",
        };
        const aggregateCampaignsRes = await api.listMailCampaigns(
          queryParameters,
          requestBody
        );
        const campaigns = aggregateCampaignsRes?.data?.result?.records;
        console.log("campaings", campaigns);
        const totalAllocated = this.calculateTotalCost(campaigns) || 0;

        this.totalGiftsAmountAllocated = totalAllocated;
        console.log("campaigns: ", campaigns);
      } catch (error) {
        console.log("budgetCreationRes error: ", error);
      }
    },
    calculateTotalCost(campaigns) {
      return campaigns.reduce((total, campaign) => {
        const recipientsCount = campaign.recipients.length;
        const giftCardAmount = campaign.metadata.gift_card_amount;
        const campaignCost = recipientsCount * giftCardAmount;
        return total + campaignCost;
      }, 0);
    },

    /** updating budget details */
    async updateBudget() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      const api = await this.getBoldClient();
      try {
        const upsertBudgetRes = await api.upsertBudget(null, {
          filter: { _id: this.budgetId },
          patch: {
            name: this.budgetFormName,
            "metadata.description": this.budgetFormDescription,
            "users.0.limit": this.budgetFormLimitInPennies,
            "users.0.period": this.budgetFormPeriod,
          },
        });
        console.log(
          "upsertBudgetRes.data.result._id: ",
          upsertBudgetRes.data.result._id
        );
        const budgetId = upsertBudgetRes.data.result._id;
        console.log("budgetId: ", budgetId);

        this.handleSuccess(budgetId, "upsertBudget");
      } catch (error) {
        console.log("budgetCreationRes error: ", error);
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your campaign."
        );
      }
    },
    validateBudgetLimitValue() {
      const trimmedValue = this?.budgetForm?.limit?.trim();
      const value = parseFloat(trimmedValue);

      // Clear any existing timeout to avoid multiple resets
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }

      // Check if the trimmed value is a valid number and meets the criteria
      if (!Number.isNaN(value) && value >= 50 && Number.isSafeInteger(value)) {
        this.budgetForm.limit = Math.round(value).toString(); // Convert back to string
      } else {
        // Set a timeout to reset the value to "0" after 350 ms
        this.timeoutId = setTimeout(() => {
          this.budgetForm.limit = "0"; // Reset to "0" string
        }, 350);
      }
    },
    toggleBudgetSettingsAndInitializeBudgetForm() {
      this.showBudgetForm = !this.showBudgetForm;
      this.initializeBudgetForm();
    },
    initializeBudgetForm() {
      // Assuming `budgetData` has a similar structure to your `form` data
      this.budgetForm.name = this.budgetName || "";
      this.budgetForm.limit = this.budgetLimitInDollars || "";
      this.budgetForm.period = this.budgetPeriod;
      this.budgetForm.metadata.description = this.budgetDescription || "";
    },

    /** dropdown list */
    budgetListItemDropdownList(budget) {
      // campaign can only be edited by it's user
      return [
        {
          label: "View and edit",
          isRouterLink: true,
          to: `/budget/${budget.id}`,
        },
        {
          label: "Delete",
          isDeleteAction: true,
          action: () => this.toggleDeleteModal(),
        },
      ];
    },

    /** handle deleting template */
    async confirmDelete() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      const budgetIdToDelete = this?.budget?._id;

      try {
        const response = await api.deleteBudgets(null, {
          filter: { _id: budgetIdToDelete },
        });
        this.handleSuccess(response, "deleteBudgets");
      } catch (error) {
        console.error("budget was NOT deleted", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignIsSaving = false;
      }
    },

    /** handle modals */
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    closeModal() {
      this.showDeleteModal = false;
    },
    /** handle success and errors */
    handleSuccess(response, api) {
      this.showBudgetForm = false;
      if ((response, api === "upsertBudget")) {
        if (this.campaignBuilderView) {
          this.$emit("checkForBudgets", response);
        } else {
          this.$emit("checkForBudgets");
        }
        let successMessage = "Budget was successfully updated.";
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: successMessage,
          duration: 4000,
          id: Date.now(),
        });
      } else if ((response, api === "deleteBudgets")) {
        this.showDeleteModal = false;
        let successMessage = "Budget was successfully deleted.";
        this.$emit("checkForBudgets");
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: successMessage,
          duration: 4000,
          id: Date.now(),
        });
      }
    },
    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error.response) {
        // Process API error message if customMessage is not provided
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.source.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 8000,
        id: Date.now(),
      });
      console.error(errorMessage); // Keep for debugging purposes
    },

    /** other */
    getTimeStamp() {
      const objectIdToTimestamp = require("objectid-to-timestamp");
      const originalDate = new Date(objectIdToTimestamp(this.budget.id));
      return moment(originalDate).format("MMMM Do, YYYY");
    },
    updateBudgetLimitTracker() {
      const totalAllocated =
        this.campaignTotalCost + this.totalGiftsAmountAllocated;
      const newAllocatedGreaterThanBudgetLimit =
        totalAllocated > this.budgetLimitInDollars;

      if (this.campaignBuilderView) {
        const budgetLimitTracker = {
          isBudgetLimitMet: this.isBudgetLimitMet,
          existingAllocatedGreaterThanBudgetLimit:
            this.isOutstandingGreaterThanAvailable,
          newAllocatedGreaterThanBudgetLimit:
            newAllocatedGreaterThanBudgetLimit,
        };
        console.log("emit budgetLimitTracker", budgetLimitTracker);

        this.$emit("updateBudgetLimitTracker", budgetLimitTracker);
      }
    },
    async reloadStats() {
      await this.checkBudgetBalance();
      await this.aggregateCampaignsForThisBudget();
      this.updateBudgetLimitTracker();
    },
  },
};
</script>

<style scoped></style>
