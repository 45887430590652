<template>
  <template v-if="paymentSetupStatus.success">
    <ThePage class="tw-flex tw-justify-center tw-h-dvh">
      <div
        class="tw-bg-blue-900 tw-flex tw-flex-col tw-items-center tw-p-5 tw-justify-center tw-gap-6 tw-w-96 tw-h-96 tw-mx-auto tw-rounded-lg"
      >
        <b-icon-check-circle
          class="tw-text-4xl tw-text-green-500"
        ></b-icon-check-circle>
        <div class="">
          <h1 class="tw-text-2xl tw-text-center tw-text-white">
            Payment information updated!
          </h1>
        </div>
      </div>
    </ThePage>
  </template>
  <template v-if="paymentSetupStatus.failure">
    <ThePage class="tw-flex tw-justify-center tw-h-dvh">
      <div
        class="tw-bg-orange-100 tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-5 tw-gap-6 tw-w-96 tw-h-96 tw-mx-auto tw-rounded-lg"
      >
        <b-icon-exclamation-circle
          class="tw-text-4xl tw-text-green-500"
        ></b-icon-exclamation-circle>
        <h1 class="tw-text-2xl tw-text-center tw-text-orange-500">
          Payment information did not save. Please try again.
        </h1>
        <TheButton variant="primary" size="large" @click="setupPaymentMethod()">
          <template #text> Setup payment </template>
        </TheButton>
      </div>
    </ThePage>
  </template>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage";
import TheButton from "../../components-v2/ui/actions/TheButton";

export default {
  name: "PaymentSetupSuccess",
  components: { ThePage, TheButton },
  data() {
    return {
      sessionId: "",
      paymentSetupStatus: {
        success: false,
        failure: false,
      },
    };
  },
  async mounted() {
    try {
      const sessionId = this.$route.params.CHECKOUT_SESSION_ID;
      this.sessionId = this.$route.params.CHECKOUT_SESSION_ID;
      await this.savePaymentInfo(sessionId);
    } catch (error) {
      console.log("error finalizing GcMarketplaceSignup and Login", error);
    }
  },
  methods: {
    routeToAccount() {
      this.$router.push({ path: "/account" });
    },
    async savePaymentInfo(sessionId) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      try {
        const api = await this.getBoldClient();
        const savePaymentInfoRes = await api.savePaymentInfo({
          sessionId,
        });

        console.log("savePaymentInfoRes ", savePaymentInfoRes);
        // console.log("savePaymentInfoRes", savePaymentInfoRes.data.user);

        this.paymentSetupStatus.success = true;
        setTimeout(() => {
          this.routeToAccount();
        }, 1000);
      } catch (error) {
        this.paymentSetupStatus.failure = true;
        console.log("savePaymentInfo error: ", error);
      }
    },
    /** setup up payment */
    /** setup payment */
    async setupPaymentMethod() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      const successUrl =
        window.location.origin + "/payment-method/save/{CHECKOUT_SESSION_ID}";
      const cancelUrl = window.location.origin + "/account";

      try {
        const paymentMethodRes = await api.setupPaymentMethod({
          successUrl: successUrl,
          cancelUrl: cancelUrl,
        });
        const paymentUrl = paymentMethodRes.data;
        console.log("paymentMethodRes", paymentMethodRes);
        console.log("paymentUrl", paymentUrl.url);
        window.location.href = paymentUrl.url;

        // redirect user to `paymentUrl`
      } catch (error) {
        console.log("defaultPaymentRes error: ", error);
      }
    },
  },
};
</script>

<style scoped></style>
