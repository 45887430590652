<template>
  <PageHeader title="Your gifts"> </PageHeader>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <ThePage customWidthClass="tw-max-w-2xl">
    <template v-if="checkingIfUserHasCampaigns">
      <ViewRedemptionsPageSkeleton></ViewRedemptionsPageSkeleton>
    </template>
    <template v-else-if="!checkingIfUserHasCampaigns && hasData">
      <BlockStack :gap="800" customWidthClass="tw-w-full tw-mx-auto">
        <PageSection>
          <RedemptionHistoryList
            :myGiftsView="true"
            :campaignData="campaignData"
          ></RedemptionHistoryList>
        </PageSection>
      </BlockStack>
    </template>
    <template v-else>
      <PageSection class="tw-w-full">
        <PageDataFailedToLoad
          emptyState="invoiceDetails"
        ></PageDataFailedToLoad>
      </PageSection>
    </template>
  </ThePage>
</template>

<script>
import RedemptionHistoryList from "../../components-v2/redemptions/RedemptionHistoryList";
import PageHeader from "../../components-v2/ui/layout/PageHeader";
import ThePage from "../../components-v2/ui/layout/ThePage";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import ViewRedemptionsPageSkeleton from "../../components-v2/ui/skeletons/page-skeletons/ViewRedemptionsPageSkeleton.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import PageDataFailedToLoad from "../../components-v2/ui/feedback/PageDataFailedToLoad.vue";
import { listMailCampaigns } from "@/services/campaignService";

export default {
  name: "ViewRedemptions",
  components: {
    PageDataFailedToLoad,
    PageSection,
    ViewRedemptionsPageSkeleton,
    NotificationMessage,
    RedemptionHistoryList,
    PageHeader,
    ThePage,
    BlockStack,
  },
  created() {
    this.loadCampaigns();
  },
  data() {
    return {
      campaignData: { campaigns: null, count: 0 },
      checkingIfUserHasCampaigns: true,
      userHasCampaigns: false,
    };
  },
  computed: {
    hasData() {
      const hasCampaigns = Array.isArray(this.campaignData.campaigns);
      return !this.checkingIfUserHasCampaigns && hasCampaigns;
    },
    User() {
      return this?.$store?.state?.auth?.user;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async loadCampaigns() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let requestBody = {};
      const userEmail = this?.$store?.state?.auth?.user?.email;
      requestBody = {
        $and: [
          {
            "recipients.contact.email": {
              $in: [userEmail],
            },
          },
          {
            $or: [
              { "metadata.campaign_status": { $eq: "redeemed" } },
              { "metadata.campaign_status": { $eq: "sent" } },
              { "metadata.isRedeemed": true },
              { "metadata.finalizations": { $exists: true } },
            ],
          },
        ],
      };

      const queryParameters = {
        limit: 1000,
        sort: "-_id",
        expand: "recipients",
      };

      try {
        const response = await api.listMailCampaigns(queryParameters, {
          filter: requestBody,
        });
        this.campaignData.campaigns = response.data.result.records;
        this.campaignData.count = response.data.result.count;
        this.campaignData.count = response.data.result.count;
      } catch (error) {
        console.log("campaigns did not load", error);
      } finally {
        this.checkingIfUserHasCampaigns = false;
      }
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.checkingIfuserHasCampaigns = false;
      this.checkingIfUserHasCampaigns = false;
      console.error(errorMessage);
    },
  },
};
</script>

<style scoped></style>
