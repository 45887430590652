<template>
  <ConfirmDeleteModal
    :media="true"
    @confirm="deleteThisMediaFile()"
    @cancel="closeConfirmDeleteModal()"
    v-if="isConfirmDeleteModalVisible"
  >
    <template #heading>Delete this image?</template>
    <template #subheading> </template>
  </ConfirmDeleteModal>
  <CategoriesListModal
    v-if="isCategoryListModalVisible"
    @cancel="closeCategoryListModal"
    @setNewCategories="handleNewCategories"
  ></CategoriesListModal>
  <CreateCategoryModal
    v-if="isNewCategoryModalVisible"
    @cancel="closeCreateCategoryModal"
    @setNewCategory="setExistingCategory"
  ></CreateCategoryModal>
  <TagsListModal
    v-if="isTagsListModalVisible"
    @cancel="closeTagsListModal"
    @setNewTag="handleNewTags"
  ></TagsListModal>
  <CreateTagsModal
    v-if="isNewTagModalVisible"
    @cancel="closeCreateTagModal"
    @setNewTag="setExistingTag"
  ></CreateTagsModal>
  <NeighborhoodsListModal
    v-if="isNeighborhoodsListModalVisible"
    @cancel="closeNeighborhoodsListModal"
    @setNewNeighborhood="handleNewNeighborhoods"
  ></NeighborhoodsListModal>
  <CreateNeighborhoodsModal
    v-if="isNewNeighborhoodModalVisible"
    @cancel="closeCreateNeighborhoodModal"
    @setNewNeighborhood="setExistingNeighborhood"
  ></CreateNeighborhoodsModal>
  <VendorOnlineStorePlatformListModal
    v-if="isVendorOnlineStorePlatformListModalVisible"
    @cancel="closeOnlineStorePlatformsListModal"
    @setNewVendorOnlineStorePlatform="handleNewOnlineStorePlatform"
  ></VendorOnlineStorePlatformListModal>
  <CreateVendorOnlineStorePlatformModal
    v-if="isNewVendorOnlineStorePlatformModalVisible"
    @cancel="closeCreateOnlineStorePlatformModal"
    @setNewVendorOnlineStorePlatform="setExistingOnlineStorePlatform"
  ></CreateVendorOnlineStorePlatformModal>
  <AddressListModal
    v-if="isAddressListModalVisible"
    @cancel="showAddressListModal"
    @setNewAddress="setNewAddress"
  ></AddressListModal>
  <CreateAddressModal
    v-if="isCreateAddressModalVisible"
    @cancel="showCreateAddressModal"
    @setNewAddress="setNewAddress"
  ></CreateAddressModal>
  <SuccessfulActionModal v-if="isSuccessful">
    <template #message>Successfully edited vendor!</template>
    <template #button-1
      ><button class="btn btn-primary me-2" @click.stop="returnToVendorList()">
        Edit another vendor
      </button></template
    >
    <template #button-2>
      <button class="btn btn-secondary" @click.stop="closeSuccessModal()">
        Close
      </button>
    </template>
  </SuccessfulActionModal>
  <template v-if="isLoading"
    ><div class="p-10">
      <LoadingGiftIcon></LoadingGiftIcon></div
  ></template>

  <template v-else-if="!isLoading && selectedVendor">
    <div class="content">
      <div class="row">
        <div class="col-lg-5 mb-3 mb-lg-0 text-start">
          <!-- gemeral info -->
          <div class="card">
            <div class="card-header bg-dark">
              <h4 class="card-header-title text-white">General information</h4>
            </div>

            <TheBox :withPadding="true">
              <BlockStack :gap="600" class="">
                <!-- warning -->
                <template v-if="!isGeneralInformationComplete">
                  <div
                    class="tw-flex tw-items-center tw-gap-1 tw-border-solid tw-border-red-500 tw-border-2 tw-p-4 tw-rounded-md"
                  >
                    <div
                      class="tw-flex tw-items-top tw-gap-2 tw-font-bold tw-text-sm"
                    >
                      <b-icon-exclamation-triangle-fill
                        class="tw-text-red-500 tw-mt-0.5"
                      ></b-icon-exclamation-triangle-fill>
                      <p class="tw-m-0 tw-text-gray-900">
                        Please complete required fields
                      </p>
                    </div>
                  </div>
                </template>
                <!-- name -->
                <BlockStack :gap="200" class="tw-max-w-lg">
                  <label
                    for="vendorName"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Name<span
                      v-if="!getVendorName"
                      class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                      >required</span
                    ></label
                  >
                  <input
                    v-model="selectedVendor.name"
                    type="text"
                    name="vendorName"
                    id="vendorName"
                    :class="{
                      'tw-ring-red-300': !getVendorName,
                      'tw-ring-green-500': getVendorName,
                    }"
                    class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                    placeholder="Bold Xchange"
                  />
                </BlockStack>
                <!-- short description -->
                <BlockStack :gap="100" class="">
                  <label
                    for="vendorShortDescription"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Short description<span
                      v-if="!selectedVendor.metadata.shortDescription"
                      class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                      >required</span
                    ></label
                  >
                  <textarea
                    rows="1"
                    v-model="selectedVendor.metadata.shortDescription"
                    name="vendorShortDescription"
                    id="vendorShortDescription"
                    placeholder="Vendor short bio"
                    :class="{
                      'tw-ring-red-300':
                        !selectedVendor.metadata.shortDescription,
                      'tw-ring-green-500':
                        selectedVendor.metadata.shortDescription,
                    }"
                    class="tw-block tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                  ></textarea>
                </BlockStack>
                <!-- Long Bio -->
                <BlockStack :gap="100" class="">
                  <label
                    for="vendorDescription"
                    class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                    >Long bio<span
                      v-if="!vendorDescription"
                      class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                      >required</span
                    ></label
                  >
                  <textarea
                    rows="5"
                    v-model="selectedVendor.description"
                    name="vendorDescription"
                    id="vendorDescription"
                    placeholder="Vendor long bio"
                    :class="{
                      'tw-ring-red-300': !vendorDescription,
                      'tw-ring-green-500': vendorDescription,
                    }"
                    class="tw-block tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                  ></textarea>
                </BlockStack>
                <!-- gift card page and minimum amount -->
                <div class="tw-flex gap-2 tw-flex-wrap tw-w-full">
                  <BlockStack :gap="200" class="">
                    <label
                      for="gift_card_minimum"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Gift card minimum<span
                        v-if="
                          !isGiftCardMinimumValid || !isGiftCardValueTypeValid
                        "
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      ></label
                    >
                    <input
                      v-model="selectedVendor.metadata.gift_card_minimum"
                      type="number"
                      name="gift_card_minimum"
                      id="gift_card_minimum"
                      :class="{
                        'tw-ring-red-300':
                          !isGiftCardMinimumValid || !isGiftCardValueTypeValid,
                        'tw-ring-green-500':
                          isGiftCardMinimumValid && isGiftCardValueTypeValid,
                      }"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="$$$"
                    />
                  </BlockStack>
                  <BlockStack :gap="200" class="tw-w-1/2">
                    <label
                      for="gift_card_page"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Gift card page<span
                        v-if="!selectedVendor.metadata.gift_card_page"
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      ></label
                    >
                    <input
                      v-model="selectedVendor.metadata.gift_card_page"
                      type="text"
                      name="gift_card_page"
                      id="gift_card_page"
                      :class="{
                        'tw-ring-green-500':
                          selectedVendor.metadata.gift_card_page,
                        'tw-ring-red-300':
                          !selectedVendor.metadata.gift_card_page,
                      }"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="www.example.com"
                    />
                  </BlockStack>
                </div>
                <!-- neighborhoods + region -->
                <div class="tw-flex tw-items-start gap-2 tw-w-full">
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="vendorRegion"
                      class="tw-text-lg tw-leading-6 tw-text-gray-900"
                      >Region<span
                        v-if="!selectedVendor.metadata.region[0]"
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      ></label
                    >
                    <select
                      v-model="selectedVendor.metadata.region"
                      id="vendorRegion"
                      name="vendorRegion"
                      class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-3 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg"
                      :class="{
                        'tw-ring-green-500': selectedVendor.metadata.region,
                        'tw-ring-red-300': !selectedVendor.metadata.region,
                      }"
                    >
                      <option value="" class="" disabled>
                        Select an region
                      </option>
                      <option
                        v-for="option in vendorRegionOptions"
                        :value="[option.value]"
                        :key="option.index"
                        class=""
                      >
                        {{ option.text }}
                      </option>
                    </select>
                  </BlockStack>
                </div>
                <!-- categories + platforms -->
                <BlockStack :gap="200" class="">
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="categories"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Categories<span
                        v-if="
                          !vendorCategories || vendorCategories.length === 0
                        "
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      >
                    </label>
                    <span
                      v-if="doesCategoryExist"
                      class="text-danger medium ms-2"
                      >Category already exists</span
                    >

                    <span class="tw-isolate tw-inline-flex tw-rounded-md">
                      <button
                        type="button"
                        @click.prevent="showCategoryListModal()"
                        class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                        :class="{
                          'tw-ring-green-500 ':
                            vendorCategories && vendorCategories.length > 0,
                          'tw-ring-red-300 tw-ring-1 tw-ring-inset':
                            !vendorCategories || vendorCategories.length === 0,
                        }"
                      >
                        <span>Select Category(s)</span>
                        <span><b-icon-chevron-down></b-icon-chevron-down></span>
                      </button>
                      <button
                        type="button"
                        @click.prevent="showCreateCategoryModal()"
                        class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                      >
                        create
                      </button>
                    </span>
                  </BlockStack>
                  <!-- category pills -->
                  <div class="tw-flex tw-flex-wrap tw-justify-start">
                    <template
                      v-if="vendorCategories && vendorCategories.length > 0"
                    >
                      <div
                        v-for="(category, key) in vendorCategories"
                        :key="'item-' + key"
                      >
                        <!-- badge -->
                        <span
                          role="button"
                          class="badge bg-soft-dark rounded-pill me-1"
                          @click.stop="removeCategorySelected(category)"
                        >
                          <b-icon-x-circle
                            class="text-danger"
                          ></b-icon-x-circle>
                          <span class="text-body ms-1">{{ category }}</span>
                        </span>
                      </div>
                    </template>
                  </div>
                </BlockStack>
                <!-- neighborhoods -->
                <BlockStack :gap="200" class="tw-w-full">
                  <BlockStack :gap="200" class="">
                    <label
                      for="neighborhoods"
                      class="tw-text-lg tw-leading-6 tw-text-gray-900"
                      >Neighborhoods</label
                    >
                    <span
                      v-if="doesNeighborhoodExist"
                      class="text-danger medium ms-2"
                      >Neighborhood already exists</span
                    >
                    <span class="tw-isolate tw-inline-flex tw-rounded-md">
                      <button
                        type="button"
                        @click.prevent="showNeighborhoodsListModal()"
                        class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                      >
                        <span>Select neighborhood(s)</span>
                        <span><b-icon-chevron-down></b-icon-chevron-down></span>
                      </button>
                      <button
                        type="button"
                        @click.prevent="showCreateNeighborhoodModal()"
                        class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                      >
                        create
                      </button>
                    </span>
                  </BlockStack>
                  <!-- pills -->
                  <div class="tw-flex tw-flex-wrap tw-justify-start">
                    <template
                      v-if="
                        vendorNeighborhoods && vendorNeighborhoods.length > 0
                      "
                    >
                      <div
                        v-for="(neighborhood, key) in vendorNeighborhoods"
                        :key="'item-' + key"
                      >
                        <!-- badge -->
                        <span
                          role="button"
                          class="badge bg-soft-dark rounded-pill me-1"
                          @click.stop="removeNeighborhoodSelected(neighborhood)"
                        >
                          <b-icon-x-circle
                            class="text-danger"
                          ></b-icon-x-circle>
                          <span class="text-body ms-1">{{ neighborhood }}</span>
                        </span>
                      </div>
                    </template>
                  </div>
                </BlockStack>
                <!-- tags -->
                <BlockStack :gap="200" class="">
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="tags"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Tags</label
                    >
                    <span v-if="doesTagExist" class="text-danger medium ms-2"
                      >Tag already exists</span
                    >
                    <span class="tw-isolate tw-inline-flex tw-rounded-md">
                      <button
                        type="button"
                        @click.prevent="showTagsListModal()"
                        class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                      >
                        <span>Select tag(s)</span>
                        <span><b-icon-chevron-down></b-icon-chevron-down></span>
                      </button>
                      <button
                        type="button"
                        @click.prevent="showCreateTagsModal()"
                        class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                      >
                        create
                      </button>
                    </span>
                  </BlockStack>
                  <!-- tag pills -->
                  <div
                    class="d-flex flex-row flex-wrap justify-content-left mt-1"
                  >
                    <template v-if="vendorTags && vendorTags.length > 0">
                      <div
                        v-for="(tag, key) in vendorTags"
                        :key="'item-' + key"
                      >
                        <!-- badge -->
                        <span
                          role="button"
                          class="badge bg-soft-dark rounded-pill me-1"
                          @click.stop="removeTagsSelected(tag)"
                        >
                          <b-icon-x-circle
                            class="text-danger"
                          ></b-icon-x-circle>
                          <span class="text-body ms-1">{{ tag }}</span>
                        </span>
                      </div>
                    </template>
                  </div>
                </BlockStack>
                <!-- gift card platform -->
                <BlockStack :gap="200" class="">
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="online_platforms"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Gift card platfoarm (Square, Toast, etc.)</label
                    >
                    <span
                      v-if="doesOnlineStorePlatformExist"
                      class="text-danger medium ms-2"
                      >Gift card platform already exists</span
                    >
                    <span class="tw-isolate tw-inline-flex tw-rounded-md">
                      <button
                        type="button"
                        @click.prevent="showVendorOnlineStoreListModal()"
                        class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-flex tw-items-center tw-justify-between tw-grow tw-rounded-l-md tw-bg-white tw-px-3.5 tw-py-2 tw-text-lg tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                      >
                        <span>Select tag(s)</span>
                        <span><b-icon-chevron-down></b-icon-chevron-down></span>
                      </button>
                      <button
                        type="button"
                        @click.prevent="showCreateOnlineStorePlatformModal()"
                        class="tw-border-none tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed tw-relative tw-rounded-r-md tw-px-2.5 tw-py-2 tw-text-lg focus:tw-z-10"
                      >
                        create
                      </button>
                    </span>
                  </BlockStack>
                  <!-- tag pills -->
                  <div
                    class="d-flex flex-row flex-wrap justify-content-left mt-1"
                  >
                    <template
                      v-if="
                        vendorOnlineStorePlatform &&
                        vendorOnlineStorePlatform.length > 0
                      "
                    >
                      <div
                        v-for="(platform, key) in vendorOnlineStorePlatform"
                        :key="'item-' + key"
                      >
                        <!-- badge -->
                        <span
                          role="button"
                          class="badge bg-soft-dark rounded-pill me-1"
                          @click.stop="
                            removeOnlineStorePlatformSelected(platform)
                          "
                        >
                          <b-icon-x-circle
                            class="text-danger"
                          ></b-icon-x-circle>
                          <span class="text-body ms-1">{{ platform }}</span>
                        </span>
                      </div>
                    </template>
                  </div>
                </BlockStack>
              </BlockStack>
            </TheBox>
          </div>
        </div>
        <div class="col-lg-7 mb-3 mb-lg-0 text-start">
          <!-- address -->
          <div class="card mb-3 mb-lg-5">
            <div class="card-header bg-dark">
              <h4 class="card-header-title text-white">Address</h4>
            </div>
            <TheBox :withPadding="true">
              <BlockStack :gap="600" class="tw-w-full">
                <!-- warning -->
                <template v-if="!addressInfoSectionComplete">
                  <div
                    class="tw-flex tw-items-center tw-gap-1 tw-border-solid tw-border-red-500 tw-border-2 tw-p-4 tw-rounded-md"
                  >
                    <div
                      class="tw-flex tw-items-top tw-gap-2 tw-font-bold tw-text-sm"
                    >
                      <b-icon-exclamation-triangle-fill
                        class="tw-text-red-500 tw-mt-0.5"
                      ></b-icon-exclamation-triangle-fill>
                      <p class="tw-m-0 tw-text-gray-900">
                        Please complete required fields
                      </p>
                    </div>
                  </div>
                </template>
                <div class="tw-flex gap-2 tw-w-full">
                  <BlockStack :gap="200" class="tw-w-1/2">
                    <label
                      for="addressLine1"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Address line 1</label
                    >
                    <input
                      type="text"
                      name="addressLine1"
                      id="addressLine1"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="Street address, P.O box"
                      aria-label="Street address, P.O box"
                      v-model="selectedVendor.address.lines[0]"
                    />
                  </BlockStack>
                  <BlockStack :gap="200" class="tw-w-1/2">
                    <label
                      for="addressLine2"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Address line 2</label
                    >
                    <input
                      type="text"
                      name="addressLine2"
                      id="addressLine2"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="Street address, P.O box"
                      aria-label="Street address, P.O box"
                      v-model="selectedVendor.address.lines[1]"
                    />
                  </BlockStack>
                </div>
                <div class="tw-flex gap-2 tw-w-full">
                  <!-- city -->
                  <BlockStack :gap="200" class="tw-w-1/4">
                    <label
                      for="city"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >City<span
                        v-if="!selectedVendor.address.city"
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      >
                    </label>
                    <input
                      type="text"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      :class="{
                        'tw-ring-red-300': !selectedVendor.address.city,
                        'tw-ring-green-500': selectedVendor.address.city,
                      }"
                      name="city"
                      id="city"
                      required
                      placeholder="St. Louis"
                      aria-label="St. Louis"
                      v-model="selectedVendor.address.city"
                    />
                  </BlockStack>
                  <!-- state -->
                  <BlockStack :gap="200" class="tw-w-1/4">
                    <label
                      for="state"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >State<span
                        v-if="!isStateValid"
                        class="tw-text-red-500 tw-m-0 tw-text-xs tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required</span
                      >
                    </label>
                    <select
                      class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg"
                      :class="{
                        'tw-ring-red-300': !isStateValid,
                        'tw-ring-green-500': isStateValid,
                      }"
                      required
                      v-model="selectedVendor.address.state"
                      name="stateSelect"
                      id="stateSelect"
                    >
                      <option value="" class="" disabled>Select state</option>
                      <option
                        v-for="option in stateOptions"
                        :value="option.value"
                        :key="option.index"
                      >
                        {{ option.text }} ({{ option.value }})
                      </option>
                    </select>
                  </BlockStack>
                  <!-- postal -->
                  <BlockStack :gap="200" class="tw-w-1/4">
                    <label
                      for="postalCode"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Postal Code</label
                    >
                    <input
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="11111"
                      aria-label="11111"
                      v-model="selectedVendor.address.postalCode"
                    />
                  </BlockStack>
                  <!-- country -->
                  <BlockStack :gap="200" class="tw-w-1/4">
                    <label
                      for="country"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Country</label
                    >
                    <input
                      :readonly="true"
                      type="url"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6"
                      name="country"
                      id="country"
                      required
                      placeholder="country"
                      v-model="selectedVendor.address.country"
                    />
                  </BlockStack>
                </div>
              </BlockStack>
            </TheBox>
          </div>
          <!-- social media -->
          <div class="card mb-3 mb-lg-5">
            <div class="card-header bg-dark">
              <h4 class="card-header-title text-white">
                Social Media (optional)
              </h4>
            </div>

            <TheBox :withPadding="true">
              <BlockStack :gap="600" class="tw-w-full">
                <div class="tw-flex gap-2 tw-w-full">
                  <BlockStack :gap="200" class="tw-w-1/2">
                    <label
                      for="companyWebsite"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      ><b-icon-globe class="tw-mr-1"></b-icon-globe
                      >Website</label
                    >
                    <input
                      type="text"
                      name="companyWebsite"
                      id="companyWebsite"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="Website URL"
                      aria-label="Website URL"
                      v-model="selectedVendor.metadata.website_url"
                    />
                  </BlockStack>
                  <BlockStack :gap="200" class="tw-w-1/2">
                    <label
                      for="linkedinProfile"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      ><b-icon-linkedin class="tw-mr-1"></b-icon-linkedin
                      >LinkedIn</label
                    >
                    <input
                      type="text"
                      name="linkedinProfile"
                      id="linkedinProfile"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="LinkedIn URL"
                      aria-label="LinkedIn URL"
                      v-model="selectedVendor.metadata.linkedin_url"
                    />
                  </BlockStack>
                </div>
                <div class="tw-flex gap-2 tw-w-full">
                  <BlockStack :gap="200" class="tw-w-1/2">
                    <label
                      for="twitterProfile"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      ><b-icon-twitter class="tw-mr-1"></b-icon-twitter
                      >Twitter</label
                    >
                    <input
                      type="text"
                      name="twitterProfile"
                      id="twitterProfile"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="Twitter URL"
                      aria-label="Twitter URL"
                      v-model="selectedVendor.metadata.twitter_url"
                    />
                  </BlockStack>
                  <BlockStack :gap="200" class="tw-w-1/2">
                    <label
                      for="tiktokProfile"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      ><b-icon-tiktok class="tw-mr-1"></b-icon-tiktok
                      >TikTok</label
                    >
                    <input
                      type="text"
                      name="tiktokProfile"
                      id="tiktokProfile"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="TikTok URL"
                      aria-label="TikTok URL"
                      v-model="selectedVendor.metadata.tiktok_url"
                    />
                  </BlockStack>
                </div>
                <div class="tw-flex gap-2 tw-w-full">
                  <BlockStack :gap="200" class="tw-w-1/2">
                    <label
                      for="instagramProfile"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      ><b-icon-instagram class="tw-mr-1"></b-icon-instagram
                      >Instagram</label
                    >
                    <input
                      type="text"
                      name="instagramProfile"
                      id="instagramProfile"
                      class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder="Instagram URL"
                      aria-label="Instagram URL"
                      v-model="selectedVendor.metadata.instagram_url"
                    />
                  </BlockStack>
                </div>
              </BlockStack>
              <!--  -->
            </TheBox>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import ConfirmDeleteModal from "../../components/ui/modals/ConfirmDeleteModal.vue";
import SuccessfulActionModal from "../../components/ui/modals/success/SuccessfulActionModal.vue";
import CategoriesListModal from "../../components/ui/modals/lists/CategoriesListModal.vue";
import CreateCategoryModal from "../../components/ui/modals/create/CreateCategoryModal.vue";
import CreateAddressModal from "../../components/ui/modals/create/CreateAddressModal.vue";
import AddressListModal from "../../components/ui/modals/lists/AddressListModal.vue";
import LoadingGiftIcon from "../../components/ui/LoadingGiftIcon.vue";
import VendorOnlineStorePlatformListModal from "../../components/ui/modals/lists/VendorOnlineStorePlatformListModal.vue";
import CreateVendorOnlineStorePlatformModal from "../../components/ui/modals/create/CreateVendorOnlineStorePlatformModal.vue";
import TagsListModal from "../../components/ui/modals/lists/TagsListModal.vue";
import CreateTagsModal from "../../components/ui/modals/create/CreateTagsModal.vue";
import NeighborhoodsListModal from "../../components/ui/modals/lists/NeighborhoodsListModal.vue";
import CreateNeighborhoodsModal from "../../components/ui/modals/create/CreateNeighborhoodsModal.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";

export default {
  name: "ImportedVendorListItem",
  emits: ["setVendor"],
  components: {
    TheTooltip,
    TheBox,
    TheCard,
    BlockStack,
    ConfirmDeleteModal,
    SuccessfulActionModal,
    CategoriesListModal,
    CreateCategoryModal,
    CreateAddressModal,
    AddressListModal,
    LoadingGiftIcon,
    VendorOnlineStorePlatformListModal,
    CreateVendorOnlineStorePlatformModal,
    TagsListModal,
    CreateTagsModal,
    NeighborhoodsListModal,
    CreateNeighborhoodsModal,
  },
  created() {
    this.extractVendorDetails(this.vendor);
    this.$emit("setVendor", this.vendor);
  },
  watch: {
    selectedVendor: {
      handler(newValue, oldValue) {
        // console.log("Change detected");
        this.updateImportedVendorData(newValue); // Pass newValue to the method
      },
      deep: true,
    },
  },
  props: {
    vendor: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      vendorRegionOptions: [
        { text: "Baltimore", value: "Baltimore" },
        { text: "Birmingham", value: "Birmingham" },
        { text: "Charlotte", value: "Charlotte" },
        { text: "Columbia", value: "Columbia" },

        { text: "Ft. Lauderdale", value: "Ft. Lauderdale" },
        { text: "Kansas City", value: "Kansas City" },
        { text: "Louisville", value: "Louisville" },
        { text: "St. Louis", value: "St. Louis" },
      ],
      selectedVendor: null,
      isLoading: false,
      isSuccessful: false,
      isConfirmDeleteModalVisible: false,
      isCategoryListModalVisible: false,
      isNewCategoryModalVisible: false,
      isAddressListModalVisible: false,
      isCreateAddressModalVisible: false,
      isVendorOnlineStorePlatformListModalVisible: false,
      isNewVendorOnlineStorePlatformModalVisible: false,
      isNewTagModalVisible: false,
      isTagsListModalVisible: false,
      isNewNeighborhoodModalVisible: false,
      isNeighborhoodsListModalVisible: false,
      doesCategoryExist: false,
      doesTagExist: false,
      doesOnlineStorePlatformExist: false,
      doesNeighborhoodExist: false,
      newGiftCardsFiles: [],
      newVendorLogoFiles: [],
      newBrandingFiles: [],
      stateOptions: [
        { text: "Alabama", value: "AL" },
        { text: "Alaska", value: "AK" },
        { text: "Arizona", value: "AZ" },
        { text: "Arkansas", value: "AR" },
        { text: "California", value: "CA" },
        { text: "Colorado", value: "CO" },
        { text: "Connecticut", value: "CT" },
        { text: "Delaware", value: "DE" },
        { text: "Florida", value: "FL" },
        { text: "Georgia", value: "GA" },
        { text: "Hawaii", value: "HI" },
        { text: "Idaho", value: "ID" },
        { text: "Illinois", value: "IL" },
        { text: "Indiana", value: "IN" },
        { text: "Iowa", value: "IA" },
        { text: "Kansas", value: "KS" },
        { text: "Kentucky", value: "KY" },
        { text: "Louisiana", value: "LA" },
        { text: "Maine", value: "ME" },
        { text: "Maryland", value: "MD" },
        { text: "Massachusetts", value: "MA" },
        { text: "Michigan", value: "MI" },
        { text: "Minnesota", value: "MN" },
        { text: "Mississippi", value: "MS" },
        { text: "Missouri", value: "MO" },
        { text: "Montana", value: "MT" },
        { text: "Nebraska", value: "NE" },
        { text: "Nevada", value: "NV" },
        { text: "New Hampshire", value: "NH" },
        { text: "New Jersey", value: "NJ" },
        { text: "New Mexico", value: "NM" },
        { text: "New York", value: "NY" },
        { text: "North Carolina", value: "NC" },
        { text: "North Dakota", value: "ND" },
        { text: "Ohio", value: "OH" },
        { text: "Oklahoma", value: "OK" },
        { text: "Oregon", value: "OR" },
        { text: "Pennsylvania", value: "PA" },
        { text: "Rhode Island", value: "RI" },
        { text: "South Carolina", value: "SC" },
        { text: "South Dakota", value: "SD" },
        { text: "Tennessee", value: "TN" },
        { text: "Texas", value: "TX" },
        { text: "Utah", value: "UT" },
        { text: "Vermont", value: "VT" },
        { text: "Virginia", value: "VA" },
        { text: "Washington", value: "WA" },
        { text: "West Virginia", value: "WV" },
        { text: "Wisconsin", value: "WI" },
        { text: "Wyoming", value: "WY" },
      ],
    };
  },
  computed: {
    isGeneralInformationComplete() {
      return (
        this.getVendorName &&
        this.vendorCategories &&
        this.vendorDescription &&
        this.getRegion &&
        this.isGiftCardMinimumValid &&
        this.isGiftCardValueTypeValid &&
        this.getGiftCardPage
      );
    },
    addressInfoSectionComplete() {
      const { city, state, country } = this.selectedVendor.address;
      return (
        (city === "" && state === "" && country === "" && !this.isStateValid) ||
        (city !== "" && state !== "" && country !== "" && this.isStateValid)
      );
    },
    getVendorName() {
      return this.selectedVendor.name;
    },
    vendorDescription() {
      return this?.selectedVendor?.description;
    },
    vendorCategories() {
      return this?.selectedVendor?.categories?.length === 0
        ? false
        : this?.selectedVendor?.categories;
    },
    getRegion() {
      return this.selectedVendor.metadata.region;
    },
    getGiftCardPage() {
      return this.selectedVendor.metadata.gift_card_page;
    },
    vendorTags() {
      return this?.selectedVendor?.metadata?.tags?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.tags;
    },
    vendorOnlineStorePlatform() {
      return this?.selectedVendor?.metadata?.onlineStorePlatforms?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.onlineStorePlatforms;
    },
    vendorNeighborhoods() {
      return this?.selectedVendor?.metadata?.neighborhoods?.length === 0
        ? false
        : this?.selectedVendor?.metadata?.neighborhoods;
    },
    isGiftCardMinimumValid() {
      const minValue = 1;
      return (
        parseFloat(this.selectedVendor.metadata.gift_card_minimum) >= minValue
      );
    },
    isGiftCardValueTypeValid() {
      return typeof this.selectedVendor.metadata.gift_card_minimum == "number";
    },
    isStateValid() {
      return this.stateOptions.some(
        (state) => state.value === this.selectedVendor.address.state
      );
    },
  },
  methods: {
    updateImportedVendorData(updatedVendor) {
      // console.log("updatedVendor", updatedVendor);
      // const updatedVendorCopy = JSON.parse(JSON.stringify(updatedVendor));
      // console.log("updatedVendorCopy", updatedVendorCopy);

      // console.log("Updating imported data");

      // Make a copy of the Vuex state to avoid direct mutations
      const vendorData = this.$store.getters["brands/getImportedVendors"];

      // Find the index of the vendor in vendorData array by its ID
      const index = vendorData.findIndex(
        (vendor) => vendor._id === updatedVendor._id
      );

      if (index !== -1) {
        // Update the vendor within vendorData array
        vendorData.splice(index, 1, updatedVendor);
        // Commit mutation to set the updated vendorData to Vuex store
        this.$store.commit("brands/SET_IMPORTED_VENDORS", vendorData);
      }
    },
    extractVendorDetails(vendorData) {
      const {
        _id = "",
        name = "",
        description = "",
        categories = [],
        address = {}, // Default empty object for address
        metadata = {}, // Default empty object for metadata
        newVendorLogoFiles = [],
        newGiftCardsFiles = [],
        newBrandingFiles = [],
      } = vendorData || {}; // Ensure vendorData is defined

      // Destructure address with default values
      const {
        lines: addressLines = [],
        city = "",
        state = "",
        postalCode = "",
        country = "US",
        _id: addressId = "",
      } = address;

      const [line1 = "", line2 = ""] = addressLines;

      // Destructure metadata with default values
      const {
        shortDescription = "",
        website_url = "",
        twitter_url = "",
        linkedin_url = "",
        tiktok_url = "",
        instagram_url = "",
        gift_card_page = "",
        gift_card_minimum = "",
        tags = [],
        onlineStorePlatforms = [],
        neighborhoods = [],
        region = [],
      } = metadata;

      // Assign the extracted details to the selectedVendor object
      this.selectedVendor = {
        _id,
        name,
        description,
        categories,
        address: {
          lines: [line1, line2],
          city,
          state,
          postalCode,
          country,
          _id: addressId,
        },
        metadata: {
          shortDescription,
          website_url,
          twitter_url,
          linkedin_url,
          tiktok_url,
          instagram_url,
          gift_card_page,
          gift_card_minimum,
          tags,
          onlineStorePlatforms,
          neighborhoods,
          region,
        },
        newVendorLogoFiles,
        newGiftCardsFiles,
        newBrandingFiles,
      };
    },
    /** categories actions */
    showCreateCategoryModal() {
      this.isNewCategoryModalVisible = true;
    },
    closeCreateCategoryModal() {
      this.isNewCategoryModalVisible = false;
    },
    showCategoryListModal() {
      this.isCategoryListModalVisible = true;
    },
    closeCategoryListModal() {
      this.isCategoryListModalVisible = false;
    },
    handleNewCategories(newCategories) {
      for (const category of newCategories) {
        this.setExistingCategory(category);
      }
      this.isCategoryListModalVisible = false;
    },
    setExistingCategory(category) {
      const existingCategory = this.selectedVendor.categories.find(
        (cat) => cat === category
      );

      if (!existingCategory) {
        this.selectedVendor.categories.push(category);
        this.doesCategoryExist = false;
      } else {
        this.doesCategoryExist = true;
        setTimeout(() => {
          this.doesCategoryExist = false;
        }, 2000);
      }
      this.isNewCategoryModalVisible = false;
    },
    removeCategorySelected(category) {
      let array = this.selectedVendor.categories;
      let index = array.indexOf(category);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    /** tags actions */
    showCreateTagsModal() {
      this.isNewTagModalVisible = !this.isNewTagModalVisible;
    },
    closeCreateTagModal() {
      this.isNewTagModalVisible = false;
    },
    showTagsListModal() {
      this.isTagsListModalVisible = !this.isTagsListModalVisible;
    },
    closeTagsListModal() {
      this.isTagsListModalVisible = false;
    },
    handleNewTags(newTags) {
      // Handle the array of selected categories
      for (const tag of newTags) {
        this.setExistingTag(tag);
      }
      this.isNewTagModalVisible = false;
    },
    setExistingTag(tag) {
      const existingStore = this.selectedVendor.metadata.tags.find(
        (s) => s === tag
      );

      if (!existingStore) {
        this.selectedVendor.metadata.tags.push(tag);
        this.doesTagExist = false;
      } else {
        this.doesTagExist = true;
        setTimeout(() => {
          this.doesTagExist = false;
        }, 2000);
      }
      this.isNewTagModalVisible = false;
      this.isTagsListModalVisible = false;
    },
    removeTagsSelected(tag) {
      let array = this.selectedVendor.metadata.tags;
      let index = array.indexOf(tag);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    /** online store actions */
    showCreateOnlineStorePlatformModal() {
      this.isNewVendorOnlineStorePlatformModalVisible =
        !this.isNewVendorOnlineStorePlatformModalVisible;
    },
    closeCreateOnlineStorePlatformModal() {
      this.isNewVendorOnlineStorePlatformModalVisible = false;
    },
    showVendorOnlineStoreListModal() {
      this.isVendorOnlineStorePlatformListModalVisible =
        !this.isVendorOnlineStorePlatformListModalVisible;
    },
    closeOnlineStorePlatformsListModal() {
      this.isVendorOnlineStorePlatformListModalVisible = false;
    },
    handleNewOnlineStorePlatform(newStores) {
      // Handle the array of selected categories
      for (const store of newStores) {
        this.setExistingOnlineStorePlatform(store);
      }
      this.isVendorOnlineStorePlatformListModalVisible = false;
    },
    setExistingOnlineStorePlatform(store) {
      const existingStore =
        this.selectedVendor.metadata.onlineStorePlatforms.find(
          (s) => s === store
        );

      if (!existingStore) {
        this.selectedVendor.metadata.onlineStorePlatforms.push(store);
        this.doesOnlineStorePlatformExist = false;
      } else {
        this.doesOnlineStorePlatformExist = true;
        setTimeout(() => {
          this.doesOnlineStorePlatformExist = false;
        }, 2000);
      }

      this.isVendorOnlineStorePlatformListModalVisible = false;
      this.isNewVendorOnlineStorePlatformModalVisible = false;
    },
    removeOnlineStorePlatformSelected(store) {
      let array = this.selectedVendor.metadata.onlineStorePlatforms;
      let index = array.indexOf(store);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    /** neighborhoods actions */
    showCreateNeighborhoodModal() {
      this.isNewNeighborhoodModalVisible = !this.isNewNeighborhoodModalVisible;
    },
    closeCreateNeighborhoodModal() {
      this.isNewNeighborhoodModalVisible = false;
    },
    showNeighborhoodsListModal() {
      this.isNeighborhoodsListModalVisible =
        !this.isNeighborhoodsListModalVisible;
    },
    closeNeighborhoodsListModal() {
      this.isNeighborhoodsListModalVisible = false;
    },
    handleNewNeighborhoods(newNeighborhoods) {
      // Handle the array of selected categories
      for (const neighborhood of newNeighborhoods) {
        this.setExistingNeighborhood(neighborhood);
      }
      this.isNeighborhoodsListModalVisible = false;
    },
    setExistingNeighborhood(neighborhood) {
      const existingNeighborhood =
        this.selectedVendor.metadata.neighborhoods.find(
          (n) => n === neighborhood
        );

      if (!existingNeighborhood) {
        this.selectedVendor.metadata.neighborhoods.push(neighborhood);
        this.doesNeighborhoodExist = false;
      } else {
        this.doesNeighborhoodExist = true;
        setTimeout(() => {
          this.doesNeighborhoodExist = false;
        }, 2000);
      }

      this.isNeighborhoodsListModalVisible = false;
      this.isNewNeighborhoodModalVisible = false;
    },
    removeNeighborhoodSelected(neighborhood) {
      let array = this.selectedVendor.metadata.neighborhoods;
      let index = array.indexOf(neighborhood);
      if (index > -1) {
        array.splice(index, 1);
      }
      return array;
    },
    /** address actions */
    showCreateAddressModal() {
      this.isCreateAddressModalVisible = !this.isCreateAddressModalVisible;
    },
    showAddressListModal() {
      this.isAddressListModalVisible = !this.isAddressListModalVisible;
    },
    setNewAddress(address) {
      this.selectedVendor.address = address;
      this.isCreateAddressModalVisible = false;
      this.isAddressListModalVisible = false;
    },
    /** error handling */

    /** navigation */
    cancelCreate() {
      this.$router.push(`/vendors`);
    },
    closeSuccessModal() {
      this.isSuccessful = false;
    },
    returnToVendorList() {
      this.$router.push({ path: "/vendors" });
    },
  },
};
</script>

<style scoped>
ul {
  max-height: 200px;
  overflow: auto;
  cursor: pointer;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
