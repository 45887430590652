<template>
  <Teleport to="#modal">
    <FullPageModal
      :open="isAccountSetupComplete"
      customWidthClass="tw-max-w-2xl"
    >
      <template v-if="currentStep === 1">
        <AccountSetupQuestionOne
          @saveAccountSetupStepQuestionOneChoice="
            saveAccountSetupStepQuestionOneChoice
          "
        ></AccountSetupQuestionOne>
      </template>
      <template v-if="currentStep === 2">
        <AccountSetupQuestionTwo
          @saveAccountSetupStepQuestionTwoChoice="
            saveAccountSetupStepQuestionTwoChoice
          "
        ></AccountSetupQuestionTwo>
      </template>
    </FullPageModal>
  </Teleport>
</template>

<script>
import FullPageModal from "../../../components-v2/ui/modals/FullPageModal.vue";
import AccountSetupQuestionOne from "./AccountSetupQuestionOne.vue";
import AccountSetupQuestionTwo from "./AccountSetupQuestionTwo.vue";

export default {
  name: "AccountSetup",
  emits: ["saveAccountSetupChoices"],
  components: {
    AccountSetupQuestionOne,
    AccountSetupQuestionTwo,
    FullPageModal,
  },
  props: {
    isAccountSetupComplete: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentStep: 1,
      accountSetupChoices: {
        questionOneChoice: "",
        questionTwoChoice: "",
      },
    };
  },
  methods: {
    /** handle steps */
    handleStepChange(step) {
      // this.updateCampaign();
      this.currentStep = step;
      this.loadSteps();
    },
    goToNextStep() {
      // this.updateCampaign();
      this.currentStep++;
      // this.loadSteps();
    },
    loadSteps() {
      const updatedSteps = this.updateSteps(this.campaign, this.steps);
      console.log("updated steps", updatedSteps);
      this.steps = updatedSteps;
    },
    saveAccountSetupStepQuestionOneChoice(accountSetupQuestionOneChoice) {
      console.log(
        "accountSetupQuestionOneChoice",
        accountSetupQuestionOneChoice
      );
      this.accountSetupChoices.questionOneChoice =
        accountSetupQuestionOneChoice;
      this.goToNextStep();
    },
    saveAccountSetupStepQuestionTwoChoice(accountSetupQuestionTwoChoice) {
      this.accountSetupChoices.questionTwoChoice =
        accountSetupQuestionTwoChoice;

      console.log(
        "accountSetupQuestionTwoChoice",
        accountSetupQuestionTwoChoice
      );
      this.$emit("saveAccountSetupChoices", this.accountSetupChoices);
    },
  },
};
</script>

<style scoped></style>
