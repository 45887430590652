<template>
  <div class="tw-relative" ref="iconDropdown">
    <CircleButton
      :disabled="disabled"
      :variant="variant"
      @click.prevent="handleToggleDropdown"
      class="tw-relative"
    >
      <template #icon><slot name="icon"></slot></template>
    </CircleButton>

    <ul
      class="tw-z-[100] tw-absolute tw-right-0 tw-divide-y tw-divide-solid tw-divide-gray-200 tw-divide-x-0 tw-overflow-hidden tw-mt-2 tw-w-max tw-border-solid tw-border-[0.5px] tw-bg-white tw-rounded-md tw-shadow-lg tw-list-none tw-p-0 tw-m-0"
      :class="{ 'tw-hidden': !isOpen }"
    >
      <li v-for="(item, index) in items" :key="index">
        <component
          :is="item.isRouterLink ? 'router-link' : 'a'"
          :to="item.isRouterLink ? item.to : null"
          class="tw-text-start tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100 tw-cursor-pointer"
          :class="{
            'tw-text-red-700 tw-font-bold tw-text-sm':
              item.isDeleteAction || item.isCriticalAction,
          }"
          @click="item.action && item.action()"
        >
          {{ item.label }}
        </component>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CircleButton from "../../actions/CircleButton.vue";

export default {
  name: "IconDropdown",
  components: { CircleButton },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "tertiary",
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState("elements", {
      openDropdownId: (state) => state.openDropdownId,
    }),
    isOpen() {
      return this.openDropdownId === this.id;
    },
  },
  methods: {
    ...mapActions("elements", ["toggleDropdown", "closeDropdown"]),
    handleToggleDropdown() {
      this.toggleDropdown(this.id);
      if (!this.isOpen) {
        this.setupClickAwayListener();
      } else {
        this.removeClickAwayListener();
      }
    },
    setupClickAwayListener() {
      document.addEventListener("mousedown", this.handleClickAway);
    },
    removeClickAwayListener() {
      document.removeEventListener("mousedown", this.handleClickAway);
    },
    handleClickAway(event) {
      const dropdown = this.$refs.iconDropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.setupClickAwayListener();
      } else {
        this.removeClickAwayListener();
      }
    },
  },
  beforeUnmount() {
    this.removeClickAwayListener();
  },
};
</script>

<style scoped>
/* No custom styles needed */
</style>
