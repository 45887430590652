<template>
  <TheCard :fullWidth="true">
    <CardBody>
      <TheBox :withPadding="true">
        <BlockStack
          :gap="300"
          class="tw-w-full tw-flex tw-items-center tw-justify-center tw-grow"
        >
          <template
            v-if="
              emptyState === 'campaignDetails' ||
              emptyState === 'invoiceDetails'
            "
          >
            <img
              style="max-height: 150px; width: auto"
              src="../../../assets/svg/illustrations-test/139_exclamation.png"
              alt="Empty State Image"
            />
          </template>
          <template v-else-if="emptyState === 'recipients'">
            <img
              style="max-height: 150px; width: auto"
              src="../../../assets/svg/illustrations-test/46_blank_page.png"
              alt="Empty State Image"
            />
          </template>
          <template v-else-if="emptyState === 'gifts'">
            <img
              style="max-height: 150px; width: auto"
              src="../../../assets/svg/illustrations-test/108_hands_plus.png"
              alt="Empty State Image"
            />
          </template>
          <template v-else-if="emptyState === 'caution'">
            <img
              style="max-height: 150px; width: auto"
              src="../../../assets/svg/illustrations-test/97_caution_alert.png"
              alt="Empty State Image"
            />
          </template>
          <template v-else-if="emptyState === 'customCardsSelection'">
            <img
              style="max-height: 150px; width: auto"
              src="../../../assets/svg/illustrations-test/108_hands_plus.png"
              alt="Empty State Image"
            />
          </template>
          <template v-else>
            <img
              style="max-height: 150px; width: auto"
              src="../../../assets/svg/illustrations-test/108_hands_plus.png"
              alt="Empty State Image"
            />
          </template>
          <BlockStack :gap="400" class="tw-flex tw-text-left tw-max-w-xl">
            <BlockStack :gap="200">
              <h1
                class="tw-m-0 tw-text-2xl tw-font-bold sm:tw-text-3xl sm:tw-tracking-tight"
              >
                {{ emptyStateTitle }}
              </h1>
              <p class="tw-m-0 tw-text-lg">{{ emptyStateSubtext }}</p>
            </BlockStack>
            <ul
              v-for="item in emptyStateOptions"
              :key="item.id"
              class="tw-list-disc tw-m-0 tw-text-left"
            >
              <li class="tw-text-lg">{{ item }}</li>
            </ul>
            <div class="">
              <TheButton @click="reloadPage()" variant="primary" size="large">
                <template #text>Try again</template>
              </TheButton>
            </div>
          </BlockStack>
        </BlockStack>
      </TheBox>
    </CardBody>
  </TheCard>
</template>

<script>
import TheCard from "./../layout/TheCard.vue";
import CardBody from "./../layout/CardBody.vue";
import BlockStack from "./../layout/BlockStack.vue";
import TheBox from "./../layout/TheBox.vue";
import TheButton from "./../actions/TheButton.vue";

export default {
  name: "EmptyState",
  components: { TheCard, CardBody, BlockStack, TheBox, TheButton },
  props: {
    role: {
      type: String,
      default: "empty state",
    },
    id: {
      type: String,
      default: "",
    },
    columnReverse: {
      type: Boolean,
      default: false,
    },
    emptyState: {
      type: String,
      default: "generic",
    },
    /**
     * False by default
     * Makes actions slot accessible when true
     */
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyStateData: {
        generic: {
          title: "No data available",
          subtext: "Please take action.",
        },
        campaignDetails: {
          title: "Hmm. We’re having trouble loading your campaign",
          subtext:
            "Please try some of the following options. If the issue keeps happening, contact us at hello@boldxchange.com",
          options: [
            "Try loading the page again",
            "Check your network connection",
            "Double check you are still logged in",
          ],
        },
        invoiceDetails: {
          title: "Hmm. We’re having trouble loading your invoice",
          subtext:
            "Please try some of the following options. If the issue keeps happening, contact us at hello@boldxchange.com",
          options: [
            "Try loading the page again",
            "Check your network connection",
            "Double check you are still logged in",
          ],
        },
        gifts: {
          title: "Your gifts",
          subtext: "eGifts sent to you will show up here",
        },
        caution: {
          title: "Action required",
          subtext:
            "Please update your payment method so your recipients can redeem their gift cards.",
        },
        customCardsSelection: {
          title: "Your e-gift cards selection will show up here",
          subtext: "Return to the catalog to make your selection.",
        },
        data: {
          title: "No exact matches",
          subtext:
            "Upload a spreadsheet of recipients or create a single recipient.",
        },
      },
    };
  },
  computed: {
    emptyStateTitle() {
      return this.emptyStateData[this.emptyState].title;
    },
    emptyStateSubtext() {
      return this.emptyStateData[this.emptyState].subtext;
    },
    emptyStateOptions() {
      return this.emptyStateData[this.emptyState].options;
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
