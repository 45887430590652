<template>
  <!-- menu button -->
  <div class="tw-flex tw-items-center">
    <div class="tw-relative" ref="dropdown">
      <template v-if="profileDropdown">
        <a
          class="tw-flex tw-items-center tw-text-white tw-text-decoration-none"
          @click.prevent="toggleDropdown()"
        >
          <div class="tw-w-8 tw-h-8 tw-rounded-full tw-overflow-hidden">
            <!-- avatar slot here -->
            <img
              :src="avatar"
              alt="User Avatar"
              class="tw-w-full tw-h-full tw-object-cover"
              v-if="avatar"
            />
            <span
              class="tw-absolute tw-bottom-0 tw-right-0 tw-block tw-w-3 tw-h-3 tw-bg-green-500 tw-border-2 tw-border-white tw-rounded-full"
            ></span>
          </div>
        </a>
      </template>
      <template v-if="buttonDropdown">
        <TheButton
          :disabled="disabled"
          :active="active"
          :variant="variant"
          :size="size"
          @click.prevent="toggleDropdown()"
          class="tw-relative"
          :hasLeadingIcon="hasLeadingIcon"
          :hasTrailingIcon="hasTrailingIcon"
        >
          <template #leading-icon><b-icon-plus-lg></b-icon-plus-lg></template>
          <template #trailing-icon
            ><b-icon-chevron-down></b-icon-chevron-down
          ></template>

          <template #text>
            {{ buttonText }}
          </template>
          <template #active>
            <b-icon-check-circle-fill
              class="tw-absolute -tw-top-1 -tw-right-1 tw-h-4 tw-w-4 tw-border-gray-300 tw-text-blue-400 tw-bg-white tw-rounded-full focus:tw-ring-blue-600 tw-z-10"
            ></b-icon-check-circle-fill>
          </template>
        </TheButton>
      </template>
      <template v-if="iconDropdown">
        <template v-if="iconType === 'circle'">
          <CircleButton variant="tertiary" @click.stop="toggleDropdown()">
            <template #icon><slot name="icon"></slot></template>
          </CircleButton>
        </template>
        <template v-if="iconType === 'square'">
          <TheButton
            variant="tertiary"
            size="small"
            @click.prevent="toggleDropdown()"
            :hasLeadingIcon="true"
            class="tw-me-2"
          >
            <template #leading-icon><b-icon-pencil></b-icon-pencil></template>
          </TheButton>
        </template>
      </template>

      <ul
        class="tw-z-[100] tw-absolute tw-divide-y tw-divide-solid tw-divide-gray-200 tw-border-[0.5px] tw-divide-x-0 tw-overflow-hidden tw-right-0 tw-mt-2 tw-min-w-auto tw-w-max tw-border-solid tw-border-gray-400 tw-bg-white tw-rounded-md tw-shadow-lg tw-list-none tw-p-0 tw-m-0"
        :class="{ 'tw-hidden': !isOpen }"
      >
        <template
          v-if="(profileDropdown || iconDropdown) && !buttonDropdownList"
        >
          <li v-for="(item, index) in items" :key="index">
            <component
              :is="item.isRouterLink ? 'router-link' : 'a'"
              :to="item.isRouterLink ? item.to : null"
              class="tw-text-start tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100 tw-cursor-pointer"
              :class="{
                'tw-text-red-700': item.isDeleteAction || item.isCriticalAction,
              }"
              @click="item.action && item.action()"
            >
              {{ item.label }}
            </component>
          </li>
        </template>
        <template v-if="buttonDropdownList && iconDropdown">
          <li v-for="(item, index) in items" :key="index">
            <component
              :is="item.isRouterLink ? 'router-link' : 'a'"
              :to="item.isRouterLink ? item.to : null"
              class="tw-text-start tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100 tw-cursor-pointer"
              :class="{
                'tw-text-red-700 tw-font-bold tw-text-sm':
                  item.isDeleteAction || item.isCriticalAction,
              }"
              @click="item.action && item.action()"
            >
              {{ item.label }}
            </component>
          </li>
        </template>
        <template v-if="buttonDropdownList && buttonDropdown">
          <li v-for="(item, index) in items" :key="index">
            <component
              :is="item.isRouterLink ? 'router-link' : 'a'"
              :to="item.isRouterLink ? item.to : null"
              class="tw-text-start tw-block tw-px-4 tw-py-2 tw-text-gray-700 hover:tw-bg-gray-100 tw-cursor-pointer"
              :class="{
                'tw-text-red-700 tw-font-bold tw-text-sm':
                  item.isDeleteAction || item.isCriticalAction,
              }"
              @click="item.action && item.action()"
            >
              {{ item.label }}
            </component>
          </li>
        </template>
        <template v-if="buttonDropdown">
          <slot></slot>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";
import CircleButton from "../../../components-v2/ui/actions/CircleButton.vue";
export default {
  name: "DropdownMenu",
  components: { TheButton, CircleButton },
  props: {
    profileDropdown: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    buttonDropdown: {
      type: Boolean,
      default: false,
    },
    buttonDropdownList: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconDropdown: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "Add dropdown button title",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasLeadingIcon: {
      type: Boolean,
      default: false,
    },
    hasTrailingIcon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "regular",
    },
    variant: {
      type: String,
      default: "tertiary",
    },
    iconType: {
      type: String,
      default: "circle",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.setupClickAwayListener();
      } else {
        this.removeClickAwayListener();
      }
    },
    closeDropdown() {
      this.isOpen = false;
      this.removeClickAwayListener();
    },
    setupClickAwayListener() {
      document.addEventListener("click", this.handleClickAway);
    },
    removeClickAwayListener() {
      document.removeEventListener("click", this.handleClickAway);
    },
    handleClickAway(event) {
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    },
  },
};
</script>
