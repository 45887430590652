<template>
  <div class="tw-w-full tw-h-full tw-overflow-hidden">
    <div class="tw-flex tw-flex-col">
      <div class="tw-animate-pulse">
        <!-- Card Header Placeholder -->
        <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-mb-4">
          <!-- title -->
          <div class="tw-col-span-4 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
        </div>
        <div class="tw-space-y-2">
          <!-- Two-colum list placeholder -->
          <div
            v-for="row in numberOfRows"
            :key="row"
            class="tw-grid tw-grid-cols-12 tw-gap-4"
          >
            <div class="tw-col-span-12 tw-h-4 tw-bg-gray-200 tw-rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataTableSkeleton",
  props: {
    // number of row to display
    numberOfRows: {
      type: Number,
      default: 25,
    },
  },
};
</script>

<style scoped></style>
