<template>
  <Teleport to="#modal">
    <AccountSetup
      :isAccountSetupComplete="!this.onboarding.accountSetup.complete"
      @saveAccountSetupChoices="saveAccountSetupChoices"
    ></AccountSetup>
    <ProductTour
      :isTakingTour="this.onboarding.appTour.active"
      @exitProductTour="exitProductTour()"
    ></ProductTour>
  </Teleport>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <PageHeader :showActions="true" title="Dashboard">
    <template #page-header-actions>
      <TheButton
        :hasLeadingIcon="true"
        variant="primary"
        @click="createCampaignDraft()"
        size="large"
        :disabled="creatingCampaignDraft"
      >
        <template #text>
          <template v-if="creatingCampaignDraft">Creating campaign</template>
          <template v-else>Create campaign</template>
        </template>
        <template #leading-icon>
          <template v-if="creatingCampaignDraft">
            <div role="status">
              <svg
                aria-hidden="true"
                class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="tw-sr-only">Loading...</span>
            </div>
          </template>
          <template v-else><b-icon-plus-lg></b-icon-plus-lg></template>
        </template>
      </TheButton>
    </template>
  </PageHeader>
  <ThePage :fullWidth="true">
    <BlockStack :gap="800" :fullWidth="true">
      <template
        v-if="
          !isChecklistComplete &&
          !loadingDashboardData &&
          dataLoadedSuccessfully
        "
      >
        <PageSection :title="checkListMessage">
          <NewUserChecklist
            @createCampaign="createCampaignDraft()"
            :totalCampaignsSentCount="totalSentCount"
            :totalRecipientsCount="totalRecipientsCount"
            @handleSuccess="handleSuccess"
            @update:showNewRecipientModal="closeModal"
          ></NewUserChecklist>
        </PageSection>
      </template>
      <PageSection title="Overview">
        <template v-if="loadingDashboardData"
          ><DashboardStatsSkeleton :numberOfCards="4"></DashboardStatsSkeleton
        ></template>
        <template v-else>
          <DashboardStats
            :totalCampaignsSentCount="totalSentCount"
            :totalCampaignsClickedCount="totalClicksCount"
            :totalCampaignsRedeemedCount="totalRedeemedCount"
            :totalRecipientsCount="totalRecipientsCount"
            :totalSpendCount="totalSpendCount"
          ></DashboardStats>
        </template>
      </PageSection>

      <PageSection title="Recent activity" class="tw-w-full">
        <template v-if="loadingDashboardData">
          <div class="tw-flex tw-flex-wrap tw-gap-4 tw-w-full">
            <div class="tw-flex-1 tw-bg-white tw-rounded tw-p-4">
              <DataTableSkeleton></DataTableSkeleton>
            </div>

            <div class="tw-flex-1 tw-bg-white tw-rounded tw-p-4">
              <DataTableSkeleton></DataTableSkeleton>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="tw-flex tw-gap-5 tw-flex-wrap tw-w-full">
            <template v-if="userHasCampaigns">
              <div class="tw-flex-1 tw-w-1/2 tw-max-w-3xl">
                <CampaignsIndexTable
                  :totalCampaignsCount="userHasCampaigns"
                  :sharedCampaignData="campaigns"
                  :dashboardView="true"
                ></CampaignsIndexTable></div
            ></template>
            <template v-else>
              <TheCard
                customWidthClass="tw-w-96 tw-h-96 tw-flex tw-items-center tw-justify-center"
              >
                <TheBox :withPadding="true">
                  <BlockStack
                    :gap="300"
                    class="tw-w-full tw-flex tw-items-center tw-justify-center tw-grow"
                  >
                    <img
                      style="max-height: 150px; width: auto"
                      src="../../assets/svg/illustrations-test/2_no_campaigns_exist.png"
                      alt="Empty State Image"
                    />
                    <BlockStack
                      :gap="100"
                      class="tw-flex tw-text-center tw-items-center tw-justify-center"
                    >
                      <h1
                        class="tw-m-0 tw-text-lg tw-font-bold sm:tw-text-xl sm:tw-tracking-tight"
                      >
                        Your most recent campaign activity will show up here
                      </h1>
                    </BlockStack>
                    <BlockStack
                      :gap="100"
                      class="tw-flex tw-items-center tw-justify-center"
                    >
                      <slot></slot>
                    </BlockStack>
                  </BlockStack>
                </TheBox>
              </TheCard>
            </template>
            <template v-if="totalRecipientsCount">
              <div class="tw-flex-1 tw-w-1/2 tw-max-w-3xl">
                <RecipientProfilesTable
                  :dashboardView="true"
                  :sharedCampaignData="recipients"
                  :totalRecipientsCount="totalRecipientsCount"
                ></RecipientProfilesTable></div
            ></template>
            <template v-else>
              <TheCard
                customWidthClass="tw-w-96 tw-h-96 tw-flex tw-items-center tw-justify-center"
              >
                <TheBox :withPadding="true">
                  <BlockStack
                    :gap="300"
                    class="tw-w-full tw-flex tw-items-center tw-justify-center tw-grow"
                  >
                    <img
                      style="max-height: 150px; width: auto"
                      src="../../assets/svg/illustrations-test/46_blank_page.png"
                      alt="Empty State Image"
                    />
                    <BlockStack
                      :gap="100"
                      class="tw-flex tw-text-center tw-items-center tw-justify-center"
                    >
                      <h1
                        class="tw-m-0 tw-text-lg tw-font-bold sm:tw-text-xl sm:tw-tracking-tight"
                      >
                        Your most current recipient list will show up here.
                      </h1>
                    </BlockStack>
                  </BlockStack>
                </TheBox>
              </TheCard>
            </template>
          </div>
        </template>
      </PageSection>
    </BlockStack>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import DashboardStats from "../../components-v2/analytics/DashboardStats.vue";
import DashboardStatsSkeleton from "../../components-v2/ui/skeletons/DashboardStatsSkeleton.vue";
import DataTableSkeleton from "../../components-v2/ui/skeletons/DataTableSkeleton.vue";

import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import NewUserChecklist from "../../components-v2/analytics/NewUserChecklist.vue";
import CampaignsIndexTable from "../../components-v2/campaigns/CampaignsIndexTable";
import RecipientProfilesTable from "../../components-v2/recipients/RecipientProfilesTable";
import AccountSetup from "../../pages-v2/onboarding/account-setup/AccountSetup.vue";
import ProductTour from "../../pages-v2/onboarding/product-tour/ProductTour.vue";

import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import {
  listMailCampaigns,
  calculateRedeemedCampaignsWithFinalizations,
  aggregateMailCampaigns,
  createCampaign,
  calculateTotalClicks,
} from "@/services/campaignService";
import { upsertUserDetails } from "@/services/userService";
export default {
  name: "TheDashboard",
  components: {
    ProductTour,
    AccountSetup,
    TheBox,
    TheCard,
    CampaignsIndexTable,
    RecipientProfilesTable,
    BlockStack,
    ThePage,
    PageSection,
    DashboardStats,
    TheButton,
    PageHeader,
    NewUserChecklist,
    NotificationMessage,
    DashboardStatsSkeleton,
    DataTableSkeleton,
  },
  mounted() {
    const userHasLoggedIn =
      this?.$store?.state?.auth?.user?.metadata?.lastLoggedIn;
    if (!userHasLoggedIn) {
      this.showAccountSetupModal();
    }
  },
  async created() {
    await this.checkForUserData();
  },
  data() {
    return {
      items: [{ id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }],
      accountSetupChoices: {},
      onboarding: {
        accountSetup: {
          complete: true,
        },
        appTour: {
          active: false,
        },
      },
      campaigns: [],
      recipients: [],
      totalRecipientsCount: 0,
      totalCampaignsCount: 0,
      totalCampaignsSentCount: 0,
      totalCampaignsSentWithSeenBoolean: 0,
      totalCampaignsSentWithSeenArray: 0,
      redeemedCampaignsWithoutFinalizations: 0,
      totalSpendByCampaignsWithoutFinalizations: 0,
      totalSpendByCampaignsWithoutFinalizationsAggregated: 0,
      clicksByCampaignsCountWithSeenBoolean: 0,
      clicksByCampaignsCountWithSeenArray: 0,
      redeemedCampaignsWithFinalizations: 0,
      totalSpendByCampaignsWithFinalizations: 0,
      redeemedCount: 0,
      userHasCampaigns: null,
      loadingDashboardData: true,
      dataLoadedSuccessfully: true,
      campaignId: null,
      creatingCampaignDraft: false,
      alertIsVisible: false,
      isTakingTour: false,
      showGlobalError: false,
    };
  },
  computed: {
    currentUserEmail() {
      return this?.$store?.state?.auth?.user?.email;
    },
    User() {
      return this?.$store?.state?.auth?.user;
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    isChecklistComplete() {
      return this.totalSentCount && this.totalRecipientsCount;
    },
    checkListMessage() {
      if (
        this?.User?.metadata?.accountSetup?.question_one_selection.includes(
          "Land more meetings"
        )
      ) {
        return "Lets connect and convert your next big meeting today.";
      } else if (
        this?.User?.metadata?.accountSetup?.question_one_selection.includes(
          "Send thank you gifts"
        )
      )
        return "A simple gift can make a big impression. Let's get started.";
      else if (
        this?.User?.metadata?.accountSetup?.question_one_selection.includes(
          "Show appreciation"
        )
      ) {
        return "Strengthen bonds with a heartfelt thank you, today.";
      } else {
        return "Let's hit the ground running!";
      }
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    totalSpendCount() {
      return (
        this.totalSpendByCampaignsWithoutFinalizationsAggregated +
        this.totalSpendByCampaignsWithFinalizations
      );
    },
    totalSentCount() {
      return (
        this.totalCampaignsSentWithSeenBoolean +
        this.totalCampaignsSentWithSeenArray
      );
    },
    totalRedeemedCount() {
      return (
        this.redeemedCampaignsWithoutFinalizations +
        this.redeemedCampaignsWithFinalizations
      );
    },
    totalClicksCount() {
      return (
        this.clicksByCampaignsCountWithSeenArray +
        this.clicksByCampaignsCountWithSeenBoolean
      );
    },
  },
  methods: {
    /** check for user data */
    async checkForUserData() {
      this.loadingDashboardData = true;
      try {
        // alert()
        await this.checkIfUserHasCampaigns();
        // If user has campaigns, dashboard stats data
        if (this.userHasCampaigns) {
          await Promise.all([
            // this.loadSentCampaigns(),
            this.loadRedeemedCampaignsWithoutFinalizations(),
            this.loadRedeemedCampaignsWithFinalizations(),
            this.loadCampaignsWhereSeenIsArray(),
            this.loadCampaignsWhereSeenIsBoolean(),
            this.loadTotalSpendByRedeemedCampaignsWithoutFinalizations(),
          ]);
        }
        this.dataLoadedSuccessfully = true;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your dashboard data. Please reload the page and try again."
        );
      } finally {
        this.loadingDashboardData = false;
      }
    },

    /** handle check if user has recipient */
    async checkIfUserHasRecipients() {
      const api = await this.getBoldClient();
      const userId = this?.User?._id;

      let requestBody = {};

      if (!this.isBoldXchangeAdmin) {
        requestBody = {
          "metadata.giftingLeads": { $in: [userId] },
        };
      }
      let selection = "firstName lastName contact metadata";

      const queryParameters = {
        limit: 25,
        sort: "firstName",
        expand: "contact",
      };

      try {
        const response = await api.listRecipients(queryParameters, {
          filter: requestBody,
          selection: selection,
        });
        const count = response.data.result.count;
        this.totalRecipientsCount = count;
        this.recipients = response.data.result.records;
        this.checkingIfUserHasRecipients = false;
      } catch (error) {
        this.checkingIfUserHasRecipients = false;
        console.log("recipients did not load", error);
      }
    },

    /** handle checking if user has campaigns */
    async checkIfUserHasCampaigns() {
      let requestBody = {};
      let filter = {};
      filter = {
        ...(!this.isBoldXchangeAdmin && {
          "user._id": { $eq: this.userId },
        }),
        ...(this.isBoldXchangeAdmin && {
          $or: [
            { "user._id": { $ne: this.userId } },
            { "user._id": { $eq: this.userId } },
          ],
        }),
        "metadata.campaign_template": { $exists: false },
      };

      const queryParameters = {
        limit: 25,
        sort: "-_id",
        expand: this.isBoldXchangeAdmin
          ? "recipients user user.client invoice"
          : "recipients user invoice",
      };
      requestBody = {
        ...filter,
      };

      const response = await listMailCampaigns(queryParameters, requestBody);
      const count = response.data.result.count;
      const campaigns = response.data.result.records;

      this.checkIfUserHasRecipients();
      this.campaigns = campaigns;
      this.userHasCampaigns = count;
      return count;
    },
    /** handle loading dashboard stats data */
    async loadRedeemedCampaignsWithoutFinalizations() {
      const queryParameters = {
        limit: 10000,
      };
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "user._id": { $eq: this.userId },
        }),
        ...(this.isBoldXchangeAdmin && {
          $or: [
            { "user._id": { $ne: this.userId } },
            { "user._id": { $eq: this.userId } },
          ],
        }),
        $or: [
          {
            "metadata.campaign_status": {
              $eq: "redeemed",
            },
          },
          {
            "metadata.isRedeemed": true,
          },
        ],
        "metadata.campaign_template": { $exists: false },
      };

      const response = await listMailCampaigns(queryParameters, filter);
      const redeemedCount = response.data.result.count;
      this.redeemedCampaignsWithoutFinalizations = redeemedCount;

      // this.redeemedCampaignsWithoutFinalizations = response.data.result.count;
    },
    async loadTotalSpendByRedeemedCampaignsWithoutFinalizations() {
      let baseFilter = {
        ...(!this.isBoldXchangeAdmin && {
          "user._id": { $eq: this.userId },
        }),
        ...(this.isBoldXchangeAdmin && {
          $or: [
            { "user._id": { $ne: this.userId } },
            { "user._id": { $eq: this.userId } },
          ],
        }),
        "metadata.campaign_template": { $exists: false },
      };

      let spentFilter = {
        // invoice: { $exists: true },
        $or: [
          {
            "metadata.campaign_status": {
              $eq: "redeemed",
            },
          },
          {
            "metadata.isRedeemed": true,
          },
        ],
      };

      let group = {
        _id: null,
        selection: {
          "metadata.gift_card_amount": "$sum",
        },
      };

      const requestBody = {
        filter: { ...baseFilter, ...spentFilter },
        group: group,
      };

      const queryParameters = {
        limit: 10000,
        expand: this.isBoldXchangeAdmin
          ? "recipients user user.client metadata invoice"
          : "recipients user metadata invoice",
      };
      const totalSpendResponse = await aggregateMailCampaigns(
        queryParameters,
        requestBody
      );
      this.totalSpendByCampaignsWithoutFinalizationsAggregated =
        totalSpendResponse?.data?.result?.records[0]
          ?.metadata_gift_card_amount__sum || 0;
    },
    async loadRedeemedCampaignsWithFinalizations() {
      // let currentUserEmail = this?.User?.email;
      const queryParameters = {
        limit: 10000,
        expand: this.isBoldXchangeAdmin
          ? "recipients user"
          : "recipients user invoice",
      };

      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "user._id": { $eq: this.userId },
        }),
        ...(this.isBoldXchangeAdmin && {
          $or: [
            { "user._id": { $ne: this.userId } },
            { "user._id": { $eq: this.userId } },
          ],
        }),
        "metadata.campaign_template": { $exists: false },
        "metadata.finalizations": { $exists: true },
      };

      const response = await listMailCampaigns(queryParameters, filter);
      const campaigns = response.data.result.records;
      let finalizedCounts =
        calculateRedeemedCampaignsWithFinalizations(campaigns);
      this.redeemedCampaignsWithFinalizations =
        finalizedCounts.totalFinalizations;
      this.totalSpendByCampaignsWithFinalizations = finalizedCounts.totalSpend;
    },
    async loadCampaignsWhereSeenIsBoolean() {
      // let currentUserEmail = this?.User?.email;
      const queryParameters = {
        limit: 0,
      };
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "user._id": { $eq: this.userId },
        }),
        ...(this.isBoldXchangeAdmin && {
          $or: [
            { "user._id": { $ne: this.userId } },
            { "user._id": { $eq: this.userId } },
          ],
        }),
        "metadata.campaign_template": { $exists: false },
        $and: [
          {
            $or: [
              {
                "metadata.campaign_status": {
                  $eq: "sent",
                },
              },
              {
                "metadata.campaign_status": {
                  $eq: "redeemed",
                },
              },
            ],
          },
          {
            seen: {
              $eq: true,
            },
          },
          {
            $or: [
              { "metadata.isRedeemed": { $exists: false } },
              { "metadata.isRedeemed": true },
            ],
          },
        ],
      };

      const response = await listMailCampaigns(queryParameters, filter);
      const count = response.data.result.count;
      this.totalCampaignsSentWithSeenBoolean = count;
      this.clicksByCampaignsCountWithSeenBoolean = count;
    },
    async loadCampaignsWhereSeenIsArray() {
      // let currentUserEmail = this?.User?.email;
      const queryParameters = {
        limit: 10000,
        expand: this.isBoldXchangeAdmin
          ? "recipients user"
          : "recipients user invoice",
      };
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "user._id": { $eq: this.userId },
        }),
        ...(this.isBoldXchangeAdmin && {
          $or: [
            { "user._id": { $ne: this.userId } },
            { "user._id": { $eq: this.userId } },
          ],
        }),
        "metadata.campaign_template": { $exists: false },
        $and: [
          { seen: { $exists: true } },
          { seen: { $type: "array" } }, // { seen: { $ne: [] } },
          { "metadata.campaign_status": { $ne: "draft" } },
        ],
      };

      const response = await listMailCampaigns(queryParameters, filter);
      const campaignsWithSeenArray = response.data.result.records;

      this.clicksByCampaignsCountWithSeenArray = calculateTotalClicks(
        campaignsWithSeenArray
      ).totalClicks;

      this.totalCampaignsSentWithSeenArray = calculateTotalClicks(
        campaignsWithSeenArray
      ).totalRecipients;
    },
    async loadSentCampaigns() {
      // let currentUserEmail = this?.User?.email;

      const queryParameters = {
        limit: 0,
      };
      let filter = {
        ...(!this.isBoldXchangeAdmin && {
          "user._id": { $eq: this.userId },
        }),
        ...(this.isBoldXchangeAdmin && {
          $or: [
            { "user._id": { $ne: this.userId } },
            { "user._id": { $eq: this.userId } },
          ],
        }),
        "metadata.campaign_template": { $exists: false },
        $and: [
          {
            $or: [
              { "metadata.campaign_status": { $eq: "sent" } },
              { "metadata.campaign_status": { $eq: "redeemed" } },
              { "metadata.isRedeemed": { $exists: true } },
            ],
          },
        ],
      };

      const response = await listMailCampaigns(queryParameters, filter);
      this.totalCampaignsWithSingleRecipient = response.data.result.count;
    },

    /** handle creating a new campaign */
    async createCampaignDraft() {
      this.creatingCampaignDraft = true;

      try {
        const campaignResponse = await createCampaign();
        this.handleSuccess(campaignResponse, "createCampaignDraft");
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your new campaign. Please reload the page and try again."
        );
      } finally {
        this.creatingCampaignDraft = false;
      }
    },

    /** handle upserting user details */
    async upsertUserDetails(accountSetupChoices, retryCount = 3) {
      const loginDate = this.getCurrentDateISO();
      const userDetailsPatch = {
        "metadata.lastLoggedIn": loginDate,
        "metadata.accountSetup.question_one_selection":
          accountSetupChoices.questionOneChoice,
        "metadata.accountSetup.question_two_selection":
          accountSetupChoices.questionTwoChoice,
      };
      const userId = this?.User?._id;
      try {
        const memberRes = await upsertUserDetails(userId, userDetailsPatch);
        if (accountSetupChoices.questionTwoChoice === "Take a tour") {
          this.onboarding.appTour.active = true;
        } else {
          window.location.reload();
        }
        await this.$store.dispatch("auth/refreshUser");
        this.handleSuccess(memberRes, "upsertUserDetails");
      } catch (error) {
        console.error("user was NOT updated", error);
        this.onboarding.accountSetup.complete = true;
        setTimeout(() => {
          this.upsertUserDetails(accountSetupChoices, retryCount - 1);
        }, 2000);
        if (this.accountSetupChoices.questionTwoChoice === "Take a tour") {
          this.onboarding.appTour.active = true;
        }
      }
    },

    /** onboarding */
    showAccountSetupModal() {
      this.onboarding.accountSetup.complete = false;
    },
    saveAccountSetupChoices(accountSetupChoices) {
      this.onboarding.accountSetup.complete = true;
      this.accountSetupChoices = accountSetupChoices;
      this.upsertUserDetails(accountSetupChoices);
    },

    getCurrentDateISO() {
      const currentDate = new Date();
      return currentDate.toISOString();
    },
    exitProductTour() {
      this.onboarding.appTour.active = false;
      window.location.reload();
    },

    /** alert */
    toggleGlobalError() {
      this.showGlobalError = !this.showGlobalError;
    },
    /** handle errors and success */
    handleSuccess(response, api) {
      if (api === "createCampaignDraft") {
        this.creatingCampaignDraft = false;
        this.campaignId = response.data.result.records[0]._id;
        this.$router.push(`/campaign/edit/${this.campaignId}`);
      } else if (api === "upsertUserDetails") {
        this.onboarding.accountSetup.complete = true;
        console.log("member details update! ");
      } else {
        this.checkForUserData();
      }
    },
    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error.response) {
        // Process API error message if customMessage is not provided
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.source.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      this.creatingCampaignDraft = false;
      this.dataLoadedSuccessfully = false;
      this.loadingDashboardData = false;
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>
