<template>
  <BlockStack :gap="400">
    <NotificationMessage :scope="$options.name"></NotificationMessage>
    <BlockStack :gap="800" class="tw-w-full">
      <div class="tw-flex gap-2 tw-flex-wrap tw-w-full">
        <BlockStack :gap="200" class="tw-w-1/2 tw-flex-1">
          <label
            for="segmentName"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >
            Name<span
              v-if="!segmentNameIsValid"
              class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-white tw-py-1 tw-px-2 tw-rounded"
              >required field</span
            >
          </label>
          <input
            v-model="newSegment.metadata.name"
            type="text"
            name="groupName"
            id="groupName"
            :class="{
              'tw-ring-red-300': !segmentNameIsValid,
              'tw-ring-green-500': segmentNameIsValid,
            }"
            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-3.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
            placeholder="Group name"
          />
        </BlockStack>
        <BlockStack :gap="200" class="tw-w-1/2">
          <label
            for="segmentTags"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >
            Tags
          </label>
          <MultiSelectDropdown
            buttonText="Select tags"
            v-model="newSegment.metadata.tags"
            :options="availableTags"
            :placeholder="'Select tags'"
            size="large"
            :id="'segment-tags-multiselect-dropdown'"
          />
        </BlockStack>
      </div>
      <BlockStack :gap="200" class="tw-w-full">
        <label
          for="segmentConditions"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
        >
          Conditions<span
            v-if="!filterIsComplete"
            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-white tw-py-1 tw-px-2 tw-rounded"
            >required field</span
          >
        </label>
        <div
          class="tw-flex gap-2 tw-flex-wrap tw-w-full tw-p-2 tw-border tw-border-solid tw-bg-white tw-rounded"
        >
          <BlockStack :gap="200" class="tw-w-1/2">
            <label
              for="segmentTags"
              class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
            >
              Criteria
            </label>
            <ButtonDropdown
              :id="'segment-criteria-dropdown'"
              :items="criteriaDropdownItems()"
              :buttonText="
                segmentFilter?.selectedCriteria?.value
                  ? segmentFilter.selectedCriteria.text
                  : 'Select criteria...'
              "
              :alignLeft="true"
              :disabled="false"
              :active="false"
              :hasLeadingIcon="false"
              :hasTrailingIcon="true"
              size="small"
              variant="tertiary"
            />
          </BlockStack>
          <BlockStack
            :gap="200"
            class="tw-w-1/2"
            v-if="segmentFilter?.selectedCriteria?.value"
          >
            <label
              for="segmentCondition"
              class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
            >
              Condition
            </label>
            <div
              class="tw-flex tw-items-center gap-2 tw-flex-wrap tw-w-max tw-p-2"
            >
              <p class="tw-m-0 tw-font-bold">Recipient</p>
              <ButtonDropdown
                :id="'segment-isOrIsNot-dropdown'"
                :items="isOrIsNot()"
                :buttonText="
                  segmentFilter.selectedGroup.isOrIsNot ? 'is' : 'is NOT'
                "
                :disabled="false"
                :alignLeft="true"
                :active="false"
                :hasLeadingIcon="false"
                :hasTrailingIcon="true"
                size="small"
                variant="tertiary"
              />
              <p class="tw-m-0 tw-font-bold">in</p>
              <ButtonDropdown
                :id="'segment-groupList-dropdown'"
                :items="groupListDropdownItems()"
                :buttonText="
                  segmentFilter.selectedGroup.id
                    ? segmentFilter.selectedGroup.name
                    : 'Choose a group...'
                "
                :disabled="false"
                :alignLeft="true"
                :active="false"
                :hasLeadingIcon="false"
                :hasTrailingIcon="true"
                maxHeight="200px"
                size="small"
                variant="tertiary"
                @click="openGroupListDropdown"
              />
            </div>
          </BlockStack>
        </div>
      </BlockStack>
    </BlockStack>
    <ModalFooter>
      <div class="tw-flex tw-gap-4 tw-items-center tw-mt-6">
        <TheButton
          type="button"
          variant="primary"
          size="large"
          :disabled="!allItemsComplete"
          @click="saveNewSegment"
        >
          <template #text>Save segment</template>
        </TheButton>
        <TheButton
          type="button"
          variant="tertiary"
          size="large"
          @click="closeModal"
        >
          <template #text>Cancel</template>
        </TheButton>
      </div>
    </ModalFooter>
  </BlockStack>
</template>
<script>
import BlockStack from "../../layout/BlockStack.vue";
import ModalFooter from "../../layout/ModalFooter.vue";
import TheButton from "../../actions/TheButton.vue";
import MultiSelectDropdown from "../../elements/dropdown/MultiSelectDropdown.vue";
import NotificationMessage from "../../feedback/NotificationMessage.vue";
import ButtonDropdown from "../../elements/dropdown/ButtonDropdown.vue";

export default {
  name: "SegmentForm",
  emits: ["closeModal"],
  components: {
    ButtonDropdown,
    MultiSelectDropdown,
    BlockStack,
    ModalFooter,
    TheButton,
    NotificationMessage,
  },
  data() {
    return {
      newSegment: {
        model: "Recipient",
        metadata: {
          name: "",
          tags: [],
        },
        filter: {},
      },
      availableTags: [
    "a-lists",
    "active clients",
    "b-lists",
    "c-lists",
    "centers of influence",
    "clients",
    "closed lost",
    "closed won",
    "cold leads",
    "customers",
    "decision makers",
    "high engagements",
    "high priorities",
    "high values",
    "hot leads",
    "inactive clients",
    "influencers",
    "interested",
    "long-term clients",
    "loyal customers",
    "low engagements",
    "low priorities",
    "low values",
    "new clients",
    "not interested",
    "pending approval",
    "potential clients",
    "prospects",
    "repeat customers",
    "referral partners",
    "short-term clients",
    "strategic partners",
    "top prospects",
    "under negotiation",
    "VIPs",
    "warm leads",
],

      segmentFilter: {
        selectedCriteria: {
          value: null,
          text: null,
        },
        selectedGroup: {
          id: null,
          name: null,
          isOrIsNot: true,
        },
      },
      groups: [],
    };
  },
  watch: {
    segmentFilter: {
      handler() {
        this.newSegment.filter = this.buildFilter();
      },
      deep: true,
    },
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    allItemsComplete() {
      return this.segmentNameIsValid && this.filterIsComplete;
    },
    segmentNameIsValid() {
      return this.newSegment.metadata.name.trim().length > 0
        ? this.newSegment.metadata.name.trim()
        : false;
    },
    filterIsComplete() {
      return (
        this.segmentFilter.selectedCriteria.value &&
        this.segmentFilter.selectedGroup.id !== null &&
        typeof this.segmentFilter.selectedGroup.isOrIsNot === "boolean"
      );
    },
  },
  methods: {
    async saveNewSegment() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      const newSegment = [this.newSegment];
      newSegment.filter = this.buildFilter();
      console.log("newSegment: ", newSegment);

      try {
        const segmentRes = await api.createSegments(null, newSegment);
        console.log("segmentRes: ", segmentRes);
        this.handleSuccess(segmentRes.data.result.records[0]._id);
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("group was NOT created", error);
      }
    },
    /** load groups */
    async checkIfUserHasGroups() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.checkingIfUserHasGroups = true;
      let requestBody = {};
      let filter = {
        model: "Recipient",
      };

      const queryParameters = {
        limit: 1000,
        sort: "metadata.name",
      };

      requestBody = {
        filter: filter,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);

        const count = response.data.result.count;
        this.count = response.data.result.count;
        this.userHasGroups = count;
        this.groups = response.data.result.records;
        return true;
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.checkingIfUserHasGroups = false;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    criteriaDropdownItems() {
      return [
        {
          label: "If someone is in or not in a group",
          action: () =>
            this.handleCriteriaSelection(
              1,
              "If someone is in or not in a group"
            ),
        },
        {
          label: "What someone has done (or not done)",
          action: () =>
            this.handleCriteriaSelection(
              2,
              "What someone has done (or not done)"
            ),
        },
      ];
    },
    isOrIsNot() {
      return [
        {
          label: "Is",
          action: () => this.handleIsOrIsNot(true),
        },
        {
          label: "Is Not",
          action: () => this.handleIsOrIsNot(false),
        },
      ];
    },
    groupListDropdownItems() {
      return this.groups.map((group) => ({
        label: group.metadata.name,
        sublabel: `Members: ${group.members.length}`,
        action: () => this.handleGroupListSelection(group),
      }));
    },
    handleCriteriaSelection(criteria, text) {
      this.segmentFilter.selectedCriteria.value = criteria;
      this.segmentFilter.selectedCriteria.text = text;
      if (criteria === 1) {
        this.checkIfUserHasGroups();
      }
    },
    handleIsOrIsNot(isOrIsNotValue) {
      this.segmentFilter.selectedGroup.isOrIsNot = isOrIsNotValue;
    },
    handleGroupListSelection(group) {
      this.segmentFilter.selectedGroup.id = group._id;
      this.segmentFilter.selectedGroup.name = group.metadata.name;
    },
    buildFilter() {
      const filter = {
        "metadata.giftingLeads": { $in: [this.userId] },
      };

      if (this.segmentFilter.selectedCriteria.value === 1) {
        if (this.segmentFilter.selectedGroup.isOrIsNot) {
          filter["groups.0._id"] = { $eq: this.segmentFilter.selectedGroup.id };
        }
        if (!this.segmentFilter.selectedGroup.isOrIsNot) {
          filter["groups.0._id"] = { $ne: this.segmentFilter.selectedGroup.id };
        }
      } else if (this.segmentFilter.selectedCriteria.value === 2) {
        filter["groups.0._id"] = { $ne: this.segmentFilter.selectedGroup.id };
      }

      return filter;
    },
    handleSuccess(segmentId) {
      this.$router.push({
        name: "RecipientSegmentDetails",
        params: { segmentId },
      });
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again.";
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage =
          "An error occurred setting up the request that prevented it from being sent. Please try again.";
      }

      this.$store.dispatch("notifications/notifyError", errorMessage);
      console.error(errorMessage);
    },
  },
};
</script>
