<template>
  <BlockStack :gap="400">
    <NotificationMessage :scope="$options.name"></NotificationMessage>
    <BlockStack :gap="600" class="tw-w-full">
      <template v-if="members">
        <div class="tw-bg-blue-100 tw-px-2 tw-py-1 tw-text-lg">
          <p class="tw-m-0 tw-text-lg">
            <span class="tw-font-bold">{{ members.length }} recipients</span>
            will be added to your new group
          </p>
        </div>
      </template>
      <BlockStack :gap="200" class="tw-grow">
        <label
          for="groupName"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
        >
          Name<span
            v-if="!groupNameIsValid"
            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
            >required field</span
          >
        </label>
        <input
          v-model="newGroup.metadata.name"
          type="text"
          name="groupName"
          id="groupName"
          :class="{
            'tw-ring-red-300': !groupNameIsValid,
            'tw-ring-green-500': groupNameIsValid,
          }"
          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
          placeholder="Group name"
        />
      </BlockStack>
      <BlockStack :gap="200" class="tw-grow tw-pl-0">
        <label
          for="groupTags"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
        >
          Tags
        </label>
        <MultiSelectAccordion
          buttonText="Select tags"
          v-model="newGroup.metadata.tags"
          :options="availableTags"
          :placeholder="'Select tags'"
          size="large"
          :id="'group-tags-multiselect-dropdown'"
        />
      </BlockStack>
    </BlockStack>
  </BlockStack>
  <ModalFooter>
    <div class="tw-flex tw-gap-4 tw-items-center tw-mt-6">
      <TheButton
        type="button"
        variant="primary"
        size="large"
        :disabled="!this.allItemsComplete"
        @click="saveNewGroup()"
      >
        <template #text>Save group</template>
      </TheButton>
      <TheButton
        type="button"
        variant="tertiary"
        size="large"
        @click="closeModal()"
      >
        <template #text>Cancel</template>
      </TheButton>
    </div>
  </ModalFooter>
</template>

<script>
import BlockStack from "../../layout/BlockStack.vue";
import ModalFooter from "../../layout/ModalFooter.vue";
import TheButton from "../../actions/TheButton.vue";
import NotificationMessage from "../../feedback/NotificationMessage.vue";
import MultiSelectAccordion from "../../elements/accordion/MultiSelectAccordion.vue";

export default {
  name: "GroupForm",
  emits: ["closeModal"],
  components: {
    MultiSelectAccordion,
    BlockStack,
    ModalFooter,
    TheButton,
    NotificationMessage,
  },
  props: {
    members: {
      type: Array,
      default: null,
    },
  },
  created() {
    this.getGroupTags();
  },
  data() {
    return {
      newGroup: {
        model: "Recipient",
        metadata: {
          name: "",
          tags: [],
          admins: [], // can delete
          viewers: [], // read only
        },
      },
      existingGroupTags: [],
      availableTags: [
        "a-lists",
        "b-lists",
        "c-lists",
        "centers of influence",
        "cold leads",
        "customers",
        "warm leads",
      ],
    };
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    allItemsComplete() {
      return this.groupNameIsValid;
    },
    groupNameIsValid() {
      return this.newGroup.metadata.name.trim().length > 0
        ? this.newGroup.metadata.name.trim()
        : false;
    },
    memberIds() {
      const newMembers = this.members;

      return newMembers.map((member) => ({
        doc: member._id,
        metadata: {},
      }));
    },
  },
  methods: {
    async getGroupTags() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      let filter = {
        model: "Recipient",
        "metadata.admins": { $eq: this.userId },
      };

      const queryParameters = {
        limit: 500,
        distinct: "metadata.tags",
        expand: "metadata",
      };
      const requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);

        const tags = response?.data?.result?.records || [];

        this.existingGroupTags = tags;
        this.setAvailableTags();
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.checkingIfUserHasGroups = false;
      }
    },
    async saveNewGroup() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      const group = this.newGroup;
      if (this.members) {
        group.members = this.memberIds;
      }
      group.metadata.admins.push(this.userId);

      const newGroup = [group];
      // groupsArr.push(this.newGroup);
      console.log("newGroup: ", newGroup);

      try {
        const groupsRes = await api.createGroups(null, newGroup);
        console.log("groupsRes: ", groupsRes);
        this.handleSuccess(groupsRes.data.result.records[0]._id);
      } catch (error) {
        // this.handleError(error);
        console.log("group was NOT created", error);
      }
    },
    setAvailableTags() {
      // Define availableTags
      const availableTags = this.availableTags;

      // Combine lifecycleStage with availableTags, ensuring unique values
      const lifecycleStage = this.newGroup.metadata.tags;
      const existingTags = this.existingGroupTags;

      // Normalize both arrays to lowercase for comparison
      const normalizedAvailableTags = availableTags.map((tag) =>
        tag.toLowerCase()
      );
      const normalizedLifecycleStage = lifecycleStage.map((tag) =>
        tag.toLowerCase()
      );
      const normalizedExistingTags = existingTags.map((tag) =>
        tag.toLowerCase()
      );

      // Combine and ensure uniqueness
      const combinedNormalizedTags = Array.from(
        new Set([
          ...normalizedLifecycleStage,
          ...normalizedAvailableTags,
          ...normalizedExistingTags,
        ])
      );

      // Sort the combined unique tags in alphabetical order
      const sortedCombinedTags = combinedNormalizedTags.sort();

      this.availableTags = sortedCombinedTags;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    handleSuccess(groupId) {
      this.$router.push({ name: "RecipientGroupDetails", params: { groupId } });
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again.";
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage =
          "An error occurred setting up the request that prevented it from being sent. Please try again.";
      }

      this.$store.dispatch("notifications/notifyError", errorMessage);
      console.error(errorMessage);
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
