<template>
  <div v-if="editor">
    <div class="tw-flex tw-gap-2 tw-pb-2 tw-m-1">
      <!-- Toolbar buttons -->
      <button
        :disabled="
          !editor.can().chain().focus().toggleBold().run() || !isEditing
        "
        class="disabled:tw-bg-slate-200 disabled:tw-text-gray-400 tw-border-none tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-50 tw-px-2 tw-py-1 text-xs tw-font-medium tw-text-blue-700 tw-ring-1 tw-ring-inset tw-ring-blue-700/10"
        :class="{ 'tw-ring-blue-900 tw-ring-2': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <b-icon-type-bold></b-icon-type-bold>
      </button>
      <button
        :disabled="
          !editor.can().chain().focus().toggleItalic().run() || !isEditing
        "
        class="disabled:tw-bg-slate-200 disabled:tw-text-gray-400 tw-border-none tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-50 tw-px-2 tw-py-1 text-xs tw-font-medium tw-text-blue-700 tw-ring-1 tw-ring-inset tw-ring-blue-700/10"
        :class="{ 'tw-ring-blue-900 tw-ring-2': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <b-icon-type-italic></b-icon-type-italic>
      </button>
      <button
        :disabled="
          !editor.can().chain().focus().toggleBulletList().run() || !isEditing
        "
        class="disabled:tw-bg-slate-200 disabled:tw-text-gray-400 tw-border-none tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-50 tw-px-2 tw-py-1 text-xs tw-font-medium tw-text-blue-700 tw-ring-1 tw-ring-inset tw-ring-blue-700/10"
        :class="{
          'tw-ring-blue-900 tw-ring-2': editor.isActive('bulletList'),
        }"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <b-icon-list-ul></b-icon-list-ul>
      </button>
      <!-- More buttons can be added here -->
    </div>
    <template v-if="isEditing">
      <EditorContent :editor="editor" />
    </template>
    <template v-else>
      <div
        class="tw-min-h-40 tw-bg-gray-100 tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
        v-html="this.decodeHtml(this.modelValue)"
      ></div>
    </template>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";

export default {
  emits: ["update:modelValue"],
  components: {
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    isEditing: {
      type: Boolean,
    },
    isComplete: { type: Boolean },
  },
  data() {
    return {
      editor: null,
    };
  },
  watch: {
    modelValue(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },
  mounted() {
    this.editor = new Editor({
      extensions: [StarterKit],
      editable: true,
      editorProps: {
        attributes: {
          class:
            "tw-min-h-40 tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6",
        },
      },
      content: this.decodeHtml(this.modelValue),
      onUpdate: () => {
        // HTML
        console.log("this.editor.getHTML()", this.editor.getHTML());
        let html = this.editor.getHTML();
        if (html === "<p></p>") {
          html = "";
        }
        this.$emit("update:modelValue", html);

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
  },
};
</script>

<style>
.btn {
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  cursor: pointer;
}
.btn.active {
  background-color: #d1d5db; /* Gray background for active state */
}
</style>
