<template>
  <TheCard class="tw-overflow-visible tw-w-full">
    <CardHeader
      :showTitle="true"
      :showSubheading="true"
      :showActions="false"
      :showBorder="true"
      title="Profile Media"
    >
      <template #subheading-content>
        <p class="tw-m-0 tw-text-sm tw-text-gray-800 tw-w-sm">
          This information will be used for campaign communications.
        </p>
      </template>
    </CardHeader>
    <TheBox :withPadding="true">
      <BlockStack :gap="400">
        <div
          class="sm:tw-flex tw-items-start tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
        >
          <div
            class="tw-flex tw-items-center tw-gap-2 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
          >
            <p class="tw-text-gray-900 tw-m-0">Profile Photo</p>
            <TheTooltip
              :toolTip="true"
              :tooltipContent="profilePhotoTooltipContent"
            >
              <template #icon>
                <b-icon-question-circle
                  class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                ></b-icon-question-circle>
              </template>
            </TheTooltip>
          </div>
          <div class="tw-flex tw-items-center tw-gap-5">
            <!-- existing logo media -->
            <div>
              <template
                v-if="
                  existingProfilePhotoMediaFiles.length > 0 &&
                  newProfilePhotoMediaFiles.length === 0
                "
              >
                <div class="tw-h-32 tw-w-32">
                  <img
                    class="tw-h-full tw-w-full tw-rounded-full tw-flex-none tw-object-cover tw-border-solid tw-border-[0.5px]"
                    :src="existingProfilePhotoMediaFiles[0].url"
                    alt="Profile Photo"
                  />
                </div>
              </template>
              <template v-else-if="newProfilePhotoMediaFiles.length > 0">
                <div
                  class="tw-h-32 tw-w-32"
                  v-for="(file, key) in newProfilePhotoMediaFiles"
                  :key="'file-' + file.id"
                >
                  <img
                    class="tw-h-full tw-w-full tw-rounded-full tw-flex-none tw-object-cover tw-border-solid tw-border-[0.5px]"
                    :id="'image-' + file.id"
                    alt="Profile Photo"
                  />
                </div>
              </template>
              <template v-else>
                <div class="tw-h-32 tw-w-32">
                  <img
                    :src="Avatar"
                    alt="Placeholder profile photo"
                    class="tw-h-full tw-w-full tw-rounded-full tw-flex-none tw-object-cover tw-border-solid tw-border-[0.5px]"
                  />
                </div>
              </template>
            </div>
            <div class="">
              <div class="tw-flex tw-gap-2">
                <template v-if="newProfilePhotoMediaFiles.length > 0">
                  <TheButton
                    :disabled="isSaving"
                    variant="success"
                    size="small"
                    @click="upsertProfilePhoto()"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon><b-icon-trash /></template>
                    <template #text> Save </template>
                  </TheButton>
                </template>
                <template v-if="newProfilePhotoMediaFiles.length === 0">
                  <label
                    class="tw-w-fit tw-cursor-pointer tw-text-xs tw-py-2 tw-px-2 tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed"
                    for="profilePhoto"
                  >
                    <b-icon-upload />
                    <div class="tw-w-fit">
                      {{
                        existingProfilePhotoMediaFiles.length > 0 ||
                        newProfilePhotoMediaFiles.length > 0
                          ? "Replace"
                          : "Upload photo"
                      }}
                      <input
                        class="form-control"
                        type="file"
                        id="profilePhoto"
                        ref="profilePhoto"
                        accept=".jpg,.jpeg,.png"
                        hidden
                        @change="handleProfilePhotoMediaUpload($event)"
                      />
                    </div>
                  </label>
                </template>
                <template
                  v-if="
                    existingProfilePhotoMediaFiles.length > 0 &&
                    newProfilePhotoMediaFiles.length === 0
                  "
                >
                  <TheButton
                    :disabled="isSaving"
                    variant="critical"
                    size="small"
                    @click="deleteProfilePhoto()"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon><b-icon-trash /></template>
                    <template #text> Delete </template>
                  </TheButton>
                </template>
                <template v-if="newProfilePhotoMediaFiles.length > 0">
                  <TheButton
                    :disabled="isSaving"
                    variant="tertiary"
                    size="small"
                    @click="cancelEdit('profile photo')"
                  >
                    <template #text> Cancel </template>
                  </TheButton>
                </template>
              </div>
              <!-- </template> -->

              <p
                class="tw-mt-2 tw-mb-0 tw-text-xs tw-leading-5 tw-text-gray-400"
              >
                JPG or PNG.
              </p>
            </div>
          </div>
        </div>
        <div
          class="sm:tw-flex tw-items-start tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
        >
          <div
            class="tw-flex tw-items-center tw-gap-2 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
          >
            <p class="tw-text-gray-900 tw-m-0">Organization Logo</p>
            <TheTooltip
              :toolTip="true"
              :tooltipContent="organizationLogoTooltipContent"
            >
              <template #icon>
                <b-icon-question-circle
                  class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                ></b-icon-question-circle>
              </template>
            </TheTooltip>
          </div>
          <div class="tw-flex tw-items-center tw-gap-5">
            <!-- existing logo media -->
            <div>
              <template
                v-if="
                  existingOrganizationLogoMediaFiles.length > 0 &&
                  newOrganizationLogoMediaFiles.length === 0
                "
              >
                <div class="tw-h-28 tw-w-48 tw-mx-auto">
                  <img
                    class="tw-h-full tw-w-full tw-object-contain tw-border-dashed tw-p-4 tw-border-[0.5px]"
                    :src="existingOrganizationLogoMediaFiles[0].url"
                    alt="Organization Logo"
                  />
                </div>
              </template>
              <template v-else-if="newOrganizationLogoMediaFiles.length > 0">
                <div
                  class="tw-h-28 tw-w-48 tw-mx-auto"
                  v-for="(file, key) in newOrganizationLogoMediaFiles"
                  :key="'file-' + file.id"
                >
                  <img
                    class="tw-h-full tw-w-full tw-object-contain tw-border-dashed tw-p-4 tw-border-[0.5px]"
                    :id="'image-' + file.id"
                    alt="Organization Logo"
                  />
                </div>
              </template>
              <template v-else>
                <div class="tw-h-28 tw-w-48 tw-mx-auto">
                  <img
                    src="../../assets/svg/illustrations-test/13_gift_box.png"
                    alt="Placeholder organization logo"
                    class="tw-h-full tw-w-full tw-object-contain tw-border-dashed tw-p-4 tw-border-[0.5px]"
                  />
                </div>
              </template>
            </div>
            <div class="">
              <div class="tw-flex tw-gap-2">
                <template v-if="newOrganizationLogoMediaFiles.length > 0">
                  <TheButton
                    :disabled="isSaving"
                    variant="success"
                    size="small"
                    @click="upsertOrganizationLogo()"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon><b-icon-trash /></template>
                    <template #text> Save </template>
                  </TheButton>
                </template>
                <template v-if="newOrganizationLogoMediaFiles.length === 0">
                  <label
                    class="tw-w-fit tw-cursor-pointer tw-text-xs tw-py-2 tw-px-2 tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed"
                    for="organizationLogo"
                  >
                    <b-icon-upload />
                    <div class="tw-w-fit">
                      {{
                        existingOrganizationLogoMediaFiles.length > 0 ||
                        newOrganizationLogoMediaFiles.length > 0
                          ? "Replace"
                          : "Upload photo"
                      }}
                      <input
                        class="form-control"
                        type="file"
                        id="organizationLogo"
                        ref="organizationLogo"
                        accept=".jpg,.jpeg,.png"
                        hidden
                        @change="handleOrganizationLogoMediaUpload($event)"
                      />
                    </div>
                  </label>
                </template>
                <template
                  v-if="
                    existingOrganizationLogoMediaFiles.length > 0 &&
                    newOrganizationLogoMediaFiles.length === 0
                  "
                >
                  <TheButton
                    :disabled="isSaving"
                    variant="critical"
                    size="small"
                    @click="deleteOrganizationLogo()"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon><b-icon-trash /></template>
                    <template #text> Delete </template>
                  </TheButton>
                </template>
                <template v-if="newOrganizationLogoMediaFiles.length > 0">
                  <TheButton
                    :disabled="isSaving"
                    variant="tertiary"
                    size="small"
                    @click="cancelEdit('organization logo')"
                  >
                    <template #text> Cancel </template>
                  </TheButton>
                </template>
              </div>
              <!-- </template> -->

              <p class="tw-mt-2 tw-mb-0 tw-text-xs tw-text-gray-400">
                JPG or PNG.
              </p>
            </div>
          </div>
        </div>
      </BlockStack>
    </TheBox>
  </TheCard>
</template>

<script>
import TheBox from "../../components-v2/ui/layout/TheBox";
import TheCard from "../../components-v2/ui/layout/TheCard";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import CardHeader from "../../components-v2/ui/layout/CardHeader";
import TheButton from "../ui/actions/TheButton.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import userMediaMixin from "@/mixins/userMediaMixin";

export default {
  name: "UserProfileMedia",
  components: {
    BlockStack,
    TheTooltip,
    TheButton,
    TheBox,
    TheCard,
    CardHeader,
  },
  mixins: [userMediaMixin],
  data() {
    return {
      files: [],
      isLoading: true,
      showCampaigns: false,
      showRecipients: false,
      isEditingDetails: false,
      isSaving: false,
    };
  },
  async created() {
    // await this.handleCreatedLifecycleLogic();
  },
  computed: {
    Avatar() {
      const url = this?.User?.photo;
      if (url) {
        if (url.indexOf("/") === 0) {
          return process.env.VUE_APP_API_BASE_URL + url;
        } else {
          return url;
        }
      } else {
        return `https://api.dicebear.com/7.x/initials/svg?seed=${this?.User?.firstName}%20${this?.User?.lastName}&backgroundColor=3949ab`;
      }
    },
    profilePhotoTooltipContent() {
      return "Your profile photo is used for your account avatar and show up on your personal gift redemption landing page, when enabled.";
    },
    organizationLogoTooltipContent() {
      return "If enabled, your organization logo will show up on your personal gift redemption landing page.";
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    cancelEdit(mediaType) {
      if (mediaType === "profile photo") {
        this.newProfilePhotoMediaFiles = [];
      } else {
        this.newOrganizationLogoMediaFiles = [];
      }
    },

    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again.";
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      console.error(errorMessage);
    },
    handleSuccess() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Profile successfully updated.",
        duration: 6000,
        id: Date.now(),
      });
      this.isEditingDetails = false;
      this.handleLoadingUserMedia();
    },
  },
};
</script>

<style scoped></style>
