<template>
  <template v-if="showDeleteModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        title="Delete selected invoices?"
        @closeModal="closeModal"
        :open="showDeleteModal"
      >
        <ModalBody>
          <DeleteData
            :invoices="idsToDelete"
            @closeModal="closeModal"
            @confirmDelete="confirmDelete()"
          ></DeleteData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="checkingIfUserHasInvoices">
    <div
      class="tw-flex tw-justify-center tw-items-center tw-w-full tw-max-w-5xl tw-mx-auto"
    >
      <PageSection :fullWidth="true">
        <PulseAnimation>
          <DataTableSkeleton></DataTableSkeleton>
        </PulseAnimation>
      </PageSection>
    </div>
  </template>
  <template v-else>
    <TheCard class="tw-w-full">
      <template v-if="userHasInvoices">
        <CardHeader
          :showTitle="dashboardView"
          title="Invoices"
          :showActions="true"
          :useSearchBar="!dashboardView"
        >
          <template #card-search-bar>
            <div class="tw-max-w-sm tw-w-full tw-flex-grow">
              <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-full">
                <div
                  class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                >
                  <b-icon-search
                    class="tw-h-5 tw-w-5 tw-text-gray-400"
                    aria-hidden="true"
                  ></b-icon-search>
                </div>
                <input
                  @keyup.enter="handleSearchQuery()"
                  v-model="searchQuery.value"
                  type="text"
                  name="invoiceSearch"
                  id="invoiceSearch"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                  :placeholder="searchBarPlaceholder"
                />
                <template v-if="searchQuery.active">
                  <div
                    @click="resetFilters()"
                    class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-cursor-pointer"
                  >
                    <span>reset</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template #card-header-actions>
            <template v-if="dashboardView">
              <TheLink to="/invoices" :isRouterLink="true">
                <template #text>View all invoices</template>
              </TheLink>
            </template>
            <template v-else>
              <!-- tabs -->
              <div class="tw-flex tw-bg-gray-100 tw-p-0.5 tw-rounded-md">
                <TheButton
                  type="button"
                  :variant="selectedInvoiceStatus === 1 ? 'secondary' : 'ghost'"
                  size="regular"
                  @click="handleStatusSelection(1)"
                  :hasLeadingIcon="true"
                >
                  <template #leading-icon>
                    <b-icon-check-square></b-icon-check-square>
                  </template>
                  <template #text>Fulfilled</template>
                </TheButton>
                <TheButton
                  type="button"
                  :variant="selectedInvoiceStatus === 0 ? 'secondary' : 'ghost'"
                  size="regular"
                  @click="handleStatusSelection(0)"
                  :hasLeadingIcon="true"
                >
                  <template #leading-icon>
                    <b-icon-hourglass-top></b-icon-hourglass-top>
                  </template>
                  <template #text>Unfulfilled</template>
                </TheButton>
                <TheButton
                  type="button"
                  :variant="
                    selectedInvoiceStatus === -1 ? 'secondary' : 'ghost'
                  "
                  size="regular"
                  @click="handleStatusSelection(-1)"
                  :hasLeadingIcon="true"
                >
                  <template #leading-icon>
                    <b-icon-list></b-icon-list>
                  </template>
                  <template #text>All</template>
                </TheButton>
              </div>
              <!-- sort -->
              <DropdownMenu
                :buttonDropdownList="true"
                :buttonDropdown="true"
                :buttonText="
                  sortedValue === '-_id' ? 'Sort by: Newest' : 'Sort by: Oldest'
                "
                :items="sortDropdownItems()"
              >
              </DropdownMenu>
              <!-- custom date rage -->
              <DropdownMenu
                :active="customDateRangeApplied"
                :buttonDropdown="true"
                buttonText="Custom date"
              >
                <TheBox customClass="tw-p-3">
                  <BlockStack :gap="400">
                    <div class="">
                      <label for="startDate">Start day</label>
                      <input
                        v-model="startDate"
                        id="startDate"
                        class="form-control"
                        type="date"
                      />
                      <span id="startDateSelected"></span>
                    </div>
                    <div class="">
                      <label for="endDate">End day</label>
                      <input
                        v-model="endDate"
                        id="endDate"
                        class="form-control"
                        type="date"
                      />
                      <span id="endDateSelected"></span>
                    </div>
                    <div class="tw-flex tw-justify-end tw-gap-2">
                      <template v-if="customDateRangeApplied">
                        <TheButton
                          type="button"
                          variant="tertiary"
                          size="small"
                          @click.prevent.stop="clearCustomDates()"
                        >
                          <template #text> Clear dates</template>
                        </TheButton>
                      </template>
                      <TheButton
                        type="button"
                        variant="primary"
                        size="small"
                        :disabled="!startDate || !endDate"
                        @click="handleCustomDateRangeSelection()"
                      >
                        <template #text>Apply</template>
                      </TheButton>
                    </div>
                  </BlockStack>
                </TheBox>
              </DropdownMenu>
            </template>
          </template>
        </CardHeader>
      </template>
      <CardBody>
        <template v-if="invoicesAreLoading">
          <PulseAnimation>
            <DataTableSkeleton></DataTableSkeleton>
          </PulseAnimation>
        </template>
        <template v-else>
          <!-- if userHasInvoices show campaigns list -->
          <template v-if="userHasInvoices && count">
            <div
              class="tw-overflow-y-auto tw-min-h-[500px] tw-max-h-[500px] tw-w-full"
            >
              <template v-if="!dashboardView">
                <div
                  class="tw-flex tw-justify-between gap-4 tw-h-12 tw-px-3 tw-bg-stone-100 tw-sticky tw-top-0 tw-z-50 tw-items-center tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b tw-border-stone-300"
                >
                  <div>
                    <input
                      id="selectAllInvoices"
                      name="selectAllInvoices"
                      type="checkbox"
                      class="tw-left-4 tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-me-2"
                      :checked="selectedInvoices.length === invoices.length"
                      @change="selectAllInvoices($event.target.checked)"
                    /><label class="tw-text-gray-900" for="selectAllInvoices"
                      >Select all visible</label
                    >
                  </div>
                  <template v-if="selectedInvoices.length > 0 && !campaignView">
                    <div class="tw-flex tw-gap-4 tw-items-center">
                      <p
                        class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600"
                      >
                        {{ selectedInvoices.length }} selected
                      </p>
                      <DropdownMenu
                        :disabled="selectedInvoices.length === 0"
                        :items="bulkDropdownItems()"
                        :buttonDropdown="true"
                        :buttonDropdownList="true"
                        buttonText="Bulk actions"
                      >
                        <template #icon>
                          <b-icon-three-dots-vertical
                            class="tw-text-black tw-z-0"
                          ></b-icon-three-dots-vertical>
                        </template>
                      </DropdownMenu>
                    </div>
                  </template>
                  <template v-if="selectedInvoices.length > 0 && campaignView">
                    <div class="tw-flex tw-gap-4 tw-items-center">
                      <template v-if="campaignRecipientsSelectionUpdated">
                        <p
                          class="tw-m-0 tw-text-sm tw-font-bold tw-text-green-600"
                        >
                          <b-icon-check-lg></b-icon-check-lg> Recipient list
                          updated
                        </p>
                      </template>
                      <template v-else>
                        <p
                          class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600"
                        >
                          {{ selectedInvoices.length }} selected
                        </p>
                      </template>
                      <TheButton
                        :disabled="this.selectedInvoices.length === 0"
                        variant="success"
                        @click="setCampaignRecipients()"
                        size="small"
                        :hasLeadingIcon="true"
                      >
                        <template #leading-icon
                          ><b-icon-check-lg></b-icon-check-lg
                        ></template>
                        <template #text>Add selected recipients</template>
                      </TheButton>
                    </div>
                  </template>
                </div>
              </template>
              <table class="tw-min-w-full tw-divide-stone-300">
                <thead
                  class="tw-sticky tw-z-10 tw-border-b tw-border-stone-300"
                  :class="{ 'tw-top-12': !dashboardView }"
                >
                  <tr>
                    <th
                      scope="col"
                      class="tw-whitespace-nowrap tw-px-2 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Invoice number
                    </th>
                    <th
                      scope="col"
                      class="tw-whitespace-nowrap tw-px-2 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Recipient
                    </th>
                    <th
                      scope="col"
                      class="tw-whitespace-nowrap tw-px-2 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Gifting Lead
                    </th>
                    <th
                      scope="col"
                      class="tw-whitespace-nowrap tw-px-2 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Date Redeemed
                    </th>
                    <th
                      scope="col"
                      class="tw-whitespace-nowrap tw-px-2 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Gift Card Amount
                    </th>
                    <th
                      scope="col"
                      class="tw-whitespace-nowrap tw-px-2 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    ></th>
                  </tr>
                </thead>
                <tbody
                  class="tw-divide-y tw-divide-gray-200 tw-bg-white tw-min-h-[500px]"
                >
                  <tr
                    v-for="invoice in campaignData"
                    :key="invoice.id"
                    :class="[
                      selectedInvoices.includes(invoice.id) && 'bg-gray-50',
                      'tw-cursor-pointer hover:tw-bg-gray-100',
                    ]"
                    @click="goToInvoiceDetails(invoice.id)"
                  >
                    <td
                      class="tw-relative tw-px-2 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-900"
                    >
                      <div
                        v-if="isInvoiceSelected(invoice.id)"
                        class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-blue-600"
                      ></div>
                      <div class="tw-flex tw-items-center gap-2">
                        <input
                          type="checkbox"
                          class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-cursor-pointer"
                          :checked="isInvoiceSelected(invoice.id)"
                          @change="toggleInvoiceSelection(invoice)"
                          @click.stop
                        />
                        <div>
                          <template v-if="invoiceNumber(invoice)">
                            <p class="tw-p-0 tw-m-0 tw-text-sm">
                              {{ invoiceNumber(invoice) }}
                            </p>
                            <span
                              :class="{
                                'tw-inline-flex tw-items-center tw-rounded-md tw-bg-red-100 tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-red-800':
                                  !hasInvoiceBeenFulfilled(invoice),
                                'tw-inline-flex tw-items-center tw-rounded-md tw-bg-green-100 tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-green-800':
                                  !!hasInvoiceBeenFulfilled(invoice),
                              }"
                            >
                              <template
                                v-if="!!hasInvoiceBeenFulfilled(invoice)"
                              >
                                Fulfilled
                              </template>
                              <template
                                v-if="!hasInvoiceBeenFulfilled(invoice)"
                              >
                                Action required
                              </template>
                            </span>
                          </template>
                          <template v-else>
                            <p class="tw-p-0 tw-m-0 tw-text-sm">No info</p>
                          </template>
                        </div>
                      </div>
                    </td>
                    <td
                      class="tw-px-2 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-900"
                    >
                      <div class="tw-flex tw-flex-col tw-w-sm">
                        <template v-if="recipientName(invoice)">
                          <p
                            class="tw-p-0 tw-m-0 tw-text-sm tw-capitalize tw-truncate"
                          >
                            {{ recipientName(invoice) }}
                          </p>
                        </template>
                        <template v-if="recipientEmail(invoice)">
                          <p class="tw-p-0 tw-m-0 tw-text-sm">
                            {{ recipientEmail(invoice) }}
                          </p>
                        </template>
                      </div>
                    </td>
                    <td
                      class="tw-px-2 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-900"
                    >
                      <div class="tw-flex tw-flex-col">
                        <p class="tw-p-0 tw-m-0 tw-text-sm">
                          {{ userName(invoice) || "N/A" }}
                        </p>
                        <p class="tw-p-0 tw-m-0 tw-text-sm">
                          {{ userEmail(invoice) || "N/A" }}
                        </p>
                      </div>
                    </td>
                    <td
                      class="tw-px-2 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-900"
                    >
                      <p class="tw-p-0 tw-m-0 tw-text-sm">
                        {{ getTimeStamp(invoice.id) }}
                      </p>
                    </td>
                    <td
                      class="tw-px-2 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-900"
                    >
                      {{
                        `$${convertAmountToDollars(
                          invoice?.metadata?.amount
                        )} ` || "---"
                      }}
                    </td>
                    <td
                      class="tw-px-2 tw-py-2 tw-whitespace-nowrap tw-text-sm tw-text-gray-900"
                    >
                      <template v-if="invoice?.campaign[0]?._id">
                        <TheLink
                          variant="primary"
                          :url="{
                            name: 'CampaignDetails',
                            params: { campaignId: invoice?.campaign[0]?._id },
                          }"
                          :isRouterLink="true"
                        >
                          <template #text> View campaign</template>
                        </TheLink>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <!-- if userHasInvoices AND query count is 0 show campaigns message -->
          <template v-if="userHasInvoices && !count">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
            >
              <img
                style="max-height: 150px; width: auto"
                src="../../assets/svg/illustrations-test/14_no_query_results.png"
                alt="Image Description"
              />
              <h1 class="tw-text-2xl tw-m-0">No exact matches</h1>
              <p class="tw-m-0 tw-text-lg">
                Try updating your search/filters or
                <a
                  class="tw-font-bold tw-cursor-pointer"
                  @click="resetFilters()"
                  >click here</a
                >
                to reset.
              </p>
            </div>
          </template>
          <!-- if !userHasInvoices show message -->
          <template v-if="!userHasInvoices">
            <div
              class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
            >
              <EmptyState emptyState="invoices"></EmptyState>
            </div>
          </template>
        </template>

        <!-- </div> -->
      </CardBody>
      <template v-if="userHasInvoices && !dashboardView">
        <CardFooter>
          <!-- Pagination -->
          <nav
            class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200"
            aria-label="Pagination"
          >
            <div class="tw-hidden sm:tw-block">
              <p class="tw-text-sm tw-text-gray-700 tw-m-0">
                <span class="tw-font-bold">{{ count }}</span> records
              </p>
            </div>
            <div
              class="tw-flex tw-flex-1 tw-justify-between tw-gap-x-3 sm:tw-justify-end"
            >
              <TheButton
                class="disabled:tw-bg-slate-200 disabled:tw-cursor-auto"
                :disabled="this.currentPage === 1"
                @click="previousPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-left></b-icon-chevron-left
                ></template>
              </TheButton>
              <TheButton
                class="disabled:tw-bg-slate-200"
                :disabled="this.isLastPage"
                @click="nextPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-right></b-icon-chevron-right
                ></template>
              </TheButton>
            </div>
          </nav>
        </CardFooter>
      </template>
    </TheCard>
  </template>
</template>

<script>
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader.vue";
import CardBody from "../../components-v2/ui/layout/CardBody.vue";
import CardFooter from "../../components-v2/ui/layout/CardFooter.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import DataTableSkeleton from "../../components-v2/ui/skeletons/DataTableSkeleton.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import DropdownMenu from "../../components-v2/ui/forms/DropdownMenu.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
// import ModalHeader from "../../components-v2/ui/layout/ModalHeader.vue";
import DeleteData from "../ui/modals/DeleteData.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import { listInvoices, findRedeemerMatch } from "@/services/invoiceService";

export default {
  name: "CampaignsIndexTable",
  components: {
    DataTableSkeleton,
    PulseAnimation,
    BlockStack,
    TheBox,
    // ModalHeader,
    ModalBody,
    DeleteData,
    TheModal,
    DropdownMenu,
    TheLink,
    EmptyState,
    CardBody,
    TheCard,
    CardHeader,
    TheButton,
    CardFooter,
    PageSection,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    dashboardView: {
      type: Boolean,
      default: false,
    },
    sharedCampaignData: {
      type: Array,
      default: () => [],
    },
    totalInvoicesCount: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  created() {
    /** load data */
    if (!this.dashboardView) {
      this.checkForInvoices();
    } else {
      this.invoicesAreLoading = false;
      this.checkingIfUserHasInvoices = false;
      if (this.totalInvoicesCount) {
        this.userHasInvoices = true;
      }
      this.count = this.totalInvoicesCount;
    }
  },
  data() {
    return {
      statusOptions: [
        { value: "all" },
        { value: "sent" },
        { value: "clicked" },
        { value: "redeemed" },
        { value: "draft" },
      ],
      typeOptions: [{ value: "email" }, { value: "link" }],
      dateOptions: [
        { value: "All time" },
        { value: "This week" },
        { value: "Past 7 days" },
        { value: "Past 30 days" },
        { value: "Past 90 days" },
      ],
      selectedInvoices: [],
      selectedRows: [],
      invoices: [],
      campaignIdToDelete: null,
      count: 0,
      pageSize: 25,
      currentPage: 1,
      searchQuery: {
        active: false,
        value: "",
      },
      sortedValue: "-_id",
      selectedDateRange: "",
      selectedCampaignType: "",
      selectedInvoiceStatus: -1,
      customDateRangeApplied: false,
      noDataPlaceholderText: "---",
      startDate: "",
      endDate: "",
      startDateTimestamp: null,
      endDateTimestamp: null,
      stamp: null,
      showDeleteModal: false,
      searchIsActive: false,
      checkingIfUserHasInvoices: false,
      invoicesAreLoading: false,
      userHasInvoices: true,
    };
  },
  computed: {
    campaignData() {
      return this.dashboardView ? this.sharedCampaignData : this.invoices;
    },
    currentUserEmail() {
      return this?.$store?.state?.auth?.user?.email;
    },
    User() {
      return this?.$store?.state?.auth?.user;
    },
    getQuickEditStatus() {
      return this.$store.getters["campaigns/getQuickEditStatus"];
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.invoices.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    userName() {
      return (invoice) => {
        const firstName = invoice?.user?.firstName;
        const lastName = invoice?.user?.lastName;
        if (firstName && lastName) {
          return `${firstName} ${lastName}`;
        } else {
          return false;
        }
      };
    },
    userEmail() {
      return (invoice) => invoice?.user?.email;
    },
    recipientsCount() {
      return (invoice) => {
        return invoice?.recipients?.length || 0;
      };
    },

    recipientName() {
      return (invoice) => {
        const redeemerMatch = findRedeemerMatch(invoice);
        if (
          redeemerMatch?.redeemer &&
          redeemerMatch?.redeemer?.firstName &&
          redeemerMatch?.redeemer?.lastName
        ) {
          // return redeemerMatch
          return `${redeemerMatch?.redeemer?.firstName} ${redeemerMatch?.redeemer?.lastName}`;
        } else if (
          redeemerMatch?.recipients[0]?.firstName &&
          redeemerMatch?.recipients[0]?.lastName
        ) {
          return `${redeemerMatch?.recipients[0]?.firstName} ${redeemerMatch?.recipients[0]?.lastName}`;
        } else {
          return "N/A";
        }
      };
    },
    recipientEmail() {
      return (invoice) => {
        const redeemerMatch = findRedeemerMatch(invoice);
        if (redeemerMatch?.redeemer && redeemerMatch?.redeemer?.email) {
          // return redeemerMatch
          return redeemerMatch?.redeemer?.email;
        } else if (redeemerMatch?.recipients[0]?.contact?.email) {
          return redeemerMatch?.recipients[0]?.contact?.email;
        } else {
          return "N/A";
        }
      };
    },
    invoiceNumber() {
      return (invoice) => {
        if (invoice?.receipt?.number) {
          return invoice?.receipt?.number;
        } else {
          return false;
        }
      };
    },
    getTimeStamp() {
      return (id) => {
        if (id) {
          const objectIdToTimestamp = require("objectid-to-timestamp");
          const originalDate = new Date(objectIdToTimestamp(id));

          const year = originalDate.getFullYear();
          const month = (originalDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          const day = originalDate.getDate().toString().padStart(2, "0");

          return `${month}/${day}/${year}`;
        }
      };
    },
    convertAmountToDollars() {
      return (giftCardAmountInPennies) => {
        return (giftCardAmountInPennies / 100).toFixed(2);
      };
    },
    searchBarPlaceholder() {
      return this.isBoldXchangeAdmin
        ? "Search by user name or email"
        : "Search by recipient name or email";
    },
    hasInvoiceBeenFulfilled() {
      return (invoice) => {
        return (
          invoice?.metadata?.date_fulfilled ||
          invoice?.campaign[0]?.metadata?.date_fulfilled
        );
      };
    },
    filterButtonText() {
      if (this.selectedInvoiceStatus === 1) {
        return "Showing fulfilled";
      } else if (this.selectedInvoiceStatus === 0) {
        return "Showing unfulfilled";
      } else {
        return "Showing all";
      }
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
    idsToDelete() {
      return this.invoiceIdsToDelete.map((recipient) => recipient._id);
    },
  },
  methods: {
    /** handle checking if user has campaigns */
    async checkForInvoices(page) {
      this.checkingIfUserHasInvoices = true;
      this.currentPage = page === undefined ? this.currentPage : page;

      try {
        const queryParameters = {
          skip: (this.currentPage - 1) * this.pageSize,
          limit: this.pageSize,
          sort: this.sortedValue,
          expand: "user campaign campaign.recipients",
        };

        const requestBody = this.createRequestBody();
        const response = await listInvoices(queryParameters, requestBody);
        const count = response.data.result.count;
        this.count = count;
        this.userHasInvoices = count;
        this.invoices = response.data.result.records;
        this.checkingIfUserHasInvoices = false;
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.error("Failed to load campaigns:", error);
      } finally {
        this.checkingIfUserHasInvoices = false;
      }
    },

    /** handle pagination of records */
    async nextPage() {
      this.changePage(this.currentPage + 1);
    },

    async previousPage() {
      this.changePage(this.currentPage - 1);
    },

    async changePage(newPage) {
      this.invoicesAreLoading = true;
      this.currentPage = newPage;

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "user campaign campaign.recipients",
      };

      let requestBody = this.createRequestBody();

      try {
        const response = await listInvoices(queryParameters, requestBody);
        this.invoices = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        console.error("Failed to load campaigns:", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.invoicesAreLoading = false;
      }
    },

    /** Query filters */
    createRecipientFilter(currentUserEmail) {
      if (!this.isBoldXchangeAdmin) {
        return { "recipients.contact.email": { $ne: currentUserEmail } };
      }
      return {};
    },
    createDateFilter() {
      let filter = {};
      if (this.stamp) {
        filter["_.id"] = { $gte: { $oidTime: this.stamp } };
      }
      if (this.customDateRangeApplied) {
        filter["_.id"] = {
          ...(filter["_.id"] || {}),
          $gte: { $oidTime: this.startDateTimestamp },
          $lte: { $oidTime: this.endDateTimestamp },
        };
      }
      return filter;
    },
    createStatusFilter() {
      switch (this.selectedInvoiceStatus) {
        case 1:
          return {
            $or: [
              { "metadata.date_fulfilled": { $exists: true } },
              { "campaign.metadata.date_fulfilled": { $exists: true } },
            ],
          };

        case 0:
          return {
            $and: [
              { "metadata.date_fulfilled": { $exists: false } },
              { "campaign.metadata.date_fulfilled": { $exists: false } },
            ],
          };

        default:
          return {}; // Handle unexpected or no selected invoice status
      }
    },
    combineFilters(filters) {
      return filters.reduce(
        (combined, filter) => ({ ...combined, ...filter }),
        {}
      );
    },
    createRequestBody() {
      let currentUserEmail = this?.User?.email;
      const filters = [
        this.createRecipientFilter(currentUserEmail),
        this.createDateFilter(),
        this.createStatusFilter(),
        this.createSearchQuery(),
      ];

      return this.combineFilters(filters);
    },

    /** handle retrieving records by selected filter */
    async filterInvoices(page) {
      this.invoicesAreLoading = true;

      this.currentPage = page === undefined ? this.currentPage : page;

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "user campaign campaign.recipients",
      };

      const requestBody = this.createRequestBody(); // Uses the refined logic for filter creation

      try {
        const response = await listInvoices(queryParameters, requestBody);
        this.invoices = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        console.error("Failed to load campaigns:", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.invoicesAreLoading = false;
      }
    },

    /** handle retrieving records by search query */
    createSearchQuery() {
      if (this.searchQuery.value === "") return {};

      const searchValues = this.searchQuery.value.split(" ");
      let searchConditions = [];

      searchConditions = searchValues
        .map((value) => [
          { "user.firstName": { $regex: value, $options: "i" } },
          { "user.lastName": { $regex: value, $options: "i" } },
          { "user.email": { $regex: value, $options: "i" } },
        ])
        .flat();

      return { $or: searchConditions };
    },
    createRequestBodyWithSearchQuery(currentUserEmail) {
      // Start with recipient filter or other base filters
      let filters = this.createRecipientFilter(currentUserEmail);

      // Add search query filters if applicable
      const searchQueryFilters = this.createSearchQuery();
      if (Object.keys(searchQueryFilters).length > 0) {
        filters = { ...filters, ...searchQueryFilters };
      }

      return filters;
    },
    async getInvoicesBySearchQuery(page) {
      this.invoicesAreLoading = true;
      this.currentPage = page === undefined ? 1 : page;

      let currentUserEmail = this?.User?.email; // Adjust based on your store/user management
      const requestBody =
        this.createRequestBodyWithSearchQuery(currentUserEmail);

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "user campaign campaign.recipients",
      };

      try {
        const { data } = await listInvoices(queryParameters, requestBody);
        this.invoices = data.result.records;
        this.count = data.result.count;
      } catch (error) {
        console.error("Campaigns did not load", error);
        this.handleErrorFromAPI(error); // Ensure you have a method to handle API errors
      } finally {
        this.invoicesAreLoading = false;
      }
    },

    /** handle deleting selected invoices */
    async confirmDelete() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      try {
        await api.deleteInvoices(null, {
          filter: { _id: { $in: this.idsToDelete } },
        });
        this.handleSuccess("deleteInvoices");
      } catch (error) {
        console.log("invoice was NOT deleted", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your delete request. Please try again."
        );
      } finally {
        this.showDeleteModal = false;
      }
    },
    toggleDeleteModal(invoice) {
      this.selectedInvoices.push(invoice);
      this.invoiceIdsToDelete = this.selectedInvoices;
      this.showDeleteModal = !this.showDeleteModal;
    },
    handleBulkDelete(invoices) {
      this.invoiceIdsToDelete = invoices;
      this.showDeleteModal = !this.showDeleteModal;
    },
    bulkDropdownItems() {
      return [
        {
          label: "Delete",
          isDeleteAction: true, // This item is a delete action
          action: () => this.handleBulkDelete(this.selectedInvoices),
        },
      ];
    },

    /** handle invoices selection */
    selectAllInvoices(isChecked) {
      this.selectedInvoices = isChecked ? this.invoices.slice() : [];
    },
    isInvoiceSelected(invoiceId) {
      return this.selectedInvoices.some((r) => r.id === invoiceId);
    },
    toggleInvoiceSelection(invoice) {
      const index = this.selectedInvoices.findIndex((r) => r.id === invoice.id);
      if (index > -1) {
        this.selectedInvoices.splice(index, 1);
      } else {
        this.selectedInvoices.push(invoice);
      }
    },

    /** campaign list item dropdown items */
    sortDropdownItems() {
      return [
        {
          label: "Sort by: Newest",
          action: () => this.handleSort("-_id"),
        },
        {
          label: "Sort by: Oldest",
          action: () => this.handleSort("_id"),
        },
      ];
    },
    filterByStatusDropdownItems() {
      return [
        {
          label: "All",
          action: () => this.handleStatusSelection(-1),
        },
        {
          label: "Fulfilled",
          action: () => this.handleStatusSelection(1),
        },
        {
          label: "Unfulfilled",
          action: () => this.handleStatusSelection(0),
        },
      ];
    },

    /** modals */
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    closeModal() {
      this.showDeleteModal = false;
    },

    /** routing */
    goToInvoiceDetails(invoiceId) {
      this.$router.push({ name: "InvoiceDetails", params: { invoiceId } });
    },
    /** timestamp */
    setStamp() {
      let timestamp;
      const dateRightNow = new Date();
      const startOfWeek = new Date(dateRightNow);
      startOfWeek.setHours(0, 0, 0, 0);
      startOfWeek.setDate(
        dateRightNow.getDate() -
          dateRightNow.getDay() +
          (dateRightNow.getDay() === 0 ? -6 : 1)
      );

      switch (this.selectedDateRange) {
        case "Past 7 days":
          timestamp = dateRightNow.getTime() - 1000 * 60 * 60 * 24 * 7;
          break;
        case "Past 30 days":
          timestamp = dateRightNow.getTime() - 1000 * 60 * 60 * 24 * 30; // Last 30 days
          break;
        case "Past 90 days":
          timestamp = dateRightNow.getTime() - 1000 * 60 * 60 * 24 * 90; // Last 90 days
          break;
        case "This week":
          timestamp = startOfWeek.getTime(); // Past week (Monday to today)
          break;
        default:
          timestamp = dateRightNow.getTime(); // Default: Current timestamp
          break;
      }

      this.stamp = timestamp;
    },

    /** utility handlers */
    handleDateRangeSelection(date) {
      this.selectedInvoiceStatus = -1;
      this.selectedDateRange = date;
      this.customDateRangeApplied = false;
      this.clearSearchQuery();
      if (date === "All time") {
        this.stamp = null;
        this.filterInvoices();
      } else {
        this.setStamp();
        this.filterInvoices();
      }
    },
    handleCustomDateRangeSelection() {
      this.clearSearchQuery();
      this.stamp = null;
      this.selectedInvoiceStatus = -1;
      this.selectedDateRange = "";
      this.customDateRangeApplied = true;
      this.startDateTimestamp = new Date(this.startDate).getTime();
      this.endDateTimestamp = new Date(this.endDate).getTime();
      this.filterInvoices();
    },
    handleStatusSelection(status) {
      this.clearSearchQuery();
      this.stamp = null;
      this.selectedDateRange = "";
      this.selectedInvoiceStatus = status;
      this.customDateRangeApplied = false;
      this.filterInvoices();
    },
    handleSort(column) {
      this.currentPage = 1;

      this.sortedValue = this.sortedValue === column ? `-${column}` : column;

      if (this.searchQuery.value) {
        return this.handleSearchQuery();
      } else {
        return this.filterInvoices();
      }
    },
    handleSearchQuery() {
      this.searchQuery.active = true;
      this.selectedInvoiceStatus = -1;
      this.selectedCampaignType = "";
      this.selectedDateRange = "";
      this.customDateRangeApplied = false;
      this.getInvoicesBySearchQuery();
    },
    clearSearchQuery() {
      this.searchQuery.active = false;
      this.searchQuery.value = "";
    },
    clearCustomDates() {
      this.startDate = "";
      this.endDate = "";
      this.customDateRangeApplied = false;
      this.filterInvoices();
    },
    resetFilters() {
      this.selectedInvoiceStatus = -1;
      this.selectedDateRange = "";
      this.currentPage = 1;
      this.clearCustomDates();
      this.clearSearchQuery();
      this.filterInvoices();
    },
    reloadList() {
      const page = this.currentPage;
      if (this.searchQuery.value !== "") {
        this.getInvoicesBySearchQuery(page);
      } else {
        this.checkForInvoices(page);
      }
    },

    /** handle success and errors */
    handleSuccess(api) {
      if (api === "deleteInvoices") {
        this.showDeleteModal = false;
        this.selectedInvoices = [];

        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Selected recipients were successfully deleted.",
          duration: 4000,
          id: Date.now(),
        });
      }

      this.reloadList();
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.checkingIfUserHasInvoices = false;
      this.invoicesAreLoading = false;
      this.campaignIsSaving = false;
      this.reloadList();
      console.error(errorMessage);
    },

    /** mixpanel */
    // trackFilter() {
    //   this.mixpanel.track("Campaign List Filters Applied", {
    //     dateRange: this.selectedDateRange,
    //     search: this.searchQuery,
    //   });
    // },
  },
};
</script>
