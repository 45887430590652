<template>
  <template v-if="loadingAvailability">
    <LoadingGiftIcon></LoadingGiftIcon>
  </template>
  <!-- <template v-else> -->
    <DatePicker
      v-if="BookingInfo"
      :bookedTimes="BookingInfo?.unavailableTimes"
      :info="BookingInfo"
      :modelValue="date"
      @update:modelValue="updt"
      :meeting-minutes="meetingMinutes || 30"
      :test-mode="testMode"
    ></DatePicker>
  <!-- </template> -->
</template>

<script>
import "v-calendar/style.css";
import DatePicker from "./DatePicker.vue";
import LoadingGiftIcon from "../components/ui/LoadingGiftIcon.vue";

export default {
  components: {
    LoadingGiftIcon,
    DatePicker,
  },
  computed: {
    BookingInfo() {
      if (!this.bookingInfo) {
        this.getBookingInfo();
      }
      return this.bookingInfo;
    },
  },
  data() {
    return {
      bookingInfo: null,
      date: new Date(),
      loadingAvailability: true,
    };
  },
  props: {
    campaignId: String,
    provider: {
      type: String,
      default: "google",
    },
    meetingMinutes: {
      type: Number,
      default: 30,
    },
    testMode: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    updt(e) {
      this.$emit("update:modelValue", e);
    },
    async getBookingInfo() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const client = await this.getBoldClient();
      const info = await client.getCampaignBookingAvailability({
        provider: this.provider,
        campaignId: this.campaignId,
      });
      this.bookingInfo = info.data;
      this.loadingAvailability = false;
      console.log("Booking info:", info);
    },
  },
};
</script>

<style></style>
