<template>
  <TheBox class="tw-pl-0.5 tw-pr-3 tw-max-h-[500px] tw-overflow-y-auto">
    <BlockStack :gap="400">
      <!-- error -->

      <NotificationMessage :scope="$options.name"></NotificationMessage>
      <!-- caution -->
      <template v-if="recipientEmailExists">
        <div
          class="tw-border-l-4 tw-border-orange-400 tw-bg-orange-50 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-items-center tw-gap-4"
        >
          <b-icon-exclamation-circle
            class="tw-text-orange-700 tw-text-lg"
          ></b-icon-exclamation-circle>
          <p class="tw-text-sm tw-m-0 tw-text-start tw-text-orange-700">
            This email already exists in your recipient list. Check to avoid
            duplication.
          </p>
        </div>
      </template>

      <template v-if="group">
        <div class="tw-bg-blue-100 tw-px-2 tw-py-1 tw-text-lg">
          <p class="tw-m-0 tw-text-lg">
            This recipient will be added to the following group:
          </p>
          <p class="tw-m-0 tw-text-lg tw-font-bold tw-capitalize">
            {{ group?.metadata?.name }}
          </p>
        </div>
      </template>

      <div class="tw-flex gap-2 tw-flex-wrap tw-w-full">
        <!-- first name -->
        <BlockStack :gap="200" class="tw-grow">
          <label
            for="recipientFirstName"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
            >First name<span
              v-if="!firstNameIsValid"
              class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
              >required field</span
            ></label
          >
          <input
            v-model="newRecipient.firstName"
            type="text"
            name="recipientFirstName"
            id="recipientFirstName"
            :class="{ 'tw-ring-red-300': !firstNameIsValid }"
            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
            placeholder="Joe"
          />
        </BlockStack>
        <!-- last name -->
        <BlockStack :gap="200" class="tw-grow">
          <label
            for="recipientLastName"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
            >Last name<span
              v-if="!lastNameIsValid"
              class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
              >required field</span
            ></label
          >
          <input
            v-model="newRecipient.lastName"
            type="text"
            name="recipientLastName"
            id="recipientLastName"
            :class="{ 'tw-ring-red-300': !lastNameIsValid }"
            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
            placeholder="Kay"
          />
        </BlockStack>
      </div>
      <!-- email -->
      <BlockStack :gap="200">
        <label
          for="recipientEmail"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >Email address<span
            v-if="!emailIsValid"
            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
            >required field</span
          ></label
        >
        <input
          v-model="contact.email"
          type="text"
          name="recipientEmail"
          id="recipientEmail"
          :class="{ 'tw-ring-red-300': !emailIsValid }"
          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
          placeholder="joekay@example.com"
        />
      </BlockStack>
      <!-- lifecycle stage -->
      <BlockStack :gap="200">
        <label
          for="recipientTags"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >Tags</label
        >
        <MultiSelectAccordion
          buttonText="Select tags"
          v-model="contact.metadata.lifecycleStage"
          :options="availableTags"
          :placeholder="'Select tags'"
          size="large"
          :id="'recipient-tags-multiselect-dropdown'"
        />
        <!-- <select
          v-model="contact.metadata.lifecycleStage"
          id="recipientLifecycleStage"
          name="recipientLifecycleStage"
          class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
        >
          <option value="" class="" disabled>Select an option</option>
          <option
            v-for="option in tagOptions"
            :value="option.value"
            :key="option.index"
            class=""
          >
            {{ option.text }}
          </option>
        </select> -->
      </BlockStack>
      <!-- birthday -->
      <BlockStack :gap="200">
        <label
          for="recipientBirthday"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >Birthday</label
        >
        <div class="tw-flex tw-gap-2">
          <select
            id="birthdayMonth"
            name="birthdayMonth"
            v-model="contact.metadata.birthday.month"
            class="tw-block tw-bg-white tw-w-1/3 tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
          >
            <option value="" class="" disabled>Month</option>
            <option
              v-for="option in monthOptions"
              :value="option.value"
              :key="option.index"
            >
              {{ option.text }}
            </option>
          </select>
          <select
            id="birthdayDay"
            name="birthdayDay"
            v-model="contact.metadata.birthday.day"
            class="tw-block tw-bg-white tw-w-1/3 tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
          >
            <option value="" class="" disabled>Day</option>
            <option
              v-for="option in dayOptions"
              :value="option.value"
              :key="option.index"
            >
              {{ option.text }}
            </option>
          </select>
        </div>
      </BlockStack>
      <!-- notes -->
      <BlockStack :gap="200">
        <label
          for="recipientNotes"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >Notes</label
        >
        <RichTextEditor
          v-model="contact.metadata.notes"
          :isEditing="true"
        ></RichTextEditor>
      </BlockStack>
      <!-- phone -->
      <BlockStack :gap="200">
        <label
          for="recipientPhone"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >Phone number<span
            v-if="!isPhoneNumberValid"
            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
            >Please enter a valid phone number (6-12 digits, no special
            characters).</span
          ></label
        >
        <input
          v-model="contact.phone"
          type="text"
          name="recipientPhone"
          id="recipientPhone"
          :class="{ 'tw-ring-red-300': !isPhoneNumberValid }"
          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
          placeholder="1111111111"
        />
      </BlockStack>
      <!-- company -->
      <BlockStack :gap="200">
        <label
          for="recipientCompany"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >Company</label
        >
        <input
          v-model="contact.company"
          type="text"
          name="recipientCompany"
          id="recipientCompany"
          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
          placeholder="Joe Kay Sales"
        />
      </BlockStack>
      <!-- title -->
      <BlockStack :gap="200">
        <label
          for="recipientTitle"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >Job Title</label
        >
        <input
          v-model="contact.title"
          type="text"
          name="recipientTitle"
          id="recipientTitle"
          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
          placeholder="Sales executive"
        />
      </BlockStack>
    </BlockStack>
  </TheBox>
  <ModalFooter>
    <div class="tw-flex tw-gap-4 tw-items-center">
      <TheButton
        type="button"
        variant="primary"
        size="large"
        :disabled="!this.allItemsComplete"
        @click="saveNewRecipient()"
      >
        <template #text>Save recipient</template>
      </TheButton>
      <TheButton
        type="button"
        variant="tertiary"
        size="large"
        @click="closeModal()"
      >
        <template #text>Cancel</template>
      </TheButton>
    </div>
  </ModalFooter>
</template>

<script>
import BlockStack from "../layout/BlockStack.vue";
import TheBox from "../layout/TheBox.vue";
import ModalFooter from "../layout/ModalFooter.vue";
import TheButton from "../actions/TheButton.vue";
import NotificationMessage from "../feedback/NotificationMessage.vue";
import RichTextEditor from "../forms/RichTextEditor.vue";
import MultiSelectAccordion from "../elements/accordion/MultiSelectAccordion.vue";

import { mapGetters } from "vuex";
export default {
  name: "NewRecipientForm",
  emits: ["handleNewRecipient", "closeModal"],
  components: {
    MultiSelectAccordion,
    TheBox,
    RichTextEditor,
    BlockStack,
    ModalFooter,
    TheButton,
    NotificationMessage,
  },
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      api: {
        error: 0,
        success: 0,
        message: "",
      },
      updateMessage: {
        successful: {
          text: "Recipient successfully deleted!",
          class: "bg-success p-3",
          show: false,
        },
        failed: {
          text: "Something went wrong while attempting to create your recipient. Please try again.",
          class: "bg-danger p-3",
          show: false,
        },
      },
      newRecipient: {
        firstName: "",
        lastName: "",
      },
      contact: {
        company: "",
        email: "",
        title: "",
        phone: "",
        metadata: {
          lifecycleStage: [],
          birthday: {
            month: "",
            day: "",
          },
          notes: null,
        },
      },
      existingRecipientsTags: [],
      availableTags: [
        "a-list",
        "b-list",
        "c-list",
        "center of influence",
        "cold lead",
        "customer",
        "warm lead",
      ],
      months: [],
      daysInMonth: [],
      doesGiftingLeadEmailExist: false,
      recipientEmailExists: false,
      isNewClientModalVisible: false,
      isClientListModalVisible: false,
      debounceTimer: null,
      newMemberId: null,
      step: 1,
    };
  },
  created() {
    this.getRecipientsTags();
    this.daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);
    this.months = Array.from({ length: 12 }, (_, i) => {
      return new Date(0, i).toLocaleDateString("default", { month: "long" });
    });
  },
  watch: {
    "contact.email": {
      handler: "handleNameChange",
      deep: true,
    },
  },
  computed: {
    ...mapGetters("notifications", ["notification"]),
    allItemsComplete() {
      return (
        this.firstNameIsValid &&
        this.lastNameIsValid &&
        this.emailIsValid &&
        this.isPhoneNumberValid
      );
    },
    firstNameIsValid() {
      return this.newRecipient.firstName.trim().length > 0;
    },
    lastNameIsValid() {
      return this.newRecipient.lastName.trim().length > 0;
    },
    emailIsValid() {
      // Trim the email to remove leading and trailing white spaces
      const email = this.contact.email.trim();

      // Check if the email is empty and return false immediately if it is
      if (email === "") {
        return false;
      }

      // Use a regular expression to test if the email is in a valid format
      // This regex checks for the presence of characters before and after an "@"
      // and at least one character after a dot, which is a basic validation for email formats
      return /\S+@\S+\.\S+/.test(email);
    },
    isPhoneNumberValid() {
      const phoneNumber = this.contact.phone;

      if (phoneNumber.trim() !== "") {
        // Validate: should contain only numbers and be at most 12 characters long
        const numberRegex = /^\d+$/; // Regex to check if string contains only numbers

        return (
          phoneNumber.length >= 6 &&
          phoneNumber.length <= 12 &&
          numberRegex.test(phoneNumber)
        );
      }
      return true; // If phone number is empty, consider it as valid
    },
    lifecycleStageIsValid() {
      return this.contact.metadata.lifecycleStage.trim().length > 0;
    },
    contactInfoCompleted() {
      const hasTruthyValue = (obj) => {
        return Object.values(obj).some((value) => {
          if (typeof value === "object" && value !== null) {
            return hasTruthyValue(value);
          }
          return Boolean(value);
        });
      };
      return hasTruthyValue(this.contact);
    },
    tagOptions() {
      const options = this.tags.map((tag) => ({
        text: tag,
        value: tag,
      }));
      return options;
    },
    monthOptions() {
      const options = this.months.map((month) => ({
        text: month,
        value: month,
      }));
      return options;
    },
    dayOptions() {
      const options = this.daysInMonth.map((day) => ({
        text: day,
        value: day,
      }));
      return options;
    },
    userClient() {
      return this?.$store?.state?.auth?.user?.client;
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
  },
  methods: {
    async getRecipientsTags() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      try {
        const api = await this.getBoldClient();

        let filter = {};
        const queryParameters = {
          limit: 500,
          // sort: "-name",
          distinct: "contact.metadata.lifecycleStage",
          expand: "contact",
        };

        // const requestBody = { filter };

        const recipientsTagsRes = await api.listRecipients(
          queryParameters
          // requestBody
        );

        const tags = recipientsTagsRes?.data?.result?.records || [];

        this.existingRecipientsTags = tags;
        this.setAvailableTags();
      } catch (error) {
        console.error("Error fetching neighborhoods:", error);
      }
    },
    setAvailableTags() {
      // Define availableTags
      const availableTags = this.availableTags;

      // Combine lifecycleStage with availableTags, ensuring unique values
      const lifecycleStage = this.contact.metadata.lifecycleStage;
      const existingTags = this.existingRecipientsTags;

      // Normalize both arrays to lowercase for comparison
      const normalizedAvailableTags = availableTags.map((tag) =>
        tag.toLowerCase()
      );
      const normalizedLifecycleStage = lifecycleStage.map((tag) =>
        tag.toLowerCase()
      );
      const normalizedExistingTags = existingTags.map((tag) =>
        tag.toLowerCase()
      );

      // Combine and ensure uniqueness
      const combinedNormalizedTags = Array.from(
        new Set([
          ...normalizedLifecycleStage,
          ...normalizedAvailableTags,
          ...normalizedExistingTags,
        ])
      );

      // Sort the combined unique tags in alphabetical order
      const sortedCombinedTags = combinedNormalizedTags.sort();

      this.availableTags = sortedCombinedTags;
    },
    /** handle name change */
    handleNameChange() {
      // Clear any previously set timer to avoid unnecessary API calls
      clearTimeout(this.debounceTimer);

      // Set a new timer
      this.debounceTimer = setTimeout(() => {
        // Ensure both email is truthy before calling the API
        if (this.emailIsValid) {
          this.checkIfRecipientEmailExists();
        }
      }, 1000); // Adjust debounce time as needed
    },
    async checkIfRecipientEmailExists() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      const email = this.contact.email.trim();
      let filterConditions = [
        {
          "contact.email": {
            $eq: email,
          },
        },
      ];

      // Only if the user is not an admin, add the giftingLeads condition
      if (!this.isBoldXchangeAdmin) {
        filterConditions.push({
          "metadata.giftingLeads": { $in: [this.userId] },
        });
      }

      // If there are multiple conditions (meaning the user is not an admin), use $and
      let requestBody = {};
      if (filterConditions.length > 1) {
        requestBody.$and = filterConditions;
      } else {
        // If only the email condition is present (admin user), don't use $and
        requestBody = filterConditions[0];
      }

      let selection = "firstName lastName contact";

      const queryParameters = {
        limit: 300,
        sort: "firstName",
        expand: "contact",
      };

      try {
        const response = await api.listRecipients(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        const count = response.data.result.count;
        this.recipientEmailExists = count > 0; // true if count is greater than 0, otherwise false
      } catch (error) {
        console.log("recipients did not load", error);
      }
    },
    /** handle create */
    async saveNewRecipient() {
      const api = await this.getBoldClient();
      const giftingLeads = [this.userId];

      let filteredContact = this.filterTruthyProperties(this.contact);

      // Construct the recipient object, conditionally adding the client field
      const recipient = {
        ...(this.userClient && { client: this.userClient }), // Conditionally add client
        firstName: this.newRecipient.firstName,
        lastName: this.newRecipient.lastName,
        metadata: {
          giftingLeads,
        },
        contact: filteredContact,
      };

      let recipientsArr = [recipient];

      try {
        const recipientResponse = await api.createRecipients(
          null,
          recipientsArr
        );
        // this.handleSuccess(recipientResponse.data.result.records[0]);
        this.handleSuccess(recipientResponse.data.result.records[0]);
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("recipient was NOT created", error);
      }
    },
    filterTruthyProperties(obj) {
      if (Array.isArray(obj)) {
        return obj.filter((item) => item); // Filter out falsy values in the array
      }
      return Object.keys(obj).reduce((acc, key) => {
        if (obj[key] && typeof obj[key] === "object") {
          const nested = this.filterTruthyProperties(obj[key]);
          if (Object.keys(nested).length > 0 || Array.isArray(obj[key])) {
            acc[key] = nested;
          }
        } else if (obj[key]) {
          acc[key] = obj[key];
        }
        return acc;
      }, {});
    },

    /** emits */
    closeModal() {
      this.$emit("closeModal");
    },

    /** handle success and errors */
    handleSuccess(recipient) {
      this.$emit("handleNewRecipient", recipient);
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage =
          "An error occurred setting up the request that prevented it from being sent. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("notifications/notifyError", errorMessage);
      console.error(errorMessage);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
