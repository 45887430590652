<template>
  <div
    class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-4"
  >
    <div
      v-for="stat in stats"
      :key="stat.name"
      class="tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-shadow tw-w-full"
    >
      <!-- header -->
      <div class="tw-p-4 sm:tw-p-6 tw-w-full">
        <div>
          <h3
            class="tw-m-0 tw-text-lg tw-font-medium sm:tw-text-xl sm:tw-tracking-tight tw-text-wrap"
          >
            {{ stat.name }}
          </h3>

          <p
            class="tw-text-xs tw-bg-slate-50 tw-inline-block tw-font-bold tw-px-2 tw-py-1"
          >
            <template v-if="stat.type === 'clicked'">
              {{ totalCampaignsClickedCount }} clicks
            </template>
            <template v-if="stat.type === 'redeemed'">
              {{ totalCampaignsRedeemedCount }} redemptions
            </template>
          </p>

          <!-- </div> -->
        </div>
      </div>
      <!-- body -->
      <div class="tw-flex-1 tw-w-full">
        <!-- box -->
        <div
          class="tw-px-4 sm:tw-px-6 tw-pt-0 tw-pb-4 sm:tw-pb-6 tw-h-full tw-flex tw-items-end"
        >
          <div class="tw-flex tw-items-start">
            <div class="tw-text-4xl tw-font-bold tw-text-gray-900">
              <template v-if="stat.type === 'campaigns'">
                {{ totalCampaignsSentCount }}
              </template>
              <template v-if="stat.type === 'clicked'">
                {{ averageClickRate }}%
              </template>
              <template v-if="stat.type === 'redeemed'">
                {{ averageRedemptionRate }}%
              </template>
              <template v-if="stat.type === 'recipients'">
                ${{ totalSpendCount }}
              </template>
            </div>
            <TheTooltip :toolTip="true" :tooltipContent="stat.description">
              <template #icon>
                <div
                  class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                >
                  <b-icon-exclamation
                    class="tw-text-black tw-z-0"
                  ></b-icon-exclamation>
                </div>
              </template>
            </TheTooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
export default {
  name: "DashboardStats",
  components: { TheTooltip },
  props: {
    totalCampaignsSentCount: {
      type: Number,
      default: 0,
    },
    totalSpendCount: {
      type: Number,
      default: 0,
    },
    totalCampaignsClickedCount: {
      type: Number,
      default: 0,
    },
    totalCampaignsRedeemedCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      stats: [
        {
          type: "campaigns",
          name: "Campaigns sent",
          description: "The total number of campaigns sent.",
        },
        {
          type: "clicked",
          name: "Click rate",
          description: "The percentage of campaigns clicked.",
        },
        {
          type: "redeemed",
          name: "Redemption rate",
          description: "The percentage of campaigns redeemed.",
        },
        {
          type: "recipients",
          name: "Total spend",
          description: "The total sum of redeemed gift cards.",
        },
      ],
      tooltipText: null,
      activeStat: null,
    };
  },
  watch: {
    userHasCampaigns() {
      if (this.userHasCampaigns) {
        // this.loadData();
      }
    },
  },
  created() {},
  computed: {
    averageClickRate() {
      if (this.totalCampaignsSentCount === 0) {
        return 0; // or any other appropriate value to indicate no campaigns sent
      }
      return (
        (this.totalCampaignsClickedCount / this.totalCampaignsSentCount) *
        100
      ).toFixed(1);
    },
    averageRedemptionRate() {
      if (this.totalCampaignsSentCount === 0) {
        return 0; // or any other appropriate value to indicate no campaigns sent
      }
      return (
        (this.totalCampaignsRedeemedCount / this.totalCampaignsSentCount) *
        100
      ).toFixed(1);
    },
    isTooltipVisible() {
      return (stat) => this.activeStat === stat.type;
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-square-container {
  flex: 1 1 auto;
  max-width: 150px;
  max-height: 150px;
  width: min(150px, 150px);
  height: min(150px, 150px);
  padding: 5px;
}

.flex-square-container h1 {
  font-size: 2.5rem;
}
</style>
