<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <!-- Page Header -->
      <div class="modal-header border-bottom px-0 pt-0 pb-2">
        <h1 class="modal-title">Create new gift card platform</h1>
      </div>

      <div class="modal-body p-0 mt-5">
        <div class="row">
          <div class="col text-start">
            <!-- Name -->
            <div>
              <label for="companyName" class="form-label"
                >Name
                <span
                  v-if="!getOnlineStorePlatformName"
                  class="badge bg-soft-info rounded-pill ms-2"
                  ><span class="text-danger">required</span>
                </span></label
              >

              <div
                v-if="doesOnlineStorePlatformExist"
                class="badge bg-warning d-flex align-items-center mb-1"
              >
                <b-icon-exclamation-circle
                  class="text-dark me-1"
                ></b-icon-exclamation-circle>
                <span class="text-dark"
                  >An gift card platform with a similar name already exists
                  in our database.</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                :class="categoryClass"
                name="categoryName"
                id="categoryName"
                placeholder="Shopify, Square, etc."
                aria-label="Shopify, Square, etc."
                v-model="store.name"
                required
                @input="debounceCheckIfOnlineStorePlatformExists()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-start px-0">
        <!-- submit button -->
        <div class="d-flex flex-column align-items-start w-100 my-2">
          <p class="text-danger" v-if="!checkIfFormIsComplete">
            Form is incomplete. Please fix invalid or missing information.
          </p>
          <div class="d-flex gap-2" v-if="!storeIsSaving && !isSuccessful">
            <button
              :disabled="!checkIfFormIsComplete"
              type="button"
              class="btn btn-primary"
              @click.prevent="saveCategory()"
            >
              Save changes
            </button>
            <button
              type="button"
              class="btn btn-soft-secondary"
              @click.prevent="$emit('cancel')"
            >
              Cancel
            </button>
          </div>
          <button
            class="btn btn-primary"
            type="button"
            disabled
            v-if="storeIsSaving"
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Saving new gift card platform...
          </button>
          <button
            class="btn btn-primary"
            type="button"
            disabled
            v-if="isSuccessful"
          >
            <span class="text-success"
              ><b-icon-check-circle-fill></b-icon-check-circle-fill>
            </span>
            Success!
          </button>
        </div>
      </div>
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "CreateVendorOnlineStorePlatformModal",
  components: {
    FixedModalSmall,
  },
  data() {
    return {
      storeIsSaving: false,
      isSuccessful: false,
      doesOnlineStorePlatformExist: false,
      store: {
        name: "",
      },
      debounceTimer: null,
    };
  },
  computed: {
    checkIfFormIsComplete() {
      return this.store.name === "" ? false : true;
    },
    getOnlineStorePlatformName() {
      return this.store.name === "" ? false : this.store.name;
    },
    categoryClass() {
      return {
        "is-invalid": !this.getOnlineStorePlatformName,
        "is-valid": this.getOnlineStorePlatformName,
      };
    },
  },
  watch: {
    "store.name"() {
      if (this.store.name === "") {
        this.doesOnlineStorePlatformExist = false;
      }
      this.doesOnlineStorePlatformExist = false;
    },
  },
  methods: {
    debounceCheckIfOnlineStorePlatformExists() {
      // Clear any previously scheduled timeouts
      clearTimeout(this.debounceTimer);

      // Get the input value
      const inputValue = this.store.name.trim(); // Trim to remove leading/trailing spaces

      // Check if the input value is not empty
      if (inputValue !== "") {
        // Schedule a new timeout to call checkIfClientExists after 500ms
        this.debounceTimer = setTimeout(() => {
          this.checkIfOnlineStorePlatformExists(); // Pass the trimmed input value to the method
        }, 450);
      }
    },
    async checkIfOnlineStorePlatformExists() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      try {
        const api = await this.getBoldClient();
        this.onlineStorePlatformsAreLoading = true;

        const pageSize = 300;
        const onlineStorePlatformsResponse = await api.listVendors(
          {
            limit: pageSize,
            sort: "-name",
            distinct: "onlineStorePlatforms",
            expand: "metadata"
          },
          {
            filter: {
              $or: [{ "metadata.onlineStorePlatforms": { $in: this.getSearchQuery } }],
            },
          }
        );

        const onlineStorePlatforms =
          onlineStorePlatformsResponse?.data?.result?.records || [];

        // Update the categories and counts
        this.onlineStorePlatforms = onlineStorePlatforms;
        const count = onlineStorePlatformsResponse.data.result.count;
        if (count === 0) {
          this.doesOnlineStorePlatformExist = false;
        } else {
          this.doesOnlineStorePlatformExist = true;
        }
        this.count = count;

        // Reset loading flags
        this.onlineStorePlatformsAreLoading = false;

        // Log ogift card if needed
        // console.log(onlineStorePlatforms);
      } catch (error) {
        console.error("Error fetching gift card platforms:", error);
        this.count = 0;
        this.onlineStorePlatformsAreLoading = false;
      }
    },
    saveCategory() {
      const store = this.store.name;
      // console.log("category to add", store);
      this.$emit("setNewVendorOnlineStorePlatform", store);
    },
  },
};
</script>

<style scoped></style>
