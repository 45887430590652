<template>
  <div>
    <CreateClientModal
      v-if="isNewClientModalVisible"
      @cancel="closeCreateClientModal"
      @setNewClient="setNewClient"
    ></CreateClientModal>
    <ClientListModal
      v-if="isClientListModalVisible"
      @cancel="closeClientListModal"
      @setNewClient="setNewClient"
    ></ClientListModal>

    <SuccessfulActionModal
      v-if="updateMessage.successful.show"
      :memberAdded="updateMessage.successful.show"
    >
      <template #button-1>
        <button class="btn btn-primary" @click.stop="viewNewMember()">
          View new member
        </button>
      </template>
      <template #button-2>
        <button class="btn btn-secondary" @click.stop="closeSuccessModal()">
          Close
        </button>
      </template>
    </SuccessfulActionModal>

    <PageHeader :showActions="true" title="Creating new member">
      <template #page-header-actions>
        <TheButton
          type="button"
          :hasLeadingIcon="true"
          variant="primary"
          size="large"
          :isRouterLink="true"
          to="/add/member"
        >
          <template #text>Create member</template>
          <template #leading-icon><b-icon-plus-lg></b-icon-plus-lg></template>
        </TheButton>
      </template>
    </PageHeader>

    <ThePage :fullWith="true">
      <!-- update messages -->
      <BlockStack :gap="400" :fullWidth="true">
        <template
          v-if="updateMessage.failed.show || updateMessage.successful.show"
        >
          <div
            class="tw-border-solid tw-border tw-border-y-0 tw-border-r-0 tw-border-l-4 tw-p-4 tw-max-w-4xl tw-mx-auto"
            :class="{
              'tw-border-yellow-400 tw-bg-yellow-50': updateMessage.failed.show,
              'tw-border-green-400 tw-bg-green-50':
                updateMessage.successful.show,
            }"
          >
            <div class="tw-flex tw-items-center">
              <div class="tw-flex-shrink-0">
                <template v-if="updateMessage.failed.show">
                  <b-icon-exclamation-circle
                    class="tw-h-5 tw-w-5 tw-text-orange-400"
                    aria-hidden="true"
                  ></b-icon-exclamation-circle>
                </template>
                <template v-if="updateMessage.successful.show">
                  <b-icon-check-circle
                    class="tw-h-5 tw-w-5 tw-text-green-400"
                    aria-hidden="true"
                  ></b-icon-check-circle>
                </template>
              </div>
              <div class="tw-ml-3">
                <template v-if="updateMessage.failed.show">
                  <p class="tw-text-lg tw-text-yellow-700 tw-m-0">
                    {{ updateMessage.failed.text }}
                  </p>
                </template>
                <template v-if="updateMessage.successful.show">
                  <p class="tw-text-lg tw-text-gray-700 tw-m-0">
                    {{ updateMessage.failed.text }}
                  </p>
                </template>
              </div>
            </div>
          </div>
        </template>
        <div class="tw-w-full tw-flex tw-justify-center">
          <ul class="step step-md step-icon-xs step-centered col-8">
            <!-- select/create organization -->
            <li class="step-item">
              <div class="step-content-wrapper">
                <template v-if="!selectedClient">
                  <span class="step-icon step-icon-soft-secondary">1</span>
                </template>
                <template v-if="selectedClient">
                  <span class="step-icon step-icon-success"
                    ><b-icon-check-lg></b-icon-check-lg
                  ></span>
                </template>

                <div class="step-content">
                  <span
                    class="step-title"
                    :class="{
                      'text-primary': step === 1,
                    }"
                  >
                    Organization
                  </span>
                </div>
              </div>
            </li>
            <!-- complete new member profile -->
            <li class="step-item">
              <div class="step-content-wrapper">
                <template v-if="!isMemberInformationComplete">
                  <span class="step-icon step-icon-soft-secondary">2</span>
                </template>
                <template v-if="isMemberInformationComplete">
                  <span class="step-icon step-icon-success"
                    ><b-icon-check-lg></b-icon-check-lg
                  ></span>
                </template>
                <div class="step-content">
                  <span
                    class="step-title"
                    :class="{
                      'text-primary': step === 2,
                    }"
                    >Member Profile
                  </span>
                </div>
              </div>
            </li>
            <!-- save new member -->
            <li class="step-item">
              <div class="step-content-wrapper">
                <template
                  v-if="!selectedClient || !isMemberInformationComplete"
                >
                  <span class="step-icon step-icon-soft-secondary">3</span>
                </template>
                <template v-else>
                  <span class="step-icon step-icon-soft-primary">3</span>
                </template>
                <div class="step-content">
                  <span
                    class="step-title"
                    :class="{
                      'text-primary': step === 3,
                    }"
                  >
                    Review and Save
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </BlockStack>
    </ThePage>

    <div class="content container-fluid">
      <!-- Content -->
      <div class="row">
        <div class="col-8 mb-3 mb-lg-0 mx-auto">
          <!-- Step 1: Select/Create Company -->
          <template v-if="step === 1">
            <div class="card mb-3 mb-lg-5 text-start">
              <div class="card-header bg-dark">
                <h4 class="card-header-title text-white">
                  Select an organization from the list or create a new one
                </h4>
              </div>
              <div class="card-body">
                <!-- client -->
                <div class="col-lg-6 text-start mx-auto py-4">
                  <div class="input-group">
                    <div
                      class="form-select"
                      @click.prevent="showClientListModal()"
                    >
                      <span>Select organization</span>
                    </div>
                    <button
                      type="button"
                      class="btn btn-warning fw-bold"
                      @click.prevent="showCreateClientModal()"
                    >
                      create
                    </button>
                  </div>
                </div>
                <template v-if="selectedClient">
                  <div class="row">
                    <div class="col-6 text-start">
                      <label for="companyName" class="form-label fw-bold"
                        >Organization
                      </label>
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        name="companyName"
                        id="companyName"
                        v-model="selectedClient.name"
                      />
                    </div>
                    <!-- Company Domain -->
                    <div class="col-6 text-start">
                      <label class="form-label fw-bold" for="companyDomain"
                        >Domain</label
                      >
                      <input
                        type="text"
                        disabled
                        class="form-control form-control-lg"
                        name="companyDomain"
                        id="companyDomain"
                        v-model="selectedClient.domains[0]"
                      />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <!-- Step 2: Complete new member profile -->
          <template v-if="selectedClient && step === 2">
            <div class="card mb-3 mb-lg-5 text-start">
              <div class="card-header bg-dark">
                <h4 class="card-header-title text-white">
                  Complete new member profile
                </h4>
              </div>
              <div class="card-body">
                <div class="row gy-5">
                  <!-- first name -->
                  <div class="col-12 col-lg-4">
                    <label for="firstName" class="form-label fw-bold"
                      >First Name</label
                    >
                    <span
                      v-if="!firstNameIsValid"
                      class="badge bg-soft-info ms-2"
                      ><span class="text-danger">required</span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      :class="firstNameClass"
                      name="firstName"
                      id="firstName"
                      placeholder="First name of new member"
                      aria-label="First name of new member"
                      v-model="member.firstName"
                      required
                    />
                  </div>
                  <!-- last name -->
                  <div class="col-12 col-lg-4">
                    <label for="lastName" class="form-label fw-bold"
                      >Last Name</label
                    >
                    <span
                      v-if="!lastNameIsValid"
                      class="badge bg-soft-info ms-2"
                      ><span class="text-danger">required</span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      :class="lastNameClass"
                      name="lastName"
                      id="lastName"
                      placeholder="Last name of new member"
                      aria-label="Last name of new member"
                      v-model="member.lastName"
                      required
                    />
                  </div>
                  <!-- Email -->
                  <div class="col-12 col-lg-4">
                    <label class="form-label fw-bold" for="giftingLeadEmail"
                      >Email</label
                    >
                    <span v-if="!emailIsValid" class="badge bg-soft-info ms-2"
                      ><span class="text-danger">required</span>
                    </span>
                    <div
                      v-if="doesGiftingLeadEmailExist"
                      class="badge bg-soft-danger d-flex align-items-center mb-1"
                    >
                      <span class="text-dark text-wrap text-start"
                        >The email you selected already already exists in the
                        organization selected. Please use a different
                        email.</span
                      >
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      :class="emailClass"
                      name="giftingLeadEmail"
                      id="giftingLeadEmail"
                      placeholder="e.g. hello@boldxchange.com"
                      aria-label="e.g. hello@boldxchange.com"
                      v-model="member.email"
                      @input="debounceCheckIfClientLeadEmailExists()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- Step 3: Save new member -->
          <template
            v-if="selectedClient && isMemberInformationComplete && step === 3"
          >
            <div class="card mb-3 mb-lg-5 text-start">
              <div class="card-header bg-dark">
                <h4 class="card-header-title text-white">
                  Review new member details
                </h4>
              </div>
              <div class="card-body">
                <div class="row text-start gy-4">
                  <div class="col-12 col-lg-4">
                    <label for="firstName" class="form-label fw-bold"
                      >First Name</label
                    >
                    <span
                      v-if="!firstNameIsValid"
                      class="badge bg-soft-info ms-2"
                      ><span class="text-danger">required</span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      name="firstName"
                      id="firstName"
                      placeholder="First name of new member"
                      aria-label="First name of new member"
                      v-model="member.firstName"
                      disabled
                    />
                  </div>
                  <div class="col-12 col-lg-4">
                    <label for="lastName" class="form-label fw-bold"
                      >Last Name</label
                    >
                    <span
                      v-if="!lastNameIsValid"
                      class="badge bg-soft-info ms-2"
                      ><span class="text-danger">required</span>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      name="lastName"
                      id="lastName"
                      placeholder="Last name of new member"
                      aria-label="Last name of new member"
                      v-model="member.lastName"
                      disabled
                    />
                  </div>
                  <div class="col-12 col-lg-4">
                    <label class="form-label fw-bold" for="giftingLeadEmail"
                      >Email</label
                    >
                    <input
                      type="email"
                      class="form-control"
                      name="giftingLeadEmail"
                      id="giftingLeadEmail"
                      placeholder="Primary email of new member"
                      aria-label="Primary email of new member"
                      v-model="member.email"
                      disabled
                    />
                  </div>
                  <div class="col-12 col-lg-6">
                    <label for="companyName" class="form-label fw-bold"
                      >Organization
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="companyName"
                      id="companyName"
                      v-model="selectedClient.name"
                      disabled
                    />
                  </div>
                  <div class="col-12 col-lg-6">
                    <label class="form-label fw-bold" for="companyDomain"
                      >Domain</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      name="companyDomain"
                      id="companyDomain"
                      v-model="selectedClient.domains[0]"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- Navigation -->
        <div class="col-8 mb-3 mb-lg-0 mx-auto">
          <div
            class="d-flex justify-content-end border-top-0"
            :class="{ 'justify-content-between': step > 1 }"
          >
            <template v-if="selectedClient">
              <template v-if="step > 1">
                <button
                  class="btn btn-ghost-secondary"
                  @click="moveToPreviousStep"
                >
                  <b-icon-chevron-left class="me-2"></b-icon-chevron-left
                  >Previous Step
                </button>
              </template>
              <template v-if="step === 1 && selectedClient">
                <button class="btn btn-primary" @click="moveToNextStep">
                  Next<b-icon-chevron-right class="ms-2"></b-icon-chevron-right>
                </button>
              </template>
              <template v-if="step === 2">
                <button
                  :disabled="!isMemberInformationComplete"
                  class="btn btn-primary"
                  @click="moveToNextStep"
                >
                  Next<b-icon-chevron-right class="ms-2"></b-icon-chevron-right>
                </button>
              </template>
              <template v-if="step === 3">
                <div class="d-flex gap-2">
                  <button class="btn btn-light" @click="cancelNewMember()">
                    Cancel
                  </button>
                  <button
                    class="btn btn-primary"
                    @click="createNewMember()"
                    :disabled="!allItemsComplete"
                  >
                    Save new member
                  </button>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import CreateClientModal from "../../components/ui/modals/create/CreateClientModal.vue";
import SuccessfulActionModal from "../../components/ui/modals/success/SuccessfulActionModal.vue";
import ClientListModal from "../../components/ui/modals/lists/ClientListModal.vue";

export default {
  name: "AddMember",
  components: {
    ThePage,
    PageHeader,
    BlockStack,
    CreateClientModal,
    SuccessfulActionModal,
    ClientListModal,
  },
  data() {
    return {
      updateMessage: {
        successful: {
          text: "Member successfully added!",
          show: false,
        },
        failed: {
          text: "Something went wrong while attempting to add your new member. Please try again.",
          show: false,
        },
      },
      member: {
        firstName: "",
        lastName: "",
        email: "",
        role: "giftingLead",
      },
      doesGiftingLeadEmailExist: false,
      isNewClientModalVisible: false,
      isClientListModalVisible: false,
      selectedClient: "",
      step: 1,
      debounceTimer: null,
      newMemberId: null,
    };
  },
  watch: {},
  computed: {
    allItemsComplete() {
      return (
        this.firstNameIsValid &&
        this.lastNameIsValid &&
        this.emailIsValid &&
        this.selectedClient
      );
    },
    isMemberInformationComplete() {
      return this.firstNameIsValid && this.lastNameIsValid && this.emailIsValid;
    },

    firstNameIsValid() {
      return this.member.firstName.trim().length > 0;
    },
    lastNameIsValid() {
      return this.member.lastName.trim().length > 0;
    },
    emailIsValid() {
      // You might want to include a more robust email validation logic here
      const email = this.member.email.trim();
      return email.length > 0 && /\S+@\S+\.\S+/.test(email);
    },
    firstNameClass() {
      return {
        "is-invalid": !this.member.firstName,
        "is-valid": this.member.firstName,
      };
    },
    lastNameClass() {
      return {
        "is-invalid": !this.member.lastName,
        "is-valid": this.member.lastName,
      };
    },
    emailClass() {
      return {
        "is-invalid": !this.emailIsValid || this.doesGiftingLeadEmailExist,
        "is-valid": this.emailIsValid && !this.doesGiftingLeadEmailExist,
      };
    },
  },
  methods: {
    async checkIfClientLeadEmailExists() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      // console.log("gifting lead email", this.member.email);
      this.originalEmail = this.member.email;
      this.doesGiftingLeadEmailExist = false;

      const pageSize = 20;
      const pageNo = 1;
      api
        .listUsers(
          {
            limit: pageSize,
            skip: pageSize * (pageNo - 1),
            sort: "firstName",
            // expand: "client",
          },
          {
            filter: {
              email: { $eq: this.member.email },
            },
          }
        )
        .then((response) => {
          const userList = response.data.result.records;
          // console.log("gifting lead email query response", userList);
          if (userList.length === 0) {
            // console.log("gifting lead email query response", userList);
            this.doesGiftingLeadEmailExist = false;
          } else {
            // console.log("gifting lead email query response", userList);
            this.doesGiftingLeadEmailExist = true;
            this.member.email = "";
          }
        })
        .catch((error) => {
          console.log("client search query failed", error);
        });
    },
    async createNewMember() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      const userToCreate = {
        client: this.selectedClient._id,
        firstName: this.member.firstName,
        lastName: this.member.lastName,
        email: this.member.email,
        role: this.member.role,
      };

      // console.log("userToCreate", userToCreate);

      try {
        const onboardUserResponse = await api.onboardUser(null, userToCreate);
        this.newMemberId = onboardUserResponse.data._doc._id;
        this.handleSuccess();
      } catch (error) {
        console.log("user was NOT created", error);
      }
    },
    debounceCheckIfClientLeadEmailExists() {
      // Clear any previously scheduled timeouts
      clearTimeout(this.debounceTimer);

      // Get the input value
      // this.validateEmail();
      // console.log("is email valid?: ", this.emailIsValid);

      // Check if the input value is not empty
      if (this.emailIsValid) {
        // Schedule a new timeout to call checkIfClientExists after 500ms
        this.debounceTimer = setTimeout(() => {
          this.checkIfClientLeadEmailExists(); // Pass the trimmed input value to the method
        }, 300);
      }
    },

    /** organizations */
    setNewClient(newClient) {
      this.selectedClient = newClient;
      this.isNewClientModalVisible = false;
      this.isClientListModalVisible = false;
      if (this.emailIsValid) {
        this.checkIfClientLeadEmailExists();
      }
    },
    showClientListModal() {
      this.isClientListModalVisible = true;
    },
    closeClientListModal() {
      this.isClientListModalVisible = false;
    },
    showCreateClientModal() {
      this.isNewClientLeadModalVisible = false;
      this.isNewClientModalVisible = true;
    },
    closeCreateClientModal() {
      this.isNewClientModalVisible = false;
    },

    /** navigation */
    moveToNextStep() {
      this.step += 1;
    },
    moveToPreviousStep() {
      this.step -= 1;
    },
    returnToAddOrderPage() {
      this.$router.push({ path: "/add-order" });
      window.location.reload();
    },
    cancelSelect() {
      this.isClientLeadListVisible = false;
    },
    viewNewMember() {
      this.updateMessage.successful.show = false;
      this.$router.push(`/member/${this.newMemberId}`);
    },
    closeSuccessModal() {
      this.updateMessage.successful.show = false;
      this.$router.push(`/members/`);
    },
    cancelNewMember() {
      window.location.reload();
    },

    /** error handling */
    handleSuccess() {
      // console.log("new member added! ", data);
      this.updateMessage.successful.show = true;
    },
    handleError(error) {
      console.error("Error adding new member: ", error);
      this.updateMessage.failed.show = true;
      setTimeout(() => {
        this.updateMessage.failed.show = false;
      }, 5000);
      setTimeout(() => {
        this.updateFailed = false;
      }, 5000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
