<template>
  <template v-if="currentTab === 1 && group">
    <tr>
      <td class="tw-px-3 tw-py-4">
        <div class="tw-flex tw-items-center gap-2">
          <TheLink
            variant="primary"
            :url="{
              name: 'RecipientGroupDetails',
              params: { groupId: group._id },
            }"
            :isRouterLink="true"
          >
            <template #text>
              {{ group?.metadata?.name }}
            </template>
          </TheLink>
        </div>
      </td>
      <td
        class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-800"
      >
        {{ "Group" }}
      </td>
      <td
        class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-800"
      >
        <template v-if="!recipientsCount">
          {{ "None" }}
        </template>
        <template v-else>
          <!-- {{ group?.members?.length }} -->
          {{ recipientsCount }}

          <b-icon-people></b-icon-people>
        </template>
      </td>
      <!-- <td
                    class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-800"
                  >
                    {{ "1" }}
                  </td> -->
      <td
        class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-800"
      >
        {{ getTimeStamp(group._id) }}
      </td>
      <td
        class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-4 tw-capitalize tw-text-sm tw-text-gray-500"
      >
        <template v-if="group?.metadata?.tags.length === 1">
          {{ group?.metadata?.tags[0] }}
        </template>
        <template v-else-if="group?.metadata?.tags.length > 1">
          <div class="tw-flex tw-items-center tw-gap-2">
            <span
              class="tw-rounded-md tw-bg-gray-100 tw-px-1.5 tw-py-1 tw-cursor-pointer"
            >
              <TheTooltip :toolTip="true" :tooltipContent="tags(group)">
                <template #icon>
                  <div
                    class="tw-flex tw-items-center tw-gap-2 tw-text-gray-800"
                  >
                    <span>Multiple </span>
                    <b-icon-eye class="tw-z-0"></b-icon-eye>
                  </div>
                </template>
              </TheTooltip>
            </span>
          </div>
        </template>
        <template v-else> --- </template>
      </td>
      <td
        class="tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-flex tw-items-center tw-justify-end tw-gap-2"
      >
        <div class="tw-flex tw-justify-end">
          <IconDropdown :items="getDropdownItems(group)" :id="group._id">
            <template #icon>
              <b-icon-three-dots-vertical />
            </template>
          </IconDropdown>
        </div>
      </td>
    </tr>
  </template>
  <template v-if="currentTab === 2 && segment">
    <tr>
      <td class="tw-px-3 tw-py-4">
        <div class="tw-flex tw-items-center gap-2">
          <TheLink
            variant="primary"
            :url="{
              name: 'RecipientSegmentDetails',
              params: { segmentId: segment._id },
            }"
            :isRouterLink="true"
          >
            <template #text>
              {{ segment?.metadata?.name }}
            </template>
          </TheLink>
        </div>
      </td>
      <td
        class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-800"
      >
        {{ "Segment" }}
      </td>
      <td
        class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-800"
      >
        <template v-if="recipientsCount === 0">
          {{ "None" }}
        </template>
        <template v-else>
          {{ recipientsCount }}
          <b-icon-people></b-icon-people>
        </template>
      </td>
      <td
        class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-800"
      >
        {{ getTimeStamp(segment._id) }}
      </td>

      <td
        class="tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500 tw-flex tw-items-center tw-justify-end tw-gap-2"
      >
        <div class="tw-flex tw-justify-end">
          <IconDropdown :items="getDropdownItems(segment)" :id="segment._id">
            <template #icon>
              <b-icon-three-dots-vertical />
            </template>
          </IconDropdown>
        </div>
      </td>
    </tr>
  </template>
</template>

<script>
import TheLink from "../../../components-v2/ui/actions/TheLink.vue";
import IconDropdown from "../../../components-v2/ui/elements/dropdown/IconDropdown.vue";
import TheTooltip from "../../../components-v2/ui/forms/TheTooltip.vue";
import moment from "moment";

export default {
  name: "RecipientsGroupsAndSegmentsListItem",
  components: {
    TheTooltip,
    IconDropdown,
    TheLink,
  },
  emits: [
    "toggleDeleteModal",
    "setCampaignRecipients",
    "update:isNewGroupModalVisible",
    "update:isNewSegmentModalVisible",
  ],
  props: {
    currentTab: {
      type: Number,
      default: 1,
    },
    group: {
      type: Object,
    },
    segment: {
      type: Object,
    },
  },
  watch: {
    isNewGroupModalVisible(newVal) {
      if (newVal) {
        this.toggleNewGroupModal();
      }
    },
    isNewSegmentModalVisible(newVal) {
      if (newVal) {
        this.toggleNewSegmentModal();
      }
    },
  },
  created() {
    // this.checkIfUserHasGroups();
    this.listRecipientsByGroupId();
    if (this.currentTab === 2) {
      this.loadRecipientsBySegmentFilter();
      // alert()
    }
  },
  data() {
    return {
      recipientsCount: 0,
      listingRecipientsBySegmentFilter: false,
    };
  },
  computed: {
    recipientData() {
      return this.dashboardView ? this.sharedCampaignData : this.recipients;
    },
    segmentRecipientCount() {
      return this?.recipientsCount;
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.recipients.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    userClient() {
      return this?.$store?.state?.auth?.user?.client;
    },
    recipientGiftingLead() {
      return (recipient) => {
        if (
          recipient?.metadata?.giftingLeads &&
          recipient?.metadata?.giftingLeads[0] ===
            this?.$store?.state?.auth?.user?._id
        ) {
          return true;
        }
      };
    },
    idsToEdit() {
      return this.recipientIdsToEdit.map((recipient) => recipient._id);
    },
    idsToDelete() {
      return this.recipientIdsToDelete.map((recipient) => recipient._id);
    },
    getTimeStamp() {
      return (id) => {
        const objectIdToTimestamp = require("objectid-to-timestamp");
        const originalDate = new Date(objectIdToTimestamp(id));
        return moment(originalDate).format("MMM D, YYYY, h:mm A");
      };
    },
    tags() {
      return (group) => {
        if (group?.metadata?.tags?.length === 1) {
          return group?.metadata?.tags[0];
        }
        return group?.metadata?.tags?.join(", ") || "";
      };
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async loadRecipientsBySegmentFilter() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.listingRecipientsBySegmentFilter = true;

      let filter = {};

      console.log("segmentFilter: ", this.segmentFilter);
      filter = this.segment.filter;

      const queryParameters = {
        limit: 1,
      };

      const requestBody = { filter };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);
        const count = response.data.result.count;

        this.recipientsCount = count;
      } catch (error) {
        console.error("recipients did not load", error);
      } finally {
        this.listingRecipientsBySegmentFilter = false;
      }
    },

    async listRecipientsByGroupId() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let requestBody = {};
      let filter = {};
      filter = {
        "metadata.giftingLeads": { $in: [this.userId] },
        "groups._id": { $eq: this.group._id },
      };

      // const groupFilter = this.group
      //   ? {
      //       "groups._id": { $eq: this.group._id },
      //     }
      //   : {};

      // filter = {
      //   ...filter,
      //   ...groupFilter,
      // };

      /////////

      const queryParameters = {
        // skip: (this.currentPage - 1) * this.pageSize,
        limit: 1,
        // sort: this.sortedValue,
        expand: "groups campaigns",
      };

      // let selection = "firstName lastName contact metadata campaigns";

      requestBody = {
        filter: filter,
        // selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);
        this.recipientsCount = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your recipients data. Please reload the page and try again."
        );
      } finally {
        this.recipientsAreLoading = false;
      }
    },
    /** dropdown menu items and actions */
    getDropdownItems(data) {
      // this.selectedRecipient = type;
      let dataType;
      if (data?.members) {
        dataType = "group";
      } else {
        dataType = "segment";
      }
      return [
        {
          label: "Edit",
          isDeleteAction: false,
          action: () => this.handleRoutingToDetailsPage(dataType, data._id),
        },
        // {
        //   label: "View campaigns",
        //   isDeleteAction: false,
        //   action: () => this.toggleRecipientDetailsModal(type),
        // },
        // {
        //   label: "Duplicate",
        //   isDeleteAction: false,
        //   action: () => this.toggleRecipientDetailsModal(type),
        // },
        {
          label: "Delete",
          isDeleteAction: true,
          action: () => this.toggleDeleteModal(dataType, data._id),
        },
      ];
    },
    /** routing */
    handleRoutingToDetailsPage(dataType, dataId) {
      if (dataType === "group") {
        this.$router.push({
          name: "RecipientGroupDetails",
          params: { groupId: dataId },
        });
      }
      if (dataType === "segment") {
        this.$router.push({
          name: "RecipientSegmentDetails",
          params: { segmentId: dataId },
        });
      }
    },
    toggleDeleteModal(dataType, dataId) {
      this.$emit("toggleDeleteModal", dataType, dataId);
      // if (dataType === "group") {
      //   this.groupIdsToDelete = [dataId];

      // }
      // if (dataType === "segment") {
      //   this.segmentIdsToDelete = [dataId];
      // }
      // this.showDeleteModal = !this.showDeleteModal;
    },
    sortByDropdownItems() {
      return [
        {
          label: "Newest",
          // action: () => this.toggleNewGroupModal(),
        },
        {
          label: "Oldest",
          // action: () => this.listGroup(),
        },
        {
          label: "A-Z",
          // action: () => this.toggleNewGroupModal(),
        },
        {
          label: "Z-A",
          // action: () => this.listGroup(),
        },
      ];
    },
    typeDropdownItems() {
      return [
        {
          label: "Groups",
          // action: () => this.handleListingGroupsAndSegments('groups'),
        },
        {
          label: "Segments",
          // action: () => this.handleListingGroupsAndSegments('segments'),
        },
      ];
    },
    setRecordsUserRecordsToView(value) {
      this.selectedRecipients = [];
      this.viewMyRecordsOnly = value;
      this.searchQuery.value = "";
      this.searchQuery.active = false;
      this.checkForRecipientData();
    },

    reloadList() {
      const page = this.currentPage;
      if (this.searchQuery.value) {
        this.handleSearchQuery();
      } else if (this.activeStage) {
        this.listRecipientsByLifecycleStage(this.activeStage);
      } else {
        this.checkForRecipientData(page);
      }
    },

    /** quick edits */
    activateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to true
      this.$store.dispatch("orders/activateQuickEdit");
    },
    deactivateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to false
      this.$store.dispatch("orders/deactivateQuickEdit");
    },

    /** modals */
    // toggleNewGroupModal
    toggleNewGroupModal() {
      this.showNewGroupModal = !this.showNewGroupModal;
    },
    toggleNewSegmentModal() {
      this.showNewSegmentModal = !this.showNewSegmentModal;
    },

    /** handle success and errors */
    handleSuccess(response, api) {
      if (this.campaignView) {
        this.$emit("setCampaignRecipients", [response]);
        this.reloadList();
        return;
      }
      if (api === "deleteRecipients") {
        let res = response;
        this.selectedRecipients = [];
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Selected recipients were successfully deleted.",
          duration: 4000,
          id: Date.now(),
        });
        this.loadRecipients();
      } else if (api === "bulkUpdateRecipients") {
        let res = response;
        this.selectedRecipients = [];
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Selected recipients were successfully edited.",
          duration: 4000,
          id: Date.now(),
        });
        this.loadRecipients();
      } else {
        this.showNewGroupModal = false;
        this.loadRecipients();
      }
    },

    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error?.response) {
        // Process API error message if customMessage is not provided
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      // this.reloadList();
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>
