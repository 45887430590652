<template>
  <!-- SIDEBAR -->
  <div class="sidebar-container">
    <div class="offcanvas offcanvas-start" tabindex="-1" data-bs-scroll="true" id="offcanvas">
      <div class="offcanvas-header">
        <!-- Avatar -->
        <div class="d-flex align-items-center">
          <div class="dropdown">
            <a href="#" class="d-flex align-items-center text-white text-decoration-none" id="dropdownUser1"
              data-bs-toggle="dropdown" aria-expanded="false">
              <div class="avatar avatar-xs">
                <img v-if="Avatar" class="avatar-img" :src="Avatar" alt="User Avatar" />
                <span class="avatar-status avatar-sm-status avatar-status-success"></span>
              </div>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
              aria-labelledby="dropdownUser1">
              <li>
                <router-link class="dropdown-item" to="/account">
                  Profile
                </router-link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <button class="dropdown-item" @click="logout()">
                  Sign out
                </button>
              </li>
            </ul>
          </div>
          <div class="text-start ms-2" v-if="User">
            <p class="mb-0">{{ User.firstName }} {{ User.lastName }}</p>
            <p class="card-text text-body">{{ User.email }}</p>
          </div>
        </div>
        <button type="button" class="navbar-toggler" data-bs-dismiss="offcanvas" aria-label="Close">
          <span class="navbar-toggler-default">
            <b-icon-chevron-double-left class="text-info"></b-icon-chevron-double-left>
          </span>
        </button>
      </div>
      <div id="sidebar" class="offcanvas-body px-0 py-0 overflow-hidden">
        <div id="sidebar-nav">
          <div id="navbarVerticalMenu"
            class="nav nav-pills nav-vertical card-navbar-nav d-flex justify-content-center ps-3">
            <div id="navbarVerticalMenuPagesMenu">
              <!-- Analytics -->
              <!-- <div class="nav-item">
                <router-link class="nav-link" to="/analytics">
                  <b-icon-house class="nav-icon"></b-icon-house
                  ><span class="nav-link-title">Dashboard</span>
                </router-link>
              </div> -->

              <!-- USERS -->
              <div class="nav-item">
                <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagesUsersMenu" role="button"
                  data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesUsersMenu" aria-expanded="false"
                  aria-controls="navbarVerticalMenuPagesUsersMenu">
                  <b-icon-people class="nav-icon"></b-icon-people><span class="nav-link-title">Members</span>
                </a>

                <div id="navbarVerticalMenuPagesUsersMenu" class="nav-collapse collapse"
                  data-bs-parent="#navbarVerticalMenuPagesMenu">
                  <router-link class="nav-link" to="/members">
                    <b-icon-search class="nav-icon"></b-icon-search><span class="nav-link-title">View members</span>
                  </router-link>
                  <router-link class="nav-link" to="/add/member">
                    <b-icon-plus-circle class="nav-icon"></b-icon-plus-circle><span class="nav-link-title">Add
                      member</span>
                  </router-link>
                </div>
              </div>

              <!-- CAMPAIGNS -->
              <div class="nav-item">
                <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagesCampaignsMenu" role="button"
                  data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesCampaignsMenu" aria-expanded="false"
                  aria-controls="navbarVerticalMenuPagesCampaignsMenu">
                  <b-icon-people class="nav-icon"></b-icon-people><span class="nav-link-title">Campaigns</span>
                </a>

                <div id="navbarVerticalMenuPagesCampaignsMenu" class="nav-collapse collapse"
                  data-bs-parent="#navbarVerticalMenuPagesMenu">
                  <router-link class="nav-link" to="/campaigns">
                    <b-icon-search class="nav-icon"></b-icon-search><span class="nav-link-title">View campaigns</span>
                  </router-link>
                  <router-link class="nav-link" to="/campaign/new">
                    <b-icon-plus-circle class="nav-icon"></b-icon-plus-circle><span class="nav-link-title">Send
                      campaign</span>
                  </router-link>
                </div>
              </div>

              <!-- VENDORS -->
              <div class="nav-item">
                <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagesVendorsMenu" role="button"
                  data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesVendorsMenu" aria-expanded="false"
                  aria-controls="navbarVerticalMenuPagesVendorsMenu">
                  <b-icon-buildings class="nav-icon"></b-icon-buildings><span class="nav-link-title">Vendors</span>
                </a>

                <div id="navbarVerticalMenuPagesVendorsMenu" class="nav-collapse collapse"
                  data-bs-parent="#navbarVerticalMenuPagesMenu">
                  <router-link class="nav-link" to="/vendors">
                    <b-icon-search class="nav-icon"></b-icon-search><span class="nav-link-title">View vendors</span>
                  </router-link>
                  <router-link class="nav-link" to="/vendors/options">
                    <b-icon-plus-circle class="nav-icon"></b-icon-plus-circle><span class="nav-link-title">Add
                      vendors</span>
                  </router-link>
                </div>
              </div>

              <!-- ONBOARDING -->
              <div class="nav-item">
                <a class="nav-link dropdown-toggle" href="#navbarVerticalMenuPagesOnboardingMenu" role="button"
                  data-bs-toggle="collapse" data-bs-target="#navbarVerticalMenuPagesOnboardingMenu" aria-expanded="false"
                  aria-controls="navbarVerticalMenuPagesOnboardingMenu">
                  <b-icon-buildings class="nav-icon"></b-icon-buildings><span class="nav-link-title">Onboarding
                    Pages</span>
                </a>

                <div id="navbarVerticalMenuPagesOnboardingMenu" class="nav-collapse collapse"
                  data-bs-parent="#navbarVerticalMenuPagesMenu">
                  <router-link class="nav-link" to="/checkout">
                    <b-icon-1-circle class="nav-icon"></b-icon-1-circle><span class="nav-link-title">Checkout</span>
                  </router-link>
                  <router-link class="nav-link" to="/account-setup/q1">
                    <b-icon-2-circle class="nav-icon"></b-icon-2-circle><span class="nav-link-title">Account Setup - Q1
                    </span>
                  </router-link>
                  <router-link class="nav-link" to="/account-setup/q2">
                    <b-icon-3-circle class="nav-icon"></b-icon-3-circle><span class="nav-link-title">Account Setup - Q2
                    </span>
                  </router-link>
                </div>
              </div>

              <!-- GIFT CARDS -->
              <div class="nav-item">
                <router-link class="nav-link" to="/home">
                  <b-icon-asterisk class="nav-icon"></b-icon-asterisk><span class="nav-link-title">Gift Cards</span>
                </router-link>
              </div>

              <li>
                <hr class="dropdown-divider" />
              </li>

              <!-- ORDERS -->
              <!-- <div class="nav-item">
                <router-link class="nav-link" to="/orders">
                  <b-icon-list class="nav-icon"></b-icon-list
                  ><span class="nav-link-title">Orders</span>
                </router-link>
              </div> -->

              <!-- PRODUCTS -->
              <!-- <div class="nav-item">
                <a
                  class="nav-link dropdown-toggle"
                  href="#navbarVerticalMenuPagesProductsMenu"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarVerticalMenuPagesProductsMenu"
                  aria-expanded="false"
                  aria-controls="navbarVerticalMenuPagesProductsMenu"
                >
                  <b-icon-gift class="nav-icon"></b-icon-gift
                  ><span class="nav-link-title">Products</span>
                </a>

                <div
                  id="navbarVerticalMenuPagesProductsMenu"
                  class="nav-collapse collapse"
                  data-bs-parent="#navbarVerticalMenuPagesMenu"
                >
                  <router-link class="nav-link" to="/products">
                    <b-icon-search class="nav-icon"></b-icon-search
                    ><span class="nav-link-title">View products</span>
                  </router-link>
                  <router-link class="nav-link" to="/create-product">
                    <b-icon-plus-circle class="nav-icon"></b-icon-plus-circle
                    ><span class="nav-link-title">Create product</span>
                  </router-link>
                </div>
              </div> -->

              <!-- KITS -->
              <!-- <div class="nav-item">
                <a
                  class="nav-link dropdown-toggle"
                  href="#navbarVerticalMenuPagesKitsMenu"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarVerticalMenuPagesKitsMenu"
                  aria-expanded="false"
                  aria-controls="navbarVerticalMenuPagesKitsMenu"
                >
                  <b-icon-boxes class="nav-icon"></b-icon-boxes
                  ><span class="nav-link-title">Kits</span>
                </a>

                <div
                  id="navbarVerticalMenuPagesKitsMenu"
                  class="nav-collapse collapse"
                  data-bs-parent="#navbarVerticalMenuPagesMenu"
                >
                  <router-link class="nav-link" to="/kits">
                    <b-icon-search class="nav-icon"></b-icon-search
                    ><span class="nav-link-title">View kits</span>
                  </router-link>
                  <router-link class="nav-link" to="/build-kit">
                    <b-icon-plus-circle class="nav-icon"></b-icon-plus-circle
                    ><span class="nav-link-title">Build kit</span>
                  </router-link>
                </div>
              </div> -->

              <!-- PROPOSALS -->
              <!-- <div class="nav-item">
                <router-link class="nav-link" to="/proposals">
                  <b-icon-receipt class="nav-icon"></b-icon-receipt
                  ><span class="nav-link-title">Proposals</span>
                </router-link>
              </div> -->

              <!-- CATALOG -->
              <!-- <div class="nav-item">
                <router-link class="nav-link" to="/home">
                  <b-icon-shop class="nav-icon"></b-icon-shop
                  ><span class="nav-link-title">Catalog</span>
                </router-link>
              </div> -->

              <!-- Ready To Go Gifts -->
              <!-- <div class="nav-item">
                <router-link class="nav-link" to="/collection/ready">
                  <b-icon-shop class="nav-icon"></b-icon-shop
                  ><span class="nav-link-title">Ready-to-Ship</span>
                </router-link>
              </div> -->
            </div>
            <!-- End Collapse -->
          </div>
        </div>
      </div>

      <!-- <div class="col d-flex flex-column border-start px-0">
        <slot name="main" class="row" role="content"> </slot>
        <footer class="bg-light py-4 mt-auto px-0 align-self-end">
          <div class="col">
            <p class="fs-6 mb-0">
              &copy; Bold Xchange, Inc.
              <span class="d-none d-sm-inline-block">2023.</span>
            </p>
          </div>
        </footer>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "TheSidebar",
  components: {},
  data() {
    return {
      ordersTabIsToggled: false,
      productsTabIsToggled: false,
      kitsTabIsToggled: false,
      navbarIsToggled: false,
      sidebarIsCollapsed: false,
    };
  },
  computed: {
    User() {
      return this.$store.state.auth.user;
    },
    Avatar() {
      const url = this.User?.photo;
      if (url) {
        if (url.indexOf("/") === 0) {
          return process.env.VUE_APP_API_BASE_URL + url;
        } else {
          return url;
        }
      } else {
        return `https://api.dicebear.com/7.x/initials/svg?seed=${this.User.firstName}%20${this.User.lastName}&backgroundColor=3949ab`;
      }
    },
  },
  methods: {
    logout() {
      // this.$store.commit("auth/LOGOUT");
      // this.$router.push({ path: "/login" });

      this.$store.dispatch("auth/logout").then(() => {
        // console.log("logout");
        this.$router.push({ path: "/login" });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sidebar-container {
  max-width: 200px;
}

.offcanvas {
  width: inherit;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.offcanvas-header {
  gap: 1rem;
  padding-left: 0;
  padding-right: 0;
}

#sidebar-nav {
  min-width: 200px;
  height: 100vh;
}

.dropdown-toggle::after {
  border-top: none;
}
</style>
