<template>
  <template v-if="showNewRecipientModal">
    <Teleport to="#modal">
      <TheModal
        title="Creating new recipient"
        @closeModal="closeModal"
        :open="showNewRecipientModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <NewRecipientForm
          @closeModal="closeModal"
          @handleNewRecipient="handleNewContact"
        ></NewRecipientForm>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showDeleteModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        title="Delete selected members?"
        @closeModal="closeModal"
        :open="showDeleteModal"
      >
        <ModalBody>
          <DeleteData
            :members="idsToDelete"
            @closeModal="closeModal"
            @confirmDelete="confirmDelete()"
          ></DeleteData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="showBulkEditModal">
    <Teleport to="#modal">
      <TheModal
        :showModalHeader="true"
        :showModalBody="true"
        :closeOnClickOutside="false"
        title="Bulk editing lifecycle stage"
        @closeModal="closeModal"
        :open="showBulkEditModal"
      >
        <ModalBody>
          <BulkEditData
            :recipients="memberIdsToEdit"
            @closeModal="closeModal"
            @confirmBulkEdit="confirmBulkEdit"
          ></BulkEditData>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="checkingIfUserHasMembers">
    <!-- <div
      class="tw-flex tw-justify-center tw-items-center tw-w-full"
    > -->
    <PageSection :fullWidth="true">
      <PulseAnimation>
        <DataTableSkeleton></DataTableSkeleton>
      </PulseAnimation>
    </PageSection>
    <!-- </div> -->
  </template>
  <template v-else>
    <TheCard class="tw-w-full">
      <template v-if="userHasMembers">
        <CardHeader
          :showActions="true"
          :useSearchBar="!dashboardView && userHasMembers ? true : false"
        >
          <template #card-search-bar>
            <div class="tw-max-w-xl tw-w-full">
              <div class="tw-relative tw-rounded-md tw-shadow-sm tw-w-full">
                <div
                  class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                >
                  <b-icon-search
                    class="tw-h-5 tw-w-5 tw-text-gray-400"
                    aria-hidden="true"
                  ></b-icon-search>
                </div>
                <input
                  @keyup.enter="handleSearchQuery()"
                  v-model="searchQuery.value"
                  type="text"
                  name="memberSearch"
                  id="memberSearch"
                  class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-pl-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-sm tw-leading-6"
                  placeholder="Search member by name or email"
                />
                <template v-if="searchQuery.active">
                  <div
                    @click="resetFilters()"
                    class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-cursor-pointer"
                  >
                    <span>reset</span>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template #card-header-actions>
            <!-- <template
              v-if="
                campaignView && userHasMembers && selectedMembers.length === 0
              "
            >
              <div class="tw-flex tw-gap-2 tw-items-center">
                <DropdownMenu
                  :items="addOrImportDropdownItems()"
                  :hasLeadingIcon="true"
                  :buttonDropdown="true"
                  :buttonDropdownList="true"
                  buttonText="Add"
                  size="small"
                  variant="primary"
                >
                </DropdownMenu>

                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  variant="tertiary"
                  size="small"
                  @click="toggleListView()"
                >
                  <template #text>
                    <template v-if="viewLifecycleDistribution">
                      All recipients</template
                    >
                    <template
                      v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
                      >Lifecycle lists</template
                    >
                  </template>
                  <template #leading-icon>
                    <template v-if="viewLifecycleDistribution">
                      <b-icon-list></b-icon-list
                    ></template>
                    <template
                      v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
                      ><b-icon-grid></b-icon-grid
                    ></template>
                  </template>
                </TheButton>
              </div>
            </template> -->
            <!-- <template v-else> -->
            <!-- <template v-if="!isBoldXchangeAdmin">
                <div class="tw-flex tw-bg-gray-100 tw-p-0.5 tw-rounded-md">
                  <TheButton
                    type="button"
                    :variant="
                      viewMyRecordsOnly === false ? 'secondary' : 'ghost'
                    "
                    size="regular"
                    @click="setRecordsUserRecordsToView(false)"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon>
                      <b-icon-people></b-icon-people>
                    </template>
                    <template #text>Customer recipients</template>
                  </TheButton>
                  <TheButton
                    type="button"
                    :variant="
                      viewMyRecordsOnly === true ? 'secondary' : 'ghost'
                    "
                    size="regular"
                    @click="setRecordsUserRecordsToView(true)"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon>
                      <b-icon-person></b-icon-person>
                    </template>
                    <template #text>My recipients</template>
                  </TheButton>
                </div>
              </template> -->
            <!-- <TheButton
              type="button"
              :hasLeadingIcon="true"
              variant="tertiary"
              size="regular"
              @click="toggleListView()"
            >
              <template #text>
                <template v-if="viewLifecycleDistribution"> List all</template>
                <template
                  v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
                  >View Lists</template
                >
              </template>
              <template #leading-icon>
                <template v-if="viewLifecycleDistribution">
                  <b-icon-list></b-icon-list
                ></template>
                <template
                  v-if="viewAllRecipients || viewRecipientsByLifecycleStage"
                  ><b-icon-grid></b-icon-grid
                ></template>
              </template>
            </TheButton> -->
            <!-- </template> -->
          </template>
        </CardHeader>
      </template>
      <CardBody>
        <template v-if="membersAreLoading">
          <PulseAnimation>
            <DataTableSkeleton></DataTableSkeleton>
          </PulseAnimation>
        </template>
        <template v-else>
          <!-- if userHasMembers show recipient list -->
          <template v-if="userHasMembers && count">
            <div
              class="tw-overflow-y-auto tw-min-h-[500px] tw-max-h-[500px] tw-w-full"
            >
              <div
                class="tw-flex tw-justify-between gap-4 tw-h-12 tw-px-3 tw-bg-stone-100 tw-sticky tw-top-0 tw-z-40 tw-items-center tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b tw-border-stone-300"
              >
                <div>
                  <template v-if="isBoldXchangeAdmin && !viewMyRecordsOnly">
                    <p class="tw-m-0 tw-text-sm tw-font-bold tw-text-red-600">
                      You are currently viewing customer recipients
                    </p>
                  </template>
                  <template v-if="viewMyRecordsOnly">
                    <input
                      id="selectAllMembers"
                      name="selectAllMembers"
                      type="checkbox"
                      class="tw-left-4 tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-me-2"
                      :checked="selectedMembers.length === members.length"
                      @change="selectAllMembers($event.target.checked)"
                    /><label class="tw-text-gray-900" for="selectAllMembers"
                      >Select all visible</label
                    >
                  </template>
                </div>
                <template
                  v-if="selectedMembers.length > 0 && viewMyRecordsOnly"
                >
                  <div class="tw-flex tw-gap-4 tw-items-center">
                    <p class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600">
                      {{ selectedMembers.length }} selected
                    </p>
                    <DropdownMenu
                      :disabled="selectedMembers.length === 0"
                      :items="bulkDropdownItems()"
                      :buttonDropdown="true"
                      :buttonDropdownList="true"
                      buttonText="Bulk actions"
                    >
                    </DropdownMenu>
                  </div>
                </template>
                <template v-if="selectedMembers.length > 0 && campaignView">
                  <div class="tw-flex tw-gap-4 tw-items-center">
                    <template v-if="campaignRecipientsSelectionUpdated">
                      <p
                        class="tw-m-0 tw-text-sm tw-font-bold tw-text-green-600"
                      >
                        <b-icon-check-lg></b-icon-check-lg> Recipient list
                        updated
                      </p>
                    </template>
                    <template v-else>
                      <p
                        class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-600"
                      >
                        {{ selectedMembers.length }} selected
                      </p>
                    </template>
                    <TheButton
                      :disabled="this.selectedMembers.length === 0"
                      variant="success"
                      @click="setCampaignRecipients()"
                      size="small"
                      :hasLeadingIcon="true"
                    >
                      <template #leading-icon
                        ><b-icon-check-lg></b-icon-check-lg
                      ></template>
                      <template #text>Add selected recipients</template>
                    </TheButton>
                  </div>
                </template>
              </div>
              <table class="tw-min-w-full tw-divide-stone-300">
                <thead
                  class="tw-sticky tw-z-10 tw-border-b tw-border-stone-300"
                >
                  <tr>
                    <th
                      scope="col"
                      class="tw-min-w-20 tw-py-3.5 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Organization
                    </th>
                    <th
                      scope="col"
                      class="tw-hidden sm:tw-table-cell tw-px-3 tw-py-3.5 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      Date created
                    </th>
                    <th
                      scope="col"
                      class="tw-px-3 tw-py-3.5 tw-text-sm tw-font-semibold tw-text-gray-900 tw-sticky tw-top-0 tw-border-b tw-border-gray-300 tw-bg-stone-200"
                    >
                      <span class="tw-sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="tw-divide-y tw-divide-gray-200 tw-bg-white tw-min-h-[500px]"
                >
                  <tr
                    v-for="member in members"
                    :key="member.id"
                    :class="[
                      selectedMembers.includes(member.id) && 'bg-gray-50',
                    ]"
                  >
                    <td class="tw-relative tw-px-3 tw-py-4">
                      <div
                        v-if="isMemberSelected(member.id)"
                        class="tw-absolute tw-inset-y-0 tw-left-0 tw-w-0.5 tw-bg-blue-600"
                      ></div>

                      <div class="tw-flex tw-items-center gap-2">
                        <input
                          :disabled="!viewMyRecordsOnly"
                          type="checkbox"
                          class="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-blue-600 focus:tw-ring-blue-600 tw-cursor-pointer"
                          :checked="isMemberSelected(member.id)"
                          @change="toggleMemberSelection(member)"
                        />
                        <TheLink
                          variant="tertiary"
                          :url="{
                            name: 'MemberDetails',
                            params: { memberId: member._id },
                          }"
                          :isRouterLink="true"
                        >
                          <template #text>
                            <template
                              v-if="member?.firstName && member?.lastName"
                            >
                              {{ member?.firstName + " " + member?.lastName }}
                            </template>
                            <template v-else>N/A</template>
                          </template>
                          <template #leading-icon
                            ><b-icon-plus-lg></b-icon-plus-lg>
                          </template>
                        </TheLink>
                        <div class="sm:tw-hidden tw-flex tw-flex-col">
                          <p class="tw-p-0 tw-m-0 tw-text-sm">
                            {{ member?.contact?.email || "---" }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      {{ member?.email || "---" }}
                    </td>

                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      <template v-if="member?.client">
                        <p class="tw-m-0 tw-text-sm">
                          {{ member?.client?.name || "---" }}
                        </p>
                        <p
                          class="tw-m-0 tw-text-sm tw-font-bold tw-text-gray-500"
                        >
                          {{ member?.client?.domains[0] || "---" }}
                        </p>
                      </template>
                      <template v-else>
                        <p class="tw-p-0 tw-m-0 tw-text-sm">No client</p>
                      </template>
                    </td>
                    <td
                      class="tw-hidden sm:tw-table-cell tw-whitespace-nowrap tw-px-3 tw-py-4 tw-text-sm tw-text-gray-500"
                    >
                      {{ dateCreated(member) || "---" }}
                    </td>

                    <td
                      class="tw-py-4 tw-pl-3 tw-pr-4 tw-text-right tw-text-sm tw-font-medium sm:tw-pr-3"
                    >
                      <div class="tw-flex tw-justify-end">
                        <IconDropdown
                          :id="member._id"
                          :items="getDropdownItems(member)"
                          :disabled="false"
                          variant="tertiary"
                        >
                          <template #icon>
                            <b-icon-three-dots-vertical />
                          </template>
                        </IconDropdown>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <!-- if userHasMembers AND query count is 0 show recipient message -->
          <template v-if="userHasMembers && !count">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
            >
              <template v-if="searchQuery.active">
                <img
                  style="max-height: 150px; width: auto"
                  src="../../assets/svg/illustrations-test/14_no_query_results.png"
                  alt="Image Description"
                />
              </template>
              <template v-else>
                <img
                  style="max-height: 150px; width: auto"
                  src="../../assets/svg/illustrations-test/46_blank_page.png"
                  alt="Image Description"
                />
              </template>

              <template v-if="activeStage && searchQuery.active">
                <h1 class="tw-text-2xl tw-m-0">
                  No exact matches found in
                  <span class="tw-text-indigo-600">{{ activeStage }}s</span>
                </h1>
                <p class="tw-m-0 tw-text-lg tw-max-w-xl">
                  Adjust your search, or manage your list by either adding new
                  recipients or moving existing ones into this one.
                </p>
              </template>
              <template v-else-if="activeStage && !searchQuery.active">
                <h1 class="tw-text-2xl tw-m-0">
                  Your
                  <span class="tw-text-indigo-600">{{
                    `${activeStage}s`
                  }}</span>
                  list is empty
                </h1>
                <p class="tw-m-0 tw-text-lg">
                  Add new recipients or move existing ones into this one.
                </p>
              </template>
              <template v-else>
                <h1 class="tw-text-2xl tw-m-0">No exact matches found</h1>
                <p class="tw-m-0 tw-text-lg tw-max-w-xl">
                  Adjust your search, or manage your list by either adding new
                  recipients or moving existing ones into this one.
                </p>
              </template>
            </div>
          </template>
          <!-- if !userHasMembers show message -->
          <template v-if="!userHasMembers">
            <div
              class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px]"
            >
              <EmptyState emptyState="recipients"></EmptyState>
              <div class="tw-flex tw-flex-col tw-gap-4 tw-items-center">
                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  variant="primary"
                  size="large"
                  @click="toggleNewRecipientModal"
                >
                  <template #text>Add recipient</template>
                  <template #leading-icon
                    ><b-icon-plus-lg></b-icon-plus-lg
                  ></template>
                </TheButton>
                <TheLink
                  type="button"
                  variant="tertiary"
                  size="large"
                  :isRouterLink="true"
                  to="/recipients/import"
                >
                  <template #text>Import contacts</template>
                </TheLink>
              </div>
            </div>
          </template>
        </template>
      </CardBody>
      <template v-if="userHasMembers">
        <CardFooter>
          <!-- Pagination -->
          <nav
            class="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-gray-200"
            aria-label="Pagination"
          >
            <div class="tw-hidden sm:tw-block">
              <p class="tw-text-sm tw-text-gray-700 tw-m-0">
                Showing
                <span class="tw-font-bold"
                  >{{ this.members.length }} of {{ count }}</span
                >
                records
              </p>
            </div>
            <div
              class="tw-flex tw-flex-1 tw-justify-between tw-gap-x-3 sm:tw-justify-end"
            >
              <TheButton
                class="disabled:tw-bg-slate-200 disabled:tw-cursor-auto"
                :disabled="
                  this.currentPage === 1 || this.selectedMembers.length > 0
                "
                @click="previousPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-left></b-icon-chevron-left
                ></template>
              </TheButton>
              <TheButton
                class="disabled:tw-bg-slate-200"
                :disabled="this.isLastPage || this.selectedMembers.length > 0"
                @click="nextPage()"
                variant="tertiary"
                size="small"
              >
                <template #text
                  ><b-icon-chevron-right></b-icon-chevron-right
                ></template>
              </TheButton>
            </div>
          </nav>
        </CardFooter>
      </template>
    </TheCard>
  </template>
</template>

<script>
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import CardHeader from "../../components-v2/ui/layout/CardHeader.vue";
import CardBody from "../../components-v2/ui/layout/CardBody.vue";
import CardFooter from "../../components-v2/ui/layout/CardFooter.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import WideModal from "../../components-v2/ui/modals/WideModal.vue";
import NewRecipientForm from "../../components-v2/ui/modals/NewRecipientForm.vue";
import EditRecipientForm from "../../components-v2/ui/modals/EditRecipientForm.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import TheLink from "../../components-v2/ui/actions/TheLink.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import DropdownMenu from "../../components-v2/ui/forms/DropdownMenu.vue";
import IconDropdown from "../../components-v2/ui/elements/dropdown/IconDropdown.vue";
import DeleteData from "../ui/modals/DeleteData.vue";
import BulkEditData from "../ui/modals/BulkEditData.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import DataTableSkeleton from "../../components-v2/ui/skeletons/DataTableSkeleton.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import moment from "moment";

export default {
  name: "RecipientsIndexTableV2",
  components: {
    IconDropdown,
    BulkEditData,
    DeleteData,
    NewRecipientForm,
    EditRecipientForm,
    TheModal,
    DropdownMenu,
    TheLink,
    EmptyState,
    CardBody,
    TheCard,
    CardHeader,
    TheButton,
    CardFooter,
    PageSection,
    DataTableSkeleton,
    PulseAnimation,
    ModalBody,
    WideModal,
  },
  emits: [
    "setCampaignRecipients",
    "update:isModalVisible",
  ],
  props: {
    campaignView: {
      type: Boolean,
      default: false,
    },
    campaignId: {
      type: String,
    },
    dashboardView: {
      type: Boolean,
      default: false,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    sharedCampaignData: {
      type: Array,
      default: () => [],
    },
    totalRecipientsCount: {
      type: Number,
      default: 1,
      required: false,
    },
    campaignRecipients: {
      type: Array,
    },
    viewCampaignRecipientsSelection: {
      type: Boolean,
    },
  },
  watch: {
    isModalVisible(newVal) {
      if (newVal) {
        this.toggleNewRecipientModal();
      }
    },
  },
  created() {
    /** load data */
    this.loadMembers();
  },
  data() {
    return {
      newRecipient: {},
      selectedMember: {},
      lifecycleStages: [
        { lifecycleStage: "Center of Influence", count: 0 },
        { lifecycleStage: "Customer", count: 0 },
        { lifecycleStage: "Warm Lead", count: 0 },
        { lifecycleStage: "Cold Lead", count: 0 },
        { lifecycleStage: "Unlisted Lead", count: 0 },
      ],
      members: [],
      selectedMembers: [],
      viewMyRecordsOnly: true,
      membersAreLoading: false,
      userHasMembers: false,
      checkingIfUserHasMembers: false,
      memberIdsToDelete: null,
      memberIdsToEdit: null,
      showDeleteModal: false,
      showBulkEditModal: false,
      viewLifecycleDistribution: true,
      count: 0,
      pageSize: 25,
      currentPage: 1,
      clientValue: 0,
      searchQuery: {
        active: false,
        value: "",
      },
      sortedValue: "-_id",
    };
  },
  computed: {
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.members.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    userClient() {
      return this?.$store?.state?.auth?.user?.client;
    },
    dateCreated() {
      return (member) => {
        const date = moment(member?.createdAt).format("MM/DD/YYYY");
        return date;
      };
    },
    idsToEdit() {
      return this.memberIdsToEdit.map((member) => member._id);
    },
    idsToDelete() {
      return this.memberIdsToDelete.map((member) => member._id);
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** handle deleting selected members */
    async confirmDelete() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      try {
        const response = await api.deleteUsers(null, {
          filter: { _id: { $in: this.idsToDelete } },
        });
        this.handleSuccess(response, "deleteUsers");
      } catch (error) {
        console.log("recipient was NOT deleted", error);
        this.handleErrorFromAPI(
          error,
          "There was issue processing your delete request. Please try again."
        );
      } finally {
        this.showDeleteModal = false;
      }
    },
    toggleDeleteModal(member) {
      this.selectedMembers.push(member);
      this.memberIdsToDelete = this.selectedMembers;
      this.showDeleteModal = !this.showDeleteModal;
    },
    handleBulkDelete(members) {
      this.memberIdsToDelete = members;
      this.showDeleteModal = !this.showDeleteModal;
    },

    closeModal() {
      this.showDeleteModal = false;
    },

    /** handle member selection */
    selectAllMembers(isChecked) {
      this.selectedMembers = isChecked ? this.members.slice() : [];
    },
    isMemberSelected(memberId) {
      return this.selectedMembers.some((r) => r.id === memberId);
    },
    toggleMemberSelection(member) {
      const index = this.selectedMembers.findIndex((r) => r.id === member.id);
      if (index > -1) {
        this.selectedMembers.splice(index, 1);
      } else {
        this.selectedMembers.push(member);
      }
    },

    /** load data */
    async loadMembers(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.checkingIfUserHasMembers = true;
      this.membersAreLoading = true;

      this.currentPage = page === undefined ? this.currentPage : page;

      // filters
      let requestBody = {};
      let selection = "client firstName lastName createdAt email";

      // if client filter is !== ''
      if (this.clientValue !== 0) {
        requestBody["client.name"] = {
          $eq: this.clientValue,
        };
      }

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.members = response.data.result.records;
        this.count = response.data.result.count;
        this.searchCount = response.data.result.count;
        this.userHasMembers = this.count;
      } catch (error) {
        console.log("members did not load", error);
      } finally {
        this.checkingIfUserHasMembers = false;
        this.membersAreLoading = false;
      }
    },

    /** paginate list of members */
    async nextPage() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.membersAreLoading = true;
      this.currentPage++;

      let requestBody = {};

      let selection = "client firstName lastName createdAt email";

      // if client filter is !== ''
      if (this.clientValue !== 0) {
        requestBody["client.name"] = {
          $eq: this.clientValue,
        };
      }

      // if status filter is !== ''
      if (this.searchQuery.value !== "") {
        requestBody = {
          $or: [
            { firstName: { $regex: this.searchQuery.value } },
            { lastName: { $regex: this.searchQuery.value } },
            { email: { $regex: this.searchQuery.value } },
            { "client.name": { $regex: this.searchQuery.value } },
            { "client.domains": { $regex: this.searchQuery.value } },
          ],
        };
      }

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.membersAreLoading = false;
        this.members = response.data.result.records;
        this.count = response.data.result.count;
        this.searchCount = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your members data. Please try again."
        );
      } finally {
        this.membersAreLoading = false;
      }
    },
    async previousPage() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      this.membersAreLoading = true;
      this.currentPage--;

      let requestBody = {};

      let selection = "client firstName lastName createdAt email";

      // if client filter is !== ''
      if (this.clientValue !== 0) {
        requestBody["client.name"] = {
          $eq: this.clientValue,
        };
      }

      // if status filter is !== ''
      if (this.searchQuery.value !== "") {
        requestBody = {
          $or: [
            { firstName: { $regex: this.searchQuery.value } },
            { lastName: { $regex: this.searchQuery.value } },
            { email: { $regex: this.searchQuery.value } },
            { "client.name": { $regex: this.searchQuery.value } },
            { "client.domains": { $regex: this.searchQuery.value } },
          ],
        };
      }

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: "client",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.membersAreLoading = false;
        this.members = response.data.result.records;
        this.count = response.data.result.count;
        this.searchCount = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your members data. Please try again."
        );
      } finally {
        this.membersAreLoading = false;
      }
    },

    /** handle resetting member list */
    resetFilters() {
      this.searchQuery.value = "";
      this.searchQuery.active = false;
      this.currentPage = 1;
      this.loadMembers();
    },

    /**handle listing members by search query*/
    async getMembersBySearchQuery(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      this.membersAreLoading = true;
      this.currentPage = page === undefined ? 1 : page;

      let requestBody = {};
      let filter = this.createSearchQuery();

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: "firstName",
        expand: "client",
      };

      let selection = "client firstName lastName createdAt email";

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listUsers(queryParameters, requestBody);
        this.members = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "We couldn't load your members data. Please try again."
        );
      } finally {
        this.membersAreLoading = false;
      }
    },
    handleSearchQuery() {
      this.searchQuery.active = true;
      this.getMembersBySearchQuery();
    },
    createSearchQuery() {
      if (this.searchQuery.value === "") return {};

      const searchValues = this.searchQuery.value.split(" ");
      const userFields = [
        "firstName",
        "lastName",
        "email",
        "client.name",
        "client.domains",
      ];

      const searchConditions = searchValues.map((value) => ({
        $or: userFields.map((field) => ({
          [field]: { $regex: value, $options: "i" },
        })),
      }));

      return {
        ...searchConditions[0],
      };
    },

    /** dropdown menu items and actions */
    getDropdownItems(member) {
      this.selectedMember = member;
      return [
        {
          label: "Edit",
          isDeleteAction: false,
          action: () => this.routeToEditPage(member._id),
        },
        {
          label: "Delete",
          isDeleteAction: true,
          action: () => this.toggleDeleteModal(member),
        },
      ];
    },
    bulkDropdownItems() {
      return [
        {
          label: "Update lifecycle stage",
          isDeleteAction: false,
          action: () => this.handleBulkEdit(this.selectedMembers),
        },
        {
          label: "Delete",
          isDeleteAction: true, // This item is a delete action
          action: () => this.handleBulkDelete(this.selectedMembers),
        },
      ];
    },

    reloadList() {
      const page = this.currentPage;
      if (this.searchQuery.value) {
        this.handleSearchQuery();
      } else {
        this.loadMembers(page);
      }
    },

    routeToEditPage(memberId) {
      this.$router.push(`/member/edit/${memberId}`);
    },

    /** handle different table views */
    toggleListView() {
      if (this.viewLifecycleDistribution) {
        this.viewLifecycleDistribution = false;
        this.viewAllRecipients = true;
        this.currentListView = "All recipients";
        this.resetFilters();
      } else if (this.viewAllRecipients) {
        this.currentListView = "Lifecycle lists";
        this.viewLifecycleDistribution = true;
        this.viewAllRecipients = false;
        this.checkForRecipientData();
      } else {
        this.viewRecipientsByLifecycleStage = false;
        this.viewLifecycleDistribution = true;
        this.checkForRecipientData();
        // this.resetFilters();
      }
      // this.resetFilters();
      this.selectedMembers = [];
      this.activeStage = "";
      this.searchQuery.value = "";
      this.searchQuery.active = false;
    },

    /** quick edits */
    activateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to true
      this.$store.dispatch("orders/activateQuickEdit");
    },
    deactivateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to false
      this.$store.dispatch("orders/deactivateQuickEdit");
    },

    /** modals */
    toggleNewRecipientModal() {
      this.showNewRecipientModal = !this.showNewRecipientModal;
    },
    handleNewContact(recipient) {
      this.showNewRecipientModal = false;
      this.handleSuccess(recipient, "handleNewRecipient");
    },

    /** handle errors */
    handleSuccess(response, api) {
      if (api === "deleteUsers") {
        let res = response;
        this.selectedMembers = [];
        this.$store.dispatch("alerts/showAlert", {
          type: this.notificationTypes.SUCCESS,
          message: "Selected recipients were successfully deleted.",
          duration: 4000,
          id: Date.now(),
        });
        this.loadMembers();
      } else {
        this.loadMembers();
      }
    },

    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error?.response) {
        // Process API error message if customMessage is not provided
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      // this.reloadList();
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>
