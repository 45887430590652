<template>
  <template v-if="checkingIfUserHasBudgets">
    <PageSection :fullWidth="true">
      <PulseAnimation>
        <GiftCardsGridSkeleton :numberOfCards="8"></GiftCardsGridSkeleton>
      </PulseAnimation>
    </PageSection>
  </template>
  <template v-else>
    <template v-if="budgetsAreLoading">
      <PulseAnimation>
        <GiftCardsGridSkeleton :numberOfCards="8"></GiftCardsGridSkeleton>
      </PulseAnimation>
    </template>
    <template v-else>
      <!-- if userHasBudgets show campaigns list -->
      <template v-if="userHasBudgets && count">
        <div>
          <div class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-3">
            <BudgetStats
              v-for="budget in budgets"
              :key="budget.id"
              :budget="budget"
              :isBudgetListItem="true"
              @checkForBudgets="checkForBudgets"
            >
            </BudgetStats>
          </div>
        </div>
      </template>
      <!-- if userHasBudgets AND query count is 0 show campaigns message -->
      <template v-if="userHasBudgets && !count">
        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
        >
          <img
            style="max-height: 150px; width: auto"
            src="../../assets/svg/illustrations-test/14_no_query_results.png"
            alt="Image Description"
          />
          <h1 class="tw-text-2xl tw-m-0">No exact matches</h1>
          <p class="tw-m-0 tw-text-lg">
            Try updating your search/filters or
            <a class="tw-font-bold tw-cursor-pointer" @click="resetFilters()"
              >click here</a
            >
            to reset.
          </p>
        </div>
      </template>
      <!-- if !userHasBudgets show message -->
      <template v-if="!userHasBudgets">
        <PageSection class="tw-w-full tw-max-w-3xl tw-mx-auto">
          <TheCard class="tw-w-full">
            <EmptyState emptyState="budgets"></EmptyState>
          </TheCard>
        </PageSection>
      </template>
    </template>
  </template>
</template>

<script>
import BudgetStats from "./BudgetStats.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import GiftCardsGridSkeleton from "../../components-v2/ui/skeletons/GiftCardsGridSkeleton.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";

export default {
  name: "BudgetsList",
  components: {
    BudgetStats,
    GiftCardsGridSkeleton,
    PulseAnimation,
    EmptyState,
    TheCard,
    PageSection,
  },
  props: {},
  created() {
    /** load data */
    // this.checkForBudgets();
  },
  data() {
    return {
      budgets: [],
      sortedValue: "-_id",
      count: 0,
      pageSize: 100,
      currentPage: 1,
      checkingIfUserHasBudgets: true,
      budgetsAreLoading: false,
      userHasBudgets: true,
    };
  },
  computed: {
    user() {
      return this?.$store?.state?.auth?.user;
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.budgets.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** handle checking if user has budgets */
    async checkForBudgets(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      this.currentPage = page === undefined ? this.currentPage : page;
      // const query = this.savedTemplates ? "saved" : "draft";

      try {
        let requestBody = {};
        let filter = {
          "user._id": { $eq: this.userId },
        };
        const queryParameters = {
          limit: 1000,
          sort: "-_id",
          expand: "user",
        };

        requestBody = {
          filter: filter,
        };
        const response = await api.listBudgets(queryParameters, requestBody);
        console.log("response: ", response);
        this.budgets = response.data.result.records;
        const count = response.data.result.count;
        this.count = count;
        this.userHasBudgets = count;
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.error("Failed to load budgets:", error);
        // Handle the error appropriately
      } finally {
        this.checkingIfUserHasBudgets = false;
      }
    },
    /** budgets list item dropdown items */
    sortDropdownItems() {
      return [
        {
          label: "Sort by: Newest",
          action: () => this.handleSort("-_id"),
        },
        {
          label: "Sort by: Oldest",
          action: () => this.handleSort("_id"),
        },
      ];
    },

    /** sorting */
    handleSort(column) {
      this.checkIfCampaignStatusIsAll();
      this.deactivateQuickEdit();
      this.currentPage = 1;

      this.sortedValue = this.sortedValue === column ? `-${column}` : column;

      if (this.searchQuery) {
        return this.handleSearchQuery();
      } else {
        return this.filterCampaigns();
      }
    },

    /** handle success and errors */
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.checkingIfUserHasBudgets = false;
      this.budgetsAreLoading = false;
      this.checkForBudgets();
      console.error(errorMessage);
    },
  },
};
</script>
