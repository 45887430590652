import store from "@/store";

export default {
  updateCart({ commit }, skuToAdd) {
    const cartData = this.getCartInformation();
    console.log("cartData", cartData);
    let skus = [];
    if (cartData) {
      skus = JSON.parse(cartData);
    } else {
      skus = [];
    }
    console.log("sku to add: ", skuToAdd);
    console.log("skus: ", skus);

    let skuExists = false;

    skus.map((kit) => {
      if (kit.sku === skuToAdd.sku) {
        kit.quantity = skuToAdd.quantity;
        skuExists = true;
        console.log("existing skuToAdd quantity: ", kit.quantity);
      }
    });

    if (!skuExists) {
      skus.push(skuToAdd);
      console.log("new skuToAdd quantity: ", skuToAdd.quantity);
    }

    commit("UPDATE_CART_SKUS", skus);
    commit("UPDATE_LOCAL_STORAGE_CART_DATA", skus);
  },
  deleteSkuFromCart({ commit }, skuToRemove) {
    const cartData = window.localStorage.getItem("cart");
    console.log("cartData", cartData);

    let skus = [];
    if (cartData) {
      skus = JSON.parse(cartData);
    } else {
      skus = [];
    }

    // const skus = store.getters["gifts/getCartSkus"];

    const index = skus.findIndex((skuItem) => skuItem.sku === skuToRemove);
    if (index !== -1) {
      console.log("SKU found in the array");
      skus.splice(index, 1);
    } else {
      console.log("SKU not found in the array");
    }

    commit("UPDATE_CART_SKUS", skus);
    commit("UPDATE_LOCAL_STORAGE_CART_DATA", skus);
  },
  updateFinalRetailPrice({ commit }, retailPrice) {
    // console.log("updated retailPrice", retailPrice);
    commit("UPDATE_KIT_FINAL_RETAIL_PRICE", retailPrice);
  },

  setCartSkus({ commit }, skusToAdd) {
    const skus = store.getters["gifts/getCartSkus"];
    console.log("SKUs to add: ", skusToAdd);
    let skuExists = false;

    skusToAdd.forEach((skuToAdd) => {
      skus.forEach((kit) => {
        if (kit.sku === skuToAdd.sku) {
          kit.quantity = skuToAdd.quantity;
          skuExists = true;
        }
      });

      if (!skuExists) {
        skus.push(skuToAdd);
      }
    });

    commit("UPDATE_CART_SKUS", skus);
  },
  resetSkus({ commit }, skus) {
    commit("UPDATE_KIT_SKUS", skus);
  },
  activateQuickEdit({ commit }) {
    commit("setQuickEditActive", true);
  },
  deactivateQuickEdit({ commit }) {
    commit("setQuickEditActive", false);
  },
};
