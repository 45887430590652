import { createApp } from "vue";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import VueMixpanel from "vue-mixpanel";
import { Papa } from "papaparse";
// import moment from "moment"; // Importing 'moment' directly
// import slugify from "slugify"; // Importing 'slugify' directly
import App from "./App.vue";
import "./styles/app.css"; // Here
import store from "./store/index";
import router from "./router";
import qs from "qs";
/**
 * @type {import('stripe').Stripe.PaymentMethod}
 */
// const paymentMethod = {};
// paymentMethod.card.

// UI ELEMENTS
import TheSidebar from "./components/ui/navigation/admin/TheSidebar";
import TheFooter from "./components/ui/navigation/TheFooter";
import BoldSDK from "@boldxchange/sdk";

const app = createApp(App);

let boldClient = null;

app.mixin({
  inject: ["mixpanel"],
  computed: {
    isUserAnonymous() {
      // console.log("TOKEN:", this.$store.state.auth.token);
      return (
        !this.$store.state.auth.token || this.$store.state.auth.token === "null"
      );
    },
    isBoldXchangeAdmin() {
      //returns true if user is logged in and has higher access
      return (
        this.$store.state.auth.user &&
        this.$store.state.auth.user.role.indexOf("boldxchange/") === 0
      );
    },
    isGiftingLead() {
      return this?.$store?.state?.auth?.user?.role === "giftingLead";
    },
    isGiftCardRecipient() {
      return this?.$store?.state?.auth?.user?.role === "recipient";
    },
    Role() {
      return this?.$store?.state?.auth?.user?.role || "anonymous";
    },
  },

  methods: {
    $logout() {
      this.$store.commit("auth/LOGOUT");
      this.$router.push({ path: "/login" });
    },
    refreshClient() {
      boldClient = null;
      return this.getBoldClient();
    },
    async getBoldClient() {
      if (boldClient) return boldClient;
      else {
        // alert("Getting bold client");
        // console.log("call trail", new Error().stack);
        const settings = {
          definition: process.env.VUE_APP_API_BASE_URL + "/api/v2/api-docs",
          axios: {
            baseURL: process.env.VUE_APP_API_BASE_URL + "/api/v2",
            paramsSerializer: qs.stringify,
          },
        };
        const isAnon = this.isUserAnonymous;
        // console.log("Is anon?", isAnon, this.isUserAnonymous);
        if (!isAnon) {
          // console.log("auth token:", this.$store.state.auth.token === "null");
          settings.axios.headers = {
            authorization: "Bearer " + this.$store.state.auth.token,
          };
        }
        // console.log("Client settings:", { settings });
        const ret = await BoldSDK.createClient(settings).then((r) => {
          // r.api.getAxiosInstance().
          // const inst = axios.create(settings.axios);
          // const inst = r.api.getAxiosInstance();
          r.interceptors.response.use(
            (res) => {
              return res;
            },
            (err) => {
              const res = err.response;
              console.log("Got a response:", res);
              // this.$showError(res);
              if (res.data) {
                const { status, code } = res.data;
                switch (status) {
                  case "bad_token":
                  case "jwt_expired":
                    this.$logout();
                    break;
                  default:
                    break;
                }
              }
              return Promise.reject(err);
            }
          );
          // inst.interceptors.request.use(req => {
          //   // alert('wonk');
          //   return req;
          // })
          // r.api.registerRunner({
          //   runRequest: (cfg) => inst.request(cfg),
          // });
          singleton.boldClient = boldClient = r;
          console.log("r", r);
          return r;
        });

        return ret;
      }
    },
  },
});

// Registering plugins and components...
app.use(store);
app.use(router);
app.use(BootstrapIconsPlugin);
app.use(Papa);
app.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  config: {
    debug: true,
  },
});

// Registering global components...
app.component("the-sidebar", TheSidebar);
app.component("the-footer", TheFooter);

app.mount("#app");

// Importing CSS files...
import "./assets/css/docs.min.css";
import "./assets/css/snippets.min.css";
import "./assets/css/theme.min.css";
import singleton from "./singleton";
