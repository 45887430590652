<template>
  <template v-if="showLandingPagePreview">
    <Teleport to="#modal">
      <TheModal
        @closeModal="toggleLandingPagePreview"
        :open="showLandingPagePreview"
        :closeOnClickOutside="true"
      >
        <LandingPageContent
          :accountSettingsPreview="true"
          @closeModal="toggleLandingPagePreview"
        ></LandingPageContent>
      </TheModal>
    </Teleport>
  </template>
  <TheCard class="tw-overflow-visible tw-w-full">
    <CardHeader
      :showTitle="true"
      :showSubheading="true"
      :showActions="true"
      :showBorder="true"
      title="Landing Page Settings"
    >
      <template #subheading-content>
        <p class="tw-m-0 tw-text-sm tw-text-gray-800 tw-w-sm tw-max-w-xl">
          Make your gift redemption experience truly personal with some custom
          brand styling and messaging. The landing page includes your company
          logo, your profile photo, and a gift title and message.
        </p>
      </template>
      <template #card-header-actions>
        <template v-if="!isEditingDetails">
          <TheButton
            variant="primary"
            size="small"
            @click="editMemberDetails()"
            :hasLeadingIcon="true"
          >
            <template #leading-icon><b-icon-pencil /></template>
            <template #text> Edit </template>
          </TheButton>
        </template>
        <template v-if="isEditingDetails">
          <div class="tw-flex tw-gap-2">
            <TheButton
              :disabled="isSaving"
              variant="success"
              size="small"
              @click="upsertUser()"
            >
              <template #text> Save </template>
            </TheButton>
            <TheButton
              variant="tertiary"
              size="small"
              @click="cancelEdit()"
              :hasLeadingIcon="true"
            >
              <template #text> Cancel </template>
            </TheButton>
          </div>
        </template>
      </template>
    </CardHeader>
    <TheBox :withPadding="true">
      <BlockStack :gap="400">
        <div
          class="sm:tw-flex tw-items-start tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
        >
          <div class="tw-w-full">
            <TheButton
              :disabled="isEditingDetails"
              variant="secondary"
              size="small"
              @click="toggleLandingPagePreview()"
            >
              <template #text> Preview your setup </template>
            </TheButton>
          </div>
        </div>
        <div
          class="sm:tw-flex tw-items-start tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
        >
          <div
            class="tw-flex tw-items-center tw-gap-2 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
          >
            <p class="tw-text-gray-900 tw-m-0">Logo</p>
          </div>
          <BlockStack :gap="400" class="tw-w-full">
            <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2 tw-w-full">
              <p class="tw-text-gray-900 tw-m-0">
                Show <span class="tw-font-bold">[ORGANIZATION LOGO]</span>? You
                can update this value in your Profile Settings tab.
              </p>
              <BlockStack :gap="100">
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings
                          .organizationLogo.show
                      "
                      :value="true"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">Yes</span>
                  </label>
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings
                          .organizationLogo.show
                      "
                      :value="false"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700"
                      >No (default to Bold Xchange Logo)</span
                    >
                  </label>
                </div>
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings
                      ?.organizationLogo.show
                  "
                >
                  <div class="tw-bg-orange-100 tw-p-2 tw-w-full">
                    <p class="tw-m-0">
                      A default image is used if organization logo is missing.
                    </p>
                  </div>
                </template>
              </BlockStack>
            </BlockStack>
          </BlockStack>
        </div>
        <div class="sm:tw-flex tw-items-start">
          <div
            class="tw-flex tw-items-center tw-gap-2 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
          >
            <p class="tw-text-gray-900 tw-m-0">Gift Note Signature</p>
          </div>
          <BlockStack :gap="400" class="tw-w-full">
            <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2 tw-w-full">
              <p class="tw-text-gray-900 tw-m-0">
                Show <span class="tw-font-bold">[PROFILE PHOTO]</span>? You can
                update this value in your Profile Settings tab.
              </p>
              <BlockStack :gap="100">
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .profilePhoto.show
                      "
                      :value="true"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">Yes</span>
                  </label>
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .profilePhoto.show
                      "
                      :value="false"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">No (default)</span>
                  </label>
                </div>
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.profilePhoto?.show
                  "
                >
                  <div class="tw-bg-orange-100 tw-p-2 tw-w-full">
                    <p class="tw-m-0">
                      This section is hidden if profile photo is missing.
                    </p>
                  </div>
                </template>
              </BlockStack>
            </BlockStack>
            <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2 tw-w-full">
              <p class="tw-text-gray-900 tw-m-0">
                Show <span class="tw-font-bold">[JOB TITLE]</span>? You can
                update this value in your Profile Settings tab.
              </p>
              <BlockStack :gap="100">
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .jobTitle.show
                      "
                      :value="true"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">Yes</span>
                  </label>
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .jobTitle.show
                      "
                      :value="false"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">No (default)</span>
                  </label>
                </div>
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.jobTitle?.show
                  "
                >
                  <div class="tw-bg-orange-100 tw-p-2 tw-w-full">
                    <p class="tw-m-0">
                      This section is hidden if job title is missing.
                    </p>
                  </div>
                </template>
              </BlockStack>
            </BlockStack>
            <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2 tw-w-full">
              <p class="tw-text-gray-900 tw-m-0">
                Show <span class="tw-font-bold">[ORGANIZATION NAME]</span>? This
                can be updated in your Profile Settings tab.
              </p>
              <BlockStack :gap="100">
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .organizationName.show
                      "
                      :value="true"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">Yes</span>
                  </label>
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .organizationName.show
                      "
                      :value="false"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">No (default)</span>
                  </label>
                </div>
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.organizationName?.show
                  "
                >
                  <div class="tw-bg-orange-100 tw-p-2 tw-w-full">
                    <p class="tw-m-0">
                      This section is hidden if organization name is missing.
                    </p>
                  </div>
                </template>
              </BlockStack>
            </BlockStack>
            <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2 tw-w-full">
              <p class="tw-text-gray-900 tw-m-0">
                Show <span class="tw-font-bold">[WEBSITE URL]</span>?
              </p>
              <BlockStack :gap="100">
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .websiteUrl.show
                      "
                      :value="true"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">Yes</span>
                  </label>
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .websiteUrl.show
                      "
                      :value="false"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">No (default)</span>
                  </label>
                </div>
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.websiteUrl?.show
                  "
                >
                  <div class="tw-bg-orange-100 tw-p-2 tw-w-full">
                    <p class="tw-m-0">
                      This section is hidden if website URL is missing.
                    </p>
                  </div>
                </template>
              </BlockStack>
            </BlockStack>
            <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2 tw-w-full">
              <p class="tw-text-gray-900 tw-m-0">
                Show <span class="tw-font-bold">[ABOUT DESCRIPTION]</span>?
              </p>
              <BlockStack :gap="100">
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .aboutDescription.show
                      "
                      :value="true"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">Yes</span>
                  </label>
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .aboutDescription.show
                      "
                      :value="false"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">No (default)</span>
                  </label>
                </div>
                <template
                  v-if="
                    userMixinData?.metadata?.landingPageSettings?.signature
                      ?.aboutDescription?.show
                  "
                >
                  <div class="tw-bg-orange-100 tw-p-2 tw-w-full">
                    <p class="tw-m-0">
                      This section is hidden if about description is missing.
                    </p>
                  </div>
                </template>
              </BlockStack>
            </BlockStack>
            <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2 tw-w-full">
              <p class="tw-text-gray-900 tw-m-0">
                Show <span class="tw-font-bold">[ACCOUNT EMAIL]</span>?
              </p>
              <BlockStack :gap="100">
                <div class="tw-flex tw-items-center tw-space-x-4">
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .accountEmail.show
                      "
                      :value="true"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">Yes</span>
                  </label>
                  <label class="tw-flex tw-items-center tw-space-x-2">
                    <input
                      type="radio"
                      v-model="
                        userMixinData.metadata.landingPageSettings.signature
                          .accountEmail.show
                      "
                      :value="false"
                      class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                      :disabled="!isEditingDetails"
                    />
                    <span class="tw-text-gray-700">No (default)</span>
                  </label>
                </div>
              </BlockStack>
            </BlockStack>
          </BlockStack>
        </div>
      </BlockStack>
    </TheBox>
  </TheCard>
</template>

<script>
import TheBox from "../../components-v2/ui/layout/TheBox";
import TheCard from "../../components-v2/ui/layout/TheCard";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import CardHeader from "../../components-v2/ui/layout/CardHeader";
import TheButton from "../ui/actions/TheButton.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import LandingPageContent from "../../components-v2/ui/modals/LandingPageContent.vue";
import userMixin from "../../mixins/userMixin";

export default {
  name: "UserLandingPageSettings",
  components: {
    LandingPageContent,
    TheModal,
    BlockStack,
    TheButton,
    TheBox,
    TheCard,
    CardHeader,
  },
  mixins: [userMixin],
  data() {
    return {
      showLandingPagePreview: false,
      isEditingDetails: false,
      isSaving: false,
    };
  },
  async created() {
    // await this.handleCreatedLifecycleLogic();
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    landingPageTooltipContent() {
      return "When this is enabled, your gift recipients will see a custom gift note from you when they land on the Bold Xchange app. This is disabled by default.";
    },
    giftNoteTooltipContent() {
      return "You can edit or customize this for each campaign, or use the default to save time and maintain a cohesive tone across similar campaigns.";
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async upsertUser() {
      this.isSaving = true;
      const api = await this.getBoldClient();

      const memberToUpdate = {
        "metadata.landingPageSettings.organizationLogo.show":
          this.userMixinData.metadata.landingPageSettings.organizationLogo.show,

        "metadata.landingPageSettings.signature.profilePhoto.show":
          this.userMixinData.metadata.landingPageSettings.signature.profilePhoto
            .show,

        "metadata.landingPageSettings.signature.jobTitle.show":
          this.userMixinData.metadata.landingPageSettings.signature.jobTitle
            .show,

        "metadata.landingPageSettings.signature.organizationName.show":
          this.userMixinData.metadata.landingPageSettings.signature
            .organizationName.show,

        "metadata.landingPageSettings.signature.websiteUrl.show":
          this.userMixinData.metadata.landingPageSettings.signature.websiteUrl
            .show,

        "metadata.landingPageSettings.signature.accountEmail.show":
          this.userMixinData.metadata.landingPageSettings.signature.accountEmail
            .show,

        "metadata.landingPageSettings.signature.aboutDescription.show":
          this.userMixinData.metadata.landingPageSettings.signature
            .aboutDescription.show,
      };

      try {
        await api.upsertUser(null, {
          filter: { _id: this.User?._id },
          patch: memberToUpdate,
        });
        this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },
    toggleLandingPagePreview() {
      this.showLandingPagePreview = !this.showLandingPagePreview;
    },
    editMemberDetails() {
      this.isEditingDetails = !this.isEditingDetails;
    },
    cancelEdit() {
      this.initializeForm(this.User);
      this.isEditingDetails = false;
    },

    /** handle success and errors */
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
    handleSuccess() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Profile successfully updated.",
        duration: 6000,
        id: Date.now(),
      });
      this.isEditingDetails = false;
      this.refreshUser();
    },
  },
};
</script>

<style scoped></style>
