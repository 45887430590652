<template>
  <ThePage :fullWith="true" class="tw-bg-stone-100 tw-h-screen">
    <!-- Content -->
    <!-- <div class="container-fluid content-space-3 animated fadeInUp"> -->
    <BlockStack
      :gap="800"
      class="tw-flex tw-pt-20 tw-text-center tw-items-center tw-justify-start tw-mx-auto"
    >
      <template v-if="targetRoute">
        <div>
          <h1
            class="tw-m-0 tw-text-2xl tw-max-w-xl tw-font-bold sm:tw-text-4xl tw-text-center tw-text-blue-900 sm:tw-tracking-tight"
          >
            Login to claim your gift card
          </h1>
          <p
            class="tw-pointer-events-none tw-block tw-text-lg tw-font-medium tw-m-0 tw-text-gray-700"
          >
            For your safety and convenience
          </p>
        </div>
      </template>
      <template v-else
        ><h1
          class="tw-m-0 tw-text-2xl tw-max-w-xl tw-font-bold sm:tw-text-4xl tw-text-center tw-text-blue-900 sm:tw-tracking-tight"
        >
          Craft gifting campaigns fast that build pipeline and close deals.
        </h1></template
      >

      <div class="">
        <TheCard class="tw-min-w-96 tw-max-w-96">
          <div class="card-body pt-0">
            <div class="card-header d-flex justify-content-center py-5">
              <img
                style="max-width: 10rem"
                src="../../assets/svg/logos/Bold_Xchange_PrimaryLogo_Black.svg"
                alt="Image Description"
              />
            </div>
            <!-- Form -->
            <form
              class="js-validate needs-validation"
              novalidate
              v-if="!wantsToSignInWithEmail && !wantsToResetPassword"
            >
              <!-- heading -->
              <div class="my-5">
                <h1
                  class="tw-m-0 tw-text-xl tw-font-bold sm:tw-text-2xl tw-text-center tw-text-gray-900 sm:tw-tracking-tight"
                >
                  Welcome!
                </h1>
                <p
                  class="tw-pointer-events-none tw-block tw-text-lg tw-font-medium tw-m-0 tw-text-gray-700"
                >
                  Enter your email to continue
                </p>
              </div>

              <div class="row">
                <div class="col mb-3 mb-lg-0 text-start">
                  <!-- email -->
                  <div class="mb-1">
                    <input
                      v-model="email"
                      :disabled="!isPasswordless"
                      type="email"
                      class="form-control form-control-lg"
                      name="email"
                      id="signupSrEmail"
                      placeholder="Enter your email address"
                      aria-label="Enter your email address"
                      required
                    />
                    <p v-if="!doesUserExist" class="text-danger mt-1">
                      We coudn't find a user with that email. Please make sure
                      it's correct and try again.
                    </p>
                  </div>
                  <!-- check if password exists -->
                  <div class="d-flex flex-column mt-2" v-if="isPasswordless">
                    <button
                      v-if="!isLoading"
                      @click.prevent="checkPasswordless()"
                      type="submit"
                      class="btn btn-primary btn-lg clickable"
                    >
                      Continue
                    </button>
                    <!-- <div class="text-center">
                      <router-link to="/" class="btn btn-link clickable">
                        <b-icon-chevron-left></b-icon-chevron-left> Back to
                        Catalog
                      </router-link>
                    </div> -->
                  </div>
                </div>
              </div>
            </form>
            <!-- if email is valid and password exists -->
            <div v-if="wantsToSignInWithEmail">
              <div class="my-5">
                <h1 class="display-6 text-primary">Welcome!</h1>
                <p>Please log in to continue.</p>
              </div>
              <div class="mb-1">
                <input
                  v-model="email"
                  :disabled="!isPasswordless"
                  type="email"
                  class="form-control form-control-lg"
                  name="email"
                  id="signupSrEmail"
                  placeholder="Enter your email address"
                  aria-label="Enter your email address"
                  required
                />
                <p v-if="!doesUserExist" class="text-danger mt-1">
                  We coudn't find a user with that email. Please make sure it's
                  correct and try again.
                </p>
              </div>
              <UserLogin
                @showResetPassword="showResetPassword()"
                :userEmail="email"
              ></UserLogin>

              <div class="text-center">
                <button
                  class="btn btn-link clickable"
                  @click.prevent="showOTPComponent()"
                >
                  <b-icon-key></b-icon-key> Login with a One-Time-Passcode
                </button>
              </div>
            </div>
            <div v-if="wantsToResetPassword">
              <ResetPassword
                :userEmail="email"
                @showResetPassword="showResetPassword()"
              ></ResetPassword>
            </div>
            <!-- if email is valid and password does not exist -->
            <div v-if="showOTP">
              <OnetimePasscodeLogin
                @signInWithEmail="signInWithEmail()"
                @showOTPComponent="showOTPComponent()"
                @showNoUserMessage="showNoUserMessage()"
                @restartLoginProcess="restartLoginProcess()"
                :userEmail="email"
              ></OnetimePasscodeLogin>
            </div>

            <!-- End Form -->
          </div>
          <div class="card-footer bg-warning" v-if="!doesEmailExist">
            <!-- login error -->
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 ms-3 text-start">
                <h3 class="text-black mb-1">User does not have password</h3>
                <p class="mb-0">
                  Try entering your information again, or
                  <router-link class="form-label-link mb-0" to="/reset-password"
                    >resetting your password</router-link
                  >.
                </p>
              </div>
            </div>
          </div>
        </TheCard>
      </div>
      <!-- </div> -->
    </BlockStack>
  </ThePage>

  <!-- End Content -->
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import UserLogin from "./UserLogin.vue";
import OnetimePasscodeLogin from "./OnetimePasscodeLogin.vue";
import ResetPassword from "./ResetPassword.vue";

export default {
  name: "LoginPortal",
  components: {
    UserLogin,
    OnetimePasscodeLogin,
    ResetPassword,
    ThePage,
    BlockStack,
    TheCard,
  },
  create() {},
  computed: {
    targetRoute() {
      return this?.$store?.state?.auth?.targetRoute?.fullPath?.includes(
        "/gift"
      );
    },
  },
  methods: {
    // Method to redirect to the extracted URL after successful login
    redirectToOriginalUrl() {
      if (this.redirectUrl) {
        window.location.href = decodeURIComponent(this.redirectUrl);
      } else {
        // Navigate to a default route if no specific redirect URL was provided
        this.$router.push("/defaultRoute");
      }
    },
    async checkPasswordless() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      const email = this.email;
      this.doesUserExist = true;
      this.isLoading = true;

      await api
        .checkPasswordless(null, { email })
        .then((response) => {
          // this.kitsAreLoading = false;
          const result = response.data;
          if (result) {
            // console.log("checkPasswordless result", result);
            this.isPasswordless = true; // doesn't have password prompt to OTP
            this.showOTPComponent();
          } else {
            // console.log("checkPasswordless result", result);
            this.isPasswordless = false; // does have password prompt to add password
            this.signInWithEmail();
            // this.showOTP = true;
            // setTimeout(() => {
            //   this.doesUserExist = false;
            // }, 5000);
          }
        })
        .catch((error) => {
          console.error("Login error:", error);
          this.isPasswordless = false;
        });
    },
    signInWithEmail() {
      this.showOTP = false;
      this.wantsToSignInWithEmail = true;
      this.isLoading = false;
      this.isPasswordless = true;
    },
    showOTPComponent() {
      // console.log("SHOW OTP CMP");
      this.wantsToSignInWithEmail = false;
      this.isLoading = false;
      this.isPasswordless = false;
      this.showOTP = true;
    },
    showNoUserMessage() {
      this.isLoading = false;
      this.isPasswordless = true;
      this.doesUserExist = false;
      setTimeout(() => {
        this.doesUserExist = true;
      }, 5000);
      this.showOTP = false;
    },
    restartLoginProcess() {
      this.wantsToSignInWithEmail = false;
      this.showOTP = false;
      this.isPasswordless = true;
    },
    showResetPassword() {
      this.wantsToResetPassword = !this.wantsToResetPassword;
      this.wantsToSignInWithEmail = !this.wantsToSignInWithEmail;
    },
  },
  data() {
    return {
      wantsToResetPassword: false,
      wantsToSignInWithEmail: false,
      isLoading: false,
      doesUserExist: true,
      doesEmailExist: true,
      isPasswordless: true,
      showOTP: false,
      email: "",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
