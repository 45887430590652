<template>
  <div
    class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 lg:tw-grid-cols-4"
  >
    <div
      v-for="stat in stats"
      :key="stat.name"
      class="tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-shadow tw-w-full"
    >
      <!-- header -->
      <div class="tw-p-4 tw-w-full">
        <h3
          class="tw-m-0 tw-text-lg tw-font-medium sm:tw-text-xl sm:tw-tracking-tight tw-text-wrap"
        >
          {{ stat.name }}
        </h3>

        <p
          class="tw-text-xs tw-bg-slate-50 tw-inline-block tw-font-bold tw-px-2 tw-py-1"
        >
          <template v-if="stat.type === 'clicked'">
            {{ totalClicksCount }} clicks
          </template>
          <template v-if="stat.type === 'redeemed'">
            {{ totalRedeemedCount }} redemptions
          </template>
        </p>
      </div>
      <!-- body -->
      <div class="tw-flex-1 tw-w-full">
        <!-- box -->
        <div class="tw-px-4 tw-pt-0 tw-pb-4 tw-h-full tw-flex tw-items-end">
          <template v-if="stat.type === 'campaigns'">
            <div class="tw-flex tw-flex-col tw-items-start">
              <p
                class="tw-inline-flex tw-items-center tw-rounded-md tw-border-solid tw-border-2 tw-border-indigo-400 tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-gray-800"
              >
                <span class="tw-font-bold tw-mr-1">{{
                  totalCampaignsCreatedCount
                }}</span>
                Campaigns created
              </p>
              <div class="tw-flex tw-flex-col tw-items-start tw-pl-2">
                <p
                  class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-gray-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-gray-800"
                >
                  <span class="tw-font-bold tw-mr-1">{{
                    totalCampaignsDraftCount
                  }}</span>
                  DRAFT
                </p>
                <p
                  class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-blue-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-blue-800"
                >
                  <span class="tw-font-bold tw-mr-1">{{ totalSentCount }}</span
                  >SENT
                </p>
                <p
                  class="tw-inline-flex tw-items-center tw-rounded-md tw-bg-green-100 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-green-800"
                >
                  <span class="tw-font-bold tw-mr-1">{{
                    totalRedeemedCount
                  }}</span
                  >REDEEMED
                </p>
              </div>
            </div>
          </template>
          <div class="tw-flex tw-items-start">
            <div class="tw-text-4xl tw-font-bold tw-text-gray-900">
              <template v-if="stat.type === 'clicked'">
                {{ averageClickRate }}%
              </template>
              <template v-if="stat.type === 'redeemed'">
                {{ averageRedemptionRate }}%
              </template>
              <template v-if="stat.type === 'spend'">
                ${{ totalSpendCount }}
              </template>
            </div>
            <template v-if="stat.type !== 'campaigns'">
              <TheTooltip :toolTip="true" :tooltipContent="stat.description">
                <template #icon>
                  <div
                    class="tw-w-4 tw-h-4 tw-rounded-full tw-border-solid tw-border tw-border-gray-500 tw-flex tw-items-center tw-justify-center hover:tw-bg-slate-100 tw-me-2 tw-cursor-pointer"
                  >
                    <b-icon-exclamation
                      class="tw-text-black tw-z-0"
                    ></b-icon-exclamation>
                  </div>
                </template>
              </TheTooltip>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import {
  calculateRedeemedCampaignsWithFinalizations,
  calculateTotalClicks,
} from "@/services/campaignService";
export default {
  name: "MemberStats",
  components: { TheTooltip },
  props: {
    member: {
      type: Object,
      required: true,
    },
    // totalCampaignsSentCount: {
    //   type: Number,
    //   default: 0,
    // },
    // totalSpendCount: {
    //   type: Number,
    //   default: 0,
    // },
    // totalCampaignsClickedCount: {
    //   type: Number,
    //   default: 0,
    // },
    // totalCampaignsRedeemedCount: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      stats: [
        {
          type: "campaigns",
          name: "Campaign activity",
          description: "The total number of campaigns sent.",
        },
        {
          type: "clicked",
          name: "Click rate",
          description: "The percentage of campaigns clicked.",
        },
        {
          type: "redeemed",
          name: "Redemption rate",
          description: "The percentage of campaigns redeemed.",
        },
        {
          type: "spend",
          name: "Total spend",
          description: "The total sum of redeemed gift cards.",
        },
      ],
      // new stuff
      campaigns: [],
      recipients: [],
      totalCampaignsCreatedCount: 0,
      totalRecipientsCount: 0,
      totalCampaignsCount: 0,
      totalCampaignsSentCount: 0,
      totalCampaignsDraftCount: 0,
      totalCampaignsSentWithSeenBoolean: 0,
      totalCampaignsSentWithSeenArray: 0,
      redeemedCampaignsWithoutFinalizations: 0,
      totalSpendByCampaignsWithoutFinalizations: 0,
      totalSpendByCampaignsWithoutFinalizationsAggregated: 0,
      clicksByCampaignsCountWithSeenBoolean: 0,
      clicksByCampaignsCountWithSeenArray: 0,
      redeemedCampaignsWithFinalizations: 0,
      totalSpendByCampaignsWithFinalizations: 0,
      redeemedCount: 0,
      userHasCampaigns: null,
      loadingDashboardData: true,
      dataLoadedSuccessfully: true,
    };
  },
  watch: {
    userHasCampaigns() {
      if (this.userHasCampaigns) {
        // this.loadData();
      }
    },
  },
  created() {
    this.checkForUserData();
  },
  computed: {
    user() {
      return this?.$store?.state?.auth?.user;
    },
    memberId() {
      return this.$route.params.memberId;
    },
    memberEmail() {
      return this?.member?.email;
    },
    averageClickRate() {
      if (this.totalSentCount === 0) {
        return 0; // or any other appropriate value to indicate no campaigns sent
      }
      return ((this.totalClicksCount / this.totalSentCount) * 100).toFixed(1);
    },
    averageRedemptionRate() {
      if (this.totalSentCount === 0) {
        return 0; // or any other appropriate value to indicate no campaigns sent
      }
      return ((this.totalRedeemedCount / this.totalSentCount) * 100).toFixed(1);
    },

    // new stuff
    totalSpendCount() {
      return (
        this.totalSpendByCampaignsWithoutFinalizationsAggregated +
        this.totalSpendByCampaignsWithFinalizations
      );
    },
    totalSentCount() {
      return (
        this.totalCampaignsSentWithSeenBoolean +
        this.totalCampaignsSentWithSeenArray
      );
    },
    totalRedeemedCount() {
      return (
        this.redeemedCampaignsWithoutFinalizations +
        this.redeemedCampaignsWithFinalizations
      );
    },
    totalClicksCount() {
      return (
        this.clicksByCampaignsCountWithSeenArray +
        this.clicksByCampaignsCountWithSeenBoolean
      );
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** check for user data */
    async checkForUserData() {
      this.loadingDashboardData = true;
      try {
        await this.checkIfUserHasCampaigns();
        // If user has campaigns, dashboard stats data
        if (this.userHasCampaigns) {
          await Promise.all([
            this.loadDraftCampaigns(),
            this.loadRedeemedCampaignsWithoutFinalizations(),
            this.loadTotalSpendByRedeemedCampaignsWithoutFinalizations(),
            this.loadRedeemedCampaignsWithFinalizations(),
            this.loadCampaignsWhereSeenIsArray(),
            this.loadCampaignsWhereSeenIsBoolean(),
          ]);
        }
        this.dataLoadedSuccessfully = true;
      } catch (error) {
        console.log(error);
        this.handleErrorFromAPI(
          error,
          "We couldn't load your dashboard data. Please reload the page and try again."
        );
      } finally {
        this.loadingDashboardData = false;
      }
    },

    /** handle check if user has recipient */
    async checkIfUserHasRecipients() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let requestBody = {};
      let filter = {};

      filter = {
        "metadata.giftingLeads": { $in: [this.memberId] },
      };

      let selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: 1,
        expand: "contact campaigns user",
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);

        const count = response.data.result.count;
        this.totalRecipientsCount = count;
        this.recipients = response.data.result.records;
        this.checkingIfUserHasRecipients = false;
      } catch (error) {
        this.checkingIfUserHasRecipients = false;
        console.log("recipients did not load", error);
      }
    },

    /** handle checking if user has campaigns */
    async checkIfUserHasCampaigns() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let filter = {};
      filter = {
        "user._id": {
          $eq: this.memberId,
        },
        "metadata.campaign_template": { $exists: false },
      };

      const queryParameters = {
        limit: 1,
        expand: "recipients user",
      };

      const requestBody = {
        filter: filter,
      };

      const response = await api.listMailCampaigns(
        queryParameters,
        requestBody
      );
      const count = response.data.result.count;
      const campaigns = response.data.result.records;

      this.checkIfUserHasRecipients();
      this.totalCampaignsCreatedCount = count;
      this.campaigns = campaigns;
      this.userHasCampaigns = count;
      return count;
    },
    /** handle loading dashboard stats data */
    async loadDraftCampaigns() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let filter = {};
      filter = {
        "user._id": {
          $eq: this.memberId,
        },
        "metadata.campaign_template": { $exists: false },
        "metadata.campaign_status": { $eq: "draft" },
      };

      const queryParameters = {
        limit: 1,
        expand: "recipients user",
      };

      const requestBody = {
        filter: filter,
      };

      const response = await api.listMailCampaigns(
        queryParameters,
        requestBody
      );
      this.totalCampaignsDraftCount = response.data.result.count;
    },
    async loadRedeemedCampaignsWithoutFinalizations() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      const api = await this.getBoldClient();

      let filter = {
        "user._id": {
          $eq: this.memberId,
        },
        "metadata.campaign_template": { $exists: false },
        $or: [
          {
            "metadata.campaign_status": {
              $eq: "redeemed",
            },
          },
          {
            "metadata.isRedeemed": true,
          },
        ],
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients user",
      };

      const requestBody = {
        filter: filter,
      };

      const response = await api.listMailCampaigns(
        queryParameters,
        requestBody
      );
      const redeemedCount = response.data.result.count;
      this.redeemedCampaignsWithoutFinalizations = redeemedCount;
    },
    async loadTotalSpendByRedeemedCampaignsWithoutFinalizations() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let filter = {
        "user._id": {
          $eq: this.memberId,
        },
        "metadata.campaign_template": { $exists: false },
        $or: [
          {
            "metadata.campaign_status": {
              $eq: "redeemed",
            },
          },
          {
            "metadata.isRedeemed": true,
          },
        ],
      };

      let group = {
        _id: null,
        selection: {
          "metadata.gift_card_amount": "$sum",
        },
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients user metadata",
      };

      const requestBody = {
        filter: filter,
        group: group,
      };

      const totalSpendResponse = await api.listMailCampaigns(
        queryParameters,
        requestBody
      );
      this.totalSpendByCampaignsWithoutFinalizationsAggregated =
        totalSpendResponse?.data?.result?.records[0]
          ?.metadata_gift_card_amount__sum || 0;
    },
    async loadRedeemedCampaignsWithFinalizations() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let filter = {
        "user._id": {
          $eq: this.memberId,
        },
        "metadata.campaign_template": { $exists: false },
        "metadata.finalizations": { $exists: true },
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients user",
      };

      const requestBody = {
        filter: filter,
      };
      ///

      const response = await api.listMailCampaigns(
        queryParameters,
        requestBody
      );
      const campaigns = response.data.result.records;
      let finalizedCounts =
        calculateRedeemedCampaignsWithFinalizations(campaigns);
      this.redeemedCampaignsWithFinalizations =
        finalizedCounts.totalFinalizations;
      this.totalSpendByCampaignsWithFinalizations = finalizedCounts.totalSpend;
    },
    async loadCampaignsWhereSeenIsArray() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let filter = {
        "user._id": {
          $eq: this.memberId,
        },
        "metadata.campaign_template": { $exists: false },
        $and: [
          { seen: { $exists: true } },
          { seen: { $type: "array" } }, // { seen: { $ne: [] } },
          { "metadata.campaign_status": { $ne: "draft" } },
        ],
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients user",
      };

      const requestBody = {
        filter: filter,
      };

      ///

      const response = await api.listMailCampaigns(
        queryParameters,
        requestBody
      );
      const campaignsWithSeenArray = response.data.result.records;

      this.clicksByCampaignsCountWithSeenArray = calculateTotalClicks(
        campaignsWithSeenArray
      ).totalClicks;

      this.totalCampaignsSentWithSeenArray = calculateTotalClicks(
        campaignsWithSeenArray
      ).totalRecipients;
    },
    async loadCampaignsWhereSeenIsBoolean() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      let filter = {
        "user._id": {
          $eq: this.memberId,
        },
        "metadata.campaign_template": { $exists: false },
        $and: [
          {
            $or: [
              {
                "metadata.campaign_status": {
                  $eq: "sent",
                },
              },
              {
                "metadata.campaign_status": {
                  $eq: "redeemed",
                },
              },
            ],
          },
          {
            seen: {
              $eq: true,
            },
          },
          {
            $or: [
              { "metadata.isRedeemed": { $exists: false } },
              { "metadata.isRedeemed": true },
            ],
          },
        ],
      };

      const queryParameters = {
        limit: 10000,
        expand: "recipients user",
      };

      const requestBody = {
        filter: filter,
      };

      ///

      const response = await api.listMailCampaigns(
        queryParameters,
        requestBody
      );
      const count = response.data.result.count;
      this.totalCampaignsSentWithSeenBoolean = count;
      this.clicksByCampaignsCountWithSeenBoolean = count;
    },

    getCurrentDateISO() {
      const currentDate = new Date();
      return currentDate.toISOString();
    },

    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error.response) {
        // Process API error message if customMessage is not provided
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.source.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      this.creatingCampaignDraft = false;
      this.dataLoadedSuccessfully = false;
      this.loadingDashboardData = false;
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-square-container {
  flex: 1 1 auto;
  max-width: 150px;
  max-height: 150px;
  width: min(150px, 150px);
  height: min(150px, 150px);
  padding: 5px;
}

.flex-square-container h1 {
  font-size: 2.5rem;
}
</style>
