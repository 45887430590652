// src/api/getBoldClient.js
import axios from "axios";
import BoldSDK from "@boldxchange/sdk";
import qs from 'qs';
let boldClient = null;

async function getBoldClient(token = null) {
    const settings = {
        definition: process.env.VUE_APP_API_BASE_URL + "/api/v2/api-docs",
        axios: {
            baseURL: process.env.VUE_APP_API_BASE_URL + "/api/v2",
            headers: {},
            paramsSerializer: qs.stringify
        },
    };

    if (token) {
        settings.axios.headers['Authorization'] = `Bearer ${token}`;
    }

    if (!boldClient) {
        boldClient = await BoldSDK.createClient(settings);
        // const inst = axios.create(settings.axios);
        boldClient.interceptors.response.use((res) => {
            return res;
        },
            (err) => {
                const res = err.response;
                console.log("Got a response:", res);
                // this.$showError(res);
                if (res.data) {
                    const { status, code } = res.data;
                    switch (status) {
                        case "bad_token":
                        case "jwt_expired":
                            this.$logout();
                            break;
                        default:
                            break;
                    }
                }
                return Promise.reject(err);
            });

        // boldClient.api.registerRunner({
        //     runRequest: (cfg) => inst.request(cfg),
        // });
    }

    return boldClient;
}

export { getBoldClient };
