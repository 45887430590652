<template>
  <template v-if="stripeFormIsLoading">
    <LoadingGiftIcon></LoadingGiftIcon>
  </template>
  <template v-else>
    <div id="embedded-stripe-checkout"></div>
  </template>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import LoadingGiftIcon from "../../../components/ui/LoadingGiftIcon.vue";
export default {
  emits: ["user", "displayLogin"],
  data() {
    return {
      stripeFormIsLoading: true,
    };
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    subscription: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    try {
      await this.signUpForGcMarketplace();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async signUpForGcMarketplace() {
      const api = await this.getBoldClient();

      const returnUrl =
        window.location.origin +
        "/subscription-payment/success/{CHECKOUT_SESSION_ID}";

      try {
        const signupRes = await api.signUpForGcMarketplace(null, {
          successUrl:
            process.env.VUE_APP_API_BASE_URL + "/blah/{CHECKOUT_SESSION_ID}",
          returnUrl: returnUrl,
          cancelUrl:
            process.env.VUE_APP_API_BASE_URL +
            "/api/v2/subscriptions/gc-marketplace/setup-failure",
          email: this.email,
          subscription: this.subscription,
        });

        this.stripeFormIsLoading = false;
        const pk_token =
          process.env.VUE_APP_STRIPE_PK_TOKEN ||
          "pk_test_oh42NW0apWNyMeNSD6WccpiJ";
        const stripe = await loadStripe(pk_token);
        const checkout = await stripe.initEmbeddedCheckout({
          clientSecret: signupRes.data.secret,
        });

        console.log("checkout", checkout);
        checkout.mount("#embedded-stripe-checkout");
      } catch (error) {
        this.stripeFormIsLoading = false;
        if (error.response.status === 401) {
          console.log("Unauthorized: Invalid credentials or token.");
          this.$emit("displayLogin");
        } else {
          console.log("error: ", error);
        }
      }
    },
  },
  components: { LoadingGiftIcon },
};
</script>
