<template>
  <div></div>
  <!-- <StripePaymentForm></StripePaymentForm> -->
</template>

<script>
export default {
  name: "PaymentSetupFailure",
  data() {
    return {};
  },
  async mounted() {
    // this.stripeRes = await this.signUpForGcMarketplace();
  },
  methods: {
    routeToAccount() {
      this.$router.push({ path: "/account" });
    },
  },
};
</script>

<style scoped></style>
