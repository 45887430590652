<template>
  <template v-if="isCampaignBuilder">
    <template v-if="!isViewingSelection">
      <div class="tw-border-blue-400 tw-bg-yellow-400/20 tw-p-2 sm:tw-px-8">
        <div class="tw-flex tw-items-center tw-flex-wrap tw-gap-y-4">
          <div class="tw-flex tw-items-center tw-gap-3 tw-flex-grow">
            <ToggleButton
              :toggleOn="isToggled"
              @click="handleToggle"
            ></ToggleButton>
            <div class="tw-flex tw-items-center tw-gap-2">
              <p
                class="tw-text-sm tw-font-bold tw-text-gray-900 tw-text-center tw-m-0"
              >
                {{ !this.isToggled ? "Custom selection" : "Recipients choice" }}
              </p>
              <TheTooltip
                :toolTip="true"
                :tooltipContent="giftCardSelectionTooltipContent"
              >
                <template #icon>
                  <div class="tw-cursor-pointer">
                    <b-icon-question-circle
                      class="tw-text-black tw-z-0"
                    ></b-icon-question-circle>
                  </div>
                </template>
              </TheTooltip>
            </div>
          </div>
          <template v-if="getSelectedRegion">
            <TheButton
              class="tw-relative"
              @click="handleTriggerRegionSelection"
              variant="tertiary"
              :hasLeadingIcon="true"
              ><template #text
                ><b-icon-geo-alt></b-icon-geo-alt
                >{{ getSelectedRegion }} region</template
              >
            </TheButton>
          </template>
          <template v-if="!getSelectedRegion">
            <TheButton
              class="tw-relative"
              @click="handleTriggerRegionSelection"
              variant="tertiary"
              :hasLeadingIcon="true"
              ><template #text
                ><b-icon-geo-alt></b-icon-geo-alt>Select a region</template
              >
            </TheButton>
          </template>
        </div>
      </div>
    </template>
  </template>
  <template v-if="isGift">
    <div
      class="tw-bg-yellow-400/20 tw-flex tw-items-center tw-justify-between tw-p-2 sm:tw-px-8"
    >
      <p class="tw-text-sm md:tw-text-lg tw-m-0 tw-font-bold tw-text-gray-900">
        Explore the best small businesses—redeem a gift card of your choice!
      </p>
      <div class="">
        <template v-if="selectedRegion">
          <!-- <p class="tw-text-sm tw-m-0 tw-font-bold tw-text-gray-900">
              <b-icon-geo-alt></b-icon-geo-alt> Viewing
              {{ selectedRegion }} region
            </p> -->
          <TheButton
            class="tw-relative"
            @click="handleTriggerRegionSelection()"
            variant="tertiary"
            :hasLeadingIcon="true"
            ><template #text
              ><b-icon-geo-alt></b-icon-geo-alt
              >{{ selectedRegion }} region</template
            >
          </TheButton>
        </template>
        <template v-if="!selectedRegion">
          <!-- <p class="tw-text-sm tw-m-0 tw-font-bold tw-text-gray-900">
              <b-icon-geo-alt></b-icon-geo-alt> Viewing
              {{ selectedRegion }} region
            </p> -->
          <TheButton
            class="tw-relative"
            @click="handleTriggerRegionSelection()"
            variant="tertiary"
            :hasLeadingIcon="true"
            ><template #text
              ><b-icon-geo-alt></b-icon-geo-alt>Select a region</template
            >
          </TheButton>
        </template>
      </div>
    </div>
  </template>
  <template v-if="isGiftCardCollection">
    <div
      class="tw-bg-yellow-400/20 tw-flex tw-items-center tw-justify-between tw-p-2"
    >
      <p class="tw-text-sm md:tw-text-lg tw-m-0 tw-font-bold tw-text-gray-900">
        Explore the best small businesses!
      </p>
      <div class="">
        <template v-if="selectedRegion">
          <!-- <p class="tw-text-sm tw-m-0 tw-font-bold tw-text-gray-900">
              <b-icon-geo-alt></b-icon-geo-alt> Viewing
              {{ selectedRegion }} region
            </p> -->
          <TheButton
            class="tw-relative"
            @click="handleTriggerRegionSelection()"
            variant="tertiary"
            :hasLeadingIcon="true"
            ><template #text
              ><b-icon-geo-alt></b-icon-geo-alt
              >{{ selectedRegion }} region</template
            >
          </TheButton>
        </template>
        <template v-if="!selectedRegion">
          <!-- <p class="tw-text-sm tw-m-0 tw-font-bold tw-text-gray-900">
              <b-icon-geo-alt></b-icon-geo-alt> Viewing
              {{ selectedRegion }} region
            </p> -->
          <TheButton
            class="tw-relative"
            @click="handleTriggerRegionSelection()"
            variant="tertiary"
            :hasLeadingIcon="true"
            ><template #text
              ><b-icon-geo-alt></b-icon-geo-alt>Select a region</template
            >
          </TheButton>
        </template>
      </div>
    </div>
  </template>
  <div>
    <GiftCardsList
      :isCampaignBuilder="isCampaignBuilder"
      :isGift="isGift"
      :custom_selection_ids="custom_selection_ids"
      :campaign="campaign"
      :categoriesFilter="categories"
      :isToggled="isToggled"
      :isRegionSelected="isRegionSelected"
      @updateSelectedGiftCards="updateSelectedGiftCards"
      @toggleSelectionView="toggleSelectionView"
      @handleStepChange="handleStepChange"
      @setSelectedRegion="setSelectedRegion"
      @handleTriggerRegionSelection="handleTriggerRegionSelection"
    >
    </GiftCardsList>
  </div>
</template>

<script>
import ToggleButton from "../../components-v2/ui/actions/ToggleButton";
import TheButton from "../../components-v2/ui/actions/TheButton";
import GiftCardsList from "./GiftCardsList";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";

export default {
  inject: ["mixpanel"],
  name: "GiftCardsGridV2",
  emits: ["updateCampaignCustomSelection", "handleStepChange"],
  props: {
    campaign: {
      type: Object,
      required: false,
    },
    custom_selection_ids: {
      type: [Array, Number],
      required: false,
    },
    isCampaignBuilder: {
      type: Boolean,
      required: false,
    },
    isGift: {
      type: Boolean,
      required: false,
    },
    isGiftCardCollection: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    TheButton,
    GiftCardsList,
    ToggleButton,
    TheTooltip,
  },
  watch: {
    // Watcher for the custom_selection_ids prop
    custom_selection_ids: {
      handler(newVal, oldVal) {
        if (newVal === 1) {
          // alert('alert')
          this.isToggled = true;
        } else if (newVal === 0) {
          this.isToggled = false;
        }
      },
      immediate: true,
    },

    // You can perform any desired actions here based on the new and old values
  },
  data() {
    return {
      categories: [
        "Wine & Spirits",
        "Fine Dining",
        "Casual Dining",
        "Fast Casual",
        "Coffee Shop",
        "Coffee",
        "Cafe",
        "Ice Cream",
        "Desserts",
        "Bakery",
      ],
      selectedGiftCardsCount: 0,
      isToggled: false,
      isRegionSelected: false,
      isViewingSelection: false,
      selectedRegion: null,
    };
  },
  computed: {
    customSelection() {
      return this.selectedGiftCardsCount;
    },
    giftCardSelectionTooltipContent() {
      return !this.isToggled
        ? "Recipients first see your curated list of e-gift cards, but they can always explore and choose any card from the Bold collection."
        : "Recipients get to explore and pick any e-gift card from the Bold collection.";
    },
    getSelectedRegion() {
      return this.selectedRegion;
    },
  },
  methods: {
    /** custom selection */
    updateSelectedGiftCards(selectedCards) {
      console.log("selected cards: ", selectedCards);
      this.selectedGiftCardsCount = selectedCards.length;
      this.$emit("updateCampaignCustomSelection", selectedCards);
      // if (selectedCards.length > 0) {
      //   this.isToggled = false;
      // }
    },
    toggleSelectionView(value) {
      this.isViewingSelection = value;
    },
    handleStepChange(step) {
      this.$emit("handleStepChange", step);
    },
    handleToggle() {
      this.isToggled = !this.isToggled;
      let new_custom_selection_ids;
      if (this.isToggled) {
        new_custom_selection_ids = 1;
        this.$emit("updateCampaignCustomSelection", new_custom_selection_ids);
      } else {
        new_custom_selection_ids = 0;
        this.$emit("updateCampaignCustomSelection", new_custom_selection_ids);
      }
    },
    handleTriggerRegionSelection() {
      this.isRegionSelected = !this.isRegionSelected;
    },
    setSelectedRegion(region) {
      this.selectedRegion = region;
    },
  },
};
</script>

<style scoped></style>
