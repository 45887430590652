// store/modules/notifications/mutations.js
export default {
  SHOW_NOTIFICATION(state, { type, message }) {
    state.notification = {
      visible: true,
      type,
      message,
    };
  },
  CLEAR_NOTIFICATION(state) {
    state.notification = {
      visible: false,
      type: null,
      message: '',
    };
  },
};