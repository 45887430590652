<template>
    <!-- <template v-else> -->
    <button :class="computedClasses" :disabled="disabled || loading" :aria-label="accessibilityLabel"
        :aria-controls="ariaControls" :aria-expanded="ariaExpanded" :aria-describedby="ariaDescribedBy"
        :aria-checked="ariaChecked" :role="role" :id="id" :data-primary-link="dataPrimaryLink" :data-external="external"
        :href="external ? url : null" :target="external ? '_blank' : target" :download="download"
        :type="submit ? 'submit' : 'button'" @click="handleClick">

        <slot name="icon"></slot>
    </button>
    <!-- </template> -->
</template>
  
<script>
export default {
    name: 'CircleButton',
    props: {
        role: {
            type: String,
            default: 'button'
        },
        id: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'regular'
        },
        url: {
            type: [String, Object],
            default: null
        },
        variant: {
            type: String,
            default: 'primary'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        accessibilityLabel: {
            type: String,
            default: ''
        },
        ariaControls: {
            type: String,
            default: ''
        },
        ariaExpanded: {
            type: Boolean,
            default: false
        },
        ariaDescribedBy: {
            type: String,
            default: ''
        },
        ariaChecked: {
            type: String,
            default: 'false'
        },
        dataPrimaryLink: {
            type: Boolean,
            default: false
        },
        external: {
            type: Boolean,
            default: false
        },
        target: {
            type: String,
            default: ''
        },
        download: {
            type: [String, Boolean],
            default: null
        },
        submit: {
            type: Boolean,
            default: false
        },
        onClick: {
            type: Function,
            default: null
        },
        onFocus: {
            type: Function,
            default: null
        },
        onBlur: {
            type: Function,
            default: null
        },
        onKeyPress: Function,
        onKeyUp: Function,
        onKeyDown: Function,
        onMouseEnter: Function,
        onTouchStart: Function,
        onPointerDown: Function
    },
    computed: {
        computedClasses() {
            return {
                // style classes
                ...this.styleClass,
                // size classes
                ...this.sizeClass,

            };
        },
        sizeClass() {
            return {
                'tw-text-xs tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center': this.size === 'small',
                'tw-text-sx tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center': this.size === 'regular',
                'tw-text-lg tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center': this.size === 'large',
            };
        },
        styleClass() {
            return {
                "tw-border-none tw-rounded-full tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-white hover:tw-bg-gray-50 tw-ring-1 tw-ring-gray-300 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-gray-300": this.variant === "tertiary",
                "tw-border-none tw-rounded-full tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-text-gray-900 tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-blue-300": this.variant === "primary",
                "tw-border-none tw-rounded-full tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-text-gray-900 tw-bg-indigo-600 hover:tw-bg-indigo-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-indigo-300": this.variant === "new",
                "tw-border-none tw-rounded-full tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-text-gray-900 tw-bg-red-600 hover:tw-bg-red-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-red-300": this.variant === "critical",
                "tw-border-none tw-rounded-full tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-text-gray-900 tw-bg-green-600 hover:tw-bg-green-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-green-300": this.variant === "success",
            };
        }
    },
    methods: {
        handleClick(event) {
            if (!this.disabled && !this.loading && this.onClick) {
                this.onClick(event);
            }
        }
    }
};
</script>
  
<style scoped>
/* Add your button styles here */
</style>
  