<template>
  <template v-if="isRouterLink">
    <router-link
      :to="url"
      :class="computedClasses"
      :aria-label="accessibilityLabel"
      :aria-controls="ariaControls"
      :aria-expanded="ariaExpanded"
      :aria-describedby="ariaDescribedBy"
      :aria-checked="ariaChecked"
      :role="role"
      :id="id"
      :data-primary-link="dataPrimaryLink"
      :data-external="external"
      :type="submit ? 'submit' : 'button'"
      @click="handleClick"
    >
      <template v-if="hasLeadingIcon">
        <slot name="leading-icon"></slot>
      </template>
      <slot name="text"></slot>
      <template v-if="hasTrailingIcon">
        <slot name="trailing-icon"></slot>
      </template>
      <span v-if="loading" class=""></span>
    </router-link>
  </template>
  <template v-else>
    <button
      :class="computedClasses"
      :disabled="disabled || loading"
      :aria-label="accessibilityLabel"
      :aria-controls="ariaControls"
      :aria-expanded="ariaExpanded"
      :aria-describedby="ariaDescribedBy"
      :aria-checked="ariaChecked"
      :role="role"
      :id="id"
      :data-primary-link="dataPrimaryLink"
      :data-external="external"
      :href="external ? url : null"
      :target="external ? '_blank' : target"
      :type="submit ? 'submit' : 'button'"
      @click="handleClick"
    >
      <template v-if="hasLeadingIcon">
        <slot name="leading-icon"></slot>
      </template>
      <slot name="text"></slot>
      <template v-if="active">
        <slot name="active"></slot>
      </template>

      <template v-if="hasTrailingIcon">
        <slot name="trailing-icon"></slot>
      </template>
      <span v-if="loading" class=""></span>
    </button>
  </template>
</template>

<script>
export default {
  name: "TheButton",
  props: {
    role: {
      type: String,
      default: "button",
    },
    id: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "regular",
    },
    isRouterLink: {
      type: Boolean,
      default: false,
    },
    url: {
      type: [String, Object],
      default: null,
    },
    hasLeadingIcon: {
      type: Boolean,
      default: false,
    },
    hasTrailingIcon: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    accessibilityLabel: {
      type: String,
      default: "",
    },
    ariaControls: {
      type: String,
      default: "",
    },
    ariaExpanded: {
      type: Boolean,
      default: false,
    },
    ariaDescribedBy: {
      type: String,
      default: "",
    },
    ariaChecked: {
      type: String,
      default: "false",
    },
    dataPrimaryLink: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: "",
    },
    submit: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  computed: {
    computedClasses() {
      return {
        // style classes
        ...this.styleClass,
        // size classes
        ...this.sizeClass,
      };
    },
    sizeClass() {
      return {
        "tw-text-xs tw-py-2 tw-px-2 tw-text-center": this.size === "small",
        "tw-text-sm tw-py-2 tw-px-3.5": this.size === "regular",
        "tw-text-lg tw-py-3 tw-px-4": this.size === "large",
      };
    },
    styleClass() {
      return {
        "tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-rounded-md tw-font-bold tw-text-center tw-text-gray-900 tw-bg-white hover:tw-bg-gray-50 tw-ring-1 tw-ring-gray-300 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-gray-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed":
          this.variant === "tertiary",
        "tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-text-gray-900 tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-blue-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed":
          this.variant === "primary",
        "tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-text-gray-900 tw-bg-indigo-600 hover:tw-bg-indigo-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-indigo-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed":
          this.variant === "new",
        "tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-text-gray-900 tw-bg-red-500 hover:tw-bg-red-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-red-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed":
          this.variant === "critical",
        "tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-yellow-400 hover:tw-bg-yellow-500 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-yellow-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed":
          this.variant === "secondary",
        "tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-text-gray-900 tw-bg-green-600 hover:tw-bg-green-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-green-300 disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed":
          this.variant === "success",
          "tw-border-none tw-rounded tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-center tw-text-gray-900 tw-bg-transparent focus:tw-outline-none disabled:tw-bg-gray-100 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed":
          this.variant === "ghost",
      };
    },
  },
  methods: {
    handleClick(event) {
      if (!this.disabled && !this.loading && this.onClick) {
        this.onClick(event);
      }
    },
  },
};
</script>

<style scoped>
/* Add your button styles here */
</style>
