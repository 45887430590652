<template>
  <template v-if="campaignData?.campaigns">
    <template v-if="campaignData.count">

      <div class="tw-flex tw-flex-col tw-gap-2">
        <RedemptionHistoryListItem
        :myGiftsView="myGiftsView"
          :recipient="recipient"
          :recipientDetailsView="recipientDetailsView"
          v-for="campaign in uniqueCampaigns"
          :key="campaign._id"
          :campaign="campaign"
        >
        </RedemptionHistoryListItem>
      </div>
    </template>
    <template v-else>
      <TheCard>
        <EmptyState emptyState="gifts"></EmptyState>
      </TheCard>
    </template>
  </template>
</template>

<script>
import TheCard from "../../components-v2/ui/layout/TheCard";
import RedemptionHistoryListItem from "./RedemptionHistoryListItem.vue";
import EmptyState from "../ui/feedback/EmptyState.vue";

export default {
  name: "RedemptionHistoryList",
  components: {
    RedemptionHistoryListItem,
    TheCard,
    EmptyState,
  },
  props: {
    campaignData: {
      type: Object,
    },
    recipientDetailsView: {
      type: Boolean,
    },
    recipient: {
      type: Object,
      required: false,
    },
    myGiftsView: {
      type: Boolean,
    },
  },
  computed: {
    uniqueCampaigns() {
      const uniqueCampaignMap = new Map();
      this.campaignData.campaigns.forEach(campaign => {
        if (!uniqueCampaignMap.has(campaign._id)) {
          uniqueCampaignMap.set(campaign._id, campaign);
        }
      });
      return Array.from(uniqueCampaignMap.values());
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
