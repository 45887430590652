export default {
  setQuickEditActive(state, isActive) {
    state.quickEditActive = isActive;
  },
  SET_GOOGLE_URL_REDIRECT(state, url){
    state.googleUrlRedirect = url
  }, 
  SET_OFFICE365_URL_REDIRECT(state, url){
    state.office365UrlRedirect = url
  }, 
  SET_SALESFORCE_URL_REDIRECT(state, url){
    state.salesforceUrlRedirect = url
  }, 

};
