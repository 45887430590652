<template>
  <BlockStack :gap="400">
    <template v-if="!isEditingDetails">
      <div class="tw-flex tw-justify-end">
        <TheButton
          type="button"
          variant="primary"
          size="small"
          :disabled="!this.allItemsComplete"
          :hasLeadingIcon="true"
          @click="editGroupDetails()"
        >
          <template #leading-icon><b-icon-pencil /></template>
          <template #text>edit</template>
        </TheButton>
      </div>
    </template>
    <template v-if="showCriteriaOnly">
      <BlockStack :gap="200" class="tw-w-full">
        <label
          for="segmentConditions"
          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
        >
          Conditions<span
            v-if="!filterIsComplete"
            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-white tw-py-1 tw-px-2 tw-rounded"
            >required field</span
          >
        </label>
        <div
          class="tw-flex gap-2 tw-flex-wrap tw-w-full tw-p-2 tw-border tw-border-solid tw-bg-white tw-rounded"
        >
          <BlockStack :gap="600" class="tw-w-full">
            <BlockStack :gap="200" class="tw-w-1/2">
              <label
                for="segmentTags"
                class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
              >
                Criteria
              </label>
              <ButtonDropdown
                :disabled="!isEditingDetails"
                :id="'segment-criteria-dropdown'"
                :items="criteriaDropdownItems()"
                :buttonText="
                  segmentFilter?.selectedCriteria?.value
                    ? segmentFilter.selectedCriteria.text
                    : 'Select criteria...'
                "
                :alignLeft="true"
                :active="false"
                :hasLeadingIcon="false"
                :hasTrailingIcon="true"
                size="small"
                variant="tertiary"
              />
            </BlockStack>
            <BlockStack
              :gap="200"
              class="tw-w-1/2"
              v-if="segmentFilter?.selectedCriteria?.value"
            >
              <label
                for="segmentCondition"
                class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
              >
                Condition
              </label>
              <div
                class="tw-flex tw-items-center gap-2 tw-flex-wrap tw-w-max tw-p-2"
              >
                <p class="tw-m-0 tw-font-bold">Recipient</p>
                <ButtonDropdown
                  :disabled="!isEditingDetails"
                  :id="'segment-isOrIsNot-dropdown'"
                  :items="isOrIsNot()"
                  :buttonText="
                    segmentFilter.selectedGroup.isOrIsNot ? 'is' : 'is NOT'
                  "
                  :alignLeft="true"
                  :active="false"
                  :hasLeadingIcon="false"
                  :hasTrailingIcon="true"
                  size="small"
                  variant="tertiary"
                />
                <p class="tw-m-0 tw-font-bold">in</p>
                <ButtonDropdown
                  :disabled="!isEditingDetails"
                  :id="'segment-groupList-dropdown'"
                  :items="groupListDropdownItems()"
                  :buttonText="
                    segmentFilter.selectedGroup.id
                      ? segmentFilter.selectedGroup.name
                      : 'Choose a group...'
                  "
                  :alignLeft="true"
                  :active="false"
                  :hasLeadingIcon="false"
                  :hasTrailingIcon="true"
                  maxHeight="200px"
                  size="small"
                  variant="tertiary"
                />
              </div>
            </BlockStack>
          </BlockStack>
        </div>
      </BlockStack>
    </template>
    <template v-if="!showCriteriaOnly">
      <BlockStack :gap="400" class="tw-w-full">
        <BlockStack :gap="200" class="tw-grow">
          <label
            for="groupName"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >
            Name<span
              v-if="!segmentNameIsValid"
              class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
              >required field</span
            >
          </label>
          <input
            v-model="form.metadata.name"
            type="text"
            name="groupName"
            id="groupName"
            :class="{
              'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6':
                !isEditingDetails,
              'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                isEditingDetails,
            }"
            class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6"
            placeholder="segment name"
            :readonly="!isEditingDetails"
          />
        </BlockStack>
        <BlockStack :gap="200" class="tw-grow tw-pl-0">
          <label
            for="segmentTags"
            class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
          >
            Tags
          </label>
          <MultiSelectDropdown
            :disabled="!isEditingDetails"
            buttonText="Select tags"
            v-model="form.metadata.tags"
            :options="availableTags"
            :placeholder="'Select tags'"
            size="large"
            :id="'edit-segment-tags-multiselect-dropdown'"
          />
        </BlockStack>
      </BlockStack>
    </template>
  </BlockStack>
  <template v-if="isEditingDetails">
    <ModalFooter>
      <div class="tw-flex tw-gap-4 tw-items-center tw-mt-2">
        <TheButton
          type="button"
          variant="primary"
          size="large"
          :disabled="!this.allItemsComplete"
          @click="saveForm()"
        >
          <template #text>Save segment</template>
        </TheButton>
        <TheButton
          type="button"
          variant="tertiary"
          size="large"
          @click="cancelEdit()"
        >
          <template #text>Cancel</template>
        </TheButton>
      </div>
    </ModalFooter>
  </template>
</template>

<script>
import BlockStack from "../../layout/BlockStack.vue";
import ModalFooter from "../../layout/ModalFooter.vue";
import TheButton from "../../actions/TheButton.vue";
import MultiSelectDropdown from "../../elements/dropdown/MultiSelectDropdown.vue";
import ButtonDropdown from "../../elements/dropdown/ButtonDropdown.vue";

export default {
  name: "EditSegmentForm",
  emits: ["reloadData"],
  components: {
    ButtonDropdown,
    MultiSelectDropdown,
    BlockStack,
    ModalFooter,
    TheButton,
  },
  props: {
    segment: {
      type: Object,
      default: () => ({}),
    },
    showCriteriaOnly: {
      type: Boolean,
    },
  },
  created() {
    this.initializeForm(this.segment);
  },
  data() {
    return {
      isEditingDetails: false,
      form: {
        model: "Recipient",
        metadata: {
          name: "",
          tags: [],
        },
        filter: {},
      },
      availableTags: [
        "a-lists",
        "active clients",
        "b-lists",
        "c-lists",
        "centers of influence",
        "clients",
        "closed lost",
        "closed won",
        "cold leads",
        "customers",
        "decision makers",
        "high engagements",
        "high priorities",
        "high values",
        "hot leads",
        "inactive clients",
        "influencers",
        "interested",
        "long-term clients",
        "loyal customers",
        "low engagements",
        "low priorities",
        "low values",
        "new clients",
        "not interested",
        "pending approval",
        "potential clients",
        "prospects",
        "repeat customers",
        "referral partners",
        "short-term clients",
        "strategic partners",
        "top prospects",
        "under negotiation",
        "VIPs",
        "warm leads",
      ],
      segmentFilter: {
        selectedCriteria: {
          value: null,
          text: null,
        },
        selectedGroup: {
          id: null,
          name: null,
          isOrIsNot: true,
        },
      },
      groups: [],
    };
  },
  watch: {
    segmentFilter: {
      handler() {
        this.form.filter = this.buildFilter();
      },
      deep: true,
    },
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    allItemsComplete() {
      return this.segmentNameIsValid;
    },
    segmentNameIsValid() {
      return this.form.metadata.name.trim().length > 0
        ? this.form.metadata.name.trim()
        : false;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    filterIsComplete() {
      return (
        this.segmentFilter.selectedCriteria.value &&
        this.segmentFilter.selectedGroup.id !== null &&
        typeof this.segmentFilter.selectedGroup.isOrIsNot === "boolean"
      );
    },
    selectedGroupId() {
      return this.segmentFilter.selectedGroup.id;
    },
  },
  methods: {
    async initializeForm(groupData) {
      this.form.metadata.name = groupData.metadata.name || "";
      this.form.metadata.tags = groupData.metadata.tags || [];
      this.form.filter = groupData.filter || {};

      // Load groups and set initial selectedGroup if applicable
      await this.checkIfUserHasGroups();

      if (groupData.filter && groupData.filter["groups.0._id"]) {
        // alert()
        let groupId, isOrIsNot;
        if (groupData.filter["groups.0._id"].$eq) {
          alert();
          groupId = groupData.filter["groups.0._id"].$eq;
          isOrIsNot = true;
        } else if (groupData.filter["groups.0._id"].$ne) {
          // alert()
          groupId = groupData.filter["groups.0._id"].$ne;
          isOrIsNot = false;
        }

        const group = this.groups.find((g) => g._id === groupId);
        if (group) {
          alert();
          this.segmentFilter.selectedCriteria.value = 1;
          this.segmentFilter.selectedCriteria.text =
            "If someone is in or not in a group";
          this.segmentFilter.selectedGroup.id = group._id;
          this.segmentFilter.selectedGroup.name = group.metadata.name;
          this.segmentFilter.selectedGroup.isOrIsNot = isOrIsNot;
        }
      }
    },
    editGroupDetails() {
      this.isEditingDetails = !this.isEditingDetails;
    },
    cancelEdit() {
      this.initializeForm(this.segment);
      this.editGroupDetails();
    },
    async saveForm() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      const patchObject = {
        "metadata.name": this.form.metadata.name,
        "metadata.tags": this.form.metadata.tags,
        filter: this.form.filter,
      };

      try {
        const groupsRes = await api.upsertSegment(null, {
          filter: {
            _id: this.segment._id,
          },
          patch: patchObject,
        });
        console.log("groupsRes: ", groupsRes);
        this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("group was NOT edited", error);
      }
    },
    async checkIfUserHasGroups() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();

      this.checkingIfUserHasGroups = true;
      let requestBody = {};
      let filter = {
        model: "Recipient",
      };

      const queryParameters = {
        limit: 1000,
        sort: "metadata.name",
      };

      requestBody = {
        filter: filter,
      };

      try {
        const response = await api.listGroups(queryParameters, requestBody);

        const count = response.data.result.count;
        this.count = response.data.result.count;
        this.userHasGroups = count;
        this.groups = response.data.result.records;
        return true;
      } catch (error) {
        console.log("recipients did not load", error);
      } finally {
        this.checkingIfUserHasGroups = false;
      }
    },
    criteriaDropdownItems() {
      return [
        {
          label: "If someone is in or not in a group",
          action: () =>
            this.handleCriteriaSelection(
              1,
              "If someone is in or not in a group"
            ),
        },
        {
          label: "What someone has done (or not done)",
          action: () =>
            this.handleCriteriaSelection(
              2,
              "What someone has done (or not done)"
            ),
        },
      ];
    },
    isOrIsNot() {
      return [
        {
          label: "Is",
          action: () => this.handleIsOrIsNot(true),
        },
        {
          label: "Is Not",
          action: () => this.handleIsOrIsNot(false),
        },
      ];
    },
    groupListDropdownItems() {
      return this.groups.map((group) => ({
        label: group.metadata.name,
        sublabel: `Members: ${group.members.length}`,
        action: () => this.handleGroupListSelection(group),
      }));
    },
    handleCriteriaSelection(criteria, text) {
      this.segmentFilter.selectedCriteria.value = criteria;
      this.segmentFilter.selectedCriteria.text = text;
      if (criteria === 1) {
        this.checkIfUserHasGroups();
      }
    },
    handleIsOrIsNot(isOrIsNotValue) {
      this.segmentFilter.selectedGroup.isOrIsNot = isOrIsNotValue;
    },
    handleGroupListSelection(group) {
      this.segmentFilter.selectedGroup.id = group._id;
      this.segmentFilter.selectedGroup.name = group.metadata.name;
    },
    buildFilter() {
      const filter = {
        "metadata.giftingLeads": { $in: [this.userId] },
      };

      if (this.segmentFilter.selectedCriteria.value === 1) {
        if (this.segmentFilter.selectedGroup.isOrIsNot) {
          filter["groups.0._id"] = { $eq: this.segmentFilter.selectedGroup.id };
        } else {
          filter["groups.0._id"] = { $ne: this.segmentFilter.selectedGroup.id };
        }
      } else if (this.segmentFilter.selectedCriteria.value === 2) {
        filter["groups.0._id"] = { $ne: this.segmentFilter.selectedGroup.id };
      }

      return filter;
    },
    handleSuccess() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Segment was successfully edited.",
        duration: 4000,
        id: Date.now(),
      });
      this.editGroupDetails();
      this.$emit("reloadData");
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again.";
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage =
          "An error occurred setting up the request that prevented it from being sent. Please try again.";
      }

      this.$store.dispatch("notifications/notifyError", errorMessage);
      console.error(errorMessage);
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
