<template>
  <template v-if="dataIsLoading">
    <TheCard>
      <CardHeader
        :showTitle="true"
        title="Payment details"
        :showSubheading="true"
      >
        <template #subheading-content
          >Payments are processed and handled by Stripe, Inc.
        </template>
      </CardHeader>
      <TheBox :noTopPadding="true">
        <ListSkeleton :numberOfRows="2"></ListSkeleton>
      </TheBox>
    </TheCard>
  </template>
  <template v-else>
    <TheCard>
      <CardHeader
        :showTitle="true"
        title="Payment details"
        :showSubheading="true"
        :showActions="true"
      >
        <template #card-header-actions>
          <TheButton
            :variant="paymentMethodButtonClass"
            size="small"
            @click="setupPaymentMethod()"
          >
            <template #text>
              {{
                !!defaultPaymentInformation?.card ? "Update" : "Add credit card"
              }}
            </template>
          </TheButton>
        </template>
        <template #subheading-content
          >Payments are processed and handled by Stripe, Inc.
        </template>
      </CardHeader>
      <TheBox :noTopPadding="true">
        <template v-if="!!defaultPaymentInformation?.card">
          <div class="tw-w-auto">
            <dl
              class="tw-mt-0 tw-space-y-2 tw-divide-solid tw-divide-x-0 tw-divide-y tw-divide-gray-100 tw-border-t tw-border-gray-200 tw-text-sm tw-leading-6"
            >
              <div class="sm:tw-flex">
                <dt
                  class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                >
                  Card
                </dt>
                <dd
                  class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto"
                >
                  <div class="tw-text-gray-900 tw-capitalize">
                    {{ paymentCardBrand || "N/A" }}
                  </div>
                </dd>
              </div>
              <div class="tw-pt-6 sm:tw-flex">
                <dt
                  class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                >
                  Last 4 digits
                </dt>
                <dd
                  class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto"
                >
                  <div class="tw-text-gray-900">
                    {{ paymentCardLastFourDigits || "N/A" }}
                  </div>
                </dd>
              </div>
              <div class="tw-pt-6 sm:tw-flex">
                <dt
                  class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
                >
                  Expiration date
                </dt>
                <dd
                  class="tw-mt-1 tw-flex tw-justify-between tw-gap-x-6 sm:tw-mt-0 sm:tw-flex-auto"
                >
                  <div class="tw-text-gray-900">
                    {{ paymentCardExpirationDate || "N/A" }}
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </template>
        <template v-else>
          <EmptyState emptyState="caution"></EmptyState>
        </template>
      </TheBox>
    </TheCard>
  </template>
</template>

<script>
import {
  getDefaultPaymentInformation,
  setupPaymentMethod,
} from "@/services/stripeService";
import TheCard from "../../../components-v2/ui/layout/TheCard";
import CardHeader from "../../../components-v2/ui/layout/CardHeader";
import TheBox from "../../../components-v2/ui/layout/TheBox";
import TheButton from "../../../components-v2/ui/actions/TheButton";
import EmptyState from "../../../components-v2/ui/feedback/EmptyState.vue";
import ListSkeleton from "@/components-v2/ui/skeletons/ListSkeleton.vue";

export default {
  name: "StripeDefaultPaymentCard",
  components: {
    TheBox,
    TheCard,
    CardHeader,
    TheButton,
    EmptyState,
    ListSkeleton,
  },
  data() {
    return {
      defaultPaymentInformation: {},
      hasDefaultPaymentMethod: false,
      dataIsLoading: false,
    };
  },
  async created() {
    await this.getDefaultPaymentInformation();
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    /** payment info */
    paymentCardBrand() {
      return this?.defaultPaymentInformation?.card?.display_brand;
    },
    paymentCardLastFourDigits() {
      return this?.defaultPaymentInformation?.card?.last4;
    },
    paymentCardExpirationDate() {
      if (
        this?.defaultPaymentInformation?.card?.exp_month &&
        this?.defaultPaymentInformation?.card?.exp_year
      ) {
        return (
          this?.defaultPaymentInformation?.card?.exp_month +
          " / " +
          this?.defaultPaymentInformation?.card?.exp_year
        );
      } else {
        return false;
      }
    },
    paymentMethodButtonClass() {
      return this.hasDefaultPaymentMethod ? "primary" : "critical";
    },

    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async getDefaultPaymentInformation() {
      this.dataIsLoading = true;

      try {
        const defaultPaymentData = await getDefaultPaymentInformation(
          this.userId
        );

        const paymentInformation = defaultPaymentData;
        this.hasDefaultPaymentMethod = true;
        this.defaultPaymentInformation = paymentInformation;
      } catch (error) {
        if (
          error.response.data.error.code === 404 &&
          error.response.data.error.status === "no_payment_method_found"
        ) {
          this.hasDefaultPaymentMethod = false;
        } else {
          this.handleErrorFromAPI(
            error,
            "Something went wrong while attempting to retrieve your payment information from Stripe. Please reload the page and try again."
          );
          console.log("defaultPaymentRes error: ", error);
        }
      } finally {
        this.dataIsLoading = false;
      }
    },

    /** handles setting up payment method */
    async setupPaymentMethod() {
      try {
        const setupPaymentMethodRes = await setupPaymentMethod();
        const paymentUrl = setupPaymentMethodRes.url;
        window.location.href = paymentUrl;
      } catch (error) {
        console.log("defaultPaymentRes error: ", error);
      }
    },

    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error?.response) {
        // Process API error message if customMessage is not provided
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>

<style scoped></style>
