<template>
  <ThePage class="tw-w-full">
    <BlockStack :gap="1000" class="tw-w-full">
      <PageSection>
        <div
          class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <p
              class="tw-py-1 tw-px-2 tw-rounded tw-text-sm tw-font-bold tw-inline-block tw-mb-1 tw-mt-0 tw-mx-0"
              :class="{
                'tw-text-red-500 tw-bg-red-50': !getCampaignName,
                'tw-text-green-600 tw-bg-green-50': getCampaignName,
              }"
            >
              {{ getCampaignName ? "complete" : "required" }}
            </p>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              1. Name your campaign
            </h2>
            <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
              Campaigns are "untitled" by default but you can give yours a
              contextual name for future reference.
            </p>
          </div>
          <div class="md:tw-col-span-2">
            <TheCampaignName
              :campaign="campaign"
              @loadSteps="loadSteps"
            ></TheCampaignName>
          </div>
        </div>
      </PageSection>
      <PageSection>
        <div
          class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <p
              class="tw-py-1 tw-px-2 tw-rounded tw-text-sm tw-font-bold tw-inline-block tw-mb-1 tw-mt-0 tw-mx-0"
              :class="{
                'tw-text-red-500 tw-bg-red-50': !getCampaignGiftCardAmount,
                'tw-text-green-600 tw-bg-green-50': getCampaignGiftCardAmount,
              }"
            >
              {{ getCampaignGiftCardAmount ? "complete" : "required" }}
            </p>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              2. Set gift card amount
            </h2>
            <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
              This is the cash value of the e-gift card that recipients can
              redeem.
            </p>
          </div>
          <div class="md:tw-col-span-2">
            <TheGiftCardAmount
              :campaign="campaign"
              @loadSteps="loadSteps"
            ></TheGiftCardAmount>
          </div>
        </div>
      </PageSection>
      <PageSection>
        <div
          class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <p
              class="tw-py-1 tw-px-2 tw-rounded tw-text-sm tw-font-bold tw-inline-block tw-mb-1 tw-mt-0 tw-mx-0"
              :class="{
                'tw-text-red-500 tw-bg-red-50':
                  !getCampaignType ||
                  (getCampaignType === 'email' &&
                    (!getCampaignEmailSubject || !getCampaignEmailText)),
                'tw-text-green-600 tw-bg-green-50':
                  getCampaignType === 'link' ||
                  (getCampaignType === 'email' &&
                    getCampaignEmailSubject &&
                    getCampaignEmailText),
              }"
            >
              {{
                getCampaignType === "link" ||
                (getCampaignType === "email" &&
                  getCampaignEmailSubject &&
                  getCampaignEmailText)
                  ? "complete"
                  : "required"
              }}
            </p>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              3. Choose send mode
            </h2>
            <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
              You can choose to create a shareable link to use in your workflow
              or send an email with our email builder.
            </p>
          </div>

          <div class="md:tw-col-span-2">
            <TheSendMode
              :campaign="campaign"
              @loadSteps="loadSteps"
            ></TheSendMode>
          </div>
        </div>
      </PageSection>
      <div
        class="tw-w-full tw-border-solid tw-border-2 tw-border-gray-300 tw-border-x-0 tw-border-b-0"
      ></div>
      <PageSection>
        <div
          class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <p
              class="tw-text-gray-500 tw-bg-white tw-py-1 tw-px-2 tw-rounded tw-text-sm tw-font-bold tw-inline-block tw-mb-1 tw-mt-0 tw-mx-0"
            >
              optional
            </p>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              4. Enable gift landing page
            </h2>
            <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
              Enable this setting to include a personalized gift landing page
              that your recipients see when they redeem their eGift card. You
              can customize the setup of your landing page in your Account
              Settings.
            </p>
          </div>

          <div class="md:tw-col-span-2">
            <TheGiftNote
              :campaign="campaign"
              @loadSteps="loadSteps"
            ></TheGiftNote>
          </div>
        </div>
      </PageSection>
      <PageSection>
        <div
          class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <p
              class="tw-text-gray-500 tw-bg-white tw-py-1 tw-px-2 tw-rounded tw-text-sm tw-font-bold tw-inline-block tw-mb-1 tw-mt-0 tw-mx-0"
            >
              optional
            </p>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              5. Set a budget
            </h2>
            <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
              Bold Xchange’s Budget feature is an easy-to-use tool that helps
              you track and monitor gift spending areas that are important to
              you. Once you’ve created a Budget, you can monitor how much you've
              spent against your budget target.
            </p>
          </div>

          <div class="md:tw-col-span-2">
            <TheBudget
              :budgetSessionId="budgetSessionId"
              @resetSessionId="resetSessionId"
              @handlePaymentMethodSetup="handlePaymentMethodSetup"
            ></TheBudget>
          </div>
        </div>
      </PageSection>
      <PageSection>
        <div
          class="tw-grid tw-max-w-6xl tw-grid-cols-1 tw-gap-x-8 md:tw-grid-cols-3"
        >
          <div>
            <p
              class="tw-text-gray-500 tw-bg-white tw-py-1 tw-px-2 tw-rounded tw-text-sm tw-font-bold tw-inline-block tw-mb-1 tw-mt-0 tw-mx-0"
            >
              optional
            </p>
            <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
              6. Require meeting
            </h2>
            <p class="tw-mt-1 tw-text-sm tw-leading-6 tw-text-gray-700">
              Bold Xchange allows you to require a recipient to take a meeting
              with you in order to accept their gift. Connect your account to
              have meetings booked on your calendar in real-time any time a lead
              accepts your e-gift terms.
            </p>
          </div>

          <div class="md:tw-col-span-2">
            <TheCalendarBooking
              :campaign="campaign"
              @loadSteps="loadSteps"
            ></TheCalendarBooking>
          </div>
        </div>
      </PageSection>
    </BlockStack>
  </ThePage>
</template>

<script>
import ThePage from "../../../components-v2/ui/layout/ThePage.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import PageSection from "../../../components-v2/ui/layout/PageSection.vue";
import TheGiftCardAmount from "./TheGiftCardAmount.vue";
import TheSendMode from "./TheSendMode.vue";
import TheBudget from "./TheBudget.vue";
import TheGiftNote from "./TheGiftNote.vue";
import TheCalendarBooking from "./TheCalendarBooking.vue";
import TheCampaignName from "./TheCampaignName.vue";

export default {
  name: "TheCampaignSettings",
  emits: ["resetSessionId", "loadSteps", "handlePaymentMethodSetup"],
  components: {
    ThePage,
    BlockStack,
    PageSection,
    TheGiftCardAmount,
    TheSendMode,
    TheBudget,
    TheCalendarBooking,
    TheCampaignName,
    TheGiftNote,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    budgetSessionId: {
      type: String,
    },
  },
  
  data() {
    return {};
  },
  computed: {
    getCampaignName() {
      return this.$store.getters["campaigns/getCampaignName"];
    },
    getCampaignGiftCardAmount() {
      return this.$store.getters["campaigns/getCampaignGiftCardAmount"];
    },
    getCampaignType() {
      return this.$store.getters["campaigns/getCampaignType"];
    },
    getCampaignEmailSubject() {
      return this.$store.getters["campaigns/getCampaignEmailSubject"];
    },
    getCampaignEmailText() {
      return this.$store.getters["campaigns/getCampaignEmailText"];
    },
  },
  methods: {
    /** from TheBudget.vue */
    resetSessionId() {
      this.$emit("resetSessionId");
    },
    loadSteps() {
      this.$emit("loadSteps");
    },
    handlePaymentMethodSetup() {
      this.$emit("handlePaymentMethodSetup");
    },
  },
};
</script>

<style scoped></style>
