<template>
  <template v-if="showMeetingPicker">
    <Teleport to="#modal">
      <FullPageModal
        :bookMeetingView="showMeetingPicker"
        :open="showMeetingPicker"
        @closeModal="closeModal"
        customWidthClass="tw-w-auto"
      >
        <div class="tw-flex tw-flex-wrap tw-gap-5 tw--mt-5 tw-px-6 tw-pb-6">
          <div class="tw-flex tw-flex-col tw-gap-2 tw-max-w-2xl tw-flex-1">
            <div
              class="tw-p-4"
              :class="{
                'tw-bg-green-50': bookingIsSelected,
                'tw-bg-orange-50': !bookingIsSelected,
              }"
            >
              <template v-if="bookingIsSelected">
                <div class="tw-flex tw-gap-2 tw-flex-wrap tw-items-center">
                  <b-icon-check-circle-fill
                    class="tw-text-sm tw-text-green-500"
                  >
                  </b-icon-check-circle-fill>
                  <p class="tw-text-xl tw-leading-8 tw-text-gray-700 tw-m-0">
                    Thank you, you're all set to redeem your gift!
                  </p>
                  <p
                    class="tw-text-2xl tw-font-bold tw-leading-8 tw-text-gray-900 tw-m-0"
                  >
                    {{ this.campaign.from.name }} will reach out to you via
                    email.
                  </p>
                </div>
              </template>
              <template v-else>
                <p class="tw-text-xl tw-leading-8 tw-text-gray-700 tw-m-0">
                  Before you confirm your gift...
                </p>
                <p
                  class="tw-text-2xl tw-font-bold tw-leading-8 tw-text-gray-900 tw-m-0"
                >
                  Pick a good time to chat with {{ this.campaign.from.name }}
                </p>
              </template>
            </div>
            <template v-if="bookingIsSelected">
              <BlockStack :gap="200">
                <!-- <BlockStack :gap="100" class="tw-grow"> -->
                <p class="tw-text-lg tw-leading-8 tw-text-gray-700 tw-m-0">
                  Here are the details of your meeting:
                </p>
                <ol class="tw-list-disc tw-m-0 tw-text-left">
                  <li class="text-gray-700 tw-text-lg">
                    Meeting with {{ this.campaign.from.name }}
                  </li>
                  <li class="text-gray-700 tw-text-lg">
                    On {{ bookingIsSelected.date }}
                  </li>
                  <li class="text-gray-700 tw-text-lg">
                    From {{ bookingIsSelected.formattedStart }} to
                    {{ bookingIsSelected.formattedEnd }}
                  </li>
                </ol>
                <!-- </BlockStack> -->
              </BlockStack>
            </template>
            <MeetingPicker
            :provider="campaign?.booking?.provider"
              :campaignId="campaign?.id"
              :testMode="true"
            ></MeetingPicker>
            <ModalFooter>
              <div
                class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-2"
              >
                <NotificationMessage
                  :scope="$options.name"
                ></NotificationMessage>
                <template v-if="requiresBooking && bookingIsSelected">
                  <TheButton
                    :hasLeadingIcon="finalizingGiftCardSelection"
                    :disabled="finalizingGiftCardSelection"
                    @click.stop="redeemThisCard()"
                    variant="secondary"
                    size="large"
                  >
                    <template #text>
                      <template v-if="finalizingGiftCardSelection"
                        >Processing request</template
                      >
                      <template v-else
                        >Redeem<span class="tw-underline">{{
                          vendor.name
                        }}</span
                        >gift card</template
                      >
                    </template>
                    <template #leading-icon>
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="tw-sr-only">Loading...</span>
                      </div>
                    </template>
                  </TheButton>
                  <TheButton
                    @click.stop="resetBooking()"
                    variant="ghost"
                    size="regular"
                    :disabled="finalizingGiftCardSelection"
                  >
                    <template #text> Pick a new date </template>
                  </TheButton>
                </template>
              </div>
            </ModalFooter>
          </div>
          <div class="tw-max-w-lg tw-flex tw-flex-col tw-gap-2 tw-flex-1">
            <ModalBody>
              <GiftCardContent
                :bookMeetingView="true"
                :selectedVendor="this.vendor"
                :vendorMedia="vendorMedia"
              ></GiftCardContent>
            </ModalBody>
          </div>
        </div>
      </FullPageModal>
    </Teleport>
  </template>
  <template v-if="giftCardModalIsOpen">
    <Teleport to="#modal">
      <TheModal
        @closeModal="closeModal"
        :open="giftCardModalIsOpen"
        :closeOnClickOutside="!finalizingGiftCardSelection"
      >
        <ModalBody>
          <GiftCardContent
            :selectedVendor="this.vendor"
            :vendorMedia="vendorMedia"
          ></GiftCardContent>
        </ModalBody>
        <template v-if="this.giftRecipientIsValid">
          <ModalFooter>
            <div
              class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-2"
            >
              <NotificationMessage :scope="$options.name"></NotificationMessage>
              <template v-if="requiresBooking">
                <template v-if="!bookingIsSelected">
                  <TheButton
                    :hasLeadingIcon="finalizingGiftCardSelection"
                    :disabled="finalizingGiftCardSelection"
                    @click.stop="toggleMeetingPicker()"
                    variant="new"
                    size="large"
                  >
                    <template #text>
                      Redeem<span class="tw-underline">{{ vendor.name }}</span
                      >gift card
                    </template>
                  </TheButton>
                </template>
                <template v-if="bookingIsSelected">
                  <TheButton
                    :hasLeadingIcon="finalizingGiftCardSelection"
                    :disabled="finalizingGiftCardSelection"
                    @click.stop="redeemThisCard()"
                    variant="secondary"
                    size="large"
                  >
                    <template #text>
                      <template v-if="finalizingGiftCardSelection"
                        >Processing request</template
                      >
                      <template v-else
                        >Redeem<span class="tw-underline">{{
                          vendor.name
                        }}</span
                        >gift card</template
                      >
                    </template>
                    <template #leading-icon>
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="tw-sr-only">Loading...</span>
                      </div>
                    </template>
                  </TheButton>
                </template>
              </template>
              <template v-else>
                <div>
                  <TheButton
                    :hasLeadingIcon="finalizingGiftCardSelection"
                    :disabled="finalizingGiftCardSelection"
                    @click.stop="redeemThisCard()"
                    variant="secondary"
                    size="large"
                  >
                    <template #text>
                      <template v-if="finalizingGiftCardSelection"
                        >Processing request</template
                      >
                      <template v-else
                        >Redeem<span class="tw-underline">{{
                          vendor.name
                        }}</span
                        >gift card</template
                      >
                    </template>
                    <template #leading-icon>
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="tw-sr-only">Loading...</span>
                      </div>
                    </template>
                  </TheButton>
                </div>
              </template>
            </div>
          </ModalFooter>
        </template>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="giftCardWasRedeemed">
    <Teleport to="#modal">
      <TheModal
        @closeModal="closeModal"
        :open="giftCardWasRedeemed"
        :closeOnClickOutside="false"
      >
        <ModalBody>
          <RedeemedGiftCardContent
            :campaign="campaign"
            :vendor="vendor"
          ></RedeemedGiftCardContent>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <li class="tw-relative hover:tw-opacity-90 tw-cursor-pointer tw-z-0">
    <div class="tw-relative tw-z-50" @click="toggleGiftCardModal()">
      <!-- media -->
      <template v-if="vendorMedia.length >= 1">
        <TheCarousel :list="vendorMedia"> </TheCarousel>
      </template>
      <template v-if="vendorMedia.length === 0">
        <div
          class="tw-aspect-h-7 tw-aspect-w-10 tw-w-full tw-overflow-hidden tw-rounded-lg tw-bg-gray-100"
        >
          <img
            src="../../assets/img/1920x400/Bold_Xchange_Gift_Card_Placeholder_WhiteOnBlue_1200x630.jpg"
            alt=""
            class="tw-h-full tw-w-full tw-pointer-events-none tw-object-cover"
          />
        </div>
      </template>
      <p
        class="tw-pointer-events-none tw-mt-2 tw-block tw-truncate tw-text-lg tw-m-0 tw-font-bold tw-text-gray-900"
      >
        {{ vendor?.name }}
      </p>
      <p
        class="tw-pointer-events-none tw-block tw-text-sm tw-font-medium tw-m-0 tw-text-gray-500"
      >
        {{ vendor?.metadata?.shortDescription }}
      </p>
      <div class="tw-flex tw-justify-between tw-items-start tw-gap-1">
        <p class="tw-text-sm tw-m-0 tw-text-gray-500 tw-truncate">
          <template v-if="vendor?.address?._id">
            {{
              vendor.address.lines[0] +
              ", " +
              vendor.address.city +
              ", " +
              vendor.address.state +
              " " +
              vendor.address.postalCode
            }}
          </template>
        </p>
        <template
          v-if="
            this.isBoldXchangeAdmin ||
            (this.isGiftingLead && !isGiftingLeadAndRecipient)
          "
        >
          <p class="tw-text-sm tw-m-0 tw-font-bold">
            ${{ vendor?.metadata?.gift_card_minimum }}
          </p>
        </template>
      </div>
    </div>
    <template v-if="!isToggled && isCampaignBuilder">
      <input
        type="checkbox"
        @change="toggleGiftCardSelection"
        :checked="isSelected()"
        class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-rounded tw-border-gray-300 tw-text-indigo-600 focus:tw-ring-indigo-600 tw-z-50"
      />
    </template>
  </li>
</template>

<script>
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import ModalFooter from "../../components-v2/ui/layout/ModalFooter.vue";
import GiftCardContent from "../../components-v2/ui/modals/GiftCardContent.vue";
import RedeemedGiftCardContent from "../../components-v2/ui/modals/RedeemedGiftCardContent.vue";
import TheCarousel from "../../components-v2/ui/carousel/TheCarousel";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import MeetingPicker from "@/components/MeetingPicker.vue";
import FullPageModal from "../../components-v2/ui/modals/FullPageModal.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";

export default {
  name: "GiftCardsListItem",
  components: {
    BlockStack,
    TheModal,
    ModalBody,
    ModalFooter,
    GiftCardContent,
    RedeemedGiftCardContent,
    TheCarousel,
    TheButton,
    NotificationMessage,
    MeetingPicker,
    FullPageModal,
  },
  emits: ["toggleGiftCardSelection"],
  props: {
    vendor: {
      type: Object,
      require: true,
    },
    isToggled: {
      type: Boolean,
      default: false,
    },
    isViewingSelection: {
      type: Boolean,
      default: false,
    },
    selectedGiftCards: {
      type: Array,
      require: true,
    },
    isGift: {
      type: Boolean,
      required: false,
    },
    isCampaignBuilder: {
      type: Boolean,
      required: false,
    },
    campaign: { type: Object, required: false },
  },
  data() {
    return {
      api: {
        error: 0,
        success: 0,
        message: "",
      },
      vendorMedia: [],
      giftCardModalIsOpen: false,
      giftCardWasRedeemed: false,
      finalizingGiftCardSelection: false,
      showMeetingPicker: false,
    };
  },
  created() {
    this.handleVendorMedia();
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    isGiftingLeadAndRecipient() {
      return (
        this?.campaign?.recipients[0]?.contact?.email === this?.User?.email ||
        this.isGiftingLead
      );
    },
    giftRecipientIsValid() {
      return (
        this?.campaign?.recipients.some(
          (recipient) => recipient?.contact?.email === this?.User?.email
        ) || this.isGiftCardRecipient
      );
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    requiresBooking() {
      return this?.campaign?.booking?.required;
    },
    bookingIsSelected() {
      return this.$store.getters["campaigns/getSelectedBooking"];
    },
  },
  methods: {
    /** handle gift card redemption */
    async redeemThisCard() {
      this.finalizingGiftCardSelection = true;
      const api = await this.getBoldClient();
      const campaignId = this.$route.params.campaignId;
      const vendorId = this.vendor.id;

      let apiParams = {
        recipient_selection: vendorId,
      };

      if (this.requiresBooking) {
        apiParams.booking = {
          end: this.bookingIsSelected.end,
          start: this.bookingIsSelected.start,
        };
      }

      console.log("apiParams", apiParams);

      try {
        const finalizeGiftCardSelectionRes =
          await api.finalizeGiftCardSelection({ campaignId }, apiParams);
        console.log(
          "finalizeGiftCardSelectionRes",
          finalizeGiftCardSelectionRes
        );
        this.handleSuccess();
      } catch (error) {
        console.log("finalizeGiftCardSelection error", error);
        this.handleErrorFromAPI(
          error,
          "Something went wrong finalizing your request. Please try again. If the issue continues, please contact our team at hello@boldxchange.com"
        );
      } finally {
        this.finalizingGiftCardSelection = false;
      }
    },

    /** handle booking selection */
    toggleMeetingPicker() {
      this.giftCardModalIsOpen = false;
      this.showMeetingPicker = !this.showMeetingPicker;
    },
    /** handle selection */
    toggleGiftCardSelection() {
      console.log("selected vendor", this.vendor.id);
      this.$emit("toggleGiftCardSelection", this.vendor);
    },
    isSelected() {
      return this.selectedGiftCards.some(
        (selected) => selected.id === this.vendor.id
      );
    },
    /** handle vendor media */
    async handleVendorMedia() {
      const api = await this.getBoldClient();
      const mediaArr = this.vendor.media;

      const giftCardsMedia = this.filterMediaByTag(mediaArr, "gift cards"); // array of media files
      const brandingMedia = this.filterMediaByTag(mediaArr, "vendor branding"); // array of media files

      // Check if both giftCardsMedia and brandingMedia are true
      // if (giftCardsMedia.length > 0 && brandingMedia.length > 0) {
      const combinedMedia = [...giftCardsMedia, ...brandingMedia]; // Combine both arrays
      this.createMediaUrls(combinedMedia, this.vendorMedia, api);
      // } else {
      // If both conditions aren't met, process each separately
      // this.createMediaUrls(giftCardsMedia, this.existingGiftCardsMedia, api);
      // this.createMediaUrls(brandingMedia, this.existingBrandingMedia, api);
      // }
    },
    createMediaUrls(mediaArr, mediaUrlArr, api) {
      mediaUrlArr.length = 0;

      for (const media of mediaArr) {
        const mediaId = media._id;
        const mediaUrl = api.utils.getMediaUrl(mediaId);
        mediaUrlArr.push({ _id: mediaId, url: mediaUrl });
      }
      this.vendorMedia = mediaUrlArr;
    },
    filterMediaByTag(mediaArr, tag) {
      return mediaArr.filter((media) => {
        return media.metadata.tags.includes(tag);
      });
    },
    /** modal toggle */
    toggleGiftCardModal() {
      this.giftCardModalIsOpen = !this.giftCardModalIsOpen;
    },
    closeModal() {
      this.giftCardModalIsOpen = false;
      this.showMeetingPicker = false;
      this.resetBooking();
    },
    /** handle resetting booking */
    resetBooking() {
      const booking = null;
      console.log("Selected booking", booking);
      this.$store.commit("campaigns/SET_BOOKING", booking);
      console.log(
        "Selected booking",
        this.$store.getters["campaigns/getSelectedBooking"]
      );
    },
    /** handle success */
    handleSuccess() {
      this.finalizingGiftCardSelection = false;
      this.giftCardModalIsOpen = false;
      this.showMeetingPicker = false;
      this.giftCardWasRedeemed = true;
      // this.$router.push("/gifts");
    },
    handleErrorFromAPI(error, customMessage = null) {
      let errorMessage = "Something went wrong. Please try again."; // Default message

      // If a custom message is provided, prioritize it
      if (customMessage) {
        errorMessage = customMessage;
      } else if (error.response) {
        // Process API error message if customMessage is not provided
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.source.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error.response.data.message || "An unknown error occurred.";
        }
      } else if (error.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      //Dispatch the error message as before
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 8000,
        id: Date.now(),
      });
      this.resetBooking();
      this.finalizingGiftCardSelection = false;
      console.error(errorMessage); // Keep for debugging purposes
    },
  },
};
</script>

<style scoped></style>
