// campaignService.js
import { getBoldClient } from "@/api/getBoldClient.js";
import store from "../store/index";

const listMailCampaigns = async (queryParameters, requestBody) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);
  return api.listMailCampaigns(queryParameters, { filter: requestBody });
};

const listMailCampaignById = async (campaignId) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);
  const queryParameters = {
    limit: 1,
    expand: "recipients contact",
  };
  const requestBody = {
    filter: { _id: campaignId },
  };

  return api.listMailCampaigns(queryParameters, requestBody);
};

const aggregateMailCampaigns = async (queryParameters, requestBody) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);

  // Initialize the request body for the API call with mandatory fields
  let apiRequestBody = {
    filter: requestBody.filter,
    group: requestBody.group,
  };

  // Conditionally add match and facet if they are provided
  if (requestBody.selection) {
    apiRequestBody.match = requestBody.match;
  }
  if (requestBody.facet) {
    apiRequestBody.facet = requestBody.facet;
  }

  return api.listMailCampaigns(queryParameters, apiRequestBody);
};

const createCampaign = async () => {
  const token = store.state.auth.token;
  const user = store.state.auth.user;

  const fromEmail = user?.email;
  const fromName = `${user?.firstName} ${user?.lastName}`;

  const campaignData = {
    from: { email: fromEmail, name: fromName },
    subject: "",
    text: "",
    html: "",
    recipients: [],
    metadata: {
      campaign_name: "Untitled Campaign",
      campaign_status: "draft",
      campaign_type: "",
      gift_card_amount: 0,
      collection_query: { custom_selection_ids: 0 },
    },
    user: user?.id,
  };

  const api = await getBoldClient(token);
  return api.createMailCampaigns(null, [campaignData]);
};

const sendMailCampaign = async (campaignId) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);
  return api.sendMailCampaign(campaignId);
};

const upsertMailCampaign = async (campaignId, patchObject) => {
  const token = store.state.auth.token;

  const api = await getBoldClient(token);
  return api.upsertMailCampaign(null, {
    filter: { _id: campaignId },
    patch: patchObject,
  });
};

const deleteMailCampaigns = async (campaignId) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);
  return api.deleteMailCampaigns(null, {
    filter: { _id: campaignId },
  });
};

const bulkUpdateMailCampaigns = async (campaignIds, patchObject) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);
  return api.bulkUpdateMailCampaigns(null, {
    filter: { _id: { $in: campaignIds } },
    patch: patchObject,
  });
};

const campaignRecipientsEngagement = (campaign) => {
  const updateRecipientsNewModel = () => {
    const seenEmails = campaign?.seen || []; // Handle missing 'seen' gracefully
    const finalizations = campaign?.metadata?.finalizations || {}; // Default to empty object if not present

    // Extract emails of redeemed recipients
    const redeemedEmails = Object.values(finalizations)
      .filter((finalization) => finalization && finalization?.redeemer)
      .map((finalization) => finalization?.redeemer?.email);

    return campaign.recipients.map((recipient) => {
      let campaignStatus = "sent"; // Default status
      if (seenEmails.includes(recipient?.contact?.email)) {
        campaignStatus = "clicked";
      }
      if (redeemedEmails.includes(recipient?.contact?.email)) {
        campaignStatus = "redeemed";
      }
      if (campaign?.campaign_status === "draft") {
        return { ...recipient, campaign_status: "draft" };
      } else {
        return { ...recipient, campaign_status: campaignStatus };
      }
    });
  };

  const updateRecipientsOldModel = () => {
    const wasLinkClicked = campaign?.seen === true;
    const isRedeemedStatus =
      !!campaign.metadata?.isRedeemed ||
      campaign?.metadata?.campaign_status === "redeemed";

    return campaign?.recipients?.map((recipient) => {
      let campaignStatus = "sent";
      if (wasLinkClicked) {
        campaignStatus = "clicked";
      }
      if (isRedeemedStatus) {
        campaignStatus = "redeemed";
      }

      if (campaign?.campaign_status === "draft") {
        return { ...recipient, campaign_status: "draft" };
      } else {
        return { ...recipient, campaign_status: campaignStatus };
      }
    });
  };

  // Determine which update function to use based on the presence of the 'finalizations' or the 'seen' array

  if (Array.isArray(campaign.seen) || campaign?.metadata?.finalizations) {
    return updateRecipientsNewModel();
  } else {
    return updateRecipientsOldModel();
  }
};

const campaignEngagementStats = (recipients) => {
  // Initialize counts
  let counts = {
    totalCampaignsSentCount: recipients.length, // All recipients are initially sent
    totalCampaignsClickedCount: 0,
    totalCampaignsRedeemedCount: 0,
    totalSpendCount: 0,
  };

  // Iterate over recipients to count statuses
  recipients.forEach((recipient) => {
    if (recipient?.campaign_status === "redeemed") {
      counts.totalCampaignsRedeemedCount += 1;
      counts.totalCampaignsClickedCount += 1; // Redeemed implies clicked
    } else if (recipient.campaign_status === "clicked") {
      counts.totalCampaignsClickedCount += 1;
    }
    // Note: 'sent' is not explicitly counted here since it's initialized to the total number of recipients
  });
  counts.totalSpendCount =
    counts.totalCampaignsRedeemedCount * counts.totalCampaignsSentCount;

  return counts;
};

const calculateTotalCampaignsSent = (campaignsSentOrRedeemed) => {
  let totalCampaignsSent = 0;

  campaignsSentOrRedeemed.forEach((campaign) => {
    const finalizationsCount = Object.keys(
      campaign.metadata.finalizations
    ).length;
    const campaignTotalSpend =
      finalizationsCount * campaign.metadata.gift_card_amount;

    totalFinalizations += finalizationsCount;
    totalSpend += campaignTotalSpend;
  });

  return totalCampaignsSent;
};

const calculateRedeemedCampaignsWithFinalizations = (
  campaignsWithFinalizations
) => {

  let totalFinalizations = 0;
  let totalSpend = 0;

  campaignsWithFinalizations.forEach((campaign) => {
    const finalizationsCount = Object.keys(
      campaign.metadata.finalizations
    ).length;
    const campaignTotalSpend =
      finalizationsCount * campaign.metadata.gift_card_amount;

    totalFinalizations += finalizationsCount;
    totalSpend += campaignTotalSpend;
  });

  return { totalFinalizations, totalSpend };
};



const calculateRedeemedCampaignsWithoutFinalizations = (
  campaignsWithoutFinalizations
) => {
  let totalRedemptions = campaignsWithoutFinalizations.length;
  let totalSpend = 0;

  campaignsWithoutFinalizations.forEach((campaign) => {
    const campaignTotalSpend = campaign?.metadata.gift_card_amount || 0;
    totalSpend += campaignTotalSpend;
  });

  return { totalRedemptions, totalSpend };
};

const calculateTotalClicks = (campaignsWithSeenArray) => {
  let totalClicks = 0;
  let totalRecipients = 0;

  campaignsWithSeenArray.forEach((record) => {
    const currentRecordRecipientsCount = record.recipients.length;
    totalRecipients += currentRecordRecipientsCount;

    const recipientEmails = record.recipients.map(
      (recipient) => recipient.contact.email
    );

    const seenEmails = record.seen;
    const clicks = seenEmails.reduce((acc, seenEmail) => {
      if (recipientEmails.includes(seenEmail)) {
        acc += 1;
      }
      return acc;
    }, 0);
    totalClicks += clicks;
  });
  return { totalClicks, totalRecipients };
};

const findRedeemerMatch = (redeemerEmail, userId, campaign) => {
  const finalizations = campaign?.metadata?.finalizations;

  // Case 1: If userId is provided and redeemerEmail is not provided
  if (finalizations && userId && !redeemerEmail) {
    return finalizations[userId] || false;
  }

  // Case 2: If redeemerEmail is provided and userId is not provided
  if (finalizations && redeemerEmail && !userId) {
    for (const key in finalizations) {
      const redeemer = finalizations[key].redeemer;
      if (redeemer && redeemer.email === redeemerEmail) {
        return finalizations[key];
      }
    }
    return false; // Return false if no match is found
  }

  // Case 3: If redeemerEmail is provided and userId is not provided
  if (finalizations && redeemerEmail && userId) {
    for (const key in finalizations) {
      const redeemer = finalizations[key].redeemer;
      if (redeemer && redeemer.email === redeemerEmail) {
        return finalizations[key];
      }
    }
    return false; // Return false if no match is found
  }

  return false; // Return false if neither condition is met
};

// Export the functions for use in your components or Vuex actions
export {
  calculateTotalCampaignsSent,
  calculateTotalClicks,
  calculateRedeemedCampaignsWithoutFinalizations,
  calculateRedeemedCampaignsWithFinalizations,
  findRedeemerMatch,
  listMailCampaigns,
  deleteMailCampaigns,
  upsertMailCampaign,
  aggregateMailCampaigns,
  bulkUpdateMailCampaigns,
  createCampaign,
  listMailCampaignById,
  sendMailCampaign,
  campaignRecipientsEngagement,
  campaignEngagementStats,
};
