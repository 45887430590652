<template>
  <TheCard>
    <TheBox :withPadding="true">
      <BlockStack :gap="500">
        <template v-if="hasOffice365Tokens || hasGoogleTokens">
          <BlockStack :gap="300">
            <label class="tw-text-lg tw-leading-6 tw-text-gray-900"
              >Do you want to require a recipient to take a meeting with you in
              order to accept their gift?</label
            >
            <div
              class="tw-flex tw-items-center tw-gap-3 tw-flex-grow tw-cursor-pointer"
              @click="enableCalendarBooking()"
            >
              <ToggleButton
                :toggleOn="getCampaignBookingEnabled"
              ></ToggleButton>
              <template v-if="!getCampaignBookingEnabled">
                <div class="tw-flex tw-flex-col tw-max-w-sm">
                  <p class="tw-text-lg tw-gray-blue-700 tw-p-0 tw-m-0">
                    No, do not require booking (default)
                  </p>
                </div>
              </template>
              <template v-if="getCampaignBookingEnabled">
                <div class="tw-flex tw-flex-col -tw-mt-1 tw-ml-1 tw-max-w-sm">
                  <p class="tw-text-lg tw-gray-blue-700 tw-p-0 tw-m-0">
                    Yes, require booking
                  </p>
                </div>
              </template>
            </div>
          </BlockStack>
          <template v-if="getCampaignBookingEnabled">
            <BlockStack :gap="200">
              <template v-if="!getCampaignBookingProvider">
                <p class="tw-text-lg tw-text-gray-700 tw-m-0 tw-bg-red-100">
                  Please choose a calendar
                </p>
              </template>
              <template
                v-if="
                  !getCampaignBookingSummary || !getCampaignBookingDescription
                "
              >
                <p class="tw-text-lg tw-text-gray-700 tw-m-0 tw-bg-red-100">
                  Please complete all required fields
                </p>
              </template>

              <!-- google -->
              <TheBox
                @click="handleUpdatingCalendarProvider('google')"
                class="tw-relative hover:tw-opacity-90 tw-cursor-pointer tw-gap-2 tw-p-2"
                :class="{
                  'tw-border-solid tw-border-2 tw-border-green-600 tw-rounded tw-flex tw-flex-col':
                    getCampaignBookingProvider === 'google',
                  'tw-border-solid tw-border-2 tw-border-gray-300 tw-rounded tw-flex':
                    getCampaignBookingProvider !== 'google',
                  'tw-border-solid tw-border-2 tw-border-red-600 tw-rounded tw-flex tw-flex-col':
                    getCampaignBookingProvider === 'google' &&
                    (!getCampaignBookingSummary ||
                      !getCampaignBookingDescription),
                }"
              >
                <div class="tw-flex tw-items-center tw-gap-4">
                  <!-- logo -->
                  <div class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded">
                    <img
                      src="../../../assets/svg/brands/google-icon.svg"
                      alt="google logo"
                      class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                    />
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                    >
                      sync calendar
                    </p>
                    <h2
                      class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                    >
                      Google
                    </h2>
                  </div>
                </div>
                <div>
                  <template v-if="getCampaignBookingProvider === 'google'">
                    <template
                      v-if="
                        getCampaignBookingSummary &&
                        getCampaignBookingDescription
                      "
                    >
                      <b-icon-check-circle-fill
                        class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-green-600"
                      ></b-icon-check-circle-fill>
                    </template>
                    <template
                      v-if="
                        !getCampaignBookingSummary ||
                        !getCampaignBookingDescription
                      "
                    >
                      <b-icon-exclamation-circle-fill
                        class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-red-600"
                      ></b-icon-exclamation-circle-fill>
                    </template>
                  </template>
                  <template v-else>
                    <b-icon-check-circle-fill
                      class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-gray-400"
                    ></b-icon-check-circle-fill>
                  </template>
                </div>
                <BlockStack :gap="400">
                  <template v-if="getCampaignBookingProvider === 'google'">
                    <BlockStack :gap="300" class="tw-grow">
                      <BlockStack :gap="100" class="tw-grow">
                        <label
                          for="bookingSummary"
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                          >Meeting title<span
                            v-if="!getCampaignBookingSummary"
                            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                            >required field</span
                          ></label
                        >

                        <input
                          v-model="getCampaignBookingSummary"
                          @input="handleUpdatingCalendarSummary($event)"
                          @click.stop
                          type="text"
                          name="bookingSummary"
                          id="bookingSummary"
                          :class="{
                            'tw-ring-red-300': !getCampaignBookingSummary,
                            'tw-ring-green-500': getCampaignBookingSummary,
                          }"
                          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                          placeholder="Add meeting title"
                        />
                      </BlockStack>
                      <BlockStack :gap="100" class="tw-grow">
                        <label
                          for="bookingDescription"
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                          >Meeting summary<span
                            v-if="!getCampaignBookingDescription"
                            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                            >required field</span
                          ></label
                        >
                        <textarea
                          v-model="getCampaignBookingDescription"
                          @input="handleUpdatingCalendarDescription($event)"
                          @click.stop
                          rows="10"
                          name="bookingDescription"
                          id="bookingDescription"
                          :class="{
                            'tw-ring-red-300': !getCampaignBookingDescription,
                            'tw-ring-green-500': getCampaignBookingDescription,
                          }"
                          class="tw-block tw-px-2.5 tw-py-2 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                          placeholder="Add meeting summary"

                          ></textarea>
                      </BlockStack>
                    </BlockStack>
                  </template>
                </BlockStack>
              </TheBox>
              <!-- office365 -->
              <TheBox
                @click="handleUpdatingCalendarProvider('office365')"
                class="tw-relative hover:tw-opacity-90 tw-cursor-pointer tw-gap-2 tw-p-2"
                :class="{
                  'tw-border-solid tw-border-2 tw-border-green-600 tw-rounded tw-flex tw-flex-col':
                    getCampaignBookingProvider === 'office365',
                  'tw-border-solid tw-border-2 tw-border-gray-300 tw-rounded tw-flex':
                    getCampaignBookingProvider !== 'office365',
                  'tw-border-solid tw-border-2 tw-border-red-600 tw-rounded tw-flex tw-flex-col':
                    getCampaignBookingProvider === 'office365' &&
                    (!getCampaignBookingSummary ||
                      !getCampaignBookingDescription),
                }"
              >
                <div class="tw-flex tw-items-center tw-gap-4">
                  <!-- logo -->
                  <div class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded">
                    <img
                      src="../../../assets/svg/brands/office365.png"
                      alt="google logo"
                      class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                    />
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                    >
                      sync calendar
                    </p>
                    <h2
                      class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                    >
                      Office365
                    </h2>
                  </div>
                </div>
                <div>
                  <template v-if="getCampaignBookingProvider === 'office365'">
                    <template
                      v-if="
                        getCampaignBookingSummary &&
                        getCampaignBookingDescription
                      "
                    >
                      <b-icon-check-circle-fill
                        class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-green-600"
                      ></b-icon-check-circle-fill>
                    </template>
                    <template
                      v-if="
                        !getCampaignBookingSummary ||
                        !getCampaignBookingDescription
                      "
                    >
                      <b-icon-exclamation-circle-fill
                        class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-red-600"
                      ></b-icon-exclamation-circle-fill>
                    </template>
                  </template>
                  <template v-else>
                    <b-icon-check-circle-fill
                      class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-gray-400"
                    ></b-icon-check-circle-fill>
                  </template>
                </div>
                <BlockStack :gap="400">
                  <template v-if="getCampaignBookingProvider === 'office365'">
                    <BlockStack :gap="300" class="tw-grow">
                      <BlockStack :gap="100" class="tw-grow">
                        <label
                          for="bookingSummary"
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                          >Meeting title<span
                            v-if="!getCampaignBookingSummary"
                            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                            >required field</span
                          ></label
                        >
                        <input
                          v-model="getCampaignBookingSummary"
                          @input="handleUpdatingCalendarSummary($event)"
                          @click.stop
                          type="text"
                          name="bookingSummary"
                          id="bookingSummary"
                          :class="{
                            'tw-ring-red-300': !getCampaignBookingSummary,
                            'tw-ring-green-500': getCampaignBookingSummary,
                          }"
                          class="tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                          placeholder="Add meeting title"
                        />
                      </BlockStack>
                      <BlockStack :gap="100" class="tw-grow">
                        <label
                          for="bookingDescription"
                          class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                          >Meeting summary<span
                            v-if="!getCampaignBookingDescription"
                            class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                            >required field</span
                          ></label
                        >
                        <textarea
                          v-model="getCampaignBookingDescription"
                          @input="handleUpdatingCalendarDescription($event)"
                          @click.stop
                          rows="10"
                          name="bookingDescription"
                          id="bookingDescription"
                          :class="{
                            'tw-ring-red-300': !getCampaignBookingDescription,
                            'tw-ring-green-500': getCampaignBookingDescription,
                          }"
                          class="tw-block tw-p-4 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                        ></textarea>
                      </BlockStack>
                    </BlockStack>
                  </template>
                </BlockStack>
              </TheBox>
            </BlockStack>
          </template>
        </template>
        <template v-if="!hasOffice365Tokens && !hasGoogleTokens">
          <BlockStack :gap="200">
            <p class="tw-text-lg tw-text-gray-700 tw-m-0 tw-bg-orange-100">
              Please link up your Google and/or Office365 account to use this
              feature.
            </p>
            <!-- google -->
            <TheBox
              class="tw-border-solid tw-border-2 tw-border-gray-300 tw-rounded tw-p-2"
            >
              <div
                class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-w-auto tw-p-2"
              >
                <div class="tw-flex tw-items-center tw-h-full tw-gap-4">
                  <!-- logo -->
                  <div class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded">
                    <img
                      src="../../../assets/svg/brands/google-icon.svg"
                      alt="google logo"
                      class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                    />
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                    >
                      sync calendar
                    </p>
                    <h2
                      class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                    >
                      Google
                    </h2>
                  </div>
                </div>
                <div>
                  <TheButton
                    :hasLeadingIcon="true"
                    variant="primary"
                    @click="startLinkingGoogle()"
                    size="small"
                  >
                    <template #text> Connect </template>
                  </TheButton>
                </div>
              </div>
            </TheBox>
            <!-- office365 -->
            <TheBox
              class="tw-border-solid tw-border-2 tw-border-gray-300 tw-rounded tw-p-2"
            >
              <div
                class="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-w-auto tw-p-2"
              >
                <div class="tw-flex tw-items-center tw-h-full tw-gap-4">
                  <!-- logo -->
                  <div class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded">
                    <img
                      src="../../../assets/svg/brands/office365.png"
                      alt="office365 logo"
                      class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                    />
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                    >
                      sync calendar
                    </p>
                    <h2
                      class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                    >
                      Office365
                    </h2>
                  </div>
                </div>
                <div>
                  <TheButton
                    :hasLeadingIcon="true"
                    variant="primary"
                    @click="startLinkingOffice365()"
                    size="small"
                  >
                    <template #text> Connect </template>
                  </TheButton>
                </div>
              </div>
            </TheBox>
          </BlockStack>
        </template>
      </BlockStack>
    </TheBox>
  </TheCard>
</template>

<script>
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";
import ToggleButton from "../../../components-v2/ui/actions/ToggleButton";

export default {
  name: "TheCalendarBooking",
  emits: ["loadSteps"],
  components: {
    ToggleButton,
    TheButton,
    TheCard,
    TheBox,
    BlockStack,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
      isToggled: false,
    },
  },
  async created() {
    await this.refreshClient();
  },
  data() {
    return {};
  },
  computed: {
    getCampaignBookingProvider() {
      return this.$store.getters["campaigns/getCampaignBookingProvider"];
    },
    getCampaignBookingSummary() {
      return this.$store.getters["campaigns/getCampaignBookingSummary"];
    },
    getCampaignBookingDescription() {
      return this.$store.getters["campaigns/getCampaignBookingDescription"];
    },
    getCampaignBookingEnabled() {
      return this.$store.getters["campaigns/getCampaignBookingEnabled"];
    },
    /** integration tokens */
    hasOffice365Tokens() {
      return this?.$store?.state?.auth?.user?.metadata?.office365?.tokens;
    },
    hasGoogleTokens() {
      return this?.$store?.state?.auth?.user?.metadata?.google?.tokens;
    },
  },
  methods: {
    /** handle linking google/office365 */
    async startLinkingGoogle() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */

        const api = await this.getBoldClient();
        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/google`,
        };
        const response = await api.startLinkingGoogle(redirectUri);
        const googleUrlRedirect = response?.data?.url;
        window.location.href = googleUrlRedirect;
      } catch (error) {
        console.log(error);
        console.error("Failed to link google", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isLinking = false;
      }
    },
    async finishLinkingGoogle(code) {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */

        this.isFinishingLinking = true;

        const api = await this.getBoldClient();
        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/google`,
          code: code,
        };

        const response = await api.finishLinkingGoogle(redirectUri);

        console.log("googleResponse", response);
      } catch (error) {
        console.error("Failed to finish link google", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isFinishingLinking = false;
        this.$router.push("/integrations");
      }
    },
    /** handle linking office365 */
    async startLinkingOffice365() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */

        const api = await this.getBoldClient();

        const redirectUri = {
          redirectUri:
            window.location.origin +
            `/campaign/edit/${this.campaign._id}?integration=office365`,
        };

        const response = await api.startLinkingOffice365(redirectUri);
        const office365UrlRedirect = response?.data?.url;
        window.location.href = office365UrlRedirect;
      } catch (error) {
        console.error("Failed to link office365", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isLinking = false;
      }
    },
    async finishLinkingOffice365(code) {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */

        const api = await this.getBoldClient();
        this.isFinishingLinking = true;

        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/office365`,
          code: code,
        };

        const response = await api.finishLinkingOffice365(redirectUri);
        console.log("office365Response", response);
        // window.location.reload();
      } catch (error) {
        console.error("Failed to finish link office365", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isFinishingLinking = false;
        this.$router.push("/integrations");
      }
    },

    /** handle gift card selection toggle */
    handleUpdatingCalendarProvider(calendarProvider) {
      // this.getCampaignBookingProvider = calendarProvider;
      console.log("calendarProvider: ", calendarProvider);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BOOKING_CALENDAR_PROVIDER",
        calendarProvider
      );
      this.$emit("loadSteps");
    },
    handleUpdatingCalendarSummary(event) {
      let calendarSummary = event.target.value.trim();
      console.log("calendarSummary: ", calendarSummary);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BOOKING_CALENDAR_SUMMARY",
        calendarSummary
      );
      this.$emit("loadSteps");
    },
    handleUpdatingCalendarDescription(event) {
      let calendarDescription = event.target.value.trim();
      console.log("calendarDescription: ", calendarDescription);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BOOKING_CALENDAR_DESCRIPTION",
        calendarDescription
      );
      this.$emit("loadSteps");
    },
    enableCalendarBooking() {
      const isBookingEnabled = this.getCampaignBookingEnabled;

      console.log("isBookingEnabled: ", isBookingEnabled);

      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_BOOKING_ENABLED",
        !isBookingEnabled
      );
      this.$emit("loadSteps");
    },
  },
};
</script>

<style scoped></style>
