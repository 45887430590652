<template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <PageHeader :showActions="true" title="Integrations"> </PageHeader>
  <ThePage customWidthClass="tw-w-full">
    <div class="tw-w-full tw-flex tw-flex-col">
      <div class="tw-max-w-8xl tw-flex tw-gap-3 tw-flex-wrap">
        <!-- google -->
        <TheCard class="tw-max-w-lg">
          <TheBox :withPadding="true" class="tw-h-full">
            <div
              class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-2 tw-w-auto tw-p-2"
            >
              <div
                class="tw-flex tw-flex-col tw-h-full tw-gap-2 tw-w-auto tw-p-2"
              >
                <div class="tw-flex tw-gap-4">
                  <!-- logo -->
                  <div class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded">
                    <img
                      src="../../assets/svg/brands/google-icon.svg"
                      alt="google logo"
                      class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                    />
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                    >
                      sync calendar
                    </p>
                    <h2
                      class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                    >
                      Google
                    </h2>
                  </div>
                </div>
                <!-- description -->
                <div class="tw-flex-1 tw-self-start">
                  <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex-1">
                      <p class="tw-text-xl tw-leading-8 tw-text-gray-700">
                        Bold Xchange allows you to require a recipient to take a
                        meeting with you in order to accept their gift. Connect
                        your Google account to have meetings booked on your
                        calendar in real-time any time a lead accepts your
                        e-gift terms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <!-- button -->
                <template v-if="hasGoogleTokens">
                  <div
                    class="tw-flex tw-gap-2 tw-justify-between tw-text-lg tw-bg-green-50 tw-rounded tw-p-4"
                  >
                    <div class="tw-flex tw-gap-2 tw-items-center tw-text-lg">
                      <b-icon-check-circle-fill
                        class="tw-text-green-500"
                      ></b-icon-check-circle-fill>
                      <p
                        class="tw-m-0 tw-font-semibold tw-text-sm tw-text-text-400"
                      >
                        Account connected
                      </p>
                    </div>
                    <TheButton
                      :hasLeadingIcon="true"
                      variant="tertiary"
                      @click="startLinkingGoogle()"
                      size="regular"
                    >
                      <template #leading-icon>
                        <img
                          class="tw-h-4 tw-w-auto tw-flex-shrink-0"
                          src="../../assets/svg/brands/google-icon.svg"
                          alt=""
                        />
                      </template>

                      <template #text>
                        <p class="tw-ml-2 tw-m-0">Switch account</p>
                      </template>
                    </TheButton>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <TheButton
                      :hasLeadingIcon="true"
                      variant="tertiary"
                      @click="startLinkingGoogle()"
                      size="large"
                    >
                      <template #leading-icon>
                        <img
                          class="tw-h-6 tw-w-auto tw-flex-shrink-0"
                          src="../../assets/svg/brands/google-icon.svg"
                          alt=""
                        />
                      </template>

                      <template #text>
                        <p class="tw-ml-4 tw-m-0">Connect to Google</p>
                      </template>
                    </TheButton>
                  </div>
                </template>
              </div>
            </div>
          </TheBox>
        </TheCard>
        <!-- office365 -->
        <TheCard class="tw-max-w-lg">
          <TheBox :withPadding="true" class="tw-h-full">
            <div
              class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-2 tw-w-auto tw-p-2"
            >
              <div
                class="tw-flex tw-flex-col tw-h-full tw-gap-2 tw-w-auto tw-p-2"
              >
                <div class="tw-flex tw-gap-4">
                  <!-- logo -->
                  <div class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded">
                    <img
                      src="../../assets/svg/brands/office365.png"
                      alt="office365 logo"
                      class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                    />
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                    >
                      sync calendar
                    </p>
                    <h2
                      class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                    >
                      Office365
                    </h2>
                  </div>
                </div>

                <!-- description -->
                <div class="tw-flex-1 tw-self-start">
                  <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex-1">
                      <p class="tw-text-xl tw-leading-8 tw-text-gray-700">
                        Bold Xchange allows you to require a recipient to take
                        book a meeting with your in order to accept their gift.
                        Connect your Office365 account to have meetings booked
                        on your calendar in real-time any time a lead accepts
                        your e-gift terms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <!-- button -->
                <template v-if="hasOffice365Tokens">
                  <div
                    class="tw-flex tw-gap-2 tw-justify-between tw-text-lg tw-bg-green-50 tw-rounded tw-p-4"
                  >
                    <div class="tw-flex tw-gap-2 tw-items-center tw-text-lg">
                      <b-icon-check-circle-fill
                        class="tw-text-green-500"
                      ></b-icon-check-circle-fill>
                      <p
                        class="tw-m-0 tw-font-semibold tw-text-sm tw-text-text-400"
                      >
                        Account connected
                      </p>
                    </div>
                    <TheButton
                      :hasLeadingIcon="true"
                      variant="tertiary"
                      @click="startLinkingOffice365()"
                      size="regular"
                    >
                      <template #leading-icon>
                        <img
                          class="tw-h-4 tw-w-auto tw-flex-shrink-0"
                          src="../../assets/svg/brands/office365.png"
                          alt=""
                        />
                      </template>

                      <template #text>
                        <p class="tw-ml-2 tw-m-0">Switch account</p>
                      </template>
                    </TheButton>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <TheButton
                      :hasLeadingIcon="true"
                      variant="tertiary"
                      @click="startLinkingOffice365()"
                      size="large"
                    >
                      <template #leading-icon>
                        <img
                          class="tw-h-6 tw-w-auto tw-flex-shrink-0"
                          src="../../assets/svg/brands/office365.png"
                          alt=""
                        />
                      </template>

                      <template #text>
                        <p class="tw-ml-4 tw-m-0">Connect to Office365</p>
                      </template>
                    </TheButton>
                  </div>
                </template>
              </div>
            </div>
          </TheBox>
        </TheCard>
        <!-- salesforce -->
        <TheCard class="tw-max-w-lg">
          <TheBox :withPadding="true" class="tw-h-full">
            <div
              class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-2 tw-w-auto tw-p-2"
            >
              <div class="tw-flex tw-flex-col tw-gap-2 tw-w-auto tw-p-2">
                <div class="tw-flex tw-h-full tw-gap-4">
                  <!-- logo -->
                  <div class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded">
                    <img
                      src="../../assets/svg/brands/salesforce.png"
                      alt="product.imageAlt"
                      class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                    />
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                    >
                      sync contacts
                    </p>
                    <h2
                      class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                    >
                      Salesforce
                    </h2>
                  </div>
                </div>
                <!-- description -->
                <div class="tw-flex-1 tw-self-start">
                  <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex-1">
                      <p class="tw-text-xl tw-leading-8 tw-text-gray-700">
                        Bold Xchange allows you to easily bring Salesforce
                        contacts into your Bold Xchange account. Streamline your
                        workflow by connecting your Salesforce account to Bold
                        Xchange.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <!-- button -->
                <template v-if="hasSalesforceTokens">
                  <div
                    class="tw-flex tw-gap-2 tw-justify-between tw-text-lg tw-bg-green-50 tw-rounded tw-p-4"
                  >
                    <div class="tw-flex tw-gap-2 tw-items-center tw-text-lg">
                      <b-icon-check-circle-fill
                        class="tw-text-green-500"
                      ></b-icon-check-circle-fill>
                      <p
                        class="tw-m-0 tw-font-semibold tw-text-sm tw-text-text-400"
                      >
                        Account connected
                      </p>
                    </div>
                    <TheButton
                      :hasLeadingIcon="true"
                      variant="tertiary"
                      @click="startLinkingSalesforce()"
                      size="regular"
                    >
                      <template #leading-icon>
                        <img
                          class="tw-h-4 tw-w-auto tw-flex-shrink-0"
                          src="../../assets/svg/brands/salesforce.png"
                          alt=""
                        />
                      </template>

                      <template #text>
                        <p class="tw-ml-2 tw-m-0">Switch account</p>
                      </template>
                    </TheButton>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <TheButton
                      :hasLeadingIcon="true"
                      variant="tertiary"
                      @click="startLinkingSalesforce()"
                      size="large"
                    >
                      <template #leading-icon>
                        <img
                          class="tw-h-6 tw-w-auto tw-flex-shrink-0"
                          src="../../assets/svg/brands/salesforce.png"
                          alt=""
                        />
                      </template>

                      <template #text>
                        <p class="tw-ml-4 tw-m-0">Connect to Salesforce</p>
                      </template>
                    </TheButton>
                  </div>
                </template>
              </div>
            </div>
          </TheBox>
        </TheCard>
        <!-- hubspot -->
        <!-- <TheCard class="tw-max-w-lg">
          <TheBox :withPadding="true" class="tw-h-full">
            <div
              class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-2 tw-w-auto tw-p-2"
            >
              <div class="tw-flex tw-flex-col tw-gap-2 tw-w-auto tw-p-2">
                <div class="tw-flex tw-h-full tw-gap-4">
                  <div class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded">
                    <img
                      src="../../assets/svg/brands/hubspot.svg"
                      alt="product.imageAlt"
                      class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                    />
                  </div>
                  <div>
                    <p
                      class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                    >
                      sync contacts
                    </p>
                    <h2
                      class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                    >
                      HubSpot
                    </h2>
                  </div>
                </div>
                <div class="tw-flex-1 tw-self-start">
                  <div class="tw-flex tw-gap-x-2">
                    <div class="tw-flex-1">
                      <p class="tw-text-xl tw-leading-8 tw-text-gray-700">
                        Bold Xchange allows you to easily bring HubSpot contacts
                        into your Bold Xchange account. Streamline your workflow
                        by connecting your HubSpot account to Bold Xchange.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <template v-if="hasHubSpotTokens">
                  <div
                    class="tw-flex tw-gap-2 tw-justify-between tw-text-lg tw-bg-green-50 tw-rounded tw-p-4"
                  >
                    <div class="tw-flex tw-gap-2 tw-items-center tw-text-lg">
                      <b-icon-check-circle-fill
                        class="tw-text-green-500"
                      ></b-icon-check-circle-fill>
                      <p
                        class="tw-m-0 tw-font-semibold tw-text-sm tw-text-text-400"
                      >
                        Account connected
                      </p>
                    </div>
                    <TheButton
                      :hasLeadingIcon="true"
                      variant="tertiary"
                      @click="startLinkingHubSpot()"
                      size="regular"
                    >
                      <template #leading-icon>
                        <img
                          class="tw-h-4 tw-w-auto tw-flex-shrink-0"
                          src="../../assets/svg/brands/hubspot.svg"
                          alt=""
                        />
                      </template>

                      <template #text>
                        <p class="tw-ml-2 tw-m-0">Switch account</p>
                      </template>
                    </TheButton>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <TheButton
                      :hasLeadingIcon="true"
                      variant="tertiary"
                      @click="startLinkingHubSpot()"
                      size="large"
                    >
                      <template #leading-icon>
                        <img
                          class="tw-h-6 tw-w-auto tw-flex-shrink-0"
                          src="../../assets/svg/brands/hubspot.svg"
                          alt=""
                        />
                      </template>

                      <template #text>
                        <p class="tw-ml-4 tw-m-0">Connect to HubSpot</p>
                      </template>
                    </TheButton>
                  </div>
                </template>
              </div>
            </div>
          </TheBox>
        </TheCard> -->
      </div>
    </div>
  </ThePage>
</template>

<script>
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import TheCard from "../../components-v2/ui/layout/TheCard";
import TheBox from "../../components-v2/ui/layout/TheBox";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";

export default {
  name: "ViewIntegrations",
  components: {
    TheCard,
    TheBox,
    TheButton,
    ThePage,
    PageHeader,
    NotificationMessage,
  },
  data() {
    return {
      isLinking: false,
    };
  },
  async created() {
    await this.$store.dispatch("auth/refreshUser");
  },
  computed: {
    User() {
      return this?.$store?.state?.auth?.user;
    },
    hasGoogleTokens() {
      return this?.$store?.state?.auth?.user?.metadata?.google?.tokens;
    },
    hasOffice365Tokens() {
      return this?.$store?.state?.auth?.user?.metadata?.office365?.tokens;
    },
    hasSalesforceTokens() {
      return this?.$store?.state?.auth?.user?.metadata?.salesforce?.tokens;
    },
    hasHubSpotTokens() {
      return this?.$store?.state?.auth?.user?.metadata?.hubSpot?.tokens;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async startLinkingGoogle() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */

        const api = await this.getBoldClient();
        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/google`,
        };
        const response = await api.startLinkingGoogle(redirectUri);
        const googleUrlRedirect = response?.data?.url;
        window.location.href = googleUrlRedirect;
      } catch (error) {
        console.log(error);
        console.error("Failed to link google", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isLinking = false;
      }
    },
    async startLinkingOffice365() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */

        const api = await this.getBoldClient();

        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/office365`,
        };

        const response = await api.startLinkingOffice365(redirectUri);
        console.log("office365Response", response);
        const office365UrlRedirect = response?.data?.url;
        window.location.href = office365UrlRedirect;
      } catch (error) {
        console.error("Failed to link office365", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isLinking = false;
      }
    },
    async startLinkingSalesforce() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */
        const api = await this.getBoldClient();
        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/salesforce`,
        };

        const response = await api.startLinkingSalesforce(redirectUri);
        console.log("salesforceResponse", response);
        const salesforceUrlRedirect = response?.data?.url;
        window.location.href = salesforceUrlRedirect;
      } catch (error) {
        console.error("Failed to link salesforce", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isLinking = false;
      }
    },
    async startLinkingHubSpot() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */
        const api = await this.getBoldClient();
        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/hubspot`,
        };

        const response = await api.startLinkingHubSpot(redirectUri);
        console.log("hubspotResponse", response);
        const hubspotUrlRedirect = response?.data?.url;
        window.location.href = hubspotUrlRedirect;
      } catch (error) {
        console.error("Failed to link hubspot", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isLinking = false;
      }
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.checkingIfUserHasInvoices = false;
      this.isLinking = false;
      this.campaignIsSaving = false;
      this.reloadList();
      console.error(errorMessage);
    },
  },
};
</script>
<style scoped></style>
