<template>
  <FixedModalSmall>
    <div class="modal-content">
      <div class="p-2 d-flex justify-content-end">
        <button
          type="button"
          class="btn bg-soft-secondary btn-xs p-1"
          @click.prevent="$emit('cancel')"
        >
          <b-icon-x-lg class="fs-3"></b-icon-x-lg>
        </button>
      </div>
      <!-- Page Header -->
      <div class="modal-header border-bottom px-0 pt-0 pb-2">
        <h1 class="modal-title">Create new category</h1>
      </div>

      <div class="modal-body p-0 mt-5">
        <div class="row">
          <div class="col text-start">
            <!-- Name -->
            <div>
              <label for="companyName" class="form-label"
                >Category name
                <span
                  v-if="!getCategoryName"
                  class="badge bg-soft-info rounded-pill ms-2"
                  ><span class="text-danger">required</span>
                </span></label
              >

              <div
                v-if="doesCategoryExist"
                class="badge bg-warning d-flex align-items-center mb-1"
              >
                <b-icon-exclamation-circle
                  class="text-dark me-1"
                ></b-icon-exclamation-circle>
                <span class="text-dark"
                  >A category with a similar name already exists in our
                  database.</span
                >
              </div>
              <input
                type="text"
                class="form-control"
                :class="categoryClass"
                name="categoryName"
                id="categoryName"
                placeholder="Enter category name"
                aria-label="Enter category name"
                v-model="category.name"
                required
                @input="debounceCheckIfCategoryExists()"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-start px-0">
        <!-- submit button -->
        <div class="d-flex flex-column align-items-start w-100 my-2">
          <p class="text-danger" v-if="!checkIfFormIsComplete">
            Form is incomplete. Please fix invalid or missing information.
          </p>
          <div class="d-flex gap-2" v-if="!clientIsSaving && !isSuccessful">
            <button
              :disabled="!checkIfFormIsComplete"
              type="button"
              class="btn btn-primary"
              @click.prevent="saveCategory()"
            >
              Save category
            </button>
            <button
              type="button"
              class="btn btn-soft-secondary"
              @click.prevent="$emit('cancel')"
            >
              Cancel
            </button>
          </div>
          <button
            class="btn btn-primary"
            type="button"
            disabled
            v-if="clientIsSaving"
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Saving company...
          </button>
          <button
            class="btn btn-primary"
            type="button"
            disabled
            v-if="isSuccessful"
          >
            <span class="text-success"
              ><b-icon-check-circle-fill></b-icon-check-circle-fill>
            </span>
            Success!
          </button>
        </div>
      </div>
    </div>
  </FixedModalSmall>
</template>

<script>
import FixedModalSmall from "../../modals/templates/FixedModalSmall.vue";

export default {
  name: "CreateCategoryModal",
  components: {
    FixedModalSmall,
  },
  data() {
    return {
      isUrlValid: false,
      clientIsSaving: false,
      isSuccessful: false,
      doesCategoryExist: false,
      doesDomainExist: false,
      clientName: "",
      category: {
        name: "",
      },
      domain: "",
      debounceTimer: null,
    };
  },
  computed: {
    checkIfFormIsComplete() {
      return this.category.name === "" ? false : true;
    },
    getCategoryName() {
      return this.category.name === "" ? false : this.category.name;
    },
    categoryClass() {
      return {
        "is-invalid": !this.getCategoryName,
        "is-valid": this.getCategoryName,
      };
    },
  },
  watch: {
    "category.name"() {
      if (this.category.name === "") {
        this.doesCategoryExist = false;
      }
      this.doesCategoryExist = false;
    },
  },
  methods: {
    debounceCheckIfCategoryExists() {
      // Clear any previously scheduled timeouts
      clearTimeout(this.debounceTimer);

      // Get the input value
      const inputValue = this.category.name.trim(); // Trim to remove leading/trailing spaces

      // Check if the input value is not empty
      if (inputValue !== "") {
        // Schedule a new timeout to call checkIfClientExists after 500ms
        this.debounceTimer = setTimeout(() => {
          // this.checkIfCategoryExists(); // Pass the trimmed input value to the method
        }, 450);
      }
    },
    async checkIfCategoryExists() {
      /**@type {import('@boldexchange/sdk/defs').Client} */
      const api = await this.getBoldClient();
      const category = this.category.name; 

      const {
        data: {
          result: { records: categoryRes },
        },
      } = await api.listVendors(null, {
        filter: { categories: { $in: [category] } },
      });

      if (categoryRes.length !== 0) {
        this.doesCategoryExist = true;
        // this.category.name = category;
      } else {
        this.doesCategoryExist = false;
        // this.category.name = category;
      }

      // console.log("categoryRes", categoryRes);
    },
    saveCategory() {
      const category = this.category.name;
      // console.log("category to add", category);
      this.$emit("setNewCategory", category);
    },
  },
};
</script>

<style scoped></style>
