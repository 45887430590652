import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  namespaced: true,
  state() {
    return {
      quickEditActive: false,
      selectedBooking: null,
      totalSpendOnRecipient: null,
      campaignBuilderData: {},
      totalCampaignCost: 0,
      campaignBudgetTracker: null,
      selectedBudgetData: null,
      isBudgetLimitMet: false,
      campaignGiftsSelectionRequestBody: {},
      areCampaignBuilderRecipientsVisible: false
    };
  },
  mutations,
  getters,
  actions,
};
