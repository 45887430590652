<template>
  <template v-if="newBudgetModalIsVisible">
    <Teleport to="#modal">
      <TheModal
        size="small"
        :title="
          !getSessionId
            ? '1. Setup budget credit card'
            : '2. Finalize budget details'
        "
        @closeModal="resetSessionId"
        :open="newBudgetModalIsVisible"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <NewBudgetForm
          @closeModal="resetSessionId"
          @cancelBudget="resetSessionId"
          :sessionId="getSessionId"
          @setupPaymentMethod="handlePaymentMethodSetup"
          @handleSuccess="handleSuccess"
        ></NewBudgetForm>
      </TheModal>
    </Teleport>
  </template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>
  <PageHeader :showActions="true" title="Budgets">
    <template #page-header-actions>
      <TheButton
        @click="toggleNewBudgetModal()"
        size="large"
        :hasLeadingIcon="true"
        variant="primary"
      >
        <template #text> Create budget </template>
        <template #leading-icon><b-icon-plus-lg></b-icon-plus-lg> </template>
      </TheButton>
    </template>
  </PageHeader>
  <ThePage customWidthClass="tw-w-full tw-mx-auto tw-gap-5">
    <BlockStack :gap="500" class="tw-w-full">
      <BudgetsList ref="budgetsListRef"></BudgetsList>
    </BlockStack>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import BudgetsList from "../../components-v2/budgets/BudgetsList.vue";
import NewBudgetForm from "../../components-v2/ui/modals/NewBudgetForm.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";

export default {
  name: "ViewBudgets",
  components: {
    TheModal,
    NewBudgetForm,
    BudgetsList,
    TheButton,
    BlockStack,
    ThePage,
    PageHeader,
    NotificationMessage,
  },
  data() {
    return {
      budgets: [],
      newBudgetModalIsVisible: false,
      selectedEmoji: null,
      debounceTimer: null,
      budgetSessionId: null,
      isCheckingForBudgets: false,
    };
  },
  async mounted() {
    await this.handleCreatedLifecycleLogic();
  },
  computed: {
    user() {
      return this?.$store?.state?.auth?.user;
    },
    budgetNameValid() {
      return this.budget.name.trim().length > 0 ? this.budget.name : false;
    },
    budgetName() {
      return this.budget.name.trim();
    },
    budgetLimit() {
      return parseInt(this?.budget?.limit) || 0;
    },
    budgetPeriod() {
      return this.budget.period;
    },
    budgetLimitInPennies() {
      const limit = parseInt(this?.budget?.limit, 10);
      return isNaN(limit) ? 0 : limit * 100;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    getSessionId() {
      return this.budgetSessionId;
    },
  },
  methods: {
    /** handle payment method setup */
    async handlePaymentMethodSetup() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();
      const successUrl =
        window.location.origin + `/budgets/{CHECKOUT_SESSION_ID}`;
      const cancelUrl = window.location.origin + `/budgets/cancelled`;

      const paymentMethodRes = await api.setupPaymentMethod({
        successUrl: successUrl,
        cancelUrl: cancelUrl,
      });
      const paymentUrl = paymentMethodRes.data;
      window.location.href = paymentUrl.url;
    },

    /** modal */
    toggleNewBudgetModal() {
      this.newBudgetModalIsVisible = !this.newBudgetModalIsVisible;
    },
    closeModal() {
      this.newBudgetModalIsVisible = false;
    },

    async resetSessionId() {
      this.budgetSessionId = null;
      this.newBudgetModalIsVisible = false;
      this.$router.replace({ path: `/budgets` });
      // this.callCheckForBudgets();
    },

    /** handle success and errors */
    handleSuccess() {
      this.resetSessionId();
      this.showSuccessNotification();
      this.callCheckForBudgets();
    },
    showSuccessNotification() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Your budget was successfully created.",
        duration: 2000,
        id: Date.now(),
      });
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again.";
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      this.checkingIfUserHasInvoices = false;
      this.isLinking = false;
      this.campaignIsSaving = false;
      console.error(errorMessage);
    },

    /** helper method for checking budgets */
    async callCheckForBudgets() {
      if (this.isCheckingForBudgets) return;
      this.isCheckingForBudgets = true;
      await this.$nextTick();
      if (this.$refs.budgetsListRef) {
        await this.$refs.budgetsListRef.checkForBudgets();
      } else {
        console.warn("BudgetsListRef is not yet available.");
      }
      this.isCheckingForBudgets = false;
    },

    /** created logic */
    async handleSessionIdLogic() {
      const sessionId = this.$route.params.CHECKOUT_SESSION_ID;
      if (sessionId && sessionId !== "cancelled") {
        this.budgetSessionId = sessionId;
        this.toggleNewBudgetModal();
      } else {
        this.resetSessionId();
        this.callCheckForBudgets();
      }
    },
    async handleCreatedLifecycleLogic() {
      await this.$nextTick();
      await this.handleSessionIdLogic();
    },
  },
};
</script>
<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
