<template>
  <div id="BxAppContent">
    <template v-if="this.isBoldXchangeAdmin || this.isGiftingLead">
      <admin-navigation></admin-navigation>
    </template>
    <template v-if="this.isUserAnonymous">
      <AnonymousNavigation></AnonymousNavigation>
    </template>
    <template v-if="this.isGiftCardRecipient">
      <RecipientsNavigation></RecipientsNavigation>
    </template>
  </div>
</template>

<script>
import AdminNavigation from "./components-v2/ui/navigation/admin/AdminNavigation.vue";
import AnonymousNavigation from "./components-v2/ui/navigation/anonymous-users/AnonymousNavigation.vue";
import RecipientsNavigation from "./components-v2/ui/navigation/recipients-users/RecipientsNavigation";

export default {
  name: "App",
  components: {
    AdminNavigation,
    AnonymousNavigation,
    RecipientsNavigation
  },
  async mounted() {
    const doTrack = this.mixpanel.track;
    this.$router.afterEach((to, from, failure) => {
      if (!failure) {
        this.mixpanel.track("Navigation", {
          to: to.fullPath,
          from: from.fullPath,
        });
      }
    });
    window.onclick = (evt) => {
      /**
       * @type {Element}
       */
      const el = evt.target;
      if (el.classList.contains("clickable")) {
        const action = el.innerText;
        const meta = el.dataset?.meta;
        this.mixpanel.track("Button Click", {
          action,
          meta,
        });
      }
    };
    const { $store } = this;
    this.mixpanel.track = function (p0, p1) {
      const args = [
        p0,
        {
          ...p1,
          user: $store?.state?.auth?.user?.id,
          userEmail: $store?.state?.auth?.user?.email,
          userFirstName: $store?.state?.auth?.user?.firstName,
        },
      ];
      console.log("args", { args });
      doTrack.bind(this)(...args);
    };
    // this.getBoldClient();
    if (!this.$store.state.auth.user) {
      const jwt = window.localStorage.getItem("jwt");

      if (jwt) {
        this.$store.commit("auth/SET_TOKEN", jwt);
        try {
          await this.$store.dispatch("auth/refreshUser");
        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  methods: {},
};
</script>

<style>
/* Global styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  height: 100vh;
}

html {
  scrollbar-gutter: stable;
}

body {
  background-color: white;
  /* margin: 0px !important;  
  padding: 0px !important; */
}

main {
  background-color: white;
  height: 100vh;
  flex: flow-root;
  /* margin: 0px !important;  
  padding: 0px !important; */
}

#app {
  /* margin: 0;
  padding: 0; */
}

/* App container */
#BxAppContent {
  background-color: white;
  display: flex;
  flex-direction: column;
  /* margin-left: auto;
  margin-right: auto; */
  height: 100vh;
}

/* Footer */
footer {
  margin-top: auto;
}

/* Background and text colors */
.bg-bold-xchange-blue {
  background: #004aad;
}

.bg-bold-xchange-yellow {
  background: #ffcc48;
}

.bg-bold-xchange-orange {
  background: #ffae42;
}

.text-bold-xchange-blue {
  color: #004aad;
}

.text-bold-xchange-yellow {
  color: #ffcc48;
}

.text-bold-xchange-orange {
  color: #ffae42;
}

/* Button styles */
.btn-orange {
  background: #ffae42;
}
</style>
