<template>
  <TheCard class="tw-overflow-visible tw-w-full">
    <CardHeader
      :showTitle="true"
      :showSubheading="true"
      :showActions="true"
      :showBorder="true"
      title="Campaign Builder Parameters"
    >
      <template #subheading-content>
        <p class="tw-m-0 tw-text-sm tw-text-gray-800 tw-w-sm">
          Optimize your campaign builder workflow by setting up some default
          campaign settings.
        </p>
      </template>
      <template #card-header-actions>
        <template v-if="!isEditingDetails">
          <TheButton
            variant="primary"
            size="small"
            @click="editMemberDetails()"
            :hasLeadingIcon="true"
          >
            <template #leading-icon><b-icon-pencil /></template>
            <template #text> Edit </template>
          </TheButton>
        </template>
        <template v-if="isEditingDetails">
          <div class="tw-flex tw-gap-2">
            <TheButton
              :disabled="isSaving"
              variant="success"
              size="small"
              @click="upsertUser()"
            >
              <template #text> Save </template>
            </TheButton>
            <TheButton
              variant="tertiary"
              size="small"
              @click="cancelEdit()"
              :hasLeadingIcon="true"
            >
              <template #text> Cancel </template>
            </TheButton>
          </div>
        </template>
      </template>
    </CardHeader>
    <TheBox :withPadding="true">
      <BlockStack :gap="400">
        <div
          class="sm:tw-flex tw-items-start tw-border-solid tw-border-[0.5px] tw-border-gray-100 tw-border-x-0 tw-border-t-0 tw-pb-4"
        >
          <div
            class="tw-flex tw-items-center tw-gap-2 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
          >
            <p class="tw-text-gray-900 tw-m-0">Home Region</p>
            <TheTooltip :toolTip="true" :tooltipContent="regionTooltipContent">
              <template #icon>
                <b-icon-question-circle
                  class="tw-text-black tw-text-xs tw-z-0 tw-cursor-pointer"
                ></b-icon-question-circle>
              </template>
            </TheTooltip>
          </div>
          <div
            class="tw-flex tw-items-top tw-gap-2 tw-w-full tw-mt-2 sm:tw-m-0"
          >
            <ButtonDropdown
              :disabled="!isEditingDetails"
              :id="'home-region-dropdown'"
              :items="toggleRegionOptions()"
              :buttonText="
                userMixinData?.metadata?.campaignSettings?.homeRegions?.length >
                0
                  ? userMixinData?.metadata?.campaignSettings?.homeRegions[0]
                  : 'Select home region'
              "
              :alignLeft="true"
              :active="false"
              :hasTrailingIcon="true"
              size="small"
              variant="secondary"
            />
          </div>
        </div>
        <div class="sm:tw-flex tw-items-start">
          <div
            class="tw-flex tw-items-center tw-gap-2 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6"
          >
            <p class="tw-text-gray-900 tw-m-0">Gift Card Amount</p>
          </div>
          <BlockStack :gap="400">
            <BlockStack :gap="200">
              <div class="tw-relative">
                <div
                  class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-px-2.5"
                >
                  <b-icon-currency-dollar
                    class="tw-text-gray-400"
                    aria-hidden="true"
                  ></b-icon-currency-dollar>
                </div>
                <input
                  v-model="
                    userMixinData.metadata.campaignSettings.giftCardAmount.value
                  "
                  @input="handleCustomAmountInput"
                  type="text"
                  name="giftCardAmount"
                  id="giftCardAmount"
                  :class="{
                    'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6 tw-cursor-not-allowed':
                      !isEditingDetails,
                    'tw-block tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-leading-6':
                      isEditingDetails,
                  }"
                  class="tw-w-sm tw-rounded-md tw-border-0 tw-pl-8 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-sm tw-leading-6"
                  placeholder="Dollar amount"
                  :readonly="!isEditingDetails"
                />
              </div>
              <template v-if="isEditingDetails">
                <BlockStack :gap="100">
                  <span class="tw-isolate tw-inline-flex tw-rounded-md">
                    <button
                      type="button"
                      @click="setGiftCardAmount(5)"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-w-16 tw-relative tw-inline-flex tw-items-center tw-justify-center tw-rounded-l-md tw-bg-white tw-text-xs tw-py-2 tw-px-2 tw-text-center tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      $5
                    </button>
                    <button
                      type="button"
                      @click="setGiftCardAmount(10)"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-w-16 tw--ml-px tw-inline-flex tw-items-center tw-justify-center tw-bg-white tw-text-xs tw-py-2 tw-px-2 tw-text-center tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      $10
                    </button>
                    <button
                      type="button"
                      @click="setGiftCardAmount(25)"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-w-16 tw--ml-px tw-inline-flex tw-items-center tw-justify-center tw-bg-white tw-text-xs tw-py-2 tw-px-2 tw-text-center tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      $25
                    </button>
                    <button
                      type="button"
                      @click="setGiftCardAmount(50)"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-w-16 tw--ml-px tw-inline-flex tw-items-center tw-justify-center tw-bg-white tw-text-xs tw-py-2 tw-px-2 tw-text-center tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      $50
                    </button>
                    <button
                      type="button"
                      @click="setGiftCardAmount(75)"
                      class="tw-border-none disabled:tw-bg-slate-300 tw-relative tw-w-16 tw--ml-px tw-inline-flex tw-items-center tw-justify-center tw-bg-white tw-text-xs tw-py-2 tw-px-2 tw-text-center tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      $75
                    </button>
                    <button
                      type="button"
                      @click="setGiftCardAmount(100)"
                      class="tw-border-none tw-relative tw--ml-px tw-inline-flex tw-w-16 tw-items-center tw-rounded-r-md tw-bg-white tw-text-xs tw-py-2 tw-px-2 tw-text-center tw-font-semibold tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10"
                    >
                      $100
                    </button>
                  </span>
                </BlockStack>
              </template>
            </BlockStack>
            <template
              v-if="userMixinData.metadata.campaignSettings.giftCardAmount"
            >
              <BlockStack :gap="200" class="tw-bg-gray-100 tw-p-2">
                <p class="tw-text-gray-900 tw-m-0">
                  Apply the default gift card amount to new campaigns and templates? This can
                  be changed in the campaign builder if set to 'Yes'.
                </p>
                <BlockStack :gap="100">
                  <div class="tw-flex tw-items-center tw-space-x-4">
                    <label class="tw-flex tw-items-center tw-space-x-2">
                      <input
                        type="radio"
                        v-model="
                          userMixinData.metadata.campaignSettings.giftCardAmount
                            .applyToNewCampaigns
                        "
                        :value="true"
                        class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                        :disabled="!isEditingDetails"
                      />
                      <span class="tw-text-gray-700">Yes</span>
                    </label>
                    <label class="tw-flex tw-items-center tw-space-x-2">
                      <input
                        type="radio"
                        v-model="
                          userMixinData.metadata.campaignSettings.giftCardAmount
                            .applyToNewCampaigns
                        "
                        :value="false"
                        class="tw-form-radio tw-text-blue-600 tw-h-4 tw-w-4 tw-transition tw-duration-150 tw-ease-in-out"
                        :disabled="!isEditingDetails"
                      />
                      <span class="tw-text-gray-700">No (default)</span>
                    </label>
                  </div>
                </BlockStack>
              </BlockStack>
            </template>
          </BlockStack>
        </div>
      </BlockStack>
    </TheBox>
  </TheCard>
</template>

<script>
import TheBox from "../../components-v2/ui/layout/TheBox";
import TheCard from "../../components-v2/ui/layout/TheCard";
import BlockStack from "../../components-v2/ui/layout/BlockStack";
import CardHeader from "../../components-v2/ui/layout/CardHeader";
import TheButton from "../ui/actions/TheButton.vue";
import TheTooltip from "../../components-v2/ui/forms/TheTooltip.vue";
import ButtonDropdown from "../../components-v2/ui/elements/dropdown/ButtonDropdown.vue";
import userMixin from "../../mixins/userMixin";

export default {
  name: "UserCampaignSettings",
  components: {
    BlockStack,
    ButtonDropdown,
    TheTooltip,
    TheButton,
    TheBox,
    TheCard,
    CardHeader,
  },
  mixins: [userMixin],
  data() {
    return {
      regions: [
        "Baltimore",
        "Birmingham",
        "Charlotte",
        "Columbia",
        "Kansas City",
        "Louisville",
        "St. Louis",
      ],
      isEditingDetails: false,
      isSaving: false,
    };
  },
  computed: {
    campaignSettingsHomeRegions() {
      return this?.userMixinData?.metadata?.campaignSettings?.homeRegions || [];
    },
    campaignSettingsGiftCardAmount() {
      return (
        this?.userMixinData?.metadata?.campaignSettings?.giftCardAmount
          ?.value || 0
      );
    },
    campaignSettingsGiftCardAmountApplyToNewCampaigns() {
      return this.userMixinData.metadata.campaignSettings.giftCardAmount
        .applyToNewCampaigns;
    },
    regionTooltipContent() {
      return "Setting your home region helps you quickly find eGift cards from local vendors relevant to your network, making campaign building and gift selection more efficient.";
    },
    regionOptions() {
      // Sort the regions alphabetically
      const sortedRegions = this.regions.slice().sort();
      return sortedRegions.map((region, index) => ({
        id: index + 1,
        label: region,
        action: () => this.setHomeRegions([region]),
      }));
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    async upsertUser() {
      this.isSaving = true;
      const api = await this.getBoldClient();

      const memberToUpdate = {
        ...(this.campaignSettingsHomeRegions && {
          "metadata.campaignSettings.homeRegions":
            this.campaignSettingsHomeRegions,
        }),
        "metadata.campaignSettings.giftCardAmount.value":
          this.campaignSettingsGiftCardAmount,

        "metadata.campaignSettings.giftCardAmount.applyToNewCampaigns":
          this.campaignSettingsGiftCardAmountApplyToNewCampaigns,
      };

      try {
        await api.upsertUser(null, {
          filter: { _id: this.User?._id },
          patch: memberToUpdate,
        });
        this.handleSuccess();
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },

    setHomeRegions(regions) {
      this.userMixinData.metadata.campaignSettings.homeRegions = regions;
    },
    editMemberDetails() {
      this.isEditingDetails = !this.isEditingDetails;
    },
    cancelEdit() {
      this.initializeForm(this.User);
      this.isEditingDetails = false;
    },
    toggleLandingPagePreview() {
      this.showLandingPagePreview = !this.showLandingPagePreview;
    },
    toggleRegionOptions() {
      return this.regionOptions;
    },
    handleCustomAmountInput(event) {
      let value = event.target.value;
      const trimmedValue = value.trim();
      value = parseFloat(trimmedValue);
      let giftCardAmount = 0;

      if (!Number.isNaN(value) && value >= 5 && Number.isSafeInteger(value)) {
        giftCardAmount = Math.round(value);
        value = giftCardAmount;
        this.setGiftCardAmount(giftCardAmount);
        this.clearTimeoutIfNeeded();
      } else {
        this.clearTimeoutIfNeeded();
        this.timeoutId = setTimeout(() => {
          value = 0;
          this.setGiftCardAmount(giftCardAmount);
        }, 350);
      }
    },
    clearTimeoutIfNeeded() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
    setGiftCardAmount(giftCardAmount) {
      this.userMixinData.metadata.campaignSettings.giftCardAmount.value =
        giftCardAmount;
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again.";
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000,
        id: Date.now(),
      });

      console.error(errorMessage);
    },
    handleSuccess() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Profile successfully updated.",
        duration: 6000,
        id: Date.now(),
      });
      this.isEditingDetails = false;
      this.refreshUser();
    },
  },
};
</script>

<style scoped></style>
