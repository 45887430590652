<template>
  <Teleport to="#modal">
    <TheModal @closeModal="closeModal" :open="showDeleteModal">
      <ModalHeader title="Modal title" :showActions="true">
        <template #card-header-actions>
          <CircleButton variant="tertiary" @click="closeModal">
            <template #icon
              ><b-icon-x-lg class="tw-text-black tw-z-0"></b-icon-x-lg
            ></template>
          </CircleButton>
        </template>
      </ModalHeader>
      <ModalBody>
        <DeleteData :recipients="recipientIdToDelete"></DeleteData>
      </ModalBody>
    </TheModal>
  </Teleport>
  <PageHeader :showActions="true" title="Members">
    <template #page-header-actions>
      <TheButton
        type="button"
        :hasLeadingIcon="true"
        variant="primary"
        size="large"
        :isRouterLink="true"
        to="/add/member"
      >
        <template #text>Create member</template>
        <template #leading-icon><b-icon-plus-lg></b-icon-plus-lg></template>
      </TheButton>
    </template>
  </PageHeader>
  <ThePage customWidthClass="tw-w-full tw-max-w-8xl">
    <!-- <MembersList></MembersList> -->
    <MembersIndexTable></MembersIndexTable>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import TheButton from "../../components-v2/ui/actions/TheButton";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import ModalHeader from "../../components-v2/ui/layout/ModalHeader.vue";
import MembersList from "../../components-v2/members/MembersList.vue";
import MembersIndexTable from "../../components-v2/members/MembersIndexTable.vue";

export default {
  name: "ViewMembers",
  components: {
    MembersIndexTable,
    MembersList,
    ThePage,
    PageHeader,
    TheButton,
    TheModal,
    ModalHeader,
    ModalBody,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
