<template>
  <template v-if="isRouterLink">
    <router-link
      :to="url"
      :class="computedClasses"
      :aria-label="accessibilityLabel"
      :aria-controls="ariaControls"
      :aria-expanded="ariaExpanded"
      :aria-describedby="ariaDescribedBy"
      :aria-checked="ariaChecked"
      :role="role"
      :id="id"
      :data-primary-link="dataPrimaryLink"
      :data-external="external"
      :target="computedTarget"
      :download="download"
      :type="submit ? 'submit' : 'button'"
      @click.stop="handleClick"
    >
      <template v-if="hasLeadingIcon">
        <slot name="leading-icon"></slot>
      </template>
      <slot name="text"></slot>
      <template v-if="hasTrailingIcon">
        <slot name="trailing-icon"></slot>
      </template>
      <span v-if="loading" class=""></span>
    </router-link>
  </template>
  <template v-else
    ><a
      :href="external ? url : null"
      :class="computedClasses"
      :aria-label="accessibilityLabel"
      :aria-controls="ariaControls"
      :aria-expanded="ariaExpanded"
      :aria-describedby="ariaDescribedBy"
      :aria-checked="ariaChecked"
      :role="role"
      :id="id"
      :data-primary-link="dataPrimaryLink"
      :data-external="external"
      :target="computedTarget"
      :download="download"
      :type="submit ? 'submit' : 'button'"
      @click.stop="handleClick"
    >
      <template v-if="hasLeadingIcon">
        <slot name="leading-icon"></slot>
      </template>
      <slot name="text"></slot>
      <template v-if="hasTrailingIcon">
        <slot name="trailing-icon"></slot>
      </template>
      <span v-if="loading" class=""></span> </a
  ></template>
</template>

<script>
export default {
  name: "TheLink",
  props: {
    role: {
      type: String,
      default: "button",
    },
    id: {
      type: String,
      default: "",
    },
    isRouterLink: {
      type: Boolean,
      default: false,
    },
    url: {
      type: [String, Object],
      required: false,
      validator(value) {
        if (typeof value === "object" && (!value.name || !value.params)) {
          console.error(
            'Invalid url prop passed to TheLink. Object type url must include "name" and "params".'
          );
          return false;
        }
        return true;
      },
    },
    size: {
      type: String,
      default: "regular",
    },
    hasLeadingIcon: {
      type: Boolean,
      default: false,
    },
    hasTrailingIcon: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    accessibilityLabel: {
      type: String,
      default: "",
    },
    ariaControls: {
      type: String,
      default: "",
    },
    ariaExpanded: {
      type: Boolean,
      default: false,
    },
    ariaDescribedBy: {
      type: String,
      default: "",
    },
    ariaChecked: {
      type: String,
      default: "false",
    },
    dataPrimaryLink: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
    },
    target: {
      type: String,
      default: "",
    },
    download: {
      type: [String, Boolean],
      default: null,
    },
    submit: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: null,
    },
    onFocus: {
      type: Function,
      default: null,
    },
    onBlur: {
      type: Function,
      default: null,
    },
    onKeyPress: Function,
    onKeyUp: Function,
    onKeyDown: Function,
    onMouseEnter: Function,
    onTouchStart: Function,
    onPointerDown: Function,
  },
  computed: {
    computedClasses() {
      return {
        // style classes
        ...this.styleClass,
        // size classes
        ...this.sizeClass,
      };
    },
    computedTarget() {
      return this.external ? "_blank" : null; // Set target to '_blank' if external is true
    },
    sizeClass() {
      return {
        "tw-text-xs": this.size === "small",
        "tw-text-sm": this.size === "regular",
        "tw-text-lg": this.size === "large",
      };
    },
    styleClass() {
      return {
        "tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-gray-900 hover:tw-text-gray-700 tw-underline":
          this.variant === "tertiary",
        "tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-blue-900 hover:tw-text-blue-700 tw-underline":
          this.variant === "primary",
        "tw-flex tw-gap-2 tw-items-center tw-font-bold tw-text-red-900 hover:tw-text-red-700 tw-underline":
          this.variant === "critical",
      };
    },
  },
  methods: {
    handleClick(event) {
      if (this.onClick) {
        this.onClick(event);
      }
    },
  },
};
</script>

<style scoped>
/* Add your button styles here */
</style>
