<template>
  <!-- loading display -->
  <div class="d-flex justify-content-center align-items center p-10">
  <div class="d-flex justify-content-center align-items-center">
    <h1 class="display-1 flash-icon">
      <b-icon-gift></b-icon-gift>
    </h1>
  </div>
</div>
</template>

<script>
export default {};
</script>

<style scoped>
/* CSS for flashing icon */

.flash-icon {
  opacity: 0;
  animation: flash 0.6s infinite alternate;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
