<template>
  <BlockStack :gap="500" class="tw-items-center tw-justify-center tw-flex">
    <BlockStack :gap="100" :fullWidth="true">
      <h1 class="tw-text-5xl tw-text-center">&#127881;</h1>
      <h1
        class="tw-m-0 tw-text-2xl tw-font-bold text-gray-900 tw-text-center sm:tw-text-4xl tw-text-wrap"
      >
        Thanks for redeeming!
      </h1>
      <p class="tw-m-0 tw-text-lg tw-text-gray-900">
        Your gift card, courtesy of
        <span class="tw-capitalize">{{ campaignFromName }}</span
        >, will arrive shortly via email. Enjoy!
      </p>
    </BlockStack>
    <div>
      <TheButton
        :isRouterLink="true"
        to="/gifts"
        variant="primary"
        size="large"
      >
        <template #text>Close</template>
      </TheButton>
    </div>
  </BlockStack>
</template>

<script>
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheButton from "../../../components-v2/ui/actions/TheButton.vue";

export default {
  name: "RedeemedGiftCardContent",
  components: {
    TheButton,
    BlockStack,
  },
  props: {
    campaign: {
      type: Object,
      required: false,
    },
    vendor: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    vendorName() {
      return this.vendor.name;
    },
    campaignFromName() {
      return this?.campaign?.from?.name;
    },
  },
  methods: {
    /** scroll behavior */
    // handleScroll() {
    //   this.scrollPositionIsAtTop =
    //     document.querySelector("#giftCardContent").scrollTop === 0;
    // },
  },
};
</script>

<style scoped></style>
