<template>
  <div class="tw-relative" ref="tagsDropdown">
    <TheButton
      :disabled="disabled"
      :active="active"
      :variant="variant"
      :size="size"
      class="tw-relative tw-w-full"
      @click.prevent="handleToggleDropdown()"
      :hasTrailingIcon="true"
    >
      <template #trailing-icon>
        <b-icon-chevron-down
          :class="[
            isOpen ? 'tw-rotate-180' : '',
            'tw-transition-transform tw-duration-200 tw-ml-auto',
          ]"
        ></b-icon-chevron-down>
      </template>
      <template #text>
        <span class="tw-font-normal">{{ buttonText }}</span>
      </template>
    </TheButton>

    <ul
      class="tw-z-[100] tw-absolute tw-right-0 tw-divide-y tw-divide-solid tw-divide-gray-200 tw-divide-x-0 tw-overflow-hidden tw-mt-2 tw-w-full tw-min-w-max tw-border-solid tw-border-[0.5px] tw-bg-white tw-rounded-md tw-shadow-lg tw-list-none tw-p-0 tw-m-0"
      :class="{ 'tw-hidden': !isOpen }"
    >
      <input
        type="text"
        v-model="search"
        @input="filterOptions"
        placeholder="search or create a new tag"
        class="tw-block tw-w-full tw-rounded-md tw-rounded-br-none tw-rounded-bl-none tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
      />
      <ul class="tw-list-none tw-p-0 tw-m-0 tw-overflow-y-auto tw-max-h-40">
        <li
          v-for="option in filteredOptions"
          :key="option"
          @click="toggleTag(option)"
          class="tw-p-2 tw-cursor-pointer hover:tw-bg-gray-100 tw-flex tw-items-center"
        >
          <input
            type="checkbox"
            :checked="isSelected(option)"
            class="tw-mr-2"
          />
          {{ option }}
        </li>
      </ul>
      <div
        v-if="
          search &&
          !filteredOptions.some(
            (option) => option.toLowerCase() === search.toLowerCase()
          )
        "
        @click="createTag()"
        class="tw-p-2 tw-cursor-pointer tw-flex tw-items-center hover:tw-bg-gray-100 tw-text-gray-800"
      >
        <b-icon-plus-lg class="tw-mr-2" /> Create tag: "{{ search }}"
      </div>
    </ul>
    <div v-if="selectedTags.length > 0" class="tw-mt-4">
      <div class="tw-flex tw-gap-2 tw-flex-wrap">
        <div v-for="(tag, key) in selectedTags" :key="'tag-' + key">
          <span
            class="tw-px-2 tw-py-1 tw-rounded"
            :class="{
              'tw-cursor-not-allowed tw-text-gray-800 tw-bg-gray-100': disabled,
              'tw-cursor-pointer tw-text-gray-800 tw-bg-green-100': !disabled,
            }"
            @click="!disabled && removeTag(tag)"
          >
            <b-icon-x-circle
              v-if="!disabled"
              class="tw-text-red-500 tw-mr-1"
            ></b-icon-x-circle>
            <span>{{ tag }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from "../../actions/TheButton.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "MultiSelectDropdown",
  emits: ["update:modelValue"],
  components: { TheButton },
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Select tags",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hasLeadingIcon: {
      type: Boolean,
      default: false,
    },
    hasTrailingIcon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "regular",
    },
    variant: {
      type: String,
      default: "tertiary",
    },
    buttonText: {
      type: String,
      default: "Add dropdown button title",
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      selectedTags: [...this.modelValue],
      filteredOptions: [...this.options],
    };
  },
  watch: {
    modelValue(newValue) {
      this.selectedTags = [...newValue];
    },
    options(newOptions) {
      this.filteredOptions = [...newOptions];
    },
    isOpen(newVal) {
      if (newVal) {
        this.setupClickAwayListener();
      } else {
        this.removeClickAwayListener();
      }
    },
  },
  methods: {
    ...mapActions("elements", ["toggleDropdown", "closeDropdown"]),
    handleToggleDropdown() {
      this.toggleDropdown(this.id);
      if (!this.isOpen) {
        this.setupClickAwayListener();
      } else {
        this.removeClickAwayListener();
      }
    },
    removeTag(tag) {
      const index = this.selectedTags.findIndex(
        (selectedTag) => selectedTag.toLowerCase() === tag.toLowerCase()
      );
      console.log(`Removing tag: ${tag} at index: ${index}`); // Debugging
      if (index > -1) {
        this.selectedTags.splice(index, 1);
        this.updateSelectedTags();
      }
    },
    setupClickAwayListener() {
      document.addEventListener("mousedown", this.handleClickAway);
    },
    removeClickAwayListener() {
      document.removeEventListener("mousedown", this.handleClickAway);
    },
    handleClickAway(event) {
      const dropdown = this.$refs.tagsDropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    },
    filterOptions() {
      const searchLower = this.search.toLowerCase();
      this.filteredOptions = this.options.filter((option) =>
        option.toLowerCase().includes(searchLower)
      );
    },
    toggleTag(tag) {
      const index = this.selectedTags.findIndex(
        (selectedTag) => selectedTag.toLowerCase() === tag.toLowerCase()
      );
      console.log(`Toggling tag: ${tag} at index: ${index}`); // Debugging
      if (index > -1) {
        this.selectedTags.splice(index, 1);
      } else {
        this.selectedTags.push(tag);
      }
      this.updateSelectedTags();
    },
    isSelected(tag) {
      const selected = this.selectedTags.some(
        (selectedTag) => selectedTag.toLowerCase() === tag.toLowerCase()
      );
      return selected;
    },
    createTag() {
      const searchLower = this.search.toLowerCase();
      const exists = this.selectedTags.some(
        (selectedTag) => selectedTag.toLowerCase() === searchLower
      );
      console.log(`Creating tag: ${this.search} - Exists: ${exists}`); // Debugging
      if (!exists && this.search) {
        this.selectedTags.push(this.search);
        this.search = "";
        this.filterOptions();
        this.updateSelectedTags();
      }
    },
    updateSelectedTags() {
      this.$emit("update:modelValue", this.selectedTags);
    },
  },
  computed: {
    ...mapState("elements", {
      openDropdownId: (state) => state.openDropdownId,
    }),
    isOpen() {
      return this.openDropdownId === this.id;
    },
  },
  beforeUnmount() {
    this.removeClickAwayListener();
  },
};
</script>

<style scoped>
/* No custom styles needed */
</style>
