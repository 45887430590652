<template>
  <div class="tw-min-h-full">
    <TheSidebarV2> </TheSidebarV2>
    <TopNavigationBar></TopNavigationBar>
    <main class="tw-flex-1 tw-pb-8 tw-h-full lg:tw-pl-52">
      <div class="tw-h-auto tw-bg-stone-100 tw-min-h-[100vh]">
        <router-view></router-view>
      </div>
    </main>
    <!-- <TheFooter></TheFooter> -->
  </div>
</template>

<script>
import TheSidebarV2 from "../../../../components-v2/ui/navigation/TheSidebarV2";
import TopNavigationBar from "../../../../components-v2/ui/navigation/TopNavigationBar";
export default {
  name: "AdminNavigation",
  components: {
    TopNavigationBar,
    TheSidebarV2,
  },

  computed: {
    User() {
      return this.$store.state.auth.user;
    },
    Avatar() {
      const url = this.User?.photo;
      if (url) {
        if (url.indexOf("/") === 0) {
          return process.env.VUE_APP_API_BASE_URL + url;
        } else {
          return url;
        }
      } else {
        return `https://avatars.dicebear.com/api/initials/${this.User.firstName}+${this.User.lastName}.svg`;
      }
    },
  },
  methods: {
    logout() {
      this.$store.commit("auth/LOGOUT");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped></style>
