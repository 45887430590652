<template>
  <!-- content container -->
  <div class="tw-bg-white tw-shadow tw-p-4 sm:tw-p-6">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ContentBox",
  components: {},
  props: {},
  computed: {},
};
</script>
