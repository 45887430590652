<template>
  <div class="tw-p-4 sm:tw-p-6 tw-w-full "
  :class="{'tw-border-solid tw-border-[2px] tw-border-gray-100 tw-border-x-0 tw-border-t-0' : showBorder}"
  >
    <template v-if="showTitle && useSearchBar">
      <BlockStack :gap="300" class="tw-flex-grow">
        <template v-if="showTitle || showSubheading">
          <BlockStack :gap="100">
            <template v-if="showStrapline">
              <p
                class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-500 sm:tw-truncate sm:tw-text-lg sm:tw-tracking-tight"
              >
                <slot name="strapline-content"> </slot>
              </p>
            </template>
            <!-- heading -->
            <template v-if="title">
              <h1
                class="tw-m-0 tw-text-lg tw-font-bold sm:tw-text-xl sm:tw-tracking-tight tw-text-wrap"
                :class="titleClass"
              >
                {{ title }}
              </h1>
            </template>

            <template v-if="showSubheading">
              <slot name="subheading-content"> </slot>
            </template>
          </BlockStack>
        </template>
      </BlockStack>
      <div class="tw-flex gap-2 tw-w-full tw-flex-wrap tw-items-center">
        <div class="tw-flex-grow">
          <template v-if="useSearchBar">
            <slot name="card-search-bar"></slot>
          </template>
        </div>
        <template v-if="showActions">
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <slot name="card-header-actions"></slot>
          </div>
        </template>
      </div>
    </template>
    <template v-if="!showTitle && useSearchBar">
      <div class="tw-flex gap-2 tw-w-full tw-flex-wrap tw-items-center">
        <div class="tw-flex-grow">
          <template v-if="useSearchBar">
            <slot name="card-search-bar"></slot>
          </template>
        </div>
        <template v-if="showActions">
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <slot name="card-header-actions"></slot>
          </div>
        </template>
      </div>
    </template>
    <template v-if="showTitle && !useSearchBar">
      <div class="tw-flex gap-2 tw-w-full tw-flex-wrap tw-items-center">
        <BlockStack :gap="300" class="tw-flex-grow">
          <template v-if="showTitle || showSubheading">
            <BlockStack :gap="100">
              <template v-if="showStrapline">
                <p
                  class="tw-m-0 tw-text-sm tw-font-bold tw-text-blue-500 sm:tw-truncate sm:tw-text-lg sm:tw-tracking-tight"
                >
                  <slot name="strapline-content"> </slot>
                </p>
              </template>
              <!-- heading -->
              <template v-if="title">
                <h1
                  class="tw-m-0 tw-text-lg tw-font-bold sm:tw-text-xl sm:tw-tracking-tight tw-text-wrap"
                  :class="titleClass"
                >
                  {{ title }}
                </h1>
              </template>

              <template v-if="showSubheading">
                <slot name="subheading-content"> </slot>
              </template>
            </BlockStack>
          </template>
        </BlockStack>
        <template v-if="showActions">
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            <slot name="card-header-actions"></slot>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import BlockStack from "./BlockStack.vue";

export default {
  name: "CardHeader",
  components: { BlockStack },
  props: {
    title: {
      type: String,
      default: null,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    /**
     * Makes search bar slot accessible when set true
     */
    useSearchBar: {
      type: Boolean,
      default: false,
    },
    /**
     * False by default
     * Makes actions slot accessible when true
     */
    showActions: {
      type: Boolean,
      default: false,
    },
    showBorder: {
      type: Boolean,
      default: false,
    },
    showSubheading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    /**
     * Shown by default
     * Routes to previous page if history exists
     */
    boldHeading: {
      type: Boolean,
      default: false,
    },
    mediumFont: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: null,
    },
    showStrapline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    titleClass() {
      return {
        "tw-font-bold": this.boldHeading,
        "tw-font-medium": this.mediumFont,
        // Add customWidth class if customWidth is provided
        [this.customClass]: !!this.customClass,
      };
    },
  },
};
</script>
