<template>
  <div class="d-flex flex-column mt-2" v-if="isLoading">
    <button class="btn btn-primary" type="button" disabled>
      <span class="spinner-grow spinner-grow-sm me-1" aria-hidden="true"></span>
      <span role="status">Confirming email...</span>
    </button>
  </div>

  <div v-if="!isLoading">
    <div class="text-start">
      <div class="text-start">
        <button
          class="btn btn-link ps-0 clickable"
          @click.stop="$emit('restartLoginProcess')"
        >
          <b-icon-arrow-left></b-icon-arrow-left> Re-enter email
        </button>
      </div>
      <label
        class="form-label fw-bold mt-4"
        for="onetimePasscodeConfirmation"
        tabindex="0"
        >Enter Code</label
      >
      <p>
        We just sent a code to
        <span class="text-info fw-bold">{{ userEmail }}</span
        >. Please check your email (including Junk/Spam folders) for a message
        from <span class="fw-semibold">noreply@boldxchange.com</span>
      </p>
      <input
        type="text"
        class="form-control form-control-lg"
        name="email"
        id="onetimePasscodeConfirmation"
        v-model="input.otp"
        tabindex="1"
        placeholder="Enter Code"
        aria-label="Enter Code"
        required
      />
      <p v-if="!isCodeValid" class="text-danger mt-1">
        Please check your passcode and try again.
      </p>
      <p v-if="codeResent" class="text-success fw-bold mt-1">
        New code sent. Please check your email.
      </p>
    </div>
    <!-- submit -->
    <div class="d-flex flex-column mt-2">
      <button
        @click.prevent="resendCode()"
        class="btn btn-primary btn-lg clickable"
        v-if="!resendingCode"
      >
        Re-Send Code
      </button>
      <button
        class="btn btn-primary"
        type="button"
        disabled
        v-if="resendingCode"
      >
        <span
          class="spinner-grow spinner-grow-sm me-1"
          aria-hidden="true"
        ></span>
        <span role="status">Sending new code...</span>
      </button>
      <div class="text-center">
        <button
          class="btn btn-link clickable"
          @click.prevent="$emit('signInWithEmail')"
        >
          <b-icon-chevron-left></b-icon-chevron-left> Sign in with password
          instead
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnetimePasscodeLogin",
  props: ["userEmail"],
  data() {
    return {
      isLoading: false,
      resendingCode: false,
      codeResent: false,
      isCodeValid: true,
      input: {
        otp: "",
        email: "",
      },
      display: {
        otp: false,
        response: false,
        loading: {
          initiateOtp: false,
          submitOtp: false,
        },
      },
      data: {
        response: null,
        codeSendRes: null,
      },
    };
  },
  watch: {
    "input.otp": async function (n, o) {
      if (n.length >= 6) {
        await this.submitCode();
      }
    },
  },
  created() {
    this.sendCode();
  },
  methods: {
    async sendCode() {
      this.isLoading = true;
      // this.display.loading.initiateOtp = true;
      /**
       * @type {import("@boldxchange/sdk/defs").Client}
       */
      const api = await this.getBoldClient();

      const codeSendResult = await api.beginOTPLogin(
        null,
        {
          email: this.userEmail,
        },
        {
          validateStatus: () => true,
        }
      );
      this.data.response = {
        status: codeSendResult.status,
        msg: codeSendResult.data.msg,
      };
      this.codeSendRes = codeSendResult;
      if (codeSendResult.status >= 200 && codeSendResult.status < 300) {
        this.isLoading = false;
        setTimeout(() => {
          this.$emit("showOTPComponent");
        }, 450);
        console.log("SENT CODE SUCCESSS");
      } else {
        this.isLoading = true;
        setTimeout(() => {
          this.$emit("showNoUserMessage");
        }, 450);
        console.log("SENT CODE FAILED");
      }
      // console.log(codeSendResult);
    },
    async resendCode() {
      this.resendingCode = true;
      this.isCodeValid = true;

      /**
       * @type {import("@boldxchange/sdk/defs").Client}
       */
      const api = await this.getBoldClient();

      const codeSendResult = await api.beginOTPLogin(
        null,
        {
          email: this.userEmail,
        },
        {
          validateStatus: () => true,
        }
      );
      this.codeSendRes = codeSendResult;
      if (codeSendResult.status >= 200 && codeSendResult.status < 300) {
        this.codeResent = true;
        this.resendingCode = false;

        setTimeout(() => {
          this.codeResent = false;
        }, 5000);
        console.log("RESENT CODE SUCESSFUL");

        console.log(codeSendResult);
        // this.display.otp = true;
      } else {
        console.log("RESENT CODE FAILED");

        // console.log(codeSendResult);
        // this.display.response = true;
      }
    },
    async submitCode() {
      this.isCodeValid = true;
      /**
       * @type {import("@boldxchange/sdk/defs").Client}
       */
      const api = await this.getBoldClient();
      // const { email, otp } = this.input;
      try {
        await this.$store.dispatch("auth/loginOTP", {
          email: this.userEmail,
          otp: this.input.otp,
          client: api,
        });
        this.isCodeValid = true;
        const targetRoute = this?.$store?.state?.auth?.targetRoute;
        // console.log("redirectToProposal", targetRoute);

        await this.refreshClient();
        this.$root.$emit("login", this.$store.state.auth.token);

        if (targetRoute) {
          // Redirect the user to the target route
          this.$router.push(targetRoute);
        } else {
          // Redirect to a default page (e.g., home) if there is no target route and the user is not an admin or gifting lead
          this.$router.push({ path: "/" });
        }
      } catch (error) {
        // Handle error here
        this.formIsValid = false; // Update the formIsValid variable based on the error condition
        console.error("Login error:", error);
        this.isCodeValid = false;
      } finally {
        this.display.loading.submitOtp = false;
        this.input.otp = "";
      }
    },
    signInWithEmail() {
      this.$emit("signInWithEmail");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  cursor: pointer;
}
</style>
