<template>
  <!-- <template v-if="finalizingGcMarketplaceSignup"> -->
  <ThePage class="tw-flex tw-items-center tw-justify-center tw-h-dvh">
    <div
      class="tw-bg-blue-900 tw-flex tw-flex-col tw-items-center tw-p-5 tw-justify-center tw-gap-6 tw-w-96 tw-h-96 tw-mx-auto tw-rounded-lg"
    >
      <div>
        <img
          style="max-width: 10rem"
          src="../../../assets/svg/logos/Bold_Xchange_PrimaryLogo_White_LG.png"
          alt="Image Description"
        />
      </div>
      <div class="">
        <h1 class="tw-text-2xl tw-text-center tw-text-white">
          Payment successful!
        </h1>
        <p class="tw-m-0 tw-text-lg tw-text-yellow-400 tw-font-bold">
          Hold tight, we're creating your profile.
        </p>
      </div>
    </div>
  </ThePage>
  <!-- </template> -->
</template>

<script>
import ThePage from "../../../components-v2/ui/layout/ThePage";

export default {
  name: "CustomerRegistrationSuccess",
  components: { ThePage },
  data() {
    return {
      sessionId: "",
      finalizingGcMarketplaceSignup: true,
    };
  },
  async mounted() {
    try {
      const sessionId = this.$route.params.CHECKOUT_SESSION_ID;
      console.log("sessionId ", sessionId);
      await this.finalizeGcMarketplaceSignup(sessionId);
    } catch (error) {
      console.log("error finalizing GcMarketplaceSignup and Login", error);
    }
  },
  methods: {
    routeToAccountSetup() {
      this.$router.push({ path: "/" });
    },
    async finalizeGcMarketplaceSignup(sessionId) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      try {
        const api = await this.getBoldClient();
        const finalizationRes = await api.finalizeGcMarketplaceSignup({
          sessionId,
        });

        console.log("finalizationRes ", finalizationRes);
        console.log("new user ", finalizationRes.data.user);
        console.log("new token ", finalizationRes.data.token);

        this.finalizingGcMarketplaceSignup = false;
        await this.$store.dispatch("auth/setUserAndToken", {
          token: finalizationRes.data.token,
          user: finalizationRes.data.user,
        });
        console.log("current user", this?.$store?.state?.auth?.user);
        await this.$store.dispatch("auth/refreshUser");
        await this.refreshClient();
        this.routeToAccountSetup();
        // return finalizationRes;
      } catch (error) {
        console.log("finalizeGcMarketplaceSignup error: ", error);
      }
    },
  },
};
</script>

<style scoped></style>
