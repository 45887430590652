<template>
  <div class="tw-w-full tw-flex tw-flex-col tw-gap-3">
    <TheCard>
      <TheBox :withPadding="true">
        <BlockStack :gap="500">
          <TheBox
            @click="handleUpdatingCampaignType('link')"
            class="tw-relative hover:tw-opacity-90 tw-cursor-pointer tw-flex tw-gap-2 tw-p-2"
            :class="{
              'tw-border-solid tw-border-2 tw-border-green-600 tw-rounded':
                getCampaignType === 'link',
              'tw-border-solid tw-border-2 tw-border-gray-300 tw-rounded':
                getCampaignType === 'email' || !getCampaignType,
            }"
          >
            <div class="tw-flex tw-gap-2 tw-items-center">
              <div>
                <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
                  Create shareable link
                </h2>
                <p
                  class="tw-pointer-events-none tw-text-lg tw-m-0 tw-text-gray-900"
                >
                  Create a shareable link that seamlessly integrates into your
                  existing workflow.
                </p>
              </div>
            </div>
            <div>
              <template v-if="getCampaignType === 'link'">
                <b-icon-check-circle-fill
                  class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-green-600"
                ></b-icon-check-circle-fill>
              </template>
              <template v-else>
                <b-icon-check-circle-fill
                  class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-gray-400"
                ></b-icon-check-circle-fill>
              </template>
            </div>
          </TheBox>
          <!-- <TheBox> -->
          <TheBox
            @click="handleUpdatingCampaignTypeConditionally()"
            class="tw-relative hover:tw-opacity-90 tw-cursor-pointer tw-gap-2 tw-w-full tw-p-2"
            :class="{
              'tw-border-solid tw-border-2 tw-border-green-600 tw-rounded tw-flex tw-flex-col':
                getCampaignType === 'email',
              'tw-border-solid tw-border-2 tw-border-red-600 tw-rounded tw-flex tw-flex-col':
                getCampaignType === 'email' &&
                (!getCampaignEmailSubject || !getCampaignEmailText),
              'tw-border-solid tw-border-2 tw-border-gray-300 tw-rounded tw-flex':
                getCampaignType === 'link' || !getCampaignType,
            }"
          >
            <div class="tw-flex tw-gap-2 tw-items-top">
              <div>
                <template v-if="getCampaignRecipientsIds.length > 1">
                  <div
                    class="tw-border-solid tw-border-orange-300 tw-border-4 tw-border-b-0 tw-border-r-0 tw-border-y-0"
                  >
                    <div class="tw-pl-4 tw-text-gray-900">
                      <h2
                        class="tw-text-xl tw-font-bold tw-text-gray-900 tw-m-0 tw-rounded tw-inline-block"
                      >
                        Email builder cannot be used for multi-recipient
                        campaigns
                      </h2>
                      <p class="tw-text-lg tw-m-0 tw-text-left">
                        Multi-recipient campaigns can only be sent using our
                        shareable link. To use our email builder please update
                        your recipient selection or create a single-recipient
                        campaign.
                      </p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <h2 class="tw-text-2xl tw-font-bold tw-text-gray-900 tw-m-0">
                    Email builder
                  </h2>
                  <p
                    class="tw-pointer-events-none tw-text-lg tw-m-0 tw-text-gray-900"
                  >
                    Use our email builder to send emails with embedded e-gift
                    card links in minutes.
                  </p>
                </template>
              </div>
            </div>
            <div>
              <template v-if="getCampaignRecipientsIds.length <= 1">
                <template
                  v-if="
                    getCampaignType === 'email' &&
                    getCampaignEmailSubject &&
                    getCampaignEmailText
                  "
                >
                  <b-icon-check-circle-fill
                    class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-green-600"
                  ></b-icon-check-circle-fill>
                </template>
                <template
                  v-if="
                    getCampaignType === 'email' &&
                    (!getCampaignEmailSubject || !getCampaignEmailText)
                  "
                >
                  <b-icon-exclamation-circle-fill
                    class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-red-600"
                  ></b-icon-exclamation-circle-fill>
                </template>
                <template v-if="!getCampaignType">
                  <b-icon-check-circle-fill
                    class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-gray-400"
                  ></b-icon-check-circle-fill>
                </template>
              </template>

              <!-- <template v-else>
                <b-icon-check-circle-fill
                  class="tw-absolute tw-top-2 tw-right-2 tw-h-5 tw-w-5 tw-text-gray-400"
                ></b-icon-check-circle-fill>
              </template> -->
            </div>
            <template v-if="getCampaignType === 'email'">
              <BlockStack :gap="400" class="tw-w-full">
                <BlockStack :gap="300" class="tw-w-full">
                  <BlockStack :gap="100" class="tw-w-full">
                    <label
                      for="bookingSummary"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Subject<span
                        v-if="!getCampaignEmailSubject"
                        class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required field</span
                      ></label
                    >
                    <input
                      v-model="getCampaignEmailSubject"
                      @input="handleUpdatingEmailSubject($event)"
                      type="text"
                      name="campaignSubject"
                      id="campaignSubject"
                      :class="{ 'tw-ring-red-300': !getCampaignEmailSubject, 'tw-ring-green-500': getCampaignEmailSubject }"
                      class="tw-block tw-w-96 tw-rounded-md tw-border-0 tw-px-2.5 tw-py-2 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-slate-900 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6"
                      placeholder=""
                    />
                  </BlockStack>
                </BlockStack>
                <BlockStack :gap="300" class="tw-w-full">
                  <BlockStack :gap="200" class="tw-w-full">
                    <label
                      for="bookingSummary"
                      class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                      >Body<span
                        v-if="!getCampaignEmailText"
                        class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required field</span
                      ></label
                    >
                    <textarea
                      rows="5"
                      v-model="getCampaignEmailText"
                      @input="handleUpdatingEmailText($event)"
                      name="emailContent"
                      id="emailContent"
                      :class="{ 'tw-ring-red-300': !getCampaignEmailText, 'tw-ring-green-500': getCampaignEmailText }"
                      class="tw-block tw-px-2.5 tw-py-2 tw-w-full tw-rounded-lg tw-border-0 tw-border-solid tw-text-gray-900 tw-shadow tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 sm:tw-text-lg"
                    ></textarea>
                  </BlockStack>
                  <template
                    v-if="getCampaignEmailSubject && getCampaignEmailText"
                  >
                    <TheLink variant="primary" @click="toggleEmailPreview">
                      <template #text>
                        {{
                          showEmailPreview ? "Hide preview" : "Preview email"
                        }}</template
                      >
                    </TheLink>
                  </template>
                </BlockStack>
                <template v-if="showEmailPreview">
                  <div class="tw-rounded-lg tw-overflow-hidden tw-shadow">
                    <table
                      id="main_table"
                      style="
                        border-collapse: collapse;
                        text-align: center;
                        background-color: #fff;
                        width: 100%;
                        border: 1px solid #efeeea;
                      "
                    >
                      <tr>
                        <td
                          id="email_from_container"
                          style="
                            padding: 24px;
                            padding-bottom: 0px;
                            text-align: left;
                            background-color: #fff;
                          "
                        >
                          <p style="margin: 0; color: rgb(0, 0, 0)">
                            {{ getCampaignGiftingLeadName }} sent you an
                            <span style="font-weight: bold"
                              >STL eGift card</span
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td
                          id="email_text_container"
                          style="
                            padding: 24px;
                            text-align: left;
                            background-color: #fff;
                            min-height: 200px;
                          "
                        >
                          <template v-if="getCampaignEmailText">
                            <div
                              style="min-height: 150px"
                              v-html="getCampaignEmailHtmlDisplay"
                            ></div>
                          </template>
                          <template v-else>
                            <div
                              class="tw-border-l-4 tw-border-orange-400 tw-bg-orange-50 tw-p-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-items-center tw-gap-4"
                            >
                              <img
                                style="max-height: 100px; width: auto"
                                src="../../../assets/svg/illustrations-test/75_pencil.png"
                                alt="Image Description"
                              />
                              <p class="tw-text-lg tw-m-0 tw-text-start">
                                Your message will show up here.
                              </p>
                            </div>
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td
                          id="email_accept_container"
                          style="padding: 24px; background-color: #f6f6f4"
                        >
                          <table
                            id="nested_table_1"
                            style="
                              width: 100%;
                              margin: 0 auto;
                              border-collapse: collapse;
                            "
                          >
                            <tr>
                              <td
                                id="nested_table_1_td_1"
                                style="
                                  text-align: left;
                                  padding-right: 5px;
                                  vertical-align: middle;
                                  height: 100%;
                                  max-width: 70%;
                                "
                              >
                                <p
                                  style="
                                    margin: 0;
                                    color: rgb(0, 0, 0);
                                    font-size: 16px;
                                  "
                                >
                                  Powered by
                                  <span style="font-weight: bold"
                                    >Bold Xchange</span
                                  >
                                </p>
                                <p style="margin: 5px 0 0; color: rgb(0, 0, 0)">
                                  Enjoy an eGift card marketplace featuring many
                                  of St. Louis’ best small businesses.
                                </p>
                              </td>
                              <td
                                id="nested_table_1_td_2"
                                style="
                                  text-align: center;
                                  padding: 0;
                                  height: 100%;
                                  width: 30%;
                                "
                              >
                                <span
                                  style="
                                    text-align: center;
                                    background-color: #004aad;
                                    border-radius: 6px;
                                    color: #ffcc48;
                                    font-weight: bold;
                                    font-style: normal;
                                    padding: 16px 24px;
                                    font-size: 16px;
                                    text-decoration: none;
                                    display: inline-block;
                                    width: auto;
                                  "
                                  >Accept</span
                                >
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          id="email_contact_container"
                          style="padding: 24px; background-color: #fff"
                        >
                          <table
                            id="nested_table_2"
                            style="
                              width: 100%;
                              margin: 0 auto;
                              border-collapse: collapse;
                            "
                          >
                            <tr>
                              <td>
                                <a
                                  href="https://boldxchange.com"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style="color: rgb(0, 0, 0)"
                                >
                                  <img
                                    alt="Gifting Powered by Bold Xchange"
                                    title="Bold Xchange"
                                    src="https://storage.googleapis.com/boldxchange-public/Bold_Xchange_PrimaryLogo_Black_Email.png"
                                    style="width: 175px; height: auto"
                                  />
                                </a>
                                <p
                                  style="margin: 10px 0 0; color: rgb(0, 0, 0)"
                                >
                                  Bold Xchange P.O Box 771576 St. Louis, MO
                                  63177
                                </p>
                                <p
                                  style="
                                    margin: 0;
                                    margin-bottom: 0;
                                    color: rgb(0, 0, 0);
                                  "
                                >
                                  <a
                                    style="
                                      text-decoration: underline;
                                      color: rgb(7, 7, 7);
                                    "
                                    href="mailto:gifts@boldxchange.com"
                                    >gifts@boldxchange.com</a
                                  >
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          id="email_copyright_container"
                          style="
                            padding: 24px;
                            text-align: center;
                            background-color: #fff;
                          "
                        >
                          <p
                            style="
                              text-align: center;
                              background-color: #fff;
                              margin: 0;
                              color: rgb(7, 7, 7);
                            "
                          >
                            <em> Copyright (C) 2024 Bold Xchange, Inc.</em>
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </template>
              </BlockStack>
            </template>
          </TheBox>
        </BlockStack>
      </TheBox>
    </TheCard>
  </div>
</template>

<script>
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import BlockStack from "../../../components-v2/ui/layout/BlockStack.vue";
import TheBox from "../../../components-v2/ui/layout/TheBox.vue";
import TheLink from "../../../components-v2/ui/actions/TheLink.vue";
export default {
  name: "TheGiftCardAmount",
  emits: ["loadSteps"],
  components: {
    TheLink,
    TheCard,
    TheBox,
    BlockStack,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showEmailPreview: false,
    };
  },
  computed: {
    getCampaignType() {
      return this.$store.getters["campaigns/getCampaignType"];
    },
    getCampaignEmailSubject() {
      return this.$store.getters["campaigns/getCampaignEmailSubject"];
    },
    getCampaignEmailText() {
      return this.$store.getters["campaigns/getCampaignEmailText"];
    },
    getCampaignEmailHtmlDisplay() {
      return this.$store.getters["campaigns/getCampaignEmailHtmlDisplay"];
    },
    getCampaignGiftingLeadName() {
      return this.$store.getters["campaigns/getCampaignGiftingLeadName"];
    },
    getCampaignGiftingLeadEmail() {
      return this.$store.getters["campaigns/getCampaignGiftingLeadEmail"];
    },
    getCampaignRecipientsIds() {
      return this.$store.getters["campaigns/getCampaignRecipientsIds"];
    },
  },
  methods: {
    handleUpdatingCampaignType(campaignType) {
      console.log("campaignType: ", campaignType);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_CAMPAIGN_TYPE",
        campaignType
      );
      this.$emit("loadSteps");
    },
    handleUpdatingCampaignTypeConditionally() {
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_CAMPAIGN_TYPE_CONDITIONALLY"
      );
      this.$emit("loadSteps");
    },
    handleUpdatingEmailSubject(event) {
      let emailSubject = event.target.value;
      console.log("emailSubject: ", emailSubject);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_EMAIL_SUBJECT",
        emailSubject
      );
      this.$emit("loadSteps");
    },
    handleUpdatingEmailText(event) {
      let emailText = event.target.value;
      console.log("emailText: ", emailText);
      this.$store.commit(
        "campaigns/SET_CAMPAIGN_BUILDER_EMAIL_TEXT",
        emailText
      );
      this.$emit("loadSteps");
    },
    toggleEmailPreview() {
      this.showEmailPreview = !this.showEmailPreview;
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
