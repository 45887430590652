<template>
  <template v-if="importMessage.successful.show">
    <Teleport to="#modal">
      <TheModal :open="importMessage.successful.show">
        <ModalBody>
          <SuccessContent :recipientsImported="importMessage.successful.show">
            <template #heading>
              <div
                class="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-text-center"
              >
                <div>
                  <h2 class="tw-text-6xl tw-font-bold tw-text-gray-900">
                    &#127881;
                  </h2>
                  <h2 class="tw-text-3xl tw-font-bold tw-text-blue-800 tw-m-0">
                    Your import was successful
                  </h2>
                  <ol class="tw-list-disc tw-m-0 tw-text-left">
                    <li class="text-gray-700 tw-text-sm">
                      {{ importCount }} new contacts were added to your account.
                    </li>
                    <li class="text-gray-700 tw-text-sm">
                      We created a new recipient group for you that includes all
                      of your imported contacts.
                    </li>
                  </ol>
                </div>
                <div>
                  <template v-if="routeToCampaignCreation">
                    <div class="tw-flex tw-gap-2">
                      <TheButton
                        :isRouterLink="true"
                        variant="secondary"
                        size="regular"
                        :to="this.$router.options.history.state.back"
                      >
                        <template #text>Return to campaign</template>
                      </TheButton>
                      <TheButton
                        :isRouterLink="true"
                        variant="tertiary"
                        size="regular"
                        :to="{
                          name: 'RecipientGroupDetails',
                          params: { groupId: this.newGroupId },
                        }"
                      >
                        <template #text>View imported recipients</template>
                      </TheButton>
                    </div>
                  </template>
                  <template v-else-if="routeToDashboard">
                    <div class="tw-flex tw-gap-2">
                      <TheButton
                        :isRouterLink="true"
                        variant="secondary"
                        size="regular"
                        to="/dashboard"
                      >
                        <template #text>Go to dashboard</template>
                      </TheButton>
                      <TheButton
                        :isRouterLink="true"
                        variant="tertiary"
                        size="regular"
                        :to="{
                          name: 'RecipientGroupDetails',
                          params: { groupId: this.newGroupId },
                        }"
                      >
                        <template #text>View imported recipients</template>
                      </TheButton>
                    </div>
                  </template>
                  <template v-else>
                    <div class="tw-flex tw-gap-2">
                      <TheButton
                        :isRouterLink="true"
                        variant="secondary"
                        size="regular"
                        to="/recipients"
                      >
                        <template #text>View all recipients</template>
                      </TheButton>
                      <TheButton
                        :isRouterLink="true"
                        variant="tertiary"
                        size="regular"
                        :to="{
                          name: 'RecipientGroupDetails',
                          params: { groupId: this.newGroupId },
                        }"
                      >
                        <template #text>View imported recipients</template>
                      </TheButton>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </SuccessContent>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <template v-if="isImportingContacts">
    <Teleport to="#modal">
      <TheModal :open="isImportingContacts">
        <ModalBody>
          <RecipientsImportStatus :recipientsImportStatus="importProgress">
            <template #heading>
              <div
                class="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-text-center"
              >
                <div>
                  <h2 class="tw-text-6xl tw-font-bold tw-text-gray-900">
                    &#127881;
                  </h2>
                  <h2 class="tw-text-3xl tw-font-bold tw-text-blue-800 tw-m-0">
                    Your import was successful
                  </h2>
                  <p class="tw-text-lg tw-m-0 tw-max-w-96">
                    We've added {{ this?.content?.data?.length }} new contacts
                    to your account.
                  </p>
                </div>
                <div>
                  <template v-if="routeToCampaignCreation">
                    <TheButton
                      :isRouterLink="true"
                      variant="tertiary"
                      size="large"
                      :to="this.$router.options.history.state.back"
                    >
                      <template #text>Close</template>
                    </TheButton>
                  </template>
                  <template v-else-if="routeToDashboard">
                    <TheButton
                      :isRouterLink="true"
                      variant="tertiary"
                      size="large"
                      to="/dashboard"
                    >
                      <template #text>Close</template>
                    </TheButton>
                  </template>
                  <template v-else>
                    <TheButton
                      :isRouterLink="true"
                      variant="tertiary"
                      size="large"
                      to="/recipients"
                    >
                      <template #text>Close</template>
                    </TheButton>
                  </template>
                </div>
              </div>
            </template>
          </RecipientsImportStatus>
        </ModalBody>
      </TheModal>
    </Teleport>
  </template>
  <PageHeader title="Import contacts"></PageHeader>

  <ThePage customWidthClass="tw-w-full">
    <div class="tw-flex tw-flex-col tw-gap-3">
      <template v-if="updateMessage.failed.show">
        <div
          class="tw-border-red-400 tw-bg-red-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-flex-col tw-gap-4 tw-w-full tw-mx-auto"
        >
          <div class="tw-flex tw-gap-2 tw-items-center">
            <b-icon-exclamation-circle-fill
              class="tw-text-red-500 tw-text-xl"
            ></b-icon-exclamation-circle-fill>
            <p class="tw-m-0 tw-text-xl">Upload failed</p>
          </div>
          <ol class="tw-list-disc tw-m-0 tw-text-left">
            <template v-if="csvColumnErrors.isActive">
              <li class="text-gray-700 tw-text-lg">
                Your CSV file column headers do not match the column headers of
                our
                <span
                  ><a href="/files/contacts-csv-upload-template.csv" download
                    >contact list CSV file template</a
                  >.</span
                >
              </li>
            </template>
            <template v-if="csvRowErrors.isActive">
              <li class="text-gray-700 tw-text-lg">
                One or more rows are missing information.
              </li>
            </template>
          </ol>
        </div>
      </template>
      <template v-if="!updateMessage.successful.show">
        <div class="tw-w-full tw-flex tw-flex-col">
          <div class="tw-max-w-8xl tw-flex tw-gap-3 tw-flex-wrap">
            <!-- csv -->
            <TheCard class="tw-max-w-lg">
              <TheBox :withPadding="true" class="tw-h-full">
                <div
                  class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-2 tw-w-auto tw-p-2"
                >
                  <div
                    class="tw-flex tw-flex-col tw-h-full tw-gap-2 tw-w-auto tw-p-2"
                  >
                    <div class="tw-flex tw-gap-4">
                      <!-- logo -->
                      <div
                        class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded"
                      >
                        <img
                          src="../../assets/svg/illustrations-test/47_import.png"
                          alt="office365 logo"
                          class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                        />
                      </div>
                      <div>
                        <p
                          class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                        >
                          csv upload
                        </p>
                        <h2
                          class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                        >
                          Upload your file
                        </h2>
                      </div>
                    </div>
                    <div>
                      <p
                        class="tw-text-xl tw-leading-8 tw-text-gray-700 tw-m-0"
                      >
                        Use our
                        <a
                          href="/files/contacts-csv-upload-template.csv"
                          download
                          >contact list CSV file template</a
                        >
                        to streamline your upload process.
                      </p>
                    </div>
                  </div>
                  <!-- button -->
                  <div class="tw-flex tw-flex-col tw-gap-2">
                    <label
                      class="tw-border-none tw-w-44 tw-justify-center tw-cursor-pointer tw-text-sm tw-py-2.5 tw-px-3 tw-rounded tw-flex tw-gap-2 tw-items-center tw-text-white tw-font-bold tw-text-center tw-bg-blue-600 hover:tw-bg-blue-700 focus:tw-ring-1 focus:tw-outline-none focus:tw-ring-blue-300 disabled:tw-bg-slate-200 disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed"
                    >
                      Upload CSV File

                      <input
                        class="form-control"
                        type="file"
                        accept=".csv"
                        hidden
                        @change="handleFileUpload($event, hideTooltip)"
                      />
                    </label>
                    <a
                      @click="toggleImportInstructions()"
                      class="tw-w-full tw-text-lg tw-cursor-pointer"
                    >
                      Learn how to prepare your import file
                    </a>
                  </div>
                </div>
              </TheBox>
            </TheCard>

            <template v-if="!importInstructionsAreVisible">
              <!-- salesforce -->
              <TheCard class="tw-max-w-lg">
                <TheBox :withPadding="true" class="tw-h-full">
                  <div
                    class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-2 tw-w-auto tw-p-2"
                  >
                    <div
                      class="tw-flex tw-flex-col tw-h-full tw-gap-2 tw-w-auto tw-p-2"
                    >
                      <div class="tw-flex tw-gap-4">
                        <!-- logo -->
                        <div
                          class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded"
                        >
                          <img
                            src="../../assets/svg/brands/salesforce.png"
                            alt="product.imageAlt"
                            class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                          />
                        </div>
                        <div>
                          <p
                            class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                          >
                            upload contacts
                          </p>
                          <h2
                            class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                          >
                            Salesforce
                          </h2>
                        </div>
                      </div>
                    </div>
                    <!-- description -->
                    <div class="tw-flex-1 tw-self-start">
                      <div class="tw-flex tw-gap-x-2">
                        <div class="tw-flex-1">
                          <p class="tw-text-xl tw-leading-8 tw-text-gray-700">
                            Bold Xchange allows you to easily bring Salesforce
                            contacts into your Bold Xchange account. Streamline
                            your workflow by connecting your Salesforce account
                            to Bold Xchange.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- button -->
                      <template v-if="hasSalesforceTokens">
                        <div
                          class="tw-flex tw-gap-2 tw-justify-between tw-text-lg tw-bg-green-50 tw-rounded tw-p-4"
                        >
                          <div
                            class="tw-flex tw-gap-2 tw-items-center tw-text-lg"
                          >
                            <b-icon-check-circle-fill
                              class="tw-text-green-500"
                            ></b-icon-check-circle-fill>
                            <p
                              class="tw-m-0 tw-font-semibold tw-text-sm tw-text-text-400"
                            >
                              Account connected
                            </p>
                          </div>

                          <!-- lifecycle stage -->
                          <BlockStack :gap="200">
                            <label
                              for="recipientLifecycleStage"
                              class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900"
                              >Select data to retrieve</label
                            >
                            <select
                              v-model="selectedSobject"
                              id="recipientLifecycleStage"
                              name="recipientLifecycleStage"
                              :class="{
                                'tw-ring-red-300': !selectedSobject,
                              }"
                              class="tw-block tw-bg-white tw-w-full tw-rounded-md tw-border-0 tw-px-4 tw-py-4 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg sm:tw-leading-6"
                            >
                              <option value="" class="" disabled>
                                Select option
                              </option>
                              <option
                                v-for="option in sObjectOptions"
                                :value="option.value"
                                :key="option.index"
                                class=""
                              >
                                {{ option.text }}
                              </option>
                            </select>
                            <template v-if="selectedSobject">
                              <TheButton
                                :disabled="
                                  isFetchingHubSpotContacts ||
                                  isFetchingSalesforceContacts
                                "
                                :hasLeadingIcon="true"
                                variant="tertiary"
                                @click="fetchSalesforceContacts()"
                                size="regular"
                              >
                                <template #leading-icon>
                                  <template v-if="isFetchingSalesforceContacts">
                                    <div role="status">
                                      <svg
                                        aria-hidden="true"
                                        class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          fill="currentFill"
                                        />
                                      </svg>
                                      <span class="tw-sr-only">Saving...</span>
                                    </div>
                                  </template>
                                  <template v-else
                                    ><img
                                      class="tw-h-4 tw-w-auto tw-flex-shrink-0"
                                      src="../../assets/svg/brands/salesforce.png"
                                      alt=""
                                  /></template>
                                </template>

                                <template #text>
                                  <p class="tw-ml-2 tw-m-0">
                                    Upload {{ selectedSobject }}s
                                  </p>
                                </template>
                              </TheButton>
                            </template>
                          </BlockStack>
                        </div>
                      </template>
                      <template v-else>
                        <div
                          class="tw-flex tw-gap-2 tw-justify-between tw-text-lg tw-bg-orange-50 tw-rounded tw-p-4"
                        >
                          <div
                            class="tw-flex tw-gap-2 tw-items-center tw-text-lg"
                          >
                            <b-icon-exclamation-circle-fill
                              class="tw-text-orange-500"
                            ></b-icon-exclamation-circle-fill>
                            <p class="tw-m-0 tw-font-semibold tw-text-text-400">
                              Not connected
                            </p>
                          </div>
                          <TheButton
                            :hasLeadingIcon="true"
                            variant="tertiary"
                            @click="startLinkingSalesforce()"
                            size="regular"
                          >
                            <template #leading-icon>
                              <img
                                class="tw-h-4 tw-w-auto tw-flex-shrink-0"
                                src="../../assets/svg/brands/salesforce.png"
                                alt=""
                              />
                            </template>

                            <template #text>
                              <p class="tw-ml-4 tw-m-0">
                                Connect to Salesforce
                              </p>
                            </template>
                          </TheButton>
                        </div>
                      </template>
                    </div>
                  </div>
                </TheBox>
              </TheCard>
              <!-- hubSpot -->
              <!-- <TheCard class="tw-max-w-lg">
                <TheBox :withPadding="true" class="tw-h-full">
                  <div
                    class="tw-flex tw-flex-col tw-justify-between tw-h-full tw-gap-2 tw-w-auto tw-p-2"
                  >
                    <div
                      class="tw-flex tw-flex-col tw-h-full tw-gap-2 tw-w-auto tw-p-2"
                    >
                      <div class="tw-flex tw-gap-4">
                        <div
                          class="tw-h-10 tw-w-auto tw-flex-shrink-0 tw-rounded"
                        >
                          <img
                            src="../../assets/svg/brands/hubspot.svg"
                            alt="product.imageAlt"
                            class="tw-h-full tw-w-auto tw-flex-shrink-0 tw-mx-auto"
                          />
                        </div>
                        <div>
                          <p
                            class="tw-m-0 tw-font-semibold tw-text-indigo-400 tw-uppercase tw-text-xs"
                          >
                            upload contacts
                          </p>
                          <h2
                            class="tw-text-2xl tw-m-0 tw-font-bold tw-tracking-tight tw-text-gray-900 tw-sm:text-lg"
                          >
                            HubSpot
                          </h2>
                        </div>
                      </div>
                      <div class="tw-flex-1 tw-self-start">
                        <div class="tw-flex tw-gap-x-2">
                          <div class="tw-flex-1">
                            <p class="tw-text-xl tw-leading-8 tw-text-gray-700">
                              Bold Xchange allows you to easily bring HubSpot
                              contacts into your Bold Xchange account.
                              Streamline your workflow by connecting your
                              HubSpot account to Bold Xchange.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <template v-if="hasHubSpotTokens">
                        <div
                          class="tw-flex tw-gap-2 tw-justify-between tw-text-lg tw-bg-green-50 tw-rounded tw-p-4"
                        >
                          <div
                            class="tw-flex tw-gap-2 tw-items-center tw-text-lg"
                          >
                            <b-icon-check-circle-fill
                              class="tw-text-green-500"
                            ></b-icon-check-circle-fill>
                            <p
                              class="tw-m-0 tw-font-semibold tw-text-sm tw-text-text-400"
                            >
                              Account connected
                            </p>
                          </div>
                          <TheButton
                            :disabled="
                              isFetchingHubSpotContacts ||
                              isFetchingSalesforceContacts
                            "
                            :hasLeadingIcon="true"
                            variant="tertiary"
                            @click="fetchHubSpotContacts()"
                            size="regular"
                          >
                            <template #leading-icon>
                              <template v-if="isFetchingHubSpotContacts">
                                <div role="status">
                                  <svg
                                    aria-hidden="true"
                                    class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                  <span class="tw-sr-only">Saving...</span>
                                </div>
                              </template>
                              <template v-else
                                ><img
                                  class="tw-h-4 tw-w-auto tw-flex-shrink-0"
                                  src="../../assets/svg/brands/hubspot.svg"
                                  alt=""
                              /></template>
                            </template>

                            <template #text>
                              <p class="tw-ml-2 tw-m-0">Upload contacts</p>
                            </template>
                          </TheButton>
                        </div>
                      </template>
                      <template v-else>
                        <div
                          class="tw-flex tw-gap-2 tw-justify-between tw-text-lg tw-bg-orange-50 tw-rounded tw-p-4"
                        >
                          <div
                            class="tw-flex tw-gap-2 tw-items-center tw-text-lg"
                          >
                            <b-icon-exclamation-circle-fill
                              class="tw-text-orange-500"
                            ></b-icon-exclamation-circle-fill>
                            <p
                              class="tw-m-0 tw-font-semibold tw-text-sm tw-text-text-400"
                            >
                              Not connected
                            </p>
                          </div>
                          <TheButton
                            :hasLeadingIcon="true"
                            variant="tertiary"
                            @click="startLinkingHubSpot()"
                            size="regular"
                          >
                            <template #leading-icon>
                              <img
                                class="tw-h-4 tw-w-auto tw-flex-shrink-0"
                                src="../../assets/svg/brands/hubspot.svg"
                                alt=""
                              />
                            </template>

                            <template #text>
                              <p class="tw-ml-4 tw-m-0">Connect to HubSpot</p>
                            </template>
                          </TheButton>
                        </div>
                      </template>
                    </div>
                  </div>
                </TheBox>
              </TheCard> -->
            </template>
          </div>
          <template v-if="importInstructionsAreVisible">
            <div class="tw-max-w-4xl tw-w-full tw-mt-3">
              <ul class="step step-border-last-0 text-start tw-w-full">
                <!-- one -->
                <li class="step-item">
                  <div class="step-content-wrapper align-items-center">
                    <span class="step-icon step-icon-soft-dark"> 1 </span>

                    <p class="mb-0 fs-4">
                      In a spreadsheet program like Microsoft Excel or Google
                      Sheets, open your blank worksheet.
                    </p>
                  </div>
                </li>
                <!-- two -->
                <li class="step-item">
                  <div class="step-content-wrapper">
                    <span class="step-icon step-icon-soft-dark"> 2 </span>

                    <div class="d-flex flex-column gap-3">
                      <p class="mb-0 fs-4">
                        Create and label 4 columns:
                        <span class="fw-bold">First Name</span>,
                        <span class="fw-bold">Last Name</span>,
                        <span class="fw-bold">Email Address</span>, and
                        <span class="fw-bold">Company</span>. These fields are
                        required.
                      </p>
                      <div
                        class="tw-border-orange-400 tw-bg-orange-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-flex-col tw-gap-2 tw-w-full tw-mx-auto"
                      >
                        <div class="tw-flex tw-gap-2 tw-items-center">
                          <b-icon-exclamation-circle-fill
                            class="tw-text-orange-500"
                          ></b-icon-exclamation-circle-fill>
                          <p class="tw-m-0">Please note...</p>
                        </div>
                        <ol class="tw-list-disc tw-m-0 tw-text-left">
                          <li class="text-gray-700">
                            Each contact must have a
                            <span class="fw-bold">First Name</span>,
                            <span class="fw-bold">Last Name</span>, and
                            <span class="fw-bold">Email Address</span>. Any
                            contacts with a missing first name, last name, or
                            email will be ignored during upload.
                          </li>
                          <li class="text-gray-700">
                            Contacts missing a first name, last name, or email
                            will be ignored during upload.
                          </li>
                          <li class="text-gray-700">
                            If an imported contact's email address matches an
                            email address already in the member's existing
                            contacts list, the existing contact’s information
                            will be updated with the data from the import file.
                          </li>
                        </ol>
                      </div>
                      <img
                        class="img-thumbnail tw-max-w-xl"
                        src="../../assets/svg/illustrations-test/import_file_example_blank.png"
                        alt=""
                      />
                    </div>
                  </div>
                </li>
                <li class="step-item">
                  <div class="step-content-wrapper">
                    <span class="step-icon step-icon-soft-dark"> 3 </span>

                    <div class="d-flex flex-column gap-3">
                      <p class="mb-0 fs-4">
                        Paste your recipients' information into the relevant
                        fields in the spreadsheet.
                      </p>
                      <img
                        class="img-thumbnail tw-w-3xl"
                        src="../../assets/svg/illustrations-test/import_file_example_filled.png"
                        alt=""
                      />
                    </div>
                  </div>
                </li>
                <li class="step-item">
                  <div class="step-content-wrapper">
                    <span class="step-icon step-icon-soft-dark"> 4 </span>

                    <div class="d-flex flex-column gap-5">
                      <p class="mb-0 fs-4">
                        Save the file in CSV format. This option can usually be
                        accessed from the File menu in your spreadsheet program,
                        labeled as "Export as .CSV" or "Download as .CSV".
                        Different spreadsheet programs may vary.
                      </p>
                      <button
                        class="btn btn-primary"
                        @click="toggleImportInstructions()"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </template>
      <template v-if="updateMessage.successful.show">
        <div class="tw-flex tw-flex-col tw-gap-3">
          <div
            class="tw-border-green-400 tw-bg-green-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-flex-col tw-gap-4 tw-w-full tw-mx-auto"
          >
            <div class="tw-flex tw-gap-2 tw-items-center">
              <b-icon-check-circle-fill
                class="tw-text-green-500 tw-text-xl"
              ></b-icon-check-circle-fill>
              <template v-if="csvCount">
                <p class="tw-m-0 tw-text-xl">
                  <span class="tw-bg-yellow-300"
                    >{{ this.content.data.length }} contacts</span
                  >
                  were uploaded from CSV file
                </p>
              </template>
              <template v-if="salesforceCount">
                <p class="tw-m-0 tw-text-xl">
                  <span class="tw-bg-yellow-300"
                    >{{ this.content.data.length }}
                    {{
                      selectedSobject === "Contact" ? "Contacts" : "Leads"
                    }}</span
                  >
                  were uploaded from Salesforce
                </p>
              </template>
              <template v-if="hubSpotCount">
                <p class="tw-m-0 tw-text-xl">
                  <span class="tw-bg-yellow-300"
                    >{{ this.content.data.length }} contacts</span
                  >
                  were uploaded from HubSpot
                </p>
              </template>
            </div>
          </div>
          <div
            class="tw-border-orange-400 tw-bg-orange-50 tw-border-l-4 tw-py-2 tw-px-4 tw-border-y-0 tw-border-r-0 tw-border-solid tw-flex tw-flex-col tw-gap-2 tw-w-full tw-mx-auto"
          >
            <div class="tw-flex tw-gap-2 tw-items-center">
              <b-icon-exclamation-circle-fill
                class="tw-text-orange-500 tw-text-xl"
              ></b-icon-exclamation-circle-fill>
              <p class="tw-m-0 tw-text-xl">Please note...</p>
            </div>
            <ol class="tw-list-disc tw-m-0 tw-text-left">
              <li class="text-gray-700 tw-text-lg">
                Uploaded contacts that are missing first name, last name, or
                email will be ignored.
              </li>
              <li class="text-gray-700 tw-text-lg">
                Only selected contacts will be imported.
              </li>
              <li class="text-gray-700 tw-text-lg">
                If an imported contact's email address matches an email address
                already in your existing contacts list, the existing contact’s
                information will be updated with the data from the import file.
              </li>
            </ol>
          </div>

          <ImportRecipientsTableV1
            :contacts="content"
            @cancelImport="cancelImport"
            @createNewRecipients="createNewRecipients"
            @loadMoreHubSpotContacts="fetchHubSpotContacts()"
            :csvUpload="csvCount"
            :salesforceUpload="salesforceCount"
            :selectedSobject="selectedSobject"
            :hubSpotUpload="hubSpotCount"
          ></ImportRecipientsTableV1>
        </div>
      </template>
    </div>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageHeader from "../../components-v2/ui/layout/PageHeader.vue";
import TheButton from "../../components-v2/ui/actions/TheButton";
import ImportRecipientsTableV1 from "../../components-v2/recipients/ImportRecipientsTableV1.vue";
import TheModal from "../../components-v2/ui/modals/TheModal.vue";
import ModalBody from "../../components-v2/ui/layout/ModalBody.vue";
import SuccessContent from "../../components-v2/ui/modals/SuccessContent.vue";
import RecipientsImportStatus from "../../components-v2/ui/modals/RecipientsImportStatus.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";

import Papa from "papaparse";
import moment from "moment";

export default {
  name: "ImportRecipients",
  components: {
    BlockStack,
    RecipientsImportStatus,
    ImportRecipientsTableV1,
    ThePage,
    PageHeader,
    TheButton,
    TheModal,
    ModalBody,
    SuccessContent,
    TheBox,
    TheCard,
  },
  props: {
    isImportingRecipientsForMember: {
      type: Boolean,
    },
  },
  data() {
    return {
      updateMessage: {
        successful: {
          text: "Import successful.",
          class: "alert-soft-success alert-dismissible fade show",
          show: false,
        },
        failed: {
          text: "Import failed. Please try again.",
          class: "alert-soft-danger alert-dismissible fade show",
          show: false,
        },
      },
      importMessage: {
        successful: {
          //   text: "Import successful.",
          //   class: "alert-soft-success alert-dismissible fade show",
          show: false,
        },
        failed: {
          text: "Import failed. Please try again.",
          class: "alert-soft-danger alert-dismissible fade show",
          show: false,
        },
      },
      orderErrorObject: {},
      csvColumnErrors: {
        isActive: false,
        data: null,
      },
      csvRowErrors: {
        isActive: false,
        data: null,
      },
      editedRow: {},
      content: [],
      file: [],
      selectedRows: [],
      sObjects: ["Lead", "Contact"],
      selectedSobject: "",
      failedRecipients: null,
      selectedLifecycleStage: "",
      isFetchingSalesforceContacts: false,
      isFetchingHubSpotContacts: false,
      isBulkEditing: false,
      selectAll: false,
      isEditing: false,
      importInstructionsAreVisible: false,
      parsed: false,
      isImportingContacts: false,
      campaignId: null,
      importProgress: 0,
      importCount: 0,
      hubSpotCount: 0,
      salesforceCount: 0,
      csvCount: 0,
      after: 0,
      newGroupId: null,
    };
  },
  created() {
    this.campaignId = this.$route.params.campaignId;
  },
  computed: {
    routeToCampaignCreation() {
      return this.$router.options.history.state.back.includes(
        "/campaign/edit/"
      );
    },
    routeToDashboard() {
      return this.$router.options.history.state.back.includes("/dashboard");
    },
    routeToRecipients() {
      return this.$router.options.history.state.back.includes("/recipients");
    },
    userClient() {
      return this?.$store?.state?.auth?.user?.client;
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    hasSalesforceTokens() {
      return this?.$store?.state?.auth?.user?.metadata?.salesforce?.tokens;
    },
    hasHubSpotTokens() {
      return this?.$store?.state?.auth?.user?.metadata?.hubSpot?.tokens;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
    sObjectOptions() {
      const options = this.sObjects.map((tag) => ({
        text: `${tag}s`,
        value: tag,
      }));
      return options;
    },
  },
  methods: {
    /** create recipients */
    async createNewRecipients(selectedRecipients) {
      // Import the Client type for better IntelliSense and type-checking
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      const api = await this.getBoldClient();

      this.isImportingContacts = true;
      const giftingLeads = [this.userId];
      const totalRecipients = selectedRecipients.length;
      this.importCount = totalRecipients;
      let processedCount = 0;

      // Array to hold recipients that failed to be imported
      const failedRecipients = [];
      let importedRecipients = [];

      // Loop through each recipient in selectedRecipients
      for (const recipientData of selectedRecipients) {
        const recipient = {
          firstName: recipientData["First Name"],
          lastName: recipientData["Last Name"],
          "metadata.giftingLeads": giftingLeads,
          "contact.email": recipientData["Email Address"],
          // "contact.metadata.lifecycleStage": recipientData["Lifecycle Stage"],
          "contact.company": recipientData["Company"] || "",
        };

        // Call the API for each recipient inside the loop
        try {
          const recipientRes = await api.upsertRecipient(null, {
            filter: {
              "contact.email": { $eq: recipientData["Email Address"] },
              ...(!this.isBoldXchangeAdmin && {
                "metadata.giftingLeads": { $eq: giftingLeads },
              }),
            },
            patch: recipient,
          });
          console.log(
            `Successfully created recipient: ${recipientData["Email Address"]}`
          );
          processedCount++;
          importedRecipients.push(recipientRes.data.result);
          this.updateProgress((processedCount / totalRecipients) * 100);
        } catch (error) {
          // Handle errors for individual recipient creations and add to the failedRecipients array
          // this.handleError(error);
          console.log(error);
          failedRecipients.push(recipient);
          processedCount++;
          this.updateProgress((processedCount / totalRecipients) * 100);
        }
      }

      // Check for failed recipients to retry or handle appropriately
      if (failedRecipients.length > 0) {
        console.log(
          `Some recipients could not be created: ${failedRecipients}`
        );
        // Optionally, store failedRecipients in a state or context for retrying later
        this.failedRecipients = failedRecipients;
        // Implement or call a function to retry or handle failed recipients
        // this.handleRetry(failedRecipients);
      } else {
        // If all went well
        console.log("imported recipients: ", importedRecipients);
        await this.saveNewGroup(importedRecipients);
        this.handleSuccess();
      }

      // No longer fetching contacts
      this.isImportingContacts = false;
    },
    async saveNewGroup(importedRecipients) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let group = {
        model: "Recipient",
        members: this.memberIds(importedRecipients),
        metadata: {
          name: this.newGroupName(),
          tags: [],
          admins:[this.userId]
        },
      };

      const newGroup = [group];
      // groupsArr.push(this.newGroup);
      console.log("newGroup: ", newGroup);

      try {
        const groupsRes = await api.createGroups(null, newGroup);
        console.log("groupsRes: ", groupsRes);
        this.newGroupId = groupsRes.data.result.records[0]._id;
      } catch (error) {
        // this.handleError(error);
        console.log("group was NOT created", error);
      }
    },
    memberIds(importedRecipients) {
      return importedRecipients.map((member) => ({
        doc: member._id,
        metadata: {},
      }));
    },
    newGroupName() {
      const date = new Date();
      return `New Import: ${moment(date).format("MMM D, YYYY, h:mm A")}`;
    },
    /** handle linking salesforce/hubSpot */
    async startLinkingSalesforce() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */
        const api = await this.getBoldClient();
        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/salesforce`,
        };

        const response = await api.startLinkingSalesforce(redirectUri);
        console.log("salesforceResponse", response);
        const salesforceUrlRedirect = response?.data?.url;
        window.location.href = salesforceUrlRedirect;
      } catch (error) {
        console.error("Failed to link salesforce", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isLinking = false;
      }
    },
    async startLinkingHubSpot() {
      try {
        /**
         * @type {import('@boldxchange/sdk/defs').Client}
         */
        const api = await this.getBoldClient();
        const redirectUri = {
          redirectUri: window.location.origin + `/integrations/hubspot`,
        };

        const response = await api.startLinkingHubSpot(redirectUri);
        console.log("hubspotResponse", response);
        const hubspotUrlRedirect = response?.data?.url;
        window.location.href = hubspotUrlRedirect;
      } catch (error) {
        console.error("Failed to link hubspot", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.isLinking = false;
      }
    },

    /** handle fetching salesforce/hubSpot contacts */
    async fetchSalesforceContacts() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      this.updateMessage.failed.show = false;

      const client = await this.getBoldClient();
      this.isFetchingSalesforceContacts = true;

      try {
        const queryOptions = {
          filter: {},
          limit: 10000,
          sobject: this.selectedSobject,
        };
        const salesforceResponse = await client.fetchSalesforceContacts(
          queryOptions
        );
        const data = salesforceResponse.data.contacts;
        this.salesforceCount = 1;
        console.log("salesforceResponse data", data);
        const transformedData = this.transformSalesforceContacts(data);
        this.validateAndUploadContacts(transformedData);
      } catch (error) {
        console.log(
          "fetchSalesforceContacts paramsSerializer = ",
          error?.config?.paramsSerializer?.toString?.()
        );
        this.handleErrorFromAPI(error);
      } finally {
        this.isFetchingSalesforceContacts = false;
      }
    },
    async fetchHubSpotContacts() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */
      this.updateMessage.failed.show = false;
      const client = await this.getBoldClient();
      this.isFetchingHubSpotContacts = true;

      try {
        const queryOptions = {
          skip: this.after,
        };
        const hubSpotResponse = await client.fetchHubSpotContacts(queryOptions);
        const contacts = hubSpotResponse.data.results;
        console.log("hubSpotResponse data", contacts);
        const transformedData = this.transformHubSpotContacts(contacts);
        this.validateAndUploadContacts(transformedData);
        this.hubSpotCount = 1;
        // Check if there is a next page and update 'after' for the next batch
        if (hubSpotResponse.data.paging && hubSpotResponse.data.paging.next) {
          this.after = hubSpotResponse.data.paging.next.after;
        } else {
          this.after = null; // Clear the after parameter if no more pages
        }
      } catch (error) {
        this.handleErrorFromAPI(error);
      } finally {
        this.isFetchingHubSpotContacts = false;
      }
    },

    /** handle setting salesforce data to retrieve */
    getDropdownItems() {
      return [
        {
          label: "Retrieve CONTACTS",
          isDeleteAction: false,
          action: () => this.setSalesforceSojbect("Contact"),
        },
        {
          label: "Retrieve CONTACTS",
          isDeleteAction: false,
          action: () => this.setSalesforceSojbect("Leads"),
        },
        {
          label: "Retrieve CONTACTS",
          isDeleteAction: false,
          action: () => this.setSalesforceSojbect("Leads"),
        },
      ];
    },
    setSalesforceSojbect(sobject) {
      this.sObject = sobject;
    },
    /** import instructions */
    toggleImportInstructions() {
      this.importInstructionsAreVisible = !this.importInstructionsAreVisible;
    },
    /** file upload */
    handleFileUpload(event) {
      this.importInstructionsAreVisible = false;
      this.resetUploadState();
      this.file = event.target.files[0];
      this.csvCount = 1;
      this.parseFile();
    },
    parseFile() {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const expectedColumns = [
            "First Name",
            "Last Name",
            "Email Address",
            "Company",
          ];
          const actualColumns = Object.keys(results.data[0]);

          const { missingColumns, extraColumns } = this.validateColumns(
            expectedColumns,
            actualColumns
          );

          const columnErrors = this.buildColumnErrors(
            missingColumns,
            extraColumns
          );
          const rowErrors = this.validateRows(results.data);

          this.handleValidationResults(columnErrors, rowErrors, results);
        },
      });
    },
    validateColumns(expectedColumns, actualColumns) {
      const missingColumns = expectedColumns.filter(
        (column) => !actualColumns.includes(column)
      );
      const extraColumns = actualColumns.filter(
        (column) => !expectedColumns.includes(column)
      );

      return { missingColumns, extraColumns };
    },
    validateRows(data) {
      const rowErrors = [];
      data.forEach((row, i) => {
        const emptyFields = [];

        if (!row["First Name"] || row["First Name"].trim() === "") {
          emptyFields.push("First Name");
        }
        if (!row["Last Name"] || row["Last Name"].trim() === "") {
          emptyFields.push("Last Name");
        }
        if (!row["Email Address"] || row["Email Address"].trim() === "") {
          emptyFields.push("Email Address");
        }
        if (emptyFields.length > 0) {
          rowErrors.push(
            `Row ${i + 1} has missing data in: ${emptyFields.join(", ")}`
          );
        }
      });

      return rowErrors;
    },
    buildColumnErrors(missingColumns, extraColumns) {
      const columnErrors = [];

      if (extraColumns.length > 0 || missingColumns.length > 0) {
        columnErrors.push(`Error: Column name validation failed`);

        if (missingColumns.length > 0) {
          columnErrors.push(`Missing columns: ${missingColumns.join(", ")}`);
        }
        if (extraColumns.length > 0) {
          columnErrors.push(`Extra columns: ${extraColumns.join(", ")}`);
        }
      }

      return columnErrors;
    },
    handleValidationResults(columnErrors, rowErrors, results) {
      if (columnErrors.length > 0 || rowErrors.length > 0) {
        this.handleValidationErrors(columnErrors, rowErrors);
      } else {
        this.handleSuccessfulUpload(results);
      }
    },
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    },
    resetUploadState() {
      this.updateMessage.failed.show = false;
      this.updateMessage.successful.show = false;
      this.csvColumnErrors.isActive = false;
      this.csvRowErrors.isActive = false;
      this.content = [];
    },
    handleValidationErrors(columnErrors, rowErrors) {
      this.updateMessage.failed.show = true;
      this.csvColumnErrors.isActive = columnErrors.length > 0;
      this.csvColumnErrors.data = columnErrors;

      this.csvRowErrors.isActive = rowErrors.length > 0;
      this.csvRowErrors.data = rowErrors;
      // console.log("column errors: ", columnErrors);
      // console.log("row errors: ", rowErrors);
    },
    handleSuccessfulUpload(results) {
      this.updateMessage.successful.show = true;
      // Add the new key-value pair to each row
      results.data.forEach((row) => {
        row.id = this.generateUniqueId(); // Assign a unique ID
        // row["Lifecycle Stage"] = ["Unlisted Lead"];
      });
      results.data.sort((a, b) =>
        a["First Name"].localeCompare(b["First Name"])
      );
      this.content = results;
      this.parsed = true;
    },
    updateProgress(percent) {
      this.importProgress = percent;
    },
    handleRetry(failedRecipients) {
      console.log("Retry logic to be implemented.", failedRecipients);
    },
    cancelImport() {
      this.updateMessage.successful.show = false;
      this.content = [];
      this.isBulkEditing = false;
      this.selectedRows = [];
      this.salesforceCount = 0;
      this.hubSpotCount = 0;
      this.csvCount = 0;
      this.resetUploadState();
    },
    transformSalesforceContacts(contacts) {
      return contacts
        .filter(
          (contact) =>
            contact.FirstName &&
            contact.FirstName.trim() !== "" &&
            contact.LastName &&
            contact.LastName.trim() !== "" &&
            contact.Email &&
            contact.Email.trim() !== ""
        )
        .map((contact) => ({
          "First Name": contact.FirstName,
          "Last Name": contact.LastName,
          "Email Address": contact.Email,
        }));
    },
    transformHubSpotContacts(contacts) {
      return contacts
        .filter(
          (contact) =>
            contact.properties.firstname &&
            contact.properties.firstname.trim() !== "" &&
            contact.properties.lastname &&
            contact.properties.lastname.trim() !== "" &&
            contact.properties.email &&
            contact.properties.email.trim() !== ""
        )
        .map((contact) => ({
          "First Name": contact.properties.firstname,
          "Last Name": contact.properties.lastname,
          "Email Address": contact.properties.email,
        }));
    },
    validateAndUploadContacts(contacts) {
      Papa.parse(Papa.unparse(contacts), {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const expectedColumns = ["First Name", "Last Name", "Email Address"];
          const actualColumns = Object.keys(results.data[0]);

          const { missingColumns, extraColumns } = this.validateColumns(
            expectedColumns,
            actualColumns
          );

          const columnErrors = this.buildColumnErrors(
            missingColumns,
            extraColumns
          );
          const rowErrors = this.validateRows(results.data);

          this.handleValidationResults(columnErrors, rowErrors, results);
        },
      });
    },

    /** handle success and errors */
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
    handleSuccess() {
      // console.log("recipients imported successfully! ", records);
      this.importMessage.successful.show = true;
    },
    handleError(error) {
      console.error("Error adding new recipient: ", error);
      this.updateMessage.failed.show = true;
      setTimeout(() => {
        this.updateMessage.failed.show = false;
      }, 5000);
      setTimeout(() => {
        this.updateFailed = false;
      }, 5000);
    },
  },
};
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
