export default {
  toggleDropdown({ commit, state }, id) {
    if (state.openDropdownId === id) {
      commit("SET_OPEN_DROPDOWN", null);
    } else {
      commit("SET_OPEN_DROPDOWN", id);
    }
  },
  closeDropdown({ commit }) {
    commit("SET_OPEN_DROPDOWN", null);
  },
};
