<template>
  <transition
    enter-active-class="tw-transition tw-ease-out tw-duration-200 tw-transform"
    enter-from-class="tw-opacity-0"
    enter-to-class="tw-opacity-100"
    leave-active-class="tw-transition tw-ease-in tw-duration-200 tw-transform"
    leave-from-class="tw-opacity-100"
    leave-to-class="tw-opacity-0"
  >
    <div
      class="tw-fixed tw-z-[200] tw-inset-0 tw-overflow-y-auto tw-bg-black tw-bg-opacity-50"
      v-show="open"
    >
      <div
        class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-text-center tw-px-4"
        @click.self="handleOutsideClick()"
      >
        <Transition
          enter-active-class="tw-transition tw-ease-out tw-duration-300 tw-transform "
          enter-from-class="tw-opacity-0 tw-translate-y-10 tw-scale-95"
          enter-to-class="tw-opacity-100 tw-translate-y-0 tw-scale-100"
          leave-active-class="tw-ease-in tw-duration-200"
          leave-from-class="tw-opacity-100 tw-translate-y-0 tw-scale-100"
          leave-to-class="tw-opacity-0 tw-translate-y-10 tw-translate-y-0 tw-scale-95"
        >
          <div
            class="tw-bg-white tw-rounded-lg tw-text-left tw-overflow-visible tw-shadow-xl tw-p-4 sm:tw-p-6 tw-w-full"
            :class="componentClass"
            role="dialog"
            ref="modal"
            aria-modal="true"
            v-show="open"
            aria-labelledby="modal-headline"
          >
            <template v-if="showModalHeader">
              <ModalHeader
                :title="title"
                :subheading="subheading"
                :showActions="true"
              >
                <template #card-header-actions>
                  <CircleButton variant="tertiary" @click="close()">
                    <template #icon
                      ><b-icon-x-lg class="tw-text-black tw-z-0"></b-icon-x-lg
                    ></template>
                  </CircleButton>
                </template>
              </ModalHeader>
            </template>
            <template v-if="showModalBody">
              <ModalBody>
                <slot name="modal-body"></slot>
              </ModalBody>
            </template>
            <template v-if="showModalFooter">
              <ModalFooter>
                <slot name="modal-footer"></slot>
              </ModalFooter>
            </template>
            <!-- <BlockStack :gap="500"> -->
            <slot></slot>
            <!-- </BlockStack> -->
          </div>
        </Transition>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalHeader from "../layout/ModalHeader.vue";
import ModalBody from "../layout/ModalBody.vue";
import ModalFooter from "../layout/ModalFooter.vue";
import CircleButton from "../actions/CircleButton.vue";

export default {
  name: "TheModal",
  emits: ["closeModal"],
  props: {
    title: {
      type: String,
      default: null,
    },
    subheading: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "",
    },
    role: {
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    showModalHeader: {
      type: Boolean,
      default: false,
    },
    showModalBody: {
      type: Boolean,
      default: false,
    },
    showModalFooter: {
      type: Boolean,
      default: false,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true, // Default to true to maintain backward compatibility
    },
    size: {
      type: String,
      default: "small",
    },
    customClass: {
      type: String,
      default: null,
    },
  },
  components: { ModalHeader, ModalBody, ModalFooter, CircleButton },
  data() {
    return {};
  },
  mounted() {
    if (this.open) {
      this.toggleBodyClass(true);
    }
  },
  beforeUnmount() {
    this.toggleBodyClass(false);
  },
  watch: {
    open(newValue) {
      this.toggleBodyClass(newValue);
    },
  },
  computed: {
    componentClass() {
      return {
        "tw-max-w-2xl": this.size === "small",
        "tw-max-w-5xl": this.size === "large",
        "tw-w-full": this.size === "full",
        [this.customClass]: !!this.customClass,
      };
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    handleOutsideClick() {
      if (this.closeOnClickOutside) {
        this.close();
      }
    },
    toggleBodyClass(open) {
      const method = open ? "add" : "remove";
      document.body.classList[method]("tw-overflow-hidden");
    },
  },
};
</script>
<style scoped></style>
