// campaignService.js
import { getBoldClient } from "@/api/getBoldClient.js";
import store from "../store/index";

const listInvoices = async (queryParameters, requestBody) => {
  const token = store.state.auth.token;
  const api = await getBoldClient(token);
  return api.listInvoices(queryParameters, { filter: requestBody });
};

const upsertInvoice = async (invoiceId, patchObject) => {
  const token = store.state.auth.token;

  const api = await getBoldClient(token);
  return api.upsertInvoice(null, {
    filter: { _id: invoiceId },
    patch: patchObject,
  });
};

const findRedeemerMatch = (invoice) => {
  const seenTrue = invoice?.campaign[0]?.seen === true || 0;
  const redeemerValue = invoice?.metadata?.redeemer || 0;
  const finalizations = invoice?.campaign[0]?.metadata?.finalizations || 0;

  if (finalizations && finalizations[redeemerValue]) {
    return finalizations[redeemerValue];
  } else if (seenTrue && invoice?.campaign[0]?.recipients?.length === 1) {
    return invoice?.campaign[0];
  } else {
    return null;
  }
};

// Export the functions for use in your components or Vuex actions
export { listInvoices, upsertInvoice, findRedeemerMatch };
