<template>
  <SuccessfulActionModal
    :bulkImport="true"
    v-if="updateMessage.successful.show"
    @close="cancelVendorImport"
  >
  </SuccessfulActionModal>

  <template v-if="isSaving">
    <LoadingGiftIcon></LoadingGiftIcon>
  </template>
  <template v-if="!isSaving && !updateMessage.successful.show">
    <div class="container-fluid">
      <div class="row">
        <div class="col mb-3">
          <div
            :class="updateMessage.failed.class"
            v-if="updateMessage.failed.show"
          >
            <p class="mb-0 fw-bold fs-4 text-white">
              {{ updateMessage.failed.text }}
            </p>
          </div>
        </div>

        <div
          class="d-flex align-items-end justify-content-between text-start my-3 my-lg-5"
        >
          <h1 class="mb-0">Import Vendors</h1>
          <div class="d-flex align-items-center justify-content-center gap-2">
            <p class="text-danger mb-0" v-if="!performChecksOnVendors">
              One or more vendors are missing required information.
            </p>
            <template v-if="this.currentPage !== 1">
              <div class="page-item">
                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  variant="ghost"
                  size="large"
                  @click="previousPage()"
                >
                  <template #leading-icon
                    ><b-icon-chevron-left></b-icon-chevron-left
                  ></template>
                </TheButton>
              </div>
            </template>
            <template v-if="currentVendor">
              <div class="dropdown">
                <button
                  class="form-select text-start text-truncate"
                  style="max-width: 15rem; min-width: 15rem"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  {{ currentVendor.name }}
                </button>
                <ul class="dropdown-menu">
                  <li
                    class="dropdown-item"
                    v-for="vendor in getImportedVendors"
                    :key="vendor._id"
                    @click="selectVendor(vendor)"
                  >
                    <template
                      v-if="
                        vendor._id ===
                        (currentVendor ? currentVendor._id : null)
                      "
                      ><span class="text-primary fw-bold">{{
                        vendor.name
                      }}</span></template
                    >
                    <template v-else> {{ vendor.name }}</template>
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <li class="page-item active">
                <a class="page-link" href="#">{{ currentPage }}</a>
              </li>
            </template>
            <template v-if="!this.isLastPage">
              <div class="page-item">
                <TheButton
                  type="button"
                  :hasLeadingIcon="true"
                  variant="ghost"
                  size="large"
                  @click="nextPage()"
                >
                  <template #leading-icon
                    ><b-icon-chevron-right></b-icon-chevron-right
                  ></template>
                </TheButton>
              </div>
            </template>
            <div class="tw-flex tw-gap-2">
              <TheButton
                variant="tertiary"
                type="button"
                size="regular"
                @click="cancelVendorImport()"
              >
                <template #text>Cancel</template>
              </TheButton>
              <TheButton
                :disabled="!this.performChecksOnVendors"
                type="button"
                variant="success"
                size="regular"
                @click="handleCreatingVendorsFromCsvUpload()"
              >
                <template #text
                  >Import
                  <span
                    class="tw-font-bold tw-rounded tw-bg-white tw-px-2 tw-py-1 tw-text-gray-800"
                    >{{ getImportedVendors.length }}</span
                  >
                  vendors</template
                >
              </TheButton>
            </div>
          </div>

          <!-- End Col -->
        </div>
      </div>
    </div>
    <ImportedVendorListItem
      v-for="vendor in paginatedData"
      :key="vendor._id"
      :vendor="vendor"
      @setVendor="setVendor"
    ></ImportedVendorListItem>
  </template>
</template>

<script>
import ImportedVendorListItem from "./ImportedVendorListItem.vue";
import LoadingGiftIcon from "../../components/ui/LoadingGiftIcon.vue";
import SuccessfulActionModal from "../../components/ui/modals/success/SuccessfulActionModal.vue";
import TheButton from "../../components-v2/ui/actions/TheButton";

export default {
  name: "ImportedVendorList",
  components: {
    TheButton,
    ImportedVendorListItem,
    LoadingGiftIcon,
    SuccessfulActionModal,
  },
  created() {
  },
  data() {
    return {
      currentVendor: null,
      updateMessage: {
        successful: {
          show: false,
        },
        failed: {
          text: "Something went wrong while attempting to update your vendor. Please try again.",
          class:
            "d-flex p-3 justify-content-center align-items-center bg-danger",
          show: false,
        },
      },
      itemsPerPage: 1,
      currentPage: 1,
      originalData: {}, // Update data that the user modifies
      isSaving: false,
      stateOptions: [
        { text: "Alabama", value: "AL" },
        { text: "Alaska", value: "AK" },
        { text: "Arizona", value: "AZ" },
        { text: "Arkansas", value: "AR" },
        { text: "California", value: "CA" },
        { text: "Colorado", value: "CO" },
        { text: "Connecticut", value: "CT" },
        { text: "Delaware", value: "DE" },
        { text: "Florida", value: "FL" },
        { text: "Georgia", value: "GA" },
        { text: "Hawaii", value: "HI" },
        { text: "Idaho", value: "ID" },
        { text: "Illinois", value: "IL" },
        { text: "Indiana", value: "IN" },
        { text: "Iowa", value: "IA" },
        { text: "Kansas", value: "KS" },
        { text: "Kentucky", value: "KY" },
        { text: "Louisiana", value: "LA" },
        { text: "Maine", value: "ME" },
        { text: "Maryland", value: "MD" },
        { text: "Massachusetts", value: "MA" },
        { text: "Michigan", value: "MI" },
        { text: "Minnesota", value: "MN" },
        { text: "Mississippi", value: "MS" },
        { text: "Missouri", value: "MO" },
        { text: "Montana", value: "MT" },
        { text: "Nebraska", value: "NE" },
        { text: "Nevada", value: "NV" },
        { text: "New Hampshire", value: "NH" },
        { text: "New Jersey", value: "NJ" },
        { text: "New Mexico", value: "NM" },
        { text: "New York", value: "NY" },
        { text: "North Carolina", value: "NC" },
        { text: "North Dakota", value: "ND" },
        { text: "Ohio", value: "OH" },
        { text: "Oklahoma", value: "OK" },
        { text: "Oregon", value: "OR" },
        { text: "Pennsylvania", value: "PA" },
        { text: "Rhode Island", value: "RI" },
        { text: "South Carolina", value: "SC" },
        { text: "South Dakota", value: "SD" },
        { text: "Tennessee", value: "TN" },
        { text: "Texas", value: "TX" },
        { text: "Utah", value: "UT" },
        { text: "Vermont", value: "VT" },
        { text: "Virginia", value: "VA" },
        { text: "Washington", value: "WA" },
        { text: "West Virginia", value: "WV" },
        { text: "Wisconsin", value: "WI" },
        { text: "Wyoming", value: "WY" },
      ],
    };
  },
  computed: {
    isLastPage() {
      return (
        this.getImportedVendors.length <= this.itemsPerPage ||
        this.currentPage === Math.ceil(this.getImportedVendors.length)
      );
    },
    isPreviousButtonDisabled() {
      return this.currentPage === 1;
    },
    isNextButtonDisabled() {
      return this.currentPage === this.totalPages;
    },
    isFilterActive() {
      return this.selectedCategoryValue ? true : false;
    },
    getImportedVendors() {
      return this.$store.getters["brands/getImportedVendors"];
    },
    totalPages() {
      return Math.ceil(this.getImportedVendors.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      const vendors = this.$store.getters["brands/getImportedVendors"];
      // console.log("next vendor", vendors);
      return vendors.slice(startIndex, endIndex);
    },
    performChecksOnVendors() {
      const vendorsArray = this.$store.getters["brands/getImportedVendors"];
      for (const vendor of vendorsArray) {
        const addressSectionValid =
          (vendor.address.city !== "" &&
            vendor.address.state !== "" &&
            vendor.address.country !== "" &&
            this.isValidState(vendor.address.state)) ||
          (vendor.address.city === "" &&
            vendor.address.state === "" &&
            vendor.address.country === "");

        const generalSectionValid =
          vendor.name !== "" &&
          Array.isArray(vendor.categories) &&
          vendor.categories.length > 0 &&
          vendor.description !== "" &&
          vendor.metadata.region !== "";

        if (!addressSectionValid || !generalSectionValid) {
          return false;
        }
      }
      return true;
    },
    
  },
  methods: {
    async createSingleVendorAddress(addressData) {
      try {
        // Get the Bold client
        const api = await this.getBoldClient();

        // Prepare address lines
        const addressLines = [];
        if (addressData.lines[0]) {
          addressLines.push(addressData.lines[0]);
        }
        if (addressData.lines[1]) {
          addressLines.push(addressData.lines[1]);
        }

        // Create an address object
        let newAddress = {
          city: addressData.city,
          state: addressData.state,
          postalCode: addressData.postalCode,
          country: addressData.country,
        };

        // Conditionally add the 'lines' property
        if (addressLines.length > 0) {
          newAddress.lines = addressLines;
        }

        // Create the address using the API
        const response = await api.createAddresses(null, [newAddress]);

        // Handle a successful response
        this.isSuccessful = true;
        // console.log("address created", response);

        return response.data.result.records[0]._id;
      } catch (error) {
        console.log("Error creating address", error);
        // Handle the error as needed
        console.log("address was NOT created");
      }
    },
    async createVendor(vendors) {
      try {
        const api = await this.getBoldClient();
        const response = await api.createVendors(null, vendors);
        // console.log("vendor response", response);
        // const vendorIds = response.data.result.records.map(
        //   (vendor) => vendor._id
        // );
        // console.log("vendorIds", vendorIds);

        return response;
      } catch (error) {
        // Handle error
        console.log("Error creating vendor:", error);
      }
    },
    async handleCreatingVendorsFromCsvUpload() {
      this.isSaving = true;
      try {
        const vendorsData = this.$store.getters["brands/getImportedVendors"];
        const updatedVendorsData = [];
        console.log("vendorsData", vendorsData);

        for (let i = 0; i < vendorsData.length; i++) {
          const vendor = vendorsData[i];
          if (
            vendor.address.city &&
            vendor.address.state &&
            vendor.address.country
          ) {
            const addressId = await this.createSingleVendorAddress(
              vendor.address
            );
            vendor.address = addressId.toString();
            const { _id, ...vendorToCreate } = vendor;
            updatedVendorsData.push(vendorToCreate);
          } else {
            delete vendorsData[i].address; // Remove the address property if condition is not met
            delete vendor._id;
          }
        }
        // console.log("updatedVendorsData", updatedVendorsData);

        await this.createVendor(updatedVendorsData);

        console.log("Vendors and addresses created successfully");
        this.handleSuccess();
      } catch (error) {
        console.error("Error creating vendors and addresses:", error);
        this.handleError();
      }
    },
    async loadCreatedVendors(vendorIds) {
      const api = await this.getBoldClient();

      let filterParam = {
        _id: { $in: vendorIds },
      };

      // let selection = "metadata name categories address contact";

      // console.log("filterParam", filterParam);

      const queryParameters = {
        sort: "name",
        expand: "media address",
      };

      try {
        // Fetch kits using the constructed filterParam
        const response = await api.listVendors(queryParameters, {
          filter: filterParam,
          // selection: selection,
        });

        const vendors = response.data.result.records;
        // console.log("vendors", vendors);

        this.$store.commit("brands/SET_IMPORTED_VENDORS", vendors);
      } catch (error) {
        // Handle errors
        this.vendorsAreLoading = false;
        console.log("vendors did not load", error);
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    selectVendor(vendor) {
      const index = this.getImportedVendors.findIndex(
        (v) => v._id === vendor._id
      );
      if (index !== -1) {
        this.currentVendor = this.getImportedVendors[index];
        // Optionally, update currentPage based on the selected vendor's position in the list
        this.currentPage = Math.floor(index / this.itemsPerPage) + 1;
      }
    },
    setVendor(vendor) {
      this.currentVendor = vendor;
    },
    isValidState(stateCode) {
      return this.stateOptions.some(state => state.value === stateCode);
    },
    showConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = true;
    },
    closeConfirmDeleteModal() {
      this.isConfirmDeleteModalVisible = false;
    },
    handleSuccess() {
      this.isSaving = false;
      this.updateMessage.successful.show = true;
    },
    handleError() {
      this.isSaving = false;
      this.updateMessage.failed.show = true;
      setTimeout(() => {
        this.updateMessage.failed.show = false;
      }, 5000);
    },
    cancelVendorImport() {
      this.$router.push({ path: "/vendors" });
    },
  },
};
</script>

<style scoped>
th,
a {
  cursor: pointer;
}

.dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-appearance: none;
  appearance: none;
}
</style>
