<template>
  <template v-if="campaignsAreLoading">
    <LoadingGiftIcon></LoadingGiftIcon>
  </template>
  <template v-else>
    <template v-if="hasGiftBeenRedeemed">
      <ThePage customWidthClass="tw-max-w-3xl tw-mx-auto tw-justify-center">
        <TheCard customWidthClass="tw-w-full tw-h-auto">
          <TheBox
            :withPadding="true"
            customClass="tw-h-full tw-flex tw-items-center"
          >
            <img
              class="tw-h-52 tw-w-auto tw-mx-auto"
              src="../../assets/svg/illustrations-test/13_gift_box.png"
              alt="Image Description"
            />
            <div>
              <h1 class="tw-text-4xl">All set!</h1>
              <p class="fs-3 mb-0">
                This gift card has been redeemed. You can find your redeemed
                gift cards
                <router-link to="/gifts" class="link">here</router-link>.
              </p>
            </div>
          </TheBox>
        </TheCard>
      </ThePage>
    </template>
    <template v-else>
      <GiftCardsGridV2
        :campaign="campaign"
        :custom_selection_ids="custom_selection_ids"
        :isGift="true"
      >
      </GiftCardsGridV2>
    </template>
  </template>
</template>

<script>
import LoadingGiftIcon from "../../components/ui/LoadingGiftIcon.vue";
import ThePage from "../../components-v2/ui/layout/ThePage";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox";
import GiftCardsGridV2 from "../../components-v2/gift-cards/GiftCardsGridV2.vue";
import { findRedeemerMatch } from "@/services/campaignService";

export default {
  name: "CampaignGift",
  components: {
    GiftCardsGridV2,
    // AnonymousNavbar,
    LoadingGiftIcon,
    TheCard,
    ThePage,
    TheBox,
  },
  data() {
    return {
      updateMessage: {
        successful: {
          text: "Your changes have been saved.",
          class: "alert-soft-success alert-dismissible fade show",
          show: false,
        },
        failed: {
          text: "Woops...something went wrong while attempting to load your gift. Refresh your page and try again.",
          class: "alert-soft-danger alert-dismissible fade show",
          show: false,
        },
      },
      campaign: {},
      campaignsAreLoading: true,
      custom_selection_ids: null,
      giftCardWasRedeemed: false,
    };
  },
  created() {
    this.loadCampaignDetails();
  },
  computed: {
    userId() {
      return this?.$store?.state?.auth?.user?.id;
    },
    hasGiftBeenRedeemed() {
      return (
        (Array.isArray(this?.campaign?.seen) && this.redeemerMatch) ||
        (this?.campaign?.metadata?.recipient_selection && !this.redeemerMatch)
      );
    },
    redeemerMatch() {
      return !!findRedeemerMatch(this.userId, this.campaign);
    },
    requiresBooking() {
      return this?.campaign?.booking?.required;
    },
  },
  methods: {
    async loadCampaignDetails() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      const id = this.$route.params.campaignId;

      // filters
      let requestBody = {
        _id: id,
      };
      // let selection = "from  subject seen text html metadata";

      const queryParameters = {
        limit: 1,
        expand: "recipients",
      };

      try {
        const response = await api.listMailCampaigns(queryParameters, {
          filter: requestBody,
          // selection: selection,
        });

        // const campaign = response.data.result.records[0];
        this.campaign = response.data.result.records[0];
        this.handleSuccess(response.data.result.records[0]);
      } catch (error) {
        this.campaignsAreLoading = false;
        console.log("campaign did not load", error);
        this.handleError(error);
      } finally {
        this.campaignsAreLoading = false;
      }
    },
    handleSuccess(campaign) {
      this.campaign = campaign;
      this.isGift = true;
      this.campaignsAreLoading = false;
    },

    handleError(error) {
      console.error("Error loading campaign: ", error);
      this.updateMessage.failed.show = true;
    },
  },
};
</script>

<style scoped></style>
