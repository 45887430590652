<template>
  <div class="mt-5">Loading landing page...</div>
</template>

<script>
export default {
  name: "LandingPage",
  mounted() {
    this.routeToHomePage();
  },
  methods: {
    routeToHomePage() {
      if (this.isBoldXchangeAdmin || this.isGiftingLead) {
        this.$router.push({ path: "/dashboard" });
      } else if (this.isGiftCardRecipient) {
        this.$router.push({ path: "/account" });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>

<style scoped></style>
