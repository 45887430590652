export default {
    getQuickEditStatus(state){
      return state.quickEditActive;
    },
    getSelectedBooking(state){
      console.log("getSelectedBooking", state.selectedBooking);
      return state.selectedBooking;

    }
    
  
  };
  