<template>
  <template v-if="listingSubscription">
    <ThePage customWidthClass="tw-max-w-3xl tw-mx-auto tw-justify-center">
      <CustomerRegistrationPageSkeleton></CustomerRegistrationPageSkeleton>
    </ThePage>
  </template>
  <template v-else>
    <!-- <template v-if="count"> -->
    <template v-if="!stripeEmbeddedFormIsLoaded">
      <ThePage class="tw-bg-stone-100 tw-h-full">
        <TheCard
          customWidthClass="tw-max-w-xl tw-m-auto tw-shadow-lg tw-shadow-gray-200"
        >
          <div
            class="tw-bg-blue-800 tw-py-10 tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-8 lg:tw-px-10 tw-px-4"
          >
            <img
              style="max-width: 25rem"
              src="../../../assets/svg/logos/Bold_Xchange_PrimaryLogo_White_LG.png"
              alt="Image Description"
            />
            <p class="tw-text-2xl tw-text-left tw-text-gray-50 tw-m-0">
              Craft gifting campaigns—fast—that build pipeline and close deals.
            </p>
          </div>
          <CustomerRegistrationCollectEmail
            :userEmailExists="userEmailExists"
            @loadStripeEmbeddedForm="loadStripeEmbeddedForm"
          >
          </CustomerRegistrationCollectEmail>
        </TheCard>
      </ThePage>
    </template>
    <template v-else>
      <div class="tw-h-dvh tw-w-full tw-grid tw-grid-cols-1 lg:tw-grid-cols-2">
        <div
          class="tw-w-full lg:tw-row-start-1 tw-h-full lg:tw-overflow-y-auto tw-bg-white"
        >
          <template v-if="!stripeEmbeddedFormIsLoaded">
            <CustomerRegistrationCollectEmail
              :userEmailExists="userEmailExists"
              @loadStripeEmbeddedForm="loadStripeEmbeddedForm"
            >
            </CustomerRegistrationCollectEmail>
          </template>
          <template v-else>
            <StripeEmbeddedForm
              :subscription="subscription"
              :email="email"
              @displayLogin="displayLogin"
            ></StripeEmbeddedForm>
          </template>
        </div>
        <div class="tw-row-start-1 tw-w-full tw-p-4 lg:tw-p-10 tw-h-full">
          <div
            class="tw-bg-blue-800 tw-rounded tw-py-10 tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-8 lg:tw-mt-56 lg:tw-px-10 tw-px-4"
          >
            <img
              style="max-width: 25rem"
              src="../../../assets/svg/logos/Bold_Xchange_PrimaryLogo_White_LG.png"
              alt="Image Description"
            />
            <p class="tw-text-2xl tw-text-left tw-text-white tw-m-0">
              Craft gifting campaigns—fast—that build pipeline and close deals.
            </p>
          </div>
        </div>
      </div>
    </template>
    <!-- </template> -->
  </template>
</template>

<script>
import ThePage from "../../../components-v2/ui/layout/ThePage.vue";
import TheCard from "../../../components-v2/ui/layout/TheCard.vue";
import CustomerRegistrationCollectEmail from "./CustomerRegistrationCollectEmail.vue";
import CustomerRegistrationPageSkeleton from "../../../components-v2/ui/skeletons/page-skeletons/CustomerRegistrationPageSkeleton";
import StripeEmbeddedForm from "./StripeEmbeddedForm";
export default {
  inject: ["mixpanel"],
  name: "CustomerRegistration",
  components: {
    CustomerRegistrationPageSkeleton,
    CustomerRegistrationCollectEmail,
    StripeEmbeddedForm,
    TheCard,
    ThePage,
  },
  created() {
    const metadataTag = this.$route.params.metadataTag;
    this.listSubscriptions(metadataTag);
    this.trackPageView();
  },
  data() {
    return {
      stripeEmbeddedFormIsLoaded: false,
      email: null,
      subscription: null,
      listingSubscription: true,
      userEmailExists: false,
      count: 0,
    };
  },
  methods: {
    loadStripeEmbeddedForm(value) {
      this.email = value;
      this.stripeEmbeddedFormIsLoaded = true;
      this.userEmailExists = false;
    },
    displayLogin() {
      this.email = null;
      this.stripeEmbeddedFormIsLoaded = false;
      this.userEmailExists = true;
    },
    async listSubscriptions(metadataTag) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      this.listingSubscription = true;

      let requestBody = {
        "metadata.tag": metadataTag,
      };

      try {
        const listSubscriptionsRes = await api.listSubscriptions(null, {
          filter: requestBody,
        });
        console.log(
          "subscription count",
          listSubscriptionsRes.data.result.count
        );
        const count = listSubscriptionsRes.data.result.count;
        if (!count) {
          this.$router.push("/");
        }
        this.subscription = listSubscriptionsRes.data.result.records[0].id;
      } catch (error) {
        this.stripeFormIsLoading = false;
        if (error.response.status === 401) {
          console.log("Unauthorized: Invalid credentials or token.");
        } else {
          console.log("error: ", error);
        }
      } finally {
        this.listingSubscription = false;
      }
    },
    /** mixpanel */
    trackPageView() {
      const page = this.$route.path;
      this.mixpanel.track("Page View", {
        Page: page,
        Platform: "Bold APP",
      });
    },
  },
};
</script>
