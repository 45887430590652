<template>
  <div class="col">
    <a>
      <div @click="viewProductDetails()" class="product-card card-transition">
        <div class="image-container">
          <img
            v-if="productMediaArray.length > 0"
            :src="productMediaArray[0]"
            class="image-content"
          />
          <img
            v-else
            src="../../assets/svg/logos/bold-placeholder-thumbnail.png"
          />
          <div
            class="image-text d-flex justify-content-center align-items-center"
          ></div>
        </div>
        <div class="mt-1">
          <p class="card-title text-start mb-0 fs-4 fw-semibold">
            {{ getProductName }}
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "TopProductListItem",
  props: ["product", "media"],
  data() {
    return {
      showProductDetails: false,
      isHovering: false,
      productMediaArray: [],
      kitMediaArray: [],
      isSuccessful: false,
    };
  },
  created() {
    this.createProductUrl();
  },
  computed: {
    getProductSku() {
      return this.product.sku;
    },
    getProductName() {
      return this.product.name;
    },
    getProductDescription() {
      return this.product.longDescription;
    },
    getVendorId() {
      return this.product.vendor._id;
    },
    getProductVendor() {
      return this.product.vendor.name;
    },
    isMediaAvailable() {
      if (this.media.length < 1) {
        return false;
      }
      return true;
    },
    getAllMedia() {
      return this.productMediaArray;
    },
  },
  methods: {
    toggleProductDetails() {
      this.showProductDetails = !this.showProductDetails;
    },
    routeToBrandPartnerBio() {
      this.$router.push(`/vendor/${this.getVendorId}`);
    },
    async createProductUrl() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      const mediaArr = this.media;

      var mediaUrlArr = [];

      for (let i = 0; i < mediaArr.length; i++) {
        let _id = mediaArr[i]._id;
        let url = api.utils.getMediaUrl(_id);
        mediaUrlArr.push(url);
      }
      this.productMediaArray = mediaUrlArr;
    },
    viewProductDetails() {
      this.$router.push(`/product/${this.getProductSku}`);
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}

.skeleton {
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }
  100% {
    background-color: #f0f3f5;
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

.card-product {
  transition: 0.3s;
}

.card-product:hover {
  box-shadow: 0 0.1875rem 0.75rem rgba(140, 152, 164, 0.25) !important;
}

.subtitle {
  margin-top: 8px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}

.image-container {
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.image-text h2 {
  margin: 0;
  color: white;
  text-align: center;
}

img {
  /* border: solid red; */
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f5f5f5;
  object-fit: contain;
  object-position: 50% 50%;
}
</style>
