<template>
  <template v-if="showImportRecipientsModal">
    <Teleport to="#modal">
      <WideModal
        title="Importing recipients for member"
        @closeModal="toggleRecipientsImportModal"
        :open="showImportRecipientsModal"
        :showModalHeader="true"
        :closeOnClickOutside="false"
      >
        <ImportData :memberId="memberId" @reloadData="reloadData"></ImportData>
      </WideModal>
    </Teleport>
  </template>
  <NotificationMessage :scope="$options.name"></NotificationMessage>

  <ThePage :fullWidth="true">
    <template v-if="isLoading">
      <CampaignDetailsPageSkeleton></CampaignDetailsPageSkeleton>
    </template>
    <template v-else>
      <BlockStack :gap="800" :fullWidth="true">
        <BlockStack :gap="800" class="tw-w-full tw-max-w-6xl">
          <PageSection title="Quick actions">
            <div class="tw-flex tw-gap-2">
              <TheButton
                type="button"
                variant="secondary"
                size="regular"
                @click.stop="toggleRecipientsImportModal()"
                :hasLeadingIcon="true"
              >
                <template #leading-icon
                  ><b-icon-upload></b-icon-upload
                ></template>
                <template #text>Import recipients</template>
              </TheButton>
            </div>
          </PageSection>
          <PageSection title="Contact Information">
            <TheCard :fullWidth="true" class="tw-relative">
              <template v-if="!isEditingDetails">
                <div class="tw-absolute tw-right-2 tw-top-2">
                  <TheButton
                    variant="primary"
                    size="small"
                    @click="editMemberDetails()"
                    :hasLeadingIcon="true"
                  >
                    <template #leading-icon><b-icon-pencil /></template>
                    <template #text> Edit </template>
                  </TheButton>
                </div>
              </template>
              <template v-if="isEditingDetails">
                <div class="tw-absolute tw-right-2 tw-top-2 tw-flex tw-gap-2">
                  <TheButton
                    :disabled="!formComplete || isSaving"
                    variant="success"
                    size="small"
                    @click="upsertUser()"
                  >
                    <template #text> Save </template>
                  </TheButton>
                  <TheButton
                    variant="tertiary"
                    size="small"
                    @click="cancelEdit()"
                    :hasLeadingIcon="true"
                  >
                    <template #text> Cancel </template>
                  </TheButton>
                </div>
              </template>

              <TheBox :withPadding="true">
                <BlockStack :gap="200">
                  <div class="sm:tw-flex tw-items-center">
                    <h4
                      class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                    >
                      First Name<span
                        v-if="!memberFirstName && isEditingDetails"
                        class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required field</span
                      >
                    </h4>
                    <!-- <template v-if="!isEditingDetails"> -->
                    <input
                      v-model="form.firstName"
                      type="text"
                      name="firstName"
                      id="firstName"
                      :class="{
                        'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6':
                          !isEditingDetails,
                        'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                          isEditingDetails,
                      }"
                      class="tw-block tw-max-w-sm tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6"
                      placeholder="First name"
                      :readonly="!isEditingDetails"
                    />
                    <!-- </template> -->
                    <!-- <div class="tw-flex tw-items-center tw-gap-2">
                      <p
                        class="tw-text-sm tw-font-bold tw-text-gray-600 tw-text-center tw-m-0 tw-capitalize"
                      >
                        {{ memberFirstName || "No info" }}
                      </p>
                    </div> -->
                  </div>
                  <div class="sm:tw-flex tw-items-center">
                    <h4
                      class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                    >
                      Last Name<span
                        v-if="!memberLastName && isEditingDetails"
                        class="tw-text-red-500 tw-m-0 tw-text-sm tw-ms-2 tw-bg-red-50 tw-py-1 tw-px-2 tw-rounded"
                        >required field</span
                      >
                    </h4>
                    <input
                      v-model="form.lastName"
                      type="text"
                      name="lastName"
                      id="lastName"
                      :class="{
                        'tw-bg-gray-100 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6':
                          !isEditingDetails,
                        'tw-block tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-blue-600 tw-text-lg tw-leading-6':
                          isEditingDetails,
                      }"
                      class="tw-block tw-max-w-sm tw-w-full tw-rounded-md tw-border-0 tw-px-2.5 tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 tw-text-lg tw-leading-6"
                      placeholder="Last name"
                      :readonly="!isEditingDetails"
                    />
                    <!-- <div class="tw-flex tw-items-center tw-gap-2">
                      <p
                        class="tw-text-sm tw-font-bold tw-text-gray-600 tw-text-center tw-m-0 tw-capitalize"
                      >
                        {{ memberLastName || "No info" }}
                      </p>
                    </div> -->
                  </div>
                  <div class="sm:tw-flex tw-items-center">
                    <h4
                      class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                    >
                      Email
                    </h4>
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <p
                        class="tw-text-sm tw-font-bold tw-text-gray-600 tw-text-center tw-m-0"
                      >
                        {{ memberEmail || "No information" }}
                      </p>
                    </div>
                  </div>
                  <div class="sm:tw-flex tw-items-start">
                    <h4
                      class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                    >
                      Client/Organization
                    </h4>
                    <div class="tw-flex tw-flex-col tw-gap-2">
                      <template v-if="member?.client?.name">
                        <p
                          class="tw-text-sm tw-font-bold tw-text-gray-600 tw-m-0"
                        >
                          {{
                            memberClientName ||
                            "Client/Organization name is missing"
                          }}
                        </p>
                        <p
                          class="tw-text-sm tw-font-bold tw-text-gray-600 tw-m-0"
                        >
                          {{
                            memberClientDomain ||
                            "Client/Organization domain is missing"
                          }}
                        </p>
                      </template>
                      <template v-else>
                        <p
                          class="tw-text-sm tw-font-bold tw-text-red-600 tw-m-0"
                        >
                          {{ "This member does not belong to a client" }}
                        </p>
                      </template>
                    </div>
                  </div>
                  <div class="sm:tw-flex tw-items-center">
                    <h4
                      class="tw-font-medium tw-text-gray-900 sm:tw-w-64 sm:tw-flex-none sm:tw-pr-6 tw-m-0"
                    >
                      Member Since
                    </h4>
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <p
                        class="tw-text-sm tw-font-bold tw-text-gray-600 tw-text-center tw-m-0"
                      >
                        {{ formattedDate }}
                      </p>
                    </div>
                  </div>
                </BlockStack>
              </TheBox>
            </TheCard>
          </PageSection>
          <PageSection title="Stats">
            <MemberStats :member="member"></MemberStats>
          </PageSection>
          <PageSection>
            <div class="tw-flex tw-gap-2">
              <TheButton
                type="button"
                :variant="showCampaigns ? 'new' : 'tertiary'"
                size="large"
                @click.stop="toggleCampaignsView()"
                :hasLeadingIcon="true"
              >
                <template #leading-icon>
                  <template v-if="!showCampaigns">
                    <b-icon-eye></b-icon-eye>
                  </template>
                  <template v-else>
                    <b-icon-eye-slash></b-icon-eye-slash>
                  </template>
                </template>

                <template #text>{{
                  showCampaigns ? "Hide campaigns" : "View campaigns"
                }}</template>
              </TheButton>
              <TheButton
                type="button"
                :variant="showRecipients ? 'new' : 'tertiary'"
                size="large"
                @click.stop="toggleRecipientsView()"
                :hasLeadingIcon="true"
              >
                <template #leading-icon>
                  <template v-if="!showRecipients">
                    <b-icon-eye></b-icon-eye>
                  </template>
                  <template v-else>
                    <b-icon-eye-slash></b-icon-eye-slash>
                  </template>
                </template>

                <template #text>{{
                  showRecipients ? "Hide recipients" : "View recipients"
                }}</template>
              </TheButton>
            </div>
            <template v-if="showCampaigns && !showRecipients">
              <MemberCampaignsIndexTable
                :memberView="true"
                :memberId="memberId"
              ></MemberCampaignsIndexTable>
            </template>
            <template v-if="!showCampaigns && showRecipients">
              <MemberRecipientsIndexTable
                :memberView="true"
                :memberId="memberId"
              ></MemberRecipientsIndexTable>
            </template>
          </PageSection>
        </BlockStack>
      </BlockStack>
    </template>
  </ThePage>
</template>

<script>
import ThePage from "../../components-v2/ui/layout/ThePage.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import BlockStack from "../../components-v2/ui/layout/BlockStack.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheBox from "../../components-v2/ui/layout/TheBox.vue";
import CampaignDetailsPageSkeleton from "../../components-v2/ui/skeletons/page-skeletons/CampaignDetailsPageSkeleton.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import WideModal from "../../components-v2/ui/modals/WideModal.vue";
import ImportData from "../../components-v2/ui/modals/ImportData.vue";
import MemberCampaignsIndexTable from "../../components-v2/members/MemberCampaignsIndexTable";
import MemberRecipientsIndexTable from "../../components-v2/members/MemberRecipientsIndexTable";
import MemberStats from "../../components-v2/members/MemberStats.vue";
import NotificationMessage from "../../components-v2/ui/feedback/NotificationMessage.vue";
import moment from "moment";

export default {
  name: "MemberDetails",
  components: {
    NotificationMessage,
    MemberRecipientsIndexTable,
    MemberCampaignsIndexTable,
    MemberStats,
    ImportData,
    WideModal,
    TheButton,
    ThePage,
    PageSection,
    CampaignDetailsPageSkeleton,
    BlockStack,
    TheBox,
    TheCard,
  },
  data() {
    return {
      member: {},
      form: {},
      recipients: [],
      recipientCount: 0,
      showImportRecipientsModal: false,
      isLoading: true,
      showCampaigns: false,
      showRecipients: false,
      isEditingDetails: false,
      isSaving: false,
    };
  },
  async created() {
    await this.handleCreatedLifecycleLogic();
  },
  computed: {
    User() {
      return this.$store.state.auth.user;
    },
    formComplete() {
      return this.memberFirstName && this.memberLastName;
    },
    memberId() {
      return this.$route.params.memberId;
    },
    memberFirstName() {
      return this?.form?.firstName?.trim() || "";
    },
    memberLastName() {
      return this?.form?.lastName?.trim() || "";
    },
    memberEmail() {
      return this?.member?.email;
    },
    memberClientName() {
      return this?.member?.client?.name;
    },
    memberClientDomain() {
      return this?.member?.client?.domains[0];
    },
    formattedDate() {
      const date = moment(this?.member?.createdAt).format("MM/DD/YYYY");
      return date;
    },
    notificationTypes() {
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** loading member info */
    async loadMemberInformation() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      const api = await this.getBoldClient();
      this.isLoading = true;

      let requestBody = {
        _id: this.memberId,
      };

      let selection = "client firstName lastName email createdAt recipients";

      const queryParameters = {
        limit: 1,
        sort: "firstName",
        expand: "client recipients",
      };

      try {
        const response = await api.listUsers(queryParameters, {
          filter: requestBody,
          selection: selection,
        });

        this.isLoading = false;
        this.member = response.data.result.records[0];
      } catch (error) {
        console.log("member did not load", error);
        this.isLoading = false;
      }
    },
    async checkIfUserHasRecipients() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();

      let requestBody = {};
      let filter = {};

      filter = {
        "metadata.giftingLeads": { $in: [this.memberId] },
      };

      let selection = "firstName lastName contact metadata";

      const queryParameters = {
        skip: 1,
        expand: "contact campaigns user",
      };

      requestBody = {
        filter: filter,
        selection: selection,
      };

      try {
        const response = await api.listRecipients(queryParameters, requestBody);

        this.recipientCount = response.data.result.count;
        this.recipients = response.data.result.records;
      } catch (error) {
        console.log("recipients did not load", error);
      }
    },
    async upsertUser() {
      /**@type {import('@boldxchange/sdk/defs').Client}*/

      this.isSaving = true;
      const api = await this.getBoldClient();

      const memberToUpdate = {
        firstName: this.memberFirstName,
        lastName: this.memberLastName,
        // email: this.member.email,
        // role: this.member.role,
      };

      // console.log("userToUpdate", memberToUpdate);

      try {
        await api.upsertUser(null, {
          filter: { _id: this.memberId },
          patch: memberToUpdate,
        });
        this.handleSuccess(); //
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.log("user was NOT edited", error);
      } finally {
        this.isSaving = false;
      }
    },
    async handleCreatedLifecycleLogic() {
      await this.loadMemberInformation();
      await this.checkIfUserHasRecipients();
      this.initializeForm(this.member);
    },
    editMemberDetails() {
      this.isEditingDetails = !this.isEditingDetails;
    },
    initializeForm(memberData) {
      this.form.firstName = memberData.firstName;
      this.form.lastName = memberData.lastName;
      this.form._id = memberData._id;
    },
    cancelEdit() {
      this.initializeForm(this.member);
      this.isEditingDetails = false;
    },
    toggleCampaignsView() {
      if (this.showRecipients) {
        this.showRecipients = false;
      }
      this.showCampaigns = !this.showCampaigns;
    },
    toggleRecipientsView() {
      if (this.showCampaigns) {
        this.showCampaigns = false;
      }
      this.showRecipients = !this.showRecipients;
    },

    /** handle recipient import */
    toggleRecipientsImportModal() {
      this.showImportRecipientsModal = !this.showImportRecipientsModal;
    },
    reloadData() {
      this.showImportRecipientsModal = false;
      this.loadMemberInformation();
      this.checkIfUserHasRecipients();
    },
    /** handle success and errors */
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      console.error(errorMessage);
    },
    handleSuccess() {
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: "Member successfully edited",
        duration: 6000,
        id: Date.now(),
      });
      this.isEditingDetails = false;
      this.handleCreatedLifecycleLogic();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
