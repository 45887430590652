// store/modules/notifications/actions.js
export default {
  notifySuccess({ commit }, message) {
    commit("SHOW_NOTIFICATION", { type: "success", message });
    setTimeout(() => {
      commit("CLEAR_NOTIFICATION");
    }, 3000); // Automatically clear notification after 5 seconds
  },
  notifyError({ commit }, message) {
    commit("SHOW_NOTIFICATION", { type: "error", message });
    setTimeout(() => {
      commit("CLEAR_NOTIFICATION");
    }, 3000);
  },
  clearNotification({ commit }) {
    commit("CLEAR_NOTIFICATION");
  },
};
