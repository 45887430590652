export default {
  setQuickEditActive(state, isActive) {
    state.quickEditActive = isActive;
  },
  SET_BOOKING(state, booking) {
    state.selectedBooking = booking;
  },
  SET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY(state, requestBody) {
    // state.campaignGiftsSelectionRequestBody = requestBody;
    if(!state?.campaignBuilderData?.metadata?.collection_query?.requestBody){
      state.campaignBuilderData.metadata.collection_query.requestBody = {}
    }
    state.campaignBuilderData.metadata.collection_query.requestBody = requestBody

    console.log("SET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY: ", requestBody);

  },
  RESET_CAMPAIGN_GIFTS_SELECTION_REQUEST_BODY(state, requestBody) {
    state.campaignGiftsSelectionRequestBody = requestBody;
  },

  /** campaign builder mutations */
  SET_CAMPAIGN_BUILDER_DATA(state, campaign) {
    state.campaignBuilderData = campaign;
    console.log("SET_CAMPAIGN_BUILDER_DATA: ", campaign);

  },
  SET_CAMPAIGN_BUILDER_BUDGET(state, selectedBudget) {
    state.selectedBudgetData = selectedBudget;
  },
  SET_CAMPAIGN_BUILDER_BUDGET_ID(state, budgetId) {
    state.campaignBuilderData.metadata.budget = budgetId ? budgetId : null;
  },
  SET_CAMPAIGN_BUILDER_BUDGET_TRACKER(state, budgetTracker) {
    state.campaignBudgetTracker = budgetTracker;
  },
  SET_CAMPAIGN_BUILDER_BUDGET_LIMIT_STATUS(state, budgetLimitStatus) {
    state.isBudgetLimitMet = budgetLimitStatus;
  },
  SET_CAMPAIGN_BUILDER_CAMPAIGN_NAME(state, campaignName) {
    state.campaignBuilderData.metadata.campaign_name = campaignName;
  },
  SET_CAMPAIGN_BUILDER_CAMPAIGN_TYPE(state, campaignType) {
    state.campaignBuilderData.metadata.campaign_type = campaignType;
  },
  SET_CAMPAIGN_BUILDER_CAMPAIGN_TYPE_CONDITIONALLY(state) {
    if (state.campaignBuilderData.recipients.length <= 1) {
      state.campaignBuilderData.metadata.campaign_type = "email";
    }
  },
  SET_CAMPAIGN_BUILDER_EMAIL_SUBJECT(state, emailSubject) {
    state.campaignBuilderData.subject = emailSubject;
  },
  SET_CAMPAIGN_BUILDER_EMAIL_TEXT(state, emailText) {
    state.campaignBuilderData.text = emailText;
  },
  SET_CAMPAIGN_BUILDER_GIFT_CARD_AMOUNT(state, amount) {
    let giftCardAmount = amount;
    state.campaignBuilderData.metadata.gift_card_amount = giftCardAmount;

    // Check if the campaignGiftsSelectionRequestBody is truthy
    if (state?.campaignGiftsSelectionRequestBody?.filter?.$and) {
      let filterConditions =
        state?.campaignGiftsSelectionRequestBody?.filter?.$and;

      // Find the condition with "metadata.gift_card_minimum"
      const giftCardMinimumCondition = filterConditions.find((condition) =>
        condition.hasOwnProperty("metadata.gift_card_minimum")
      );

      // Update the $lte value if the condition is found
      if (giftCardMinimumCondition) {
        giftCardMinimumCondition["metadata.gift_card_minimum"].$lte =
          giftCardAmount;
      }
    }
  },
  SET_CAMPAIGN_BUILDER_RECIPIENTS(state, recipients) {
    state.campaignBuilderData.recipients = recipients;
  },
  SET_CAMPAIGN_BUILDER_RECIPIENTS_VISIBLE(state, isVisible) {
    state.areCampaignBuilderRecipientsVisible = isVisible;
  },
  TOGGLE_CAMPAIGN_BUILDER_RECIPIENTS_VISIBLE(state) {
    state.areCampaignBuilderRecipientsVisible =
      !state.areCampaignBuilderRecipientsVisible;
  },
  SHOW_CAMPAIGN_BUILDER_RECIPIENTS_VISIBLE(state) {
    state.areCampaignBuilderRecipientsVisible = true;
  },
  SET_CAMPAIGN_BUILDER_BOOKING_CALENDAR_PROVIDER(state, calendarProvider) {
    console.log("calendarProvider: ", calendarProvider);
    if (!state.campaignBuilderData.booking) {
      state.campaignBuilderData.booking = {};
    }

    state.campaignBuilderData.booking.provider = calendarProvider;
  },
  SET_CAMPAIGN_BUILDER_BOOKING_CALENDAR_SUMMARY(state, calendarSummary) {
    console.log("calendarSummary: ", calendarSummary);
    if (!state.campaignBuilderData.booking) {
      state.campaignBuilderData.booking = {};
    }

    state.campaignBuilderData.booking.summary = calendarSummary;

    if (
      state.campaignBuilderData.booking.summary &&
      state.campaignBuilderData.booking.description
    ) {
      state.campaignBuilderData.booking.required = true;
    } else {
      state.campaignBuilderData.booking.required = false;
    }
  },
  SET_CAMPAIGN_BUILDER_BOOKING_CALENDAR_DESCRIPTION(
    state,
    calendarDescription
  ) {
    console.log("calendarDescription: ", calendarDescription);
    if (!state.campaignBuilderData.booking) {
      state.campaignBuilderData.booking = {};
    }

    state.campaignBuilderData.booking.description = calendarDescription;

    if (
      state.campaignBuilderData.booking.summary &&
      state.campaignBuilderData.booking.description
    ) {
      state.campaignBuilderData.booking.required = true;
    } else {
      state.campaignBuilderData.booking.required = false;
    }
  },
  SET_CAMPAIGN_BUILDER_BOOKING_ENABLED(state, isBookingEnabled) {
    console.log("isBookingEnabled: ", isBookingEnabled);

    if (!state.campaignBuilderData.booking) {
      state.campaignBuilderData.booking = {};
    }

    state.campaignBuilderData.booking.description = "";
    state.campaignBuilderData.booking.summary = "";
    state.campaignBuilderData.booking.enabled = isBookingEnabled;
    if (
      state.campaignBuilderData.booking.summary &&
      state.campaignBuilderData.booking.description
    ) {
      state.campaignBuilderData.booking.required = true;
    } else {
      state.campaignBuilderData.booking.required = false;
    }
    if (!isBookingEnabled) {
      state.campaignBuilderData.booking.summary = "Untitled booking";
    }
    console.log(
      "state.campaignBuilderData.booking: ",
      state.campaignBuilderData.booking.required
    );
  },

  SET_CAMPAIGN_BUILDER_GIFT_NOTE_ENABLED(state, isLandingPageEnabled) {
    console.log("isBookingEnabled: ", isLandingPageEnabled);

    if (!state?.campaignBuilderData?.metadata?.landingPage) {
      state.campaignBuilderData.metadata.landingPage = {};
    }

    state.campaignBuilderData.metadata.landingPage.title = "";
    state.campaignBuilderData.metadata.landingPage.message = "";
    state.campaignBuilderData.metadata.landingPage.enabled =
      isLandingPageEnabled;
    if (
      state.campaignBuilderData.metadata.landingPage.title &&
      state.campaignBuilderData.metadata.landingPage.message
    ) {
      state.campaignBuilderData.metadata.landingPage.required = true;
    } else {
      state.campaignBuilderData.metadata.landingPage.required = false;
    }

    console.log(
      "state.campaignBuilderData.metadata.landingPage: ",
      state.campaignBuilderData.metadata.landingPage.required
    );
  },
  SET_CAMPAIGN_BUILDER_GIFT_NOTE_SETTINGS(state, landingPageSettings) {
    console.log("landingPageSettings: ", landingPageSettings);
    if (!state?.campaignBuilderData?.metadata?.landingPageSettings) {
      state.campaignBuilderData.metadata.landingPageSettings = {};
    }

    state.campaignBuilderData.metadata.landingPageSettings =
      landingPageSettings;
  },
  SET_CAMPAIGN_BUILDER_GIFT_NOTE_TITLE(state, landingPageTitle) {
    console.log("landingPageTitle: ", landingPageTitle);
    if (!state?.campaignBuilderData?.metadata?.landingPageSettings?.giftNote) {
      state.campaignBuilderData.metadata.landingPageSettings.giftNote = {};
    }

    state.campaignBuilderData.metadata.landingPageSettings.giftNote.title =
      landingPageTitle;
    if (
      state.campaignBuilderData.metadata.landingPageSettings.giftNote.title &&
      state.campaignBuilderData.metadata.landingPageSettings.giftNote.message
    ) {
      state.campaignBuilderData.metadata.landingPageSettings.required = true;
    } else {
      state.campaignBuilderData.metadata.landingPageSettings.required = false;
    }
  },
  SET_CAMPAIGN_BUILDER_GIFT_NOTE_MESSAGE(state, landingPageMessage) {
    console.log("landingPageMessage: ", landingPageMessage);
    if (!state?.campaignBuilderData?.metadata?.landingPageSettings?.giftNote) {
      state.campaignBuilderData.metadata.landingPageSettings.giftNote = {};
    }

    state.campaignBuilderData.metadata.landingPageSettings.giftNote.message =
      landingPageMessage;
    if (
      state.campaignBuilderData.metadata.landingPageSettings.giftNote.title &&
      state.campaignBuilderData.metadata.landingPageSettings.giftNote.message
    ) {
      state.campaignBuilderData.metadata.landingPageSettings.required = true;
    } else {
      state.campaignBuilderData.metadata.landingPageSettings.required = false;
    }
  },

  SET_CAMPAIGN_TEMPLATE_BUILDER_STATUS(state, isPrivate) {
    console.log("isPrivate: ", isPrivate);
    if (state?.campaignBuilderData?.metadata?.campaign_template?.isPrivate) {
      state.campaignBuilderData.metadata.campaign_template.isPrivate =
        isPrivate;
    }
  },
};
