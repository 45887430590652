// store/modules/notifications/getters.js
export default {
  scopedAlerts: (state) => (scope) => {
    return (
      state.alerts.filter((alert) => alert.scope === scope || !alert.scope) ||
      []
    );
  },

  getAlertTypes: (state) => state.alertTypes,
};
