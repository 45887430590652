<template>
  <template v-if="checkingIfUserHasCampaigns">
    <PageSection :fullWidth="true">
      <PulseAnimation>
        <GiftCardsGridSkeleton :numberOfCards="8"></GiftCardsGridSkeleton>
      </PulseAnimation>
    </PageSection>
  </template>
  <template v-else>
    <template v-if="campaignsAreLoading">
      <PulseAnimation>
        <GiftCardsGridSkeleton :numberOfCards="8"></GiftCardsGridSkeleton>
      </PulseAnimation>
    </template>
    <template v-else>
      <!-- if userHasCampaigns show campaigns list -->
      <template v-if="userHasCampaigns && count">
        <div>
          <ul
            role="list"
            class="tw-m-0 tw-p-0 tw-list-none tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-8 xs:tw-grid-cols-2 sm:tw-grid-cols-2 sm:tw-gap-x-6 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 xl:tw-gap-x-8"
          >
            <CampaignTemplatesListItem
              v-for="campaign in this.campaigns"
              :key="campaign.id"
              :campaign="campaign"
              @checkForCampaigns="checkForCampaigns"
            >
            </CampaignTemplatesListItem>
          </ul>
        </div>
      </template>
      <!-- if userHasCampaigns AND query count is 0 show campaigns message -->
      <template v-if="userHasCampaigns && !count">
        <div
          class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[500px] tw-min-w-96 tw-p-10"
        >
          <img
            style="max-height: 150px; width: auto"
            src="../../assets/svg/illustrations-test/14_no_query_results.png"
            alt="Image Description"
          />
          <h1 class="tw-text-2xl tw-m-0">No exact matches</h1>
          <p class="tw-m-0 tw-text-lg">
            Try updating your search/filters or
            <a class="tw-font-bold tw-cursor-pointer" @click="resetFilters()"
              >click here</a
            >
            to reset.
          </p>
        </div>
      </template>
      <!-- if !userHasCampaigns show message -->
      <template v-if="!userHasCampaigns">
        <PageSection class="tw-w-full">
          <TheCard class="tw-w-full">
            <EmptyState :emptyState="activeList"></EmptyState>
            <template v-if="activeList !== 'boldTemplates'">
              <div class="tw-pb-6 tw-flex tw-justify-center">
                <TheButton
                  :hasLeadingIcon="true"
                  variant="primary"
                  @click="createCampaignTemplateDraft()"
                  size="large"
                >
                  <template #text>
                    <template v-if="creatingCampaignTemplateDraft"
                      >Creating campaign</template
                    >
                    <template v-else>Build template</template>
                  </template>
                  <template #leading-icon>
                    <template v-if="creatingCampaignTemplateDraft">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="tw-inline tw-w-4 tw-h-4 tw-text-gray-200 tw-animate-spin dark:tw-text-gray-600 tw-fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="tw-sr-only">Loading...</span>
                      </div>
                    </template>
                  </template>
                </TheButton>
              </div>
            </template>
          </TheCard>
        </PageSection>
      </template>
    </template>
  </template>
</template>

<script>
import CampaignTemplatesListItem from "./CampaignTemplatesListItem.vue";
import TheCard from "../../components-v2/ui/layout/TheCard.vue";
import TheButton from "../../components-v2/ui/actions/TheButton.vue";
import PulseAnimation from "../../components-v2/ui/feedback/PulseAnimation.vue";
import GiftCardsGridSkeleton from "../../components-v2/ui/skeletons/GiftCardsGridSkeleton.vue";
import EmptyState from "../../components-v2/ui/feedback/EmptyState.vue";
import PageSection from "../../components-v2/ui/layout/PageSection.vue";
import {
  deleteMailCampaigns,
  bulkUpdateMailCampaigns,
  campaignRecipientsEngagement,
  campaignEngagementStats,
} from "@/services/campaignService";

export default {
  name: "CampaignTemplatesList",
  components: {
    TheButton,
    CampaignTemplatesListItem,
    GiftCardsGridSkeleton,
    PulseAnimation,
    EmptyState,
    TheCard,
    PageSection,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    boldTemplates: {
      type: Boolean,
    },
    savedTemplates: {
      type: Boolean,
    },
    draftTemplates: {
      type: Boolean,
    },
    sharedCampaignData: {
      type: Array,
      default: () => [],
    },
    totalCampaignsCount: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  created() {
    /** load data */
    this.checkForCampaigns();
  },
  data() {
    return {
      statusOptions: [
        { value: "all" },
        { value: "sent" },
        { value: "clicked" },
        { value: "redeemed" },
        { value: "draft" },
      ],
      typeOptions: [{ value: "email" }, { value: "link" }],
      dateOptions: [
        { value: "All time" },
        { value: "This week" },
        { value: "Past 7 days" },
        { value: "Past 30 days" },
        { value: "Past 90 days" },
      ],
      selectedCampaigns: [],
      selectedRows: [],
      campaigns: [],
      selectedNeighborhoods: [],
      campaignIdToDelete: null,
      campaignId: null,
      count: 0,
      pageSize: 25,
      currentPage: 1,
      searchQuery: "",
      sortedValue: "-_id",
      selectedDateRange: "",
      selectedCampaignType: "",
      selectedCampaignStatus: "all",
      bulkEditCampaignStatus: "",
      areQuickEditsActive: false,
      customDateRangeApplied: false,
      noDataPlaceholderText: "---",
      startDate: "",
      endDate: "",
      startDateTimestamp: null,
      endDateTimestamp: null,
      stamp: null,
      isBulkEditing: false,
      isSearchFocused: false,
      searchIsActive: false,
      checkingIfUserHasCampaigns: true,
      campaignsAreLoading: false,
      userHasCampaigns: true,
      userHasNoCampaigns: false,
      selectAll: false,
      showFilterModal: false,
      showDeleteModal: false,
    };
  },
  computed: {
    activeList() {
      if (this.boldTemplates) {
        return "boldTemplates";
      } else if (this.savedTemplates) {
        return "savedTemplates";
      } else {
        return "draftTemplates";
      }
    },
    currentUserEmail() {
      return this?.$store?.state?.auth?.user?.email;
    },
    user() {
      return this?.$store?.state?.auth?.user;
    },
    isLastPage() {
      return (
        this.count <= this.pageSize ||
        this.currentPage === Math.ceil(this.count) ||
        this.campaigns.length < this.pageSize ||
        this.count / this.currentPage === this.pageSize
      );
    },
    userName() {
      return (campaign) => {
        const firstName = campaign?.user?.firstName;
        const lastName = campaign?.user?.lastName;
        if (firstName && lastName) {
          return `${firstName} ${lastName}`;
        } else {
          return false;
        }
      };
    },
    userEmail() {
      return (campaign) => campaign?.user?.email;
    },
    recipientsCount() {
      return (campaign) => {
        return campaign?.recipients?.length || 0;
      };
    },
    getCampaignRecipientsEngagement() {
      return (campaign) => {
        const recipients = campaignRecipientsEngagement(campaign);
        return campaignEngagementStats(recipients);
      };
    },

    recipientName() {
      return (campaign) => {
        const recipientsCount = campaign?.recipients?.length || 0;
        // Handling no recipients
        if (recipientsCount === 0) {
          return "N/A";
        }

        // Handling a single recipient with both first and last names
        if (recipientsCount === 1) {
          const firstName = campaign.recipients[0]?.firstName;
          const lastName = campaign.recipients[0]?.lastName;
          if (firstName && lastName) {
            return `${firstName} ${lastName}`;
          } else {
            return "N/A";
          }
        }
      };
    },
    recipientEmail() {
      return (campaign) => {
        if (!campaign?.recipients[0]?.contact?.email) {
          return "N/A";
        }

        return campaign?.recipients[0]?.contact?.email;
      };
    },
    campaignName() {
      return (campaign) => {
        if (campaign?.metadata?.campaign_name) {
          return campaign?.metadata?.campaign_name;
        } else {
          return "Untitled Campaign";
        }
      };
    },
    campaignType() {
      return (campaign) => campaign?.metadata?.campaign_type;
    },
    campaignInvoice() {
      return (campaign) => {
        if (
          campaign?.invoice &&
          campaign?.metadata?.campaign_status === "sent"
        ) {
          return "Not fulfilled";
        } else if (
          campaign?.invoice &&
          campaign?.metadata?.campaign_status === "redeemed"
        ) {
          return "Order fulfilled ";
        }
        return false;
      };
    },
    getTimeStamp() {
      return (id) => {
        if (id) {
          const objectIdToTimestamp = require("objectid-to-timestamp");
          const originalDate = new Date(objectIdToTimestamp(id));

          const year = originalDate.getFullYear();
          const month = (originalDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          const day = originalDate.getDate().toString().padStart(2, "0");

          return `${month}/${day}/${year}`;
        }
      };
    },
    searchBarPlaceholder() {
      return this.isBoldXchangeAdmin
        ? "Search by user name or email"
        : "Search by recipient name or email";
    },
    userId() {
      return this?.$store?.state?.auth?.user?._id;
    },
    notificationTypes() {
      // Access namespaced getter
      return this.$store.getters["alerts/getAlertTypes"];
    },
  },
  methods: {
    /** handle checking if user has campaigns */
    async checkForCampaigns(page) {
      /**@type {import('@boldxchange/sdk/defs').Client}*/
      const api = await this.getBoldClient();
      this.currentPage = page === undefined ? this.currentPage : page;
      const query = this.savedTemplates ? "saved" : "draft";

      try {
        // let requestBody = {
        // "metadata.campaign_template.status": { $eq: query },
        // $or: [
        //   { "metadata.campaign_status": { $eq: "saved" } },
        //   { "metadata.campaign_status": { $eq: "draft" } },
        // ],
        // };
        let requestBody = {};
        if (this.boldTemplates) {
          requestBody = {
            "metadata.campaign_template.isPrivate": false,
            "metadata.campaign_template.status": { $eq: "saved" },
          };
        } else if (this.savedTemplates) {
          requestBody = {
            "metadata.campaign_template.isPrivate": true,
            user: { $eq: this.userId },
            "metadata.campaign_template.status": { $eq: query },
          };
        } else {
          requestBody = {
            user: { $eq: this.userId },
            "metadata.campaign_template.status": { $eq: query },
          };
        }

        // requestBody = this.boldTemplates
        //   ? {
        //       "metadata.campaign_template.isPrivate": false,
        //       "metadata.campaign_template.status": { $eq: "saved" },
        //     }
        //   : {
        //       user: { $eq: this.userId },
        //       "metadata.campaign_template.status": { $eq: query },
        //     };

        const queryParameters = {
          skip: (this.currentPage - 1) * this.pageSize,
          limit: this.pageSize,
          sort: this.sortedValue,
        };

        requestBody = { ...requestBody, ...this.createSearchQuery() };

        const response = await api.listMailCampaigns(queryParameters, {
          filter: requestBody,
        });
        const count = response.data.result.count;
        this.count = count;
        this.userHasCampaigns = count;
        this.campaigns = response.data.result.records;
      } catch (error) {
        this.handleErrorFromAPI(error);
        console.error("Failed to load campaigns:", error);
        // Handle the error appropriately
      } finally {
        this.checkingIfUserHasCampaigns = false;
      }
    },

    /** handle creating draft campaign template*/
    async createCampaignTemplateDraft() {
      /**
       * @type {import('@boldxchange/sdk/defs').Client}
       */

      const api = await this.getBoldClient();
      this.creatingCampaignTemplateDraft = true;

      try {
        const user = this?.user;

        const fromEmail = user?.email;
        const fromName = `${user?.firstName} ${user?.lastName}`;
        const requestBody = {
          tabName: "Full collection",
          filter: {
            $or: [
              {
                categories: {
                  $in: [
                    "Wine & Spirits",
                    "Fine Dining",
                    "Casual Dining",
                    "Coffee Shop",
                    "Coffee",
                    "Cafe",
                    "Ice Cream",
                    "Desserts",
                    "Bakery",
                  ],
                },
              },
              {
                "metadata.tags": {
                  $in: [
                    "Wine & Spirits",
                    "Fine Dining",
                    "Casual Dining",
                    "Coffee Shop",
                    "Coffee",
                    "Cafe",
                    "Ice Cream",
                    "Desserts",
                    "Bakery",
                  ],
                },
              },
            ],
            $and: [
              {
                $or: [
                  { "metadata.isOnline": { $exists: false } },
                  { "metadata.isOnline": true },
                ],
              },
            ],
          },
        };

        const campaignData = {
          from: { email: fromEmail, name: fromName },
          subject: "",
          text: "",
          html: "",
          recipients: [],
          metadata: {
            campaign_template: {
              isPrivate: true, // default
              name: "Untitled Template", // default
              status: "draft", // default
            },
            campaign_name: "Untitled Campaign",
            campaign_status: "draft",
            campaign_type: "",
            gift_card_amount: 0,
            collection_query: {
              custom_selection_ids: 0,
              requestBody: requestBody,
            },
          },
          user: user?.id,
        };

        const campaignResponse = await api.createMailCampaigns(null, [
          campaignData,
        ]);
        this.handleSuccess(campaignResponse);
      } catch (error) {
        this.handleErrorFromAPI(
          error,
          "Something went wrong while attempting to create your new campaign template. Please reload the page and try again."
        );
      } finally {
        this.creatingCampaignTemplateDraft = false;
      }
    },

    /** handle deleting a campaign */
    async confirmDelete() {
      const campaignIdToDelete = this.campaignIdToDelete[0]._id;

      try {
        await deleteMailCampaigns(campaignIdToDelete);
        this.handleSuccess("deleteMailCampaigns");
      } catch (error) {
        console.error("Campaign was NOT deleted", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignIsSaving = false;
      }
    },

    /** handle pagination of records */
    async nextPage() {
      this.changePage(this.currentPage + 1);
    },

    async previousPage() {
      this.changePage(this.currentPage - 1);
    },

    async changePage(newPage) {
      this.deactivateQuickEdit();
      this.clearBulkEditSelections();
      this.campaignsAreLoading = true;
      this.currentPage = newPage;

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: this.isBoldXchangeAdmin
          ? "recipients user"
          : "recipients invoice",
      };

      let requestBody = this.createRequestBody();

      try {
        const response = await listMailCampaigns(queryParameters, requestBody);
        this.campaigns = response.data.result.records;
        this.count = response.data.result.count;
      } catch (error) {
        console.error("Failed to load campaigns:", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignsAreLoading = false;
      }
    },

    /** Query filters */
    createRecipientFilter(currentUserEmail) {
      if (!this.isBoldXchangeAdmin) {
        return { "recipients.contact.email": { $ne: currentUserEmail } };
      }
      return {};
    },
    createDateFilter() {
      let filter = {};
      if (this.stamp) {
        filter["_.id"] = { $gte: { $oidTime: this.stamp } };
      }
      if (this.customDateRangeApplied) {
        filter["_.id"] = {
          ...(filter["_.id"] || {}),
          $gte: { $oidTime: this.startDateTimestamp },
          $lte: { $oidTime: this.endDateTimestamp },
        };
      }
      return filter;
    },
    createTypeFilter() {
      if (this.selectedCampaignType) {
        if (this.selectedCampaignType === "link") {
          return {
            $or: [
              { "metadata.campaign_type": { $eq: this.selectedCampaignType } },
              { "metadata.isRedeemed": { $exists: true } },
            ],
          };
        }
        return { "metadata.campaign_type": { $eq: this.selectedCampaignType } };
      }
      return {};
    },
    createStatusFilter() {
      if (
        !this.selectedCampaignStatus ||
        this.selectedCampaignStatus === "all"
      ) {
        return {
          "metadata.campaign_status": { $eq: "savedTemplate" },
        };
      }

      let filter = {};

      switch (this.selectedCampaignStatus) {
        case "sent":
          filter = {
            $or: [
              { "metadata.campaign_status": { $eq: "sent" } },
              { "metadata.campaign_status": { $eq: "redeemed" } },
              { "metadata.isRedeemed": true },
            ],
          };
          break;
        case "redeemed":
          filter = {
            $or: [
              {
                "metadata.campaign_status": {
                  $eq: "redeemed",
                },
              },
              {
                "metadata.isRedeemed": true,
              },
              { "metadata.finalizations": { $exists: true } },
            ],
          };
          break;
        case "clicked":
          filter = {
            $or: [
              {
                $and: [
                  { "metadata.campaign_status": { $exists: true } },
                  {
                    $or: [
                      {
                        "metadata.campaign_status": {
                          $eq: "sent",
                        },
                      },
                      {
                        "metadata.campaign_status": {
                          $eq: "redeemed",
                        },
                      },
                    ],
                  },
                  {
                    seen: {
                      $eq: true,
                    },
                  },
                  {
                    $or: [
                      { "metadata.isRedeemed": { $exists: false } },
                      { "metadata.isRedeemed": true },
                    ],
                  },
                ],
              },
              {
                $and: [
                  { seen: { $exists: true } },
                  { seen: { $ne: [] } }, // { seen: { $ne: [] } },
                  { "metadata.campaign_status": { $ne: "draft" } },
                  { "metadata.campaign_status": { $exists: true } },
                ],
              },
            ],
          };
          break;
        default:
          filter = {
            "metadata.campaign_status": { $eq: this.selectedCampaignStatus },
          };
      }

      return filter;
    },
    combineFilters(filters) {
      return filters.reduce(
        (combined, filter) => ({ ...combined, ...filter }),
        {}
      );
    },
    createRequestBody() {
      let currentUserEmail = this?.User?.email;
      const filters = [
        this.createRecipientFilter(currentUserEmail),
        this.createDateFilter(),
        this.createTypeFilter(),
        this.createStatusFilter(),
        this.createSearchQuery(),
      ];

      return this.combineFilters(filters);
    },

    /** handle retrieving records by selected filter */
    async filterCampaigns(page) {
      this.deactivateQuickEdit();
      this.clearBulkEditSelections();
      this.campaignsAreLoading = true;
      this.showFilterModal = false;

      this.currentPage = page === undefined ? this.currentPage : page;

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: this.isBoldXchangeAdmin
          ? "recipients user"
          : "recipients invoice",
      };

      const requestBody = this.createRequestBody(); // Uses the refined logic for filter creation

      try {
        const { data } = await listMailCampaigns(queryParameters, requestBody);
        this.campaigns = data.result.records;
        this.count = data.result.count;
      } catch (error) {
        console.error("Failed to load campaigns:", error);
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignsAreLoading = false;
      }
    },

    /** handle retrieving records by search query */
    createSearchQuery() {
      if (this.searchQuery === "") return {};

      const searchValues = this.searchQuery.split(" ");
      let searchConditions = [];

      if (this.isBoldXchangeAdmin) {
        // When isBoldXchange is truthy
        searchConditions = searchValues
          .map((value) => [
            { "user.firstName": { $regex: value, $options: "i" } },
            { "user.lastName": { $regex: value, $options: "i" } },
            { "user.email": { $regex: value, $options: "i" } },
          ])
          .flat();
      } else {
        // When isBoldXchange is falsy
        searchConditions = searchValues
          .map((value) => [
            { "recipients.firstName": { $regex: value, $options: "i" } },
            { "recipients.lastName": { $regex: value, $options: "i" } },
            { "recipients.contact.email": { $regex: value, $options: "i" } },
          ])
          .flat();
      }

      return { $or: searchConditions };
    },
    createRequestBodyWithSearchQuery(currentUserEmail) {
      // Start with recipient filter or other base filters
      let filters = this.createRecipientFilter(currentUserEmail);

      // Add search query filters if applicable
      const searchQueryFilters = this.createSearchQuery();
      if (Object.keys(searchQueryFilters).length > 0) {
        filters = { ...filters, ...searchQueryFilters };
      }

      // Add any other filters here similarly

      return filters;
    },
    async getCampaignsBySearchQuery(page) {
      this.campaignsAreLoading = true;
      this.currentPage = page === undefined ? 1 : page;

      let currentUserEmail = this?.User?.email; // Adjust based on your store/user management
      const requestBody =
        this.createRequestBodyWithSearchQuery(currentUserEmail);

      const queryParameters = {
        skip: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize,
        sort: this.sortedValue,
        expand: this.isBoldXchangeAdmin
          ? "recipients user"
          : "recipients invoice",
      };

      try {
        const { data } = await listMailCampaigns(queryParameters, requestBody);
        this.campaigns = data.result.records;
        this.count = data.result.count;
      } catch (error) {
        console.error("Campaigns did not load", error);
        this.handleErrorFromAPI(error); // Ensure you have a method to handle API errors
      } finally {
        this.campaignsAreLoading = false;
      }
    },

    /** handle bulk and quick edits */
    async bulkdUpdateCampaigns() {
      this.campaignIsSaving = true; // Assuming you have a flag to indicate saving state

      const campaignIds = this.selectedRows.map((campaign) => campaign._id);
      const patchObject = {
        "metadata.campaign_status": this.bulkEditCampaignStatus,
      };

      try {
        await bulkUpdateMailCampaigns(campaignIds, patchObject);
        // Assuming `handleSuccess` processes and displays success feedback
        this.handleSuccess("bulkEditCampaignStatus");
      } catch (error) {
        // Assuming `handleErrorFromAPI` processes and displays error feedback
        this.handleErrorFromAPI(error);
      } finally {
        this.campaignIsSaving = false;
      }
    },
    /** campaign list item dropdown items */
    campaignListItemDropdownItems(campaign) {
      // campaign can only be edited by it's user
      if (
        campaign?.metadata?.campaign_status === "draft" &&
        !this.isBoldXchangeAdmin
      ) {
        return [
          {
            label: "Edit",
            isRouterLink: true,
            to: `/campaign/edit/${campaign.id}`,
          },
          {
            label: "Delete",
            isDeleteAction: true, // This item is a delete action
            action: () => this.toggleDeleteModal(campaign),
          },
        ];
      } else if (
        campaign?.metadata?.campaign_status === "draft" &&
        this.isBoldXchangeAdmin &&
        campaign?.user?._id === this.userId
      ) {
        return [
          {
            label: "Edit",
            isRouterLink: true,
            to: `/campaign/edit/${campaign.id}`,
          },
          {
            label: "Delete",
            isDeleteAction: true, // This item is a delete action
            action: () => this.toggleDeleteModal(campaign),
          },
        ];
      } else if (
        campaign?.metadata?.campaign_status === "draft" &&
        this.isBoldXchangeAdmin &&
        campaign?.user?._id !== this.userId
      ) {
        return [
          {
            label: "View details",
            isRouterLink: true,
            to: `/campaign/report/${campaign.id}`,
          },
        ];
      } else if (
        campaign?.metadata?.campaign_status !== "draft" &&
        this.isBoldXchangeAdmin
      ) {
        return [
          {
            label: "View details",
            isRouterLink: true,
            to: `/campaign/report/${campaign.id}`,
          },
          {
            label: "Delete",
            isDeleteAction: true, // This item is a delete action
            action: () => this.toggleDeleteModal(campaign),
          },
        ];
      } else {
        return [
          {
            label: "View details",
            isRouterLink: true,
            to: `/campaign/report/${campaign.id}`,
          },
        ];
      }
    },
    /** campaign list item dropdown items */
    sortDropdownItems() {
      return [
        {
          label: "Sort by: Newest",
          action: () => this.handleSort("-_id"),
        },
        {
          label: "Sort by: Oldest",
          action: () => this.handleSort("_id"),
        },
      ];
    },

    /** modals */
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    toggleDeleteModal(campaignId) {
      this.campaignIdToDelete = [campaignId];
      this.showDeleteModal = !this.showDeleteModal;
    },
    closeModal() {
      this.campaignIdToDelete = null;
      this.showDeleteModal = false;
    },

    /** timestamp */
    setStamp() {
      let timestamp;
      const dateRightNow = new Date();
      const startOfWeek = new Date(dateRightNow);
      startOfWeek.setHours(0, 0, 0, 0);
      startOfWeek.setDate(
        dateRightNow.getDate() -
          dateRightNow.getDay() +
          (dateRightNow.getDay() === 0 ? -6 : 1)
      );

      switch (this.selectedDateRange) {
        case "Past 7 days":
          timestamp = dateRightNow.getTime() - 1000 * 60 * 60 * 24 * 7;
          break;
        case "Past 30 days":
          timestamp = dateRightNow.getTime() - 1000 * 60 * 60 * 24 * 30; // Last 30 days
          break;
        case "Past 90 days":
          timestamp = dateRightNow.getTime() - 1000 * 60 * 60 * 24 * 90; // Last 90 days
          break;
        case "This week":
          timestamp = startOfWeek.getTime(); // Past week (Monday to today)
          break;
        default:
          timestamp = dateRightNow.getTime(); // Default: Current timestamp
          break;
      }

      this.stamp = timestamp;
    },

    /** utility handlers */
    handlePrimaryAction() {
      this.$router.push("/campaigns");
    },
    handleDateRangeSelection(date) {
      this.selectedCampaignType = "";
      this.selectedCampaignStatus = "";
      this.selectedDateRange = date;
      this.customDateRangeApplied = false;
      this.clearSearchQuery();
      if (date === "All time") {
        this.stamp = null;
        this.filterCampaigns();
      } else {
        this.setStamp();
        this.filterCampaigns();
      }
    },
    handleCustomDateRangeSelection() {
      this.clearSearchQuery();
      this.stamp = null;
      this.selectedCampaignType = "";
      this.selectedCampaignStatus = "";
      this.selectedDateRange = "";
      this.customDateRangeApplied = true;
      this.startDateTimestamp = new Date(this.startDate).getTime();
      this.endDateTimestamp = new Date(this.endDate).getTime();
      this.filterCampaigns();
    },
    handleTypeSelection(type) {
      this.clearSearchQuery();
      this.stamp = null;
      this.selectedDateRange = "";
      this.selectedCampaignStatus = "";
      this.selectedCampaignType = type;
      this.customDateRangeApplied = false;
      this.filterCampaigns();
    },
    handleStatusSelection(status) {
      this.clearSearchQuery();
      this.stamp = null;
      this.selectedDateRange = "";
      this.selectedCampaignType = "";
      this.selectedCampaignStatus = status;
      this.customDateRangeApplied = false;
      if (status === "all") {
        this.selectedCampaignStatus = "";
        this.filterCampaigns();
      } else {
        this.filterCampaigns();
      }
    },
    handleSort(column) {
      this.checkIfCampaignStatusIsAll();
      this.deactivateQuickEdit();
      this.currentPage = 1;

      this.sortedValue = this.sortedValue === column ? `-${column}` : column;

      if (this.searchQuery) {
        return this.handleSearchQuery();
      } else {
        return this.filterCampaigns();
      }
    },
    handleSearchQuery() {
      this.searchIsActive = true;
      this.selectedCampaignStatus = "";
      this.selectedCampaignType = "";
      this.selectedDateRange = "";
      this.customDateRangeApplied = false;
      this.getCampaignsBySearchQuery();
      // this.trackFilter();
    },
    checkIfCampaignStatusIsAll() {
      if (this.selectedCampaignStatus === "all") {
        this.selectedCampaignStatus = "";
      }
    },
    clearSearchQuery() {
      this.searchIsActive = false;
      this.searchQuery = "";
    },
    clearCustomDates() {
      this.startDate = "";
      this.endDate = "";
      this.customDateRangeApplied = false;
      this.filterCampaigns();
    },
    resetFilters() {
      this.selectedCampaignStatus = "";
      this.selectedCampaignType = "";
      this.selectedDateRange = "";
      this.searchIsActive = false;
      this.startDate = "";
      this.endDate = "";
      this.customDateRangeApplied = false;
      this.searchQuery = "";
      this.currentPage = 1;
      this.filterCampaigns();
    },
    reloadList() {
      const page = this.currentPage;
      this.$store.dispatch("campaigns/deactivateQuickEdit");
      if (this.searchQuery !== "") {
        this.getCampaignsBySearchQuery(page);
      } else {
        // this.filterCampaigns(page);
        this.checkForCampaigns(page);
      }
    },

    toggleBulkEdit() {
      this.isBulkEditing = !this.isBulkEditing;
      this.content = [];
      this.selectedRows = [];
      this.selectAll = false;
    },
    clearBulkEditSelections() {
      this.isBulkEditing = false;
      this.content = [];
      this.selectedRows = [];
      this.selectAll = false;
    },
    selectAllRows() {
      // Select or deselect all rows based on the "Select All" checkbox
      if (this.selectAll) {
        this.selectedRows = this.campaigns.map((campaign) => campaign);
      } else {
        this.selectedRows = [];
      }
    },
    handleRowSelection(campaign) {
      const existingCampaignIndex = this.selectedRows.findIndex(
        (v) => v._id === campaign._id
      );

      if (existingCampaignIndex !== -1) {
        // If the row is already selected, deselect it
        this.selectedRows.splice(existingCampaignIndex, 1);
      } else {
        // If the row is not selected, select it
        this.selectedRows.push(campaign);
      }
    },
    activateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to true
      this.$store.dispatch("campaigns/activateQuickEdit");
    },
    deactivateQuickEdit() {
      // Trigger mutation or action to set quickEditActive to false
      this.$store.dispatch("campaigns/deactivateQuickEdit");
    },

    /** handle success and errors */
    handleSuccess(response, api) {
      let successMessage = "Update successful!";
      if (api === "upsertMailCampaign") {
        successMessage = "Campaign status was successfully updated.";
      } else if (api === "deleteMailCampaigns") {
        this.showDeleteModal = false;
        successMessage = "Campaign was successfully deleted.";
      } else {
        this.campaignId = response.data.result.records[0]._id;
        this.$router.push(`/template/edit/${this.campaignId}`);
      }

      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.SUCCESS,
        message: successMessage,
        duration: 4000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.clearBulkEditSelections();
      this.reloadList();
      this.campaignIsSaving = false;
    },
    handleErrorFromAPI(error) {
      let errorMessage = "Something went wrong. Please try again."; // Default message
      if (error?.response) {
        if (
          error?.response?.data &&
          error?.response?.data?.error &&
          error?.response?.data?.error?.source?.errors
        ) {
          const sourceErrors = error.response.data.error.source.errors;
          const firstErrorKey = Object.keys(sourceErrors)[0];
          errorMessage = sourceErrors[firstErrorKey].message;
        } else {
          errorMessage =
            error?.response?.data?.message || "An unknown error occurred.";
        }
      } else if (error?.request) {
        errorMessage =
          "No response was received from the server. Please check your internet connection.";
      } else {
        errorMessage = "An error occurred with your request. Please try again.";
      }

      // Dispatch the notifyError action to the Vuex store
      this.$store.dispatch("alerts/showAlert", {
        type: this.notificationTypes.ERROR,
        message: errorMessage,
        duration: 6000, // milliseconds before auto-clearing the notification
        // scope: this.$options.name, // Use component's name as the scope
        id: Date.now(), // Ensures each notification is unique
      });

      this.checkingIfUserHasCampaigns = false;
      this.campaignsAreLoading = false;
      this.campaignIsSaving = false;
      this.reloadList();
      console.error(errorMessage);
    },

    /** mixpanel */
    // trackFilter() {
    //   this.mixpanel.track("Campaign List Filters Applied", {
    //     dateRange: this.selectedDateRange,
    //     search: this.searchQuery,
    //   });
    // },
  },
};
</script>
